import { MINIMUM_PASSWORD_LENGTH } from 'app/util/constants';

import {
  errorMessages as accountSettingsErrorMessages,
  validations as accountSettingsValidations,
} from 'app/components/Common/AccountSettingsForm/constants';

import { isFormat, isPresent } from 'app/util/validations';

const defaultFormValues = {
  stepOne: {
    bypassSsnRegistration: false,
    dateOfBirth: undefined,
    employerRegistrationId: undefined,
    firstName: undefined,
    lastName: undefined,
  },
  stepTwo: {
    email: '',
    phoneNumber: '',
    password: '',
    passwordConfirmation: '',
    referralSource: '',
    skipEmailValidation: false,
  },
};

export const errorMessages = {
  dateOfBirth: { missing: 'Please select your birth month, day, and year' },
  employerRegistrationId1: {
    invalid: 'Enter the last 4 digits of your Social Security Number',
    missing: 'Enter the last 4 digits of your Social Security Number',
  },
  employerRegistrationId: {
    socialSecurityNumber: {
      invalid: 'Enter the last 4 digits of your Social Security Number',
      missing: 'Enter the last 4 digits of your Social Security Number',
    },
    memberId: {
      invalid: 'Enter the last 4 digits of your Member ID',
      missing: 'Enter the last 4 digits of your Member ID',
    },
  },
  firstName: { missing: 'Please enter your First Name' },
  lastName: { missing: 'Please enter your Last Name' },
  phoneNumber: {
    invalid: 'Please enter a 10-digit Phone Number',
    missing: 'Please enter your Phone Number',
  },
  referralSource: {
    missing: 'Please let us know how you became aware of Carrum',
  },
};

export { Component } from 'react';

export { DeviceType } from 'expo-device';

export { emailValidationError } from 'app/actions/sessionActions';

export const errorMessagesForStepOneTests = {
  firstName: errorMessages.firstName.missing,
  lastName: errorMessages.lastName.missing,
  dateOfBirth: errorMessages.dateOfBirth.missing,
  employerRegistrationId:
    errorMessages.employerRegistrationId.socialSecurityNumber.missing,
};

export const errorMessagesForStepTwoTests = {
  email: accountSettingsErrorMessages.email.missing,
  phoneNumber: errorMessages.phoneNumber.missing,
  password: accountSettingsErrorMessages.password.missing,
  passwordConfirmation:
    accountSettingsErrorMessages.passwordConfirmation.mismatch,
  referralSource: errorMessages.referralSource.missing,
};

export { EVENTS } from 'app/util/analytics';

export const expiredAuthLinkConfig = {
  guidanceText:
    'For your security, you can only use that link once to register. Use the button below to get a secure link sent to $email.',
  guidanceTextWithoutEmail:
    'For your security, you can only use that link once to register. If you\'re stuck, use the "Create Account" link in the navigation to register manually.',
  headerText: "You're already registered!",
  linkText: 'Send Instant Login Link',
};

export const invalidFieldValuesForStepTwoTests = {
  email: '',
  phoneNumber: '',
  password: '',
  passwordConfirmation: 'a',
  referralSource: '',
};

export { MINIMUM_PASSWORD_LENGTH };

export const phoneNumber = '18888557806';

export { Platform } from 'react-native';

export { default as PropTypes } from 'prop-types';

export const stepOneDefaultFormValues = defaultFormValues.stepOne;

export const stepTwoDefaultFormValues = defaultFormValues.stepTwo;

export const stepTwoValidations = {
  email: accountSettingsValidations.email,
  password: accountSettingsValidations.password,
  passwordConfirmation: accountSettingsValidations.passwordConfirmation,
  phoneNumber: [
    isPresent(errorMessages.phoneNumber.missing),
    isFormat(/^\(\d{3}\) \d{3} \d{4}$/, errorMessages.phoneNumber.invalid),
  ],
  referralSource: [isPresent(errorMessages.referralSource.missing)],
};

export { TestID } from 'app/util/test-id';

export const validFieldValuesForStepOneTests = {
  firstName: 'Unregistered',
  lastName: 'Tester',
  dateOfBirth: '1/02/2020',
  employerRegistrationId: '1234',
};

export const validFieldValuesForStepTwoTests = {
  email: 'unregistered.tester@carrumhealth.com',
  phoneNumber: '1231231234',
  password: 'Carrum@123',
  passwordConfirmation: 'Carrum@123',
  referralSource: 'Carrum Health Website',
};
