import { ProcedureSearchPageFooterStyles as Styled } from 'app/components/ProcedureSearch/styles';

const ProcedureSearchPageFooter = ({
  queryHasNoResults,
  setIsLetUsKnowVisible,
}: {
  queryHasNoResults: boolean;
  setIsLetUsKnowVisible: any;
}) => {
  return (
    <Styled.Wrapper>
      {queryHasNoResults && (
        <Styled.NoResults.Wrapper>
          <Styled.NoResults.Message>
            Can&apos;t find what you&apos;re looking for?
          </Styled.NoResults.Message>

          <Styled.NoResults.CtaButton
            title="Let us know"
            onPress={() => setIsLetUsKnowVisible(true)}
            type="clear"
          />
        </Styled.NoResults.Wrapper>
      )}
    </Styled.Wrapper>
  );
};

export default ProcedureSearchPageFooter;
