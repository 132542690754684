import { breakpoints } from 'app/components/Common/Onboarding/constants';
import {
  DimensionsProps,
  OnboardingPageProps,
  ValuePropImageProps,
} from 'app/components/Common/Onboarding/types';
import {
  CareImage,
  CostImage,
  MindImageForEmployer,
  MindImageForPartner,
  QualityImage,
} from 'app/components/Common/Onboarding/styles';

/**
 * Determine minimum height necessary to accommodate sliding content and ensure
 * that content below does not jump or stutter. Note that the smallest size has
 * been arbitrarily adjusted here to optimize presentation.
 *
 * @param   currentWidth  Current width of screen
 * @return                Scale factor required to responsively scale an image
 */
const getScaleFactor = (
  currentWidth: number,
  page: OnboardingPageProps
): number => {
  const divisor = breakpoints.xlarge;
  switch (true) {
    case currentWidth <= breakpoints.xsmall:
      return page.key === 'mind'
        ? (breakpoints.xsmall / divisor) * 1.15
        : breakpoints.xsmall / divisor;
    case currentWidth <= breakpoints.small:
      return breakpoints.small / divisor;
    default:
      return breakpoints.medium / divisor;
  }
};

/**
 * Configure and return the correct SVG component based on specified key and
 * size parameters.
 *
 * @param   key                Unique identifier for the SVG component
 * @param   dimensions         Height and width of the SVG
 * @param   dimensions.height  Height assigned to the SVG
 * @param   dimensions.width   Width assigned to the SVG
 * @return                     SVG component with specified dimensions
 */
const getValuePropImage = (
  imageKey: string,
  dimensions: DimensionsProps
): React.ReactElement => {
  switch (true) {
    case imageKey === 'care':
      return <CareImage {...dimensions} />;
    case imageKey === 'cost':
      return <CostImage {...dimensions} />;
    case imageKey === 'quality':
      return <QualityImage {...dimensions} />;
    case imageKey === 'mindForEmployer':
      return <MindImageForEmployer {...dimensions} />;
    case imageKey === 'mindForPartner':
      return <MindImageForPartner {...dimensions} />;
    default:
      return null;
  }
};

/**
 * Return a scaled SVG image related to Carrum Health's value propositions.
 */
export default ({ dimensions, page }: ValuePropImageProps) => {
  const image = page.image;
  const scaleFactor = getScaleFactor(dimensions.width, page);
  const scaledDimensions = {
    height: image.height * scaleFactor,
    width: image.width * scaleFactor,
  };
  return getValuePropImage(page.image.key, scaledDimensions);
};
