import React, { Component } from 'react';

import FaqSection from './FaqSection';
import sections from './sections.json';
import { Container } from './styles';

export default class Faq extends Component {
  state = {
    activeSection: null,
  };

  toggleSection = (index) => {
    this.setState({
      activeSection: this.state.activeSection === index ? null : index,
    });
  };

  render() {
    return (
      <Container testID="Faq">
        {sections.map(({ title, body }, index) => (
          <FaqSection
            key={title}
            body={body}
            title={title}
            active={this.state.activeSection === index}
            onToggle={() => this.toggleSection(index)}
          />
        ))}
      </Container>
    );
  }
}
