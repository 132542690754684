import styled, { css } from 'styled-components/native';

import ModalRaw, { ModalProps } from 'app/components/Common/Modal';
import { Text } from 'app/components/Common/Styled';

export { InfoIcon } from 'app/components/Common/svg-components';

export const Header = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.bodyText};
    font-family: ${theme.fontFamilyMedium};
    font-size: ${theme.fontSizes.h2}px;
    margin: ${theme.spacing / 2}px auto;
  `}
`;

export const InfoIconContainer = styled.View`
  align-items: center;
  flex: 0 0 auto;
  justify-content: center;
  padding-bottom: ${({ theme }) => theme.spacing}px;
`;

export const Modal = styled(ModalRaw)<ModalProps>``;

export const Paragraph = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.bodyText};
    font-family: ${theme.fontFamily};
    font-size: ${theme.fontSizes.body2}px;
    margin: ${theme.spacing / 2}px auto;
  `}
`;
