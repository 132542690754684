import {
  expiredAuthLinkConfig,
  TestID,
} from 'app/components/Register/constants';

import { getSavedSingleClickRegistrations } from 'app/components/Register/helpers';

import {
  useCallback,
  useEffect,
  useHistory,
  useState,
} from 'app/components/Register/hooks';

import { ExpiredAuthLink } from 'app/components/Register/styles';

/**
 * Display possible pivots for user after they use an expired single-click
 * registration link.
 */
const SingleClickRegistrationExpired = () => {
  const history = useHistory();

  const [guestEmail, setGuestEmail] = useState('');

  /**
   * Pull the most recent single-click registrant email address from local
   * storage when this component mounts.
   *
   * - If one or more addresses are found, use the most recent as the magic
   *   link recipient. (NOTE: There is an edge-case scenario in which the email
   *   will be misdirected when multiple guests use the same browser and
   *   device.)
   *
   * - If no address is found, it is not possible to send a magic link
   *   directly. Instead, show a more generic error message without the
   *   magic link CTA.
   */
  useEffect(() => {
    handleGuestEmailFromLocalStorage();
  });

  const handleGuestEmailFromLocalStorage = useCallback(async () => {
    const registrantEmails = await getSavedSingleClickRegistrations();

    const lastItemIndex = registrantEmails.length - 1;

    if (!registrantEmails[lastItemIndex]) return;

    setGuestEmail(registrantEmails[lastItemIndex]);
  }, [setGuestEmail]);

  const guidanceText = guestEmail
    ? expiredAuthLinkConfig.guidanceText.replace('$email', guestEmail)
    : expiredAuthLinkConfig.guidanceTextWithoutEmail;

  return (
    <ExpiredAuthLink
      guestEmail={guestEmail}
      guidanceText={guidanceText}
      headerText={expiredAuthLinkConfig.headerText}
      history={history}
      linkText={expiredAuthLinkConfig.linkText}
      // Match the default Test ID for the One Click Welcome page to simplify
      // E2E testing, since the former can redirect here.
      testID={TestID.OneClickWelcome.Page}
    />
  );
};

export default SingleClickRegistrationExpired;
