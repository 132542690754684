import { Pressable } from 'react-native';
import { Input } from 'react-native-elements';
import styled, { css } from 'styled-components/native';

export { default as FloatingLabel } from 'app/components/Common/FloatingLabel';
export { InfoIcon } from 'app/components/Common/svg-components';
export { Modal } from 'app/components/Common/Modal';
import { View } from 'app/components/Common/Styled';

import { InputProps } from './types';

export const Container = styled(View)`
  align-items: flex-start;
  flex: 1 0 auto;
  flex-direction: row;
`;

export const InfoIconWrapper = styled(Pressable).attrs({
  accessibilityRole: 'button',
})`
  ${({ theme }) => css`
    right: ${theme.spacing}px;
  `};

  align-items: center;
  flex-direction: row;
  height: 100%;
  position: absolute;
`;

export const InputWrapper = styled(Input).attrs(
  ({ disabled, error, theme, inputStyle }: InputProps) => {
    return {
      containerStyle: {
        borderColor: error ? theme.colors.error : null,
        borderRadius: 8,
      },
      errorStyle: {
        display: error ? null : 'none',
      },
      inputStyle: {
        color: !disabled ? theme.colors.bodyText : theme.colors.dimText,
        marginBottom: error ? theme.spacing / 2 : null,
        //@ts-ignore
        ...inputStyle, // ensure we pick up the prop-ified styles
      },
      placeholderTextColor: theme.colors.placeholderText,
    };
  }
)`
  flex: 1 0 auto;
  flex-direction: row;
  align-items: center;
`;
