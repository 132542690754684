import React, { ReactNode } from 'react';
import { useParams, useHistory } from '@cross-platform/react-router-native';

import { SelectYourDoctorRoutes } from '../../routes';
import {
  Container,
  ContentContainer,
  DoctorsAtThisFacility,
  ScrollView,
  TopBackLink,
} from './styles';
import { TestID } from 'app/util/test-id';

interface DetailsProps {
  children: ReactNode;
  facilityId: number;
}

interface Params {
  id: string;
}

const Details = ({ children, facilityId }: DetailsProps) => {
  const params = useParams<Params>();
  const history = useHistory();
  const pathname = history.location.pathname;

  const isOnDoctorPage =
    pathname.includes(SelectYourDoctorRoutes.Doctors) && params.id;

  return (
    <ScrollView testID={TestID.SelectYourDoctor.FacilityDetails}>
      <Container>
        <ContentContainer>
          <TopBackLink testID={TestID.SelectYourDoctor.BackButton} />

          {children}
        </ContentContainer>

        {!isOnDoctorPage && <DoctorsAtThisFacility facilityId={facilityId} />}
      </Container>
    </ScrollView>
  );
};

export default Details;
