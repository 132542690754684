import React from 'react';

import TabsRaw from 'app/components/Common/Tabs';

import ExperienceColumn from './ExperienceColumn';

import { experienceModalContent } from '../data';
const { columns, columnTitles, rowTitles } = experienceModalContent;

const ExperienceTabs = () => {
  return (
    <TabsRaw
      containerStyle={{
        backgroundColor: 'white',
        shadowColor: 'rgba(0, 0, 0)',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.25,
        shadowRadius: 4,
      }}
      tabListStyle={{
        height: 60,
      }}
      tabs={columnTitles}
      testID="extended-options--modal--tabs"
    >
      {columns.map((column) => (
        <ExperienceColumn
          column={column}
          key={column.quality.header}
          rowTitles={rowTitles}
        />
      ))}
    </TabsRaw>
  );
};

export default ExperienceTabs;
