import { ReactNode } from 'react';

export enum TaskStatus {
  Complete = 'complete',
  Current = 'current',
  Upcoming = 'upcoming',
}

export interface Status {
  status: TaskStatus;
}

export interface Meta {
  help?: string;
}

export interface TaskProps {
  meta?: Meta;
  onPress?: () => void;
  onPressHelp?: () => void;
  status: TaskStatus;
  title: ReactNode;
}
