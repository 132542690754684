import { StyleSheet } from 'react-native';
import {
  Button as RNEButton,
  ButtonProps as RawButtonProps,
} from 'react-native-elements';
import styled from 'styled-components/native';

interface ButtonProps extends RawButtonProps {
  iconColor: string;
  iconSize: number;
}

export const Button = styled(RNEButton).attrs<ButtonProps>(
  ({ iconSize, containerStyle, titleStyle, icon }) => {
    return {
      containerStyle: {
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end',
        ...StyleSheet.flatten([containerStyle]),
      },
      titleStyle: {
        textTransform: 'none',
        ...StyleSheet.flatten([titleStyle]),
      },
      icon: {
        containerStyle: {
          alignItems: 'center',
        },
        iconSize,
        ...(icon as Object),
      },
    };
  }
)`
  padding-left: 0;
`;
