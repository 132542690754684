import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

//@ts-ignore
import MapViewRaw from '@cross-platform/react-native-maps';

import { View } from 'app/components/Common/Styled';
import { MapPin as MapPinRaw } from 'app/components/Common/svg-components';

const isSmallScreen = ({ breakpoints, windowDimensions }) =>
  windowDimensions.width < breakpoints.medium;

export const Container = styled(View)`
  min-height: ${({ theme }) => theme.windowDimensions.height / 3}px;
  max-height: ${({ theme }) => (isSmallScreen(theme) ? '30%' : '100%')};
  flex: ${({ theme }) => (isSmallScreen(theme) ? '2 0 0' : '1 0 0')};
`;

export const MapPin = styled(MapPinRaw).attrs((props) => {
  const height = isSmallScreen(props.theme) ? 25 : 50;
  const width = isSmallScreen(props.theme) ? 20 : 40;

  return { ...props, height, width };
})`
  ${({ height }) => css`
    ${Platform.OS === 'ios' && `top: -${height / 2}px;`}
  `}
`;

export const MapView = styled(MapViewRaw)`
  flex: 1 1 auto;
`;

export const MapViewPositionWrapper = styled(View)`
  ${({ theme }) =>
    isSmallScreen(theme)
      ? 'flex: 1 1 auto;'
      : css`
          position: ${Platform.select({ default: 'absolute', web: 'fixed' })};
          bottom: 0;
          width: ${({ theme }) => theme.windowDimensions.width / 2}px;
          right: 0;
          top: ${Platform.select({
            default: 0,
            web: theme.navigationHeight,
          })}px;
        `}
`;
