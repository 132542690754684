import React, { ReactNode } from 'react';

import { isEmpty } from 'lodash';

import {
  DeadButtonSpace,
  CellBorders,
  CellContent,
  CellDetailText,
  CellHeader,
} from './styles';

export interface CellBordersProps {
  alignOverride?: boolean;
  centered?: boolean;
  flex?: number;

  borderTop?: boolean;
  borderRight?: boolean;
  borderBottom?: boolean;
  borderLeft?: boolean;
}

export interface CellProps extends CellBordersProps {
  button?: ReactNode;
  details?: string;
  header: string | ReactNode;
  fillDeadButtonSpace?: boolean;
}

const Cell = ({
  button,
  centered = false,
  details = '',
  flex = 1,
  header = '',
  fillDeadButtonSpace = false,
  borderTop = false,
  borderRight = false,
  borderBottom = false,
  borderLeft = false,
}: CellProps) => {
  return (
    <CellBorders
      /**
       * `align-items: stretch;` stretches and centers all cell content in all cases -except- for on iOS > iPad (physical, not in dev tools).
       * Overriding `align-items: center;` when there is no content ensures the column headers will be centered on all platforms and screen sizes.
       */
      alignOverride={isEmpty(details)}
      flex={flex}
      borderTop={borderTop}
      borderRight={borderRight}
      borderBottom={borderBottom}
      borderLeft={borderLeft}
    >
      <CellHeader>{header}</CellHeader>

      {!isEmpty(details) && (
        <CellContent>
          <CellDetailText centered={centered}>{details}</CellDetailText>
        </CellContent>
      )}

      {button}
      {fillDeadButtonSpace && <DeadButtonSpace />}
    </CellBorders>
  );
};

export default Cell;
