import styled from 'styled-components/native';

import { View } from 'app/components/Common/Styled';
import { MapDot } from 'app/components/Common/svg-components';
export { MapPin } from 'app/components/Common/svg-components';

export const Bar = styled(View)<{ screenWidth: number }>`
  align-items: center;
  flex: 1 0 auto;
  flex-direction: row;
  flex-wrap: wrap;

  ${({ screenWidth, theme }) =>
    screenWidth < theme.breakpoints.small
      ? `
        flex-wrap: wrap;
      `
      : `
        background-color: ${theme.colors.white};
        border-radius: 4px;
        box-shadow: ${theme.shadows.mediumBottomString};
        justify-content: space-around;
        max-width: ${theme.breakpoints.xsmall}px;
        padding: ${theme.spacing}px;
      `}
`;

export const LegendWrapper = styled(View)<{ screenWidth: number }>`
  ${({ screenWidth, theme }) =>
    screenWidth < theme.breakpoints.small
      ? `
        padding: ${theme.spacing}px;
        pointer-events: none;
      `
      : `
        bottom: ${theme.spacing * 2}px;
        flex-direction: row;
        justify-content: center;
        pointer-events: none;
        position: absolute;
        width: 100%;
      `}
`;

export const LegendItem = styled(View)`
  align-items: center;
  flex-direction: row;
  margin: 0 ${({ theme }) => theme.spacing}px;
`;

export const BlueMapDot = () => <MapDot />;
