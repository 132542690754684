import { MESSAGE_API_URL } from 'app/util/constants';

const webSocketUrl = `${MESSAGE_API_URL.replace(/^http/, 'ws')}/cable`;

const isCloseEvent = (event) =>
  event &&
  event.target &&
  event.target.readyState &&
  event.target.readyState == event.target.CLOSED;

/**
  An abstract class for interacting with an ActionCable server.

  @param {object} identifier An object specifying the channel to connect to.
  @param {object} config A configuration object with callbacks for socket events.

  @example
  // Connect to an ActionCable channel
  const myChannel = new Subscription({ channel: 'MyChannel' }, {
    connected: () => console.log('Subscribed'),
    disconnected: (event) => console.log('Disconnected', event),
    rejected: () => console.log('Unsubscribed'),
    received: (data) => console.log(data),
    error: (error) => console.log('Got an error', error),
  });

  // Send a message to the ActionCable channel
  myChannel.perform('test_action', { some: 'payload' });

  // Stop listening for events
  myChannel.unsubscribe();
*/
export class Subscription {
  static authToken = null;
  static connected = false;
  static subscriptions = [];
  static waitForConnection = null;
  static ws = null;

  /**
   * Store an authorization token for secure connections.
   *
   * @param {string} authToken An authorization token for the connection request.
   */
  static authorize = (authToken) => {
    Subscription.authToken = authToken;
  };

  /**
   * Adds a new subscription and subscribes to
   * the ActionCable channel using the identifier.
   *
   * @param {object} subscription A Subscription instance.
   */
  static add = (subscription) => {
    Subscription.subscriptions.push(subscription);
    return Subscription.subscribe(subscription.identifier);
  };

  /**
   * Removes a subscription and unsubscribes from
   * the ActionCable channel using the identifier.
   *
   * @param {object} subscription A Subscription instance.
   */
  static remove = (subscription) => {
    const index = Subscription.subscriptions.indexOf(subscription);
    Subscription.subscriptions.splice(index, 1);

    return Subscription.unsubscribe(subscription.identifier);
  };

  /**
   * Finds an active subscription using a given
   * identifier.
   *
   * @param {object} identifier The object identifier for the subscription.
   * @return {Subscription|null} A Subscription instance (if found) or null.
   */
  static find = (identifier) => {
    return Subscription.subscriptions.find(
      (subscription) => subscription.identifier === JSON.stringify(identifier)
    );
  };

  /**
   * Makes a subscription request to the ActionCable
   * channel using the given identifier.
   *
   * @param {object} identifier The identifier for the channel.
   * @return {promise} A promise that resolves after sending the request.
   */
  static subscribe = (identifier) => {
    return Subscription.send(identifier, 'subscribe');
  };

  /**
   * Makes a disconnection request to the ActionCable
   * channel using the given identifier.
   *
   * @param {object} identifier The identifier for the channel.
   * @return {promise} A promise that resolves after sending the request.
   */
  static unsubscribe = (identifier) => {
    return Subscription.send(identifier, 'unsubscribe');
  };

  /**
   * Sends a message to the ActionCable server to
   * perform a specific action.
   *
   * @param {object} identifier The identifier for the channel.
   * @param {object} data An object to use as a JSON payload for the request.
   * @return {promise} A promise that resolves after sending the request.
   */
  static message = (identifier, data) => {
    return Subscription.send(identifier, 'message', data);
  };

  /**
   * Sends an encoded message to the open WebSocket instance.
   *
   * @param {object} identifier The ActionCable channel identifier.
   * @param {string} command The ActionCable command to execute.
   * @param {object} data An optional JSON payload to include with the message.
   * @return {promise} A promise that resolves after sending the message.
   */
  static send = (identifier, command, data = {}) => {
    return Subscription.connect().then(() =>
      Subscription.ws.send(
        JSON.stringify({
          command,
          identifier,
          data: JSON.stringify(data),
        })
      )
    );
  };

  /**
   * Creates a new WebSocket connection unless
   * one exists already, and binds event listeners.
   *
   * @return {promise} A promise that resolves after the WebSocket connects.
   */
  static connect = () => {
    Subscription.waitForConnection =
      Subscription.waitForConnection ||
      new Promise((resolve, reject) => {
        try {
          Subscription.ws = new WebSocket(
            `${webSocketUrl}?authorization=${Subscription.authToken}`
          );
          Subscription.ws.onopen = Subscription.onOpen(resolve);
          Subscription.ws.onclose = Subscription.onClose;
          Subscription.ws.onerror = Subscription.onError;
          Subscription.ws.onmessage = Subscription.onMessage;
        } catch (error) {
          reject(error);
        }
      });

    return Subscription.waitForConnection;
  };

  /**
   * Closes the WebSocket connection.
   *
   * @return {promise} A promise that resolves after closing the connection.
   */
  static disconnect = () => {
    if (Subscription.connected) {
      Subscription.ws.close();
    }

    return Promise.resolve();
  };

  /**
   * Returns a callback function to run when
   * a new WebSocket connection opens.
   *
   * @param {function} callback A callback function to run on open.
   * @return {function} A function to pass as an onOpen event handler.
   */
  static onOpen = (callback) => () => {
    Subscription.connected = true;
    return callback();
  };

  /**
   * Iterates through all subscriptions and calls
   * `confing.disconnected` with the event and
   * then resets the internal state.
   *
   * @param {object} event A native WebSocket onClose event or error.
   */
  static onClose = (event) => {
    Subscription.subscriptions.map(({ config }) =>
      config.disconnected ? config.disconnected(event) : null
    );
    Subscription.subscriptions = [];
    Subscription.waitForConnection = null;
    Subscription.ws = null;
    Subscription.connected = false;
  };

  /**
   * Iterates through any subscriptions with a
   * matching identifier and calls the appropriate
   * config function based on the event type.
   *
   * @param {object} event A native WebSocket onMessage event.
   */
  static onMessage = (event) => {
    const { identifier, message, type } = JSON.parse(event.data);
    const subscriptions = Subscription.subscriptions.filter(
      (subscription) => subscription.identifier === identifier
    );

    switch (type) {
      case 'confirm_subscription':
        return subscriptions.forEach(({ config }) =>
          config.connected ? config.connected() : null
        );
      case 'reject_subscription':
        return subscriptions.forEach(({ config }) =>
          config.rejected ? config.rejected() : null
        );
      case 'disconnect':
        return subscriptions.forEach(({ config }) =>
          config.disconnected ? config.disconnected() : null
        );
      case 'ping':
        return Subscription.ws.send(JSON.stringify({ command: 'pong' }));
      default:
        if (!message) return;

        return subscriptions.forEach(({ config }) =>
          config.received ? config.received(message) : null
        );
    }
  };

  /**
   * Iterates through all subscriptions and calls
   * `confing.error` with the error object.
   *
   * @param {object} error A native WebSocket error.
   */
  static onSocketError = (error) => {
    Subscription.subscriptions.map(({ config }) =>
      config.error ? config.error(error) : null
    );
  };

  /**
   * Handles errors emitted from the WebSocket instance.
   * If there is an active connection, calls `onSocketError()`,
   * otherwise calls `.onClose()` and cancels.
   *
   * @param {object} error A native WebSocket error.
   */
  static onError = (error) => {
    if (!Subscription.connected || isCloseEvent(error)) {
      Subscription.onClose(error);
    } else {
      Subscription.onSocketError(error);
    }
  };

  constructor(identifier, config = {}) {
    this.identifier = JSON.stringify(identifier);
    this.config = config;
    Subscription.add(this);
  }

  /**
   * Sends a message to an ActionCable channel
   * using a given action and optional data.
   *
   * @param {string} action The action to perform.
   * @param {object} data An optional JSON payload to send.
   */
  perform = (action, data = {}) => {
    return Subscription.message(this.identifier, { ...data, action });
  };

  /**
   * Remove this instance from the subscriptions
   * array to stop broadcasting updates.
   */
  unsubscribe = () => {
    return Subscription.remove(this);
  };
}
