import { createUpload } from 'app/actions/uploadActions';
import { deleteSavedSingleClickRegistrations } from 'app/util/userUtils';
import fetchCurrentEpisode from 'app/actions/episodeActions/fetch-current-episode';
import getEpisodeDistance from 'app/actions/episodeActions/get-episode-distance';
import { showGlobalError } from 'app/actions/uiActions';

import { receiveCurrentUser } from './action-types';
import getUserLocation from './get-user-location';
import updateCurrentUser from './update-current-user';

/**
 * Make a PATCH request to the Core Service User API to update the user record.
 * For profile image updates, also send a POST request to the Upload Service.
 *
 * @param   {object}   input  params to apply to the user record
 *
 * @return  {promise}         promise that resolves when user record updates
 */
export default (input) => {
  return async (dispatch, getState) => {
    try {
      if (/^(data|file):/.test(input.profileImage)) {
        const uri = await dispatch(createUpload(input.profileImage));
        input.profileImage = uri;
      }
      const response = await dispatch(updateCurrentUser(input));
      await dispatch(receiveCurrentUser(response.data.data));
      let episode = getState().episode;

      // When a user creates an account password, clear their email from secure
      // storage as the presence of that stored property is used to distinguish
      // between guests and verified users in the "password" step of the two
      // step login process.
      if (input.password) deleteSavedSingleClickRegistrations(input.email);

      await dispatch(getUserLocation());

      if (episode?.episode) {
        await dispatch(fetchCurrentEpisode());
        await dispatch(getEpisodeDistance());
      }

      return true;
    } catch (error) {
      dispatch(showGlobalError(error));

      return false;
    }
  };
};
