import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  personalizeSingleClickRegistration,
  verifySingleClickRegistration,
} from 'app/actions/sessionActions';
import useSession from 'app/hooks/useSession';

const useSingleClickRegistration = (token?: string) => {
  const dispatch = useDispatch();
  const { personalizationDetails } = useSession();

  const personalize = useCallback(async () => {
    await dispatch(personalizeSingleClickRegistration(token));
  }, [dispatch, token]);

  const verify = useCallback(
    async (phoneNumber, dateOfBirth) => {
      dispatch(verifySingleClickRegistration(token, phoneNumber, dateOfBirth));
    },
    [dispatch, token]
  );

  useEffect(() => {
    if (!token) return;
    if (Object.keys(personalizationDetails).length > 0) return;

    personalize();
  }, [token]);

  return { personalizationDetails, personalize, token, verify };
};

export default useSingleClickRegistration;
