import React from 'react';
import Svg, { G, Path } from 'react-native-svg';

interface Props {
  height?: number;
  width?: number;
}

const MapDot = ({ height = 202, width = 216 }: Props) => (
  <Svg width={width} height={height} viewBox="0 0 216 202" fill="none">
    <Path
      d="M35.4513 98.743L177.275 97.418L181.429 193.834L34.5703 192.778L35.4513 98.743Z"
      fill="#EF7136"
    />
    <Path
      d="M35.4512 98.743L102.461 44.29C105.298 41.984 109.365 41.989 112.197 44.301L177.275 97.418"
      fill="#EF7136"
    />
    <G opacity="0.3">
      <Path
        d="M35.4512 98.743L102.461 44.29C105.298 41.984 109.365 41.989 112.197 44.301L177.275 97.418"
        fill="#3A4750"
      />
    </G>
    <Path
      d="M34.5704 192.778C34.3934 192.612 45.7214 180.192 59.8694 165.039C74.0224 149.881 85.6364 137.732 85.8134 137.897C85.9904 138.063 74.6644 150.48 60.5114 165.638C46.3634 180.79 34.7474 192.942 34.5704 192.777V192.778Z"
      fill="#3A4750"
    />
    <Path
      d="M181.429 193.834C181.248 193.995 170.165 181.872 156.678 166.759C143.186 151.641 132.4 139.258 132.58 139.097C132.761 138.936 143.841 151.056 157.333 166.175C170.82 181.288 181.609 193.673 181.428 193.835L181.429 193.834Z"
      fill="#3A4750"
    />
    <G opacity="0.5">
      <Path d="M177.275 97.418L106.176 154.459L35.4512 98.743" fill="#3A4750" />
    </G>
    <Path
      d="M42.6045 103.997L42.2915 59.7009L166.476 57.9749L167.973 104.579L106.167 153.715L42.6045 103.997Z"
      fill="#FAFAFA"
    />
    <Path
      d="M42.6046 103.997C42.6046 103.997 43.0146 104.293 43.7826 104.881C44.5576 105.479 45.6906 106.352 47.1676 107.492C50.1336 109.795 54.4746 113.166 60.0226 117.474C71.1076 126.115 87.0046 138.507 106.299 153.546H106.035C123.837 139.378 144.958 122.567 167.812 104.377L167.715 104.587C167.429 95.6959 167.128 86.3689 166.822 76.8769C166.617 70.4879 166.415 64.1739 166.216 57.9829L166.479 58.2339C116.439 58.8929 72.2936 59.4739 42.2926 59.8689L42.4576 59.7009C42.5216 73.6279 42.5716 84.6869 42.6066 92.2799C42.6146 96.0689 42.6206 98.9909 42.6256 100.979C42.6236 101.961 42.6216 102.71 42.6206 103.227C42.6176 103.733 42.6046 103.997 42.6046 103.997C42.6046 103.997 42.5886 103.747 42.5776 103.247C42.5696 102.733 42.5566 101.991 42.5406 101.017C42.5166 99.0329 42.4816 96.1189 42.4366 92.3389C42.3636 84.7329 42.2576 73.6539 42.1246 59.7019L42.1226 59.5359L42.2886 59.5339C72.2886 59.0949 116.433 58.4479 166.472 57.7149L166.726 57.7109L166.734 57.9659C166.933 64.1559 167.136 70.4699 167.342 76.8599C167.646 86.3519 167.944 95.6789 168.229 104.57L168.233 104.699L168.132 104.78C145.258 122.945 124.118 139.732 106.3 153.882L106.168 153.987L106.036 153.883C86.7656 138.759 70.8886 126.297 59.8176 117.608C54.3096 113.268 49.9996 109.873 47.0546 107.553C45.6006 106.4 44.4866 105.516 43.7236 104.911C42.9746 104.313 42.6036 103.998 42.6036 103.998L42.6046 103.997Z"
      fill="#3A4750"
    />
    <Path
      d="M97.8217 82.7154C97.8217 77.1286 102.374 72.5823 107.971 72.5823C110.009 72.5823 111.976 73.1826 113.655 74.3198C114.934 75.1847 116.671 74.8516 117.539 73.575C118.405 72.2984 118.072 70.5635 116.793 69.6973C114.184 67.9328 111.134 66.9995 107.972 66.9995C99.293 66.9995 92.2314 74.05 92.2314 82.7154C92.2314 84.7058 92.6025 86.6421 93.3278 88.4763L98.4888 86.3271C98.048 85.1783 97.8217 83.9649 97.8217 82.7154Z"
      fill="#A53723"
    />
    <Path
      d="M107.971 98.4317C111.578 98.4317 115.103 97.1822 117.897 94.9129C120.65 92.6772 122.584 89.5508 123.343 86.1082C123.675 84.6031 122.722 83.1135 121.213 82.7817C119.705 82.4487 118.214 83.4013 117.882 84.9077C116.867 89.5095 112.699 92.8489 107.971 92.8489C103.852 92.8489 100.17 90.3937 98.5911 86.5948C98.5549 86.507 98.5226 86.4167 98.4877 86.3289L93.3267 88.4781C93.3603 88.5633 93.3913 88.6498 93.4262 88.735C95.8736 94.6251 101.583 98.4317 107.971 98.4317Z"
      fill="#EF7136"
    />
    <Path
      d="M30.9762 43.4854L32.5045 46.0169C34.2208 48.8559 36.7182 51.1473 39.6976 52.6173L42.354 53.9287L39.8133 55.4515C36.964 57.1616 34.6644 59.6499 33.1891 62.6187L31.8778 65.2655L30.3495 62.734C28.6332 59.8949 26.1358 57.6036 23.1564 56.1336L20.5 54.8222L23.0407 53.2994C25.89 51.5893 28.1896 49.1009 29.6649 46.1322L30.9762 43.4854Z"
      fill="#F6B99A"
    />
    <Path
      d="M162.251 9.00194L163.27 10.6896C164.413 12.5826 166.077 14.1113 168.065 15.0898L169.836 15.9617L168.142 16.977C166.243 18.1162 164.709 19.7748 163.727 21.755L162.852 23.5201L161.833 21.8325C160.69 19.9395 159.025 18.4108 157.038 17.4323L155.267 16.5584L156.96 15.5432C158.86 14.4039 160.392 12.7453 161.376 10.7651L162.253 9L162.251 9.00194Z"
      fill="#F6B99A"
    />
    <Path
      d="M116.871 21.7071L117.381 22.7619C117.953 23.9448 118.785 24.8996 119.779 25.512L120.664 26.0565L119.817 26.691C118.867 27.4035 118.101 28.4403 117.609 29.6773L117.172 30.7802L116.663 29.7253C116.09 28.5424 115.258 27.5877 114.265 26.9752L113.379 26.4288L114.226 25.7943C115.176 25.0817 115.943 24.0449 116.434 22.8079L116.873 21.7051L116.871 21.7071Z"
      fill="#F6B99A"
    />
    <Path
      d="M73.9401 65L74.8791 66.5611C75.9337 68.3119 77.4682 69.7249 79.2989 70.6313L80.9311 71.4401L79.37 72.3791C77.6192 73.4337 76.2062 74.9682 75.2998 76.7989L74.4911 78.4311L73.552 76.87C72.4974 75.1192 70.9629 73.7062 69.1322 72.7998L67.5 71.991L69.0611 71.052C70.8119 69.9974 72.2249 68.4629 73.1314 66.6322L73.9401 65Z"
      fill="#F6B99A"
    />
    <Path
      d="M183.732 54L184.642 55.51C185.66 57.207 187.145 58.5744 188.921 59.4499L190.5 60.2319L188.99 61.1419C187.293 62.16 185.926 63.6455 185.05 65.4211L184.268 67L183.358 65.49C182.34 63.793 180.855 62.4256 179.079 61.5501L177.5 60.7681L179.01 59.8581C180.707 58.84 182.074 57.3545 182.95 55.5789L183.732 54Z"
      fill="#F6B99A"
    />
    <Path
      d="M60.2674 9.00175L61.2853 10.8995C62.4278 13.0296 64.0925 14.7474 66.0799 15.85L67.8506 16.8321L66.1578 17.9731C64.2577 19.2558 62.7255 21.1203 61.742 23.35L60.866 25.3351L59.8481 23.4374C58.704 21.3073 57.0409 19.5895 55.052 18.4869L53.2812 17.5048L54.974 16.362C56.8741 15.0811 58.4063 13.2148 59.3899 10.9851L60.2659 9L60.2674 9.00175Z"
      fill="#F6B99A"
    />
    <Path
      d="M142.426 34L143.143 35.1936C143.95 36.5334 145.123 37.6139 146.526 38.3074L147.774 38.9251L146.581 39.6439C145.241 40.4506 144.159 41.6234 143.466 43.0258L142.849 44.2744L142.131 43.0808C141.324 41.741 140.151 40.6606 138.749 39.967L137.5 39.3493L138.694 38.6305C140.034 37.8249 141.114 36.651 141.808 35.2497L142.426 34Z"
      fill="#F6B99A"
    />
    <Path
      d="M150.293 83L150.993 84.1622C151.778 85.4666 152.919 86.5186 154.285 87.193L155.5 87.7932L154.338 88.4931C153.033 89.2778 151.981 90.4189 151.307 91.7847L150.707 93L150.007 91.8378C149.222 90.5334 148.081 89.4814 146.715 88.807L145.5 88.2068L146.662 87.5069C147.967 86.7222 149.019 85.5811 149.693 84.2153L150.293 83Z"
      fill="#F6B99A"
    />
    <Path
      d="M146.951 107.251C146.952 107.395 127.961 107.666 104.538 107.857C81.1072 108.048 62.1182 108.086 62.1162 107.942C62.1152 107.798 81.1022 107.527 104.533 107.336C127.956 107.145 146.949 107.107 146.95 107.251H146.951Z"
      fill="#3A4750"
    />
    <Path
      d="M146.981 121.673C146.982 121.817 128.864 122.081 106.517 122.263C84.1634 122.445 66.0464 122.476 66.0454 122.332C66.0444 122.188 84.1594 121.924 106.513 121.742C128.859 121.56 146.98 121.529 146.981 121.673Z"
      fill="#3A4750"
    />
    <Path
      d="M131.123 134.364C131.123 134.382 130.952 134.401 130.626 134.421C130.24 134.438 129.769 134.458 129.2 134.482C127.881 134.515 126.108 134.559 123.954 134.613C119.45 134.695 113.368 134.805 106.646 134.927C99.8842 135.054 93.7612 135.093 89.3302 135.089C87.1752 135.075 85.4022 135.063 84.0822 135.054C83.5132 135.039 83.0422 135.027 82.6552 135.018C82.3292 135.004 82.1572 134.988 82.1572 134.97C82.1572 134.952 82.3282 134.933 82.6542 134.913C83.0402 134.896 83.5112 134.876 84.0802 134.852C85.3992 134.819 87.1722 134.775 89.3262 134.721C93.8302 134.639 99.9132 134.529 106.636 134.406C113.396 134.279 119.518 134.24 123.949 134.244C126.104 134.258 127.877 134.27 129.197 134.279C129.767 134.294 130.238 134.306 130.624 134.316C130.95 134.33 131.123 134.346 131.123 134.364Z"
      fill="#3A4750"
    />
    <Path
      d="M115.161 146.564C115.161 146.708 111.112 146.833 106.118 146.843C101.122 146.853 97.0732 146.745 97.0732 146.601C97.0732 146.457 101.121 146.332 106.117 146.322C111.111 146.312 115.161 146.42 115.161 146.564Z"
      fill="#3A4750"
    />
  </Svg>
);

export default MapDot;
