import axios from 'axios';

import store from 'app/util/store';
import { invalidateSession } from 'app/actions/sessionActions';

// Handle session expiration while using the app.
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      store.getState().session.coreToken &&
      error.response &&
      error.response.status === 401
    ) {
      store.dispatch(invalidateSession());
    }

    return Promise.reject(error);
  }
);
