import { connect } from 'react-redux';

import {
  connectToWebSocket,
  disconnectFromWebSocket,
} from 'app/actions/messageActions';
import { registerForPushNotifications } from 'app/actions/notificationActions';
import { detectSessionOverride, logout } from 'app/actions/sessionActions';
import {
  dismissLocalNotification,
  showLocalNotification,
} from 'app/actions/uiActions';
import App from 'app/components/App';
import { getIsLoggedIn } from 'app/selectors/session';
import { location, goBack, push } from 'app/util/history';

const mapStateToProps = ({ message, session, ui }) => ({
  autoLogout: ui.autoLogout,
  conversationId: message.conversations[0] ? message.conversations[0].id : '',
  features: session.features,
  loggedIn: getIsLoggedIn(session),
  notifications: ui.notifications,
  pathname: location.pathname,
  user: session.user,
});

const mapDispatchToProps = (dispatch) => ({
  goBack,
  push,
  connectToWebSocket: () => dispatch(connectToWebSocket()),
  detectSessionOverride: () => dispatch(detectSessionOverride()),
  disconnectFromWebSocket: () => dispatch(disconnectFromWebSocket()),
  dismissLocalNotification: (options) =>
    dispatch(dismissLocalNotification(options)),
  logout: (redirect) => dispatch(logout(redirect)),
  registerForPushNotifications: () => dispatch(registerForPushNotifications()),
  showLocalNotification: (notification) =>
    dispatch(showLocalNotification(notification)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
