import TestimonialOneImg from 'app/assets/images/doctor_selection_quote1.png';
import TestimonialTwoImg from 'app/assets/images/doctor_selection_quote2.png';

export const testimonials = {
  'leslie-knee-replacement': {
    text: "Leslie's testimonial...",
    image: TestimonialOneImg,
  },
  'melody-knee-replacement': {
    text: "Melody's testimonial...",
    image: TestimonialTwoImg,
  },
};
