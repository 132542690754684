import { Episode } from 'types/episode';

export const RECEIVE_COST_ESTIMATE = 'RECEIVE_COST_ESTIMATE';
export const RECEIVE_COST_ESTIMATE_LOADING = 'RECEIVE_COST_ESTIMATE_LOADING';
export const RECEIVE_DISTANCE = 'RECEIVE_DISTANCE';
export const RECEIVE_EPISODE = 'RECEIVE_EPISODE';
export const RECEIVE_EPISODE_LOADING = 'RECEIVE_EPISODE_LOADING';
export const RECEIVE_EPISODE_STEPS = 'RECEIVE_EPISODE_STEPS';
export const RECEIVE_EPISODE_TASK_COMPLETE = 'RECEIVE_EPISODE_TASK_COMPLETE';
export const RECEIVE_ESTIMATED_SAVINGS = 'RECEIVE_ESTIMATED_SAVINGS';
export const RECEIVE_HOSPITAL_BUNDLE_PRICES_LOADING =
  'RECEIVE_HOSPITAL_BUNDLE_PRICES_LOADING';
export const RECEIVE_HOSPITAL_BUNDLE_PRICES = 'RECEIVE_HOSPITAL_BUNDLE_PRICES';
export const RECEIVE_INQUIRY = 'RECEIVE_INQUIRY';
export const RECEIVE_EPISODE_JOURNEY_PHASES = 'RECEIVE_EPISODE_JOURNEY_PHASES';
export const RECEIVE_PHYSICIAN_RATING = 'RECEIVE_PHYSICIAN_RATING';
export const RECEIVE_POSSIBLE_PHYSICIANS = 'RECEIVE_POSSIBLE_PHYSICIANS';
export const RECEIVE_POSSIBLE_PROCEDURES = 'RECEIVE_POSSIBLE_PROCEDURES';
export const RECEIVE_POSSIBLE_HOSPITALS = 'RECEIVE_POSSIBLE_HOSPITALS';
export const RECEIVE_PRODUCT_OFFERINGS = 'RECEIVE_PRODUCT_OFFERINGS';
export const RECEIVE_PROCEDURE = 'RECEIVE_PROCEDURE';
export const RECEIVE_REFERRAL_SOURCES = 'RECEIVE_REFERRAL_SOURCES';
export const RECEIVE_TEMP_COE_SELECTION = 'RECEIVE_TEMP_COE_SELECTION';
export const RECEIVE_HOSPITAL_TRAVEL_TIME = 'RECEIVE_HOSPITAL_TRAVEL_TIME';
export const RECEIVE_ESTIMATES_ERROR = 'RECEIVE_ESTIMATES_ERROR';
export const RECEIVE_BUNDLE_PRICES = 'RECEIVE_BUNDLE_PRICES';
export const RECEIVE_BUNDLE_PRICES_LOADING = 'RECEIVE_BUNDLE_PRICES_LOADING';
export const RECEIVE_BUNDLE_PRICES_ERROR = 'RECEIVE_BUNDLE_PRICES_ERROR';

export const receivePossibleProcedures = (
  possibleProcedures,
  fetchPossibleProcedureCategories
) => {
  return {
    type: RECEIVE_POSSIBLE_PROCEDURES,
    possibleProcedures,
    fetchPossibleProcedureCategories,
  };
};

export const receivePossiblePhysicians = (
  possiblePhysicians,
  total,
  page,
  nextPage
) => {
  return {
    type: RECEIVE_POSSIBLE_PHYSICIANS,
    possiblePhysicians,
    total,
    page,
    nextPage,
  };
};

export const receivePossibleHospitals = (
  possibleHospitals,
  total,
  page,
  nextPage
) => {
  return {
    type: RECEIVE_POSSIBLE_HOSPITALS,
    possibleHospitals,
    total,
    page,
    nextPage,
  };
};

export const receivePhysicianRating = (rating) => {
  return {
    type: RECEIVE_PHYSICIAN_RATING,
    rating,
  };
};

export const receiveEstimatedSavings = (estimatedSavings) => {
  return {
    type: RECEIVE_ESTIMATED_SAVINGS,
    estimatedSavings,
  };
};

export const receiveEstimatesError = (
  error: null | 'oop' | 'contractedRate' | 'both'
) => {
  return {
    type: RECEIVE_ESTIMATES_ERROR,
    error,
  };
};

export const receiveCostEstimate = (costEstimate) => {
  return {
    type: RECEIVE_COST_ESTIMATE,
    costEstimate,
  };
};

export const receiveCostEstimateLoading = (costEstimateLoading) => {
  return {
    type: RECEIVE_COST_ESTIMATE_LOADING,
    costEstimateLoading,
  };
};

export const receiveProcedure = (procedure) => {
  return {
    type: RECEIVE_PROCEDURE,
    procedure,
  };
};

export const receiveDistance = (distance) => {
  return {
    type: RECEIVE_DISTANCE,
    distance,
  };
};

export const receiveEpisode = (episode) => {
  return {
    type: RECEIVE_EPISODE,
    episode,
  };
};

export const receiveInquiry = (inquiry) => {
  return {
    type: RECEIVE_INQUIRY,
    inquiry,
  };
};

export const receiveHospitalBundlePricesLoading = (loading) => {
  return {
    type: RECEIVE_HOSPITAL_BUNDLE_PRICES_LOADING,
    loading,
  };
};

export const receiveHospitalBundlePrices = (bundlePrices) => {
  return {
    type: RECEIVE_HOSPITAL_BUNDLE_PRICES,
    bundlePrices,
  };
};

export const receiveEpisodeLoading = (loading) => {
  return {
    type: RECEIVE_EPISODE_LOADING,
    loading,
  };
};

export const receiveReferralSources = (referralSources) => {
  return {
    type: RECEIVE_REFERRAL_SOURCES,
    referralSources,
  };
};

export const receiveEpisodeSteps = (steps) => {
  return {
    type: RECEIVE_EPISODE_STEPS,
    steps,
  };
};

export const receiveEpisodeTaskComplete = (stepId, taskId) => {
  return {
    type: RECEIVE_EPISODE_TASK_COMPLETE,
    stepId,
    taskId,
  };
};

export const receiveEpisodeJourneyPhases = (journeyPhases) => {
  return {
    type: RECEIVE_EPISODE_JOURNEY_PHASES,
    journeyPhases,
  };
};

export const receiveTempCoeSelection = (
  params: Episode.EpisodeState['tempCoeSelection']
) => {
  return {
    type: RECEIVE_TEMP_COE_SELECTION,
    tempCoeSelection: params,
  };
};

export const receiveProductOfferings = (
  productOfferingsList: Episode.ProductOffering[]
) => {
  return {
    type: RECEIVE_PRODUCT_OFFERINGS,
    productOfferingsList,
  };
};

export const receiveHospitalTravelTime = (
  hospitalId: string,
  travelTime: string
) => {
  return {
    type: RECEIVE_HOSPITAL_TRAVEL_TIME,
    hospitalId,
    travelTime,
  };
};

export const receiveBundlePrices = (bundlePrices: []) => {
  return {
    type: RECEIVE_BUNDLE_PRICES,
    bundlePrices,
  };
};

export const receiveBundlePricesLoading = (bundlePricesLoading: boolean) => {
  return {
    type: RECEIVE_BUNDLE_PRICES_LOADING,
    bundlePricesLoading,
  };
};

export const receiveBundlePricesError = (error) => {
  return {
    type: RECEIVE_BUNDLE_PRICES_ERROR,
    error,
  };
};
