import { TestID } from './constants';

import { onPressWithTracking } from './helpers';

import {
  useState,
  useWindowDimensions,
  useSelector,
  useSession,
} from './hooks';

import {
  BenefitsCostIcon,
  BenefitsQualityIcon,
  BenefitsSpecialistIcon,
  CarrumBenefitsCards,
  CardText,
  CardTextWrapper,
  CardWrapper,
  CareModal,
  CarrumBenefits,
  CostModal,
  GoldCheckMark,
  GoldCheckMarkWrapper,
  GoldExperienceGoldText,
  GoldExperienceGoldTextWrapper,
  GoldExperienceText,
  GoldExperienceTextWrapper,
  GoldExperienceWrapper,
  GoldLaurelsLeft,
  GoldLaurelsRight,
  hasLaurels,
  LaurelWrapper,
  QualityModal,
} from './styles';

import {
  CarrumGoldExperienceBenefitsProps,
  SelectedModal,
  State,
} from './types';

const CarrumGoldExperienceBenefits = ({
  disabled = false,
  mode = 'light',
}: CarrumGoldExperienceBenefitsProps) => {
  const [selectedModal, setSelectedModal] = useState(SelectedModal.None);
  const { user } = useSession();

  const procedure = useSelector(
    (state: State.RootState) => state.episode?.procedure || ''
  );

  const screenWidth = useWindowDimensions().width;
  const hasVisibleLaurels = hasLaurels(screenWidth);
  const isPartnerClient = user?.employer?.type === 'partner';

  const getIsCostClickDisabled = (disabled) =>
    disabled || !procedure?.showCostEstimate;

  const handleBenefitCardClick = (selectedModal, title) =>
    onPressWithTracking(() => setSelectedModal(selectedModal), { title });

  const onHideModal = () => setSelectedModal(SelectedModal.None);

  return (
    <>
      <CarrumBenefits>
        {hasVisibleLaurels && <Laurels />}

        <CarrumBenefitsCards>
          <CarrumBenefitsCard
            text="Quality advantages"
            disabled={disabled}
            Image={BenefitsQualityIcon}
            topic="Quality"
            type={mode}
            onPress={handleBenefitCardClick}
          />
          <CarrumBenefitsCard
            text="Dedicated care specialist"
            disabled={disabled}
            Image={BenefitsSpecialistIcon}
            topic="Care"
            type={mode}
            onPress={handleBenefitCardClick}
          />
          <CarrumBenefitsCard
            text={
              isPartnerClient ? 'No surprise bills' : 'Lower out-of-pocket cost'
            }
            disabled={getIsCostClickDisabled(disabled)}
            Image={BenefitsCostIcon}
            topic="Cost"
            type={mode}
            onPress={handleBenefitCardClick}
          />
        </CarrumBenefitsCards>
        <GoldExperienceLabel />
      </CarrumBenefits>

      <>
        <QualityModal
          isVisible={selectedModal === SelectedModal.Quality}
          onClose={onHideModal}
        />

        <CareModal
          isVisible={selectedModal === SelectedModal.Care}
          onClose={onHideModal}
        />

        <CostModal
          isVisible={selectedModal === SelectedModal.Cost}
          onClose={onHideModal}
        />
      </>
    </>
  );
};

const CarrumBenefitsCard = ({
  disabled,
  Image,
  topic,
  text,
  type,
  onPress,
}) => {
  return (
    <CardWrapper
      mode={type}
      disabled={disabled}
      onPress={onPress(SelectedModal[topic], text)}
      testID={TestID.BenefitModal[`BenefitCardFor${topic}`]}
    >
      <Image mode={type} />

      <CardTextWrapper>
        <CardText mode={type}>{text}</CardText>
      </CardTextWrapper>
    </CardWrapper>
  );
};

const GoldExperienceLabel = () => (
  <GoldExperienceWrapper>
    <GoldCheckMarkWrapper>
      <GoldCheckMark />
    </GoldCheckMarkWrapper>

    <GoldExperienceTextWrapper>
      <GoldExperienceText>Carrum</GoldExperienceText>
    </GoldExperienceTextWrapper>

    <GoldExperienceGoldTextWrapper>
      <GoldExperienceGoldText>Gold</GoldExperienceGoldText>
    </GoldExperienceGoldTextWrapper>

    <GoldExperienceTextWrapper>
      <GoldExperienceText>Experience</GoldExperienceText>
    </GoldExperienceTextWrapper>
  </GoldExperienceWrapper>
);

const Laurels = () => (
  <>
    <LaurelWrapper placement="left">
      <GoldLaurelsLeft />
    </LaurelWrapper>
    <LaurelWrapper placement="right">
      <GoldLaurelsRight />
    </LaurelWrapper>
  </>
);

export default CarrumGoldExperienceBenefits;
