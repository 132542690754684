import styled, { css } from 'styled-components/native';

import { Text } from 'app/components/Common/Styled';
import Anchor from 'app/components/Common/Anchor';

export const LegaleseLink = styled(Anchor).attrs(({ theme }) => {
  return {
    titleStyle: { fontSize: theme.fontSizes.small },
  };
})``;

export const LegaleseText = styled(Text)`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.small}px;
    padding: ${theme.spacing / 4}px;
    text-align: center;
  `}
`;
