import { formatPhoneNumber } from 'app/util/methods';
/**
 * Determine whether an input string is a valid phone number.
 *
 * @param    {string}  phoneNumber  the phone number to validate
 *
 * @returns  {string}               whether the input phone number is valid
 */
export function isValidPhoneNumber(phoneNumber: string) {
  if (typeof phoneNumber !== 'string') return false;

  return phoneNumber.replace(/\D/g, '').length >= 10;
}

export function maskPhoneNumber(phoneNumber, prevValue) {
  const strippedNumber = phoneNumber.replace(/\D/g, '');
  const strippedPrevNumber = prevValue?.replace(/\D/g, '');

  const numberLength = strippedNumber?.length;

  if (numberLength === 0) return '';
  // handle deletion of special characters
  if (
    phoneNumber?.length < prevValue?.length &&
    strippedNumber?.length === strippedPrevNumber?.length
  )
    return formatPhoneNumber(strippedNumber.slice(0, -1));
  if (numberLength > 10) {
    return prevValue;
  } else {
    return formatPhoneNumber(phoneNumber);
  }
}
