import camelCase from 'camel-case';
import snakeCase from 'snake-case';
import { formatDateRailsToDisplay, formatDateDisplayToRails } from '../methods';
import { CDN_URL, LOCAL_NETWORK } from '../constants';

export function formatData(data, type) {
  if (typeof data !== 'object') {
    return data;
  }

  const formattedData = {};
  const attributes = { ...(data?.attributes || data) };

  Object.keys(attributes).map((key) => {
    const formattedKey = camelCase(key);
    if (isDateString(attributes[key]) && isRailsDateFormat(data[key])) {
      // format dates from YYYY-MM-DD to MM-DD-YYYY format
      attributes[key] = formatDateRailsToDisplay(attributes[key]);
    } else if (attributes[key] && isDateField(formattedKey)) {
      attributes[key] = new Date(attributes[key]);
    } else if (Array.isArray(attributes[key])) {
      attributes[key] = attributes[key].map((item) =>
        formatData(item, key.replace('participants', 'users'))
      );
    } else if (attributes[key] && typeof attributes[key] === 'object') {
      attributes[key] = formatData(attributes[key]);
    }

    formattedData[formattedKey] = attributes[key];
  });

  if (data?.id) {
    formattedData.id = data.id;
  }

  if (formattedData.images && typeof formattedData.images === 'object') {
    Object.keys(formattedData.images).forEach((key) => {
      formattedData.images[key] = carrumImagePath(
        formattedData.images[key],
        type || data.type
      );
    });
  }

  if (formattedData.profileImage) {
    formattedData.profileImage = carrumImagePath(
      formattedData.profileImage,
      type || data.type || 'users'
    );
  }

  return formattedData;
}

export function unformatObject(data) {
  if (typeof data !== 'object') {
    return data;
  }

  const unformattedObject = {};

  Object.keys(data).forEach((key) => {
    const unformattedKey = snakeCase(key);
    if (isDateString(data[key]) && !isRailsDateFormat(data[key])) {
      // format dates from YYYY-MM-DD to MM-DD-YYYY format
      unformattedObject[unformattedKey] = formatDateDisplayToRails(data[key]);
    } else if (Array.isArray(data[key])) {
      unformattedObject[unformattedKey] = data[key].map(unformatObject);
    } else if (
      (unformattedObject[unformattedKey] && isDateField(key)) ||
      data[key] instanceof Date
    ) {
      unformattedObject[unformattedKey] = data[key].toISOString();
    } else if (data[key] && typeof data[key] === 'object') {
      unformattedObject[unformattedKey] = unformatObject(data[key]);
    } else {
      unformattedObject[unformattedKey] = data[key];
    }
  });

  return unformattedObject;
}

function isDateField(key) {
  return (
    ['createdAt', 'updatedAt', 'startedOn', 'completedOn'].indexOf(key) !== -1
  );
}

function isDateString(value) {
  if (typeof value !== 'string') return false;
  if (value.length !== 10) return false;

  const parts = value.split(/\/|-/);
  return parts.length === 3 && parts[0].length === 4;
}

/**
 * Checks if a data array is formatted
 * for the Rails API (YYYY-MM-DD).
 * @param  {array}   dateElements an array of date fields
 * @return {boolean}              true if the date array fields are in the correct order for the API
 */
function isRailsDateFormat(value) {
  if (!value) return;

  const elements = /-/.test(value) ? value.split('-') : value.split('/');
  return elements[0].length === 4;
}

/**
 * Prefixes a URL if it is a relative path.
 *
 * @param {string} path The image path.
 * @param {string} namespace The namespace in the CDN.
 * @return {string} A URL for an image.
 */
function carrumImagePath(path, namespace) {
  if (!path) return '';
  if (!/^(http|data:)/.test(path)) return `${CDN_URL}/${namespace}/${path}`;

  return path.replace(/^http:\/\/localhost/, LOCAL_NETWORK);
}
