import { Button as RNEButton, Text } from 'react-native-elements';
import styled from 'styled-components/native';

import { View } from 'app/components/Common/Styled';

interface ButtonProps {
  isFirstButton?: boolean;
}

export const Button = styled(RNEButton).attrs<ButtonProps>(
  ({ isFirstButton, theme: { breakpoints, spacing, windowDimensions } }) => {
    const { height, width } = windowDimensions;

    const isMobile = width < breakpoints.small;
    const isShortScreen = height < breakpoints.shortScreen;
    const isNarrowOrShort = isMobile || isShortScreen;

    return {
      // Flex and spacing styles need to be applied to the container, not the Button's `style` prop
      containerStyle: {
        // In the grid view, there are elements above the button, so it needs margin-top
        marginTop: !isNarrowOrShort ? spacing : 0,

        // In the tab view, the buttons should be equal widths with 7px between them
        flex: isNarrowOrShort ? 1 : 'initial',
        flexBasis: isNarrowOrShort ? 0 : 'auto',
        marginRight: isFirstButton && isNarrowOrShort ? spacing : 0,
      },
    };
  }
)<ButtonProps>``;

export const ButtonText = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamilyMedium};
  font-size: ${({ theme }) => theme.fontSizes.body3}px;
  padding: ${({ theme }) => `${theme.spacing / theme.spacing}px 0`}; // 1px 0
`;

export const TextRow = styled(View)`
  flex-direction: row;
`;
