import { DeviceType, errorMessages, phoneNumber, TestID } from './constants';

import { isPresent, isFormat } from 'app/util/validations';
import { getDeviceTypeAsync } from 'expo-device';
import * as Linking from 'expo-linking';

export { callCarrum } from 'app/util/call';

export { getSavedSingleClickRegistrations } from 'app/util/userUtils';

export const getStepOneValidations = (bypassSsnRegistration) => {
  const lastFourTypeKey = bypassSsnRegistration
    ? 'memberId'
    : 'socialSecurityNumber';

  return {
    firstName: [isPresent(errorMessages.firstName.missing)],
    lastName: [isPresent(errorMessages.lastName.missing)],
    dateOfBirth: [isPresent(errorMessages.dateOfBirth.missing)],
    employerRegistrationId: [
      isPresent(errorMessages.employerRegistrationId[lastFourTypeKey].missing),
      isFormat(
        /\d{4}/,
        errorMessages.employerRegistrationId[lastFourTypeKey].invalid
      ),
    ],
  };
};

/** Whether the device is a phone or tablet. */
export const isMobileDevice = async () => {
  const deviceType = await getDeviceTypeAsync();
  return deviceType === DeviceType.PHONE || deviceType === DeviceType.TABLET;
};

export { logEvent } from 'app/util/analytics';

/** If on a mobile device, launch a call. */
export const makeCall = async () => {
  if (!isMobileDevice) return;

  Linking.openURL(`tel:${phoneNumber}`);
};

export { trimWhitespace } from 'app/util/methods';
