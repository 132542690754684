import updateUserDetails from './update-user-details';

/**
 * Makes a PATCH request to the Core Service Users API to save insurance details
 * on the user profile property.
 *
 * @param {object} insuranceUpdates Updates to the user's insurance information that should be saved.
 * @return {promise} A promise that resolves after updating the user record.
 */
export default (insuranceUpdates) => {
  return async (dispatch, getState) => {
    const { eligiblePatient, profile } = getState().session.user;

    /**
     * Copy existing insurance details from the eligible patient record and
     * existing profile data (if any), and apply the given updates.
     */
    profile.insurance = {
      ...eligiblePatient.insurance,
      ...profile.insurance,
      ...insuranceUpdates,
    };

    await dispatch(updateUserDetails({ profile }));
  };
};
