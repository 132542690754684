import React from 'react';

import {
  GoldButton,
  SilverButton,
} from 'app/components/CarrumSilverExperienceTable/CTAs';
import { CTACallbacks } from 'app/components/CarrumSilverExperienceTable/types';

import { ButtonRow } from './styles';

export const NarrowScreenCTAs = ({ onClose, onShowSilver }: CTACallbacks) => {
  return (
    <ButtonRow>
      <GoldButton onPress={onClose} />
      <SilverButton onPress={onShowSilver} />
    </ButtonRow>
  );
};

export default NarrowScreenCTAs;
