import styled, { css } from 'styled-components/native';

import { Platform } from './constants';

import { default as AccountSettingsForm } from 'app/components/Common/AccountSettingsForm';
import { ScrollView } from 'app/components/Common/Styled';
import { Text } from 'app/components/Common/Styled';
import { View } from 'app/components/Common/Styled';

export { default as SaveProgress } from './SaveProgress';

export { ProgressNav } from 'app/components/Common/ProgressNav';
export { Route } from '@cross-platform/react-router-native';
export { Switch } from '@cross-platform/react-router-native';

const PlatformSpecificView = Platform.OS === 'web' ? View : ScrollView;

export const CompleteYourProfileStyles = {
  Wrapper: styled(View)`
    flex: 1 1 auto;
  `,
};

export const SaveProgressStyles = {
  Form: AccountSettingsForm,

  FormWrapper: styled(PlatformSpecificView).attrs({
    keyboardAware: true,
  })`
    ${({ theme }) => css`
      z-index: ${theme.zIndexes.overlay};
      padding: 0 ${theme.isNarrow ? theme.spacing : 0}px;
      padding-bottom: ${theme.isNarrow ? 5 * theme.spacing : 0}px;
    `}
  `,

  Header: {
    Text: styled(Text).attrs({
      size: 'body1',
    })`
      margin-top: ${({ theme }) => theme.spacing}px;
    `,

    Title: styled(Text).attrs({
      align: 'center',
      medium: true,
      size: 'h1',
    })``,

    Wrapper: styled(View)`
      padding: ${({ theme }) => theme.spacing}px;
    `,
  },

  InputWrapper: styled(View)`
    margin-top: ${({ theme }) => theme.spacing * 2}px;
  `,

  Wrapper: styled(View)`
    ${({ theme }) => css`
      max-width: ${theme.breakpoints.xsmall}px;
      width: 100%;
      flex: 1 0 auto;
      height: 100%;
      margin: 0 auto;
    `}
  `,
};
