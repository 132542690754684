import { parameterize } from 'app/util/methods';
import { Question } from 'types/submission';

import camelCase from 'camel-case';
export { debounce } from 'lodash';
export { parameterize };
export { withRouter } from '@cross-platform/react-router-native';

const capitalizeWord = (word) => word.charAt(0).toUpperCase() + word.slice(1);

/**
 * Calculate the chain of linked questions, omitting those that are not
 * relevant based on responses.
 */
export const buildLinkedQuestionsList = (submission, values) => {
  const questionList = [...submission.formQuestions];
  const result = [questionList[0]];

  const addNextQuestion = () => {
    const findNextQuestion = (q) => {
      if (q.nextQuestions?.length) {
        return findNextLinkedQuestion(q, submission.formQuestions, values);
      } else {
        return findNextUnlinkedQuestion(q, submission.formQuestions);
      }
    };

    const lastQuestion = result.slice(-1)[0];
    const nextQuestion = findNextQuestion(lastQuestion);
    if (!nextQuestion || lastQuestion?.label === nextQuestion?.label)
      return result;
    result.push({ ...nextQuestion });

    return addNextQuestion();
  };

  addNextQuestion();
  return result;
};

/**
 * Capitalize the first letter of the string and the first letter after each
 * space.
 * 
 * > NOTE: This transformation was added per product request for TEC-2326.
 * 
 * @param   input  string to modify

 * @return         modified string
*/
export const capitalizeFirstLetters = (input) =>
  input
    .split(' ')
    .map((substring) => {
      const word = substring.toLowerCase();
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');

/**
 * Convert string to Pascal case.
 *
 * @param   input  string to modify
 *
 * @return         string with pascal casing
 */
export const convertToPascalCase = (word) => capitalizeWord(camelCase(word));

/**
 * Some questions are conditional based on the given response,
 * so attempt to find a previous question that presents the current
 * question conditionally.
 */
export const findPreviousLinkedQuestion = (
  question: Question,
  questions: Question[] = []
) =>
  questions.find(({ nextQuestions }) =>
    nextQuestions?.find((q) => q?.label === question?.label)
  );

/**
 * Some questions are conditional based on the given response,
 * so attempt to find the next question that presents based on
 * the answer to the current question.
 */
export const findNextLinkedQuestion = (
  question: Question,
  questions: Question[] = [],
  values?: []
) => {
  const currentIndex = questions.findIndex(
    ({ label }) => label === question.label
  );
  const nextQuestions = question.nextQuestions || [];
  // we have to compare to 2 possible locations because of the way that redux-forms stores form values
  const nextQuestion = nextQuestions.find(({ response }) =>
    values
      ? response === values[parameterize(question.label)]
      : response === question.response
  );

  return questions
    .slice(currentIndex)
    .find(({ label }) => label === nextQuestion?.label);
};

/**
 * Some questions are conditional based on the given response,
 * so attempt to find the next question that will always present
 * regardless of the answer to the current question.
 */
export const findNextUnlinkedQuestion = (
  question: Question,
  questions: Question[] = []
) => {
  const linkedQuestion = findPreviousLinkedQuestion(question, questions);
  const currentIndex = questions.findIndex((q) => q?.label === question?.label);

  if (!linkedQuestion) return questions[currentIndex + 1];

  return questions
    .slice(currentIndex)
    .find(
      ({ label }) =>
        linkedQuestion.nextQuestions
          .map(({ label }) => label)
          .indexOf(label) === -1
    );
};

/**
 * Return the current question based on state variables: `currentOrder` and
 * `currentQuestionIndex`.
 */
export const getCurrentQuestion = (submission, index) => {
  if (!submission) return;

  return submission.formQuestions[index];
};

/**
 * Get the current question number indexed by 1.
 */
export const getCurrentQuestionNumber = (
  question: Question,
  questions: Question[] = []
) => {
  return questions.indexOf(question) + 1;
};

/**
 * Get the current total number of answered questions in the form.
 */
export const getCurrentQuestionsAnsweredNumber = (values: Question[] = []) => {
  return Object.keys(values).filter((key) => ![null, ''].includes(values[key]))
    .length;
};

/**
 * Return object with custom styles as required per input type.
 */
export const getCustomStyles = (inputType) => ({
  container:
    inputType === 'confirmation'
      ? { flexDirection: 'column-reverse', marginLeft: 0 }
      : {},
  options: inputType === 'checkbox' ? { flexDirection: 'column' } : {},
});

/** Return properly formatted array of input options. */
export const getInputOptions = (options) => {
  return (options || []).map((value) => ({
    label: value,
    value,
  }));
};

/**
 * Get the current total number of questions in the form.
 */
export const getTotalQuestionCount = (questions: Question[] = []) => {
  return questions.length;
};

export const isFormValid = (errors) => !Object.values(errors).some((e) => !!e);

/**
 * Formats an object from an array of form questions such that the keys are a parameterized label
 * and the values are the responses or an initialize value.
 *
 * This is necessary because of the use of redux-forms, which expects an object in this manner.
 *
 * @example
 * parameterizeFormForReduxForms([{ label: 'My Question', response: 'My Response' }]);
 * // { 'my-question': 'My Response' };
 */
export const parameterizeFormForReduxForms = (
  questions: Question[],
  patientName: string
) => {
  const parameterizedForm = {};

  questions.forEach(({ inputType, label, response }) => {
    const key = parameterize(label);

    if (inputType === 'signature') {
      return (parameterizedForm[key] = response === true ? patientName : '');
    }

    if (inputType === 'acknowledgement') {
      return (parameterizedForm[key] = true);
    }

    if (inputType === 'date' && response) {
      const dateParts = response.split('/');
      const formattedDate = `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`;
      return (parameterizedForm[key] = formattedDate);
    }

    if (typeof response !== 'undefined') {
      return (parameterizedForm[key] = response);
    }

    parameterizedForm[key] = inputType === 'checkbox' ? [] : '';
  });

  return parameterizedForm;
};

/**
 * Validate a single form question. Return an error string if the question is
 * invalid, or `null` if it is valid.
 */
export const validateQuestion = (
  question: Question,
  values: Question[],
  patientName: string
) => {
  const key = parameterize(question.label);
  const value = values[key];
  const hasValue = Array.isArray(value) ? value.length > 0 : Boolean(value);

  switch (true) {
    // For signature type questions, require that the entered value matches
    // the current patient's name, but make the check case-insensitive due
    // to the unpredictable casing of our patient data.
    case question.inputType === 'signature' &&
      value?.toUpperCase() !== patientName?.toUpperCase():
      return `Please enter "${capitalizeFirstLetters(
        patientName
      )}" to confirm.`;
    case question.required && !hasValue:
      return 'A response is required.';
    default:
      return null;
  }
};

/**
 * Returns true if the given question was shown to the user based on the list
 * of possible questions, their responses, and their conditional next questions.
 */
export const wasQuestionShown = (question: Question, questions: Question[]) => {
  const linkedQuestion = questions.find(({ nextQuestions }) =>
    nextQuestions?.find((nextQuestion) => nextQuestion.label === question.label)
  );

  // If there is no linked questions, then this one is always shown.
  if (!linkedQuestion) return true;

  const selectedQuestionFromThisBranch = linkedQuestion.nextQuestions.find(
    (nextQuestion) => nextQuestion.response === linkedQuestion.response
  );

  return question.label === selectedQuestionFromThisBranch?.label;
};
