import axios from 'axios';

import { CORE_API_URL } from 'app/util/constants';

import {
  receiveEstimatesError,
  receiveHospitalBundlePrices,
  receiveHospitalBundlePricesLoading,
} from './action-types';
import { formatData } from 'app/util/reducerUtils';

export default (hospitalId: string, procedureKey: string) => {
  return async (dispatch, getState) => {
    dispatch(receiveHospitalBundlePricesLoading(true));

    try {
      const { coreToken } = getState().session;
      const response = await axios.get(
        `${CORE_API_URL}/hospitals/${hospitalId}/bundle-prices`,
        {
          params: { procedure_key: procedureKey },
          headers: { Authorization: `Bearer ${coreToken}` },
        }
      );

      dispatch(receiveHospitalBundlePrices(response.data.data.map(formatData)));
    } catch (error) {
      dispatch(
        receiveEstimatesError(
          getState().episode.estimatesError === 'oop'
            ? 'both'
            : 'contractedRate'
        )
      );
    } finally {
      dispatch(receiveHospitalBundlePricesLoading(false));
    }
  };
};
