import React, { useMemo, useState } from 'react';
import { maxBy, minBy } from 'lodash';

import { getOopEstimate } from 'app/util/oop';
import { TestID } from 'app/util/test-id';
import { useEpisode, useHospitalBundlePrices, useOopEstimate } from 'app/hooks';

import {
  EstimateText,
  FooterContainer,
  LearnMoreButton,
  NsaModal,
} from './styles';

export const PricingFooter = () => {
  const [nsaModalIsVisible, setNsaModalIsVisible] = useState(false);

  const { tempCoeSelection } = useEpisode();
  const {
    data: { records: bundlePrices },
    loading: loadingBundlePrices,
    error: bundlePricesError,
  } = useHospitalBundlePrices();

  const { data, loading: loadingOop, error: oopError } = useOopEstimate();
  const { carrumEstimate, nonCarrumEstimate } = getOopEstimate(data);
  const selectedFacility = tempCoeSelection?.facility;
  const selectedProductOffering = selectedFacility?.productOffering;
  const isSilverSelected = selectedProductOffering?.code === 'silver';
  const estimate = isSilverSelected ? nonCarrumEstimate : carrumEstimate;

  const contractedPrice = useMemo(() => {
    if (!bundlePrices?.length) return;
    const rangeMax = Number(
      maxBy(bundlePrices, ({ price }) => price).price
    ).toLocaleString();
    const rangeMin = Number(
      minBy(bundlePrices, ({ price }) => price).price
    ).toLocaleString();
    return rangeMax === rangeMin
      ? `$${rangeMax}`
      : `$${rangeMin} - $${rangeMax}`;
  }, [bundlePrices]);

  // handle loading state state
  if (loadingBundlePrices || loadingOop)
    return <EstimateText>Estimating costs... </EstimateText>;

  return (
    <FooterContainer>
      <EstimateText>
        {oopError
          ? `Sorry, we are unable to calculate your out-of-pocket cost for this provider.`
          : `Your out-of-pocket for this provider is estimated to be $${estimate}.`}
      </EstimateText>
      <EstimateText>
        {bundlePricesError || bundlePrices?.length === 0
          ? `Sorry, we are unable to display the contracted rate for this provider.`
          : `Carrum contracted rate is ${contractedPrice}.`}
      </EstimateText>
      <LearnMoreButton
        testID={TestID.SelectYourDoctor.OpenNsaModalButton}
        title="Learn More"
        onPress={() => {
          setNsaModalIsVisible(true);
        }}
      />
      <NsaModal
        onClose={() => setNsaModalIsVisible(false)}
        visible={nsaModalIsVisible}
      />
    </FooterContainer>
  );
};

export default PricingFooter;
