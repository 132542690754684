import {
  Routes,
  routeToFAQ,
} from 'app/components/Common/ProgressNav/constants';

import { receiveSetCallUsModalVisibility } from 'app/components/Common/ProgressNav/helpers';

import {
  useDispatch,
  useHistory,
} from 'app/components/Common/ProgressNav/hooks';

import {
  FeatureSwitch,
  ActionIconsStyles as Styled,
} from 'app/components/Common/ProgressNav/styles';

import { IconType } from 'app/components/Common/ProgressNav/types';

export const ActionIcons = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Styled.Wrapper>
      <Styled.Link
        title="About Us"
        iconName="info"
        iconType={IconType.Material}
        onPress={routeToFAQ}
      />

      <FeatureSwitch name="pre-episode-messaging" state="off">
        <Styled.Link
          title="Message Us"
          iconName="commenting"
          iconType={IconType.FontAwesome}
          onPress={() => {
            history.push(`/${Routes.Messages}`);
          }}
        />
      </FeatureSwitch>

      <Styled.Link
        title="Talk to Us"
        iconName="phone"
        iconType={IconType.Material}
        onPress={() => {
          dispatch(receiveSetCallUsModalVisibility(true));
        }}
      />
    </Styled.Wrapper>
  );
};
