import React from 'react';

import Cell from './Cell';
import { cellFlexSizes, RowWrapper } from './styles';

import { experienceModalContent } from '../data';

const CareRow = () => {
  const { columns, rowTitles } = experienceModalContent;
  const [_qualityTitle, careTitle, _costTitle] = rowTitles;
  const [
    { care: GoldComponent }, // { details, header }
    { care: SilverComponent }, // { details, header }
  ] = columns;
  const [cell1Flex, cell2Flex, cell3Flex] = cellFlexSizes;

  return (
    <RowWrapper>
      <Cell flex={cell1Flex} header={careTitle} borderTop borderRight />
      <Cell flex={cell2Flex} {...GoldComponent} borderTop borderRight />
      <Cell flex={cell3Flex} {...SilverComponent} borderTop />
    </RowWrapper>
  );
};

export default CareRow;
