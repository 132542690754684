import { breakpoints, defaultFormState, validations } from './constants';

import { getUserDetails, updateUserDetails } from './helpers';

import {
  useCallback,
  useDispatch,
  useEffect,
  useEpisode,
  useHistory,
  useRef,
  useSession,
  useState,
  useValidations,
  useWindowDimensions,
} from './hooks';

import {
  AccountSettingsFormStyles,
  InputField,
  LegalContent,
  StickyNavFooter,
  TogglePasswordText,
} from './styles';

import { NavigationMode, AccountSettingsValidationHook } from './types';

const AccountSettingsForm = ({
  accountSettingsWrapper,
  formWrapper,
  getFormValuesFromUser,
  headerComponent,
  inputWrapper,
  nextRoute,
  submitButtonText = 'Save',
  testIDRoot,
  enableStickyNavFooter = true,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { refresh } = useEpisode();
  const { user } = useSession();

  const [formValues, setFormValues] = useState(defaultFormState);
  const [showPassword, setShowPassword] = useState(false);
  const nextRouteRef = useRef(nextRoute);

  /**
   * Update the value of `nextRouteRef` when the `nextRoute` prop changes.
   *
   * This allows us to support scenarios where the `nextRoute` prop changes
   * during the course of submitting the form.
   *
   * Without these two hooks, the `nextRoute` variable may become stale
   * by the time navigation occurs.
   */
  useEffect(() => {
    nextRouteRef.current = nextRoute;
  }, [nextRoute]);

  useEffect(() => {
    if (user)
      setFormValues({
        ...defaultFormState,
        ...getFormValuesFromUser(user),
      });
  }, [user]);

  const { errors, isValid }: AccountSettingsValidationHook = useValidations({
    formValues,
    validations,
  });

  const AccountSettingsWrapper = accountSettingsWrapper;
  const FormWrapper = formWrapper;
  const HeaderComponent = headerComponent;
  const InputWrapper = inputWrapper;

  const isNarrow = useWindowDimensions().width <= breakpoints.xsmall;
  const hasStickyNavFooter = isNarrow && enableStickyNavFooter;
  const userHasPreviouslyAcceptedTerms = !!user.acceptedTerms;
  const userHasPassword = !!user.verified;

  /**
   * Update the user record with the current form values if there are no
   * errors.
   */
  const updateUserAndGoToNextPage = useCallback(async () => {
    if (!isValid) return;

    const { email, password } = formValues;

    // page content displays legal content to ensure user accepts terms
    const userDetails = getUserDetails(
      email,
      password,
      userHasPreviouslyAcceptedTerms
    );

    const isSuccess = await dispatch(updateUserDetails(userDetails));

    if (!isSuccess) return;

    /**
     * Saving this form is associated with the completion of the
     * "create_account" task, which could cause the value of
     * `nextRoute` to change.
     *
     * Because this happens asynchronously, we refresh the episode
     * before we navigate to make sure that the `nextRoute` is the
     * most up-to-date value.
     */
    await refresh();

    // Navigate to the `nextRoute` prop.
    history.push(nextRouteRef.current);
  }, [formValues, history, isValid]);

  return (
    <>
      <AccountSettingsWrapper testID={testIDRoot['AccountSettings']}>
        {HeaderComponent}

        <FormWrapper>
          <InputWrapper>
            <InputField
              label="Email"
              autoCorrect={false}
              error={errors.email}
              input={{
                name: 'email',
                onChange: (email) => setFormValues({ ...formValues, email }),
                value: formValues.email || '',
              }}
              enterKeyHint="next"
              inputMode="email"
            />

            <InputField
              label={userHasPassword ? 'Update Password' : 'Create Password'}
              error={errors.password}
              input={{
                name: 'password',
                onChange: (password) => {
                  setFormValues({ ...formValues, password });
                },
                value: formValues.password || '',
              }}
              enterKeyHint="next"
              rightIcon={
                <TogglePasswordText
                  onPress={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? 'HIDE' : 'SHOW'}
                </TogglePasswordText>
              }
              secureTextEntry={!showPassword}
            />

            <InputField
              label={
                userHasPassword ? 'Confirm Password Update' : 'Confirm Password'
              }
              error={errors.passwordConfirmation}
              input={{
                name: 'passwordConfirmation',
                onChange: (passwordConfirmation) => {
                  setFormValues({ ...formValues, passwordConfirmation });
                },
                value: formValues.passwordConfirmation || '',
              }}
              enterKeyHint="next"
              rightIcon={
                <TogglePasswordText
                  onPress={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? 'HIDE' : 'SHOW'}
                </TogglePasswordText>
              }
              secureTextEntry={!showPassword}
            />

            {!hasStickyNavFooter && (
              <ContinueButton
                disabled={!isValid}
                onPress={updateUserAndGoToNextPage}
                testID={testIDRoot['AccountSettingsInlineSubmitButton']}
                title={submitButtonText}
              />
            )}

            {!userHasPreviouslyAcceptedTerms && (
              <LegalContent buttonText={submitButtonText} />
            )}
          </InputWrapper>
        </FormWrapper>
      </AccountSettingsWrapper>

      {hasStickyNavFooter && (
        <StickyNavFooter
          navigationConfig={[
            {
              disabled: !isValid,
              onPress: updateUserAndGoToNextPage,
              testID: testIDRoot['AccountSettingsNavFooterSubmitButton'],
              text: submitButtonText,
            },
          ]}
          navigationMode={NavigationMode.Centered}
          testID={testIDRoot['AccountSettingsNavFooter']}
        />
      )}
    </>
  );
};

const ContinueButton = ({ disabled, onPress, title }) => (
  <AccountSettingsFormStyles.SubmitButton.Wrapper>
    <AccountSettingsFormStyles.SubmitButton.Button
      disabled={disabled}
      onPress={onPress}
      title={title}
    />
  </AccountSettingsFormStyles.SubmitButton.Wrapper>
);

export default AccountSettingsForm;
