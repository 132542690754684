import React from 'react';
import PropTypes from 'prop-types';
import Svg, { Circle } from 'react-native-svg';

interface ProgressNavIncompleteProps {
  size: number;
}

/**
 * Renders the Progress Nav Complete Icon as an SVG.
 */
const ProgressNavIncomplete = ({ size }: ProgressNavIncompleteProps) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none">
    <Circle cx="16" cy="16" r="11" fill="#3A4750" />
  </Svg>
);

export default ProgressNavIncomplete;
