import React from 'react';

import { AlignmentWrapper, Container } from './styles';

interface CenteredScrollScreenProps {
  children?: any;
  maxSize?: 'xsmall' | 'small' | 'medium' | undefined;
  testID?: string;
}

const CenteredScrollScreen = React.forwardRef(
  (
    { children, maxSize = 'xsmall', testID = '' }: CenteredScrollScreenProps,
    ref
  ) => (
    <Container ref={ref} testID={testID}>
      <AlignmentWrapper maxSize={maxSize}>{children}</AlignmentWrapper>
    </Container>
  )
);

CenteredScrollScreen.displayName = 'CenteredScrollScreen';

export default CenteredScrollScreen;
