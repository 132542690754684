import { Episode } from './types';

export { REQUEST_RECEIVED_TEXT } from 'app/util/constants';
export { TestID } from 'app/util/test-id';
export { default as theme } from 'app/util/theme';

export const contactMethodOptions = [
  {
    label: 'Phone',
    value: Episode.ContactMethod.Phone,
  },
  {
    label: 'In-app messages',
    value: Episode.ContactMethod.Email,
  },
];
