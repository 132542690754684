import { useSelector } from 'react-redux';

import { State } from 'types/state';

const useUi = () => {
  const { ui } = useSelector((store: State.RootState) => store);

  return ui;
};

export default useUi;
