import React from 'react';
import { useWindowDimensions } from 'react-native';

import theme from 'app/util/theme';

import {
  PreTitleText,
  TitleSection,
  TitleSubRow,
  TitleText,
  SilverText,
  SilverBackground,
} from './styles';

const ExperienceHeader = () => {
  const { width: screenWidth } = useWindowDimensions();

  const isMobile = screenWidth < theme.breakpoints.small;

  return (
    <>
      <PreTitleText>Looking for something closer?</PreTitleText>

      <TitleSection>
        {isMobile ? (
          <>
            <TitleSubRow>
              <TitleText>You can choose a local</TitleText>
            </TitleSubRow>

            <TitleSubRow>
              <SilverText>Carrum&nbsp;</SilverText>

              <SilverBackground>Silver</SilverBackground>

              <TitleText>&nbsp;Experience</TitleText>
            </TitleSubRow>
          </>
        ) : (
          <TitleSubRow>
            <TitleText>You can choose a local&nbsp;</TitleText>

            <SilverText>Carrum&nbsp;</SilverText>

            <SilverBackground>Silver</SilverBackground>

            <TitleText>&nbsp;Experience</TitleText>
          </TitleSubRow>
        )}
      </TitleSection>
    </>
  );
};

export default ExperienceHeader;
