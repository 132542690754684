import styled, { css } from 'styled-components/native';

import TrackableButton from '../TrackableButton';

interface ButtonWrapperProps {
  theme: any;
  isActive: boolean;
  isFinalOption: boolean;
}

const getButtonTitleStyle = ({ theme, isActive, isFinalOption }) => ({
  color: isActive ? theme.colors.white : theme.colors.primary,
  textTransform: 'none',
});

const getButtonStyle = ({ theme, isActive, isFinalOption }) => ({
  backgroundColor: isActive ? theme.colors.primary : theme.colors.white,
  marginBottom: isFinalOption ? 0 : theme.spacing,
});

export const ButtonWrapper = styled(TrackableButton).attrs(
  ({ theme, isActive, isFinalOption }: ButtonWrapperProps) => ({
    buttonStyle: getButtonStyle({ theme, isActive, isFinalOption }),
    disabledStyle: getButtonStyle({ theme, isActive, isFinalOption }),
    disabledTitleStyle: getButtonTitleStyle({ theme, isActive, isFinalOption }),
    titleStyle: getButtonTitleStyle({ theme, isActive, isFinalOption }),
    buttonContainerStyle: {},
  })
)<ButtonWrapperProps>`
  ${({ theme }) => css`
    border-radius: ${theme.spacing / 4}px;
  `};
`;
