import React from 'react';

import { Hoverable } from 'react-native-web-hover';

import { ResponsivePressable } from 'app/components/Common/ResponsivePressable';
import { TestID } from 'app/util/test-id';

import { SuggestionText, SuggestionWrapper } from './styles';

interface SearchSuggestionProps {
  /**
   * A search suggestion rendered by a `<FlatList />`.
   */
  item: string[];
  /**
   * A callback to call when pressing an item in the list.
   */
  onPress: () => void;
  /**
   * If true, highlights the search suggestion.
   */
  selected: boolean;
}

const SearchSuggestion = ({
  item,
  onPress,
  selected,
}: SearchSuggestionProps) => {
  return (
    <Hoverable>
      {({ hovered }) => (
        <SuggestionWrapper
          hovered={hovered || selected}
          onPress={onPress}
          testID={TestID.ProcedureSearch.SearchResult}
        >
          <ResponsivePressable onPress={onPress}></ResponsivePressable>
          <SuggestionText hovered={hovered}>{item[0]}</SuggestionText>
        </SuggestionWrapper>
      )}
    </Hoverable>
  );
};

export default SearchSuggestion;
