import React from 'react';

//@ts-ignore
import { useHistory } from '@cross-platform/react-router-native';
import { SelectYourDoctorRoutes } from 'app/components/SelectYourDoctor/routes';
import { Episode } from 'types/episode';

import {
  DoctorImage,
  DoctorImageNameRow,
  DoctorName,
  DoctorTitle,
  ExperienceSection,
  FacilityImage,
  LocationSection,
  TextColumn,
  TopImageSection,
  CardPaddingWrapper,
  CardWrapper,
  LearnMoreButton,
  SpacerColumn,
  View,
} from './styles';
import { isSilverDoctor } from '../../helpers';
import { TestID } from 'app/util/test-id';

interface DoctorCardProps extends Episode.PossiblePhysician {}

const DoctorCard = (doctorCardProps: DoctorCardProps) => {
  const {
    education = [],
    hospital,
    id,
    name = '',
    profileImage = '',
    title = '',
    yearsOfExperience,
  } = doctorCardProps;

  const [medSchool] = education;
  const {
    address,
    address: { city: facilityCity, state: facilityState },
    latitude,
    longitude,
    images: { logo: facilityIcon },
  } = hospital;

  const history = useHistory();

  return (
    <CardWrapper testID={TestID.SelectYourDoctor.DoctorCard}>
      <TopImageSection>
        <FacilityImage source={{ uri: facilityIcon }} />
      </TopImageSection>

      <CardPaddingWrapper>
        <SpacerColumn>
          <View>
            <DoctorImageNameRow>
              <DoctorImage source={{ uri: profileImage }} />
              <TextColumn>
                <DoctorName>{name}</DoctorName>
                <DoctorTitle>{title}</DoctorTitle>
                <DoctorTitle>
                  {facilityCity},&nbsp;{facilityState}
                </DoctorTitle>
                {/* TODO: make this dynamic once API provides data */}
                <DoctorTitle>Speaks: English</DoctorTitle>
              </TextColumn>
            </DoctorImageNameRow>

            <LocationSection
              address={address}
              education={medSchool}
              yearsOfExperience={yearsOfExperience}
              latitude={latitude}
              longitude={longitude}
            />
          </View>
          <View>
            <ExperienceSection isSilver={isSilverDoctor(doctorCardProps)} />

            <LearnMoreButton
              onPress={() =>
                history.push(`${SelectYourDoctorRoutes.Doctors}/${id}`)
              }
              title="Learn More"
              testID={TestID.SelectYourDoctor.DoctorLearnMore}
            />
          </View>
        </SpacerColumn>
      </CardPaddingWrapper>
    </CardWrapper>
  );
};

export default DoctorCard;
