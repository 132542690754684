import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export const featureOn = (features, feature) =>
  (features || []).indexOf(feature) !== -1;
export const featureOff = (features, feature) =>
  (features || []).indexOf(feature) === -1;

const FeatureSwitch = ({ active, children }) => (active ? children : null);

FeatureSwitch.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
};

FeatureSwitch.defaultProps = {
  active: true,
  children: [],
};

const mapStateToProps = ({ session }, { state, name }) => {
  return {
    active:
      state === 'on'
        ? featureOn(session.features, name)
        : featureOff(session.features, name),
  };
};

/**
 * Returns a connected component that can be used to wrap content
 * that loads only when a specific feature flag is on or off.
 * Assumes that the `session` reduce has a `features` property.
 *
 * @example:
 * <FeatureSwitch name="test-feature" state="on">
 *   Show me when 'test-feature' is on
 * </FeatureSwitch>
 * <FeatureSwitch name="test-feature" state="off">
 *   Show me when 'test-feature' is off
 * </FeatureSwitch>
 */
export default connect(mapStateToProps)(FeatureSwitch);
