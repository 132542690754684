import styled from 'styled-components/native';

import { Platform } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

export const Container = styled.View`
  flex: 1 1 auto;
  background-color: ${({ theme }) => theme.colors.sand};
`;

interface RoutesViewProps {
  topPadding: boolean;
}

/**
 * Create a vertical offset to avoid the fixed position header at
 * the top of the application, and offset the amount in iOS to
 * ensure that everything lines up properly.
 */
const navigationOffset = ({ navigationHeight, spacing }: any) =>
  Platform.select({
    default: navigationHeight,
    ios: navigationHeight - spacing * 0.5,
  });

// the `edges` property prevents unwanted space on the bottom and sides; the
// bottom space caused design problems on iOS when a component used both
// StickyNavFooter and KeyboardAvoidingScrollView on iOS
export const RoutesView = styled(SafeAreaView).attrs({
  edges: ['top'],
})<RoutesViewProps>`
  flex: 1 1 auto;
  padding-top: ${({ theme, topPadding }) =>
    topPadding ? navigationOffset(theme) : 0}px;
`;
