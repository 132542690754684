import { TestID } from 'app/components/MySettings/constants';

import {
  getProfileProgress,
  getUserName,
  isEmpty,
  launchCamera,
  launchImageLibrary,
  updateUserDetails,
} from 'app/components/MySettings/helpers';

import {
  useDispatch,
  useEffect,
  useHistory,
  useSession,
  useState,
} from 'app/components/MySettings/hooks';

import {
  Card,
  CardContent,
  Container,
  Modal,
  MySettingsHeader,
  MySettingsMenu,
  Platform,
  SummaryContainer,
  SummaryInnerContainer,
  View,
} from 'app/components/MySettings/styles';

import {
  ModalOptionType,
  MySettingsHomeFormValues,
} from 'app/components/MySettings/types';

const MySettingsHome = () => {
  const loadingTimeout = undefined;
  const savedTimeout = undefined;

  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState<MySettingsHomeFormValues>({});
  const {
    location: { pathname },
  } = useHistory();
  const { user } = useSession();

  const [showAvatarOverlay, setShowAvatarOverlay] = useState(false);

  useEffect(() => {
    return () => {
      clearTimeout(loadingTimeout);
      clearTimeout(savedTimeout);
    };
  }, []);

  /** If in the edit state, persist the changed profile image. */
  const changeProfileImage = (profileImage) => {
    dispatch(updateUserDetails({ profileImage }));
    setShowAvatarOverlay(false);
  };

  /** Clear the stored profile image. */
  const clearProfileImage = () => {
    changeProfileImage('');
  };

  const launchCameraAction = (type) => async () => {
    const action = type === 'camera' ? launchCamera : launchImageLibrary;
    const options = {
      allowsEditing: true,
      aspect: [1, 1],
      mediaTypes: 'Images',
    };

    // @ts-ignore
    const { canceled, assets } = await dispatch(action(options));

    if (!canceled && assets?.[0]?.uri) changeProfileImage(assets[0].uri);
  };

  const launchCameraRoll = launchCameraAction('cameraRoll');
  const launchCamera = launchCameraAction('camera');

  if (isEmpty(user)) return <View />;

  const hasAvatar = Boolean(user.profileImage);

  const isEditing = pathname
    .split('/')
    .some((substring) =>
      ['personal-information', 'account-settings'].includes(
        substring.toLowerCase()
      )
    );

  const progress =
    !isEditing && isEmpty(user.profile) ? 0 : getProfileProgress(formValues);

  const modalOptions = [
    Platform.OS !== 'web' && {
      title: 'Take photo',
      onPress: launchCamera,
      type: 'solid' as ModalOptionType,
    },
    {
      title: 'Pick photo',
      onPress: launchCameraRoll,
      type: 'solid' as ModalOptionType,
    },
    hasAvatar && {
      title: 'Clear photo',
      onPress: clearProfileImage,
      type: 'outline' as ModalOptionType,
    },
  ].filter((isPresent) => isPresent);

  const userName = getUserName(user);

  return (
    <SummaryContainer isEditing={isEditing} testID={TestID.MySettings.Home}>
      <SummaryInnerContainer
        enableOnAndroid
        extraHeight={48}
        keyboardShouldPersistTaps="handled"
      >
        <MySettingsHeader onAvatarEdit={() => setShowAvatarOverlay(true)} />

        <Container>
          <Card>
            <CardContent>
              <MySettingsMenu />
            </CardContent>
          </Card>
        </Container>
      </SummaryInnerContainer>

      <Modal
        isVisible={showAvatarOverlay}
        title={hasAvatar ? 'Change Profile Image' : 'Set Profile Image'}
        onClose={() => setShowAvatarOverlay(false)}
        options={modalOptions}
        testID={TestID.MySettings.HomeImagePicker}
      />
    </SummaryContainer>
  );
};

export default MySettingsHome;
