import { receiveProcedure } from './action-types';
import fetchOfferedProcedure from './fetch-offered-procedure';

/**
 * Finds the correct procedure for a given procedureKey and
 * updates the `procedure` object in the store.
 *
 * @param {integer} clientId - the client ID for the given episode.
 * @param {string} procedureKey - the key for the procedure to find.
 * @return {promise} a promise that resolves after getting the procedure.
 */
export default (procedureKey) => {
  return async (dispatch, getState) => {
    const { possibleProcedures } = getState().episode;

    let procedure = possibleProcedures.find(({ key }) => key === procedureKey);

    if (!procedure)
      procedure = await dispatch(fetchOfferedProcedure(procedureKey));

    dispatch(receiveProcedure(procedure));
  };
};
