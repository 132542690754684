import { KeyboardEvent } from 'react';

export const EventType = {
  ArrowDown: 'ArrowDown',
  ArrowUp: 'ArrowUp',
  Enter: 'Enter',
};

export function isUpKey(event: KeyboardEvent) {
  return (
    event.code === EventType.ArrowUp ||
    event.key === EventType.ArrowUp ||
    event.keyCode === 38
  );
}

export function isDownKey(event: KeyboardEvent) {
  return (
    event.code === EventType.ArrowDown ||
    event.key === EventType.ArrowDown ||
    event.keyCode === 40
  );
}

export function isEnterKey(event: KeyboardEvent) {
  return (
    event.code === EventType.Enter ||
    event.key === EventType.Enter ||
    event.keyCode === 13
  );
}
