import React, { Component } from 'react';
import { Animated, Easing, Image } from 'react-native';

import trophy from 'app/assets/images/trophy.png';
import { useNativeDriver } from 'app/util/constants';

const AnimatedImage = Animated.createAnimatedComponent(Image);

export default class AnimatedTrophy extends Component {
  state = {
    scale: new Animated.Value(0.1),
    rotation: new Animated.Value(0),
  };

  componentDidMount() {
    const config = {
      duration: 500,
      easing: Easing.inOut(Easing.ease),
      useNativeDriver,
    };

    Animated.parallel([
      Animated.timing(this.state.scale, { ...config, toValue: 1 }),
      Animated.timing(this.state.rotation, { ...config, toValue: 2 }),
    ]).start();
  }

  render() {
    const rotate = this.state.rotation.interpolate({
      inputRange: [0, 1],
      outputRange: ['0deg', '360deg'],
    });

    return (
      <AnimatedImage
        source={trophy}
        style={{
          transform: [{ scale: this.state.scale }, { rotate }],
          height: 200,
          width: 200,
        }}
      />
    );
  }
}
