import { TestID } from 'app/components/App/Navigation/constants';

import { extractBranding } from 'app/components/App/Navigation/helpers';

import { useSelector, useSession } from 'app/components/App/Navigation/hooks';

import { ClientLogoStyles as Styled } from 'app/components/App/Navigation/styles';

import { State } from 'app/components/App/Navigation/types';

/** Render a client logo. */
export const ClientLogo = () => {
  const session = useSession();
  const branding = extractBranding(session);
  const hasClientLogo = !!branding;

  if (!hasClientLogo) return null;

  return (
    <Styled.Wrapper testID={TestID.Navigation.ClientLogo}>
      <Styled.Logo maxHeight={36} source={branding} />
    </Styled.Wrapper>
  );
};
