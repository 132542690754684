import styled, { css } from 'styled-components/native';

import { ScrollView, Text, View } from 'app/components/Common/Styled';
import { DefaultTheme } from 'types/theme';

export { StickyNavFooter } from 'app/components/Common/StickyNavFooter';

export {
  DotIndicator,
  StepToCompleteProfile,
  StepToEvaluateCase,
  StepToGetCare,
  StepToRecover,
  StepToSelectDoctor,
} from 'app/components/Common/svg-components';

export { NextStepInfo as NextStep } from './NextStepInfo';

const contentWraps = (theme: DefaultTheme) => {
  return theme.windowDimensions.width < theme.breakpoints.medium;
};

export const NextSteps = styled(View)`
  ${({ theme }) => {
    return css`
      flex-direction: row;
      flex-wrap: ${contentWraps(theme) ? 'wrap' : 'nowrap'};
      justify-content: center;
    `;
  }}
`;

export const NextStepsTitle = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.bodyText};
    font-family: ${theme.fontFamilyMedium};
    font-size: ${theme.rawFontSizes[
      contentWraps(theme) ? 'xxlarge' : 'xxxlarge'
    ]}px;
    margin: 0 auto;
  `}
`;

export const NextStepsWrapper = styled(ScrollView)`
  ${({ theme }) => css`
    background: ${theme.colors.white};
    display: flex;
    margin-top: ${theme.spacing * 1.5}px;
    padding-bottom: ${theme.spacing * 5}px;
    padding-top: ${theme.spacing * 1.5}px;
    width: 100%;
  `}
`;

export const ProcedureConfirmationContainer = styled(View)`
  ${({ theme }) => css`
    align-items: center;
    background: ${theme.colors.sand};
    display: flex;
    height: 100%;
    padding: ${theme.spacing * 2}px 0;
  `}
`;

export const ProcedureLabel = styled(Text)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing * 1.25}px;
  `}
`;

export const ProcedureName = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.bodyText};
    font-family: ${theme.fontFamilyMedium};
    font-size: ${theme.rawFontSizes[
      contentWraps(theme) ? 'xlarge' : 'xxlarge'
    ]}px;
    margin: 0 auto;
    text-align: center;
  `}
`;
