import { HeaderStyles as Styled } from 'app/components/Messages/styles';

import { HeaderProps } from 'app/components/Messages/types';

import { Participant } from './subcomponents';

export const Header = ({ isRendered = false, participants }: HeaderProps) => {
  if (!isRendered) return null;

  return (
    <Styled.Wrapper>
      <Styled.Content>
        <Styled.Title.Wrapper>
          <Styled.Title.Text>My Care Team</Styled.Title.Text>
        </Styled.Title.Wrapper>

        <Styled.Participants horizontal>
          {participants.map(({ id, name, status }) => (
            <Participant key={id} id={id} name={name} status={status} />
          ))}
        </Styled.Participants>
      </Styled.Content>
    </Styled.Wrapper>
  );
};
