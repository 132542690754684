import * as SecureStore from '@cross-platform/expo-secure-store';
import Alert from '@cross-platform/react-native-alert';

import { ONBOARDING } from 'app/util/onboardingUtils';

export const TEST_USER = {
  email: 'm.patient@carrumhealth.com',
  password: 'test1234',
};

export const isJestRunning = process.env.JEST_WORKER_ID !== undefined;

const resetOnboarding = async () => {
  await Object.keys(ONBOARDING).forEach(async (key) => {
    try {
      await SecureStore.deleteItemAsync(key);
    } catch (error) {
      /* Do nothing */
    }

    delete ONBOARDING[key];
  });
};

export const showDevTools = () => {
  Alert.alert('Developer Tools', 'What can we help you with today?', [
    { text: 'Cancel', style: 'cancel' },
    { text: 'Reset Onboarding State', onPress: resetOnboarding },
  ]);
};
