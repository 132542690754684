import React from 'react';
import { useWindowDimensions } from 'react-native';
import { useDispatch } from 'react-redux';

import Modal from 'app/components/Common/Modal';
import Anchor from 'app/components/Common/Anchor';
import { receiveTempCoeSelection } from 'app/actions/episodeActions';
import { useEpisode } from 'app/hooks';
import CarrumSilverExperienceTable from 'app/components/CarrumSilverExperienceTable';
import { EVENTS, logEvent } from 'app/util/analytics';
import { routeToFAQ } from 'app/util/routeUtils';
import theme from 'app/util/theme';

import Header from './ExperienceHeader';
import { NarrowScreenCTAs } from './NarrowScreenCTAs';
import { LearnMoreText, ScrollWrapper } from './styles';

interface ExtendedOptionsModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const ExtendedOptionsModal = ({
  isVisible = true,
  onClose,
}: ExtendedOptionsModalProps) => {
  const dispatch = useDispatch();
  const { tempCoeSelection } = useEpisode();
  const { height: screenHeight, width: screenWidth } = useWindowDimensions();

  const isMobile = screenWidth < theme.breakpoints.small;
  const isShortScreen = screenHeight < theme.breakpoints.shortScreen;
  const isNarrowOrShort = isMobile || isShortScreen;

  const onShowSilver = () => {
    logEvent(EVENTS.episode.carrumLocalViewSilverOptions);
    dispatch(
      receiveTempCoeSelection({
        ...tempCoeSelection,
        productOffering: {
          code: 'both',
          name: 'Universal',
          active: true,
          id: 4,
        },
      })
    );
    onClose();
  };

  const overlayStyleOverrides = {
    height: isNarrowOrShort ? screenHeight : 'auto',
    justifyContent: 'space-between',
    marginBottom: 0,
  };

  // Remove padding around Benefits Tabs
  if (isNarrowOrShort) {
    // @ts-ignore
    overlayStyleOverrides.paddingRight = 0;
    // @ts-ignore
    overlayStyleOverrides.paddingBottom = 0;
    // @ts-ignore
    overlayStyleOverrides.paddingLeft = 0;
  }
  return (
    <Modal
      /**
       * The `flexShrink: 1` override is needed because of the ScrollWrapper passed as a peer-child to the NarrowScreenCTAs.
       * It allows the CTAs to remain visible.
       */
      contentStyleOverrides={{
        flexShrink: 1,
      }}
      isVisible={isVisible}
      onClose={onClose}
      options={[]}
      overlayStyleOverrides={overlayStyleOverrides}
      size="xlarge"
    >
      <ScrollWrapper>
        <Header />
        <CarrumSilverExperienceTable
          onClose={onClose}
          onShowSilver={onShowSilver}
          showButtons
        />
        <LearnMoreText>
          Learn more in&nbsp;
          <Anchor onPress={routeToFAQ} title="our FAQs" />.
        </LearnMoreText>
      </ScrollWrapper>

      {isNarrowOrShort && (
        <NarrowScreenCTAs onClose={onClose} onShowSilver={onShowSilver} />
      )}
    </Modal>
  );
};

export default ExtendedOptionsModal;
