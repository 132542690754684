import * as SecureStore from '@cross-platform/expo-secure-store';

/**
 * A global variable to keep track of rendered onboarding screens. This enables
 * us to initialize these values from the device storage outside of redux.
 */
export const ONBOARDING = {};

/**
 * Track the view of an onboarding screen of a given identifier.
 *
 * @param  {string}  key  The identifier for the onboarding screen.
 */
export const onboard = (key) => {
  ONBOARDING[key] = true;
  SecureStore.setItemAsync(key, 'true');
};

/**
 * Returns true if the user has seen an onboarding screen with a given name.
 *
 * @param   {string}   key  The identifier for the onboarding screen.
 *
 * @return  {promise}       A promise that resolves with a boolean indicating
 *                          the onboarding state.
 */
export const isOnboarded = async (key) => {
  if (Object.keys(ONBOARDING).indexOf(key) !== -1) {
    return ONBOARDING[key] === true;
  }

  let isOnboarded;
  try {
    isOnboarded = await SecureStore.getItemAsync(key);
  } catch (error) {
    isOnboarded = false;
  }

  return (ONBOARDING[key] = Boolean(isOnboarded));
};
