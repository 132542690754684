import { Image } from 'react-native';
import styled, { css } from 'styled-components/native';

interface TestimonialImageProps {
  testimonialKey: string;
  first?: boolean;
}
export const TestimonialImage = styled(Image).attrs({
  resizeMode: 'contain',
})<TestimonialImageProps>`
  ${({ first, theme: { breakpoints, spacing, windowDimensions } }) => css`
    height: ${spacing * 9}px;
    margin-right: ${spacing * 1.25}px;
    margin-top: ${!first && windowDimensions.width <= breakpoints.xlarge
      ? spacing * 1.25
      : 0}px;
    max-width: 100%;
    width: ${spacing * 26}px;
  `};
`;
