import {
  convertToPascalCase,
  getCustomStyles,
  getInputOptions,
} from 'app/components/Forms/helpers';

import {
  Acknowledgement,
  FormInputs,
  FormQuestionHtmlContent,
  FormQuestionInputField,
  FormQuestionSignatureGuidanceText,
  FormQuestionText,
} from 'app/components/Forms/styles';

import { FormQuestionProps } from 'app/components/Forms/types';

const FormQuestion = ({
  content,
  disabled,
  error,
  inputType,
  labelText = '',
  name,
  onCancel,
  onChange = () => {},
  options,
  required,
  enterKeyHint,
  signatureConfirmationText,
}: FormQuestionProps) => {
  // return early when input type does not require a form field
  if (inputType === 'acknowledgement') {
    return (
      <Acknowledgement.Wrapper>
        {content ? (
          <Acknowledgement.HtmlContent html={content} />
        ) : (
          <Acknowledgement.LabelText>{labelText}</Acknowledgement.LabelText>
        )}
      </Acknowledgement.Wrapper>
    );
  }

  // add an asterisk after the label of required fields
  labelText = required && labelText.length > 0 ? `${labelText} *` : labelText;

  // determine what type of form component to render (e.g., RadioGroup, Address, Select)
  const FormComponent = FormInputs[convertToPascalCase(inputType)];

  if (!FormComponent) {
    console.warn(`Unable to render unknown form input: "${inputType}"`);
    return null;
  }

  // define custom styles based on input type
  const customStyles = getCustomStyles(inputType);

  // form options data as required for form fields below
  const inputOptions = getInputOptions(options);

  return (
    <>
      {content ? (
        <FormQuestionHtmlContent html={content} />
      ) : (
        <FormQuestionText>{labelText}</FormQuestionText>
      )}

      {inputType === 'signature' && (
        <FormQuestionSignatureGuidanceText>
          Enter &quot;{signatureConfirmationText}
          &quot; to confirm.
        </FormQuestionSignatureGuidanceText>
      )}

      {/* @ts-ignore */}
      <FormQuestionInputField
        component={FormComponent}
        containerStyle={customStyles.container}
        disabled={disabled}
        error={error}
        name={name}
        onBlur={(e) => e?.preventDefault()}
        onCancel={onCancel}
        onChange={onChange}
        options={inputOptions}
        optionsStyle={customStyles.options}
        enterKeyHint={enterKeyHint}
        unSelectedOption={inputType === 'select' ? true : undefined}
      />
    </>
  );
};

export default FormQuestion;
