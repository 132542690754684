import {
  contactMethodOptions,
  REQUEST_RECEIVED_TEXT,
  TestID,
  theme,
} from './constants';

import {
  useDispatch,
  useSelector,
  useState,
  useWindowDimensions,
} from './hooks';

import {
  createInquiryFromState,
  getFormIsValid,
  updateInquiry,
} from './helpers';

import { LetUsKnowStyles as Styled } from './styles';

import {
  Episode,
  LetUsKnowProps,
  Location,
  ModalOption,
  PageOptions,
} from './types';

export function LetUsKnow({ onClose }: LetUsKnowProps) {
  const dispatch = useDispatch();

  // @ts-expect-error Property 'episode' does not exist on type 'DefaultRootState'.
  const inquiry = useSelector((state) => state?.episode?.inquiry);

  const [formValues, setFormValues] = useState({
    location: inquiry?.location || ({} as Location),
    description: inquiry?.description || '',
    contactMethodPreference: inquiry?.contactMethodPreference || undefined,
  });

  const [pageOption, setPageOption] = useState<PageOptions>(
    PageOptions.LetUsKnow
  );

  const createOrUpdateInquiry = async () => {
    const promise = inquiry?.id
      ? dispatch(updateInquiry(inquiry.id, { ...inquiry, ...formValues }))
      : dispatch(
          createInquiryFromState(
            formValues.description,
            formValues.location,
            formValues.contactMethodPreference
          )
        );

    const isSuccess = (await promise) as Promise<boolean>;

    if (isSuccess) {
      // show success page
      setPageOption(PageOptions.Confirmation);
    }
  };

  const updateAddress = (location: Location) => {
    setFormValues({ ...formValues, location });
  };

  const updateContactMethodPreference = (
    contactMethodPreference: Episode.ContactMethod
  ) => {
    setFormValues({ ...formValues, contactMethodPreference });
  };

  const updateDescription = (description: string) => {
    setFormValues({ ...formValues, description });
  };

  const formIsValid = getFormIsValid(formValues);

  const isNarrow = useWindowDimensions().width < theme.breakpoints.xlarge;

  const modalOptions: ModalOption[] = [];

  if (pageOption === PageOptions.LetUsKnow) {
    modalOptions.push({
      disabled: !formIsValid,
      onPress: createOrUpdateInquiry,
      title: 'Continue',
      type: 'solid',
    });
  } else {
    modalOptions.push({
      disabled: false,
      keepCasing: true,
      onPress: onClose,
      title: 'OK',
      type: 'solid',
    });
  }

  return (
    <Styled.Modal
      isVisible={true}
      onClose={onClose}
      options={modalOptions}
      size="large"
      title={pageOption}
      fullScreen={isNarrow}
    >
      {/* @ts-expect-error No overload matches this call. */}
      <Styled.Container disableScrollViewPanResponder>
        {pageOption === PageOptions.Confirmation && (
          <Styled.ConfirmationMessage.Wrapper>
            <Styled.ConfirmationMessage.Image size={80} />
            <Styled.ConfirmationMessage.Text>
              {REQUEST_RECEIVED_TEXT}
            </Styled.ConfirmationMessage.Text>
          </Styled.ConfirmationMessage.Wrapper>
        )}

        {pageOption === PageOptions.LetUsKnow && (
          <Styled.GuidanceText.Wrapper>
            <Styled.GuidanceText.Text>
              Please help us by describing your issue. We respond Monday through
              Friday between 9 AM and 8 PM EST, except major holidays. For
              medical emergencies, please call 911.
            </Styled.GuidanceText.Text>
          </Styled.GuidanceText.Wrapper>
        )}

        {pageOption === PageOptions.LetUsKnow && (
          <Styled.Form.Wrapper>
            <Styled.Form.InquiryInput.Wrapper>
              <Styled.Form.InquiryInput.Field
                placeholder="Type your health concern or recommended care here..."
                input={{
                  value: formValues.description,
                  onChange: updateDescription,
                }}
                enterKeyHint="next"
                multiline
                name="description"
                returnKeyLabel="Continue"
              />
            </Styled.Form.InquiryInput.Wrapper>

            <Styled.Form.AddressInput.Wrapper>
              <Styled.Form.AddressInput.Field
                label="Your Address or Zip Code"
                input={{
                  value: formValues.location,
                  onChange: updateAddress,
                  name: TestID.ProcedureSearch.LetUsKnowAddress,
                }}
                // @ts-expect-error Property 'theme' does not exist on type
                theme={theme}
              />
            </Styled.Form.AddressInput.Wrapper>

            <Styled.Form.ContactMethodInput
              label="How would you like us to contact you?"
              input={{
                onChange: updateContactMethodPreference,
                value: formValues.contactMethodPreference,
              }}
              inline
              options={contactMethodOptions}
            />
          </Styled.Form.Wrapper>
        )}
      </Styled.Container>
    </Styled.Modal>
  );
}
