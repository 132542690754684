import React from 'react';
import PropTypes from 'prop-types';

import { Platform, StyleSheet, View } from 'react-native';
import { Text } from 'react-native-elements';
import theme from 'app/util/theme';

/**
 * Renders a bulleted list of text items.
 */
const UnorderedList = ({ containerStyle, itemStyle, bulletStyle, items }) => (
  <View style={containerStyle}>
    {items.map((item, i) => (
      <View key={`list-item-${i}`} style={[styles.listItem, itemStyle]}>
        <Text style={StyleSheet.flatten([styles.listItemBullet, bulletStyle])}>
          {'● '}
        </Text>
        <Text style={StyleSheet.flatten([styles.listItemText])}>{item}</Text>
      </View>
    ))}
  </View>
);

/**
 * @property {array} items An array of items to display in a list (required).
 * @property {object} containerStyle Styles to add to the container element.
 * @property {object} itemStyle Styles to add to each item element.
 */
UnorderedList.propTypes = {
  items: PropTypes.array.isRequired,
  containerStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  itemStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  bulletStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
};

UnorderedList.defaultProps = {
  containerStyle: {},
  itemStyle: {},
  bulletStyle: {},
};

export default UnorderedList;

const styles = StyleSheet.create({
  listItem: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: theme.spacing / 4,
  },

  listItemBullet: {
    fontSize:
      Platform.OS === 'ios' ? theme.fontSizes.body3 : theme.fontSizes.xxsmall,
    marginLeft: theme.spacing * 1.25,
    marginRight: theme.spacing / 4,
    marginTop: Platform.OS === 'ios' ? theme.spacing / 8 : theme.spacing / 3,
  },

  listItemText: {
    flexWrap: 'wrap',
  },
});
