import { View as ViewRaw } from 'react-native';
import { ButtonGroup as ButtonGroupRaw, Text } from 'react-native-elements';
import styled, { css } from 'styled-components/native';

export { ScrollView } from 'react-native';

export { default as Faq } from './Faq';
export { default as HowItWorks } from './HowItWorks';
export { default as WhyCarrum } from './WhyCarrum';

export const ButtonGroup = styled(ButtonGroupRaw).attrs(({ theme }) => {
  return {
    buttonStyle: {
      borderBottomColor: '#eee',
      borderBottomWidth: 2,
    },
    containerStyle: {
      backgroundColor: 'transparent',
      borderRadius: 0,
      borderWidth: 0,
    },
    innerBorderStyle: {
      color: 'transparent',
      width: 4,
    },
    selectedButtonStyle: {
      backgroundColor: 'transparent',
      borderBottomColor: theme.colors.primary,
    },
    selectedTextStyle: {
      color: theme.colors.primary,
    },
    textStyle: {
      fontSize: theme.fontSizes.body3,
      color: theme.colors.primary,
    },
  };
})``;

export const Header = styled(Text).attrs(({ theme }) => {
  return {
    h1: true,
    h1Style: {
      paddingHorizontal: theme.spacing / 2,
      color: theme.colors.bodyText,
    },
  };
})``;

export const MoreInfo = styled(Text)`
  ${({ theme }) => css`
    text-align: center;
    margin-top: ${-theme.spacing * 2}px;
    margin-bottom: ${theme.spacing * 1.25}px;
    color: ${theme.colors.bodyText};
  `}
`;

export const View = styled(ViewRaw)`
  ${({ theme }) => css`
    align-self: center;
    flex: 1;
    max-width: ${theme.breakpoints.small}px;
    padding-bottom: ${theme.spacing * 2.5}px;
    width: 100%;
  `}
`;
