import React from 'react';
import Svg, { Path } from 'react-native-svg';

interface Props {
  color?: string;
  size?: number;
  style?: any;
}

const ExperienceCircle = ({ color = '#5F7881', size = 16, style }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 16 16" fill="none" style={style}>
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 11.8423C5.35858 11.8781 5.72478 11.8187 6.05469 11.6641C6.35938 12.4609 7.10938 13 8 13C8.86719 13 9.61719 12.4609 9.92188 11.6641C10.2658 11.8204 10.6375 11.8794 11 11.8411V15.1834C11 15.5569 10.6054 15.7986 10.2727 15.6288L8.22731 14.5848C8.08453 14.5119 7.91547 14.5119 7.77269 14.5848L5.72731 15.6288C5.39463 15.7986 5 15.5569 5 15.1834V11.8423Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.6406 4.07812C13.4375 4.38281 14 5.13281 14 6C14 6.89062 13.4375 7.64063 12.6406 7.94531C12.9922 8.69531 12.8516 9.63281 12.2422 10.2422C11.6094 10.875 10.6953 11.0156 9.92188 10.6641C9.61719 11.4609 8.86719 12 8 12C7.10938 12 6.35938 11.4609 6.05469 10.6641C5.30469 11.0156 4.36719 10.875 3.75781 10.2422C3.125 9.63281 2.98438 8.69531 3.33594 7.94531C2.53906 7.64063 2 6.89062 2 6C2 5.13281 2.53906 4.38281 3.33594 4.07812C2.98438 3.32812 3.125 2.39062 3.75781 1.75781C4.36719 1.14844 5.30469 1.00781 6.05469 1.35938C6.35938 0.5625 7.10938 0 8 0C8.86719 0 9.61719 0.5625 9.92188 1.35938C10.6719 1.00781 11.6094 1.14844 12.2422 1.75781C12.8516 2.39062 12.9922 3.32812 12.6406 4.07812ZM7.03437 7.92461C7.14687 8.02513 7.35313 8.02513 7.46563 7.92461L10.9156 4.84188C11.0281 4.74136 11.0281 4.55707 10.9156 4.45654L10.4844 4.08796C10.3719 3.97068 10.1844 3.97068 10.0719 4.08796L7.25938 6.60105L5.92812 5.42827C5.81562 5.31099 5.62812 5.31099 5.51562 5.42827L5.08437 5.79686C4.97187 5.89738 4.97187 6.08168 5.08437 6.1822L7.03437 7.92461Z"
      fill={color}
    />
  </Svg>
);

export default ExperienceCircle;
