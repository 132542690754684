import { TestID, theme } from 'app/components/App/Navigation/constants';
import { getIsLoggedIn } from 'app/components/App/Navigation/helpers';
import { useSelector, useSession } from 'app/components/App/Navigation/hooks';
import { State } from 'app/components/App/Navigation/types';

import { Avatar } from './subcomponents';

/**
 * Display the profile avatar only to authenticated users. Display as an image
 * or as initials.
 */
export const ProfileAvatar = () => {
  const {
    user: { profileImage },
  } = useSession();
  const isLoggedIn = useSelector((state: State.RootState) =>
    getIsLoggedIn(state.session)
  );

  if (!isLoggedIn) return null;

  return (
    <Avatar
      /**
       * The underlying component is heavily cached and does not
       * refresh automatically if the `source` param changes.
       *
       * By setting `key` to the value of the image, we can force
       * this component to re-render if the profile image changes.
       */
      key={profileImage}
      // @ts-expect-error Property 'testID' does not exist on type
      testID={TestID.Navigation.ProfileAvatar}
      rounded
      size={theme.fontSizes.h1}
      source={profileImage ? { uri: profileImage } : null}
      icon={
        profileImage
          ? null
          : {
              color: theme.colors.mediumBlue,
              name: 'user',
              type: 'font-awesome',
            }
      }
      containerStyle={{
        backgroundColor: theme.colors.backgroundLightBlue,
      }}
    />
  );
};
