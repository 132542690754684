const alert = (title, description, options) => {
  setTimeout(() => {
    const text = `${title}\n${description}`;
    const cancelOption = options.filter(({ style }) => style === 'cancel')[0];
    const succssOption = options.filter(({ style }) => style !== 'cancel')[0];

    if (!succssOption || !succssOption.onPress) return window.alert(text);

    const confirmed = window.confirm(text);
    if (confirmed) succssOption.onPress();
    if (!confirmed && cancelOption && cancelOption.onPress)
      cancelOption.onPress();
  });
};

export default { alert };
