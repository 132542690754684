import styled, { css } from 'styled-components/native';

import { View } from 'app/components/Common/Styled';

export {
  ExperienceCircle,
  GradCapCircle,
  LuggageCircle,
  PlaceCircle,
} from 'app/components/Common/svg-components';
export { default as TravelDuration } from 'app/components/Common/TravelDuration';
export {
  DetailsRow,
  DetailsText,
  iconConfig,
  IconWrapper,
} from 'app/components/SelectYourDoctor/Cards/styles';

export const LocationWrapper = styled(View)`
  ${({ theme }) => css`
    padding: ${theme.spacing / 4}px ${theme.spacing / 2}px;
    height: ${theme.windowDimensions.height <= theme.breakpoints.small
      ? '130px'
      : '110px'};
  `}
`;
