export type SmokingStatus =
  | 'current_smoker'
  | 'former_smoker_quit_between_3_and_6_months_ago'
  | 'former_smoker_quit_in_the_last_3_months'
  | 'former_smoker_quit_more_than_6_months_ago'
  | 'never_smoked';

export interface SmokingStatuses {
  label: string;
  value: SmokingStatus;
  triggersWarning: boolean;
}

export const smokingStatuses: SmokingStatuses[] = [
  {
    label: 'I am a current smoker',
    value: 'current_smoker',
    triggersWarning: true,
  },
  {
    label: 'I quit in the last 3 months',
    value: 'former_smoker_quit_in_the_last_3_months',
    triggersWarning: true,
  },
  {
    label: 'I quit between 3 and 6 months ago',
    value: 'former_smoker_quit_between_3_and_6_months_ago',
    triggersWarning: false,
  },
  {
    label: 'I quit more than 6 months ago',
    value: 'former_smoker_quit_more_than_6_months_ago',
    triggersWarning: false,
  },
  {
    label: 'I have never smoked',
    value: 'never_smoked',
    triggersWarning: false,
  },
];

export const riskySmokingStatuses: SmokingStatus[] = smokingStatuses
  .filter((status) => status.triggersWarning)
  .map((status) => status.value);
