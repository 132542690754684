import { connect } from 'react-redux';
import CarrumID from 'app/components/CarrumID';

const mapStateToProps = ({ session }) => ({
  session,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CarrumID);
