import axios from 'axios';

import { showGlobalError } from 'app/actions/uiActions';
import { CARE_API_URL } from 'app/util/constants';

import { receiveEpisodeSteps } from './action-types';

export default () => {
  return async (dispatch, getState) => {
    try {
      const { coreToken } = getState().session;
      const { episode } = getState().episode;
      const response = await axios.get(
        `${CARE_API_URL}/episodes/${episode.id}/steps`,
        {
          headers: { Authorization: `Bearer ${coreToken}` },
        }
      );

      await dispatch(receiveEpisodeSteps(response.data.data));
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
};
