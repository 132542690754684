import React from 'react';
import Svg, { ClipPath, Defs, G, Rect, Path } from 'react-native-svg';
import theme from 'app/util/theme';

interface LiveChatSolidProps {
  color?: string;
  height?: number;
  width?: number;
  iconStyle?: any;
}

const LiveChatSolid = ({
  color = 'red',
  height = 24,
  width = 24,
  iconStyle,
}: LiveChatSolidProps) => {
  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 24 24`}
      fill="none"
      style={iconStyle}
    >
      <G>
        <Path
          d="M12 21C18.6281 21 24 16.6359 24 11.25C24 5.86406 18.6281 1.5 12 1.5C5.37188 1.5 1.55508e-06 5.86406 1.55508e-06 11.25C1.55508e-06 13.3641 0.829689 15.3187 2.23594 16.9172C2.14688 18.0656 1.70156 19.0875 1.23281 19.8656C0.975002 20.2969 0.712502 20.6437 0.520314 20.8781C0.421877 20.9953 0.346877 21.0844 0.290627 21.1453C0.262502 21.1734 0.243752 21.1969 0.229689 21.2109L0.215627 21.225C1.56462e-06 21.4406 -0.0609359 21.7594 0.0562516 22.0406C0.173439 22.3219 0.445314 22.5047 0.750002 22.5047C2.09531 22.5047 3.45 22.0875 4.575 21.6C5.64844 21.1312 6.5625 20.5734 7.12031 20.1656C8.61094 20.7047 10.2609 21.0047 12 21.0047V21ZM6 9.75C6.39783 9.75 6.77936 9.90804 7.06066 10.1893C7.34197 10.4706 7.5 10.8522 7.5 11.25C7.5 11.6478 7.34197 12.0294 7.06066 12.3107C6.77936 12.592 6.39783 12.75 6 12.75C5.60218 12.75 5.22065 12.592 4.93934 12.3107C4.65804 12.0294 4.5 11.6478 4.5 11.25C4.5 10.8522 4.65804 10.4706 4.93934 10.1893C5.22065 9.90804 5.60218 9.75 6 9.75ZM12 9.75C12.3978 9.75 12.7794 9.90804 13.0607 10.1893C13.342 10.4706 13.5 10.8522 13.5 11.25C13.5 11.6478 13.342 12.0294 13.0607 12.3107C12.7794 12.592 12.3978 12.75 12 12.75C11.6022 12.75 11.2206 12.592 10.9393 12.3107C10.658 12.0294 10.5 11.6478 10.5 11.25C10.5 10.8522 10.658 10.4706 10.9393 10.1893C11.2206 9.90804 11.6022 9.75 12 9.75ZM16.5 11.25C16.5 10.8522 16.658 10.4706 16.9393 10.1893C17.2206 9.90804 17.6022 9.75 18 9.75C18.3978 9.75 18.7794 9.90804 19.0607 10.1893C19.342 10.4706 19.5 10.8522 19.5 11.25C19.5 11.6478 19.342 12.0294 19.0607 12.3107C18.7794 12.592 18.3978 12.75 18 12.75C17.6022 12.75 17.2206 12.592 16.9393 12.3107C16.658 12.0294 16.5 11.6478 16.5 11.25Z"
          fill={color === 'white' ? theme.colors.white : theme.colors.primary}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_640_2474">
          <Rect
            width="24"
            height="24"
            fill={color === 'white' ? theme.colors.primary : theme.colors.white}
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default LiveChatSolid;
