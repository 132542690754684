import { breakpoints, Routes } from './constants';

import {
  getHomeComponent,
  isWithinDateRangeOf,
  logCampaignParams,
  setShouldRememberDevice,
  syncShouldRememberFromLocalStorage,
  receiveSetCallUsModalVisibility,
} from './helpers';

import {
  useAppState,
  useCallback,
  useDispatch,
  useEffect,
  useHistory,
  useNextPhaseRoute,
  useNotifications,
  useState,
  useUi,
  useWindowDimensions,
} from './hooks';

import { AppProps } from './types';

import { BackHandler, Platform } from 'react-native';

import * as Linking from 'expo-linking';
import { Redirect, Route, Switch } from '@cross-platform/react-router-native';

import { Container, RoutesView } from 'app/components/App/styles';

import ActivationRedirect from 'app/components/Login/ActivationRedirect';
import CallUs from 'app/components/CallUs';
import CarrumID from 'app/containers/CarrumIDContainer';
import Dashboard from 'app/containers/DashboardContainer';
import Forms from 'app/containers/FormsContainer';
import JourneyPhase from 'app/components/JourneyPhase';
import LearnMore from 'app/components/LearnMore';
import Login from 'app/components/Login';
import LoginOverlay from 'app/components/App/LoginOverlay';
import MagicLinkRedirect from 'app/components/Login/Magic';
import Messages from 'app/components/Messages';
import MySettings from 'app/components/MySettings';
import Navigation from 'app/components/App/Navigation';
import { GetHelpModal } from 'app/components/App/Navigation/GetHelpModal';
import NotAvailable from 'app/components/NotAvailable';
import OneClickExpired from 'app/components/Register/SingleClick/Expired';
import OneClickWelcome from 'app/components/OneClickWelcome';
import ProcedureConfirmationPage from 'app/components/ProcedureConfirmation';
import ProcedureDescriptionPage from 'app/components/ProcedureDescription';
import ProcedureSearchResults from 'app/components/ProcedureSearch/ProcedureSearchResults';
import Register from 'app/containers/RegisterContainer';
import RegisterError from 'app/components/Register/Error';
import SaveProgress from 'app/components/CompleteYourProfile/SaveProgress';
import SelectYourDoctor from 'app/components/SelectYourDoctor';
import SidePanel from 'app/components/App/Navigation/SidePanel';
import Toasts from 'app/components/App/Toasts';
import Travel from 'app/containers/TravelContainer';
import WorkflowTask from 'app/components/WorkflowTask';

const loginRoutePath = 'login';

const redirect = (path) => () => <Redirect to={path} />; // eslint-disable-line react/display-name

/**
 * Top level container that displays the application routes and layout.
 *
 * @param  autoLogout                    whether to log user out when app is
 *                                       backgrounded
 * @param  connectToWebSocket            method to open web socket connection
 * @param  detectSessionOverride         method to detect session override
 * @param  disconnectFromWebSocket       method to close web socket connection
 * @param  dismissLocalNotification      method to dismiss a local notification
 * @param  loggedIn                      whether user is logged in
 * @param  logout                        method to log user out
 * @param  notifications                 notifications to display
 * @param  registerForPushNotifications  method to register for push
 *                                       notifications
 * @param  user                          details about user
 */
const App = ({
  autoLogout = true,
  connectToWebSocket,
  detectSessionOverride,
  disconnectFromWebSocket,
  dismissLocalNotification,
  loggedIn = false,
  logout,
  notifications = [],
  registerForPushNotifications,
  user,
}: AppProps) => {
  const { appState } = useAppState();
  const {
    goBack,
    location: { pathname },
    push,
  } = useHistory();

  const dispatch = useDispatch();
  const { nextRoute } = useNextPhaseRoute();
  const { shouldRememberDateSet, isCallUsModalVisible } = useUi();

  const [redirectPath, setRedirectPath] = useState(null);

  // Listen for and handle in-app and native push notifications.
  const { onNotificationPress } = useNotifications();

  const isNarrow = useWindowDimensions().width <= breakpoints.small;

  /** Listen for back button presses on Android or web browsers. */
  useEffect(() => {
    if (Platform.OS === 'web') return;

    BackHandler.addEventListener('hardwareBackPress', onBackPress);

    return () => {
      BackHandler.removeEventListener('hardwareBackPress', onBackPress);
    };
  }, []);

  /**
   * Listen for route changes on mobile devices when opening the application
   * from another app (eg., an email application).
   */
  useEffect(() => {
    if (Platform.OS === 'web') return;

    const subscription = Linking.addEventListener('url', navigateToLink);

    return () => {
      subscription?.remove();
    };
  }, []);

  /** Publish a notification if logged in as another user. */
  useEffect(() => {
    detectSessionOverride();
  }, []);

  /** Detect the initial route when loading the app. */
  useEffect(() => {
    loadInitialRoute();
  }, []);

  /** Handle changes from log in to log out state. */
  useEffect(() => {
    loggedIn ? onLogin() : onLogout();
  }, [loggedIn]);

  /**
   * Listen for app state changes.
   *
   * - On mobile, trigger callback when app moves to background or foreground.
   * - On web, trigger callback when browser tab gains or loses focus.
   */
  useEffect(() => {
    if (appState === 'background') onBackgroundApp();
  }, [appState]);

  /** Check the local storage for remember device settings. */
  useEffect(() => {
    if (user) {
      dispatch(syncShouldRememberFromLocalStorage(user.email));
    }
  }, [dispatch, user?.email]);

  /**
   * When logging in, connect to the ActionCable server, register for push
   * notifications, and handle redirects.
   */
  const onLogin = () => {
    connectToWebSocket();
    registerForPushNotifications();

    // reset `rememberMe` value after login, if expired
    if (
      shouldRememberDateSet &&
      !isWithinDateRangeOf(shouldRememberDateSet, 30)
    ) {
      dispatch(setShouldRememberDevice(user?.email, false));
    }
    if (!redirectPath) return;

    push(redirectPath);
    setRedirectPath(null);
  };

  /** When logging out, disconnect from the ActionCable server. */
  const onLogout = () => {
    disconnectFromWebSocket();
  };

  /** When backgrounding the app and `autoLogout` is on, log out of the app. */
  const onBackgroundApp = useCallback(() => {
    if (autoLogout && loggedIn) logout();
  }, [autoLogout, loggedIn]);

  const onBackPress = () => {
    goBack();
    return true;
  };

  /** Load the initial route (if any). */
  const loadInitialRoute = async () => {
    const url = await Linking.getInitialURL();
    await navigateToLink({ url });
  };

  /**
   * Parse the current link and, if valid, call `props.push()` with the path.
   *
   * @param  {string}  url  URL used to launch the app (such as
   *                        carrumhealth://password-reset)
   */
  const navigateToLink = async ({ url }) => {
    const uri = url?.replace(/^([^?]*)carrumhealth\.com/, '');

    if (!uri) return;

    let { path, queryParams } = Linking.parse(uri);

    // @ts-expect-error Argument of type 'ParsedQs' is not assignable to parameter of type
    if (queryParams) await logCampaignParams(queryParams);
    if (!path || path === '/' || path === 'localhost') return;

    const query = Object.keys(queryParams || {})
      .map((key) => {
        // @ts-expect-error Argument of type 'ParsedQs' is not assignable to parameter of type
        const value = encodeURIComponent(queryParams[key]);
        return `${key}=${value}`;
      })
      .join('&');

    path = /^\//.test(path) ? path : `/${path}`;
    path = [path, query].filter((isPresent) => isPresent).join('?');

    setRedirectPath(path);

    await push(path);
  };

  // Don't display the navigation when viewing the login page or sub pages.
  const displayNavigation =
    loggedIn || (pathname && !pathname.includes(loginRoutePath));

  return (
    <Container testID="App">
      <RoutesView topPadding={displayNavigation}>
        <Switch>
          {/**
           * (1) Protected Routes
           * ------------------------------------------------------------------
           *
           * The following section of code contains protected routes that can
           * be accessed by authenticated users, covering several aspects of
           * the patient's journey of care:
           *
           * (1.1) Patient Communication and Support
           * (1.2) Account Settings
           * (1.3) Procedure Selection
           * (1.4) Profile Completion
           * (1.5) Episode Creation
           * (1.6) Provider and Physician Selection
           */}

          {/* (1.1) Patient Communication and Support Routes */}
          <Route
            exact
            path={`/:carrumId/${Routes.CallUs}`}
            component={CallUs}
          />
          <Route exact path={`/${Routes.CarrumID}`} component={CarrumID} />
          <Route path={`/${Routes.Dashboard}`} component={Dashboard} />
          <Route
            exact
            path={`/${Routes.Forms}/:id?`}
            component={loggedIn ? Forms : null}
          />
          <Route exact path={`/${Routes.Messages}`} component={Messages} />
          <Route path={`/${Routes.NotAvailable}`} component={NotAvailable} />
          <Route path={`/${Routes.Travel}`} component={Travel} />
          <Route path={`/${Routes.Tasks}/:taskId`} component={WorkflowTask} />

          {/* (1.2) Account Settings Route */}
          <Route path={`/${Routes.MySettings}`} component={MySettings} />

          {/* (1.3) Procedure Selection Routes */}
          <Route
            path={`/${Routes.Search}/:search`}
            component={ProcedureSearchResults}
          />

          <Route
            exact
            path={`/${Routes.Procedures}/:id`}
            component={ProcedureDescriptionPage}
          />

          <Route
            exact
            path={`/${Routes.ProcedureConfirmation}`}
            component={ProcedureConfirmationPage}
          />

          {/* (1.4) Episode Creation Routes */}
          <Route
            exact
            path={`/${Routes.CompleteYourProfile}/${Routes.SaveProgress}`}
            component={SaveProgress}
          />

          {/* (1.6) Provider and Physician Selection Routes */}
          <Route
            path={`/${Routes.SelectYourDoctor}`}
            component={SelectYourDoctor}
          />

          {/**
           * (2) Public Routes and Client Personalization
           * ------------------------------------------------------------------
           *
           * Public routes can be displayed for non-authenticated guest. Each
           * can include a path fragment that maps to a client code to enable a
           * more personalized experience (e.g., "/pear/register").
           *
           * Public routes are included in the following blocks:
           *
           * - (2.1) Registration
           * - (2.2) Password Reset
           * - (2.3) Login
           * - (2.4) "Learn More"
           */}

          {/**
           * (2.1) Registration Routes
           *
           * NOTE: Many erroneous routes were reported as a result of mistakes
           * during marketing campaigns (see TEC-4132). To enable users to
           * register still, the following URL patterns should redirect such
           * users directly to the registration page:
           *
           * - `/invalid/register`
           * - `/register/invalid`
           * - `/register/invalid/register`
           * - `/register/token/invalid`
           * - `/register/u530f35c-6343-b3eb-8556-164802440b72/invalid`
           */}
          <Route
            exact
            path={`/invalid/${Routes.Register}`}
            component={loggedIn ? redirect(nextRoute) : Register}
          />
          <Route
            exact
            path={`/:clientId?/${Routes.Register}/invalid/register`}
            component={loggedIn ? redirect(nextRoute) : Register}
          />
          <Route
            exact
            path={`/:clientId?/${Routes.Register}/:token?/(expired|invalid)`}
            component={loggedIn ? redirect(nextRoute) : OneClickExpired}
          />
          <Route
            exact
            path={`/:clientId?/${Routes.Register}/:token`}
            component={loggedIn ? redirect(nextRoute) : OneClickWelcome}
          />
          <Route
            exact
            path={`/:clientId?/${Routes.Register}`}
            component={loggedIn ? redirect(nextRoute) : Register}
          />
          <Route
            path={`/:clientId?/${Routes.InvalidActivation}`}
            component={loggedIn ? redirect(nextRoute) : RegisterError}
          />

          {/* These routes no longer exist so we redirect to login */}
          <Route
            exact
            path={`/:clientId?/${Routes.PasswordReset}/valid`}
            component={redirect(`/${Routes.Login}`)}
          />
          <Route
            path={`/:clientId?/${Routes.PasswordReset}(|/invalid|/expired)`}
            component={redirect(`/${Routes.Login}`)}
          />

          {/** (2.3) Login Routes */}
          <Route
            exact
            path={`/:clientId?/${Routes.Login}/activation`}
            component={loggedIn ? redirect(nextRoute) : ActivationRedirect}
          />
          <Route
            exact
            path={`/:clientId?/${Routes.Login}/magic`}
            component={MagicLinkRedirect}
          />
          <Route
            path={`/:clientId?/${Routes.Login}`}
            component={loggedIn ? redirect(nextRoute) : Login}
          />

          {/** (2.4) "Learn More" Route */}
          <Route
            path={`/:clientId?/${Routes.LearnMore}`}
            component={LearnMore}
          />

          {/**
           * (3) "Catch-all" Routes
           * ------------------------------------------------------------------
           *
           * Attempt to match any other route to a Journey Phase.
           *
           * This component will automatically redirect to the correct route if
           * the one given is not valid or belongs to a future journey phase.
           */}
          <Route
            exact
            path="/:journeyPhase"
            component={loggedIn ? JourneyPhase : Login}
          />
          <Route
            path={Routes.Home}
            component={getHomeComponent({
              loggedIn,
              nextRoute,
              redirect,
              user,
            })}
          />
        </Switch>
      </RoutesView>

      <SidePanel />

      <Toasts
        notifications={notifications}
        // @ts-expect-error Argument of type '{data: any; }' is not assignable to parameter
        onPress={(data) => onNotificationPress({ data })}
        onDismiss={(index) => dismissLocalNotification({ index })}
      />

      <LoginOverlay isVisible={!loggedIn} />

      {displayNavigation && <Navigation />}
      <GetHelpModal
        onClose={() => dispatch(receiveSetCallUsModalVisibility(false))}
        visible={isCallUsModalVisible}
      />
    </Container>
  );
};

export default App;
