import { FlatList as FlatListRaw, Platform } from 'react-native';

import styled, { css } from 'styled-components/native';

import { Text, View } from 'app/components/Common/Styled';

import SearchSuggestion from '../SearchSuggestion';
export { SearchSuggestion };

export const FlatList = styled(FlatListRaw)`
  /**
   * Limit the height of the container such that the next scrollable item
   * is partially visible.
   */
  max-height: ${({ theme }) => theme.spacing * 9}px;
`;

export const ScrollHint = styled(Text)`
  ${({ theme }) =>
    css`
      background-color: ${theme.colors.white};
      padding: ${theme.spacing / 2}px;
      padding-left: ${theme.spacing}px;
      color: ${theme.colors.black}70;
      font-size: ${theme.fontSizes.small}px;
    `}
`;

export const SearchSuggestionsContainer = styled(View)`
  ${({ theme }) => css`
    border-top-width: 2px;
    border-top-color: ${theme.colors.defaultDark};
    z-index: ${theme.zIndexes.overlay};
  `}

  /**
   * On Android, absolute positioned elements and their children are not
   * pressable if they're rendered outside of a parent's boundaries. However,
   * the container won't stretch to fill the contents because they are absolute
   * positioned.
   *
   * In order to ensure that the child contents are pressable on Android, we'll
   * give the wrapper a min-height property so it expands to allow the children
   * to render within this container's boundaries, allowing items to be pressed.
   */
  ${Platform.OS === 'android' ? 'min-height: 50%;' : ''}
`;

export const SearchSuggestionsPositionWrapper = styled(View)`
  left: 0;
  right: 0;
  position: absolute;
`;
