import styled, { css } from 'styled-components/native';

import { Text, View } from 'app/components/Common/Styled';

export const PreTitleText = styled(Text)`
  text-align: center;

  ${({
    theme: { breakpoints, colors, fontFamilyMedium, windowDimensions },
  }) => {
    const { height, width } = windowDimensions;

    const isTabletOrSmaller = width < breakpoints.medium;
    const isShortScreen = height < breakpoints.shortScreen;
    const isNarrowOrShort = isTabletOrSmaller || isShortScreen;

    return css`
      color: ${colors.bodyText};
      font-family: ${fontFamilyMedium};
      font-size: ${({ theme }) => theme.fontSizes.h2}px;

      ${isNarrowOrShort &&
      css`
        margin-top: ${({ theme }) => theme.spacing * 1.5}px;
      `}
    `;
  }}
`;

export const TitleText = styled(Text).attrs({
  align: 'center',
  medium: true,
  size: 'h2',
})`
  ${({ theme }) => css`
    align-items: baseline;
    padding: ${theme.spacing / 4}px 0;
  `}
`;

export const SilverText = styled(TitleText)`
  ${({ theme }) => css`
    color: ${theme.colors.silver};
  `}
`;

export const SilverTitlePadding = styled(View)`
  ${({ theme }) => css`
    background-color: ${theme.colors.silver};
    border-radius: ${theme.spacing / 2}px;
    padding: 0 ${theme.spacing / 8}px;
  `}
`;

const SilverBackgroundText = styled(TitleText)`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    /* Reset TitleText padding to prep for <SilverTitlePadding /> */
    padding: 0;
  `}
`;

// Text cannot receive border radius in mobile,
// so the Text is wrapped in styled View
export const SilverBackground = ({ children }) => (
  <SilverTitlePadding>
    <SilverBackgroundText>{children}</SilverBackgroundText>
  </SilverTitlePadding>
);

export const TitleSection = styled(View)`
  ${({ theme }) => css`
    align-items: center;
    flex: 0 1 auto;
    margin: ${theme.spacing / 2}px 0 ${theme.spacing * 1.5}px;
  `}
`;

export const TitleSubRow = styled(View)`
  ${({ theme }) => css`
    align-items: center;
    flex-direction: row;
    margin-top: ${theme.spacing / 2}px;
  `}
`;
