import React from 'react';
import { Button } from 'react-native-elements';

//@ts-ignore
import { onPressWithTracking } from './helpers';

/**
 * Renders a <Button /> from `react-native-elements` that implements onPress
 * tracking in Google Analytics.
 */
const TrackableButton = ({ onPress, ...buttonProps }) => {
  return (
    <Button
      onPress={onPressWithTracking(onPress, buttonProps)}
      {...buttonProps}
    />
  );
};

export default TrackableButton;
