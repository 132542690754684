import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchCurrentEpisode } from 'app/actions/episodeActions';
import { getEpisodeJourneyPhase } from 'app/selectors/episode';
import { State } from 'types/state';

const useEpisode = () => {
  const dispatch = useDispatch();
  const { episode } = useSelector((store: State.RootState) => store);
  const journeyPhase = getEpisodeJourneyPhase(episode);

  const refresh = useCallback(async () => {
    await dispatch(fetchCurrentEpisode());
  }, [dispatch]);

  return {
    ...episode,
    journeyPhase,
    refresh,
  };
};

export const useProductOfferings = () => {
  const { episode } = useSelector((store: State.RootState) => store);

  return episode.productOfferingsList;
};

export default useEpisode;
