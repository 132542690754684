import React from 'react';
import PropTypes from 'prop-types';

import Text from 'app/components/Common/Styled/Text';

import {
  Container,
  Icon,
  NumberLabel,
  NumberLabelContainer,
  TitleContainer,
} from './styles';

const propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  number: PropTypes.number.isRequired,
};

const HowItWorksSection = ({ children, title, icon, number }) => (
  <Container>
    <Icon source={icon} />
    <TitleContainer>
      <NumberLabelContainer>
        <NumberLabel>{number}</NumberLabel>
      </NumberLabelContainer>

      <Text h3>{title}</Text>
    </TitleContainer>
    {children}
  </Container>
);

HowItWorksSection.propTypes = propTypes;
export default HowItWorksSection;
