import React from 'react';
import { Platform, StyleSheet } from 'react-native';
import { Text } from 'react-native-elements';
import { Link } from '@cross-platform/react-router-native';

import theme, { fontFamilyMedium } from 'app/util/theme';

import { onPressLink } from './helpers';
import { AnchorProps } from './types';

export { AnchorProps };

/**
 * Renders clickable text that opens a new route.
 *
 * @example
 * <Anchor title="My anchor" to="/some-path" />
 * <Anchor title="My anchor" to="/some-other-path">
 *   <Icon name="chevron-right" />
 * </Anchor>
 */
export const Anchor = ({
  onPress,
  testID,
  title,
  titleStyle,
  ...linkProps
}: AnchorProps) => (
  <Link
    /**
     * href is invalid for absolute paths in web.
     * TODO: https://carrumhealth.atlassian.net/browse/TEC-2192
     */
    {...linkProps}
    data-testid={testID}
    onClick={onPress || onPressLink(linkProps.to)}
    style={StyleSheet.flatten([
      {
        color: theme.colors.primary,
        fontFamily: fontFamilyMedium,
        fontSize: theme.fontSizes.body3,
        textDecorationLine: 'none',
      },
      titleStyle,
    ])}
  >
    {title}
  </Link>
);

Anchor.defaultProps = {
  titleStyle: {},
  to: '#',
};

export default Anchor;
