import React from 'react';
import { Anchor, Container, StatusIcon, Text } from './styles';

interface StatusMessageProps {
  ctaPath?: string;
  ctaText?: string;
  message: string;
  status: string;
}

const StatusMessage = ({
  ctaPath,
  ctaText,
  message,
  status,
}: StatusMessageProps) => {
  return (
    <Container>
      <StatusIcon name={status} status={status} />
      <Text>{message}</Text>
      {ctaPath && ctaText ? <Text> </Text> : null}
      {ctaPath && ctaText ? <Anchor to={ctaPath} title={ctaText} /> : null}
    </Container>
  );
};

export default StatusMessage;
