/**
 * Parses a string date from 'YYYY-MM-DD' format.
 */
export const asDate = (dateString) => {
  if (!dateString) return;

  const [year, month, day] = dateString.split('-');

  return new Date(year, month - 1, day);
};

/**
 * Converts a string or date object into 'MM/DD/YYYY' format.
 */
export const asString = (date) => {
  if (!date) return;
  if (!date.getUTCFullYear) date = asDate(date);

  const year = date.getUTCFullYear();
  const month = numberWithPadding(date.getUTCMonth() + 1);
  const day = numberWithPadding(date.getUTCDate());

  return [month, day, year].join('/');
};

/**
 * Converts a date object into 'YYYY-MM-DD' format.
 */
export const asValue = (date) => {
  if (!date?.getUTCFullYear) return;

  const year = date.getUTCFullYear();
  const month = numberWithPadding(date.getUTCMonth() + 1);
  const day = numberWithPadding(date.getUTCDate());

  return [year, month, day].join('-');
};

/**
 * Adds left padding of 0 to ensure two digit numbers.
 */
export const numberWithPadding = (number) => String(number).padStart(2, '0');
