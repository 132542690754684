import { ScrollView as ScrollViewRaw } from 'react-native';
import { Avatar as AvatarRaw, ButtonProps, Image } from 'react-native-elements';
import styled, { css } from 'styled-components/native';

import { NsaModal as NsaModalRaw } from 'app/components/SelectYourDoctor/Confirmation/NsaModal';
import { Text, View } from 'app/components/Common/Styled';
import TrackableButton from 'app/components/Common/TrackableButton';

import DisclaimerTextRaw from 'app/components/Common/DisclaimerText';

export { TopBackLink } from 'app/components/Common/TopBackLink';
export { default as StickyNavFooter } from 'app/components/Common/StickyNavFooter';
export { NavigationMode } from 'app/components/Common/StickyNavFooter/types';
export { default as Anchor } from 'app/components/Common/Anchor';
export { OptionsWrapper } from '../OptionsSection/styles';
export { DoctorCard } from '../Cards';

export const NsaModal = NsaModalRaw;

export const Avatar = styled(AvatarRaw)`
  ${({ theme }) => {
    const avatarDiameter = theme.spacing * 11;
    return css`
      border-radius: ${avatarDiameter / 2}px;
      box-shadow: ${theme.shadows.mediumBottomString};
      height: ${avatarDiameter}px;
      overflow: hidden;
      width: ${avatarDiameter}px;
    `;
  }}
`;

export const Button = styled(TrackableButton).attrs<ButtonProps, ButtonProps>(
  (props) => ({
    ...props,
    buttonStyle: {
      minWidth: props.theme.spacing * 20,
    },
    containerStyle: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: props.theme.spacing * 0.5,
      marginBottom: props.theme.spacing * 2,
    },
  })
)``;

export const Container = styled(View)`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.colors.sand};
    flex: 1 0 auto;
    justify-content: center;
    padding: ${theme.spacing}px;
  `}
`;

export const FacilityCard = styled(View)`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: ${theme.spacing}px;
    box-shadow: ${theme.shadows.mediumBottomString};
    height: ${theme.spacing * 9}px;
    justify-content: center;
    width: ${theme.spacing * 22}px;
    max-width: ${theme.windowDimensions - theme.spacing}px;
  `}
`;

export const FooterContainer = styled(View)`
  flex: 1 0 auto;
`;

export const FullBenefitsText = styled(Text)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing}px;
  `}
`;

export const Header = styled(Text).attrs({ size: 'h1' })`
  ${({ theme }) => css`
    margin-top: ${theme.spacing * 1}px;
    text-align: center;
    font-family: ${theme.fontFamilyBold};
  `}
`;

export const HospitalImage = styled(Image)`
  ${({ theme }) => css`
    height: ${theme.spacing * 5}px;
    resize-mode: contain;
    width: 100%;
  `}
`;

export const InnerColumn = styled(View)`
  ${({ theme }) => css`
    flex: 1 0 auto;
    max-width: ${theme.breakpoints.small}px;
  `}
`;

export const LearnMoreButton = styled(Button).attrs(({ theme }) => {
  return {
    buttonStyle: {
      padding: 0,
    },
    containerStyle: {
      marginBottom: theme.spacing,
    },
    type: 'clear',
  };
})``;

export const EstimateText = styled(Text)`
  text-align: center;
`;

export const DisclaimerText = styled(DisclaimerTextRaw)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing}px;
  `}
`;

export const ScrollView = styled(ScrollViewRaw).attrs(({ theme }) => ({
  contentContainerStyle: { flexGrow: 1, paddingBottom: theme.spacing * 6 },
}))``;

export const Selection = styled(View)`
  ${({ theme }) => {
    const useHorizontalLayout =
      theme.windowDimensions.width > theme.breakpoints.small;
    return css`
      flex-grow: ${useHorizontalLayout ? 1 : 0};
      align-items: center;
      justify-content: flex-end;
      margin-bottom: ${theme.spacing}px;
      ${theme.windowDimensions.width < theme.breakpoints.medium
        ? `width: 100%;`
        : 'max-width: 50%'}
    `;
  }}
`;

export const Selections = styled(View)`
  ${({ theme }) => {
    const useHorizontalLayout =
      theme.windowDimensions.width > theme.breakpoints.small;
    return css`
      align-items: ${useHorizontalLayout ? 'flex-end' : 'center'};
      flex-wrap: wrap;
      flex: ${useHorizontalLayout ? '0 0 auto' : '1 0 auto'};
      flex-direction: ${useHorizontalLayout ? 'row' : 'column'};
      justify-content: ${useHorizontalLayout ? 'space-between' : 'flex-start'};
    `;
  }}
`;

export const SelectionName = styled(Text).attrs({ size: 'body1' })`
  ${({ theme }) => css`
    font-family: ${theme.fontFamilyBold};
    margin-bottom: ${theme.spacing / 2}px;
    margin-top: ${theme.spacing * 2}px;
    text-align: center;
  `}
`;

export const SelectionSubheader = styled(Text).attrs({ size: 'body2' })``;

export const YouSelected = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.bodyText};
    font-size: ${theme.fontSizes.body2}px;
    margin: ${theme.spacing * 2}px auto;
    text-align: center;
  `}
`;

export const LearnMore = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-size: ${theme.fontSizes.body2}px;
  `}
`;
export const Link = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-size: ${theme.fontSizes.body2}px;
    margin: ${theme.spacing * 2}px auto;
  `}
`;
