import React from 'react';
import Svg, { Path } from 'react-native-svg';

interface Props {
  color?: string;
  size?: number;
  style?: any;
}

const PlaceCircle = ({ color = '#5F7881', size = 16, style }: Props) => (
  <Svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <Path
      d="M7.41406 15.582C7.67773 15.9922 8.29297 15.9922 8.55664 15.582C12.834 9.42969 13.625 8.78516 13.625 6.5C13.625 3.39453 11.1055 0.875 8 0.875C4.86523 0.875 2.375 3.39453 2.375 6.5C2.375 8.78516 3.13672 9.42969 7.41406 15.582ZM8 8.84375C6.68164 8.84375 5.65625 7.81836 5.65625 6.5C5.65625 5.21094 6.68164 4.15625 8 4.15625C9.28906 4.15625 10.3438 5.21094 10.3438 6.5C10.3438 7.81836 9.28906 8.84375 8 8.84375Z"
      fill={color}
    />
  </Svg>
);

export default PlaceCircle;
