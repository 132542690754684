import {
  navigationPaths,
  TestID,
} from 'app/components/App/Navigation/constants';
import {
  getIsLoggedIn,
  isEmpty,
  routeTo,
  setPanelOpen,
  setProfileMenuOpen,
} from 'app/components/App/Navigation/helpers';
import {
  useDispatch,
  useSession,
  useSelector,
} from 'app/components/App/Navigation/hooks';
import { ProfileInfoStyles as Styled } from 'app/components/App/Navigation/styles';
import { State } from 'app/components/App/Navigation/types';

/**
 * Render the profile details for an authenticated user.
 *
 * 1. for wide screens as part of the top nav
 * 2. for narrow screens as part of the side nav
 */
export const ProfileInfo = () => {
  const dispatch = useDispatch();
  const { user } = useSession();
  const { email } = user;
  const showProfileInfo = !isEmpty(user);

  const isLoggedIn = useSelector((state: State.RootState) =>
    getIsLoggedIn(state.session)
  );

  if (!isLoggedIn || !showProfileInfo) return null;

  const getUserName = (user) => {
    const { profile, name } = user;
    return (profile && profile.preferredName) || name;
  };

  const navigateToProfile = () => {
    dispatch(setPanelOpen(false));
    dispatch(setProfileMenuOpen(false));
    routeTo(navigationPaths.settings);
  };

  return (
    <Styled.Wrapper
      onPress={navigateToProfile}
      testID={TestID.Navigation.ProfileInfo}
    >
      {/* The RN `Avatar` component can't be styled with `styled(Avatar)` */}
      <Styled.Avatar />

      <Styled.Identity>
        <Styled.Name testID={TestID.Navigation.ProfileName}>
          {getUserName(user)}
        </Styled.Name>

        <Styled.Email
          testID={TestID.Navigation.ProfileEmail}
          numberOfLines={1}
          ellipsizeMode="tail"
        >
          {email}
        </Styled.Email>
      </Styled.Identity>
    </Styled.Wrapper>
  );
};
