import React, { useState } from 'react';

import { useNextTask } from 'app/components/Forms/hooks';
import { SaveLink } from 'app/components/Forms/styles';

/*
  A progress indicator for the Workflow Presentation Mode
*/

const FormProgressIndicator = () => {
  const { stepProgress, presentationMode } = useNextTask();
  const stepProgressState = useState(stepProgress)[0];

  if (presentationMode === 'dashboard') return null;

  return (
    <SaveLink.ProgressIndicator key="progress" value={stepProgressState} />
  );
};

export default React.memo(FormProgressIndicator);
