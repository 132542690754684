import { Divider as DividerRaw } from 'react-native-elements';

import styled, { css } from 'styled-components/native';

import ModalRaw, { ModalProps } from 'app/components/Common/Modal';
import { Text as TextRaw, View } from 'app/components/Common/Styled';
import {
  CheckCircle,
  CheckCircleProps,
} from 'app/components/Common/svg-components';

export const CarrumPhoneText = styled(TextRaw)`
  font-size: ${({ theme }) => theme.fontSizes.body3}px;
  margin: 0 auto;
  padding-top: ${({ theme }) => theme.spacing / 2}px;
  padding-bottom: ${({ theme }) => theme.spacing / 2}px;
  text-align: center;
`;

export const ConfirmationBody = styled(TextRaw)`
  text-align: center;
`;

export const ConfirmationHeader = styled(TextRaw)`
  ${({ theme }) => css`
    color: ${theme.colors.success};
    font-family: ${theme.fontFamilyBold};
    margin: ${theme.spacing / 2}px auto;
  `}
`;

export const Divider = styled(DividerRaw)`
  margin-top: ${({ theme }) =>
    theme.isNarrow ? `${theme.spacing / 2}px` : `${theme.spacing}px`};
`;

export const GreenCheckMark = styled(CheckCircle).attrs<CheckCircleProps>(
  ({ theme }) => ({
    color: theme.colors.success,
    size: 67,
  })
)<CheckCircleProps>`
  margin: 0 auto;
`;

export const Modal = styled(ModalRaw).attrs(({ theme }) => ({
  overlayStyleOverrides: {
    backgroundColor: theme.colors.sand,
  },
}))<ModalProps>``;

export const PhoneNumberDescriptionWrapper = styled(View)`
  margin-bottom: ${({ theme }) => theme.spacing / 2}px;
  margin-top: ${({ theme }) => theme.spacing / 2}px;
`;

export const Text = styled(TextRaw)`
  ${({ theme }) => css`
    color: ${theme.colors.bodyText};
    font-family: ${theme.fontFamily};
    font-size: ${theme.fontSizes.body2}px;
    padding: ${theme.spacing / 4}px;
  `}
`;

export const SubText = styled(TextRaw)`
  ${({ theme }) => css`
    color: ${theme.colors.bodyText};
    font-family: ${theme.fontFamily};
    font-size: ${theme.fontSizes.body2}px;
    font-style: italic;
    padding-bottom: ${theme.spacing / 2}px;
  `}
`;

interface BodyTextProps {
  mb?: number;
  mt?: number;
}

export const BodyText = styled(Text).attrs({
  size: 'h3',
})<BodyTextProps>`
  margin-bottom: ${({ mb = 0, theme }) => theme.spacing * mb}px;
  margin-top: ${({ mt = 0, theme }) => theme.spacing * mt}px;
`;

export const Header = styled(Text).attrs({
  align: 'center',
  medium: true,
  size: 'h1',
})``;
