import { Platform, ScrollView as ScrollViewRaw } from 'react-native';
import styled, { css } from 'styled-components/native';

import { View } from 'app/components/Common/Styled';

export { TopBackLink } from 'app/components/Common/TopBackLink';
export { default as DoctorsAtThisFacility } from '../DoctorsAtThisFacility';

export const Container = styled(View)`
  ${({ theme }) => css`
    flex: 1 0 auto;
    flex-direction: ${theme.windowDimensions.width < theme.breakpoints.medium
      ? 'column'
      : 'row'};
  `}
`;

export const ContentContainer = styled(View)`
  ${({ theme }) => {
    const hasSingleColumnLayout =
      theme.windowDimensions.width < theme.breakpoints.medium;
    return css`
      background-color: ${theme.colors.white};
      flex: ${hasSingleColumnLayout ? '1 0 auto' : '1 1 0'};
      padding: ${theme.spacing * (theme.isNarrow ? 1 : 2)}px;
    `;
  }}
`;

export const ScrollView = styled(ScrollViewRaw).attrs({
  contentContainerStyle: { flex: Platform.OS === 'web' ? 1 : 0 },
})``;
