import React, { useMemo } from 'react';

import { useWindowDimensions } from 'react-native';
import Html from 'react-native-render-html';

import { ignoreConsoleWarning } from 'app/util/methods';
import theme from 'app/util/theme';

import {
  baseStyle,
  customHTMLElementModels,
  renderersProps,
} from './constants';
import { htmlStyles, renderHtmlTemplate } from './helpers';
import { BenefitsOverview, FlexContainer, ScrollView, Video } from './styles';
import { Testimonial } from 'app/components/Common/Testimonial';

interface HtmlViewProps {
  // The HTML content to render (defaults to '').
  html?: string;
  // If true, wraps the component in a <ScrollView /> (defaults to true).
  scrollView?: boolean;
  // Additional styling definitions to add (defaults to {}).
  tagsStyles?: any;
  // Override color for the body text (defaults to '').
  textColor?: string;
}

/**
 * Render a scrollable container with HTML content.
 *
 * @example
 * import HtmlView from 'app/components/Common/HtmlView';
 *
 * <HtmlView html="<p>This is a paragraph</p>" />
 */
export const HtmlView = ({
  html = '',
  scrollView = true,
  tagsStyles = {},
  textColor = '',
}: HtmlViewProps) => {
  const { width } = useWindowDimensions();
  const maxWidth = width - theme.spacing * 2;

  const cachedHtml = useMemo(() => renderHtmlTemplate(html), [html]);

  const cachedTagStyles = useMemo(() => {
    return {
      ...htmlStyles({ maxWidth, textColor }),
      ...tagsStyles,
    };
  }, [maxWidth, tagsStyles, textColor]);

  const htmlView = (
    <Html
      baseStyle={baseStyle}
      contentWidth={maxWidth}
      customHTMLElementModels={customHTMLElementModels}
      renderers={renderers}
      renderersProps={renderersProps}
      source={{ html: cachedHtml }}
      tagsStyles={cachedTagStyles}
    />
  );

  if (!scrollView)
    return <FlexContainer style={{ flexGrow: 0 }}>{htmlView}</FlexContainer>;

  return <ScrollView>{htmlView}</ScrollView>;
};

/**
 * Passed to an `<Html />` component from `react-native-render-html`
 * as the `renderers` prop.
 *
 * Creates a mapping between HTML tags and custom render implementations
 * to provide granular control over how tags render as components.
 */
const renderers = {
  /**
   * Add a custom renderer to parse a custom <benefits-overview /> tag into a
   * <BenefitsOverview /> component.
   */
  'benefits-overview': ({ tnode: { attributes } }) => (
    <BenefitsOverview {...attributes} />
  ),
  /**
   * Add a custom renderer to parse a custom <react-video /> tag into a <Video />
   * component that renders in web and mobile.
   *
   * NOTE: <react-video /> is used here because <video /> appears to be reserved.
   */
  'react-video': ({ tnode: { attributes } }) => <Video {...attributes} />,
  /**
   * Add a custom renderer to parse a custom <testimonial /> tag into a
   * <Testimonial /> component.
   */
  testimonial: ({ tnode: { attributes } }) => (
    <Testimonial testimonialKey={attributes.key} />
  ),
};

/**
 * Even though this component is properly memoized, a warning occurs frequently,
 * which pollutes the logs and impedes testing and development.
 *
 * This we're using `React.memo()` and `useMemo()`, this seems safe to ignore.
 *
 * This is the full error message:
 *
 *     You seem to update the renderers prop(s) of the "RenderHTML" component in short
 *     periods of time, causing costly tree rerenders (last update was 24.00ms ago).
 *     See https://stackoverflow.com/q/68966120/2779871
 */
ignoreConsoleWarning(
  'component in short periods of time, causing costly tree rerenders'
);

export default React.memo(HtmlView);
