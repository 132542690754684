import { EVENTS, scheduledHolidays } from './constants';

import { isImage, isVideo } from 'app/util/methods';

import { logEvent } from 'app/util/analytics';

import { playSound } from './helpers';

import { Platform } from './styles';

import { composeConversation } from 'app/util/messageUtils';

import {
  createMessage as createMessageAction,
  fetchMessages as fetchMessagesAction,
  markUnreadMessages as markUnreadMessagesAction,
  setMessagesOpen as setMessagesOpenAction,
} from 'app/actions/messageActions';

import {
  launchCamera as launchCameraAction,
  launchDocumentPicker as launchDocumentPickerAction,
  launchImageLibrary as launchImageLibraryAction,
} from 'app/actions/uiActions';

const fetchMessages = (dispatch, conversationId, maxId) => {
  dispatch(fetchMessagesAction(conversationId, maxId));
};

const launchCamera = (dispatch, options) =>
  dispatch(launchCameraAction(options));

const launchDocumentPicker = (dispatch) =>
  dispatch(launchDocumentPickerAction());

const launchImageLibrary = (dispatch, options) =>
  dispatch(launchImageLibraryAction(options));

export { callCarrum } from 'app/util/call';

/** Play a chime to indicate a new message. */
export const chime = () => {
  playSound('received');
};

export const createMessage = (dispatch, conversationId, message) =>
  dispatch(createMessageAction(conversationId, message));

export {
  receiveSetCallUsModalVisibility,
  receiveSetLiveChatModalVisibility,
} from 'app/actions/uiActions';

/**
 * Fetch the next page of messages with a 300 ms debounce.
 *
 * @return  promise that resolves after fetching more messages
 */
export const fetchMoreMessages = async (
  dispatch,
  conversation,
  refreshing,
  setRefreshing,
  refreshTimeout
) => {
  if (refreshing || !conversation || !conversation?.messages.length) return;

  const finalMessageIndex = conversation.messages.length - 1;
  const finalMessage = conversation.messages[finalMessageIndex];

  // Preserve location in the current scroll index.
  if (Platform.OS === 'web') window.scrollTo({ top: 1, behavior: 'instant' });

  setRefreshing(true);

  await fetchMessages(dispatch, conversation.id, finalMessage.id);

  refreshTimeout = setTimeout(() => setRefreshing(false), 300);
};

export { formatPhoneNumber } from 'app/util/methods';

export const getConversation = (message, session) => {
  if (!message.conversationId) return null;

  const correspondingConversation = message.conversations.find(
    ({ id }) => parseInt(id) === parseInt(message.conversationId)
  );

  return composeConversation(
    correspondingConversation,
    message.userStatuses,
    session.user.id
  );
};

export const getMessageGroups = (conversation) =>
  conversation ? conversation.messageGroups : {};

export const getMessagesData = (messageGroups) => {
  return Object.keys(messageGroups).map((key, idx) => {
    const messageIsLastInConversation = idx === 0;

    return {
      key,
      messageIsLastInConversation,
      ...messageGroups[key],
    };
  });
};

/**
 * Get the correct style for a message based on its position within a message
 * group with a given number of messages.
 *
 * @param   index            index of this message in the group
 * @param   count            total number of messages in this group
 * @param   patientIsAuthor  whether current user is the author
 *
 * @return                   style object for the message
 */
export const getMessageTextWrapperStyleParams = (
  index,
  count,
  patientIsAuthor
) => ({
  isFirst: count > 1 && index === 0,
  isLast: count > 1 && index === count - 1,
  isMiddle: count > 2 && index < count - 1 && index > 0,
  patientIsAuthor: patientIsAuthor,
});

export const getParticipants = (conversation): [] =>
  conversation ? conversation.participants : [];

export const getShowLoadMoreButton = ({ conversation, messagesPerPage }) => {
  const platformIsWeb = Platform.OS === 'web';
  const messageCount = conversation?.messages?.length;
  const moreMessagesMayExist = messageCount % messagesPerPage === 0;

  return platformIsWeb && moreMessagesMayExist;
};

/**
 * Launch the camera roll to allow users to send photos already on their
 * device.
 */
export const handleAlbumPress = async ({
  dispatch,
  conversation,
  formValues,
  setFormValues,
}) => {
  if (!conversation) return null;

  const { canceled, assets } = await launchImageLibrary(dispatch, {
    allowsEditing: true,
    mediaTypes: 'All',
  });

  if (canceled) return;

  setFormValues({
    ...formValues,
    attachments: [...formValues.attachments, ...assets.map(({ uri }) => uri)],
  });
};

/**
 * Launch the camera app to allow users to take and send photos using their
 * device.
 */
export const handleCameraPress = async ({
  dispatch,
  conversation,
  formValues,
  setFormValues,
}) => {
  if (!conversation) return null;

  const { canceled, assets } = await launchCamera(dispatch, {
    allowsEditing: true,
    mediaTypes: 'All',
  });

  if (canceled) return;

  setFormValues({
    ...formValues,
    attachments: [...formValues.attachments, ...assets.map(({ uri }) => uri)],
  });
};

/**
 * Launch the file explorer to allow users to upload documents stored on
 * their device.
 */
export const handleDocumentPress = async ({
  dispatch,
  conversation,
  formValues,
  setFormValues,
}) => {
  if (!conversation) return null;

  const { canceled, assets } = await launchDocumentPicker(dispatch);

  if (canceled) return;

  setFormValues({
    ...formValues,
    attachments: [...formValues.attachments, assets[0].uri],
  });
};

/**
 * Determine whether there is a new message from another user.
 *
 * @param   currentUserId         identifier for the current user
 * @param   conversation          data for the conversation as it is
 * @param   previousConversation  data for the conversation as it was
 *
 * @return                        whether there is a new message
 */
export const hasNewMessage = (
  currentUserId,
  conversation,
  previousConversation
) => {
  if (!conversation) return false;

  const lastMessage = conversation.messages[0];
  const lastMessageExists = Boolean(lastMessage);
  const userDidNotAuthorLastMessage =
    parseInt(lastMessage?.author.id, 10) !== parseInt(currentUserId, 10);

  const conversationIsNew = Boolean(!previousConversation && conversation);
  const conversationHasMoreMessages =
    conversation?.messages?.length > previousConversation?.messages?.length;
  const conversationHasBeenUpdated =
    conversationIsNew || conversationHasMoreMessages;

  const result =
    conversationHasBeenUpdated &&
    lastMessageExists &&
    userDidNotAuthorLastMessage;

  return result;
};

export { logEvent };

/**
 * Log messaging events in Google Analytics.
 *
 * @param  body         the message body
 * @param  attachments  the message attachments
 */
export const logMessageSent = (body, attachments) => {
  if (body.length) logEvent(EVENTS.messaging.sendMessage);

  attachments.forEach((attachment) => {
    const type = isVideo(attachment)
      ? 'video'
      : isImage(attachment)
      ? 'image'
      : 'document';

    logEvent(EVENTS.messaging.sendAttachment, { type });
  });
};

/**
 * Mark unread messages as read after an optional timeout.
 *
 * @param  {integer}  timeout  the number of milliseconds to wait before
 *                             updating unread messages
 */
export const markUnreadMessages = (
  dispatch,
  conversationId,
  timeout,
  unreadTimeout
) => {
  unreadTimeout = setTimeout(() => {
    dispatch(markUnreadMessagesAction(conversationId));
  }, timeout);
};

export { playSound } from 'app/util/soundUtils';

export const removeAttachment = (index, formValues, setFormValues) => {
  const { attachments } = formValues;

  const newAttachments = [...attachments];
  newAttachments.splice(index, 1);

  setFormValues({
    ...formValues,
    attachments: newAttachments,
  });
};

export const setMessagesOpen = (dispatch, isOpen) => {
  dispatch(setMessagesOpenAction(isOpen));
};

/** Scroll to the bottom of the viewport. */
export const scrollToBottom = (animated = false, scrollView) => {
  if (scrollView) scrollView.scrollToOffset({ offset: 0, animated });

  if (window?.scrollTo && document?.body) {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: animated ? 'smooth' : 'instant',
    });
  }
};

/**
 * Return a holiday message if today is within a holiday range, or null if not.
 *
 * @return  message or `null`
 */
export function getHolidayMessage(): string | null {
  const today = new Date();
  const todayString = today.toISOString().split('T')[0]; // today's date in 'YYYY-MM-DD' format

  let holidayMessage = null;

  scheduledHolidays.forEach((holiday) => {
    const { date, messageText } = holiday;
    const { starting: startingDate, ending: endingDate } = date;

    const todayIsScheduledHoliday =
      todayString >= startingDate && todayString <= endingDate;

    if (todayIsScheduledHoliday) holidayMessage = messageText;
  });

  return holidayMessage;
}
