import { Image } from 'react-native';
import { Icon } from 'react-native-elements';
import styled, { css } from 'styled-components/native';

import AnchorRaw from 'app/components/Common/Anchor';
import { Text, View } from 'app/components/Common/Styled';
import theme from 'app/util/theme';

import { IconType } from 'types/icon';

import { getBarHeight, graphHeight } from './helpers';

const graphPaddingHorizontal = theme.spacing * 3.5;

export const Anchor = styled(AnchorRaw).attrs(({ theme }) => ({
  titleStyle: {
    fontSize: theme.fontSizes.h3,
  },
}))``;

interface BodyTextProps {
  mb?: number;
  mt?: number;
}

export const BodyText = styled(Text).attrs({
  size: 'h3',
})<BodyTextProps>`
  margin-bottom: ${({ mb = 0, theme }) => theme.spacing * mb}px;
  margin-top: ${({ mt = 0, theme }) => theme.spacing * mt}px;
`;

export const CareConciergeImage = styled(Image)`
  ${({ theme }) => css`
    align-self: center;
    border-radius: ${theme.spacing * 10}px;
    height: ${theme.spacing * 10}px;
    margin-bottom: ${theme.spacing}px;
    width: ${theme.spacing * 10}px;
  `}
`;

export const CareConciergeImageFallback = styled(Icon).attrs(({ theme }) => ({
  type: IconType.FontAwesome,
  name: 'user-circle',
  size: theme.spacing * 10,
  containerStyle: {
    marginTop: theme.spacing * 1.25,
    marginBottom: theme.spacing,
  },
}))``;

export const GridWrapper = styled(View)`
  align-items: stretch;
  margin-bottom: ${({ theme }) => theme.spacing * 2}px;
`;

export const Header = styled(Text).attrs({
  align: 'center',
  medium: true,
  size: 'h1',
})``;

export const LeftText = styled(Text).attrs({
  medium: true,
  size: 'body2',
})`
  flex: 1 0 125px;
`;

export const CenteredText = styled(Text).attrs({
  align: 'center',
  size: 'body2',
})`
  flex: 1 0 100px;
`;

export const DetailText = styled(Text).attrs({
  size: 'body1',
})`
  margin-bottom: ${({ theme }) => theme.spacing * 1.25}px;
`;

export const SuccessText = styled(Text).attrs({
  align: 'center',
  color: 'success',
  medium: true,
  size: 'h1',
})`
  margin-bottom: ${({ theme }) => theme.spacing * 2.5}px;
`;

interface RowProps {
  borderTop?: boolean;
  borderTopDark?: boolean;
}

export const Row = styled(View)<RowProps>`
  border-width: 1px;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  flex-direction: row;
  flex: 1 0 auto;
  padding: ${({ theme }) => `${theme.spacing / 2}px 0`};

  ${({ borderTop, borderTopDark }) => {
    if (borderTop) {
      return 'border-top-color: #DADCE0';
    }

    if (borderTopDark) {
      return 'border-top-color: #50514F';
    }

    return 'border-top-color: transparent';
  }}
`;

export const GraphWrapper = styled(View)`
  align-items: stretch;
  flex: 1 0 auto;
`;

export const Graph = styled(View)`
  border-bottom-color: black;
  border-bottom-width: 1px;
  flex-direction: row;
  justify-content: space-around;
  height: ${graphHeight}px;
  align-items: flex-end;

  padding: ${({ theme }) =>
    theme.windowDimensions.width < theme.breakpoints.small
      ? css`
       0;
        `
      : css`
          0 ${graphPaddingHorizontal / 2}px;
        `};
`;

export const GraphBar = styled(View)<{
  carrumPercent: string;
  nationalPercent: string;
  isCarrum?: boolean;
}>`
  background-color: ${({ isCarrum, theme }) =>
    isCarrum ? theme.colors.success : '#50514F80'};
  height: ${({ carrumPercent, isCarrum, nationalPercent }) =>
    getBarHeight(carrumPercent, nationalPercent, isCarrum)}px;
  width: ${({ theme }) => theme.spacing * 6}px;
`;

export const GraphBarText = styled(Text).attrs<{ isCarrum?: boolean }>(
  ({ isCarrum }) => ({
    align: 'center',
    color: isCarrum ? 'success' : 'bodyText',
    medium: true,
    size: 'body3',
  })
)<{ isCarrum?: boolean }>``;

const magicSpaceForText = theme.spacing * 9;

export const GraphBarWrapper = styled(View)`
  align-items: center;
  justify-content: flex-end;
  width: ${magicSpaceForText}px;
`;

export const GraphLabelRow = styled(View)`
  flex-direction: row;
  justify-content: space-around;
  margin-top: ${({ theme }) => theme.spacing / 2}px;
  padding: ${({ theme }) =>
    theme.windowDimensions.width < theme.breakpoints.small
      ? css`
       0;
        `
      : css`
          0 ${graphPaddingHorizontal / 2}px;
        `};
`;

export const GraphLabelText = styled(Text).attrs<{ isCarrum?: boolean }>(
  ({ isCarrum }) => ({
    align: 'center',
    color: isCarrum ? 'success' : 'bodyText',
    size: 'body3',
  })
)<{ isCarrum?: boolean }>`
  width: ${magicSpaceForText}px;
`;

export const GraphHelperText = styled(Text).attrs({
  align: 'center',
  size: 'xsmall',
})`
  margin-top: ${({ theme }) => theme.spacing * 1.5}px;
  margin-bottom: ${({ theme }) => theme.spacing * 2}px;
`;

export const SvgWrapper = styled(View)`
  flex: 1 0 auto;
  flex-direction: row;
  justify-content: center;
`;
