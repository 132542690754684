import { Platform } from 'react-native';

import { toAddressString } from 'app/util/methods';

export {
  fetchLocationFromAddress,
  formatGoogleAddress,
} from 'app/util/methods';

const isAddressObject = (address) =>
  address?.street && address?.city && address?.state && address?.postalCode;

export const formatValue = (value) =>
  isAddressObject(value.address)
    ? toAddressString(value.address)
    : typeof value.address === 'string'
    ? value.address
    : '';

/**
 * Manually restore visibility to the text input rendered by
 * the `react-google-places-autocomplete` package, which
 * doesn't properly store visibility after clicking on a
 * selection.
 */
export const restoreVisibilityToInput = (className) => {
  if (Platform.OS !== 'web') return;

  const input = document.querySelector(`.${className} input`);

  // @ts-expect-error Ignore typing for web elements
  if (input?.style) input.style.opacity = 1;
};

/**
 * Manually update the internal state value that controls the text input
 * rendered by the `react-native-google-places-autocomplete` package
 * using a given value, enabling the implementation of a "controlled" input.
 */
export const setGooglePlacesTextInputValue = (
  formattedValue,
  googlePlacesAutoCompleteRef
) => {
  if (Platform.OS === 'web') return;
  if (!formattedValue) return;
  if (!googlePlacesAutoCompleteRef.current) return;

  const { getAddressText, setAddressText } =
    googlePlacesAutoCompleteRef.current;

  if (!getAddressText || !setAddressText) return;
  if (getAddressText() === formattedValue) return;

  googlePlacesAutoCompleteRef.current.setAddressText(formattedValue);
};
