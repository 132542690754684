import styled, { css } from 'styled-components/native';

import Anchor from 'app/components/Common/Anchor';
import { Button as RNEButton } from 'react-native-elements';
import SearchBarRaw from 'app/components/Common/SearchBar';
import {
  Text as TextRaw,
  View,
  ScrollView,
} from 'app/components/Common/Styled';

import ModalRaw from 'app/components/Common/Modal';
import { default as RadioGroupRaw } from 'app/components/Common/RadioGroup';
import { Divider as DividerRaw } from 'react-native-elements';
import { Platform } from 'react-native';

export { ModalOption } from 'app/components/Common/Modal';

export { CheckMark } from 'app/components/Common/svg-components';
export { default as TextArea } from 'app/components/Common/TextArea';

interface BodyTextProps {
  mb?: number;
  mt?: number;
}

const PlatformContainer = Platform.select({
  default: ScrollView as any,
  web: View,
});

export const BodyText = styled(TextRaw).attrs({
  size: 'body2',
})<BodyTextProps>`
  font-family: ${({ theme }) => theme.fontFamily};
  margin-bottom: ${({ mb = 0, theme }) => theme.spacing * mb}px;
  margin-top: ${({ mt = 0, theme }) => theme.spacing * mt}px;
`;

export const Column = styled(View)`
  flex: 1 0 auto;
  padding: ${({ theme }) => theme.spacing / 2}px;
  border: 1px solid #ccc;
  width: 50%;
`;

export const Container = styled(View)`
  ${({ theme }) => css`
    flex: 1 0 auto;
  `}
`;

export const Divider = styled(DividerRaw)`
  margin-top: ${({ theme }) =>
    theme.isNarrow ? `${theme.spacing / 2}px` : `${theme.spacing}px`};
`;

export const Header = styled(TextRaw)`
  font-weight: bold;
`;

export const HeaderText = styled(TextRaw)`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.body1}px;
    font-weight: bold;
    margin-top: ${theme.spacing / 2}px;
    margin-bottom: ${theme.spacing / 2}px;
  `}
`;

export const HeaderTitle = styled(View)`
  background-color: ${({ theme }) => theme.colors.backgroundLightBlue};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const List = styled(TextRaw)``;

export const Modal = styled(ModalRaw).attrs(({ theme }) => ({
  size: 'medium',
  overlayStyleOverrides: {
    backgroundColor: theme.colors.white,
    maxHeight: theme.windowDimensions.height - theme.spacing * 6.5,
  },
}))``;

export const RadioGroup = styled(RadioGroupRaw).attrs(({ theme }) => {
  return {
    inline: !theme.isNarrow,
  };
})``;

export const SearchBar = styled(SearchBarRaw).attrs(({ theme }) => {
  return {
    cancelButtonProps: {
      buttonStyle: { display: 'none' },
      disabled: true,
    },
    clearIcon: { color: theme.colors.bodyText, name: 'close' },
    inputContainerStyle: {
      backgroundColor: theme.colors.white,
      borderColor: theme.colors.defaultDark,
      borderRadius: theme.spacing / 4,
      borderWidth: 2,
      borderBottomWidth: 2,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 0,
    },
    labelStyle: {
      color: theme.colors.bodyText,
      fontFamily: theme.fontFamily,
      fontWeight: 500,
    },
    placeholder: 'Search...',
    placeholderTextColor: theme.colors.placeholderText,
    platform: Platform.OS.replace('web', 'default'),
    /** Prevent the clear icon from affecting the component layout. */
    rightIconContainerStyle: {
      position: 'absolute',
      right: 0,
    },
    searchIcon: { color: theme.colors.bodyText, name: 'search' },
  };
})``;

export const SearchButton = styled(RNEButton)``;

export const SearchContainer = styled(View)`
  ${({ theme }) => css`
    margin: ${theme.spacing}px 0;
  `}
`;

export const SendMessageLink = styled(Anchor).attrs(({ theme }) => {
  return {
    titleStyle: { fontSize: theme.fontSizes.body1 },
  };
})``;

export const Table = styled(View)`
  flex: 1 0 auto;
  flex-direction: row;
  justify-content: space-between;
`;

export const Text = styled(TextRaw)`
  ${({ theme }) => css`
    color: ${theme.colors.bodyText};
    font-family: ${theme.fontFamily};
    font-size: ${theme.fontSizes.body2}px;
  `}
`;
