import axios from 'axios';

import { CORE_API_URL } from 'app/util/constants';

import {
  receiveBundlePrices,
  receiveBundlePricesLoading,
  receiveBundlePricesError,
} from './action-types';
import { formatData } from 'app/util/reducerUtils';

export default (
  procedureKey: string,
  filterKey: string,
  filterValue: string
) => {
  return async (dispatch, getState) => {
    dispatch(receiveBundlePricesLoading(true));

    try {
      const { coreToken } = getState().session;
      const response = await axios.get(
        `${CORE_API_URL}/offered_procedures/${procedureKey}/bundle-prices?filter[${filterKey}]=${filterValue}`,
        {
          headers: { Authorization: `Bearer ${coreToken}` },
        }
      );

      dispatch(receiveBundlePrices(response.data.data.map(formatData)));
    } catch (error) {
      dispatch(receiveBundlePricesError(error));
    } finally {
      dispatch(receiveBundlePricesLoading(false));
    }
  };
};
