import styled from 'styled-components/native';

import Text from 'app/components/Common/Styled/Text';

export const Container = styled.View`
  flex: 1 0 auto;
`;

export const Disclaimer = styled(Text).attrs({ size: 'small' })`
  font-style: italic;
  margin-top: ${({ theme }) => theme.spacing / 2}px;
`;

export const Paragraph = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacing / 2}px;
`;
