import React from 'react';

import { useOopEstimate, useSession } from 'app/hooks';
import { toCurrencyString } from 'app/util/methods';
import DisclaimerText from 'app/components/Common/DisclaimerText';
import { CostImage } from 'app/components/Common/svg-components/ValuePropositions/CostImage';
import { MissingContent } from 'app/components/Common/svg-components';

import BenefitModalBase from './BenefitModalBase';
import {
  Anchor,
  BodyText,
  CenteredText,
  GridWrapper,
  LeftText,
  Row,
  SuccessText,
  SvgWrapper,
} from './styles';

const CostModal = ({ isVisible, onClose }) => {
  const {
    data: { record },
  } = useOopEstimate();

  const { user } = useSession();
  const carrumCoinsuranceAmount = record?.carrumCoinsuranceAmount;
  const carrumCopay = record?.carrumCopay;
  const carrumDeductible = record?.carrumDeductible;
  const nonCarrumCoinsuranceAmount = record?.nonCarrumCoinsuranceAmount;
  const nonCarrumCopay = record?.nonCarrumCopay;
  const nonCarrumDeductible = record?.nonCarrumDeductible;
  const isPartnerClient = user?.employer?.type === 'partner';

  const carrumTotalCost =
    carrumCoinsuranceAmount + carrumCopay + carrumDeductible;

  const nonCarrumTotalCost =
    nonCarrumCoinsuranceAmount + nonCarrumCopay + nonCarrumDeductible;

  const carrumDifference = nonCarrumTotalCost - carrumTotalCost;

  if (isPartnerClient)
    return (
      <BenefitModalBase
        isVisible={isVisible}
        onClose={onClose}
        title="No Surprise Bills"
      >
        <SvgWrapper>
          <CostImage height={350} width={350} />
        </SvgWrapper>
        <BodyText mb={2}>
          One bundled payment, so you don't need to worry about any unexpected
          bills.
        </BodyText>
      </BenefitModalBase>
    );

  if (!record)
    return (
      <BenefitModalBase
        isVisible={isVisible}
        onClose={onClose}
        title="Lower Out-of-Pocket Cost"
      >
        <BodyText mb={2}>
          Looks like we may have the wrong information. Please{' '}
          <Anchor to="/complete-your-profile/insurance-info" title="go back" />{' '}
          and re-enter your insurance details. If you continue to experience
          issues, <Anchor to="/messages" title="message your care specialist" />
          .
        </BodyText>

        <SvgWrapper>
          <MissingContent />
        </SvgWrapper>
      </BenefitModalBase>
    );

  return (
    <BenefitModalBase
      isVisible={isVisible}
      onClose={onClose}
      title="Lower Out-of-Pocket Cost&#42;"
    >
      <BodyText mb={2}>
        When using the Carrum Health benefit instead of your current insurance,
      </BodyText>

      <SuccessText>
        You may save approximately {toCurrencyString(carrumDifference)}
      </SuccessText>

      <GridWrapper>
        <Row>
          <LeftText></LeftText>
          <CenteredText>Carrum Health{'\n'}Centers of Excellence</CenteredText>
          <CenteredText>Your Current{'\n'}Insurance</CenteredText>
        </Row>
        <Row borderTop>
          <LeftText>Coinsurance:</LeftText>
          <CenteredText>
            {toCurrencyString(carrumCoinsuranceAmount)}
          </CenteredText>
          <CenteredText>
            {toCurrencyString(nonCarrumCoinsuranceAmount)}
          </CenteredText>
        </Row>
        <Row borderTop>
          <LeftText>Copay:</LeftText>
          <CenteredText>{toCurrencyString(carrumCopay)}</CenteredText>
          <CenteredText>{toCurrencyString(nonCarrumCopay)}</CenteredText>
        </Row>
        <Row borderTop>
          <LeftText>Deductible:</LeftText>
          <CenteredText>{toCurrencyString(carrumDeductible)}</CenteredText>
          <CenteredText>{toCurrencyString(nonCarrumDeductible)}</CenteredText>
        </Row>
        <Row borderTopDark>
          <LeftText>Out-of-Pocket Total:</LeftText>
          <CenteredText>{toCurrencyString(carrumTotalCost)}</CenteredText>
          <CenteredText>{toCurrencyString(nonCarrumTotalCost)}</CenteredText>
        </Row>
      </GridWrapper>

      <DisclaimerText>
        &#42;The out-of-pocket costs shown are estimates only. Please
        independently confirm them as your actual costs may vary.
      </DisclaimerText>
    </BenefitModalBase>
  );
};

export default CostModal;
