import { Text } from 'react-native-elements';
import styled, { css } from 'styled-components/native';

import { View } from 'app/components/Common/Styled';

export { default as ExperienceSection } from 'app/components/SelectYourDoctor/Cards/ExperienceSection';
export { default as LocationSection } from 'app/components/SelectYourDoctor/Cards/LocationSection';
export {
  CardPaddingWrapper,
  CardWrapper,
  FacilityImage,
  LearnMoreButton,
  SpacerColumn,
  TopImageSection,
  View,
} from 'app/components/SelectYourDoctor/Cards/styles';

export const DetailsRow = styled(View)`
  ${({ theme }) => css`
    align-items: center;
    flex-direction: row;
    margin: ${theme.spacing / 4}px 0;
  `}
`;

export const GoldText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.gold};
    font-family: ${theme.fontFamilyMedium};
    font-size: ${theme.fontSizes.body3}px;
    margin: ${theme.spacing / 4}px 0;
  `}
`;

export const HospitalName = styled(Text).attrs(({ theme }) => {
  return {
    h4: true,
    h4Style: { color: theme.colors.bodyText },
  };
})`
  ${({ theme }) => css`
    /* horizontal padding doesn't work on Text - margin is used instead */
    margin: 0 ${theme.spacing / 2}px;
  `}
`;
