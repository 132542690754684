import React, { useMemo, useState } from 'react';

import { useHistory } from '@cross-platform/react-router-native';
import { GoldExperienceModal } from 'app/components/BenefitModals/GoldExperience';
import SilverExperienceModal from 'app/components/BenefitModals/SilverExperience';
import { SelectYourDoctorRoutes } from 'app/components/SelectYourDoctor/routes';
import { TestID } from 'app/util/test-id';

import {
  DetailsRow,
  iconConfig,
  IconWrapper,
  ExperienceText,
  InfoIconWrapper,
  InfoIcon,
  CheckCircle,
} from './styles';

interface ExperienceLabelProps {
  isSilver?: boolean;
}

type CurrentModal = 'silver' | 'gold' | '';

const ExperienceLabel = ({ isSilver = false }: ExperienceLabelProps) => {
  const {
    location: { pathname },
  } = useHistory();

  const isDoctorDescriptionPage =
    pathname.includes(SelectYourDoctorRoutes.Doctors) &&
    pathname !== SelectYourDoctorRoutes.Doctors;
  const isFacilityDescriptionPage =
    pathname.includes(SelectYourDoctorRoutes.Facilities) &&
    pathname !== SelectYourDoctorRoutes.Facilities;
  const shouldShowInfoIcon = useMemo(
    () => isDoctorDescriptionPage || isFacilityDescriptionPage,
    [isDoctorDescriptionPage, isFacilityDescriptionPage]
  );

  const [currentModal, setCurrentModal] = useState<CurrentModal>('');
  const experienceText = `Carrum ${isSilver ? 'Silver' : 'Gold'} Experience`;

  return (
    <>
      <DetailsRow>
        <IconWrapper>
          <CheckCircle isSilver={isSilver} {...iconConfig} />
        </IconWrapper>
        <ExperienceText isSilver={isSilver}>{experienceText}</ExperienceText>

        {shouldShowInfoIcon && (
          <InfoIconWrapper
            testID={TestID.ExperienceLabel.InfoIcon}
            onPress={() => setCurrentModal(isSilver ? 'silver' : 'gold')}
          >
            <InfoIcon />
          </InfoIconWrapper>
        )}
      </DetailsRow>

      <GoldExperienceModal
        isVisible={currentModal === 'gold'}
        onClose={() => setCurrentModal('')}
      />

      <SilverExperienceModal
        isVisible={currentModal === 'silver'}
        onClose={() => setCurrentModal('')}
      />
    </>
  );
};

export default ExperienceLabel;
