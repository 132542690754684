import {
  breakpoints,
  navigationPaths,
  TestID,
} from 'app/components/App/Navigation/constants';

import {
  extractIncompleteFormCount,
  extractUnreadMessageCount,
  getHasConversation,
  getIsEpisodeCreated,
  getIsEpisodeTraveling,
  getIsLoggedIn,
} from 'app/components/App/Navigation/helpers';

import {
  useEpisode,
  useMessage,
  useSelector,
  useSession,
  useSubmission,
  useWindowDimensions,
} from 'app/components/App/Navigation/hooks';

import { ContentLinksStyles as Styled } from 'app/components/App/Navigation/styles';

import { State } from 'app/components/App/Navigation/types';

import { FeatureSwitch, HelpLink, NavigationLink } from './subcomponents';

/** Render navigation links pertaining to content. */
export const ContentLinks = () => {
  const episode = useEpisode();
  const message = useMessage();
  const submission = useSubmission();
  const { user } = useSession();

  const conversation = message.conversations[0];
  const incompleteFormsCount = extractIncompleteFormCount(
    submission.submissions
  );
  const unreadMessagesCount = conversation
    ? extractUnreadMessageCount(user.id, conversation.messages)
    : 0;

  const hasConversation = getHasConversation(message);
  const isEpisodeCreated = getIsEpisodeCreated(episode);
  const isEpisodeTraveling = getIsEpisodeTraveling(episode);
  const isLoggedIn = useSelector((state: State.RootState) =>
    getIsLoggedIn(state.session)
  );
  const isNarrow = useWindowDimensions().width <= breakpoints.small;

  if (!isLoggedIn) return null;

  return (
    <Styled.Wrapper testID={TestID.Navigation.ContentLinks}>
      {isEpisodeCreated && (
        <NavigationLink
          name="Home"
          path={navigationPaths.home}
          testID={TestID.Navigation.HomeLink}
        />
      )}

      <FeatureSwitch name="pre-episode-messaging" state="off">
        {/* Include 'Messages' link regardless of screen width. */}
        {hasConversation && (
          <NavigationLink
            badgeCount={unreadMessagesCount}
            name="Messages"
            path={navigationPaths.messages}
            testID={TestID.Navigation.MessagesLink}
          />
        )}
      </FeatureSwitch>

      <FeatureSwitch name="pre-episode-messaging" state="on">
        {/* Include 'Messages' link for narrow screens only. */}
        {hasConversation && isNarrow && (
          <NavigationLink
            badgeCount={unreadMessagesCount}
            name="Messages"
            path={navigationPaths.messages}
            testID={TestID.Navigation.MessagesLink}
          />
        )}
      </FeatureSwitch>

      {isEpisodeCreated && (
        <NavigationLink
          badgeCount={incompleteFormsCount}
          name="Forms"
          path={navigationPaths.forms}
          testID={TestID.Navigation.FormsLink}
        />
      )}

      {isEpisodeTraveling && (
        <NavigationLink
          name="Travel"
          path={navigationPaths.travel}
          testID={TestID.Navigation.TravelLink}
        />
      )}

      {!isNarrow && <HelpLink />}
    </Styled.Wrapper>
  );
};
