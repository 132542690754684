import { IStipendExpense, IStipendTypes } from '../types';

// used for building out a single-layered object w/ each key being a stipend type, and a total amount
export type SummarizedStipendExpense = {
  [key: string]: number;
};

// used for building out a map of stipends w/ each key being a date, and each value being an amounts object
export interface DatedStipendExpensesMap {
  [key: string]: SummarizedStipendExpense;
}

/**
 * Transforms the stipends from an array of stipends into a map of dated stipends.
 * Any stipend that doesn't belong to a predefined category falls under "Miscellaneous"
 */
export const formatStipendListItems = (list: IStipendExpense[]) => {
  const result: DatedStipendExpensesMap = {};

  const addCategoryToResult = (
    category: IStipendTypes,
    date: string,
    amount: number
  ) => {
    category = category.toLowerCase() as IStipendTypes;

    let categoryStr = category as string;

    // check to see if category is of meals type
    if (
      categoryStr === 'meals (patient)' ||
      categoryStr === 'meals (companion)'
    ) {
      categoryStr = IStipendTypes.Meals;
    } else {
      // check to see if it should be miscellaneous
      const categoryFound = Object.values(IStipendTypes).some(
        (c) => c === category
      );

      if (!categoryFound) {
        categoryStr = IStipendTypes.Miscellaneous;
      }
    }

    if (!result[date][categoryStr]) {
      result[date][categoryStr] = 0;
    }

    result[date][categoryStr] += amount;
  };

  list.forEach(({ date, description, amount }) => {
    if (!result[date]) {
      result[date] = {
        total: 0,
      };
    }

    addCategoryToResult(description, date, amount);

    result[date].total += amount;
  });

  return result;
};

export const getGrandTotal = (
  formattedStipendList: DatedStipendExpensesMap
): number => {
  const datesList = Object.keys(formattedStipendList);

  return datesList.reduce((total, date) => {
    const datedStipend = formattedStipendList[date];
    total += datedStipend.total;
    return total;
  }, 0);
};

/**
 * Returns an appropriate string needed for the 'iconType' property on <Icon ... /> components.
 * Some of the icons use a default (''), while others require 'font-awesome-5'
 */
export const getIconType = (stipendType: IStipendTypes) => {
  if (
    stipendType === IStipendTypes.Mileage ||
    stipendType === IStipendTypes.Miscellaneous
  ) {
    return 'font-awesome-5';
  }

  return '';
};

/**
 * Sorts the stipend types in alphabetical ascending order, with miscellaneous last.
 * "Miscellaneous" is any stipend type that doesn't have an associated type/category.
 * */
export const sortItems = (a: IStipendTypes, b: IStipendTypes) => {
  // puts the "Miscellaneous" at the end of the list
  if (a === IStipendTypes.Miscellaneous) {
    return 1;
  } else if (b === IStipendTypes.Miscellaneous) {
    return -1;
  }

  // otherwise sorts alphabetically, ascending
  return a.localeCompare(b);
};
