import { DeviceType, getDeviceTypeAsync } from 'expo-device';
import { Linking } from 'react-native';

export const callCarrum = async (
  event: any,
  numberToCall: string = '8888557806'
) => {
  event?.preventDefault();

  const deviceType = await getDeviceTypeAsync();
  if (deviceType !== DeviceType.PHONE && deviceType !== DeviceType.TABLET)
    return;

  Linking.openURL(`tel:${numberToCall}`);
};
