// @ts-expect-error Cannot find module 'app/assets/images/loading-puzzle gif' or its corresponding type declarations.
import LoadingPuzzleUrl from 'app/assets/images/loading-puzzle.gif';
import { TestID } from 'app/components/ProcedureSearch/constants';
import { ProcedureSearchPageLoadingScreenStyles as Styled } from 'app/components/ProcedureSearch/styles';

const LoadingScreen = () => {
  return (
    <Styled.Wrapper>
      <Styled.Animation.Fade>
        <Styled.Animation.Background>
          <Styled.Animation.Image
            resizeMode="contain"
            source={LoadingPuzzleUrl}
            testID={TestID.ProcedureSearch.LoadingAnimation}
          />
        </Styled.Animation.Background>
      </Styled.Animation.Fade>
    </Styled.Wrapper>
  );
};

export default LoadingScreen; // NOTE: potential common component
