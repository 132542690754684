import { uniqBy } from 'lodash';

import {
  ReceiveEscalationAction,
  RECEIVE_ESCALATION,
} from 'app/actions/escalationActions';
import { EscalationState } from 'types/escalation';
import { Redux } from 'types/redux';

const initialEscalationState: EscalationState = {
  escalations: [],
  loading: false,
};

export default function reducer(
  state: EscalationState = initialEscalationState,
  action: Redux.Action
): EscalationState {
  switch (action.type) {
    case RECEIVE_ESCALATION:
      return handleReceiveEscalation(state, action as ReceiveEscalationAction);

    default:
      return state;
  }
}

function handleReceiveEscalation(
  state: EscalationState,
  action: ReceiveEscalationAction
): EscalationState {
  return {
    ...state,
    escalations: uniqBy(
      [...state.escalations, action.escalation],
      (escalation) => escalation.id
    ),
  };
}
