import { Platform } from 'react-native';

import Constants from 'expo-constants';
import * as Device from 'expo-device';

import API from 'app/util/api.json';

/**
 * Read the APP_ENV environment variable as defined during build time.
 * https://docs.expo.dev/eas-update/environment-variables/#creating-an-envts-file-to-get-environment
 */
export const APP_ENV = Constants.expoConfig?.extra?.appEnv || 'development';

/**
 * Allow overriding the "infrastructure" setting to support migrating from
 * Heroku to AWS.
 */
export const APP_INFRASTRUCTURE =
  Constants.expoConfig?.extra?.appInfrastructure || 'heroku';

/**
 * Dynamically read the application environment in the web
 * application to support dynamic subdomain deployments.
 *
 * @example
 * patient-app.staging.carrumhealth.com => "staging"
 * patient-app-staging.carrumhealth.com => "staging"
 * patient-app.testprod.carrumhealth.com => "production"
 * my.carrumhealth.com => "production"
 */
export const getEnvironmentForWeb = () => {
  if (['test', 'development'].includes(APP_ENV)) return APP_ENV;
  const regexp = new RegExp('patient-app(?:-|.)(.+).carrumhealth.com');
  const matches = window?.location?.hostname?.match(regexp);
  const subdomain = (matches && matches[1]) || 'unknown';

  if (subdomain === 'testprod') return 'production';
  if (Object.keys(API).includes(subdomain)) return subdomain;

  return APP_ENV;
};

/**
 * Determine the local network IP if running in development.
 */
export const LOCAL_NETWORK =
  process.env.DOCKER === 'true'
    ? 'http://localhost'
    : Constants.expoConfig.hostUri
    ? `http://${Constants.expoConfig.hostUri.replace(/:\d+$/, '')}`
    : window?.location?.hostname
    ? `http://${window.location.hostname}`
    : 'http://localhost'; // Fallback for unit tests

export const ENVIRONMENT = Platform.select({
  default: APP_ENV,
  web: getEnvironmentForWeb(),
});

export const formatUrlForInfrastructureTwo = (url) => {
  if (['test', 'development'].includes(ENVIRONMENT)) return;

  if (ENVIRONMENT === 'production') {
    return url.replace('.carrumhealth.com', '.testprod.carrumhealth.com');
  }

  return url.replace(
    `-${ENVIRONMENT}.carrumhealth.com`,
    `.${ENVIRONMENT}.carrumhealth.com`
  );
};

const formatAPIUrl = (url) => {
  let formattedUrl = withLocalNetwork(url);
  const regexp = new RegExp(`patient-app.${ENVIRONMENT}.carrumhealth.com`);

  if (['development', 'test'].includes(ENVIRONMENT)) return formattedUrl;
  // TODO: Remove the following line when we've migrated CI to AWS.
  if (APP_INFRASTRUCTURE === 'heroku') return formattedUrl;

  if (/\.testprod\.carrumhealth\.com/.test(window?.location?.hostname || '')) {
    return formatUrlForInfrastructureTwo(formattedUrl);
  }

  if (Platform.OS !== 'web' || regexp.test(window?.location?.hostname || '')) {
    return formatUrlForInfrastructureTwo(formattedUrl);
  }

  return formattedUrl;
};

const withLocalNetwork = (url) =>
  url.replace('http://localhost', LOCAL_NETWORK);

export const CORE_API_URL = formatAPIUrl(API[ENVIRONMENT].core);

export const CARE_API_URL = formatAPIUrl(API[ENVIRONMENT].care);

export const MESSAGE_API_URL = formatAPIUrl(API[ENVIRONMENT].message);

export const UPLOAD_API_URL = formatAPIUrl(API[ENVIRONMENT].upload);

export const PRICE_API_URL = formatAPIUrl(API[ENVIRONMENT].price);

export const GOOGLE_API_KEY = Platform.select({
  default: 'AIzaSyCIR0keQ-WYcXqofaQQgLrrIpMt_OsUaew',
  web: 'AIzaSyBQVYjt93PFnUDn-DIbMIkyydx4Q3AFFpM',
});

export const SENTRY_DSN = Platform.select({
  web: 'https://dc9ad325ea68473e991ec0dcbe4ebf87@o149626.ingest.sentry.io/1197794',
  default:
    'https://62b033cf51b94249ab13555578c8bcbc@o149626.ingest.sentry.io/1199350',
});

export const CARRUM_DIGEST =
  'U2FsdGVkX19CU2IjMHdJ3xBQMYAZln4S+mWScih+tBm1VUFFjVi7jQs/x70mqhci';

export const CDN_URL = 'https://carrum-cdn.s3.amazonaws.com';

export const MINIMUM_PASSWORD_LENGTH = 8;

export const useNativeDriver = Platform.select({ default: true, web: false });

// The average number of milliseconds for a native modal view to dismiss.
export const MODAL_DISMISSAL_TIMEOUT = 280;

export const REQUEST_RECEIVED_TEXT = 'Thank you';

export const PATIENT_FAQ_URL = 'https://info.carrumhealth.com/patient-faq/';

export const PATIENT_SILVER_FAQ_URL =
  'https://info.carrumhealth.com/patient-faq-silver/';

export const SINGLE_CLICK_REGISTRATION = 'singleClickRegistration';

/**
 * Log errors using Sentry when running in non-development mode,
 * including staging and production builds of the web and native
 * app.
 *
 * Explicitly prevent launching in a simulator when running e2
 * tests against the staging environment.
 */
export const SENTRY_ENABLED = ENVIRONMENT !== 'development' && Device.isDevice;
