import styled, { css } from 'styled-components/native';
import {
  ButtonGroup as ButtonGroupRaw,
  ButtonGroupProps as ButtonGroupPropsRaw,
} from 'react-native-elements';

type ButtonGroupProps = ButtonGroupPropsRaw & {
  testID?: string;
};

import { View } from 'app/components/Common/Styled';

export const ButtonGroup = styled(ButtonGroupRaw).attrs(({ theme }) => ({
  containerStyle: {
    marginHorizontal: 0,
    marginVertical: theme.spacing,
    maxWidth:
      theme.windowDimensions.width < theme.breakpoints.small ? 350 : 'auto',
  },
  selectedTextStyle: { color: theme.colors.white },
  selectedButtonStyle: { backgroundColor: theme.colors.mediumBlue },
}))<ButtonGroupProps>``;

export const OptionsWrapper = styled(View)`
  ${({ theme }) => css`
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 0;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-vertical: ${theme.spacing}px;
  `}
`;

/**
 * Apply padding to a separate inner container to ensure flexbox calculations
 * are accurate for the various page layouts.
 */
export const PaddingWrapper = styled(View)`
  ${({ theme }) => css`
    padding: ${theme.spacing}px;
  `}
`;

export const SectionWrapper = styled(View)`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    flex: ${theme.windowDimensions.width < theme.breakpoints.small
      ? '3 0 0'
      : '1 0 0'};
  `}
`;
