import {
  stepOneDefaultFormValues as defaultFormValues,
  TestID,
} from 'app/components/Register/constants';

import {
  getStepOneValidations,
  isMobileDevice,
  makeCall,
} from 'app/components/Register/helpers';

import {
  useCallback,
  useMemo,
  useRef,
  useState,
  useValidations,
} from 'app/components/Register/hooks';

import {
  ButtonWrapper,
  DatePicker,
  FormError,
  FormView,
  HalfWidthButton,
  Input,
  LegalContent,
  Paragraph,
  ScreenWrapper,
} from 'app/components/Register/styles';

import { StepOneProps } from 'app/components/Register/types';
import LastFourMemberIDInput from 'app/components/Register/LastFourMemberIDInput';
import LastFourSSNInput from 'app/components/Register/LastFourSSNInput';

const StepOne = ({
  onSubmit,
  bypassSsnRegistration,
  error,
  loading,
}: StepOneProps) => {
  const [formValues, setFormValues] = useState(defaultFormValues);

  const validations = useMemo(
    () => getStepOneValidations(bypassSsnRegistration),
    [bypassSsnRegistration]
  );

  const { errors, isValid, validateField } = useValidations({
    formValues: {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      dateOfBirth: formValues.dateOfBirth,
      employerRegistrationId: formValues.employerRegistrationId,
    },
    validations,
  });

  const dateOfBirthInput = useRef<HTMLInputElement>();
  const employerRegistrationIdInput = useRef<HTMLInputElement>();

  const lastNameInput = useRef<HTMLInputElement>();

  // True if there are any incomplete form values
  const isIncomplete =
    !formValues.firstName ||
    !formValues.lastName ||
    !formValues.dateOfBirth ||
    !formValues.employerRegistrationId;

  const isDisabled = loading || isIncomplete || !isValid;

  /**
   * Assign focus to a specific input (if possible).
   *
   * @param  inputRef  the input ref to receive focus
   */
  const assignFocusTo = (
    inputRef: React.MutableRefObject<HTMLInputElement>
  ) => {
    if (inputRef?.current) inputRef.current.focus();
  };

  /**
   * Calls the `onSubmit` prop with the current form values if
   * the component is not loading and there are no errors.
   */
  const handleFormSubmission = useCallback(() => {
    if (!isValid) return;
    onSubmit({ ...formValues, bypassSsnRegistration });
  }, [bypassSsnRegistration, formValues, isValid, onSubmit]);

  const lastFourInputProps = {
    formValues,
    setFormValues,
    validateField,
    errors,
    handleFormSubmission,
    employerRegistrationIdInput,
  };

  const LastFourInput = bypassSsnRegistration
    ? LastFourMemberIDInput
    : LastFourSSNInput;

  return (
    <ScreenWrapper testID={TestID.Register.StepOne}>
      <>
        <Paragraph>
          For your security, we need to verify your eligibility. Your
          information must match your medical insurance ID card.
        </Paragraph>

        <FormView>
          <Input
            label="First Name"
            autoCapitalize="words"
            autoCorrect={false}
            blurOnSubmit={false}
            error={errors.firstName}
            input={{
              name: TestID.Register.FirstNameInput,
              value: formValues.firstName,
              onBlur: () => validateField('firstName'),
              onChange: (firstName) => {
                setFormValues({ ...formValues, firstName });
              },
            }}
            onSubmitEditing={() => assignFocusTo(lastNameInput)}
            enterKeyHint="next"
          />

          <Input
            label="Last Name"
            autoCapitalize="words"
            autoCorrect={false}
            error={errors.lastName}
            input={{
              name: TestID.Register.LastNameInput,
              value: formValues.lastName,
              onBlur: () => validateField('lastName'),
              onChange: (lastName) => {
                setFormValues({ ...formValues, lastName });
              },
            }}
            onSubmitEditing={() => assignFocusTo(dateOfBirthInput)}
            enterKeyHint="next"
            textInputRef={lastNameInput}
          />

          <DatePicker
            label="Date of Birth"
            error={errors.dateOfBirth}
            forwardRef
            input={{
              name: TestID.Register.DateOfBirthInput,
              value: formValues.dateOfBirth,
              onBlur: () => validateField('dateOfBirth'),
              onChange: (dateOfBirth) => {
                setFormValues({ ...formValues, dateOfBirth });
              },
            }}
            onSubmitEditing={() => assignFocusTo(employerRegistrationIdInput)}
            placeholder="Select a Date"
            ref={dateOfBirthInput}
          />

          <LastFourInput {...lastFourInputProps} />

          {error ? (
            <FormError testID={TestID.Register.ErrorMessage}>{error}</FormError>
          ) : null}
        </FormView>
      </>

      <ButtonWrapper>
        {!isMobileDevice || !error ? null : (
          <HalfWidthButton
            isFullWidth={!isMobileDevice || !error}
            onPress={makeCall}
            title="Call Us"
            type="outline"
          />
        )}
        <HalfWidthButton
          disabled={isDisabled}
          isFullWidth={!isMobileDevice || !error}
          loading={loading}
          onPress={handleFormSubmission}
          testID={TestID.Register.ContinueButton}
          title="Continue"
        />
      </ButtonWrapper>
      <LegalContent />
    </ScreenWrapper>
  );
};

export default StepOne;
