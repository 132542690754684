import {
  defaultFormState,
  Platform,
  TestID,
} from 'app/components/Login/constants';

import {
  callCarrum,
  goToEmailForm,
  loginCoreUser,
  receiveSessionError,
  requestMagicLink,
} from 'app/components/Login/helpers';

import {
  useEffect,
  useForm,
  useRef,
  useState,
  useDispatch,
} from 'app/components/Login/hooks';

import {
  Anchor,
  Biometrics,
  Button,
  ButtonContainer,
  CheckBox,
  Controller,
  FormErrorContainer,
  FormView,
  Subtitle,
  HeaderWrapper,
  HelpText,
  Icon,
  Input,
  Message,
  Text,
  TogglePasswordButton,
} from 'app/components/Login/styles';

import { PasswordProps } from 'app/components/Login/types';

const Password = ({
  appState,
  error = '',
  formValues = {},
  loading = false,
  onSubmit,
  softLogout,
  setLoginStep,
  setFormValues,
}: PasswordProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({ defaultValues: formValues });

  const [showPassword, setShowPassword] = useState(false);
  const passwordInput = useRef(null);

  const focusPassword = () => passwordInput?.current?.focus();
  const togglePassword = () => setShowPassword(!showPassword);
  const email = formValues?.email;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!formValues?.email) return setLoginStep('email');

    focusPassword();
  }, []);

  return (
    <Password.Wrapper testID={TestID.Login.PasswordForm}>
      <Password.Header
        error={error}
        dispatch={dispatch}
        formValues={formValues}
        setFormValues={setFormValues}
        setLoginStep={setLoginStep}
        softLogout={softLogout}
      />

      <Controller
        control={control}
        rules={{ minLength: 1, required: true }}
        name="password"
        render={({ field }) => (
          <Input
            label="Password"
            input={field}
            onSubmitEditing={handleSubmit(onSubmit)}
            enterKeyHint="go"
            rightIcon={
              <TogglePasswordButton onPress={togglePassword}>
                <Icon name={showPassword ? 'visibility' : 'visibility-off'} />
              </TogglePasswordButton>
            }
            secureTextEntry={!showPassword}
            textInputRef={passwordInput}
          />
        )}
      />

      {Platform.OS == 'web' && (
        <Controller
          control={control}
          name="shouldRememberDevice"
          render={({ field }) => (
            <CheckBox label="Remember me for 30 days" input={field} />
          )}
        />
      )}

      <ButtonContainer>
        <Button
          title="Log in"
          disabled={!isDirty || errors.password ? true : false}
          loading={loading}
          onPress={handleSubmit(onSubmit)}
          testID={TestID.Login.SubmitPasswordButton}
        />
      </ButtonContainer>

      <Password.Footer
        dispatch={dispatch}
        email={email}
        setLoginStep={setLoginStep}
        appState={appState}
        error={error}
        loginCoreUser={loginCoreUser}
      />
    </Password.Wrapper>
  );
};

const PasswordWrapper = ({ children, testID }) => {
  return <FormView testID={testID}>{children}</FormView>;
};

const PasswordHeader = ({
  error,
  dispatch,
  formValues,
  setFormValues,
  setLoginStep,
  softLogout,
}) => {
  return (
    <>
      {error ? (
        <FormErrorContainer>
          <Message>{error}</Message>
        </FormErrorContainer>
      ) : softLogout ? (
        <Text></Text>
      ) : null}

      {formValues?.email && (
        <HeaderWrapper>
          <Subtitle>{formValues.email}</Subtitle>

          <HelpText testID={TestID.Login.ChangeEmailLink}>
            <Anchor
              title="Change email"
              onPress={() =>
                goToEmailForm({
                  defaultFormState,
                  dispatch,
                  formValues,
                  receiveSessionError,
                  setFormValues,
                  setLoginStep,
                })
              }
            />
          </HelpText>
        </HeaderWrapper>
      )}
    </>
  );
};

const PasswordFooter = ({
  appState,
  dispatch,
  email,
  error,
  loginCoreUser,
  setLoginStep,
}) => {
  const sendMagicLink = async ({ isPasswordReset = false }) => {
    if (!email) return;

    const nextLoginStep = isPasswordReset
      ? 'magic-password-reset-sent'
      : 'magic-link-sent';

    const success = dispatch(
      requestMagicLink({ userEmail: email, isPasswordReset })
    );

    if (success) setLoginStep(nextLoginStep); // proceed to confirmation screen
  };

  return (
    <>
      <HelpText>
        <Anchor
          onPress={() => sendMagicLink({ isPasswordReset: true })}
          title="Forgot password?"
        />
      </HelpText>

      <HelpText>
        <Anchor
          title="Login without a password"
          onPress={() => sendMagicLink({ isPasswordReset: false })}
          testID={TestID.Login.SendMagicLink}
        />
        {'\n'}
        using a link sent to your email.
      </HelpText>

      <Biometrics
        appState={appState}
        error={error}
        onSubmit={(params) => dispatch(loginCoreUser(params))}
      />

      <HelpText>
        Need help? Call us at&nbsp;
        <Anchor onPress={(e) => callCarrum(e)} title="1-888-855-7806" />.
      </HelpText>
    </>
  );
};

Password.Wrapper = PasswordWrapper;
Password.Header = PasswordHeader;
Password.Footer = PasswordFooter;

export default Password;
