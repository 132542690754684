import {
  Button as ButtonRaw,
  ButtonProps as ButtonPropsRaw,
} from 'react-native-elements';
import { Icon as IconRaw } from 'react-native-elements';
import styled, { css } from 'styled-components/native';

import { Text as TextRaw, View } from 'app/components/Common/Styled';

interface ButtonProps extends ButtonPropsRaw {
  mobileIcon: ButtonPropsRaw['icon'];
}

export const Button = styled(ButtonRaw).attrs<ButtonProps>(
  ({ mobileIcon: icon, theme }) => {
    const isSmallScreen =
      theme.windowDimensions.width < theme.breakpoints.medium;

    return {
      buttonStyle: {
        backgroundColor: 'transparent',
        borderColor: theme.colors.white,
        borderWidth: isSmallScreen ? 0 : 1,
        borderRadius: 4,
        margin: theme.spacing / 2,
      },
      iconContainerStyle: isSmallScreen ? {} : { height: 0, width: 0 },
      titleStyle: isSmallScreen
        ? { display: 'none' }
        : {
            color: theme.colors.white,
          },
      icon: isSmallScreen ? icon : null,
    };
  }
)<ButtonProps>``;

export const Container = styled(View)`
  ${({ theme }) => {
    const isSmallScreen =
      theme.windowDimensions.width < theme.breakpoints.medium;

    return css`
      align-items: center;
      background-color: ${theme.colors.mediumBlue};
      flex: 0 0 auto;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: ${isSmallScreen ? 'space-between' : 'center'};
      z-index: ${({ theme }) => theme.zIndexes.base};
    `;
  }}
`;

export const Icon = styled(IconRaw).attrs(({ theme }) => ({
  color: theme.colors.white,
}))``;

export const Text = styled(TextRaw)`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    margin: ${theme.spacing / 2}px;
  `}
`;
