import { breakpoints, TestID } from 'app/components/App/Navigation/constants';

import { getIsLoggedIn } from 'app/components/App/Navigation/helpers';

import {
  useSelector,
  useWindowDimensions,
} from 'app/components/App/Navigation/hooks';

import { NavigationLinksStyles as Styled } from 'app/components/App/Navigation/styles';

import { State } from 'app/components/App/Navigation/types';

import { ContentLinks, GuestLinks, ProfileLinks } from './subcomponents';

/**
 * Render the navigation links responsively.
 *
 * - for narrow screens, render links as side panel items.
 * - for wide screens, render links as top nav items and/or as profile menu items.
 *
 * Render the navigation links conditionally based on authentication status.
 *
 * - for authenticated users, render content links
 * - for authenticated users, render profile links to side panel for narrow screens
 * - for authenticated users, render profile links to menu for wide screens
 * - for non-authenticated users, render guest links
 */
export const NavigationLinks = () => {
  const isLoggedIn = useSelector((state: State.RootState) =>
    getIsLoggedIn(state.session)
  );
  const isNarrow = useWindowDimensions().width <= breakpoints.small;

  return (
    <Styled.Wrapper testID={TestID.Navigation.NavigationLinks}>
      {isLoggedIn && <ContentLinks />}
      {isLoggedIn && isNarrow && <ProfileLinks layout="column" />}
      {!isLoggedIn && <GuestLinks />}
    </Styled.Wrapper>
  );
};
