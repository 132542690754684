import {
  DetailsRow,
  DetailsText,
  DollarCircle,
  ExperienceLabel,
  ExperienceWrapper,
  iconConfig,
  IconWrapper,
  MessagesCircle,
} from 'app/components/SelectYourDoctor/Cards/ExperienceSection/styles';
import { useSession, useOopEstimate } from 'app/hooks';
import { getOopEstimate } from 'app/util/oop';

interface ExperienceSectionProps {
  isSilver?: boolean;
}

const ExperienceSection = ({ isSilver = false }: ExperienceSectionProps) => {
  const { data, loading } = useOopEstimate();
  const { user } = useSession();

  const careSupportText = `${isSilver ? 'Basic' : 'Premium'} care support`;
  const isPartnerClient = user?.employer?.type === 'partner';

  // What the UI refers to a "cost estimate" is an "out-of-pocket" estimate according to the API.
  const { carrumEstimate, nonCarrumEstimate } = getOopEstimate(data);

  // Silver providers are not fully covered by Carrum, so show the non-Carrum cost.
  const estimate = isSilver ? nonCarrumEstimate : carrumEstimate;

  // Sometimes a cost estimate cannot or will not load from the API.
  const hasEstimate = typeof estimate === 'number' && !isNaN(estimate);

  // Cost details do not apply when the employer is a partner like Point32.
  const hasVisibleCostDetails = (loading || hasEstimate) && !isPartnerClient;

  return (
    <ExperienceWrapper isSilver={isSilver}>
      <ExperienceLabel isSilver={isSilver} />

      <DetailsRow>
        <IconWrapper>
          <MessagesCircle {...iconConfig} />
        </IconWrapper>

        <DetailsText>{careSupportText}</DetailsText>
      </DetailsRow>

      {hasVisibleCostDetails && (
        <DetailsRow>
          <IconWrapper>
            <DollarCircle {...iconConfig} />
          </IconWrapper>

          <DetailsText>
            Estimated Cost&nbsp;
            {loading ? 'calculating...' : `$${estimate}`}
          </DetailsText>
        </DetailsRow>
      )}
    </ExperienceWrapper>
  );
};

export default ExperienceSection;
