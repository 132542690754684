import { connect } from 'react-redux';
import { fetchReferralSources } from 'app/actions/episodeActions';
import {
  createCoreUser,
  receiveSessionError,
  verifyEligiblePatient,
} from 'app/actions/sessionActions';
import { getUserEmployer } from 'app/actions/userActions';

import Register from 'app/components/Register';
import { getBypassSsnRegistration } from 'app/selectors/session';

const mapStateToProps = ({ episode, session }) => ({
  error: session.error,
  loading: session.loading,
  referralSources: episode.referralSources.map((referralSource) => ({
    label: referralSource.name,
    value: referralSource.key,
  })),
  bypassSsnRegistration: getBypassSsnRegistration(session),
});

const mapDispatchToProps = (dispatch) => ({
  createCoreUser: (user) => dispatch(createCoreUser(user)),
  fetchReferralSources: () => dispatch(fetchReferralSources()),
  getUserEmployer: (clientId) => dispatch(getUserEmployer(clientId)),
  receiveSessionError: (error) => dispatch(receiveSessionError(error)),
  verifyEligiblePatient: (patient) => dispatch(verifyEligiblePatient(patient)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
