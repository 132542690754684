import styled, { css } from 'styled-components/native';

import { View } from 'app/components/Common/Styled';

export {
  DollarCircle,
  MessagesCircle,
} from 'app/components/Common/svg-components';

export {
  DetailsRow,
  DetailsText,
  iconConfig,
  IconWrapper,
} from 'app/components/SelectYourDoctor/Cards/styles';

export { default as ExperienceLabel } from 'app/components/Common/ExperienceLabel';

export const ExperienceWrapper = styled(View)<{ isSilver: boolean }>`
  ${({ theme }) => css`
    background-color: ${({ isSilver }) => (isSilver ? '#F4F4F4' : '#FCF7E7')};
    border-radius: 4px;
    padding: ${theme.spacing / 2}px;
  `}
`;
