import React, { useCallback } from 'react';

import Input from 'app/components/Common/Input';
import { maskPhoneNumber } from './helpers';

export * from './helpers';

/**
 * An input component with a phone number mask for web.
 */
const PhoneInput = (originalProps) => {
  const {
    input: { onChange, ...inputProps },
    disabled,
    ...props
  } = originalProps;

  const handleChange = useCallback(
    (value) => onChange(maskPhoneNumber(value, inputProps.value)),
    [inputProps, onChange]
  );

  return (
    <Input
      {...props}
      label={props.label}
      disabled={disabled}
      input={{
        onChange: handleChange,
        ...inputProps,
      }}
      inputMode="tel"
      style={{ border: 'none' }}
      // Expose test ID using input name to match redux-form components.
      data-testid={inputProps.name}
      // Support `onSubmitEditing()` interaction to match other inputs.
      onKeyPress={({ key }) => {
        if (key === 'Enter') props.onSubmitEditing?.();
      }}
    />
  );
};

PhoneInput.propTypes = {
  ...Input.propTypes,
};

PhoneInput.defaultProps = {
  ...Input.defaultProps,
};

export default PhoneInput;
