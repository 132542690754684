import styled, { css } from 'styled-components/native';

import { loadingAnimationTimeout } from './constants';
import { IconType } from './types';

import { AbsoluteFixedContainer } from 'app/components/Common/Styled';
import { DotIndicator } from 'app/components/Common/svg-components';
import { Fade } from 'app/components/Common/Animated';
import { default as IconLink } from 'app/components/Common/IconLink';
import { Image } from 'react-native';
import { KeyboardAvoidingView } from 'react-native';
import { Platform } from 'react-native';
import ProcedureSearchResultsWrapper from 'app/components/ProcedureSearch/ProcedureSearchResults/Wrapper';
import SearchBar from 'app/components/Common/SearchBar';
import SearchSuggestions from 'app/components/ProcedureSearch/ProcedureSearchPage/SearchSuggestions';
import SubCategoryButtons from 'app/components/ProcedureSearch/ProcedureSearchPage/SubCategoryButtons';
import { Text } from 'app/components/Common/Styled';
import TrackableButton from 'app/components/Common/TrackableButton';
import { View } from 'app/components/Common/Styled';

export { LetUsKnow } from 'app/components/LetUsKnow';
export { default as Onboarding } from 'app/components/Common/Onboarding';
export { default as ProcedureSearchPageFooter } from 'app/components/ProcedureSearch/ProcedureSearchPage/Footer';
export { default as ProcedureSearchPageLetUsKnow } from 'app/components/ProcedureSearch/ProcedureSearchPage/LetUsKnow';
export { default as ProcedureSearchPageLoadingScreen } from 'app/components/ProcedureSearch/ProcedureSearchPage/LoadingScreen';
export { default as ProcedureSearchPageSearchInterface } from 'app/components/ProcedureSearch/ProcedureSearchPage/SearchInterface';
export { default as ProcedureSearchPageOnboardingOverlay } from 'app/components/ProcedureSearch/ProcedureSearchPage/OnboardingOverlay';

const StyledIcon = styled(IconLink).attrs(({ iconType, theme }) => {
  return {
    color: theme.colors.primary,
    containerStyle: {
      flex: 1,
      flexBasis: 'auto',
    },
    fontSize: theme.fontSize,
    iconType: IconType[iconType],
    size: 26,
  };
})``;

export const ProcedureSearchPageFooterStyles = {
  NoResults: {
    CtaButton: styled(TrackableButton).attrs(({ theme }) => {
      return {
        buttonStyle: {
          paddingTop: theme.spacing,
        },
        containerStyle: {
          marginBottom: theme.spacing * 2,
        },
        titleStyle: {
          fontSize: theme.fontSizes.small,
        },
      };
    })``,
    Message: styled(Text)`
      ${({ theme }) => css`
        font-size: ${theme.fontSizes.body3}px;
        text-align: center;
      `}
    `,
    Wrapper: styled(View)``,
  },

  Wrapper: styled(View)``,
};

export const ProcedureSearchPageSearchInterfaceStyles = {
  DotIndicator: styled(DotIndicator)`
    align-self: center;
    margin: ${({ theme }) => theme.spacing}px;
  `,

  NoResultsText: styled(Text)`
    font-size: ${({ theme }) => theme.fontSizes.body3}px;
    padding-top: ${({ theme }) => theme.spacing}px;
    text-align: justify;
  `,

  SearchBar: styled(SearchBar).attrs(({ theme }) => {
    return {
      blurOnSubmit: true,
      cancelButtonProps: {
        buttonStyle: { display: 'none' },
        disabled: true,
      },
      clearIcon: { color: theme.colors.bodyText, name: 'close' },
      containerStyle: {
        borderRadius: theme.spacing / 4,
      },
      inputContainerStyle: {
        backgroundColor: theme.colors.white,
      },
      placeholder: 'Type condition or procedure here',
      placeholderTextColor: theme.colors.placeholderText,
      platform: Platform.OS.replace('web', 'default'),
      /** Prevent the clear icon from affecting the component layout. */
      rightIconContainerStyle: {
        position: 'absolute',
        right: 0,
      },
      searchIcon: { color: theme.colors.bodyText, name: 'search', size: 28 },
    };
  })``,

  SearchInterface: styled(KeyboardAvoidingView).attrs(({ theme }) => ({
    behavior: Platform.select({
      android: 'position',
    }),
    keyboardVerticalOffset: theme.spacing * -2,
  }))`
    flex: 1 0 auto;

    /* Allow <SearchResultsPositionWrapper /> to cover contents rendered after */
    z-index: ${({ theme }) => theme.zIndexes.base};
  `,

  SearchSuggestions,

  SubCategoryButtons,

  Title: styled(Text).attrs({
    align: 'center',
    size: 'h1',
  })`
    ${({ theme }) => css`
      flex: 0 1 auto;
      margin-bottom: ${theme.spacing * 3.75}px;
      margin-top: ${theme.spacing * 4}px;
    `}
  `,
};

export const ProcedureSearchPageLoadingScreenStyles = {
  Animation: {
    Background: styled(View)`
      ${({ theme }) => css`
        align-items: center;
        background-color: ${theme.colors.sand};
        justify-content: center;
        height: ${theme.windowDimensions.height}px;
        width: ${theme.windowDimensions.width}px;
      `}
    `,
    Fade: styled(Fade).attrs({
      delay: loadingAnimationTimeout,
      out: true,
    })``,
    Image: styled(Image)`
      ${({ theme }) => css`
        height: ${theme.isNarrow ? theme.spacing * 10 : theme.spacing * 30}px;
        width: ${theme.isNarrow ? theme.spacing * 15 : theme.spacing * 45}px;
        max-width: 100%;
      `}
    `,
  },
  Wrapper: styled(AbsoluteFixedContainer).attrs({
    fill: true,
  })`
    ${({ theme }) => css`
      pointer-events: none;
      z-index: ${theme.zIndexes.overlay};
    `}
  `,
};

export const ProcedureSearchPageStyles = {
  Wrapper: styled(ProcedureSearchResultsWrapper).attrs(({ theme }) => {
    return {
      containerStyle: {
        padding: theme.spacing,
      },
    };
  })``,
};
