import { Pressable } from 'react-native';
import { Icon, Text } from 'react-native-elements';
import styled, { css } from 'styled-components/native';

import { View } from 'app/components/Common/Styled';

import { Status, TaskStatus } from './types';

const greenBackground = '#DBE6CF';
const greenText = '#4A8410';

export const TextWrapper = styled(View)`
  flex: 1 1 auto;
`;

export const StatusText = styled(Text)<Status>`
  ${({ status, theme }) => {
    const { colors, fontFamilyMedium, fontSizes } = theme;
    const { primary, bodyText } = colors;

    const statusColorMap = {
      [TaskStatus.Complete]: greenText,
      [TaskStatus.Current]: primary,
      [TaskStatus.Upcoming]: bodyText,
    };

    return css`
      color: ${statusColorMap[status]};
      font-family: ${fontFamilyMedium};
      font-size: ${fontSizes.body2}px;
    `;
  }}
`;

export const TitleText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.bodyText};
    font-family: ${theme.fontFamilyMedium};
    font-size: ${theme.fontSizes.body1}px;
    margin-top: ${theme.spacing / 2}px;
  `}
`;

export const Button = styled(Pressable)<Status>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-color: #cccccc;
  border-radius: 8px;
  border-width: 1px;
  flex-direction: row;
  flex: 1 0 auto;
  margin-bottom: ${({ theme }) => theme.spacing / 2}px;
  padding: ${({ theme }) => theme.spacing * 1.25}px;
`;

export const NonButton = styled(View)<Status>`
  align-items: center;
  background-color: ${({ status, theme }) =>
    status === TaskStatus.Complete ? greenBackground : theme.colors.white};
  border-color: ${({ status, theme }) =>
    status === TaskStatus.Complete ? greenBackground : theme.colors.white};
  border-radius: 8px;
  border-width: 1px;
  flex-direction: row;
  flex: 1 0 auto;
  margin-bottom: ${({ theme }) => theme.spacing / 2}px;
  padding: ${({ theme }) => theme.spacing * 1.25}px;
`;

export const IconsWrapper = styled(View)`
  align-items: center;
  flex-direction: row;

  ${({ theme }) =>
    theme.windowDimensions.width <= theme.breakpoints.small
      ? css`
          margin-left: ${({ theme }) => theme.spacing}px;
        `
      : css`
          margin-left: ${({ theme }) => theme.spacing * 3}px;
        `}
`;

export const StatusIcon = styled(Icon).attrs<Status>(({ status, theme }) => {
  const { primary, bodyText } = theme.colors;
  const statusColorMap = {
    [TaskStatus.Complete]: greenText,
    [TaskStatus.Current]: primary,
    [TaskStatus.Upcoming]: bodyText,
  };
  const statusIconNamerMap = {
    [TaskStatus.Complete]: 'check',
    [TaskStatus.Current]: 'arrow-forward',
  };

  return {
    color: statusColorMap[status],
    name: statusIconNamerMap[status],
    size: 24,
  };
})<Status>``;

export const HelpIcon = styled(Icon).attrs(() => ({
  color: '#FFCA58',
  name: 'lightbulb-outline',
  reverse: true,
  size: 16,
}))``;
