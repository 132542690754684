import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';

import FileIcon from 'app/components/Common/FileIcon';
import { ResponsivePressable } from 'app/components/Common/ResponsivePressable';
import SizedImage from 'app/components/Common/SizedImage';
import SizedVideo from 'app/components/Common/SizedVideo';

import { isImage, isVideo } from 'app/util/methods';
import theme from 'app/util/theme';

/**
 * Renders an image or an icon for a file.
 */
const FilePreview = ({
  children,
  containerStyle,
  file,
  controls = true,
  iconPadding = theme.spacing / 2,
  maxHeight = 102,
  onLongPress,
  onPress,
}) => (
  <View style={containerStyle}>
    {isImage(file) ? (
      <ResponsivePressable onPress={onPress} onLongPress={onLongPress}>
        <SizedImage
          maxHeight={maxHeight}
          source={file}
          containerStyle={styles.container}
        />
      </ResponsivePressable>
    ) : isVideo(file) ? (
      <SizedVideo
        height={maxHeight}
        source={file}
        controls={controls}
        containerStyle={styles.container}
      />
    ) : (
      <FileIcon
        file={file}
        onPress={onPress}
        onLongPress={onLongPress}
        containerStyle={[styles.container, { padding: iconPadding }]}
      />
    )}
    {children}
  </View>
);

/**
 * @property {array} file A file to display a preview for.
 * @property {Component} children Child content to render inside the container (optional).
 * @property {string|object} containerStyle Additional styles to apply to the container (optional).
 * @property {boolean} controls Display controls for video players on supported types (optional, defaults to true).
 * @property {integer} iconPadding Padding to apply to <FileIcon /> instances (optional, defaults to 10).
 * @property {integer} maxHeight Max height to apply to <SizedImage /> instances (optional, defaults to 102).
 * @property {function} onLongPress A function to call when long pressing a <FileIcon /> instance (optional).
 * @property {function} onPress A function to call when pressing a <FileIcon /> instance (optional).
 */
FilePreview.propTypes = {
  file: PropTypes.string.isRequired,
  children: PropTypes.node,
  containerStyle: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.object,
  ]),
  controls: PropTypes.bool,
  iconPadding: PropTypes.number,
  maxHeight: PropTypes.number,
  onLongPress: PropTypes.func,
  onPress: PropTypes.func,
};

export default FilePreview;

const styles = StyleSheet.create({
  container: {
    borderColor: '#eee',
    borderRadius: 10,
    borderWidth: 1,
    overflow: 'hidden',
    backgroundColor: '#fff',
    maxWidth: '100%',
  },
});
