import axios from 'axios';
import { decode as base64decode } from 'base-64';

import { receivePersonalizationDetails } from 'app/actions/sessionActions/action-types';
import { showGlobalError } from 'app/actions/uiActions';
import { CORE_API_URL } from 'app/util/constants';
import { push } from 'app/util/history';
import { Routes } from 'app/util/routes';

/**
 * Fetches a Base64 encoded JSON string for a given single click registration token that
 * will return the first and last name for the associated patient, which can be used to
 * personalize the `OneClickWelcome` page.
 */
export const personalizeSingleClickRegistration = (token?: string) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${CORE_API_URL}/scr/${token}`, {
        params: { personalize: true },
      });

      const {
        data: { details: encodedDetails },
      } = response;

      const personalizationDetails = JSON.parse(base64decode(encodedDetails));

      dispatch(receivePersonalizationDetails(personalizationDetails));
    } catch (error) {
      /**
       * In the case of an expired or invalid SCR token, we're unable to
       * fetch the personalization details for the `<OneClickWelcome />`
       * page, so instead redirect to an error page.
       *
       * NOTE: Technically, the API will issue a redirect, but the default
       * behavior for web browsers is to follow that redirect as an AJAX
       * request, not a URL change in the browser itself.
       */
      push(`/${Routes.Register}/${token}/invalid`);
    }
  };
};

export default personalizeSingleClickRegistration;
