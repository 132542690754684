import React from 'react';
import HtmlView from 'app/components/Common/HtmlView';
import { Paragraph, UnorderedList } from './styles';

interface PhysicianBiographyProps {
  text: string;
}

/**
 * Renders the biography for a Physician record in the platform using the
 * `physician.details` property.  This component handles both HTML and plain
 * text biographies - the latter being mostly for backwards compatability.
 */
const PhysicianBiography = ({ text }: PhysicianBiographyProps) => {
  const biographyIsHtml = /<.*>.*<\/.*>/.test(text);
  const biographySections = sanitizeText(text || '').split(/\n\s*\n/);

  if (biographyIsHtml) return <HtmlView html={text} />;

  return biographySections.map((text) =>
    /^\s*\*/.test(text) ? (
      <UnorderedList key={text} items={separateListItems(text)} />
    ) : (
      <Paragraph key={text}>{condenseSpaceCharacters(text)}</Paragraph>
    )
  );
};

export default PhysicianBiography;

/**
 * Handles legacy plain-text biographies that have extra white-space characters.
 */
const condenseSpaceCharacters = (text: string) =>
  text.replace(/^\s+/, '').replace(/\s+/g, ' ');

const separateListItems = (text: string) =>
  text.split('* ').filter((isPresent: boolean) => !!isPresent);

const sanitizeText = (text: string) =>
  text.replace('&#39;', "'").replace(/<(|\/)\w+>/, '');
