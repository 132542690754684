import React, { ReactNode } from 'react';

import { Label } from './styles';

interface FloatingLabelProps {
  children: ReactNode;
}

const FloatingLabel = ({ children }: FloatingLabelProps) =>
  children ? <Label>{children}</Label> : null;

export default FloatingLabel;
