import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { logEvent, EVENTS } from 'app/util/analytics';
import { parameterize, trimWhitespace } from 'app/util/methods';
import { Routes } from 'app/util/routes';
import { TestID } from 'app/util/test-id';

import { parameterizeFormForReduxForms, wasQuestionShown } from './helpers';

import { StyleSheet, View } from 'react-native';
import { Redirect, Route, Switch } from '@cross-platform/react-router-native';
import { ProgressNav } from 'app/components/Common/ProgressNav';
import FormDetails from './FormDetails';
import FormDetailsLayout from './FormDetailsLayout';
import FormList from './FormList';
export default class Forms extends Component {
  /**
   * @property  {function}  initialize             function to initialize the redux-form values
   * @property  {function}  fetchSubmissions       function to fetch submissions from the API
   * @property  {object}    formValues             redux-form values
   * @property  {string}    patientName            name of patient
   * @property  {function}  showLocalNotification  function to display local notifications
   * @property  {array}     submissions            array of Submission records from the API
   * @property  {function}  updateSubmission       function to update the current episode
   * @property  {object}    submission             current Submission record
   * @property  {boolean}   loading                whether current form is loading
   */
  static propTypes = {
    initialize: PropTypes.func.isRequired,
    fetchSubmissions: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    patientName: PropTypes.string.isRequired,
    showLocalNotification: PropTypes.func.isRequired,
    submissions: PropTypes.array.isRequired,
    updateSubmission: PropTypes.func.isRequired,

    iterations: PropTypes.array,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
    iterations: [],
  };

  /**
   * Initialize the redux form state for a given submission object.
   *
   * @param  {object}  submission  submission object with a `formKey` and `formQuestions`
   */
  initialize = ({ formName, formQuestions }) => {
    const form = parameterizeFormForReduxForms(
      formQuestions,
      this.props.patientName
    );

    this.props.initialize(form);

    logEvent(EVENTS.forms.view, { form: formName });
  };

  /**
   * Apply responses to a submission object.
   *
   * @param   {object}  submission  submission record
   * @param   {object}  responses   object with form response properties (key
   *                                is label; value is response)
   *
   * @return  {object}              submission with form responses applied
   */
  applyChanges = (submission, responses) => {
    const result = { ...submission };

    Object.keys(responses).forEach((key) => {
      const response = responses[key];
      const question = result.formQuestions.find(
        ({ label }) => parameterize(label) === key
      );

      // Assign the response to the question.
      question.response = response;

      if (question.inputType === 'acknowledgement') {
        question.response = wasQuestionShown(question, result.formQuestions)
          ? true
          : null;
      } else if (question.inputType === 'signature') {
        question.response = response.toUpperCase() === this.props.patientName;
      }
    });

    return result;
  };

  /** Display a local message to the user indicating save success. */
  notify = (isComplete) => {
    this.props.showLocalNotification({
      id: Math.random(),
      icon: 'check',
      title: isComplete ? 'Form submitted.' : 'Changes saved.',
      dismissIn: isComplete ? 5000 : 2000,
      status: isComplete ? 'success' : 'info',
    });
  };

  /**
   * Submit form responses to API with appropriate timestamps. Resolve with a
   * boolean indicating success.
   *
   * @param   {string}   key         key of submission record to save
   * @param   {integer}  order       order of submission record to save
   * @param   {boolean}  isComplete  whether form is being submitted for completion
   *
   * @return  {promise}              promise that resolves after submission
   */
  submitChanges = async (key, order = 0, isComplete = false) => {
    const submission = this.applyChanges(
      this.props.submissions.find(
        (submission) => submission.formKey === key && submission.order === order
      ),
      this.props.formValues
    );

    submission.startedOn = submission.startedOn || new Date();

    if (isComplete) submission.completedOn = new Date();

    const isSuccess = await this.props.updateSubmission(
      submission.id,
      submission
    );

    if (isSuccess) {
      logEvent(isComplete ? EVENTS.forms.submit : EVENTS.forms.save, {
        form: submission.formName,
      });
    }

    return isSuccess;
  };

  /**
   * Save form responses for a submission with a given form key, and set the
   * "started on" date.
   *
   * @param   {string}   key    key of submission record to save
   * @param   {integer}  order  order of submission record to save
   *
   * @return  {promise}         promise that resolves after saving the record
   */
  onSave = (key, order = 0) => this.submitChanges(key, order, false);

  /**
   * Save form responses for a submission with a given form key, and set the
   * "submitted on" date.
   *
   * @param   {string}   key    key of submission record to save
   * @param   {integer}  order  order of submission record to save
   *
   * @return  {promise}         promise that resolves after submission
   */
  onSubmit = (key, order = 0) => this.submitChanges(key, order, true);

  render() {
    const { fetchSubmissions, formValues, iterations, submissions } =
      this.props;

    const useLayout = iterations?.some(
      (iteration) => Object.keys(iteration.formLayout).length > 0
    );

    const FormDetailsComponent = useLayout ? FormDetailsLayout : FormDetails;
    const noIterationsExist = iterations.length == 0;

    return (
      <View testID={TestID.Forms.Page} style={styles.container}>
        <Switch>
          <Route
            exact
            path="/forms"
            render={() => (
              <FormList
                forms={submissions}
                refresh={fetchSubmissions}
                loading={this.props.loading}
              />
            )}
          />

          <Route
            exact
            path="/forms/:id"
            render={() => {
              if (noIterationsExist)
                return <Redirect to={`/${Routes.Forms}`} />;

              return (
                <ProgressNav open>
                  <FormDetailsComponent
                    onSave={this.onSave}
                    onSubmit={this.onSubmit}
                    values={trimWhitespace(formValues || {})}
                    initialize={this.initialize}
                    loading={this.props.loading}
                    patientName={this.props.patientName}
                    iterations={iterations}
                  />
                </ProgressNav>
              );
            }}
          />
        </Switch>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
