import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Animated, Dimensions, Easing, StyleSheet } from 'react-native';

import { useNativeDriver } from 'app/util/constants';

/**
 * A sliding container that allows content to slide in/out of view.
 */
export default class SlidingView extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    containerStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  };

  static defaultProps = {
    containerStyle: null,
  };

  state = {
    slide: new Animated.Value(0),
  };

  componentWillUnmount = () => {
    clearTimeout(this.slideTimeout);
  };

  slideLeft = () => {
    const config = {
      duration: 160,
      useNativeDriver,
    };

    const offset = Dimensions.get('window').width;

    Animated.sequence([
      Animated.timing(this.state.slide, {
        ...config,
        easing: Easing.in(Easing.ease),
        toValue: offset * -1,
      }),
      Animated.timing(this.state.slide, {
        ...config,
        toValue: offset,
        duration: 0,
      }),
      Animated.timing(this.state.slide, {
        ...config,
        easing: Easing.out(Easing.ease),
        toValue: 0,
      }),
    ]).start();
  };

  slideRight = () => {
    const config = {
      duration: 160,
      useNativeDriver,
    };

    const offset = Dimensions.get('window').width * -1;

    Animated.sequence([
      Animated.timing(this.state.slide, {
        ...config,
        easing: Easing.in(Easing.ease),
        toValue: offset * -1,
      }),
      Animated.timing(this.state.slide, {
        ...config,
        toValue: offset,
        duration: 0,
      }),
      Animated.timing(this.state.slide, {
        ...config,
        easing: Easing.out(Easing.ease),
        toValue: 0,
      }),
    ]).start();
  };

  render() {
    return (
      <Animated.View
        style={StyleSheet.flatten([
          this.props.containerStyle,
          { transform: [{ translateX: this.state.slide }] },
        ])}
      >
        {this.props.children}
      </Animated.View>
    );
  }
}
