import { Platform } from 'react-native';
import * as Updates from 'expo-updates';

import { ENVIRONMENT } from 'app/util/constants';
import { logError } from 'app/util/methods';

import { EnvironmentTypes } from 'types/environments';
import { PlatformTypes } from 'types/platforms';

const expectedErrors = [
  // Ignore issues when attempting to reload the app
  'You cannot check for updates in development mode',
];

/**
 * Checks for new versions using the Expo API
 * and reloads the app if one is found.
 *
 * @return {promise} A promise that resolves after checking and loading a new version.
 */
const checkForUpdates = async () => {
  try {
    const update = await Updates.checkForUpdateAsync();
    if (!update.isAvailable) return;

    await Updates.fetchUpdateAsync();
    await Updates.reloadAsync();
  } catch (error) {
    // Ignore expected errors that might occur when attempting to load updates.
    if (expectedErrors.some((e) => error.message.includes(e))) return;
    logError('Unable to fetch updates from Expo', error);
  }
};

// Support over-the-air updates in Staging and Demo.
if (
  [PlatformTypes.Android, PlatformTypes.iOS].includes(Platform.OS) &&
  [
    EnvironmentTypes.Staging,
    EnvironmentTypes.Demo,
    EnvironmentTypes.UserAcceptanceTesting,
  ].includes(ENVIRONMENT)
) {
  checkForUpdates();
}
