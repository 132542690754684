import React from 'react';
import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

type PinColors = 'gold' | 'silver';

interface Props {
  height?: number;
  style?: any;
  tier: PinColors;
  width?: number;
}

const mapTierToColors: { [key in PinColors]: string[] } = {
  silver: ['#828282', '#CFCFCF', '#828282', '#696969'],
  gold: ['#B77618', '#F4CD82', '#BA8516', '#8A632C'],
};

const MapPin = ({ height = 50, style, tier = 'gold', width = 39 }: Props) => {
  const colorSheme = mapTierToColors[tier];
  return (
    <Svg
      width={width}
      height={height}
      fill="none"
      style={style}
      viewBox="0 0 39 50"
    >
      <Path
        d="M17.7031 46.8516C18.5117 48.1094 20.3984 48.1094 21.207 46.8516C34.3242 27.9844 36.75 26.0078 36.75 19C36.75 9.47656 29.0234 1.75 19.5 1.75C9.88672 1.75 2.25 9.47656 2.25 19C2.25 26.0078 4.58594 27.9844 17.7031 46.8516ZM19.5 26.1875C15.457 26.1875 12.3125 23.043 12.3125 19C12.3125 15.0469 15.457 11.8125 19.5 11.8125C23.4531 11.8125 26.6875 15.0469 26.6875 19C26.6875 23.043 23.4531 26.1875 19.5 26.1875Z"
        fill={`url(#paint0_linear_2694_43812_${tier})`}
      />
      <Path
        d="M17.7031 46.8516L18.9649 46.0404L18.9502 46.0176L18.9347 45.9953L17.7031 46.8516ZM21.207 46.8516L19.9754 45.9953L19.9599 46.0176L19.9453 46.0404L21.207 46.8516ZM16.4414 47.6627C17.8404 49.839 21.0698 49.839 22.4688 47.6627L19.9453 46.0404C19.8428 46.1998 19.6682 46.2949 19.4551 46.2949C19.242 46.2949 19.0674 46.1998 18.9649 46.0404L16.4414 47.6627ZM22.4386 47.7078C28.9478 38.3454 32.9029 33.0363 35.1823 29.2525C36.341 27.3292 37.1226 25.7135 37.6047 24.0795C38.09 22.4346 38.25 20.8448 38.25 19H35.25C35.25 20.6591 35.1068 21.9443 34.7273 23.2306C34.3447 24.5277 33.7 25.8993 32.6126 27.7045C30.3998 31.3777 26.5835 36.4906 19.9754 45.9953L22.4386 47.7078ZM38.25 19C38.25 8.64814 29.8519 0.25 19.5 0.25V3.25C28.195 3.25 35.25 10.305 35.25 19H38.25ZM19.5 0.25C9.0532 0.25 0.75 8.65324 0.75 19H3.75C3.75 10.2999 10.7202 3.25 19.5 3.25V0.25ZM0.75 19C0.75 20.8408 0.903639 22.4285 1.37902 24.0718C1.85165 25.7057 2.6212 27.3217 3.76933 29.2468C6.02856 33.035 9.96026 38.3423 16.4715 47.7078L18.9347 45.9953C12.3288 36.4936 8.53394 31.379 6.34591 27.7102C5.2704 25.9068 4.63614 24.5355 4.26087 23.2382C3.88835 21.9504 3.75 20.6631 3.75 19H0.75ZM19.5 24.6875C16.2855 24.6875 13.8125 22.2145 13.8125 19H10.8125C10.8125 23.8714 14.6286 27.6875 19.5 27.6875V24.6875ZM13.8125 19C13.8125 15.863 16.2977 13.3125 19.5 13.3125V10.3125C14.6164 10.3125 10.8125 14.2307 10.8125 19H13.8125ZM19.5 13.3125C22.6247 13.3125 25.1875 15.8753 25.1875 19H28.1875C28.1875 14.2184 24.2816 10.3125 19.5 10.3125V13.3125ZM25.1875 19C25.1875 22.2023 22.637 24.6875 19.5 24.6875V27.6875C24.2693 27.6875 28.1875 23.8836 28.1875 19H25.1875Z"
        fill={colorSheme[3]}
      />
      <Defs>
        <LinearGradient
          id={`paint0_linear_2694_43812_${tier}`}
          x1="0"
          y1="25.0291"
          x2="39"
          y2="25.0291"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor={colorSheme[0]} />
          <Stop offset="0.51" stopColor={colorSheme[1]} />
          <Stop offset="1" stopColor={colorSheme[2]} />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default MapPin;
