import React from 'react';
import Svg, { Circle, Rect } from 'react-native-svg';

export interface InfoIconProps {
  size?: number;
  color?: string;
}

const InfoIcon = ({ size = 80, color = '#5F7881' }: InfoIconProps) => (
  <Svg fill="none" height={size} viewBox="0 0 80 80" width={size}>
    <Circle cx="40" cy="40" r="40" fill={color} />
    <Circle cx="40" cy="21.0313" r="6.18557" fill="white" />
    <Rect
      x="34.6392"
      y="33.8145"
      width="10.7216"
      height="32.1649"
      rx="4"
      fill="white"
    />
  </Svg>
);

export default InfoIcon;
