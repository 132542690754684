import { Audio } from 'expo-av';
import { debounce } from 'lodash';
import { Platform } from 'react-native';

export const SOUNDS = {
  loading: require('app/assets/sounds/Loading.wav'),
  error: require('app/assets/sounds/Error-5.m4a'),
  success: require('app/assets/sounds/Success-2.m4a'),
  info: require('app/assets/sounds/Notification-1.m4a'),
  warning: require('app/assets/sounds/Notification-2.m4a'),
  received: require('app/assets/sounds/Tab-2.m4a'),
  sent: require('app/assets/sounds/Tab-3.m4a'),
};

/**
 * Load all sounds in the sound library.
 *
 * @return {promise} A promise that resolves after loading all the sounds.
 */
export const loadSoundsAsync = async () => {
  Object.keys(SOUNDS).forEach(async (key) => {
    try {
      await loadSoundAsync(key);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Failed to load sound', error);
    }
  });
};

/**
 * Load a sound in the sound library.
 *
 * @param {string} sound The name of the sound to load.
 * @return {promise} A promise that resolves after loading the sound.
 */
export const loadSoundAsync = async (sound) => {
  // Avoid loading the same sound twice.
  if (SOUNDS[sound]?.play) return;

  const path = SOUNDS[sound];
  const soundObject = new Audio.Sound();

  // Replace keys with sound objects.
  SOUNDS[sound] = soundObject;

  // Use debounce to prevent colliding sounds
  soundObject.play = debounce(soundObject.replayAsync, 2000, { leading: true });

  // Preload the sound file
  await soundObject.loadAsync(path);

  // Modify the default volume for loud sounds
  await soundObject.setVolumeAsync(
    ['info', 'warning'].indexOf(sound) !== -1 ? 0.5 : 1.0
  );
};

/**
 * Play a sound in the sound library.
 *
 * @param {string} sound The name of the sound to play.
 */
export const playSound = async (sound) => {
  if (!SOUNDS[sound]?.play) return;
  if (!SOUNDS[sound]?._loaded) return;
  if (!isPlaybackEnabled()) return;

  try {
    await SOUNDS[sound].play();
  } catch (error) {
    // Do nothing
  }
};

/**
 * In web browsers, media playback is disabled until there is some
 * kind of user interaction on the page.  In order to detect if this
 * has happened, we create a new `AudioContext` instance and check to
 * see if the status changes from "suspended" to "running."
 *
 * https://sites.google.com/a/chromium.org/dev/audio-video/autoplay
 */
let _isPlaybackEnabled = Platform.select({ default: true, web: false });

/**
 * A function that returns the status of media playback in the app.
 *
 * @returns boolean True if media playback is enabled.
 */
const isPlaybackEnabled = debounce(
  () => {
    if (_isPlaybackEnabled) return true;

    try {
      const audioContext = new AudioContext();
      _isPlaybackEnabled = audioContext?.state === 'running';
    } catch (error) {
      // Do nothing if this API fails or is unavailable
    }

    return _isPlaybackEnabled;
  },
  400,
  { leading: true }
);
