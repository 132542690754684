import { isEmpty } from 'lodash';
import { Episode } from 'types/episode';

export const formatCoords = (
  {
    lat,
    lng,
    latitude,
    longitude,
  }: { lat: number; lng: number; latitude?: string; longitude?: string },
  offset?: { x: number; y: number }
) => ({
  lat: (lat || parseFloat(latitude)) + (offset && offset.y ? offset.y : 0),
  lng: (lng || parseFloat(longitude)) + (offset && offset.x ? offset.x : 0),
  latitude: (lat || parseFloat(latitude)) + (offset && offset.y ? offset.y : 0),
  longitude:
    (lng || parseFloat(longitude)) + (offset && offset.x ? offset.x : 0),
  latitudeDelta: 12,
  longitudeDelta: 12,
});

/**
 * Get a string representing a time comparison between now and a prior time. The difference is rounded up by the unit: mins, hours, days.
 *
 * @param {number} durationInSeconds Duration provided by Google in seconds.
 * @returns A string escalating from mins to hours to days depending on the length of time since an event.
 */
export const formatDurationString = (durationInSeconds) => {
  const minuteDivisor = 60;
  const hourDivisor = 60 * 60;

  const ageInMinutes = Math.floor(durationInSeconds / minuteDivisor);
  if (ageInMinutes < 60) return `${ageInMinutes} min`;

  const ageInHours = Math.floor(durationInSeconds / hourDivisor);
  const extraMinutes = ageInMinutes % 60;

  return `${ageInHours} hr${` and ${extraMinutes && extraMinutes} min`}`;
};

/**
 * Generates a string representing a time comparison between now and a prior time. The difference is rounded up by the unit: mins, hours, days.
 *
 * @param {object} userLocation User's Profile address
 * @param {object} destination Location of the destination facility
 * @returns A Promise with a string escalating from mins to hours to days depending on the length of time since an event.
 */
export const fetchTravelDuration = (
  userLocation: { lat: number; lng: number },
  destination: { lat: number; lng: number }
): Promise<string> => {
  // TODO: investigate including the global `google` var on mobile
  const directionsService = new google.maps.DirectionsService();

  return directionsService
    .route({
      origin: { lat: userLocation.lat, lng: userLocation.lng },
      destination: {
        lat: destination.lat,
        lng: destination.lng,
      },
      travelMode: google.maps.TravelMode.DRIVING,
    })
    .then((res) => {
      return `${formatDurationString(
        res.routes[0].legs[0].duration.value
      )} drive`;
    })
    .catch((err) => {
      console.error({ err });
      return 'Error';
    });
};

export const getProductOffering = (
  id: number,
  list: Episode.ProductOffering[]
): string =>
  list.find((offering) => offering.id?.toString() === id?.toString());

/**
 * Map a product offering ID to a string value
 */
export const getProductOfferingString = (
  id: number,
  list: Episode.ProductOffering[]
): string => getProductOffering(id, list)?.code;

/**
 * Filter a given option for a given tier selection
 */
export const filterOptionByTier = (option, tier) =>
  tier === 'gold' ? !option.isSilver : true;

export const isSilverDoctor = (doctor: Episode.PossiblePhysician) => {
  return doctor?.hospital?.productOffering?.code === 'silver';
};

export const isSilverHospital = (hospital: Episode.Hospital) => {
  return hospital?.productOffering?.code === 'silver';
};
