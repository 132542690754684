import { TestID } from 'app/components/App/Navigation/constants';

import { useDispatch, useLocation } from 'app/components/App/Navigation/hooks';

import {
  routeTo,
  setPanelOpen,
  setProfileMenuOpen,
} from 'app/components/App/Navigation/helpers';

import {
  NavBadge,
  NavigationLinkStyles as Styled,
} from 'app/components/App/Navigation/styles';

import { NavigationLinkProps } from 'app/components/App/Navigation/types';

export const NavigationLink = ({
  badgeCount = 0,
  layout = 'row',
  name,
  onPress = null,
  path,
  testID = TestID.Navigation.NavigationLinkPressable,
}: NavigationLinkProps) => {
  const dispatch = useDispatch();
  const location = useLocation();

  // Navigate to route if a path has been provided. If not, invoke the event
  // handler that has been passed in as a prop.
  const handleLinkPress = (path: string) => {
    dispatch(setPanelOpen(false));
    dispatch(setProfileMenuOpen(false));
    if (path) {
      routeTo(path);
    } else {
      onPress && onPress();
    }
  };

  const isActive = location?.pathname === path; // link path matches current

  return (
    <Styled.Pressable
      isActive={isActive}
      layout={layout}
      onPress={() => handleLinkPress(path)}
      testID={testID}
    >
      <Styled.Text isActive={isActive} layout={layout}>
        {name}
      </Styled.Text>
      {badgeCount > 0 && <NavBadge value={badgeCount} />}
    </Styled.Pressable>
  );
};
