import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

import { Modal as ModalRaw, ModalProps } from 'app/components/Common/Modal';
export { default as Messages } from 'app/components/Messages';
import { View } from 'app/components/Common/Styled';

export const Modal = styled(ModalRaw).attrs(
  ({ theme }) =>
    ({
      scrollable: false,
      overlayStyle: theme.isNarrow
        ? {
            height: theme.windowDimensions.height - theme.spacing * 4,
            // The modal is always vertically centered on web, so restrict
            // width to 90% to add a small margin and look nicer.
            width: Platform.select({ web: '90%', default: '100%' }) as string,
          }
        : {
            height: '90%',
            maxHeight: theme.breakpoints.small,
            maxWidth: theme.breakpoints.medium,
            width: '90%',
          },
    } as ModalProps)
)<ModalProps>``;

export const InnerContainer = styled(View)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: ${theme.spacing / 2}px;
    margin-right: ${theme.spacing / 2}px;
  `}
`;
