import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { Input as FormInput, withTheme } from 'react-native-elements';

import FloatingLabel from 'app/components/Common/FloatingLabel';
import FormError from 'app/components/Common/FormError';
import Select from 'app/components/Common/Select/';

import theme from 'app/util/theme';

import { numberWithPadding } from './util';

/**
 * A date picker component wrapper for interacting
 * with redux-form on browsers.
 */
export class DatePicker extends Component {
  /**
   * @property {object} input An object with props passed down from redux-form (required).
   * @property {function} input.onChange A function to call when the value changes (required).
   * @property {function} input.onBlur A function to call after the changes.
   * @property {*} input.value The currently selected value.
   * @property {boolean} disabled If true the input is disabled.
   * @property {string} error An error message to display above the select.
   * @property {string} label A label to display above the select.
   * @property {object} containerStyle An optional style to pass to the container.
   */
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func,
      value: PropTypes.any,
    }).isRequired,
    theme: PropTypes.object.isRequired,

    disabled: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.string,
    containerStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  };

  static defaultProps = {
    disabled: false,
    label: '',
    containerStyle: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      year:
        props.input && props.input.value ? props.input.value.split('-')[0] : '',
      month:
        props.input && props.input.value ? props.input.value.split('-')[1] : '',
      day:
        props.input && props.input.value ? props.input.value.split('-')[2] : '',
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.input &&
      this.props.input.value &&
      this.props.input.value !== prevProps.input.value
    ) {
      this.setState({
        year: this.props.input.value.split('-')[0],
        month: this.props.input.value.split('-')[1],
        day: this.props.input.value.split('-')[2],
      });
    }
  }

  focus = () => {
    if (this.select) this.select.querySelector('select').focus();
  };

  onChange = async () => {
    const { year, month, day } = this.state;

    const dayWithPadding = numberWithPadding(day);

    if (year.length === 4 && dayWithPadding != 0 && month) {
      this.props.input.onChange([year, month, dayWithPadding].join('-'));
    } else {
      // this allows for de-validation on change after a valid value has been entered
      this.props.input.onChange('');
    }

    if (this.props.input.onBlur) {
      await this.props.input.onBlur();
    }
  };

  onYearChange = (year) => {
    this.setState({ year }, this.onChange);
  };

  onMonthChange = (month) => {
    this.setState({ month }, this.onChange);
  };

  onDayChange = (day) => {
    this.setState({ day }, this.onChange);
  };

  render() {
    const { name } = this.props.input;

    const dayWithoutLeadingZero = this.state.day.replace(/^0+/, '');

    return (
      <View
        style={[
          this.props.theme.Input.containerStyle,
          this.props.containerStyle,
        ]}
        testID={name}
      >
        <FloatingLabel>{this.props.label}</FloatingLabel>

        <View
          style={styles.inputWrapper}
          ref={(select) => (this.select = select)}
        >
          <Select
            input={{
              name: `${name}--month`,
              value: this.state.month,
              onChange: (value) => this.onMonthChange(value),
            }}
            containerStyle={StyleSheet.flatten([styles.selectContainer])}
            options={monthOptions}
            disabled={this.props.disabled}
            pickerStyle={StyleSheet.flatten([
              styles.select,
              this.props.theme.Input.inputStyle,
              this.props.disabled ? styles.inputDisabled : null,
              {
                color:
                  this.state.month === ''
                    ? theme.colors.dimText
                    : theme.colors.black,
              },
            ])}
          />

          <FormInput
            containerStyle={[
              styles.inputContainer,
              this.props.disabled ? styles.inputDisabled : null,
            ]}
            disabled={this.props.disabled}
            errorStyle={{ marginVertical: 0 }}
            maxLength={2}
            value={dayWithoutLeadingZero}
            onChangeText={(value) => this.onDayChange(value)}
            placeholder="Day"
            style={styles.input}
            testID={`${name}--day`}
          />

          <FormInput
            containerStyle={[
              styles.inputContainer,
              this.props.disabled ? styles.inputDisabled : null,
            ]}
            disabled={this.props.disabled}
            errorStyle={{ marginVertical: 0 }}
            maxLength={4}
            value={this.state.year}
            onChangeText={(value) => this.onYearChange(value)}
            placeholder="Year"
            style={styles.input}
            testID={`${name}--year`}
          />
        </View>

        {this.props.error && <FormError>{this.props.error}</FormError>}
      </View>
    );
  }
}

// eslint-disable-next-line import/no-unused-modules
export default withTheme(DatePicker);

const styles = StyleSheet.create({
  inputWrapper: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },

  inputContainer: {
    borderWidth: 0,
    flex: 1,
    height: 50,
  },

  inputDisabled: {
    color: 'rgba(0, 0, 0, 0.3)',
  },

  input: {
    height: 50,
    paddingHorizontal: theme.spacing / 4,
    width: '100%',
  },
  selectContainer: {
    padding: 0,
    /**
     * Offset the natural <select /> padding in web so that the options text is
     * horizontally aligned with the label text.
     */
    marginLeft: theme.spacing / -2,
  },
  select: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    cursor: 'pointer',
    flex: 1,
    height: 50,
  },

  item: {
    marginLeft: 0,
  },

  label: {
    marginHorizontal: theme.spacing * 0.75,
  },
});

const monthOptions = [
  { label: 'Month', value: '' },
  { label: 'January', value: '01' },
  { label: 'February', value: '02' },
  { label: 'March', value: '03' },
  { label: 'April', value: '04' },
  { label: 'May', value: '05' },
  { label: 'June', value: '06' },
  { label: 'July', value: '07' },
  { label: 'August', value: '08' },
  { label: 'September', value: '09' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];
