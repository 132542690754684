import { expiredAuthLinkConfig, TestID } from 'app/components/Login/constants';

import { loginCoreUser, parseQueryParams } from 'app/components/Login/helpers';

import {
  useCallback,
  useDispatch,
  useEffect,
  useHistory,
  useLocation,
  useNextPhaseRoute,
  useSession,
  useState,
} from 'app/components/Login/hooks';

import {
  DotIndicator,
  ExpiredAuthLink,
  MagicLinkRedirectContainer,
  Redirect,
  TitleText,
} from 'app/components/Login/styles';

const Magic = () => {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();
  const currentUrl = useLocation();
  const { nextRoute } = useNextPhaseRoute();
  const { error, coreToken } = useSession();

  const { email, token, redirectTo } = parseQueryParams(currentUrl);

  useEffect(() => {
    if (email && token && !coreToken) loginUser();
    if (coreToken) setLoading(false);
  }, []);

  /** Send a login request using the token as the password param. */
  const loginUser = useCallback(async () => {
    await dispatch(loginCoreUser({ email, password: token }, true));
    setLoading(false);
  }, [email, loading, loginCoreUser, setLoading, token]);

  // display progress indicator while response to login request is pending
  if (loading) {
    return (
      <MagicLinkRedirectContainer>
        <TitleText>Attempting to log you in</TitleText>
        <DotIndicator />
      </MagicLinkRedirectContainer>
    );
  }

  // display possible pivots for user after failed login request
  if (error) {
    return (
      <ExpiredAuthLink
        guestEmail={email}
        guidanceText={expiredAuthLinkConfig.guidanceText.replace(
          '$email',
          email
        )}
        headerText={expiredAuthLinkConfig.headerText}
        history={history}
        linkText={expiredAuthLinkConfig.linkText}
        loading={loading}
        testID={TestID.Register.SingleClickRegistrationExpired}
      />
    );
  }

  // redirect user if the magic link includes a `redirect_to` query parameter
  if (redirectTo && !error) return <Redirect to={redirectTo} />;

  return <Redirect to={nextRoute} />;
};

export default Magic;
