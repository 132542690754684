/**
 * Returns an object with shortened keys for latitude and longitude
 * to match the expected format in the Episodes API.
 *
 * @param {object} coords An object with latitude/longitude properties.
 * @return {object} An object with lat/lng properties.
 */
export const formatEpisodeCoordinates = (coords) => {
  const changes = { ...coords };

  if (changes.latitude) {
    changes.lat = changes.latitude;
    delete changes.latitude;
  }

  if (changes.longitude) {
    changes.lng = changes.longitude;
    delete changes.longitude;
  }

  return changes;
};
