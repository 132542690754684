import { formatEpisodeCoordinates } from 'app/util/episodeUtils';

import createEpisode from './create-episode';

const extractRollupCodes = (rollups) => {
  if (!rollups) return [];

  const codes = [];

  rollups.forEach((rollup) => {
    codes.push(rollup.code);

    if (rollup.rollup) {
      const parentCode = rollup.rollup.code;
      codes.push(parentCode);
    }
  });

  const uniqueCodes = Array.from(new Set(codes));

  return uniqueCodes;
};

/**
 * Create a new episode from the session state with a given procedure.
 *
 * @return  {promise}  promise that resolves after creating a new episode
 */
export default ({ procedure } = { procedure: {} }) => {
  return (dispatch, getState) => {
    const { episode, session } = getState();

    const location = formatEpisodeCoordinates(session.user.location);
    const { id, email, referralSource, eligiblePatient, employer } =
      session.user;

    const productOffering = episode.productOfferingsList.find(
      ({ code }) => code === 'gold'
    );

    return dispatch(
      createEpisode({
        location,
        productOffering,
        eligiblePatient: { ...eligiblePatient, employer },
        memberFullSsn: eligiblePatient.memberFullSsn,
        subscriberFullSsn: eligiblePatient.subscriberFullSsn,
        procedureKey: procedure.key,
        offeredProcedure: {
          key: procedure.key,
          name: procedure.name,
          mandated: procedure.mandated,
          rollups: extractRollupCodes(procedure.rollups),
        },
        user: { id, email, referralSource },
        inquiryMethod: 'online',
        status: 'active',
        confirmed: true,
        verified: true,
      })
    );
  };
};
