import { isEmpty } from 'lodash';

export { createInquiryFromState } from 'app/actions/episodeActions';
export { updateInquiry } from 'app/actions/episodeActions';

export const getFormIsValid = (formValues) => {
  return (
    !!formValues.description &&
    !isEmpty(formValues.location) &&
    formValues.contactMethodPreference !== undefined
  );
};
