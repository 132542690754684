import {
  animationConfig,
  borderRadiuses,
  colors,
  navigationButton,
  navigationPanel,
  theme,
} from './constants';

import {
  useEffect,
  useEpisode,
  useRef,
  useState,
  useWindowDimensions,
} from './hooks';

import { Animated, ProgressNavStyles as Styled } from './styles';

import { ProgressNavProps } from './types';

import { ActionIcons, ProgressSection } from './subcomponents';

export const ProgressNav = ({ children, open = false }: ProgressNavProps) => {
  const [isOpen, setIsOpen] = useState(open);
  const { episode } = useEpisode();
  const { width } = useWindowDimensions();

  /**
   * navigationAnimation controls all animation related to the ProgressNav opening and closing:
   *
   *  - Nav sliding on/off the screen
   *  - Left boundary of the children (aka MainContents)
   *  - MenuToggleButton flipping left/right (in/out) of the ProgressNav and its background and icon colors
   *
   * The 0 / 1 input range is interpolated as open / closed values
   */
  const navigationAnimation = useRef(
    new Animated.Value(isOpen ? 0 : 1)
  ).current;

  const navigationPanelPositionInterpolation = navigationAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [
      navigationPanel.positions.open,
      navigationPanel.positions.closed,
    ],
  });

  const mainContentsGrowInterpolation = navigationAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [navigationPanel.width, 0],
  });

  const navigationButtonPositionInterpolation = navigationAnimation.interpolate(
    {
      inputRange: [0, 1],
      outputRange: [
        navigationButton.positions.open,
        navigationButton.positions.closed,
      ],
    }
  );

  const navigationButtonBackgroundInterpolation =
    navigationAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [colors.toggleButton.open, colors.toggleButton.closed],
    });

  const navigationButtonLeftBorderRadiusInterpolation =
    navigationAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [borderRadiuses.rounded, borderRadiuses.squared],
    });

  const navigationButtonRightBorderRadiusInterpolation =
    navigationAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [borderRadiuses.squared, borderRadiuses.rounded],
    });

  useEffect(() => {
    Animated.timing(navigationAnimation, {
      ...animationConfig,
      toValue: isOpen ? 0 : 1,
    }).start();
  }, [isOpen, navigationAnimation]);

  if (width < theme.breakpoints.medium) return children;

  if (!episode) return children;

  return (
    <Styled.Wrapper>
      <Styled.NavigationWrapper
        width={navigationPanel.width}
        style={{
          transform: [{ translateX: navigationPanelPositionInterpolation }],
        }}
      >
        {/* TopSpacer balances positioning of <BottomSection /> */}
        <Styled.TopSpacer />

        <ProgressSection />

        <ActionIcons />

        {/* TODO:
        - might be something to do with ResponsivePressable
        - check into onLongPress noOp
        - Race condition with animation?
        - Is there some other state variable that is behind (when pressing)?
        */}
        {/* MenuToggleButton Can be placed anywhere directly within NavigationWrapper */}
        <Styled.ToggleButton.Wrapper
          onLongPress={() => {}}
          onPressIn={() => {}}
          onPressOut={() => {}}
          onPress={() => setIsOpen(!isOpen)}
          style={{
            backgroundColor: navigationButtonBackgroundInterpolation,
            borderBottomLeftRadius:
              navigationButtonLeftBorderRadiusInterpolation,
            borderBottomRightRadius:
              navigationButtonRightBorderRadiusInterpolation,
            borderTopLeftRadius: navigationButtonLeftBorderRadiusInterpolation,
            borderTopRightRadius:
              navigationButtonRightBorderRadiusInterpolation,
            transform: [
              { translateX: navigationButtonPositionInterpolation },
              { translateY: navigationButton.height / -2 },
            ],
          }}
          width={navigationButton.width}
        >
          <Styled.ToggleButton.Icon
            color={isOpen ? colors.icon.open : colors.icon.closed}
            name={isOpen ? 'arrow-left' : 'arrow-right'}
            size={20}
          />
        </Styled.ToggleButton.Wrapper>
      </Styled.NavigationWrapper>

      <Styled.MainContentWrapper
        style={{
          left: mainContentsGrowInterpolation,
        }}
      >
        {children}
      </Styled.MainContentWrapper>
    </Styled.Wrapper>
  );
};
