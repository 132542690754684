import { Platform } from 'react-native';
import { createBrowserHistory, createMemoryHistory } from 'history';

let history;

if (Platform.OS === 'web') {
  history = history || createBrowserHistory();
} else {
  history = history || createMemoryHistory();
}

export default history;

const { location, goBack, push } = history;

export { location, goBack, push };
