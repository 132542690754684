import { AccountSettingsFormState, User } from './types';

/**
 * Return an object with properties of a `AccountSettingsFormState`
 * destructured from the current user object in the session.
 */
export const getAccountSettingsFormValuesFromUser = (user: User) => {
  const { email } = user;

  const formValues: AccountSettingsFormState = { email };

  Object.keys(formValues).forEach((field) => {
    if (typeof formValues[field] === 'undefined') delete formValues[field];
  });

  return formValues;
};
