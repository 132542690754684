import axios from 'axios';

import { CORE_API_URL } from 'app/util/constants';
import { unformatObject } from 'app/util/reducerUtils';

/**
 * Make a PATCH request to the Core Service User API update the user record.
 *
 * @param   {object}   params  params to apply to the user record
 *
 * @return  {promise}          promise resolves after updating the user record
 */
export default (params) => {
  return (_, getState) => {
    const { coreToken, user } = getState().session;

    return axios.patch(
      `${CORE_API_URL}/users/${user.id}`,
      { user: unformatObject({ ...params }) },
      {
        headers: { Authorization: `Bearer ${coreToken}` },
      }
    );
  };
};
