import styled, { css } from 'styled-components/native';

import { Role } from 'react-native';
import Anchor from 'app/components/Common/Anchor';
import { Button as ButtonRaw } from 'react-native-elements';
import CheckBoxRaw from 'app/components/Common/CheckBox';

import { DotIndicator as DotIndicatorRaw } from 'app/components/Common/svg-components';
import FormError from 'app/components/Common/FormError';
import { Icon as IconRaw } from 'react-native-elements';
import { default as InputRaw } from 'app/components/Common/Input';
import { KeyboardAvoidingView } from 'react-native';
import * as Linking from 'expo-linking';
import { MagicLink as MagicLinkSvg } from 'app/components/Common/svg-components';
import { MagicLinkSent as MagicLinkSentSvg } from 'app/components/Common/svg-components';
import { Platform } from 'react-native';

import { ResponsivePressable } from 'app/components/Common/ResponsivePressable';
import { Text } from 'app/components/Common/Styled';
import { View } from 'app/components/Common/Styled';

export { ActivityIndicator } from 'react-native';

export { default as Alert } from '@cross-platform/react-native-alert';

export { Anchor };

export { default as Biometrics } from 'app/components/Login/Biometrics';

export const Button = styled(ButtonRaw).attrs(({ theme }) => {
  return {
    buttonStyle: {
      height: 66,
    },
    disabledStyle: {
      backgroundColor: theme.colors.disabledNavBackground,
    },
    disabledTitleStyle: {
      color: theme.colors.white,
    },
  };
})``;

export const ButtonContainer = styled(View)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing * 1.5}px;
  `}
`;

export { CenteredScrollScreen } from 'app/components/Common/Layout';
export { Controller } from 'react-hook-form';

export const CheckBox = styled(CheckBoxRaw).attrs(({ theme }) => ({
  containerStyle: { marginBottom: theme.spacing },
}))``;

export const DotIndicator = styled(DotIndicatorRaw).attrs(({ theme }) => ({
  color: theme.colors.lightBlue,
}))``;

export { default as Email } from 'app/components/Login/Email';

export { default as EnvironmentSwitch } from 'app/components/Common/EnvironmentSwitch';

export { ExpiredAuthLink } from 'app/components/Common/ExpiredAuthLink';

export const FormErrorContainer = styled(FormError)`
  ${({ theme }) => css`
    text-align: center;
    padding: ${`5px 0px ${theme.spacing}px`};
    color: ${theme.colors.error};
    margin-bottom: ${theme.spacing}px;
  `}
`;

export const FormView = styled(View).attrs({
  role: Platform.select({
    web: 'form' as Role,
  }),
})`
  ${({ theme }) => css`
    width: 100%;
    padding: 0 ${theme.spacing * 2}px;
  `}
`;

export const HeaderWrapper = styled(View)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing * 2.5}px;
  `}
`;

export const HelpText = styled(Text)`
  ${({ theme }) => css`
    font-family: ${theme.fontFamilyMedium};
    text-align: center;
    margin-top: ${theme.spacing}px;
  `}
`;

export const Icon = styled(IconRaw).attrs(() => {
  return {
    color: '#afafaf',
  };
})`
  ${({ theme }) => css`
    position: relative;
    bottom: ${theme.spacing / 4}px;
  `}
`;

export const Input = styled(InputRaw).attrs(({ theme }) => {
  return {
    containerStyle: {
      marginBottom: theme.spacing,
    },
  };
})``;

export { KeyboardAvoidingView };

export { Linking };

export const LogoContainer = styled(View)`
  ${({ theme }) => css`
    flex-direction: row;
    justify-content: center;
    margin-top: 12%;
    margin-bottom: ${theme.spacing}px;
  `}
`;

const MagicLinkView = styled(View)`
  ${({ theme }) => css`
    width: 100%;
    padding: 0 ${theme.spacing * 2}px;
  `}
`;

export { default as MagicLinkConfirmation } from 'app/components/Login/MagicLinkConfirmation';

export const MagicLinkRedirectContainer = styled(View)`
  ${({ theme }) => css`
    align-items: center;
    margin: ${theme.spacing * 4}px auto;
  `}
`;

export { default as MagicLinkRequest } from 'app/components/Login/MagicLinkRequest';

export const Message = styled(Text).attrs({ color: 'error' })`
  ${({ theme }) => css`
    text-align: center;
    margin-bottom: ${theme.spacing * 5}px;
    padding: 0 ${theme.spacing * 2}px;
  `}
`;

export const ParagraphText = styled(Text)`
  ${({ theme }) => css`
    margin: ${theme.spacing}px 0;
    text-align: center;
  `}
`;

export { default as Password } from 'app/components/Login/Password';

export { Redirect } from '@cross-platform/react-router-native';

export { RedVerticalLogo } from 'app/components/Common/svg-components';

export { Route } from '@cross-platform/react-router-native';

export { Routes } from 'app/util/routes';

export const Subtitle = styled(Text).attrs({ size: 'h3' })`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing * -0.5}px;
    text-align: center;
  `}
`;

export { Switch } from '@cross-platform/react-router-native';

export { Text };

export const TitleText = styled(Text).attrs(({ theme }) => {
  return {
    h1: true,
    h1Style: {
      color: theme.colors.bodyText,
      marginTop: theme.spacing * 4,
      textAlign: 'center',
    },
  };
})``;

export const TogglePasswordButton = styled(ResponsivePressable)`
  ${({ theme }) => css`
    position: absolute;
    right: ${theme.spacing / 2}px;
    top: ${theme.spacing / 2}px;
  `}
`;

export { TopBackLink } from 'app/components/Common/TopBackLink';

export const TopBackLinkWrapper = styled(View)`
  ${({ theme }) => css`
    position: absolute;
    padding: ${theme.spacing}px;
  `}
`;

export { View };

export const MagicLinkConfirmationStyles = {
  Anchor,

  Bold: styled(Text)`
    font-weight: bold;
  `,

  Image: MagicLinkSentSvg,

  ImageWrapper: styled(View)`
    margin: 0 auto;
  `,

  Text: ParagraphText,

  Wrapper: MagicLinkView,
};

export const MagicLinkRequestStyles = {
  Button,

  ButtonWrapper: ButtonContainer,

  Image: styled(MagicLinkSvg)``,

  ImageWrapper: styled(View)`
    ${({ theme }) => css`
      margin: ${theme.spacing * 0.75}px auto;
    `}
  `,

  Subtitle,

  Text: ParagraphText,

  Wrapper: MagicLinkView,
};
