import axios from 'axios';

import { fetchSubmissions } from 'app/actions/submissionActions';
import { showGlobalError } from 'app/actions/uiActions';
import { CARE_API_URL } from 'app/util/constants';

import { receiveEpisodeTaskComplete } from './action-types';
import getEpisodeSteps from './get-episode-steps';

export default (episodeId, stepId, taskId) => {
  return async (dispatch, getState) => {
    try {
      const { coreToken } = getState().session;
      const { steps } = getState().episode;

      await axios.patch(
        `${CARE_API_URL}/episodes/${episodeId}/steps/${stepId}/tasks/${taskId}`,
        {
          task: { complete: true },
        },
        {
          headers: { Authorization: `Bearer ${coreToken}` },
        }
      );

      await dispatch(receiveEpisodeTaskComplete(stepId, taskId));

      // Reload steps, tasks, and forms when completing a step.
      const currentStep = steps.find(({ id }) => stepId === id);

      if (
        currentStep &&
        currentStep.tasks.filter(({ complete }) => !complete).length === 0
      ) {
        dispatch(getEpisodeSteps());
        dispatch(fetchSubmissions());
      }
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
};
