import { TestID } from 'app/components/App/Navigation/constants';

import {
  extractIncompleteFormCount,
  extractUnreadMessageCount,
  getIsLoggedIn,
  setPanelOpen,
} from 'app/components/App/Navigation/helpers';

import {
  useDispatch,
  useMessage,
  useSelector,
  useSession,
  useSubmission,
  useUi,
} from 'app/components/App/Navigation/hooks';

import { MenuIconStyles as Styled } from 'app/components/App/Navigation/styles';

import { State } from 'app/components/App/Navigation/types';

/**
 * Render the "hamburger menu" icon which toggles the display of the side nav.
 */
export const MenuIcon = () => {
  const dispatch = useDispatch();
  const message = useMessage();
  const { user } = useSession();
  const submission = useSubmission();
  const { panelOpen: panelIsOpen } = useUi();

  const togglePanelDisplay = () => {
    dispatch(setPanelOpen(!panelIsOpen));
  };

  const conversation = message.conversations[0];
  const incompleteFormsCount = extractIncompleteFormCount(
    submission.submissions
  );
  const isLoggedIn = useSelector((state: State.RootState) =>
    getIsLoggedIn(state.session)
  );
  const unreadMessagesCount = conversation
    ? extractUnreadMessageCount(user.id, conversation.messages)
    : 0;

  const hasIncompleteForms = incompleteFormsCount > 0;
  const hasUnreadMessages = unreadMessagesCount > 0;

  const menuHasIndicator =
    isLoggedIn && (hasUnreadMessages || hasIncompleteForms);

  return menuHasIndicator ? (
    <Styled.HamburgerIconWithBadge
      // @ts-ignore
      testID={TestID.Navigation.MenuIconWithBadge}
      onPress={togglePanelDisplay}
    />
  ) : (
    <Styled.HamburgerIcon
      testID={TestID.Navigation.MenuIcon}
      onPress={togglePanelDisplay}
    />
  );
};
