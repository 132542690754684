import { ReactNode } from 'react';

import { Text } from 'react-native-elements';
import styled from 'styled-components/native';

import { View } from 'app/components/Common/Styled';

export const TabText = styled(Text)`
  color: ${({ theme }) => theme.colors.bodyText};
  font-family: ${({ theme }) => theme.fontFamilyBold};
  text-align: center;

  font-size: ${({ theme }) =>
    theme.windowDimensions.height < theme.breakpoints.shortScreen
      ? theme.fontSizes.small
      : theme.fontSizes.body2}px;
`;

export const GoldText = styled(TabText)`
  color: ${({ theme }) => theme.colors.gold};
`;

export const SilverText = styled(TabText)`
  color: ${({ theme }) => theme.colors.silver};
`;

const ColoredBackgroundText = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
`;

const ColoredBackgroundView = styled(View)<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor, theme }) =>
    theme.colors[backgroundColor]};
  border-radius: 2px;
  padding: ${({ theme }) => `0 ${theme.spacing / 8}px`};
`;

interface ColoredBackgroundProps {
  backgroundColor: string;
  children: ReactNode;
}

const ColoredBackground = ({
  backgroundColor,
  children,
}: ColoredBackgroundProps) => (
  <ColoredBackgroundView backgroundColor={backgroundColor}>
    <ColoredBackgroundText>{children}</ColoredBackgroundText>
  </ColoredBackgroundView>
);

export const GoldBackground = ({ children }) => (
  <ColoredBackground backgroundColor="gold">{children}</ColoredBackground>
);
export const SilverBackground = ({ children }) => (
  <ColoredBackground backgroundColor="silver">{children}</ColoredBackground>
);

export const TabRow = styled(View)`
  flex-direction: row;
`;

export const TabWrapper = styled(View)`
  align-items: center;
  justify-content: flex-end;
`;
