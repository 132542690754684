import React, { useState } from 'react';

import {
  FlatList,
  ScrollHint,
  SearchSuggestion,
  SearchSuggestionsContainer,
  SearchSuggestionsPositionWrapper,
} from './styles';

interface SearchSuggestionsProps {
  onSelectItem: () => void; // call with selected item index on item press
  searchSuggestions: any[]; // array of search suggestions
  suggestionCursor?: number; // index of selected item (from keyboard navigation)
}

/**
 * Render an absolute-positioned list of search suggestions.
 */
const SearchSuggestions = ({
  onSelectItem,
  searchSuggestions,
  suggestionCursor,
}: SearchSuggestionsProps) => {
  const [viewabilityState, setViewabilityState] = useState<any>();

  const lastResultKey = searchSuggestions.slice(-1)[0][0];

  const isLastItemVisible =
    viewabilityState?.viewableItems?.some(({ key }) => key === lastResultKey) ||
    false;

  return (
    <SearchSuggestionsContainer>
      <SearchSuggestionsPositionWrapper>
        <FlatList
          onViewableItemsChanged={setViewabilityState}
          persistentScrollbar
          data={searchSuggestions}
          viewabilityConfig={{
            itemVisiblePercentThreshold: 75,
          }}
          renderItem={({ item, index }) => (
            <SearchSuggestion
              item={item}
              index={index}
              selected={suggestionCursor === index}
              onPress={() => onSelectItem(index)}
            />
          )}
          keyExtractor={(item) => item[0]}
        />
        {!isLastItemVisible && (
          <ScrollHint>Scroll down for more results</ScrollHint>
        )}
      </SearchSuggestionsPositionWrapper>
    </SearchSuggestionsContainer>
  );
};

export default SearchSuggestions;
