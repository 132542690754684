import {
  MINIMUM_PASSWORD_LENGTH,
  Routes,
  TestID,
} from 'app/components/CompleteYourProfile/constants';
import { getAccountSettingsFormValuesFromUser } from 'app/components/CompleteYourProfile/helpers';
import { useNextTask } from 'app/components/CompleteYourProfile/hooks';
import { SaveProgressStyles as Styled } from 'app/components/CompleteYourProfile/styles';

export const SaveProgress = () => {
  const { presentationMode, nextTask } = useNextTask();

  const nextRoute =
    presentationMode === 'workflow'
      ? `/${Routes.Tasks}/${nextTask?.id}`
      : `/${Routes.CompleteYourProfile}/${Routes.Complete}`;

  return (
    <Styled.Form
      accountSettingsWrapper={Styled.Wrapper}
      enableStickyNavFooter={false}
      formWrapper={Styled.FormWrapper}
      getFormValuesFromUser={getAccountSettingsFormValuesFromUser}
      headerComponent={<SaveProgressHeader />}
      inputWrapper={Styled.InputWrapper}
      nextRoute={nextRoute}
      submitButtonText="Create Account"
      testIDRoot={TestID.CompleteYourProfile}
    />
  );
};

const SaveProgressHeader = () => (
  <Styled.Header.Wrapper>
    <Styled.Header.Title>Save your progress</Styled.Header.Title>
    <Styled.Header.Text>
      You are currently exploring this benefit as a Guest. Create an account to
      save your progress.
    </Styled.Header.Text>
    <Styled.Header.Text>
      Your password must be at least {MINIMUM_PASSWORD_LENGTH} characters long
      and include at least 1 upper case letter and 1 number.
    </Styled.Header.Text>
  </Styled.Header.Wrapper>
);

export default SaveProgress;
