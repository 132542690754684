import React, { useMemo } from 'react';
import { Platform } from 'react-native';
import { Icon } from 'react-native-elements';

import Text from 'app/components/Common/Styled/Text';
import theme from 'app/util/theme';

import {
  STIPEND_GRAND_TOTAL_CONTAINER,
  STIPEND_LEGEND_ICON,
} from '../constants';
import { IStipendTypes, ITravel } from '../types';
import {
  formatStipendListItems,
  getGrandTotal,
  getIconType,
  sortItems,
} from './helpers';
import {
  Amount,
  Breakdown,
  BreakdownCell,
  ContainerHeader,
  DisbursementDetail,
  DisbursementDetailLabel,
  DisbursementDetailValue,
  DisbursementWrapper,
  GrandTotalContainer,
  GrandTotalText,
  ItemRow,
  ItemRowLabel,
  StipendContainer,
  TypeIcon,
  TypeRow,
  TypesContainer,
  TypesHeader,
} from './styles';

const { primary } = theme.colors;
const medium = theme.fontSizes['medium'];

const categoryIconsMap = {
  [IStipendTypes.Baggage]: 'luggage',
  [IStipendTypes.Mileage]: 'gas-pump',
  [IStipendTypes.Miscellaneous]: 'dollar-sign',
  [IStipendTypes.Parking]: 'local-parking',
  [IStipendTypes.Meals]: 'restaurant',
  [IStipendTypes.Transportation]: 'directions-car',
};

const categoryNamesMap = {
  [IStipendTypes.Baggage]: 'Baggage',
  [IStipendTypes.Mileage]: 'Mileage',
  [IStipendTypes.Miscellaneous]: 'Miscellaneous',
  [IStipendTypes.Parking]: 'Long-term parking',
  [IStipendTypes.Meals]: 'Meals and incidentals',
  [IStipendTypes.Transportation]: 'Local transportation',
};

interface TravelStipendProps {
  travel: ITravel;
}

const TravelStipend = ({ travel }: TravelStipendProps) => {
  const expenses = travel?.expenses || [];
  if (expenses.length === 0) return null;

  const formattedStipendListItems = useMemo(() => {
    return formatStipendListItems(expenses);
  }, [expenses]);

  const grandTotal = useMemo(
    () => getGrandTotal(formattedStipendListItems),
    [formattedStipendListItems]
  );

  const getShortDate = (date: string): string =>
    date.split('/').slice(0, 2).join('/');

  const datesList = Object.keys(formattedStipendListItems);
  const disbursementStartDate = datesList[0];
  const disbursementEndDate = datesList?.slice(-1)[0];

  return (
    <StipendContainer>
      <ContainerHeader h2>Your Travel Stipend Details</ContainerHeader>
      <TypesContainer>
        <TypesHeader bold>Stipend items:</TypesHeader>

        {Object.values(IStipendTypes)
          .sort(sortItems)
          .map((stipendType: IStipendTypes) => {
            return (
              <TypeRow key={stipendType}>
                <TypeIcon
                  className={STIPEND_LEGEND_ICON} // used for integration test
                  color={primary}
                  name={categoryIconsMap[stipendType]}
                  size={medium}
                  type={getIconType(stipendType)}
                />
                <Text size="body3">{categoryNamesMap[stipendType]}</Text>
              </TypeRow>
            );
          })}
      </TypesContainer>

      <DisbursementWrapper>
        <DisbursementDetail>
          <DisbursementDetailLabel>Dates</DisbursementDetailLabel>
          <DisbursementDetailValue>
            {disbursementStartDate} - {disbursementEndDate}
          </DisbursementDetailValue>
        </DisbursementDetail>

        <DisbursementDetail>
          <DisbursementDetailLabel>Disbursement Method</DisbursementDetailLabel>
          <DisbursementDetailValue>
            {travel?.disbursementMethod || 'Not found'}
          </DisbursementDetailValue>
        </DisbursementDetail>

        <DisbursementDetail>
          <DisbursementDetailLabel>Disbursement Date</DisbursementDetailLabel>
          <DisbursementDetailValue>
            {travel.disbursementDate || 'Not found'}
          </DisbursementDetailValue>
        </DisbursementDetail>

        {travel?.disbursementMethod?.toLowerCase() === 'paypal' && (
          <DisbursementDetail>
            <DisbursementDetailLabel>
              Paypal Email Address
            </DisbursementDetailLabel>
            <DisbursementDetailValue>
              {travel.paypalEmailAddress || 'Not Found'}
            </DisbursementDetailValue>
          </DisbursementDetail>
        )}
      </DisbursementWrapper>

      {datesList.map((date) => {
        const { total, ...stipendItems } = formattedStipendListItems[date];

        return (
          <ItemRow key={date}>
            <ItemRowLabel>
              <Text size="body3" bold>
                {Platform.OS === 'web' ? date : getShortDate(date)}
              </Text>
              <Text size="body3">${total}</Text>
            </ItemRowLabel>
            <Breakdown>
              {Object.keys(stipendItems)
                .sort(sortItems)
                .map((stipendType: IStipendTypes) => {
                  const amount = stipendItems[stipendType];
                  const iconName = categoryIconsMap[stipendType];

                  return (
                    <BreakdownCell key={stipendType}>
                      <Icon
                        color={primary}
                        name={iconName}
                        size={medium}
                        type={getIconType(stipendType)}
                      />
                      <Amount size={'medium'}>${amount}</Amount>
                    </BreakdownCell>
                  );
                })}
            </Breakdown>
          </ItemRow>
        );
      })}

      <GrandTotalContainer
        id={STIPEND_GRAND_TOTAL_CONTAINER} // used for integration test
      >
        <GrandTotalText size="body3">Total</GrandTotalText>
        <GrandTotalText size="body2">${grandTotal}</GrandTotalText>
      </GrandTotalContainer>
    </StipendContainer>
  );
};

TravelStipend.defaultProps = {
  expenses: [],
};

export default TravelStipend;
