import React from 'react';
import Svg, { Path } from 'react-native-svg';

interface Props {
  color?: string;
  size?: number;
  style?: any;
}

const LuggageCircle = ({ color = '#5F7881', size = 16, style }: Props) => (
  <Svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <Path
      d="M12.4407 5H3.49153C2.66777 5 2 5.67156 2 6.5V13.5C2 14.3284 2.66777 15 3.49153 15H3.9887V15.5C3.9887 15.7763 4.21119 16 4.48588 16H5.48023C5.75492 16 5.9774 15.7763 5.9774 15.5V15H9.9548V15.5C9.9548 15.7763 10.1773 16 10.452 16H11.4463C11.721 16 11.9435 15.7763 11.9435 15.5V15H12.4407C13.2644 15 13.9322 14.3284 13.9322 13.5V6.5C13.9322 5.67156 13.2644 5 12.4407 5ZM11.9435 11.75C11.9435 11.8881 11.8323 12 11.6949 12H4.23729C4.09994 12 3.9887 11.8881 3.9887 11.75V11.25C3.9887 11.1119 4.09994 11 4.23729 11H11.6949C11.8323 11 11.9435 11.1119 11.9435 11.25V11.75ZM11.9435 8.75C11.9435 8.88813 11.8323 9 11.6949 9H4.23729C4.09994 9 3.9887 8.88813 3.9887 8.75V8.25C3.9887 8.11187 4.09994 8 4.23729 8H11.6949C11.8323 8 11.9435 8.11187 11.9435 8.25V8.75ZM6.47458 1.5H9.45763V4H10.9492V1.5C10.9492 0.671562 10.2814 0 9.45763 0H6.47458C5.65082 0 4.98305 0.671562 4.98305 1.5V4H6.47458V1.5Z"
      fill={color}
    />
  </Svg>
);

export default LuggageCircle;
