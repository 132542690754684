import styled from 'styled-components/native';
import { View } from 'react-native';

import theme from 'app/util/theme';

export const HtmlContentContainer = styled(View)`
  background-color: ${theme.colors.defaultLight};
  border-color: ${theme.colors.default};
  border-width: 1px;
  border-radius: ${({ theme }) => theme.spacing}px;
  margin: ${({ theme }) => theme.spacing}px 0;
  padding: ${({ theme }) => `${theme.spacing * 1.5}px ${theme.spacing}px`};
`;
