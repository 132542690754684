import { Button } from 'react-native-elements';
import styled, { css } from 'styled-components/native';

import { ScrollView, Text, View } from 'app/components/Common/Styled';
export { HtmlView } from 'app/components/Common/HtmlView';

export const ButtonWrapper = styled(View)`
  ${({ theme }) => css`
    align-items: center;
    flex: 0 0 auto;
    justify-content: center;
    display: ${theme.isNarrow ? 'none' : 'flex'};
  `}
`;

export const LinkButton = styled(Button)``;

export const ManualTaskButton = styled(Button)``;

export const LinkTaskContainer = styled(View)``;

export const ManualTaskContainer = styled(View)`
  flex: 1;
`;

export const ManualTaskScrollView = styled(ScrollView)`
  ${({ theme }) => css`
    padding: ${({ theme: { isNarrow, spacing } }) =>
      isNarrow ? spacing : spacing * 2}px;
    padding-bottom: ${({ theme: { isNarrow, spacing } }) =>
      isNarrow ? spacing * 3 : spacing}px;
  `}
`;

export const TaskTitle = styled(Text)`
  ${({ theme }) => css`
    align-items: center;
    padding: ${theme.spacing * 1.25}px;
  `}
`;
