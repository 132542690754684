import { Routes, TestID } from 'app/components/MySettings/constants';

import {
  BackLink,
  BackLinkContainer,
  Header,
  SectionHeaderContainer,
} from 'app/components/MySettings/styles';

import { SectionHeaderProps } from 'app/components/MySettings/types';

const SectionHeader = ({
  showBackLink = false,
  showTitle = false,
  title = '',
}: SectionHeaderProps) => {
  return (
    <>
      {showBackLink && (
        <BackLinkContainer>
          <BackLink
            testID={TestID.MySettings.SectionHeaderBackLink}
            title="Back"
            to={`/${Routes.MySettings}`}
          />
        </BackLinkContainer>
      )}
      <SectionHeaderContainer>
        {showTitle && <Header h2>{title}</Header>}
      </SectionHeaderContainer>
    </>
  );
};

export default SectionHeader;
