import { Image } from 'react-native';
import { Text } from 'react-native-elements';
import styled, { css } from 'styled-components/native';

import { View } from 'app/components/Common/Styled';

export { default as ExperienceSection } from 'app/components/SelectYourDoctor/Cards/ExperienceSection';
export { default as LocationSection } from 'app/components/SelectYourDoctor/Cards/LocationSection';
export {
  CardPaddingWrapper,
  CardWrapper,
  FacilityImage,
  LearnMoreButton,
  SpacerColumn,
  TopImageSection,
  View,
} from 'app/components/SelectYourDoctor/Cards/styles';

export const FacilityCity = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.bodyText};
    font-family: ${theme.fontFamily};
    font-size: ${theme.fontSizes.small}px;
  `}
`;

export const FacilityName = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.bodyText};
    font-family: ${theme.fontFamilyBold};
    font-size: ${theme.fontSizes.body3}px;
  `}
`;

export const DoctorImage = styled(Image)`
  ${({ theme }) => {
    const doctorImageSize = theme.spacing * 4;
    return css`
      border-radius: ${doctorImageSize / 2}px;
      height: ${doctorImageSize}px;
      width: ${doctorImageSize}px;
    `;
  }}
`;

export const DoctorImageNameRow = styled(View)`
  ${({ theme }) => css`
    align-items: center;
    flex: 0 1 auto;
    flex-direction: row;
    padding: 0 ${theme.spacing / 2}px;
  `}
`;

export const DoctorName = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.bodyText};
    font-family: ${theme.fontFamilyBold};
    font-size: ${theme.fontSizes.body2}px;
    /* horizontal padding doesn't work on Text - margin is used instead */
    margin: 0 ${theme.spacing / 2}px;
  `}
`;

export const DoctorTitle = styled(FacilityCity)`
  padding: 0 ${({ theme }) => theme.spacing / 2}px;
`;

export const TextColumn = styled(View)`
  ${({ theme }) => css`
    flex: 0 1 auto;
    margin-left: ${theme.spacing / 2}px;
  `}
`;
