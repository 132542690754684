import React from 'react';

import { useHistory } from '@cross-platform/react-router-native';

import {
  CardPaddingWrapper,
  CardWrapper,
  ExperienceSection,
  FacilityImage,
  HospitalName,
  LearnMoreButton,
  LocationSection,
  SpacerColumn,
  TopImageSection,
  View,
} from './styles';

import { TestID } from 'app/util/test-id';
import { SelectYourDoctorRoutes } from 'app/components/SelectYourDoctor/routes';

interface Address {
  city: string;
  state: string;
}

interface FacilityCardProps {
  address: Address;
  id: string;
  images: { logo: string };
  isSilver?: boolean;
  latitude: string;
  longitude: string;
  name: string;
}

const FacilityCard = ({
  address,
  id,
  images: { logo } = { logo: '' },
  isSilver = false,
  latitude,
  longitude,
  name,
}: FacilityCardProps) => {
  const history = useHistory();
  return (
    <CardWrapper testID={TestID.SelectYourDoctor.FacilityCard}>
      <TopImageSection>
        <FacilityImage source={{ uri: logo }} />
      </TopImageSection>

      <CardPaddingWrapper>
        <SpacerColumn>
          <View>
            <HospitalName>{name}</HospitalName>

            <LocationSection
              address={address}
              latitude={latitude}
              longitude={longitude}
            />
          </View>
          <View>
            <ExperienceSection isSilver={isSilver} />

            <LearnMoreButton
              onPress={() =>
                history.push(`${SelectYourDoctorRoutes.Facilities}/${id}`)
              }
              title="Learn More"
              testID={TestID.SelectYourDoctor.FacilityLearnMore}
            />
          </View>
        </SpacerColumn>
      </CardPaddingWrapper>
    </CardWrapper>
  );
};

export default FacilityCard;
