import { removeAttachment } from 'app/components/Messages/helpers';

import { AttachmentsStyles as Styled } from 'app/components/Messages/styles';

import { AttachmentsProps } from 'app/components/Messages/types';

/** Render previews of pending message attachments. */
export const Attachments = ({
  attachments,
  formValues,
  isRendered = false,
  setFormValues,
}: AttachmentsProps) => {
  if (attachments?.length === 0) return null;
  if (!isRendered) return null;

  return (
    <Styled.Wrapper>
      <Styled.ScrollView>
        {attachments.map((attachment, index) => (
          // @ts-expect-error no overload matches
          <Styled.FilePreview.Wrapper
            key={index}
            file={attachment}
            controls={false}
          >
            <Styled.FilePreview.Icon
              name="remove"
              onPress={() => removeAttachment(index, formValues, setFormValues)}
            />
          </Styled.FilePreview.Wrapper>
        ))}
      </Styled.ScrollView>
    </Styled.Wrapper>
  );
};
