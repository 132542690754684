import React, { Component } from 'react';
import { StyleSheet } from 'react-native';

import PropTypes from 'prop-types';

import SlidingView from 'app/components/Common/SlidingView';
import { View } from 'app/components/Common/Styled';

import TabList from './TabList';

/**
 * A tab menu for navigating a <Tabs /> component.
 *
 * @example
 * <Tabs
 *   tabs={['Tab 1', 'Tab 2', 'Tab 3']}
 *   onTabPress={(selectedIndex) => console.log('selectedIndex', selectedIndex)}
 * >
 *   <Text>Tab 1</Text>
 *   <Text>Tab 2</Text.
 *   <Text>Tab 3</Text.
 * </Tabs>
 */
export default class Tabs extends Component {
  /**
   * @property {node} children An array of children to render as each tab (required).
   * @property {integer|object} containerStyle Optional styles for the container (defaults to {}).
   * @property {function} onTabPress A function to call when pressing a tab (defaults to null).
   * @property {integer|object} tabListStyle Optional styles for the tab list (defaults to {}).
   * @property {array} tabs A list of tabs to display (defaults to []).
   */
  static propTypes = {
    children: PropTypes.array.isRequired,
    containerStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    onTabPress: PropTypes.func,
    tabListStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    tabs: PropTypes.array,
  };

  static defaultProps = {
    children: null,
    containerStyle: {},
    onTabPress: null,
    tabListStyle: {},
    tabs: [],
  };

  state = {
    selectedIndex: 0,
  };

  /**
   * Changes the current selected tab.
   *
   * @param {number} selectedIndex The index of the current tab.
   */
  onTabPress = (selectedIndex) => {
    if (selectedIndex === this.state.selectedIndex) return;

    if (this.slidingView) {
      selectedIndex > this.state.selectedIndex
        ? this.slidingView.slideLeft()
        : this.slidingView.slideRight();
    }

    if (this.props.onTabPress) this.props.onTabPress(selectedIndex);

    setTimeout(() => this.setState({ selectedIndex }), 140);
  };

  render() {
    return (
      <View
        style={StyleSheet.flatten([
          styles.container,
          this.props.containerStyle,
        ])}
      >
        <TabList
          containerStyle={this.props.tabListStyle}
          onTabPress={this.onTabPress}
          selectedIndex={this.state.selectedIndex}
          tabs={this.props.tabs}
        />

        <SlidingView
          ref={(slidingView) => (this.slidingView = slidingView)}
          containerStyle={{
            flex: 1,
          }}
        >
          {this.props.children[this.state.selectedIndex]}
        </SlidingView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
