import { Platform } from 'react-native';
import * as Sentry from '@sentry/react-native';

import { logScreenView } from 'app/util/analytics';
import history from 'app/util/history';

import { routingInstrumentation } from './sentry';

history.listen((location) => {
  // Log navigation event in Google Analytics
  logScreenView(location.pathname);

  // Log navigation event in Sentry
  Sentry.addBreadcrumb({
    category: 'navigation',
    message: location.pathname,
    level: 'info',
  });

  // Implement custom routing instrumentation when navigating.
  routingInstrumentation.onRouteWillChange({
    name: location.pathname,
    op: 'navigation',
  });

  if (Platform.OS === 'web') {
    // ensure we wait for render to scroll on subsequent content
    setTimeout(() => {
      // Reset the scroll position when navigating in web browsers.
      window?.scrollTo(0, 0); // this is because of RN web's injected global scroll component
    }, 0);
  }
});
