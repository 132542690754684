import { Image } from 'react-native';
import { Button, Text } from 'react-native-elements';
import styled, { css } from 'styled-components/native';

import { View as ViewRaw } from 'app/components/Common/Styled';
import theme from 'app/util/theme';

const getCardWrapperWidth = ({
  breakpoints,
  isNarrow,
  spacing,
  windowDimensions,
}) => {
  const gutterWidth = spacing * 1.5;
  if (isNarrow) return '100%';
  if (windowDimensions.width >= breakpoints.medium) {
    return `${windowDimensions.width / 4 - gutterWidth}px`;
  }
  return `${windowDimensions.width / 2 - gutterWidth}px`;
};

export const iconConfig = {
  size: 16,
  style: {
    marginRight: theme.spacing / 2,
  },
};

export const CardWrapper = styled(ViewRaw)`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border: 1px solid #dadce0;
    border-radius: 4px;
    margin: 0 0 ${theme.spacing * 1.25}px;
    width: ${getCardWrapperWidth(theme)};
  `}
`;

export const CardPaddingWrapper = styled(ViewRaw)`
  ${({ theme }) => css`
    flex: 1 0 auto;
    min-height: 320px;
    padding: ${theme.spacing}px;
  `}
`;

export const DetailsRow = styled(ViewRaw)`
  ${({ theme }) => css`
    align-items: flex-start;
    flex-direction: row;
    margin: ${theme.spacing / 4}px 0;
  `}
`;

export const DetailsText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.bodyText};
    font-family: ${theme.fontFamily};
    font-size: ${theme.fontSizes.body3}px;
  `}
`;

export const FacilityImage = styled(Image)`
  ${({ theme }) => css`
    height: ${theme.spacing * 4}px;
    resize-mode: contain;
    width: 100%;
  `}
`;

// Prevent Icons from shrinking next to varied text lengths
export const IconWrapper = styled(ViewRaw)`
  ${({ theme }) => css`
    flex: 0 0 auto;
    padding-top: ${theme.spacing / 8}px;
  `}
`;

export const LearnMoreButton = styled(Button).attrs(({ theme }) => ({
  containerStyle: {
    marginTop: theme.spacing,
  },
}))``;

export const SpacerColumn = styled(ViewRaw)`
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: space-between;
`;

export const TopImageSection = styled(ViewRaw)`
  ${({ theme }) => css`
    align-items: center;
    border-bottom-color: #dadce0;
    border-bottom-width: 1px;
    flex-direction: row;
    justify-content: center;
    min-height: ${theme.spacing * 5.5}px;
  `}
`;

export const View = ViewRaw;
