import {
  RECEIVE_SUBMISSION_LOADING,
  RECEIVE_SUBMISSIONS,
  RECEIVE_SUBMISSION,
} from 'app/actions/submissionActions';
import { RECEIVE_LOGOUT } from 'app/actions/sessionActions';

import { formatData } from 'app/util/reducerUtils';

const nullState = Object.freeze({
  loading: false,
  submissions: [],
});

export { nullState };

const submissionReducer = (state = nullState, action) => {
  switch (action.type) {
    case RECEIVE_SUBMISSIONS:
      return { ...state, submissions: action.submissions.map(formatData) };
    case RECEIVE_SUBMISSION: {
      const submissions = [...state.submissions];
      const submission = formatData(action.submission);
      const index = submissions.findIndex(({ id }) => id === submission.id);

      if (index !== -1) submissions[index] = submission;

      return { ...state, submissions };
    }
    case RECEIVE_SUBMISSION_LOADING:
      return { ...state, loading: action.loading };
    case RECEIVE_LOGOUT:
      return action.hard ? nullState : state;
    default:
      return state;
  }
};

export default submissionReducer;
