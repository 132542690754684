import * as analytics from '@cross-platform/react-native-firebase';

import { ENVIRONMENT } from 'app/util/constants';
import history from 'app/util/history';

/**
 * NOTE: The list of events and their descriptions is documented in the following location:
 *
 * https://docs.google.com/spreadsheets/d/1qc6DYVLkDnrLeWixHJMSEmFyn911uaXJhjNkLpv2ZGU
 *
 * Please help us keep this documentation up to date with code changes.
 */
export const EVENTS = {
  account: {
    activate: 'activate_account',
    register: 'register_account',
    stepOneComplete: 'verify_account',
  },
  app: {
    pressButton: 'press_button',
  },
  episode: {
    create: 'create_episode',
    selectProvider: 'select_provider',
    skipProvider: 'skip_provider',
    carrumLocalTopbarView: 'carrum_local_topbar_view',
    carrumLocalTopbarClick: 'carrum_local_topbar_click',
    carrumLocalViewSilverOptions: 'carrum_local_view_silver_options',
  },
  forms: {
    view: 'view_form',
    save: 'save_form',
    submit: 'submit_form',
  },
  messaging: {
    sendAttachment: 'send_attachment',
    sendMessage: 'send_message',
    view: 'view_messages',
  },
  onboarding: {
    complete: 'complete_onboarding',
    dismiss: 'dismiss_onboarding',
    next: 'view_onboarding_next_page',
    view: 'view_onboarding',
  },
  procedures: {
    confirm: 'view_procedure_confirmation',
    dismiss: 'dismiss_procedure_confirmation',
    search: 'search_procedures',
    viewDetails: 'view_procedure_details',
    viewLocations: 'view_procedure_locations',
    viewQuality: 'view_procedure_quality',
    viewSavings: 'view_procedure_savings',
  },
  profile: {
    complete: 'complete_profile',
    previous: 'profile_previous_page',
    save: 'save_profile',
    update: 'update_profile',
  },
  session: {
    campaign: 'detect_campaign',
    expired: 'session_expired',
    login: 'log_in',
    logout: 'log_out',
  },
  tasks: {
    complete: 'complete_task',
    viewDetails: 'view_details',
    viewHelp: 'view_help',
  },
  /**
   * The following events are built-in event types supported by default.
   * https://support.google.com/firebase/answer/9234069
   */
  default: {
    // https://firebase.google.com/docs/analytics/screenviews#manually_track_screens
    screenView: 'screen_view',
  },
};

// Only log analytics events in staging and production
let isTrackingEnabled = ['staging', 'production'].includes(ENVIRONMENT);

// Update UTM params and append to events.
let utmParams = {};

// Support toggling tracking for testing
export const setTrackingEnabled = (enabled) => {
  isTrackingEnabled = enabled;
};

export const logCampaignParams = async ({
  utm_campaign,
  utm_content,
  utm_medium,
  utm_source,
  utm_term,
}) => {
  utmParams = {
    campaign: utm_campaign,
    content: utm_content,
    medium: utm_medium,
    source: utm_source,
    term: utm_term,
  };

  // Delete undefined keys since not all params may be used.
  Object.keys(utmParams).forEach((key) => {
    if (!utmParams[key]) delete utmParams[key];
  });

  // Return early if there are no capmaign params detected.
  if (!Object.keys(utmParams).length) return;

  await logEvent(EVENTS.session.campaign);
};

export const logEvent = async (action, details = {}) => {
  Object.keys(details).forEach((key) => {
    if (details[key] === undefined) delete details[key];
  });

  details['screen_name'] = details['screen_name'] || history.location.pathname;

  // eslint-disable-next-line no-console
  if (!isTrackingEnabled) return console.log(action, { details, utmParams });

  try {
    await analytics.logEvent(action, { ...details, ...utmParams });
  } catch (error) {
    logFailure('logEvent', error);
  }
};

export const logFailure = (type, error) => {
  // Ignore events blocked by Ad Blockers.
  if (error.message === 'NotAllowedError') return;

  // eslint-disable-next-line no-console
  console.error(`Unable to track Firebase event with ${type}`, error);
};

/**
 * Track screen_view events in Firebase / Google Analytics, and include both
 * 'firebase_screen' and 'screen_name' properties to ensure logging is backwards
 * compatible in both web and mobile.
 *
 * https://firebase.google.com/docs/analytics/screenviews
 */
export const logScreenView = (route) =>
  logEvent(EVENTS.default.screenView, {
    firebase_screen: route,
    screen_name: route,
  });

export const setUserId = async (value) => {
  // eslint-disable-next-line no-console
  if (!isTrackingEnabled) return console.log('User ID', value);

  try {
    await analytics.setUserId(value);
  } catch (error) {
    logFailure('setUserId', error);
  }
};

export const setUserProperty = async (name, value) => {
  // eslint-disable-next-line no-console
  if (!isTrackingEnabled) return console.log('User Property', name, value);

  try {
    await analytics.setUserProperty(name, value);
  } catch (error) {
    logFailure('setUserProperty', error);
  }
};
