import { TestID } from './constants';

import {
  BodyText,
  CenteredScrollScreen,
  DateText,
  FinalText,
  LogOutButton,
  LogOutButtonContainer,
  NotAvailableTimerSvg,
  SvgWrapper,
  Title,
} from './styles';

import { disconnectFromWebSocket, getDisplayDate, logout } from './helpers';

import { useDispatch, useLocation } from './hooks';

const NotAvailable = () => {
  const dispatch = useDispatch();

  const logoutUser = () => {
    disconnectFromWebSocket();
    dispatch(logout(true));
  };

  return (
    <CenteredScrollScreen testID={TestID.NotAvailable.Page}>
      <Title>You're a little early</Title>

      <SvgWrapper>
        <NotAvailableTimerSvg />
      </SvgWrapper>

      <AvailabilityDetails />

      <FinalText>
        If you have any questions, please contact your Benefits Manager or call
        Carrum at 888-855-7806 to learn more.
      </FinalText>

      <LogOutButtonContainer>
        <LogOutButton
          title="Log out"
          disabled={false}
          loading={false}
          onPress={logoutUser}
          testID={TestID.NotAvailable.LogOutButton}
        />
      </LogOutButtonContainer>
    </CenteredScrollScreen>
  );
};

const AvailabilityDetails = () => {
  const location = useLocation();

  const queryParam = location.search.replace('?until=', '');

  if (queryParam.length === 0)
    return <BodyText>Hang tight, we'll be ready for you soon.</BodyText>;

  const dateDisplay = getDisplayDate(queryParam);

  return (
    <>
      <BodyText>Carrum benefits go live at your organization on:</BodyText>

      <DateText>{dateDisplay}</DateText>

      <BodyText>
        Log back into this application after this time to proceed.
      </BodyText>
    </>
  );
};

export default NotAvailable;
