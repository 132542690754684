import React from 'react';

import { LegaleseLink, LegaleseText } from './styles';

export const LegalContent = ({
  buttonText = 'Continue',
}: {
  buttonText?: string;
}) => {
  return (
    <LegaleseText>
      By clicking "{buttonText}", you agree to our&nbsp;
      <LegaleseLink
        title="Terms"
        to="https://www.carrumhealth.com/terms-of-use"
      />
      ,&nbsp;
      <LegaleseLink
        title="Privacy Policy"
        to="https://www.carrumhealth.com/privacy-statement"
      />
      , and&nbsp;
      <LegaleseLink
        title="Member Agreement"
        to="https://www.carrumhealth.com/member-registration-agreement"
      />
      .
    </LegaleseText>
  );
};
