import styled from 'styled-components/native';

import { Text, View } from 'app/components/Common/Styled';

export const DescriptionWrapper = styled(View)`
  margin-horizontal: ${({ theme }) => theme.spacing}px;
  margin-bottom: ${({ theme }) => theme.spacing}px;
`;

export const LearnMoreWrapper = styled(View)`
  margin-top: ${({ theme }) => theme.spacing}px;
  margin-bottom: ${({ theme }) => theme.spacing * 2}px;
`;

export const LearnMoreText = styled(Text)`
  text-align: center;
`;
