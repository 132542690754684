export const graphHeight = 100;

const percentStringToNumber = (percent: string): number =>
  parseFloat(percent.replace('%', ''));

export const getBarHeight = (
  carrumPercent: string, // "0.7%"
  nationalPercent: string, // "1.8%"
  isCarrum: boolean = false
): number => {
  if (!isCarrum) {
    if (percentStringToNumber(nationalPercent) === 0) return 0;

    return graphHeight;
  }

  const carPct = percentStringToNumber(carrumPercent);
  const natPct = percentStringToNumber(nationalPercent);

  const carPx = (carPct / natPct) * graphHeight;

  return carPx;
};
