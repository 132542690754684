import React from 'react';
import Svg, { Path } from 'react-native-svg';

interface Props {
  color?: string;
  size?: number;
  style?: any;
}

const MessagesCircle = ({ color = '#5F7881', size = 16, style }: Props) => (
  <Svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <Path
      d="M14.0336 6H11.9095V8.55836C11.9084 8.96038 11.7507 9.3456 11.4709 9.62986C11.1911 9.91413 10.812 10.0743 10.4163 10.0755H5V12.2335C5.00084 12.4938 5.103 12.7431 5.28418 12.9271C5.46536 13.1111 5.71082 13.2147 5.96697 13.2154H10.2151L12.3237 14.9613C12.3525 14.983 12.3866 14.9961 12.4222 14.9993C12.4579 15.0024 12.4937 14.9954 12.5257 14.9791C12.5577 14.9627 12.5846 14.9377 12.6034 14.9067C12.6222 14.8758 12.6322 14.8401 12.6322 14.8037V13.2176H14.0336C14.2897 13.2169 14.5351 13.1132 14.7162 12.9292C14.8973 12.7452 14.9993 12.4959 15 12.2357V6.98191C14.9993 6.72171 14.8973 6.47237 14.7162 6.28838C14.5351 6.10439 14.2897 6.00071 14.0336 6V6Z"
      fill={color}
    />
    <Path
      d="M10.0564 2H1.94305C1.6931 2.00085 1.45363 2.10465 1.27694 2.28873C1.10024 2.47281 1.00068 2.7222 1 2.98245V8.23621C1.00068 8.49641 1.10026 8.74575 1.27697 8.92974C1.45368 9.11373 1.69315 9.21741 1.94305 9.21813H2.55604V10.8037C2.55606 10.8401 2.56579 10.8758 2.58414 10.9067C2.60249 10.9377 2.62873 10.9627 2.65995 10.9791C2.69117 10.9954 2.72613 11.0024 2.76094 10.9993C2.79575 10.9961 2.82904 10.983 2.85709 10.9613L4.91782 9.21813H10.0564C10.3064 9.21741 10.5459 9.11375 10.7227 8.92978C10.8995 8.7458 10.9992 8.49646 11 8.23621V2.98245C10.9993 2.72211 10.8997 2.47263 10.7229 2.28854C10.5461 2.10445 10.3065 2.00071 10.0564 2V2Z"
      fill={color}
    />
  </Svg>
);

export default MessagesCircle;
