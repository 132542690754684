import { Platform, View } from 'react-native';
import styled, { css } from 'styled-components/native';

const position = Platform.select({
  default: 'absolute',
  web: 'fixed',
});

/**
 * Renders a container using fixed position on the web and absolute position
 * on native (since fixed is only available on web).
 *
 * Pass `fill={true}` to fill the entire container/screen.
 */
const AbsoluteFixedContainer = styled(View)`
  position: ${position};

  ${({ fill }) =>
    fill
      ? css`
          bottom: 0px;
          left: 0px;
          right: 0px;
          top: 0px;
        `
      : null}
`;

export default AbsoluteFixedContainer;
