import styled from 'styled-components/native';

import Text from 'app/components/Common/Styled/Text';
import UnorderedListRaw from 'app/components/Common/UnorderedList';

export const Paragraph = styled(Text)`
  margin-vertical: ${({ theme }) => theme.spacing / 2}px;
`;

export const UnorderedList = styled(UnorderedListRaw)`
  margin-vertical: ${({ theme }) => theme.spacing / 2}px;
`;
