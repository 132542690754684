const nextStepsSvgDimensions = {
  height: 175,
  width: 254,
};

const getScalableSvgDimensions = ({ height, width }) => ({
  height: height,
  width: height,
  aspectRatio: width / height,
  viewBox: `0 0 ${width} ${height}`,
});

export const ScalableNextStepsSvgDimensions = getScalableSvgDimensions(
  nextStepsSvgDimensions
);
