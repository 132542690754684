import { EVENTS, logEvent } from 'app/util/analytics';
import { TestID } from 'app/util/test-id';
import React, { useEffect } from 'react';

import { Button, Container, Icon, Text } from './styles';

interface Props {
  isVisible: boolean;
  displayExtendedOptionsModal: () => void;
}

const ExtendedOptionsNotification = ({
  isVisible,
  displayExtendedOptionsModal,
}: Props) => {
  useEffect(() => {
    if (!isVisible) return;

    logEvent(EVENTS.episode.carrumLocalTopbarView);
  }, [isVisible]);

  const handleButtonClick = () => {
    displayExtendedOptionsModal();
    logEvent(EVENTS.episode.carrumLocalTopbarClick);
  };

  if (!isVisible) return null;

  return (
    <Container>
      <Text>Looking for something closer?</Text>
      <Button
        testID={TestID.SelectYourDoctor.ShowExtendedOptionsModal}
        mobileIcon={<Icon name="arrow-forward" />}
        iconRight
        onPress={handleButtonClick}
        title="Show Extended Options"
      />
    </Container>
  );
};

export default ExtendedOptionsNotification;
