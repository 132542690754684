import React, { ReactNode, useEffect, useState } from 'react';
import { Animated, Easing, StyleSheet } from 'react-native';

import { useNativeDriver } from 'app/util/constants';

import { Container } from './styles';

const FADE_FROM = 0;
const FADE_TO = 1;

const SLIDE_FROM = 20;
const SLIDE_TO = 0;

const animationConfig = {
  delay: 300,
  duration: 300,
  easing: Easing.in(Easing.ease),
  useNativeDriver,
};

interface FadeProps {
  children: ReactNode;
  delay: number;
  in?: boolean;
  out?: boolean;
}

const Fade = ({ children, delay, ...rest }: FadeProps) => {
  const [opacity] = useState(new Animated.Value(rest.in ? FADE_FROM : FADE_TO));
  const [slide] = useState(new Animated.Value(rest.in ? SLIDE_FROM : SLIDE_TO));

  /**
   * Animate the component in or out when setting the `in` or `out` props.
   */
  useEffect(() => {
    if (rest.in) {
      fadeIn();
    } else if (rest.out) {
      fadeOut();
    }
  }, [rest.in, rest.out]);

  const fadeIn = () => {
    Animated.parallel([
      Animated.timing(opacity, {
        ...animationConfig,
        delay: delay,
        toValue: FADE_TO,
      }),
      Animated.timing(slide, {
        ...animationConfig,
        delay: delay,
        toValue: SLIDE_TO,
      }),
    ]).start();
  };

  const fadeOut = () => {
    Animated.parallel([
      Animated.timing(opacity, {
        ...animationConfig,
        delay: delay,
        toValue: FADE_FROM,
      }),
      Animated.timing(slide, {
        ...animationConfig,
        delay: delay,
        toValue: SLIDE_FROM,
      }),
    ]).start();
  };

  return (
    <Container
      style={StyleSheet.flatten([
        { opacity: opacity },
        { transform: [{ translateY: slide }] },
      ])}
    >
      {children}
    </Container>
  );
};

export default Fade;
