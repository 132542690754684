import {
  Animated,
  Easing,
  EVENTS,
  TestID,
} from 'app/components/Common/Onboarding/constants';
import {
  logEvent,
  onboard,
  userHasOnboarded,
} from 'app/components/Common/Onboarding/helpers';
import {
  useCallback,
  useEffect,
  useNativeDriver,
  useRef,
  useState,
} from 'app/components/Common/Onboarding/hooks';
import {
  AnimationContainer,
  ContentContainer,
  Icon,
  ImageContainer,
  Modal,
  ProgressIndicator,
  SlidingView,
  StickyNavFooter,
  Subtitle,
  TextContainer,
  Title,
  ValuePropImage,
} from 'app/components/Common/Onboarding/styles';
import {
  NavigationMode,
  OnboardingProps,
} from 'app/components/Common/Onboarding/types';

/**
 * Render a sliding card list as a modal view to onboard users at various points
 * in the app.
 *
 * @param  name          Unique identifier to track views and only show once per user.
 * @param  pages         Data to display within the slides.
 *
 * @example <caption>Example declaration of component.</caption>
 * <Onboarding
 *   name="onboarding.test"
 *   pages={[
 *     { key: 'foo', title: 'Foo', subtitle: 'Foo', image: { height: 12, width: 23 } },
 *     { key: 'bar', title: 'Bar', subtitle: 'Bar', image: { height: 12, width: 23 } },
 *   ]}
 * />
 */
const Onboarding = ({ name, pages }: OnboardingProps) => {
  const [isOnboarded, setIsOnboarded] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const setIsSliding = useState(false)[1];

  const slide = new Animated.Value(0);
  const slidingViewRef = useRef(null);
  const slideForwardTimeoutRef = useRef(null);

  /** On first load, check whether user has onboarded. */
  useEffect(() => {
    (async () => {
      const _isOnboarded = await userHasOnboarded(name);
      if (!_isOnboarded) log('view');
      setIsOnboarded(_isOnboarded);
    })();
  }, []);

  /** Clear running timeouts in case component unmounts before they expire. */
  useEffect(() => {
    return () => clearTimeout(slideForwardTimeoutRef.current);
  }, []);

  /** Register that user viewed onboarding when visibility toggles to false. */
  useEffect(() => {
    if (!isVisible) onboard(name);
  }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  const completeOnboarding = () => {
    log('complete');
    hideOverlay();
  };

  const goToNextSlide = () => {
    log('next', { pageNumber: pageNumber + 2 });
    slideForward();
  };

  const hideOverlay = () => {
    const config = {
      duration: 320,
      easing: Easing.in(Easing.ease),
      toValue: 1000,
      useNativeDriver,
    };

    Animated.timing(slide, { ...config }).start(() => {
      setIsVisible(false);
    });
  };

  const isFinalPage = (pageNumber: number) => pageNumber === pages.length - 1;

  const log = (action: string, details = {}) => {
    const onboarding = name.split('.')[1];
    logEvent(EVENTS.onboarding[action], { onboarding, ...details });
  };

  const skipRemainingSlides = useCallback(() => {
    log('dismiss');
    hideOverlay();
  }, [slide]);

  const slideForward = () => {
    clearTimeout(slideForwardTimeoutRef.current);
    setIsSliding(true);
    slidingViewRef?.current?.slideLeft?.();
    slideForwardTimeoutRef.current = setTimeout(() => {
      setPageNumber(pageNumber + 1);
      setIsSliding(false);
    }, 160);
  };

  if (isOnboarded !== false) return null;

  return (
    <Modal
      fullScreen
      isVisible={isVisible}
      onClose={skipRemainingSlides}
      options={[]}
      overlayStyleOverrides={{
        backgroundColor: 'transparent', // Allows content behind the modal to show as the modal slides off screen
      }}
      scrollable={false}
      testID={TestID.Onboarding.Component}
    >
      {/* `options` above are passed as children */}
      <AnimationContainer style={{ transform: [{ translateY: slide }] }}>
        <ContentContainer>
          <SlidingView ref={slidingViewRef}>
            <ImageContainer>
              {pages[pageNumber].image ? (
                <ValuePropImage page={pages[pageNumber]} />
              ) : (
                <Icon name={pages[pageNumber].icon} page={pages[pageNumber]} />
              )}
            </ImageContainer>
            <TextContainer>
              <Title testID={TestID.Onboarding.ContentTitle}>
                {pages[pageNumber].title}
              </Title>
              <Subtitle>{pages[pageNumber].subtitle}</Subtitle>
            </TextContainer>
          </SlidingView>

          <ProgressIndicator pageCount={pages.length} pageNumber={pageNumber} />
        </ContentContainer>

        <StickyNavFooter
          navigationConfig={[
            {
              onPress: skipRemainingSlides,
              testID: TestID.Onboarding.SkipButton,
              text: 'Skip',
            },
            {
              onPress: isFinalPage(pageNumber)
                ? completeOnboarding
                : goToNextSlide,
              testID: TestID.Onboarding.NextButton,
              text: 'Next',
            },
          ]}
          navigationMode={NavigationMode.SkipAndNext}
          testID={TestID.Onboarding.NavFooter}
        />
      </AnimationContainer>
    </Modal>
  );
};

export default Onboarding;
