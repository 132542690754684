export { Episode } from 'types/episode';

export interface LetUsKnowProps {
  onClose: () => void;
}

export { Location } from 'types/location';

export enum PageOptions {
  LetUsKnow = 'Message us',
  Confirmation = 'Thank you!',
}

export { ModalOption } from 'app/components/Common/Modal/types';
