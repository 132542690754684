import { logEvent, EVENTS } from 'app/util/analytics';

interface ButtonPressEventParameters {
  icon?: string;
  title?: string;
}

const getIconLabel = (buttonProps) => {
  if (typeof buttonProps.icon === 'string') {
    return buttonProps.icon;
  } else if (typeof buttonProps.icon?.name === 'string') {
    return buttonProps.icon?.name;
  } else {
    return buttonProps.iconName;
  }
};

/**
 * Log the onPress event, and then invoke the onPress handler (if any).
 */
export const onPressWithTracking = (onPress, buttonProps) => (event: Event) => {
  /**
   * Log both the `title` and `icon` props to reference the context of the
   * button being pressed.  In cases where `icon` is a React node, use the
   * `iconName` prop instead.
   */
  const logParams: ButtonPressEventParameters = {
    icon: getIconLabel(buttonProps),
    title: buttonProps.title,
  };

  logEvent(EVENTS.app.pressButton, logParams);

  if (typeof onPress === 'function') return onPress(event);
};
