import { connect } from 'react-redux';
import { reduxForm, initialize } from 'redux-form';

import {
  fetchSubmissions,
  updateSubmission,
} from 'app/actions/submissionActions';
import { showLocalNotification } from 'app/actions/uiActions';

import Forms from 'app/components/Forms';

const mapStateToProps = ({ form, session, submission }, { match }) => ({
  loading: submission.loading,
  submissions: submission.submissions,
  iterations: match.params.id
    ? submission.submissions
        .filter(({ formKey }) => formKey === match.params.id)
        .sort((a, b) => a.order - b.order)
    : [],
  submission: match.params.id
    ? submission.submissions.find(({ formKey }) => formKey === match.params.id)
    : null,
  formValues: (form && form.submission && form.submission.values) || {},
  patientName:
    `${session.user.firstName} ${session.user.lastName}`.toUpperCase(),
});

const mapDispatchToProps = (dispatch) => ({
  fetchSubmissions: () => dispatch(fetchSubmissions()),
  initialize: (submission) => dispatch(initialize('submission', submission)),
  showLocalNotification: (notification) =>
    dispatch(showLocalNotification(notification)),
  updateSubmission: (id, submission) =>
    dispatch(updateSubmission(id, submission)),
});

const FormsContainer = connect(mapStateToProps, mapDispatchToProps)(Forms);

export default reduxForm({
  form: 'submission',
  destroyOnUnmount: false,
  updateUnregisteredFields: true,
})(FormsContainer);
