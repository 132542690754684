import * as SecureStore from '@cross-platform/expo-secure-store';

import { SINGLE_CLICK_REGISTRATION } from 'app/util/constants';
import { isEmpty } from 'app/util/methods';

/**
 * Return the member ID of this user. Use the profile information, if present,
 * otherwise uses the eligible patient record.
 *
 * @param   {object}  user  A user object from the current session.
 *
 * @return  {string}        The member ID of the patient
 */
export const memberId = (user) => {
  const insurance =
    (user && user.profile && user.profile.insurance) ||
    (user && user.eligiblePatient && user.eligiblePatient.insurance);

  return insurance ? insurance.memberId : '';
};

/**
 * Return true for patients with a required member ID but the cost estimate
 * data fails to load.
 *
 * @param   {object}   user                 A user object from the current session.
 * @param   {object}   costEstimate         A cost estimate object.
 * @param   {boolean}  costEstimateLoading  A boolean indicating if the cost estimate is still loading.
 *
 * @return  {boolean}                       True if there is an error with the member ID.
 */
export const hasMemberIdError = (user, costEstimate, costEstimateLoading) =>
  Boolean(memberId(user) && !costEstimate && !costEstimateLoading);

/**
 * Return true if the current patient is missing a required member ID for their
 * insurance.
 *
 * @param   {object}   user  A user object from the current session.
 *
 * @return  {boolean}        True if the member ID is required.
 */
export const isMemberIdRequired = (user) =>
  Boolean(
    !memberId(user) && user && user.insurer && user.insurer.memberIdRequired
  );

/**
 * Return the user's preferred name from their profile (if set) or from their
 * user record.
 */
export const getUserName = (user) =>
  (user.profile &&
    user.profile.preferredName &&
    user.profile.preferredName.split(' ')[0]) ||
  user.firstName;

/**
 * Returns true if the given latitude and longitude values are both
 * non-zero numbers.
 */
const isValidLocation = (latitude, longitude) => {
  const coords = [Number(latitude), Number(longitude)];
  const invalidValues = [0, NaN];

  return coords.filter((value) => invalidValues.includes(value)).length === 0;
};

/**
 * Use the address defined in the user profile, if set, otherwise use the
 * address from the eligible patient record.
 *
 * @param   {object}  user  A user record from the Core Service.
 * @param   {object}  episodeLocation  Location associated with Episode in Care Service.
 * @return  {object}        A location object with an address, longitude, and latitude.
 */
export const parseUserLocation = (
  { eligiblePatient, location },
  episodeLocation
) => {
  let latitude, longitude;

  let address;

  if (!isEmpty(episodeLocation?.address)) {
    address = episodeLocation.address;
  } else if (!isEmpty(eligiblePatient?.address)) {
    address = eligiblePatient.address;
  } else if (!isEmpty(location?.address)) {
    address = location.address;
  }

  // Confirm we have the geometry AND that it is valid
  if (isValidLocation(episodeLocation?.lat, episodeLocation?.lng)) {
    ({ lat: latitude, lng: longitude } = episodeLocation);
  } else if (isValidLocation(location?.lat, location?.lng)) {
    ({ lat: latitude, lng: longitude } = location);
  }

  return { address, latitude, longitude };
};

/**
 * Return true if the given email is saved in local storage as having
 * registered using single-click registration.
 *
 * @param  {string}  email  The email to check for.
 */
export const isGuestAccount = async (email) => {
  const values = await getSavedSingleClickRegistrations();
  return values.includes(email);
};

/**
 * Return an array of emails stored in SecureStorage that have been used for a
 * single-click registration.
 */
export const getSavedSingleClickRegistrations = async () => {
  const savedValues = await SecureStore.getItemAsync(SINGLE_CLICK_REGISTRATION);
  const values = JSON.parse(savedValues || '[]');

  return Array.isArray(values) ? values : [];
};

/**
 * Update the array of emails stored in SecureStorage that have been used for a
 * single-click registration.
 */
const setSavedSingleClickRegistrations = async (newValues) => {
  const values = [...newValues].filter(
    (value, index, array) => array.indexOf(value) === index
  );

  await SecureStore.setItemAsync(
    SINGLE_CLICK_REGISTRATION,
    JSON.stringify(values)
  );
};

/**
 * Add an email to the list of saved single-click registrations.
 *
 * @param  {string}  email  The email to add to list.
 */
export const updateSavedSingleClickRegistrations = async (email) => {
  const savedValues = await getSavedSingleClickRegistrations();

  await setSavedSingleClickRegistrations([...savedValues, email]);
};

/**
 * Delete an email from the list of saved single click registrations.
 */
export const deleteSavedSingleClickRegistrations = async (email) => {
  const savedValues = await getSavedSingleClickRegistrations();

  const newValues = [...savedValues];

  const index = savedValues.indexOf(email);
  newValues.splice(index, 1);

  await setSavedSingleClickRegistrations(newValues);
};
