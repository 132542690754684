import styled from 'styled-components/native';

import AbsoluteFixedContainer from 'app/components/Common/Styled/AbsoluteFixedContainer';

export const Container = styled(AbsoluteFixedContainer).attrs({
  fill: true,
})`
  background-color: ${({ theme }) => theme.colors.sand};
  flex: 1;
  z-index: ${({ theme }) => theme.zIndexes.overlay};
`;
