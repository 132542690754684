import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Path, Rect } from 'react-native-svg';

/**
 * Renders the Carrum Health logo as an SVG.
 *
 * @property {number} height The rendered height.
 * @property {number} width The rendered width.
 */
const RedHorizontalLogo = ({ height = 28, width = 200 }) => (
  <Svg height={height} version="1.1" viewBox="0 0 1000 149.95" width={width}>
    <G>
      <G>
        <Path
          fill="#a53723"
          d="M26.64,74.98c0-26.66,21.69-48.34,48.34-48.34c9.71,0,19.08,2.87,27.08,8.29c6.09,4.13,14.37,2.54,18.5-3.55
			s2.53-14.37-3.55-18.5C104.57,4.45,90.04,0,74.98,0C33.63,0,0,33.63,0,74.98c0,9.5,1.77,18.73,5.22,27.49l24.59-10.25
			C27.71,86.72,26.64,80.93,26.64,74.98z"
        />
        <Path
          fill="#ef7136"
          d="M74.98,149.95c17.18,0,33.97-5.96,47.28-16.79c13.11-10.66,22.32-25.58,25.94-42
			c1.58-7.18-2.96-14.29-10.14-15.87c-7.19-1.59-14.29,2.96-15.87,10.14c-4.84,21.95-24.69,37.88-47.21,37.88
			c-19.62,0-37.16-11.71-44.68-29.84c-0.17-0.42-0.33-0.85-0.49-1.27L5.22,102.46c0.16,0.41,0.31,0.82,0.47,1.23
			C17.36,131.79,44.55,149.95,74.98,149.95z"
        />
      </G>
      <G>
        <G>
          <Path
            fill="#50514f"
            d="M231.01,98.67c7.97,0,13.29-4.44,16.06-10.98c0.82-1.94,3.16-2.72,5.04-1.77l8.31,4.2
				c1.76,0.89,2.55,3.01,1.74,4.8c-4.85,10.86-15.94,19-31.16,19c-22,0-37.84-16.28-37.84-40.04c0-23.75,15.84-40.03,37.84-40.03
				c15.1,0,25.93,7.79,30.85,18.49c0.81,1.76,0.08,3.86-1.62,4.79l-8.1,4.41c-1.88,1.03-4.27,0.26-5.13-1.71
				c-2.79-6.4-8.1-10.88-16-10.88c-12.03,0-20.24,9.24-20.24,24.93C210.77,89.57,218.98,98.67,231.01,98.67z"
          />
          <Path
            fill="#50514f"
            d="M268.66,90.89c0-14.67,11-21.12,25.81-24.2l21.26-4.4v-1.32c0-7.33-3.81-11.88-13.2-11.88
				c-7.16,0-11.42,2.8-13.78,8.31c-0.72,1.67-2.45,2.65-4.22,2.25l-9.26-2.13c-2.23-0.51-3.54-2.94-2.6-5.03
				c4.84-10.74,15.45-18.65,30.6-18.65c18.48,0,29.62,8.8,29.62,26.54v33.14c0,3.62,1.29,5.15,4.44,5.26c1.3,0.04,2.3,1.16,2.3,2.46
				v8.81c0,1.27-0.97,2.36-2.23,2.46c-11.32,0.93-17.43-1.63-19.91-7.55c-4.84,5.43-12.91,8.65-22.88,8.65
				C279.95,113.63,268.66,104.68,268.66,90.89z M315.73,77.33c0-0.79-0.73-1.38-1.5-1.22l-15.07,3.2
				c-7.48,1.61-13.05,3.96-13.05,10.85c0,6.27,4.78,9.67,12.01,9.37c9.69-0.4,17.61-9,17.61-18.7L315.73,77.33z"
          />
          <Path
            fill="#50514f"
            d="M393.57,48.69c0,2.11-1.75,3.82-3.86,3.7c-0.8-0.04-1.65-0.06-2.6-0.06c-11.15,0-19.65,7.19-19.65,19.5
				v36.77c0,2.05-1.66,3.71-3.71,3.71h-10.18c-2.05,0-3.71-1.66-3.71-3.71V39.02c0-2.05,1.66-3.71,3.71-3.71h10.18
				c2.05,0,3.71,1.66,3.71,3.71v7.73c3.37-7.18,11.29-11.88,21.12-11.88c0.52,0,1,0.01,1.47,0.03c1.97,0.07,3.52,1.73,3.52,3.7
				L393.57,48.69z"
          />
          <Path
            fill="#50514f"
            d="M445.66,48.69c0,2.11-1.75,3.82-3.86,3.7c-0.8-0.04-1.65-0.06-2.6-0.06c-11.15,0-19.65,7.19-19.65,19.5
				v36.77c0,2.05-1.66,3.71-3.71,3.71h-10.18c-2.05,0-3.71-1.66-3.71-3.71V39.02c0-2.05,1.66-3.71,3.71-3.71h10.18
				c2.05,0,3.71,1.66,3.71,3.71v7.73c3.37-7.18,11.29-11.88,21.12-11.88c0.52,0,1,0.01,1.47,0.03c1.97,0.07,3.52,1.73,3.52,3.7
				L445.66,48.69z"
          />
          <Path
            fill="#50514f"
            d="M514.75,112.31h-10.18c-2.05,0-3.71-1.66-3.71-3.71v-4.5c-4.4,5.13-11,9.83-20.97,9.83
				c-16.13,0-26.1-11-26.1-27.57V39.02c0-2.05,1.66-3.71,3.71-3.71h10.18c2.05,0,3.71,1.66,3.71,3.71v43.51
				c0,9.39,4.11,15.4,13.64,15.4c7.92,0,15.84-5.86,15.84-16.86V39.02c0-2.05,1.66-3.71,3.71-3.71h10.18c2.05,0,3.71,1.66,3.71,3.71
				v69.57C518.46,110.64,516.8,112.31,514.75,112.31z"
          />
          <Path
            fill="#50514f"
            d="M543.02,112.31h-10.18c-2.05,0-3.71-1.66-3.71-3.71V39.02c0-2.05,1.66-3.71,3.71-3.71h10.18
				c2.05,0,3.71,1.66,3.71,3.71v4.5c4.25-5.13,10.85-9.68,21.12-9.68c9.24,0,16.13,4.11,20.38,10.71
				c4.69-4.99,12.03-10.71,23.9-10.71c15.84,0,26.1,11.14,26.1,27.72v47.03c0,2.05-1.66,3.71-3.71,3.71h-10.18
				c-2.05,0-3.71-1.66-3.71-3.71V66.7c0-9.53-3.52-16.42-12.91-16.42c-7.48,0-15.25,5.86-15.25,16.86v41.46
				c0,2.05-1.66,3.71-3.71,3.71h-10.18c-2.05,0-3.71-1.66-3.71-3.71V66.7c0-9.53-3.52-16.42-12.9-16.42
				c-7.48,0-15.25,5.86-15.25,16.86v41.46C546.73,110.64,545.07,112.31,543.02,112.31z"
          />
        </G>
        <G>
          <Path
            fill="#50514f"
            d="M696.05,36.71c-11.31,0-19.29,6.15-23.64,12.78V10.47h-6.87v102.05h6.87V66.94
				c0-14.8,11.66-23.64,22.94-23.64c16.08,0,18.48,13.24,18.48,21.13v48.09h6.87V63.87C720.71,46.86,711.49,36.71,696.05,36.71z"
          />
          <Path
            fill="#50514f"
            d="M765.8,36.71c-19.25,0-32.19,15.51-32.19,38.6s13.05,38.6,32.47,38.6c14.42,0,24.98-8.35,28.25-22.35
				l0.21-0.9l-6.71-1.53l-0.19,0.92c-2.27,11.06-10.13,17.4-21.56,17.4c-15.39,0-25.74-12.92-25.74-32.15l54.85-0.05v-2.6
				C795.19,50.49,783.93,36.71,765.8,36.71z M740.74,69.08c2.07-16.02,11.6-25.93,25.06-25.93c19.13,0,21.96,18.73,22.34,25.93
				H740.74z"
          />
          <Path
            fill="#50514f"
            d="M858.92,105.92c-1.4-0.94-2.14-2.55-2.14-4.65V58.43c0-13.6-8.95-21.72-23.95-21.72
				c-13.43,0-23.98,7.26-27.54,18.94l-0.24,0.78l6.47,2.85l0.28-1.03c2.7-9.73,10.17-15.09,21.02-15.09
				c11.17,0,17.08,5.42,17.08,15.68v5.53l-12.51,2.5c-17.78,3.53-34.24,9.15-34.24,26.17c0,14.43,11.89,20.88,23.67,20.88
				c10.38,0,19.05-5.23,23.4-11.11c0.21,3.28,1.39,5.83,3.5,7.61c2.89,2.43,7.35,3.2,12.89,2.23l0.76-0.13v-7.04l-1.27,0.52
				C863.37,107.13,860.68,107.1,858.92,105.92z M849.92,70.58v17.85c0,9.26-9.28,19.17-23.08,19.17c-10.12,0-16.66-5.72-16.66-14.57
				c0-9.34,4.64-15.34,29.13-20.3L849.92,70.58z"
          />
          <Rect
            x="877.31"
            y="10.47"
            fill="#50514f"
            width="6.87"
            height="102.05"
          />
          <Path
            fill="#50514f"
            d="M924.17,106.63c-6.33,0-10.25-1.88-10.25-11.22V44.28h18.84V38.1h-18.84l-0.1-27.41h-6.73l0.1,27.41h-13.26
				v6.18h13.26v51.69c0,11.51,5.51,17.11,16.83,17.11c3.89,0,6.49-0.36,10.29-1.43l0.67-0.19v-6.75l-1.23,0.43
				C930.01,106.45,926.94,106.63,924.17,106.63z"
          />
          <Path
            fill="#50514f"
            d="M975.35,36.71c-11.31,0-19.29,6.15-23.64,12.78V10.47h-6.87v102.05h6.87V66.94
				c0-14.8,11.66-23.64,22.94-23.64c16.08,0,18.48,13.24,18.48,21.13v48.09h6.87V63.87C1000,46.86,990.79,36.71,975.35,36.71z"
          />
        </G>
      </G>
    </G>
  </Svg>
);

RedHorizontalLogo.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default RedHorizontalLogo;

// TODO: FINALLY: CONVERT TO TS W/O PROPTYPES
