import React, { useCallback } from 'react';

import { DotIndicator, VisibilitySensor } from './styles';

interface ScrollPaginationProps {
  onScrollBottom?: () => void;
}

/**
 * Renders a component that automatically loads more data when the user
 * reaches the bottom of the screen.
 */
const ScrollPagination = ({ onScrollBottom }: ScrollPaginationProps) => {
  if (!onScrollBottom) return;

  return (
    <VisibilitySensor onChange={(isVisible) => isVisible && onScrollBottom()}>
      <DotIndicator />
    </VisibilitySensor>
  );
};

export default ScrollPagination;
