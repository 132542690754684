import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const checkMarkGreen = '#4A8410';

interface CheckMarkProps {
  /**
   * The rendered size (height and width). Defaults to 50.
   */
  size?: number;

  /**
   * The check mark fill color. Defaults to green.
   */
  color?: string;
}

/**
 * Renders a check mark as an SVG.
 * Default to green.
 */
const CheckMark = ({ size = 50, color = checkMarkGreen }: CheckMarkProps) => (
  <Svg width={size} height={size} viewBox="0 0 98 97" fill="none">
    <Path
      d="M97.4375 48.5C97.4375 21.9375 75.5625 0.0625 49 0.0625C22.2422 0.0625 0.5625 21.9375 0.5625 48.5C0.5625 75.2578 22.2422 96.9375 49 96.9375C75.5625 96.9375 97.4375 75.2578 97.4375 48.5ZM43.3359 74.2812C42.1641 75.4531 40.0156 75.4531 38.8438 74.2812L18.5312 53.9688C17.3594 52.7969 17.3594 50.6484 18.5312 49.4766L23.0234 45.1797C24.1953 43.8125 26.1484 43.8125 27.3203 45.1797L41.1875 58.8516L70.4844 29.5547C71.6562 28.1875 73.6094 28.1875 74.7812 29.5547L79.2734 33.8516C80.4453 35.0234 80.4453 37.1719 79.2734 38.3438L43.3359 74.2812Z"
      fill={color}
    />
  </Svg>
);

export default CheckMark;
