import { LetUsKnow } from 'app/components/ProcedureSearch/styles';

import { ProcedureSearchPageLetUsKnowProps } from 'app/components/ProcedureSearch/types';

const ProcedureSearchPageLetUsKnow = ({
  isVisible,
  setIsVisible,
}: ProcedureSearchPageLetUsKnowProps) => {
  if (!isVisible) return;

  return <LetUsKnow onClose={() => setIsVisible(false)} />;
};

export default ProcedureSearchPageLetUsKnow;
