import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { TestID } from 'app/util/test-id';

import { CheckBox, FormError, Text, FlexView } from './styles';

/**
 * A checkbox group component wrapper for interacting
 * with redux-form.
 *
 * @example
 * import { Field } from 'redux-form';
 * import CheckBoxGroup from 'app/components/Common/CheckBoxGroup';
 *
 * const MyForm = () => (
 *   <View>
 *    <Field
 *      name="myCheckBoxGroup"
 *      label="My CheckBox Group"
 *      options={[{ label: 'Test 1', value: true }, { label: 'Test 2', value: false }]}
 *      component={CheckBoxGroup}
 *    />
 *   </View>
 * );
 */
export default class CheckBoxGroup extends Component {
  /**
   * @property {object} input An object with props passed down from redux-form (required).
   * @property {function} input.onChange A function to call when the value changes (required).
   * @property {function} input.onBlur A function to call after the changes.
   * @property {*} input.value The currently selected value.
   * @property {array} options An array of options to render in the radio group (required).
   * @property {boolean} disabled If true the input is disabled.
   * @property {string} error An error message to display above the radio group.
   * @property {string} label A label to display above the radio group.
   * @property {object} containerStyle An optional style to pass to the container.
   * @property {object} optionsStyle An optional style to pass to the options container.
   */
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func,
      value: PropTypes.any,
    }).isRequired,
    options: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.string,
    containerStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    optionsStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  };

  static defaultProps = {
    label: '',
    containerStyle: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      selected: props.input && props.input.value ? [...props.input.value] : [],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.input && this.props.input.value !== prevProps.input.value) {
      this.setState({ selected: [...this.props.input.value] });
    }
  }

  onChange = (value) => {
    const selected = [...this.state.selected];
    const index = this.state.selected.indexOf(value);

    if (index === -1) {
      selected.push(value);
    } else {
      selected.splice(index, 1);
    }

    this.setState({ selected });
    this.props.input.onChange(selected);

    if (this.props.input.onBlur) setTimeout(this.props.input.onBlur);
  };

  render() {
    return (
      <FlexView
        style={[this.props.containerStyle]}
        testID={TestID.FormDetails.CheckboxGroup}
      >
        {!!this.props.label && (
          <Text label={this.props.label}>{this.props.label}</Text>
        )}

        <FlexView
          disabled={this.props.disabled}
          style={[this.props.optionsStyle]}
        >
          {this.props.options.map(
            ({ label, value, checkedIcon, uncheckedIcon }, index) => (
              <CheckBox
                key={index}
                title={label}
                checkedIcon={checkedIcon || 'check-square-o'}
                uncheckedIcon={uncheckedIcon || 'square-o'}
                checked={this.state.selected.indexOf(value) !== -1}
                onPress={() => this.props.disabled || this.onChange(value)}
                onIconPress={() => this.props.disabled || this.onChange(value)}
              />
            )
          )}
        </FlexView>
        {this.props.error && <FormError>{this.props.error}</FormError>}
      </FlexView>
    );
  }
}
