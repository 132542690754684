import { connect } from 'react-redux';

import Travel from 'app/components/Travel';

const mapStateToProps = ({ episode }) => ({
  travel: episode?.episode?.travel || {},
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Travel);
