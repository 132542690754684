/**
 * A helper function to convert a user object into
 * a pre-populated JSON profile.
 *
 * @param {object} formValues An object with form values for the current user.
 * @return {number} A number between 1-100 representing the profile completion rate.
 */
export const getProfileProgress = ({ location, profile }) => {
  let progress = 0;
  const total = 29; // Total number of fields in the profile

  [
    'ethnicity',
    'gender',
    'heightFeet',
    'heightInches',
    'language',
    'maritalStatus',
    'phoneNumber',
    'preferredName',
    'religion',
    'weight',
  ].forEach((field) => {
    if (profile && profile[field]) progress += 1;
  });

  if (location && location.lat && location.lng) progress += 5;
  if (profile && profile.insurance && profile.insurance.memberId) progress += 5;
  if (
    profile &&
    profile.mailingAddress &&
    profile.mailingAddress.street &&
    profile.mailingAddress.city &&
    profile.mailingAddress.state &&
    profile.mailingAddress.postalCode
  )
    progress += 5;
  if (
    profile &&
    profile.emergencyContact &&
    profile.emergencyContact.name &&
    profile.emergencyContact.email &&
    profile.emergencyContact.phone &&
    profile.emergencyContact.relationship
  )
    progress += 4;

  return Math.round((progress / total) * 100);
};

/**
 * Returns a BMI calculation for a given height and weight.
 *
 * @param {string|number} heightFeet The height of the person (feet).
 * @param {string|number} heightInches The height of the person (inches).
 * @param {string|number} weight The weight of the person in pounds.
 * @example
 * calculateBmi('5', '11', '165'); // => "23.01"
 */
export const calculateBmi = (heightFeet, heightInches, weight) => {
  if (!heightFeet || (!heightInches && heightInches !== 0) || !weight) return;

  const height = Number(heightFeet) * 12 + Number(heightInches);
  const heightSquared = height * height;

  return ((Number(weight) / heightSquared) * 703).toFixed(2);
};

/**
 * Returns the BMI categorization based on the gievn BMI.
 *
 * @param {string|number} bmi The BMI to categorize.
 * @return {string|number} The BMI categorization for the given BMI.
 */
export const categorizeBmi = (bmi) =>
  Number(bmi) < 18.5
    ? 'underweight'
    : Number(bmi) >= 18.5 && Number(bmi) < 25
    ? 'healthy'
    : Number(bmi) >= 25 && Number(bmi) < 30
    ? 'overweight'
    : Number(bmi) >= 30 && Number(bmi) < 40
    ? 'obese'
    : Number(bmi) >= 40
    ? 'morbidly_obese'
    : '';
