import {
  defaultAuthorProfileImage,
  TestID,
} from 'app/components/Messages/constants';

import { callCarrum, formatPhoneNumber } from 'app/components/Messages/helpers';

import {
  MessageGroupStyles as Styled,
  Platform,
} from 'app/components/Messages/styles';

import { MessageGroupProps } from 'app/components/Messages/types';

import { Message } from './subcomponents';

/**
 * Render a group of messages based on the author and timestamp of the message.
 */
export const MessageGroup = ({
  author,
  messageIsLastInConversation,
  messages,
  patientIsAuthor,
  timestamp,
}: MessageGroupProps) => {
  // Display the delivery icon when the message group belongs to the user, is
  // the last message in the conversation, and is pending.
  const deliveredIconIsDisplayed =
    patientIsAuthor &&
    messageIsLastInConversation &&
    !messages[messages.length - 1].pending;

  const authorProfileImage =
    Platform.OS === 'web' ? author.profileImage : { uri: author.profileImage };

  const authorProfileImageSource = author.profileImage
    ? authorProfileImage
    : defaultAuthorProfileImage;

  return (
    <Styled.Wrapper
      testID={TestID.Messages.MessageGroup}
      patientIsAuthor={patientIsAuthor}
    >
      <Styled.Content patientIsAuthor={patientIsAuthor}>
        {!patientIsAuthor && (
          <Styled.Avatar rounded source={authorProfileImageSource} />
        )}

        <Styled.MessageGroupContentWrapper>
          <Styled.Header patientIsAuthor={patientIsAuthor}>
            {!patientIsAuthor && (
              <Styled.Author.Wrapper>
                <Styled.Author.Name>{author.name}</Styled.Author.Name>

                {author.phoneNumber && (
                  <Styled.Author.PhoneNumber>
                    <Styled.Author.Icon />
                    <Styled.Author.CallLink
                      onPress={(e) => callCarrum(e, author.phoneNumber)}
                      title={formatPhoneNumber(author.phoneNumber)}
                    />
                  </Styled.Author.PhoneNumber>
                )}
              </Styled.Author.Wrapper>
            )}

            <Styled.Timestamp>{timestamp}</Styled.Timestamp>
          </Styled.Header>

          {messages.map((message, index) => {
            return (
              <Message
                key={message.id}
                attachments={message.attachments}
                count={messages.length}
                isRead={message.read}
                index={index}
                patientIsAuthor={patientIsAuthor}
                text={message.body}
              />
            );
          })}
        </Styled.MessageGroupContentWrapper>
      </Styled.Content>

      {deliveredIconIsDisplayed && (
        <Styled.Footer>
          <Styled.DeliveryStatus.Icon />
        </Styled.Footer>
      )}
    </Styled.Wrapper>
  );
};

MessageGroup.displayName = 'MessageGroup';
