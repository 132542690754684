import { Text } from 'react-native-elements';
import styled, { css } from 'styled-components/native';

import { View } from 'app/components/Common/Styled';

import { CellBordersProps } from '../ExperienceTable/Cell';

const borderColor = '#DADCE0';

export const cellFlexSizes = [0.75, 1.75, 1.75];

export const TableWrapper = styled(View)`
  ${({ theme }) => {
    const shadowPx = theme.spacing / 4;
    return css`
      background-color: white;
      border-radius: ${shadowPx}px;
      box-shadow: 0px ${shadowPx}px ${shadowPx}px rgba(0, 0, 0, 0.25);
      margin: ${shadowPx}px;
      padding: ${theme.spacing * 1.25}px;
    `;
  }}
`;

export const RowWrapper = styled(View)`
  flex-direction: row;
`;

// Cell styles

export const CellBorders = styled(View)<CellBordersProps>`
  ${({ theme }) => css`
    align-items: ${({ alignOverride }) =>
      alignOverride ? 'center' : 'stretch'};
    border-width: 1px;
    border-style: solid;
    justify-content: center;
    padding: ${theme.spacing / 2}px ${theme.spacing}px
      ${(theme.spacing / 2) * 2}px;
  `}

  border-top-color: ${({ borderTop }) =>
    `${borderTop ? borderColor : 'transparent'}`};
  border-right-color: ${({ borderRight }) =>
    `${borderRight ? borderColor : 'transparent'}`};
  border-bottom-color: ${({ borderBottom }) =>
    `${borderBottom ? borderColor : 'transparent'}`};
  border-left-color: ${({ borderLeft }) =>
    `${borderLeft ? borderColor : 'transparent'}`};

  ${({ centered }) => centered && `text-align: center;`}
  ${({ flex }) => `flex: ${flex} 0 135px;`}
`;

export const CellHeader = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.bodyText};
    font-family: ${theme.fontFamilyMedium};
    font-size: ${theme.fontSizes.body2}px;
    text-align: center;
  `}
`;

export const CellContent = styled(View)`
  ${({ theme }) => css`
    background-color: #f2f6f9;
    border-radius: 4px;
    flex: 1 0 auto;
    margin-top: ${theme.spacing / 2}px;
    padding: ${theme.spacing / 2}px;
  `}
`;

export const CellDetailText = styled(Text)<{
  centered: boolean;
}>`
  ${({ theme }) => css`
    text-align: ${({ centered }) => (centered ? 'center' : 'left')};
    color: ${theme.colors.bodyText};
    font-family: ${theme.fontFamily};
    font-size: ${theme.fontSizes.small}px;
  `}
`;

export const DeadButtonSpace = styled(View)`
  ${({ theme }) => css`
    flex: 1 0 ${45 + theme.spacing}px;
  `}
`;
