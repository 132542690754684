import * as LocalAuthentication from 'expo-local-authentication';

export { Component } from 'react';

export const defaultFormState = {
  email: '',
  password: '',
  shouldRememberDevice: true,
};

export { EVENTS } from 'app/util/analytics';

export const expiredAuthLinkConfig = {
  guidanceText:
    "Magic links may only be used once and expire after 2 hours, but it's easy to send another and try again.",
  headerText: 'Magic Link Failed',
  linkText: 'Send Another Magic Link',
};

export { LocalAuthentication };

export { Platform } from 'react-native';

export { default as PropTypes } from 'prop-types';

export { TEST_USER } from 'app/util/devUtils';

export { TestID } from 'app/util/test-id';

export { default as theme } from 'app/util/theme';
