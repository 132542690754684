import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { Platform, StyleSheet, View } from 'react-native';
import { CheckBox } from 'react-native-elements';

import FormError from 'app/components/Common/FormError';
import { Text } from 'app/components/Common/Styled';
import { TestID } from 'app/util/test-id';
import theme from 'app/util/theme';

/**
 * A radio group component wrapper for interacting
 * with redux-form.
 *
 * @example
 * import { Field } from 'redux-form';
 * import RadioGroup from 'app/components/Common/RadioGroup';
 *
 * const MyForm = () => (
 *   <View>
 *    <Field
 *      name="myRadioGroup"
 *      label="My Radio"
 *      options={[{ label: 'Test 1', value: 1 }, { label: 'Test 2', value: 2 }]}
 *      component={RadioGroup}
 *    />
 *   </View>
 * );
 */
export default class RadioGroup extends Component {
  /**
   * @property  {object}    input           object with props passed down from redux-form
   * @property  {function}  input.onChange  function to call when the value changes
   * @property  {function}  input.onBlur    function to call after the changes
   * @property  {*}         input.value     currently selected value
   * @property  {array}     options         array of options to render in the radio group
   * @property  {boolean}   disabled        whether the input is disabled
   * @property  {string}    error           error message to display above the radio group
   * @property  {string}    label           label to display above the radio group
   * @property  {object}    containerStyle  optional style to pass to the container
   * @property  {object}    optionsStyle    optional style to pass to the options container
   */
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func,
      value: PropTypes.any,
    }).isRequired,
    options: PropTypes.array.isRequired,

    disabled: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.string,
    containerStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),

    optionsStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    inline: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    label: '',
    containerStyle: {},
    inline: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      selected: props.input ? props.input.value : null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.input && this.props.input.value !== prevProps.input.value) {
      this.setState({ selected: this.props.input.value });
    }
  }

  onChange = (selected) => {
    this.setState({ selected });
    this.props.input.onChange(selected);

    if (this.props.input.onBlur) {
      // Call input life-cycle events to match ReactNative <Input />.
      setTimeout(this.props.input.onBlur);
    }
  };

  render() {
    return (
      <View
        style={[
          this.props.containerStyle,
          this.props.inline ? styles.containerOptionsInline : null,
        ]}
        testID={TestID.FormDetails.RadioGroup}
      >
        {!!this.props.label && <Text size="body2">{this.props.label}</Text>}

        <View
          style={[
            styles.options,
            this.props.disabled ? styles.optionsDisabled : null,
            this.props.inline ? styles.optionsInline : null,
            StyleSheet.flatten(this.props.optionsStyle),
          ]}
        >
          {this.props.options.map(
            ({ label, value, checkedIcon, uncheckedIcon }, index) => (
              <CheckBox
                containerStyle={{
                  borderWidth: 0,
                  marginRight: theme.spacing * 3,
                }}
                size={32}
                key={index}
                title={label}
                checkedColor={
                  this.props.disabled
                    ? theme.colors.default
                    : theme.colors.primary
                }
                checkedIcon={checkedIcon || 'dot-circle-o'}
                uncheckedIcon={uncheckedIcon || 'circle-o'}
                checked={value === this.state.selected}
                onPress={() => this.props.disabled || this.onChange(value)}
                onIconPress={() => this.props.disabled || this.onChange(value)}
              />
            )
          )}
        </View>

        {this.props.error && <FormError>{this.props.error}</FormError>}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  containerOptionsInline: {
    flex: Platform.select({
      default: 1,
      web: 'none',
    }),
  },

  options: {
    flex: 1,
  },

  optionsDisabled: {
    opacity: 0.3,
  },

  optionsInline: {
    marginTop: theme.spacing / 2,
    flexDirection: Platform.select({
      default: 'column',
      web: 'row',
    }),
  },
});
