import React from 'react';

import { ScrollView as ScrollViewRaw, ScrollViewProps } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

interface ExtendedScrollViewProps extends ScrollViewProps {
  keyboardAware?: boolean;
}

/**
 * Wraps a `<ScrollView />` in such a way that allows styling the
 * `contentContainerStyle` property using a styled component.
 *
 * @example
 * import { ScrollView } from 'app/components/Common/Styled';
 *
 * export const ScrollViewWithFlex = styled(ScrollView)`
 *   flex: 1 0 auto;
 * `;
 */
const ScrollView = ({
  keyboardAware = false,
  style,
  ...rest
}: ExtendedScrollViewProps) =>
  keyboardAware ? (
    <KeyboardAwareScrollView
      contentContainerStyle={style}
      enableOnAndroid
      extraScrollHeight={30}
      keyboardShouldPersistTaps="handled"
      {...rest}
    />
  ) : (
    <ScrollViewRaw {...rest} contentContainerStyle={style} />
  );

export default ScrollView;
