import React from 'react';
import Svg, { Path } from 'react-native-svg';

interface Props {
  height?: number;
  width?: number;
}

const MapDot = ({ height = 140, width = 224 }: Props) => (
  <Svg width={width} height={height} viewBox="0 0 224 140" fill="none">
    <Path
      d="M174.904 34.2598L66.0486 39.3909L69.5074 112.755L178.362 107.624L174.904 34.2598Z"
      fill="#EE7235"
    />
    <Path
      d="M69.4748 112.792V112.76L66.0134 39.3621H66.0462L174.933 34.2295V34.2622L178.394 107.66H178.362L69.4748 112.792ZM66.0828 39.4236L69.5376 112.723L178.326 107.596L174.872 34.2963L66.0828 39.4236Z"
      fill="#3A4750"
    />
    <Path
      d="M175.291 35.1273C175.315 35.1522 174.981 35.5043 174.324 36.1523C173.591 36.8578 172.625 37.7885 171.418 38.9496C168.785 41.4223 165.11 44.8755 160.598 49.1154C151.322 57.6986 138.579 71.1381 124.517 84.1523L124.22 84.4272L123.891 84.1889C123.641 84.0083 123.369 83.8106 123.096 83.6143C107.426 72.1997 93.2914 60.1792 82.923 52.6262C77.8698 48.8863 73.75 45.8389 70.8005 43.6555C69.4429 42.6253 68.3564 41.8006 67.5342 41.1762C66.7946 40.6003 66.4149 40.2848 66.4346 40.2573C66.4542 40.2298 66.8731 40.4916 67.6507 41.0152C68.5004 41.6017 69.6236 42.3766 71.0257 43.344C74.0197 45.4646 78.201 48.4255 83.3315 52.0594C93.7443 59.5521 107.985 69.7991 123.722 81.1233C123.994 81.3197 124.265 81.5173 124.515 81.698L123.891 81.7346C138.031 68.8055 150.798 57.1318 160.124 48.6049C164.724 44.4619 168.472 41.0859 171.156 38.6695C172.415 37.5634 173.422 36.6785 174.186 36.007C174.887 35.4061 175.265 35.1037 175.287 35.1286L175.291 35.1273Z"
      fill="white"
    />
    <Path
      d="M69.8961 113.624C69.6788 113.457 77.3909 103.015 87.1177 90.3022C96.8485 77.5851 104.91 67.4141 105.127 67.5804C105.345 67.7466 97.6339 78.186 87.9032 90.903C78.175 103.616 70.1134 113.788 69.8961 113.624Z"
      fill="white"
    />
    <Path
      d="M141.269 64.957C141.475 64.779 150.034 74.3806 160.383 86.4012C170.736 98.4258 178.957 108.314 178.75 108.492C178.543 108.67 169.986 99.0698 159.633 87.0466C149.284 75.0259 141.06 65.1351 141.267 64.957H141.269Z"
      fill="white"
    />
    <Path
      d="M13.7722 15.9205L110.262 46.5055C110.262 46.5055 115.799 44.8273 113.449 36.9419L10.9013 3.89196C10.9013 3.89196 7.2999 3.64325 4.22737 6.84116C4.22737 6.84116 3.11854 10.886 4.98929 13.1035L13.7709 15.9205H13.7722Z"
      fill="#3A4750"
    />
    <Path
      d="M14.2328 16.097L4.98902 13.1046C2.77135 11.0023 4.15772 6.92085 4.15772 6.92085C7.41483 3.15614 10.9469 3.74912 10.9469 3.74912C28.6725 9.34383 66.3571 21.4077 109.883 35.4363C111.112 35.833 112.336 36.227 113.554 36.6197L113.724 36.6747L113.776 36.8475C114.335 38.7049 114.584 40.713 114.105 42.6267C113.602 44.5313 112.217 46.1689 110.361 46.83L110.268 46.8627L110.161 46.8287C82.9006 38.1342 58.7759 30.4412 41.4313 24.9093C32.8237 22.1355 25.9011 19.9049 21.0952 18.3564C18.744 17.5867 16.9126 16.9871 15.6362 16.5696C15.0418 16.3693 14.5771 16.2122 14.2341 16.0957C13.9212 15.9871 15.1151 16.2999 15.7291 16.4819C17.0238 16.8798 18.8828 17.4492 21.2694 18.1823C26.1 19.6889 33.0607 21.858 41.714 24.5558C59.0391 30.0131 83.1362 37.6054 110.365 46.1833H110.165C111.729 45.6204 113.013 44.1557 113.449 42.45C113.881 40.7208 113.666 38.828 113.124 37.0399L113.346 37.2663C112.128 36.8736 110.904 36.4796 109.675 36.083C66.1489 22.0569 28.5115 9.84518 10.8552 4.03579L10.5345 4.05543C10.5345 4.05543 7.8547 3.68628 4.50464 7.08186C4.50464 7.08186 3.16409 10.3557 5.05971 12.8035"
      fill="#3A4750"
    />
    <Path
      d="M94.9329 41.6215L110.262 46.5067C110.262 46.5067 115.799 44.8285 113.449 36.943L98.0644 31.9426L94.9329 41.6215Z"
      fill="#E0E0E0"
    />
    <Path
      d="M94.933 41.6214C94.8859 41.6462 95.1412 40.7312 95.6347 39.0452C96.1283 37.3605 96.8627 34.8917 97.8118 31.8614L97.8917 31.6074L98.1483 31.6899C102.4 33.05 107.74 34.7595 113.555 36.6196L113.735 36.6772L113.777 36.8474C113.813 36.9914 113.853 37.1485 113.891 37.3042C114.387 39.1722 114.58 41.1724 113.989 42.9814C113.427 44.8062 112.012 46.2081 110.352 46.8063L110.266 46.8378L110.166 46.805C105.593 45.3206 101.741 44.0208 99.0633 43.1058C97.817 42.6725 96.7881 42.3151 95.9699 42.0311C95.2642 41.7784 94.9016 41.6384 94.933 41.6214C94.9644 41.6056 95.386 41.7156 96.144 41.929C96.9936 42.1764 98.0618 42.4879 99.3566 42.8649C102.087 43.6726 105.898 44.8153 110.356 46.2094H110.171C111.573 45.6806 112.879 44.4095 113.355 42.7746C113.881 41.1449 113.71 39.273 113.232 37.4796C113.195 37.3304 113.157 37.1799 113.123 37.0424L113.345 37.2689C107.545 35.3538 102.222 33.5971 97.9833 32.1978L98.3197 32.0263C97.3497 34.9231 96.5184 37.2925 95.9254 38.9654C95.6478 39.7351 95.4174 40.3778 95.225 40.9106C95.0574 41.3622 94.9566 41.6096 94.933 41.6214Z"
      fill="#3A4750"
    />
    <Path
      d="M47.9366 39.73L49.5296 42.3688C51.3186 45.328 53.9217 47.7165 57.0273 49.2487L59.7963 50.6157L57.1479 52.2029C54.178 53.9855 51.7809 56.5792 50.2432 59.6737L48.8763 62.4327L47.2833 59.7939C45.4943 56.8346 42.8912 54.4462 39.7855 52.914L37.0166 51.547L39.6649 49.9597C42.6349 48.1771 45.0319 45.5834 46.5697 42.4889L47.9366 39.73Z"
      fill="#F6B99A"
    />
    <Path
      d="M184.771 3.78596L185.833 5.54506C187.025 7.51824 188.76 9.11173 190.831 10.1316L192.678 11.0405L190.912 12.0988C188.932 13.2863 187.333 15.0151 186.31 17.0792L185.398 18.9191L184.336 17.16C183.144 15.1868 181.409 13.5933 179.338 12.5734L177.491 11.6625L179.257 10.6042C181.237 9.41669 182.834 7.68789 183.859 5.62382L184.773 3.78394L184.771 3.78596Z"
      fill="#F6B99A"
    />
    <Path
      d="M137.47 17.0294L138.001 18.1289C138.598 19.362 139.465 20.3571 140.501 20.9956L141.424 21.563L140.541 22.2244C139.551 22.9671 138.752 24.0479 138.239 25.3372L137.784 26.4868L137.253 25.3873C136.656 24.1543 135.789 23.1591 134.753 22.5207L133.83 21.9511L134.713 21.2897C135.703 20.547 136.502 19.4663 137.015 18.1769L137.472 17.0273L137.47 17.0294Z"
      fill="#F6B99A"
    />
    <Path
      d="M198.138 54.8675L198.934 56.1869C199.829 57.6665 201.13 58.8607 202.683 59.6269L204.068 60.3078L202.743 61.1015C201.258 61.9928 200.06 63.2896 199.291 64.8369L198.608 66.2163L197.811 64.8969C196.917 63.4173 195.615 62.2231 194.062 61.457L192.678 60.7735L194.002 59.9799C195.487 59.0886 196.685 57.7917 197.454 56.2445L198.14 54.865L198.138 54.8675Z"
      fill="#F6B99A"
    />
    <Path
      d="M112.413 53.2002L113.391 54.8275C114.491 56.6523 116.09 58.1252 117.998 59.0701L119.7 59.913L118.072 60.8918C116.248 61.9911 114.775 63.5906 113.83 65.4988L112.987 67.2002L112.008 65.5729C110.909 63.7481 109.309 62.2752 107.401 61.3303L105.7 60.4874L107.327 59.5085C109.152 58.4093 110.625 56.8098 111.57 54.9016L112.413 53.2002Z"
      fill="#F6B99A"
    />
    <Path
      d="M95.6079 20.4001L95.8199 20.7519C96.0571 21.1471 96.4031 21.4656 96.8167 21.6695L97.1845 21.8517L96.8328 22.0636C96.4375 22.3008 96.1189 22.6468 95.915 23.0604L95.7328 23.4281L95.5209 23.0764C95.2837 22.6812 94.9377 22.3627 94.524 22.1587L94.1562 21.9766L94.508 21.7646C94.9033 21.5275 95.2218 21.1815 95.4258 20.7679L95.6079 20.4001Z"
      fill="#F6B99A"
    />
    <Path
      d="M128.967 49.1895L129.366 49.8487C129.812 50.5896 130.462 51.1866 131.24 51.5688L131.932 51.9103L131.27 52.3075C130.527 52.752 129.928 53.4006 129.545 54.1758L129.202 54.8651L128.803 54.2059C128.357 53.465 127.707 52.868 126.929 52.4858L126.237 52.1443L126.899 51.7471C127.642 51.3004 128.241 50.654 128.624 49.8788L128.967 49.1895Z"
      fill="#F6B99A"
    />
    <Path
      d="M167.553 62.4326L167.765 62.7843C168.002 63.1796 168.348 63.4981 168.762 63.702L169.13 63.8842L168.778 64.0961C168.383 64.3333 168.064 64.6793 167.86 65.0928L167.678 65.4606L167.466 65.1089C167.229 64.7136 166.883 64.3951 166.469 64.1912L166.101 64.009L166.453 63.7971C166.848 63.5599 167.167 63.214 167.371 62.8004L167.553 62.4326Z"
      fill="#F6B99A"
    />
    <Path
      d="M78.4681 3.78576L79.5291 5.76386C80.72 7.98423 82.4552 9.77472 84.5268 10.9241L86.3725 11.9477L84.608 13.1371C82.6274 14.4741 81.0303 16.4176 80.0051 18.7418L79.092 20.811L78.0311 18.8329C76.8385 16.6125 75.1049 14.822 73.0317 13.6727L71.186 12.649L72.9505 11.4578C74.9311 10.1226 76.5282 8.1773 77.5534 5.85312L78.4665 3.78394L78.4681 3.78576Z"
      fill="#F6B99A"
    />
    <Path
      d="M146.278 29.9033L147.026 31.1475C147.867 32.5441 149.09 33.6702 150.552 34.3932L151.853 35.037L150.609 35.7863C149.213 36.6272 148.085 37.8496 147.363 39.3115L146.719 40.6129L145.971 39.3687C145.13 37.9722 143.908 36.846 142.446 36.1231L141.144 35.4792L142.388 34.73C143.785 33.8902 144.911 32.6666 145.634 31.2059L146.278 29.9033Z"
      fill="#F6B99A"
    />
    <Path
      d="M145.911 75.6758L146.289 76.3036C146.713 77.0082 147.33 77.5764 148.068 77.9407L148.724 78.265L148.096 78.643C147.392 79.0669 146.823 79.6833 146.459 80.4211L146.135 81.0776L145.757 80.4497C145.333 79.7452 144.716 79.1769 143.978 78.8126L143.322 78.4884L143.95 78.1103C144.654 77.6864 145.223 77.07 145.587 76.3322L145.911 75.6758Z"
      fill="#F6B99A"
    />
    <Path
      opacity="0.3"
      d="M124.108 136.551C154.052 136.551 178.326 133.738 178.326 130.268C178.326 126.797 154.052 123.984 124.108 123.984C94.1647 123.984 69.8906 126.797 69.8906 130.268C69.8906 133.738 94.1647 136.551 124.108 136.551Z"
      fill="#F6B99A"
    />
  </Svg>
);

export default MapDot;
