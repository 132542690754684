import React from 'react';
import { useHistory } from 'react-router-dom';

import Login from 'app/components/Login';

import BlurLayer from './BlurLayer';
import { isProtected } from './helpers';
import { Container } from './styles';

/**
 * Renders the `<Login />` component within a fixed position element that covers
 * the application content, along with an animated blur layer beneath.
 */
const LoginOverlay = ({ isVisible }) => {
  const { location } = useHistory();

  const showLogin = isVisible && isProtected(location.pathname);

  return (
    <>
      {showLogin && (
        <Container>
          <Login />
        </Container>
      )}
      <BlurLayer blur={showLogin} />
    </>
  );
};

export default LoginOverlay;
