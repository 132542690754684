import { Button } from 'react-native-elements';
import styled from 'styled-components/native';

export const TopBackLinkButton = styled(Button).attrs(({ theme }) => {
  return {
    buttonStyle: {
      marginLeft: -theme.spacing * 0.75,
      padding: 0,
      paddingTop: theme.isNarrow ? 0 : theme.spacing,
    },
    containerStyle: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
    },
    titleStyle: {
      fontFamily: theme.fontFamily,
      fontSize: theme.fontSizes.body1,
    },
    icon: {
      color: theme.colors.primary,
      name: 'chevron-left',
      size: 28,
    },
    type: 'clear',
  };
})``;
