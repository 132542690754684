import { Platform, View, ScrollView as RNScrollView } from 'react-native';

import { Icon as RNEIcon } from 'react-native-elements';
import styled from 'styled-components/native';

import Text from 'app/components/Common/Styled/Text';
import theme from 'app/util/theme';

const iconPadding = Platform.select({
  default: theme.spacing * 2.5,
  web: theme.spacing,
});

const iconSize = Platform.select({
  default: 206,
  web: 156,
});

export const Container = styled(View)`
  align-items: stretch;
  flex: 1;
  max-width: ${({ theme }) => theme.breakpoints.small}px;
  padding: ${({ theme }) => theme.spacing}px;
`;

export const Icon = styled(RNEIcon).attrs({
  size: iconSize,
})`
  padding: ${iconPadding}px 0;
`;

export const ScrollView = styled(RNScrollView).attrs({
  contentContainerStyle: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
})``;

export const Title = styled(Text).attrs({
  align: 'center',
  size: 'body2',
})`
  margin-bottom: ${({ theme }) => theme.spacing * 0.75}px;
`;
