const getParenthetical = (text) => (text ? `(${text})` : '');

export const getProcedureName = (procedure) => {
  if (procedure?.colloquialName) return procedure.colloquialName;
  if (procedure?.name) return procedure.name;
  return '';
};

export const getProcedureColloquialName = (procedure) => {
  if (procedure?.colloquialName) return getParenthetical(procedure?.name);
  return '';
};

const getExtendedTitle = (text) => {
  if (text) return ` ${String.fromCharCode(8212)} ${text}`;
  return '';
};

// Determine the display name for a procedure.
export const getProcedureTitle = (procedure) => {
  if (procedure?.colloquialName)
    return procedure.colloquialName + getExtendedTitle(procedure?.name);
  if (procedure?.name) return procedure.name;
  return '';
};
