import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

export interface CheckCircleProps {
  color?: string;
  size?: number;
  style?: any;
}

const CheckCircle = ({
  color = '#8B8D8B',
  size = 16,
  style,
}: CheckCircleProps) => (
  <Svg width={size} height={size} viewBox="0 0 16 16" fill="none" style={style}>
    <Circle cx="8" cy="8" r="8" fill={color} />
    <Path
      d="M6.37891 12.1377C6.57422 12.333 6.90625 12.333 7.10156 12.1377L12.8438 6.39551C13.0391 6.2002 13.0391 5.86816 12.8438 5.67285L12.1406 4.96973C11.9453 4.77441 11.6328 4.77441 11.4375 4.96973L6.75 9.65723L4.54297 7.46973C4.34766 7.27441 4.03516 7.27441 3.83984 7.46973L3.13672 8.17285C2.94141 8.36816 2.94141 8.7002 3.13672 8.89551L6.37891 12.1377Z"
      fill="white"
    />
  </Svg>
);

export default CheckCircle;
