import axios from 'axios';

import { showGlobalError } from 'app/actions/uiActions';
import { CARE_API_URL } from 'app/util/constants';
import { unformatObject } from 'app/util/reducerUtils';

import { receiveEpisodeLoading, receiveInquiry } from './action-types';

/**
 * Makes a PATCH request to the Care Service API
 * to update an inquiry.
 *
 * @param {integer} inquiryId - the ID of the inquiry to update.
 * @param {object} inquiry - inquiry details to save in the API.
 * @return {promise} a promise that resolves after the inquiry is updated.
 */
export default (inquiryId, inquiry) => {
  return async (dispatch, getState) => {
    try {
      const { coreToken } = getState().session;

      dispatch(receiveEpisodeLoading(true));

      const response = await axios.patch(
        `${CARE_API_URL}/inquiries/${inquiryId}`,
        unformatObject({ inquiry }),
        {
          headers: { Authorization: `Bearer ${coreToken}` },
        }
      );

      dispatch(receiveInquiry(response.data.data));
      dispatch(receiveEpisodeLoading(false));

      return true;
    } catch (error) {
      dispatch(showGlobalError(error));
      dispatch(receiveEpisodeLoading(false));

      return false;
    }
  };
};
