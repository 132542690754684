import React from 'react';
import { useDispatch } from 'react-redux';

import { useHistory } from '@cross-platform/react-router-native';
import { markTaskComplete } from 'app/actions/episodeActions';
import StickyNavFooter from 'app/components/Common/StickyNavFooter';
import { NavigationMode } from 'app/components/Common/StickyNavFooter/types';
import { useNextTask } from 'app/hooks';
import { logEvent, EVENTS } from 'app/util/analytics';
import { TestID } from 'app/util/test-id';

import {
  ButtonWrapper,
  HtmlView,
  ManualTaskButton,
  ManualTaskContainer,
  ManualTaskScrollView,
} from './styles';

interface ManualTaskProps {
  episodeId: string;
  task: any;
}

const ManualTask = ({ episodeId, task }: ManualTaskProps) => {
  const { goToNextTask } = useNextTask();
  const { push } = useHistory();
  const dispatch = useDispatch();

  const handleManualTaskClick = async () => {
    await logEvent(EVENTS.tasks.complete, { task: task.title });
    await dispatch(markTaskComplete(episodeId, task.stepId, task.id));

    goToNextTask();
  };

  if (task?.taskType === 'automated') {
    return (
      <ManualTaskContainer>
        <ManualTaskScrollView>
          <HtmlView html={task?.meta?.details || ''} />
        </ManualTaskScrollView>
      </ManualTaskContainer>
    );
  }

  return (
    <ManualTaskContainer>
      <ManualTaskScrollView>
        <HtmlView html={task?.meta?.details || ''} />
        <ButtonWrapper>
          <ManualTaskButton
            title={task?.ctaLabel || 'Confirm'}
            onPress={handleManualTaskClick}
            testID={TestID.WorkflowTask.ManualTaskButton}
          />
        </ButtonWrapper>
      </ManualTaskScrollView>

      <StickyNavFooter
        navigationConfig={[
          {
            onPress: handleManualTaskClick,
            testID: TestID.WorkflowTask.ManualTaskButton,
            text: task?.ctaLabel || 'Confirm',
          },
        ]}
        navigationMode={NavigationMode.Continue}
        testID={TestID.WorkflowTask.NavFooter}
      />
    </ManualTaskContainer>
  );
};

export default ManualTask;
