import React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

/**
 * Renders the Carrum Health logo as an SVG.
 */
const RedVerticalLogo = ({ height, width }) => (
  <Svg
    height={height}
    width={width}
    viewBox="0 0 168 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      d="M63.497 31.164C63.497 20.0857 72.5237 11.0706 83.6218 11.0706C87.6647 11.0706 91.5641 12.2608 94.8943 14.5159C97.4298 16.2309 100.875 15.5705 102.596 13.039C104.313 10.5074 103.652 7.06725 101.116 5.34971C95.9429 1.85064 89.8951 0 83.6244 0C66.4144 0 52.4116 13.9809 52.4116 31.164C52.4116 35.111 53.1474 38.9505 54.5856 42.5878L64.8198 38.3259C63.9456 36.0478 63.497 33.6417 63.497 31.164Z"
      fill="#A53723"
    />
    <Path
      d="M83.6223 62.3304C90.7749 62.3304 97.7661 59.8526 103.306 55.3528C108.764 50.9194 112.599 44.7199 114.104 37.8933C114.763 34.9087 112.874 31.9548 109.882 31.297C106.89 30.6366 103.934 32.5256 103.275 35.5128C101.263 44.638 92.9976 51.2598 83.6223 51.2598C75.4544 51.2598 68.153 46.3913 65.0228 38.8582C64.951 38.6842 64.8869 38.505 64.8177 38.331L54.5835 42.5928C54.6502 42.7617 54.7117 42.9332 54.7809 43.1022C59.6339 54.7819 70.9551 62.3304 83.6223 62.3304Z"
      fill="#EF7136"
    />
    <Path
      d="M7.87818 97.7001C9.53688 97.7001 10.6444 96.7786 11.2238 95.4169C11.3955 95.015 11.8826 94.8512 12.2723 95.0483L14.0028 95.9211C14.3694 96.1054 14.5335 96.5457 14.3668 96.9194C13.3568 99.177 11.0469 100.869 7.87818 100.869C3.29689 100.869 0 97.4851 0 92.5449C0 87.6073 3.29689 84.2209 7.87818 84.2209C11.0212 84.2209 13.2773 85.8412 14.3002 88.0655C14.4694 88.4315 14.3181 88.8693 13.9618 89.0612L12.2774 89.9776C11.8852 90.19 11.3878 90.0313 11.2084 89.6218C10.6264 88.2908 9.52149 87.3616 7.87818 87.3616C5.37347 87.3616 3.66349 89.2814 3.66349 92.5449C3.66606 95.8085 5.37603 97.7001 7.87818 97.7001Z"
      fill="#50514F"
    />
    <Path
      d="M15.7178 96.0825C15.7178 93.0339 18.0071 91.6927 21.0912 91.0528L25.5187 90.139V89.8651C25.5187 88.3395 24.724 87.395 22.7704 87.395C21.2784 87.395 20.3914 87.9786 19.9017 89.1228C19.753 89.4709 19.3915 89.6731 19.0224 89.5886L17.0945 89.1458C16.6304 89.0383 16.3561 88.534 16.5535 88.1015C17.5611 85.8694 19.7709 84.2236 22.9243 84.2236C26.7723 84.2236 29.0925 86.0537 29.0925 89.7422V96.6328C29.0925 97.3854 29.3617 97.7053 30.018 97.7258C30.2872 97.7335 30.4974 97.9664 30.4974 98.2377V100.07C30.4974 100.334 30.2974 100.562 30.0333 100.582C27.6773 100.777 26.4032 100.245 25.8879 99.0108C24.8804 100.14 23.2011 100.81 21.1246 100.81C18.0687 100.81 15.7178 98.9493 15.7178 96.0825ZM25.5187 93.2643C25.5187 93.1005 25.3675 92.9776 25.2059 93.0109L22.068 93.6764C20.5118 94.0117 19.3505 94.4981 19.3505 95.9315C19.3505 97.2344 20.3452 97.9408 21.8527 97.8794C23.8703 97.7975 25.5187 96.0083 25.5187 93.9913V93.2643Z"
      fill="#50514F"
    />
    <Path
      d="M41.7293 87.3079C41.7293 87.7456 41.3653 88.1014 40.9269 88.0783C40.7603 88.0706 40.5834 88.0655 40.386 88.0655C38.0658 88.0655 36.2943 89.5604 36.2943 92.12V99.7632C36.2943 100.188 35.9483 100.534 35.5227 100.534H33.4025C32.977 100.534 32.6309 100.188 32.6309 99.7632V85.2985C32.6309 84.8736 32.977 84.5281 33.4025 84.5281H35.5227C35.9483 84.5281 36.2943 84.8736 36.2943 85.2985V86.906C36.9968 85.4111 38.6452 84.4359 40.691 84.4359C40.7987 84.4359 40.8987 84.4385 40.9961 84.441C41.4063 84.4564 41.7293 84.7994 41.7293 85.2115V87.3079Z"
      fill="#50514F"
    />
    <Path
      d="M52.576 87.3079C52.576 87.7456 52.212 88.1014 51.7736 88.0783C51.607 88.0706 51.4301 88.0655 51.2327 88.0655C48.9125 88.0655 47.141 89.5604 47.141 92.12V99.7632C47.141 100.188 46.7949 100.534 46.3694 100.534H44.2492C43.8236 100.534 43.4775 100.188 43.4775 99.7632V85.2985C43.4775 84.8736 43.8236 84.5281 44.2492 84.5281H46.3694C46.7949 84.5281 47.141 84.8736 47.141 85.2985V86.906C47.8435 85.4111 49.4919 84.4359 51.5377 84.4359C51.6454 84.4359 51.7454 84.4385 51.8428 84.441C52.253 84.4564 52.576 84.7994 52.576 85.2115V87.3079Z"
      fill="#50514F"
    />
    <Path
      d="M66.9608 100.534H64.8407C64.4151 100.534 64.069 100.188 64.069 99.7632V98.8264C63.1538 99.8937 61.7796 100.869 59.7031 100.869C56.3446 100.869 54.2681 98.5832 54.2681 95.1379V85.2985C54.2681 84.8736 54.6142 84.5281 55.0397 84.5281H57.1599C57.5855 84.5281 57.9316 84.8736 57.9316 85.2985V94.3444C57.9316 96.2949 58.7878 97.5465 60.7721 97.5465C62.4206 97.5465 64.069 96.3281 64.069 94.0398V85.2985C64.069 84.8736 64.4151 84.5281 64.8407 84.5281H66.9608C67.3864 84.5281 67.7325 84.8736 67.7325 85.2985V99.7632C67.7325 100.191 67.3864 100.534 66.9608 100.534Z"
      fill="#50514F"
    />
    <Path
      d="M72.8469 100.534H70.7267C70.3012 100.534 69.9551 100.188 69.9551 99.7633V85.2986C69.9551 84.8737 70.3012 84.5282 70.7267 84.5282H72.8469C73.2725 84.5282 73.6186 84.8737 73.6186 85.2986V86.2355C74.503 85.1681 75.8772 84.2236 78.0153 84.2236C79.938 84.2236 81.3737 85.0759 82.2607 86.4479C83.2375 85.4113 84.7654 84.2236 87.2368 84.2236C90.5337 84.2236 92.6718 86.5401 92.6718 89.9854V99.7633C92.6718 100.188 92.3257 100.534 91.9001 100.534H89.78C89.3544 100.534 89.0083 100.188 89.0083 99.7633V91.0528C89.0083 89.0716 88.2751 87.6382 86.3216 87.6382C84.7654 87.6382 83.1452 88.8566 83.1452 91.1449V99.7633C83.1452 100.188 82.7991 100.534 82.3735 100.534H80.2534C79.8278 100.534 79.4817 100.188 79.4817 99.7633V91.0528C79.4817 89.0716 78.7485 87.6382 76.795 87.6382C75.2362 87.6382 73.6186 88.8566 73.6186 91.1449V99.7633C73.6186 100.191 73.2725 100.534 72.8469 100.534Z"
      fill="#50514F"
    />
    <Path
      d="M104.711 84.8198C102.355 84.8198 100.693 86.0996 99.7884 87.4767V79.3651H98.3579V100.58H99.7884V91.1038C99.7884 88.027 102.216 86.1892 104.565 86.1892C107.913 86.1892 108.413 88.9408 108.413 90.5816V100.58H109.843V90.4664C109.843 86.9289 107.926 84.8198 104.711 84.8198Z"
      fill="#50514F"
    />
    <Path
      d="M119.234 84.8198C115.224 84.8198 112.532 88.0449 112.532 92.8443C112.532 97.6437 115.25 100.869 119.293 100.869C122.295 100.869 124.494 99.1309 125.176 96.2231L125.22 96.0362L123.823 95.7188L123.784 95.9108C123.312 98.2094 121.674 99.5276 119.295 99.5276C116.091 99.5276 113.937 96.8425 113.937 92.8443L125.358 92.8341V92.294C125.353 87.6815 123.007 84.8198 119.234 84.8198ZM114.017 91.5491C114.447 88.219 116.432 86.1585 119.234 86.1585C123.215 86.1585 123.807 90.0543 123.887 91.5491H114.017Z"
      fill="#50514F"
    />
    <Path
      d="M138.623 99.2077C138.333 99.0131 138.179 98.6778 138.179 98.2401V89.335C138.179 86.5091 136.315 84.8198 133.193 84.8198C130.398 84.8198 128.201 86.3274 127.458 88.7565L127.409 88.9203L128.755 89.5142L128.814 89.2992C129.378 87.2745 130.932 86.161 133.19 86.161C135.518 86.161 136.746 87.2898 136.746 89.4221V90.5713L134.141 91.091C130.439 91.8256 127.012 92.9928 127.012 96.5302C127.012 99.5302 129.486 100.871 131.939 100.871C134.1 100.871 135.905 99.7836 136.81 98.5601C136.854 99.2409 137.1 99.7733 137.541 100.142C138.143 100.646 139.071 100.807 140.225 100.605L140.384 100.577V99.113L140.12 99.2205C139.548 99.4585 138.989 99.4534 138.623 99.2077ZM136.749 91.8589V95.5704C136.749 97.4952 134.818 99.5558 131.942 99.5558C129.834 99.5558 128.473 98.3655 128.473 96.5277C128.473 94.5875 129.44 93.3383 134.539 92.3068L136.749 91.8589Z"
      fill="#50514F"
    />
    <Path d="M143.884 79.3651H142.453V100.58H143.884V79.3651Z" fill="#50514F" />
    <Path
      d="M152.211 99.3535C150.893 99.3535 150.078 98.9645 150.078 97.0217V86.3914H154V85.1064H150.078L150.057 79.4086H148.658L148.678 85.1064H145.917V86.3914H148.678V97.1368C148.678 99.5301 149.824 100.695 152.183 100.695C152.993 100.695 153.534 100.621 154.326 100.398L154.467 100.359V98.9568L154.21 99.0464C153.426 99.3177 152.785 99.3535 152.211 99.3535Z"
      fill="#50514F"
    />
    <Path
      d="M162.867 84.8197C160.511 84.8197 158.85 86.0995 157.945 87.4766V79.3651H156.515V100.58H157.945V91.1037C157.945 88.027 160.373 86.1891 162.721 86.1891C166.069 86.1891 166.569 88.9408 166.569 90.5815V100.58H168V90.4663C168 86.9289 166.08 84.8197 162.867 84.8197Z"
      fill="#50514F"
    />
  </Svg>
);

/**
 * @property {number} height The rendered height.
 * @property {number} width The rendered width.
 */
RedVerticalLogo.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default RedVerticalLogo;
