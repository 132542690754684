import React from 'react';
import { Image, ScrollView, StyleSheet, View } from 'react-native';
import { Text } from 'react-native-elements';

import confetti from 'app/assets/images/confetti.gif';

import AnimatedTrophy from './AnimatedTrophy';
import CongratsFooter from './CongratsFooter';
import theme from 'app/util/theme';

const Congratulations = () => (
  <View style={styles.container}>
    <Image
      source={confetti}
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        left: 0,
        top: 0,
      }}
    />

    <ScrollView enableOnAndroid contentContainerStyle={styles.scrollView}>
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          marginTop: theme.spacing * 1.25,
          marginBottom: -2.5 * theme.spacing,
        }}
      >
        <AnimatedTrophy />
      </View>

      <Text h1>Congratulations!</Text>

      <View style={{ flex: 1, alignItems: 'center' }}>
        <Text
          h3
          style={{
            textAlign: 'center',
            paddingHorizontal: theme.spacing * 1.25,
          }}
        >
          You&apos;ve completed your Carrum journey!
        </Text>
      </View>
    </ScrollView>

    <CongratsFooter />
  </View>
);

export default Congratulations;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

  loadingContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  tasksContainer: {
    flex: 1,
    padding: theme.spacing * 1.25,
  },

  scrollView: {
    flex: 1,
    paddingBottom: theme.spacing * 2.5,
  },

  anchor: {
    textAlign: 'center',
    marginTop: theme.spacing / 2,
    marginBottom: theme.spacing * 1.25,
  },
});
