import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Route, Switch } from '@cross-platform/react-router-native';

import { receiveTempCoeSelection } from 'app/actions/episodeActions';
import { ProgressNav } from 'app/components/Common/ProgressNav';
import { defaultTempCoeSelection } from 'app/reducers/episodeReducer';

import Confirmation from './Confirmation';
import DoctorDetails from './Details/DoctorDetails';
import FacilityDetails from './Details/FacilityDetails';
import Introduction from './Introduction';
import Selections from './Selections';
import { SelectYourDoctorRoutes } from './routes';

const SelectYourDoctor = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Reset the local user selection if this page unmounts
    return () => {
      dispatch(receiveTempCoeSelection(defaultTempCoeSelection));
    };
  }, []);

  return (
    <ProgressNav>
      <Switch>
        <Route
          exact
          path={SelectYourDoctorRoutes.Facilities}
          component={Selections}
        />
        <Route
          exact
          path={SelectYourDoctorRoutes.Doctors}
          component={Selections}
        />
        <Route
          exact
          path={SelectYourDoctorRoutes.Facility}
          component={FacilityDetails}
        />
        <Route
          exact
          path={SelectYourDoctorRoutes.Doctor}
          component={DoctorDetails}
        />
        <Route
          exact
          path={SelectYourDoctorRoutes.Confirmation}
          component={Confirmation}
        />
        <Route path={SelectYourDoctorRoutes.Root} component={Introduction} />
      </Switch>
    </ProgressNav>
  );
};

export default SelectYourDoctor;
