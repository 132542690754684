import React from 'react';

import { NumberBadgeWrapper, NumberBadgeText } from './styles';

interface NumberBadgeProps {
  number: number;
}

export const NumberBadge = ({ number }: NumberBadgeProps) => {
  return (
    <NumberBadgeWrapper>
      <NumberBadgeText>{number.toString()}</NumberBadgeText>
    </NumberBadgeWrapper>
  );
};
