import React from 'react';

import Cell from './Cell';
import { experienceModalContent } from '../data';

import { cellFlexSizes, RowWrapper } from './styles';

const HeaderRow = () => {
  const [GoldComponent, SilverComponent] = experienceModalContent.columnTitles;
  const [cell1Flex, cell2Flex, cell3Flex] = cellFlexSizes;

  return (
    <RowWrapper>
      <Cell flex={cell1Flex} header="" borderRight />
      <Cell flex={cell2Flex} header={GoldComponent} borderRight />
      <Cell flex={cell3Flex} header={SilverComponent} />
    </RowWrapper>
  );
};

export default HeaderRow;
