import {
  Linking,
  MessageStyles as Styled,
} from 'app/components/Messages/styles';

import { MessageProps } from 'app/components/Messages/types';
import { MessageBody } from './MessageBody';

/**
 * Render a single message within a message group.
 *
 * @param  attachments      array of attachments for message
 * @param  count            number of messages in group
 * @param  index            index of message in group
 * @param  isRead           whether current user read message
 * @param  patientIsAuthor  whether current user is author
 * @param  text             message text
 */
export const Message = ({
  attachments = [],
  count = 0,
  index = 0,
  isRead = false,
  patientIsAuthor = false,
  text = '',
}: MessageProps) => {
  const hasText = Boolean(text?.length);

  return (
    <Styled.Wrapper>
      {hasText && (
        <MessageBody
          count={count}
          index={index}
          isRead={isRead}
          isVisible={hasText}
          patientIsAuthor={patientIsAuthor}
          text={text.replace(/<(|\/)\w+>/g, '')} // strip html
        />
      )}

      {attachments.map((attachment, index) => (
        <Styled.AttachmentPreview
          file={attachment}
          key={index}
          onPress={() => Linking.openURL(attachment)}
          patientIsAuthor={patientIsAuthor}
        />
      ))}
    </Styled.Wrapper>
  );
};
