import theme from 'app/util/theme';

export const getWidth = (modalSize, screenWidth): number | string => {
  switch (true) {
    case modalSize === 'small' && screenWidth < theme.breakpoints.xsmall:
      return '100%';
    case modalSize === 'small':
      return theme.spacing * 25; // 400: magic number, prescribed by Aimee/Design
    case modalSize === 'medium' && screenWidth < theme.breakpoints.small:
      return '100%';
    case modalSize === 'medium':
      return theme.spacing * 43; // 688: magic number, prescribed by Aimee/Design
    case modalSize === 'large' && screenWidth < theme.breakpoints.small:
      return '100%';
    case modalSize === 'large':
      return theme.spacing * 48; // 768: magic number, prescribed by Aimee/Design
    case modalSize === 'xlarge' && screenWidth < theme.breakpoints.medium:
      return '100%';
    case modalSize === 'xlarge':
      return theme.spacing * 55; // 880: magic number, prescribed by Aimee/Design
  }
};
