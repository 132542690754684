import { TestID } from 'app/components/Forms/constants';

import {
  capitalizeFirstLetters,
  findNextLinkedQuestion,
  findNextUnlinkedQuestion,
  findPreviousLinkedQuestion,
  getCurrentQuestion,
  getCurrentQuestionNumber,
  getTotalQuestionCount,
  parameterize,
  validateQuestion,
} from 'app/components/Forms/helpers';

import {
  useCallback,
  useEffect,
  useHistory,
  useNextTask,
  useState,
} from 'app/components/Forms/hooks';

import {
  CenteredScrollView,
  ConstrainedWidthView,
  LeftAlignedView,
  FormQuestion,
  QuestionWrapper,
  SaveLink,
  StickyNavFooter,
  FormFadeaway,
} from 'app/components/Forms/styles';

import { FormDetailsProps, NavigationMode } from 'app/components/Forms/types';

/**
 * Render a form with individual question inputs arranged in a sequence so that
 * patients can modify and submit answers that meet validation requirements.
 * Enable patients to navigate between questions using "previous" and "next"
 * navigation.
 *
 * @property  initialize    initialize the redux-form values
 * @property  [iterations]  array of iterations of the same form
 * @property  onSave        method to save the form
 * @property  onSubmit      method to submit the form
 * @property  patientName   name of patient
 * @property  values        redux-form values
 */
const FormDetails = ({
  initialize,
  iterations = [],
  onSave,
  onSubmit,
  patientName,
  values,
}: FormDetailsProps) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [error, setError] = useState<any>();
  const [hasChanged, setHasChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { goToNextTask, presentationMode } = useNextTask();
  const history = useHistory();

  const submissions = [...iterations];
  const earliestIncompleteForm =
    submissions.find(({ completedOn }) => !completedOn) ||
    submissions.reverse()[submissions.length - 1];
  const currentOrder = earliestIncompleteForm.order;
  const submission = iterations[currentOrder];
  const currentQuestion = getCurrentQuestion(submission, currentQuestionIndex);
  const questionKey = parameterize(currentQuestion.label);
  const currentStep = getCurrentQuestionNumber(
    currentQuestion,
    submission.formQuestions
  );
  const isFirstQuestion = currentStep === 1;
  const isFinalQuestion =
    currentQuestionIndex === submission.formQuestions.length - 1;

  /**
   * When this component mounts, initialize the `redux-form` state with the
   * selected form to populate existing values (if any).
   *
   * TODO: Remove when `redux-form` is removed from the app.
   */
  useEffect(() => {
    initialize(submission);
  }, [submission?.formKey]);

  /**
   * Handle the final navigation event after submitting a completed form based
   * on the presentation mode for the current task.
   *
   * If the presentation mode is 'workflow,' go automatically to the next task.
   *
   * Otherwise, go back to the previous page that the user navigated from.
   */
  const onComplete = useCallback(() => {
    if (presentationMode === 'workflow') return goToNextTask();
    history.goBack();
  }, [history.goBack, goToNextTask, presentationMode]);

  const goToQuestion = (index) => {
    setCurrentQuestionIndex(index);
    setHasChanged(false);
    setError(null);
  };

  /**
   * Navigate to the next question in the form if one exists. Otherwise, submit
   * the form.
   *
   * Some questions are conditional based on a user's response, so navigate to
   * 1 of 3 possible destinations:
   *
   * 1. If question has a nested `nextQuestions` property, find the index of a
   *    question with a `label` that matches the current question's `response`.
   *
   * 2. If question has no nested `nextQuestions` property, move to the next
   *    question in sequence.
   *
   * 3. If the next question is conditional but won't show based on a previous
   *    response, submit the form.
   */
  const goToNextQuestionOrSubmit = async () => {
    if (!validate()) return;

    // Save the form changes.
    await handleSave();

    const question = getCurrentQuestion(submission, currentQuestionIndex);
    const nextQuestion =
      findNextLinkedQuestion(question, submission.formQuestions) ||
      findNextUnlinkedQuestion(question, submission.formQuestions);
    const nextQuestionIndex = submission.formQuestions.indexOf(nextQuestion);

    // Submit the form when reaching the last question.
    if (nextQuestionIndex === -1) return await handleSubmit();

    // Move to the next question if the form is not complete.
    goToQuestion(nextQuestionIndex);
  };

  /**
   * Navigate to the previous question in the form, if any.
   *
   * Some questions are conditional based on a user's response. Navigate to 1
   * of 2 possible destinations based on whether the question has a `label`
   * property included in a previous question's `nextQuestions` property:
   *
   * 1. If it does, navigate directly to that question.
   *
   * 2. If it does not, navigate to the previous question in sequence.
   */
  const goToPreviousQuestion = () => {
    const question = getCurrentQuestion(submission, currentQuestionIndex);
    const nextQuestion = findPreviousLinkedQuestion(
      question,
      submission.formQuestions
    );

    const nextQuestionIndex = nextQuestion
      ? submission.formQuestions.indexOf(nextQuestion)
      : currentQuestionIndex - 1;

    goToQuestion(nextQuestionIndex);
  };

  /** Attempt to submit and save the form (if valid). */
  const handleSave = async () => {
    if (!validate()) return;

    await onSave(submission.formKey, submission.order);
  };

  /**
   * Attempt to submit and save the form (if valid) and navigate the user to an
   * appropriate location based on the presentation mode of the current step.
   *
   * If this is a workflow step, go back to the root path (which will redirect
   * to the current journey phase intro page).
   *
   * Otherwise, go back to the previous URL, which will either be the Dashboard
   * page or the list of forms.
   */
  const handleSaveAndClose = async (event) => {
    event?.preventDefault();

    await handleSave();

    if (presentationMode === 'workflow') return history.push('/');

    history.goBack();
  };

  const handleSubmit = async () => {
    if (isSaving) return;

    setIsSaving(true);

    // Don't attempt to resubmit if the form was already complete.
    if (submission.completedOn) return onComplete();

    if (!validate()) return setIsSaving(false);

    const isSuccess = await onSubmit(submission.formKey, submission.order);

    if (!isSuccess) return setIsSaving(false);

    await onComplete();
  };

  const validate = useCallback(() => {
    const error = validateQuestion(currentQuestion, values, patientName);

    setError(error);

    return error ? false : true;
  }, [currentQuestion, error, values]);

  /** Validate the form when the form values change. */
  useEffect(() => {
    if (!hasChanged) return;

    validate();
  }, [hasChanged, values]);

  return (
    <FormDetails.Wrapper
      stickyNavFooter={
        <FormDetails.StickyNavFooter
          error={error}
          isSaving={isSaving}
          goToNextQuestionOrSubmit={goToNextQuestionOrSubmit}
          goToPreviousQuestionOrBack={
            isFirstQuestion ? history.goBack : goToPreviousQuestion
          }
        />
      }
    >
      <FormDetails.Question>
        <FormDetails.Header
          currentStep={currentStep}
          handleSaveAndClose={handleSaveAndClose}
          submission={submission}
        />
        <FormQuestion
          content={currentQuestion.content}
          disabled={Boolean(submission.completedOn) || currentQuestion.disabled}
          error={error}
          inputType={currentQuestion.inputType}
          labelText={currentQuestion.label}
          name={questionKey}
          onCancel={history.goBack}
          onChange={setHasChanged}
          options={currentQuestion.options}
          required={currentQuestion.required}
          enterKeyHint={isFinalQuestion ? 'done' : 'next'}
          signatureConfirmationText={capitalizeFirstLetters(patientName)}
        />
      </FormDetails.Question>
    </FormDetails.Wrapper>
  );
};

const FormDetailsHeader = ({ currentStep, handleSaveAndClose, submission }) => {
  const totalSteps = getTotalQuestionCount(submission.formQuestions);
  const { stepProgress, presentationMode } = useNextTask();

  const progressValue =
    presentationMode === 'dashboard' ? currentStep / totalSteps : stepProgress;

  return (
    <>
      <SaveLink.Wrapper>
        <SaveLink.Link
          title={'Save and close'}
          onPress={handleSaveAndClose}
          testID={TestID.FormDetails.SaveLink}
        />
      </SaveLink.Wrapper>
      <SaveLink.ProgressIndicator value={progressValue} />
    </>
  );
};

FormDetails.Header = FormDetailsHeader;

const FormDetailsQuestion = ({ children }) => {
  return (
    <ConstrainedWidthView>
      <QuestionWrapper>{children}</QuestionWrapper>
    </ConstrainedWidthView>
  );
};

FormDetails.Question = FormDetailsQuestion;

const FormDetailsStickyNavFooter = ({
  error,
  isSaving,
  goToNextQuestionOrSubmit,
  goToPreviousQuestionOrBack,
}) => {
  return (
    <StickyNavFooter
      navigationConfig={[
        {
          text: 'Previous',
          onPress: goToPreviousQuestionOrBack,
        },
        {
          text: 'Next',
          disabled: isSaving || !!error,
          loading: isSaving,
          onPress: goToNextQuestionOrSubmit,
        },
      ]}
      navigationMode={NavigationMode.SkipAndNext}
      testID={TestID.FormDetails.NavFooter}
    />
  );
};

FormDetails.StickyNavFooter = FormDetailsStickyNavFooter;

const FormDetailsWrapper = ({ children, stickyNavFooter }) => {
  return (
    <>
      <CenteredScrollView testID={TestID.FormDetails.Component}>
        <LeftAlignedView>{children}</LeftAlignedView>
      </CenteredScrollView>

      <FormFadeaway />

      {stickyNavFooter}
    </>
  );
};

FormDetails.Wrapper = FormDetailsWrapper;

export default FormDetails;
