import React from 'react';

import { TestimonialImage } from './styles';
import { testimonials } from './helpers';

export const Testimonial = ({ testimonialKey }) => {
  const testimonial = testimonials[testimonialKey];

  if (!testimonial) {
    return null; // Testimonial not found
  }

  return (
    <>
      <TestimonialImage
        testimonialKey={testimonialKey} // Unique key for the testimonial
        source={testimonial.image}
      />
    </>
  );
};
