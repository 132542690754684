import { useSelector } from 'react-redux';

import { State } from 'types/state';

const useSubmission = () => {
  const { submission } = useSelector((store: State.RootState) => store);

  return submission;
};

export default useSubmission;
