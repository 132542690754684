import axios from 'axios';

import { showGlobalError } from 'app/actions/uiActions';
import { CORE_API_URL } from 'app/util/constants';

import { receiveUserInsurer } from './action-types';

/**
 * Makes a GET request to the Core Service Insurer API
 * with the current user's insurer key.
 */
export default () => {
  return async (dispatch) => {
    try {
      const response = await dispatch(fetchUserInsurer());
      dispatch(receiveUserInsurer(response.data.data));
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
};

const fetchUserInsurer = () => {
  return (dispatch, getState) => {
    const { coreToken, user } = getState().session;
    const { partnerId } = user.eligiblePatient.insurance;

    return axios.get(`${CORE_API_URL}/insurers/${partnerId}`, {
      headers: { Authorization: `Bearer ${coreToken}` },
    });
  };
};
