import React, { CSSProperties, ReactNode } from 'react';
import { useWindowDimensions } from 'react-native';

import theme from 'app/util/theme';

import {
  Container,
  HomeLeft,
  HomeRight,
  MainWrapper,
  SvgWrapper,
  ThemeProvider,
} from './styles';

interface ProcedureSearchResultsWrapperProps {
  children: ReactNode;
  containerStyle?: CSSProperties;
  testID?: string;
}

const ProcedureSearchResultsWrapper = ({
  children,
  containerStyle,
  testID,
}: ProcedureSearchResultsWrapperProps) => {
  const { width } = useWindowDimensions();
  const showBackgroundSvgs = width > theme.breakpoints.medium;

  return (
    <ThemeProvider>
      <Container testID={testID} style={containerStyle}>
        {showBackgroundSvgs && (
          <SvgWrapper side="left">
            <HomeLeft />
          </SvgWrapper>
        )}

        <MainWrapper>{children}</MainWrapper>

        {showBackgroundSvgs && (
          <SvgWrapper side="right">
            <HomeRight />
          </SvgWrapper>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default ProcedureSearchResultsWrapper;
