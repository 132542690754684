import { useSelector } from 'react-redux';

import { getEpisodeJourneyPhase } from 'app/selectors/episode';

import { State } from 'types/state';

interface UseNextPhaseRoute {
  nextRoute: string;
}

const useNextPhaseRoute = (): UseNextPhaseRoute => {
  const state = useSelector((state: State.RootState) => state);

  // fallback route
  let nextRoute: string = '/';

  // return early if the user is still logging in
  if (state.session.loading) return { nextRoute };

  const journeyPhase = getEpisodeJourneyPhase(state.episode);

  if (journeyPhase) {
    nextRoute = `/${journeyPhase.code}`;
  }

  return {
    nextRoute,
  };
};

export default useNextPhaseRoute;
