import { initialFormValues } from 'app/components/Messages/constants';

import {
  createMessage,
  getHolidayMessage,
  logMessageSent,
  playSound,
  scrollToBottom,
} from 'app/components/Messages/helpers';

import {
  useDispatch,
  useSession,
  useState,
} from 'app/components/Messages/hooks';

import { FooterStyles as Styled } from 'app/components/Messages/styles';

import { FooterProps } from 'app/components/Messages/types';

import { ActionButtons, InputField } from './subcomponents';

/**
 * Render the input and action icons for submitting text messages and
 * attachments.
 */
export const Footer = ({
  conversation,
  currentUserId,
  formValues,
  isRendered = false,
  scrollView,
  setFormValues,
}: FooterProps) => {
  const [submitting, setSubmitting] = useState(false);

  const dispatch = useDispatch();
  const { features } = useSession();

  const hasPreEpisodeMessagingFeature =
    features.indexOf('pre-episode-messaging') > -1;

  if (!isRendered && !hasPreEpisodeMessagingFeature) return null;

  const holidayMessage = getHolidayMessage();
  const holidayHoursAreInEffect = Boolean(holidayMessage);

  const actionPressParams = {
    conversation,
    dispatch,
    formValues,
    setFormValues,
  };

  /**
   * Create a new message for a conversation, then play a sound and clear out
   * the input.
   *
   * @return  {promise}  a promise that resolves after creating the message
   */
  const sendMessage = async () => {
    if (!conversation && !hasPreEpisodeMessagingFeature) return;

    const { attachments, body } = formValues;

    if (submitting || (!body.length && !attachments.length)) return;

    await setSubmitting(true);

    await createMessage(dispatch, conversation?.id, {
      attachments,
      body,
      authorId: currentUserId,
    });

    playSound('sent');
    logMessageSent(body, attachments);
    setSubmitting(false);
    scrollToBottom(false, scrollView.current);
    setFormValues(initialFormValues);
  };

  return (
    <Styled.Wrapper>
      {holidayHoursAreInEffect && (
        <Styled.HolidayMessage.Wrapper>
          <Styled.HolidayMessage.Text>
            {holidayMessage}
          </Styled.HolidayMessage.Text>
        </Styled.HolidayMessage.Wrapper>
      )}

      <InputField
        sendMessage={sendMessage}
        submitting={submitting}
        formValues={formValues}
        setFormValues={setFormValues}
      />

      <ActionButtons
        actionPressParams={actionPressParams}
        sendMessage={sendMessage}
        submitting={submitting}
      />
    </Styled.Wrapper>
  );
};
