import {
  animationConfig,
  animationValues,
  breakpoints,
  TestID,
} from 'app/components/App/Navigation/constants';

import { setPanelOpen } from 'app/components/App/Navigation/helpers';

import {
  useDispatch,
  useEffect,
  useState,
  useUi,
  useWindowDimensions,
} from 'app/components/App/Navigation/hooks';

import { SidePanelStyles as Styled } from 'app/components/App/Navigation/styles';

import { Animated, AnimatedOverlay, AnimatedPanel } from './subcomponents';

/**
 * Provide access for users to account-related screens.
 *
 * Responsively render the navigation links based on many factors:
 *
 * 1. The screen size. This determines whether this component should be rendered.
 * 2. The patient's situation and position in their journey of care. This
 *    logic is encapsulated downstream where relevant.
 * 3. The user's authentication status. This logic is not directly relevant here
 *    and has been encapsulated in descendant components "downstream."
 * 4. Whether this component has been toggled open to display.
 */
const SidePanel = () => {
  const fade = useState(new Animated.Value(0))[0];
  const slide = useState(new Animated.Value(280))[0];

  const dispatch = useDispatch();
  const { panelOpen: panelIsOpen } = useUi();
  const hasTopNavLinks = useWindowDimensions().width > breakpoints.small;

  const animatePanel = (animationValues) => {
    Animated.parallel([
      Animated.timing(fade, {
        ...animationConfig,
        toValue: animationValues.fadeTo,
      }),
      Animated.timing(slide, {
        ...animationConfig,
        toValue: animationValues.slideTo,
      }),
    ]).start();
  };

  const closePanel = () => {
    dispatch(setPanelOpen(!panelIsOpen));
  };

  useEffect(
    function handleAnimation() {
      const animationKey = panelIsOpen ? 'opening' : 'closing';
      const animationValue = animationValues[animationKey];
      animatePanel(animationValue);
    },
    [panelIsOpen]
  );

  if (hasTopNavLinks) return null;

  return (
    <Styled.Wrapper isOpen={panelIsOpen} testID={TestID.Navigation.SidePanel}>
      <AnimatedOverlay
        onClose={panelIsOpen ? closePanel : null}
        style={{ opacity: fade }}
      />
      <AnimatedPanel style={{ transform: [{ translateX: slide }] }} />
    </Styled.Wrapper>
  );
};

export default SidePanel;
