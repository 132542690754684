import { TestID } from 'app/components/Register/constants';

import { callCarrum } from 'app/components/Register/helpers';

import { RegisterSuccessStyles as Styled } from 'app/components/Register/styles';

const Success = ({ email }: { email: string }) => {
  return (
    <Styled.Wrapper testID={TestID.Register.Page}>
      <Styled.Icon.Wrapper>
        <Styled.Icon.Image name="mail-outline" />
      </Styled.Icon.Wrapper>

      <Styled.Text>Thank you! An email has been sent to</Styled.Text>

      <Styled.Email>{email}</Styled.Email>

      <Styled.Text testID={TestID.Register.SuccessMessage}>
        Please check your email inbox for a confirmation email to activate your
        account. Activation emails can sometimes take a few minutes to arrive.
        If you didn't get one after 15 minutes, call us at{' '}
        <Styled.Anchor onPress={(e) => callCarrum(e)} title="1-888-855-7806" />{' '}
        for assistance.{' '}
      </Styled.Text>
    </Styled.Wrapper>
  );
};

export default Success;
