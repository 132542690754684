import { fetchDistanceFromAddress, isEmpty, logError } from 'app/util/methods';

import { receiveDistance } from './action-types';
import updateCurrentEpisode from './update-current-episode';

/**
 * Makes a GET request to the Google Maps Distance API to
 * determine the distance from the user to the provider
 * and stores it in the reducer.
 *
 * @return {promise} a promise that resolves when the distance is fetched.
 */
export default () => {
  return async (dispatch, getState) => {
    try {
      const { episode } = getState().episode;

      // Return early if there is no active episode
      if (isEmpty(episode)) return false;

      const { location, provider } = episode;

      // Return early if there is no user or provider location
      if (isEmpty(location)) return false;
      if (isEmpty(provider)) return false;

      const distance = await fetchDistanceFromAddress(
        `${location.lat},${location.lng}`,
        `${provider.latitude},${provider.longitude}`
      );

      // Return early if the Google Maps API fails to return a valid result.
      if (!distance?.text || !distance?.value) return false;

      dispatch(receiveDistance(distance.text));

      // Avoid redundant API calls if the distance hasn't changed.
      if (provider.distance === distance.value) return true;

      dispatch(
        updateCurrentEpisode({
          provider: { ...provider, distance: distance.value },
        })
      );

      return true;
    } catch (error) {
      logError(error);

      return false;
    }
  };
};
