import { fetchSubmissions } from 'app/actions/submissionActions';

import getEpisodeProcedure from './get-episode-procedure';
import getEpisodeSteps from './get-episode-steps';

/**
 * Fetches additional details about the current episode
 * if one is present in the reducer.
 *
 * @return {promise} A promise that resolves after fetching additional details.
 */
export default () => {
  return async (dispatch, getState) => {
    const { episode } = getState();

    if (!episode.episode || !episode.episode.procedureKey) return;

    await Promise.all([
      dispatch(getEpisodeProcedure(episode.episode.procedureKey)),
      dispatch(getEpisodeSteps()),
      dispatch(fetchSubmissions()),
    ]);
  };
};
