import { getMessageTextWrapperStyleParams } from 'app/components/Messages/helpers';

import { MessageBodyStyles as Styled } from 'app/components/Messages/styles';

import { MessageBodyProps } from 'app/components/Messages/types';

export const MessageBody = ({
  count,
  index,
  isRead,
  isVisible,
  patientIsAuthor,
  text,
}: MessageBodyProps) => {
  if (!isVisible) return;

  const textWrapperStyle = getMessageTextWrapperStyleParams(
    index,
    count,
    patientIsAuthor
  );

  return (
    <Styled.Wrapper {...textWrapperStyle} patientIsAuthor={patientIsAuthor}>
      <Styled.Text patientIsAuthor={patientIsAuthor} isRead={isRead}>
        <Styled.Anchor
          patientIsAuthor={patientIsAuthor}
          stripPrefix={false}
          text={text}
          truncate={42}
          truncateLocation="end"
        />
      </Styled.Text>
    </Styled.Wrapper>
  );
};
