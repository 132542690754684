import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Animated, Easing, StyleSheet } from 'react-native';
import { Button, Icon } from 'react-native-elements';
import * as Linking from 'expo-linking';

import { useNativeDriver } from 'app/util/constants';
import theme from 'app/util/theme';

export class SocialLink extends Component {
  static propTypes = {
    handle: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,

    size: PropTypes.number,
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  };

  static defaultProps = {
    size: 54,
    style: {},
  };

  state = {
    highlight: new Animated.Value(0),
    opacity: new Animated.Value(0),
    scale: new Animated.Value(1),
  };

  animate(delay) {
    const config = {
      duration: 200,
      easing: Easing.out(Easing.ease),
      useNativeDriver,
    };

    Animated.parallel([
      Animated.timing(this.state.opacity, {
        ...config,
        delay,
        duration: 400,
        toValue: 1,
      }),
      Animated.sequence([
        Animated.timing(this.state.scale, { ...config, delay, toValue: 1.2 }),
        Animated.timing(this.state.scale, { ...config, toValue: 1 }),
      ]),
      Animated.sequence([
        Animated.timing(this.state.highlight, { ...config, toValue: 1 }),
        Animated.timing(this.state.highlight, { ...config, toValue: 0 }),
      ]),
    ]).start();
  }

  render() {
    return (
      <Animated.View
        style={{
          opacity: this.state.opacity,
          transform: [{ scale: this.state.scale }],
        }}
      >
        <Button
          buttonStyle={StyleSheet.flatten([
            {
              backgroundColor: 'white',
              padding: theme.spacing / 2,
              borderRadius: this.props.size,
              width: this.props.size,
              height: this.props.size,
            },
            this.props.style,
          ])}
          icon={
            <Icon
              name={this.props.type}
              size={this.props.size / 2}
              type="material-community"
            />
          }
          onPress={() =>
            Linking.openURL(
              `https://www.${this.props.type}.com/${this.props.handle}`
            )
          }
        />
      </Animated.View>
    );
  }
}

export const Facebook = React.forwardRef((props, ref) => (
  <SocialLink type="facebook" handle="carrumhealth" ref={ref} {...props} />
));

export const Twitter = React.forwardRef((props, ref) => (
  <SocialLink type="twitter" handle="CarrumHealth" ref={ref} {...props} />
));

export const LinkedIn = React.forwardRef((props, ref) => (
  <SocialLink
    type="linkedin"
    handle="company/carrum-health"
    ref={ref}
    {...props}
  />
));
