import { useCallback, useEffect, useState } from 'react';
import { AppState, AppStateStatus } from 'react-native';

interface AppStateHook {
  appState: AppStateStatus;
}

/**
 * Allows access to changes in the app state when the mobile app is moved into
 * the foreground or background, or when the web app changes tab focus.
 */
const useAppState = (): AppStateHook => {
  const [appState, setAppState] = useState(AppState.currentState);

  useEffect(() => {
    const subscription = AppState.addEventListener('change', onAppStateChange);

    return () => {
      subscription.remove();
    };
  }, []);

  const onAppStateChange = useCallback(
    (appStateValue: AppStateStatus) => {
      setAppState(appStateValue);
    },
    [setAppState]
  );

  return { appState };
};

export default useAppState;
