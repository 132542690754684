import axios from 'axios';

import { showGlobalError } from 'app/actions/uiActions';
import { CORE_API_URL } from 'app/util/constants';

import { receiveUserEmployer } from './action-types';

/**
 * Fetch data for the current user's employer from the Core Service API.
 *
 * @param   {string}   clientId  identifier for user's employer
 *
 * @return  {promise}            promise that resolves when the data is fetched
 */
export default (clientId) => {
  return async (dispatch) => {
    try {
      const response = await dispatch(fetchUserEmployer(clientId));
      dispatch(receiveUserEmployer(response.data.data));
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
};

/**
 * Fetch data for the current user's employer from the Core Service API.
 *
 * @param   {string}   clientId  identifier for user's employer
 */
const fetchUserEmployer = (clientId) => {
  return (_, getState) => {
    const { coreToken, user } = getState().session;

    const headers = {};

    if (!clientId) clientId = user.eligiblePatient.clientId;
    if (coreToken) headers.Authorization = `Bearer ${coreToken}`;

    return axios.get(`${CORE_API_URL}/clients/${clientId}`, {
      headers,
    });
  };
};
