import styled, { css } from 'styled-components/native';

import { Text as TextRaw, View } from 'app/components/Common/Styled';

import { DoctorCard as DoctorCardRaw } from 'app/components/SelectYourDoctor/Cards';

export const CardRow = styled(View)`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const DoctorCard = styled(DoctorCardRaw)``;

export const DoctorsSection = styled(View)`
  ${({ theme }) => css`
    background-color: ${theme.colors.sand};
    flex: ${theme.windowDimensions.width < theme.breakpoints.medium
      ? '1 0 auto'
      : '1 1 0'};
    padding: ${theme.spacing}px;
  `}
`;

export const DoctorsSectionHeader = styled(TextRaw)`
  ${({ theme }) => css`
    font-family: ${theme.fontFamilyBold};
    font-size: ${theme.fontSizes.h1}px;
    margin: ${({ theme }) => `${theme.spacing * 2}px ${theme.spacing / 2}px`};
  `}
`;

export const FacilityDetailsSection = styled(View)`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    flex: ${theme.windowDimensions.width < theme.breakpoints.medium
      ? '1 0 auto'
      : '1 1 0'};
    padding: ${theme.spacing}px;
  `}
`;
