import {
  SelectYourDoctorRoutes,
  TestID,
  theme,
} from 'app/components/App/Navigation/constants';

import {
  getIsLoggedIn,
  receiveSetCallUsModalVisibility,
} from 'app/components/App/Navigation/helpers';

import {
  useDispatch,
  useHistory,
  useMemo,
  useSelector,
  useWindowDimensions,
} from 'app/components/App/Navigation/hooks';

import { NeedHelpCtaStyles as Styled } from 'app/components/App/Navigation/styles';

import { State } from 'app/components/App/Navigation/types';

export const NeedHelpCta = () => {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state: State.RootState) =>
    getIsLoggedIn(state.session)
  );

  const {
    location: { pathname },
  } = useHistory();

  const isDoctorSelectionPage = pathname.includes(SelectYourDoctorRoutes.Root);
  const isNarrow = useWindowDimensions().width < theme.breakpoints.xsmall;
  const isProcedureSearchPage = pathname === '/';

  const buttonIsVisible = useMemo(
    () =>
      isLoggedIn &&
      (isDoctorSelectionPage || isProcedureSearchPage || isNarrow),
    [isLoggedIn, isDoctorSelectionPage, isProcedureSearchPage, isNarrow]
  );

  return (
    <>
      {buttonIsVisible && (
        <Styled.Button
          onPress={() => dispatch(receiveSetCallUsModalVisibility(true))}
          testID={TestID.Navigation.NeedHelpCta}
        />
      )}
    </>
  );
};
