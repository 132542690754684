import { useEffect, useState } from 'react';
import { uniq } from 'lodash';

//@ts-ignore
import { useHistory } from '@cross-platform/react-router-native';
import { View } from 'app/components/Common/Styled';
import { TestID } from 'app/util/test-id';

import { ButtonWrapper } from './styles';

export interface ButtonListOption {
  label: string;
  value: any;
}

export type ButtonListMode = 'single' | 'multi';

const getActiveButtonsFromValue = (value) => {
  if (Array.isArray(value)) return value;
  if (value) return [value];

  return [];
};

interface ButtonListProps {
  disabled?: boolean;
  onChange: (value) => void;
  options: ButtonListOption[];
  mode: ButtonListMode;
  value?: any;
}

export const ButtonList = ({
  disabled = false,
  onChange,
  options,
  mode = 'single',
  value: activeButton,
}: ButtonListProps) => {
  const history = useHistory();
  const activeButtons = getActiveButtonsFromValue(activeButton);

  const handleMultiChange = (value: string) => {
    if (activeButtons.includes(value)) {
      // remove button from active list
      onChange(activeButtons.filter((buttonValue) => buttonValue !== value));
    } else {
      // add button to active list
      onChange(uniq([...activeButtons, value]));
    }
  };

  const handleSingleChange = (value: string) => {
    onChange(value);
  };

  const handleButtonChange = (value: string) => {
    if (disabled) return;

    if (mode === 'multi') {
      // multi mode
      handleMultiChange(value);
    } else {
      // single mode
      handleSingleChange(value);
    }
  };

  return (
    <View testID={TestID.ButtonList.Component}>
      {options.map(({ label, value }, index) => {
        const isFinalOption = index === options.length - 1;

        let isActive = false;

        if (mode === 'multi') {
          // multi mode
          isActive = activeButtons.includes(value);
        } else {
          // single mode
          isActive = activeButton === value;
        }

        return (
          <ButtonWrapper
            disabled={disabled}
            isActive={isActive}
            isFinalOption={isFinalOption}
            key={label}
            onPress={() => handleButtonChange(value)}
            pageLocation={history.location.pathname}
            testID={label}
            title={label}
          />
        );
      })}
    </View>
  );
};

export default ButtonList;
