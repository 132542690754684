import React from 'react';

import { NumberBadge, NextStepInfoWrapper, NumberStepText } from './styles';
import { NextStepInfoProps } from '../types';

export const NextStepInfo = ({
  icon: Icon,
  number,
  text,
}: NextStepInfoProps) => {
  return (
    <NextStepInfoWrapper>
      <NumberBadge number={number} />
      <Icon />
      <NumberStepText>{text}</NumberStepText>
    </NextStepInfoWrapper>
  );
};
