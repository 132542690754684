import { PressableProps, ViewProps } from 'react-native';

import { Button, TextProps } from 'react-native-elements';
import styled, { css } from 'styled-components/native';

import { GoBackLink as GoBackLinkRaw } from 'app/components/Common/GoBackLink';
import StickyNavFooterRaw from 'app/components/Common/StickyNavFooter';
import {
  ScrollView as ScrollViewRaw,
  Text,
  View,
} from 'app/components/Common/Styled';
import { DotIndicator as DotIndicatorRaw } from 'app/components/Common/svg-components';
import { ResponsivePressable } from 'app/components/Common/ResponsivePressable';
import { TopBackLink as TopBackLinkRaw } from 'app/components/Common/TopBackLink';
import { LetUsKnow as LetUsKnowRaw } from 'app/components/LetUsKnow';

import { default as ProcedureSearchResultsWrapperRaw } from './Wrapper';

interface SearchResultsContainerProps extends ViewProps {}

interface SearchResultItemTextProps extends TextProps {
  isSelected: boolean;
}

interface SearchResultItemViewProps extends PressableProps {
  isSelected: boolean;
}

interface SearchResultsWrapperProps extends ViewProps {}

interface SelectOptionsTextProps extends TextProps {}

export const CenteredText = styled(Text)`
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing}px;
`;

export const ChangeItLaterText = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.small}px;
`;

export const ContinueButton = styled(Button).attrs(({ theme }) => ({
  disabledStyle: {
    backgroundColor: theme.colors.disabledNavBackground,
    borderRadius: 0,
  },
  disabledTitleStyle: {
    color: theme.colors.white,
  },
  title: 'Continue',
  titleStyle: {
    color: theme.colors.white,
    fontSize: theme.fontSizes.body2,
  },
}))`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    min-width: ${theme.spacing * 10}px;
    margin-top: ${theme.spacing * 6}px;
  `}
`;

export const DotIndicator = styled(DotIndicatorRaw)`
  margin-top: ${({ theme }) => theme.spacing * 2}px;
`;

export const GoBackLink = GoBackLinkRaw;

export const LetUsKnow = LetUsKnowRaw;

export const LetUsKnowButton = styled(Button).attrs(({ theme }) => {
  return {
    buttonStyle: {
      padding: 0,
    },
    containerStyle: {
      marginBottom: theme.spacing,
    },
    titleStyle: {
      fontSize: theme.fontSizes.small,
    },
    type: 'clear',
  };
})``;

export const NotWhatYoureLookingForMobileText = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.small}px;
  text-align: center;
`;

export const ProcedureSearchResultsWrapper = styled(
  ProcedureSearchResultsWrapperRaw
).attrs(({ theme }) => {
  return {
    containerStyle: {
      padding: theme.spacing,
    },
  };
})``;

export const ScrollView = styled(ScrollViewRaw)`
  flex: 1 0 auto;
`;

export const SearchText = styled(Text).attrs(({ theme }) => {
  return {
    h1: true,
    h1Style: {
      textAlign: 'center',
      color: theme.colors.bodyText,
    },
  };
})`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing / 4}px;
    margin-top: ${theme.spacing}px;
  `}
`;

export const SearchResultsContainer = styled(View)<SearchResultsContainerProps>`
  ${({ theme }) => css`
    align-items: center;
    background: ${theme.colors.sand};
    flex: 1 0 auto;
    flex-direction: column;
    padding-top: ${theme.isNarrow ? theme.spacing : theme.spacing * 3}px;
  `}
`;

export const SearchResultItemText = styled(Text)<SearchResultItemTextProps>`
  ${({ theme, isSelected }) => css`
    color: ${isSelected ? theme.colors.white : theme.colors.primary};
    font-size: ${theme.fontSizes.body2}px;
    font-family: ${theme.fontFamilyMedium};
    text-align: center;
    width: 100%;
  `}
`;

interface ResultTextProps extends TextProps {
  isSelected: boolean;
}

export const SearchResultItemSubText = styled(Text)<ResultTextProps>`
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.white : theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.small}px;
  text-align: center;
`;

export const SearchResultItemView = styled(
  ResponsivePressable
)<SearchResultItemViewProps>`
  ${({ theme, isSelected }) => css`
    background: ${isSelected ? theme.colors.primary : theme.colors.white};
    border-radius: ${theme.spacing / 4}px;
    margin-bottom: ${theme.spacing}px;
    padding: ${theme.spacing / 2}px;
    flex-shrink: 1;
    max-width: 100%;
    width: 100%;
  `}
`;

export const SearchResultsWrapper = styled(View)<SearchResultsWrapperProps>`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing * 2.5}px;
    margin-top: ${theme.isNarrow ? theme.spacing * 1.5 : 0}px;
    width: 100%;
  `}
`;

export const SelectOptionsText = styled(Text)<SelectOptionsTextProps>`
  ${({ theme }) => css`
    font-family: ${theme.fontFamilyMedium};
    font-size: ${theme.isNarrow
      ? theme.fontSizes.body3
      : theme.fontSizes.body2}px;
    margin-bottom: ${theme.isNarrow ? 0 : theme.spacing * 3}px;
    margin-top: ${theme.isNarrow ? theme.spacing : theme.spacing * 3}px;
    text-align: center;
  `}
`;

export const StickyNavFooter = StickyNavFooterRaw;

export const TopBackLink = TopBackLinkRaw;
