import { View } from 'react-native';
import { Icon } from 'react-native-elements';
import styled from 'styled-components/native';

import Text from 'app/components/Common/Styled/Text';
import theme from 'app/util/theme';

export const Amount = styled(Text)`
  text-align: center;
`;

export const Breakdown = styled.ScrollView.attrs({
  contentContainerStyles: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  horizontal: true,
})``;

export const BreakdownCell = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70px;
`;

export const ContainerHeader = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
  margin: ${({ theme }) => theme.spacing}px auto;
`;

export const ItemRowLabel = styled.View`
  border-right-color: ${theme.colors.defaultDark};
  border-right-width: 1px;
  margin-right: ${({ theme }) => theme.spacing / 2}px;
  padding-right: ${({ theme }) => theme.spacing / 2}px;
  width: 20%;
`;

export const ItemRow = styled.View`
  border: 1px solid ${theme.colors.defaultDark};
  border-radius: 5px;
  padding: ${({ theme }) => theme.spacing / 2}px;
  margin-top: ${({ theme }) => theme.spacing / 2}px;
  flex-direction: row;
`;

export const StipendContainer = styled.View`
  margin: 0 auto;
  max-width: ${({ theme }) => theme.breakpoints.small}px;
  width: 100%;
`;

export const TypesHeader = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacing}px;
`;

export const TypesContainer = styled.View`
  border: 1px solid ${theme.colors.primary};
  border-radius: 5px;
  padding: ${({ theme }) => theme.spacing / 2}px;
`;

export const TypeIcon = styled(Icon)`
  /* align-self: center;
  justify-self: center; */
  margin-right: ${({ theme }) => theme.spacing / 2}px;
  min-width: 30px;
`;

export const TypeRow = styled.View`
  align-items: center;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.spacing / 4}px;
`;

export const GrandTotalText = styled(Text)`
  text-align: center;
`;

export const GrandTotalContainer = styled(ItemRow)`
  flex-direction: column;
  justify-content: center;
`;

export const DisbursementWrapper = styled(ItemRow)`
  flex-direction: column;
`;

export const DisbursementDetail = styled(View)`
  margin-bottom: ${({ theme }) => theme.spacing / 2}px;
`;

export const DisbursementDetailLabel = styled(Text).attrs({
  size: 'small',
})``;

export const DisbursementDetailValue = styled(Text).attrs({
  bold: true,
  size: 'small',
})``;
