import React from 'react';
import { useDispatch } from 'react-redux';

import { useUi } from 'app/hooks';

import { Button } from './styles';
import ContactMeModal from '../ContactMeModal';
import { receiveSetContactMeModalVisibility } from 'app/actions/uiActions';

const ContactMeButton = () => {
  const dispatch = useDispatch();
  const { isContactMeModalVisible } = useUi();

  return (
    <>
      <Button
        onPress={() => dispatch(receiveSetContactMeModalVisibility(true))}
      />
      <ContactMeModal
        onClose={() => dispatch(receiveSetContactMeModalVisibility(false))}
        visible={isContactMeModalVisible}
      />
    </>
  );
};

export default ContactMeButton;
