import React from 'react';

import { isEmpty } from 'lodash';

import Anchor from 'app/components/Common/Anchor';
import { useEpisode, useSession } from 'app/hooks';
import { getFaqUrl } from 'app/util/methods';

import BenefitModalBase from './BenefitModalBase';
import {
  BodyText,
  Graph,
  GraphBar,
  GraphBarText,
  GraphBarWrapper,
  GraphHelperText,
  GraphLabelRow,
  GraphLabelText,
  GraphWrapper,
  SuccessText,
} from './styles';

const QualityModal = ({ isVisible, onClose }) => {
  const { procedure } = useEpisode();
  const { user } = useSession();
  const qualityMetrics = procedure?.qualityMetrics;

  const hasLoadedQualityMetrics = qualityMetrics && !isEmpty(qualityMetrics);

  if (!hasLoadedQualityMetrics) {
    return (
      <BenefitModalBase
        isVisible={isVisible}
        onClose={onClose}
        title="Quality Advantages"
      >
        <BodyText>
          Sorry, but this procedure doesn't appear to have quality metrics data.
        </BodyText>
      </BenefitModalBase>
    );
  }

  /**
   * @example
   * episode.procedure.qualityMetrics = [
   *  {
   *    carrum: '1.7%',
   *    national: '8.2%',
   *    title: 'Readmission Rates',
   *  },
   *  {
   *    carrum: '0.7%',
   *    national: '1.2%',
   *    title: 'Mortality Rates',
   *  },
   * ...
   * ]
   */
  const [{ carrum: carrumPercent, national: nationalPercent, title }] =
    qualityMetrics;

  return (
    <BenefitModalBase
      disclaimer={
        <Anchor
          title="Learn about our methodology"
          to={getFaqUrl(user.employer)}
        />
      }
      isVisible={isVisible}
      onClose={onClose}
      title="Quality Advantages"
    >
      <BodyText mb={1.25}>
        Carrum providers deliver higher quality outcomes when compared to others
        performing this procedure.
      </BodyText>
      <SuccessText>{title}</SuccessText>

      <GraphWrapper>
        <Graph>
          <GraphBarWrapper>
            <GraphBarText>{nationalPercent}</GraphBarText>
            <GraphBar
              carrumPercent={carrumPercent}
              nationalPercent={nationalPercent}
            />
          </GraphBarWrapper>
          <GraphBarWrapper>
            <GraphBarText isCarrum>{carrumPercent}</GraphBarText>
            <GraphBar
              isCarrum
              carrumPercent={carrumPercent}
              nationalPercent={nationalPercent}
            />
          </GraphBarWrapper>
        </Graph>
        <GraphLabelRow>
          <GraphLabelText>National average</GraphLabelText>
          <GraphLabelText isCarrum>Carrum COE</GraphLabelText>
        </GraphLabelRow>
        <GraphHelperText>*COE: Centers of Excellence</GraphHelperText>
      </GraphWrapper>
    </BenefitModalBase>
  );
};

export default QualityModal;
