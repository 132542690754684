import theme from 'app/util/theme';
import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

interface Props {
  height?: number;
  width?: number;
}

const BenefitCircleCare = ({ height = 47, width = 47 }: Props) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 47 47" fill="none">
      <Circle cx="23.5" cy="23.5" r="23.5" fill={theme.colors.bodyText} />
      <Path
        d="M30.6418 21.75V13C30.6418 11.0859 29.0559 9.5 27.1418 9.5H11.3918C9.42304 9.5 7.89179 11.0859 7.89179 13V21.75C7.89179 23.7188 9.42304 25.25 11.3918 25.25V28.2578C11.3918 28.6953 11.884 28.9141 12.2121 28.6406L16.7512 25.25H27.1418C29.0559 25.25 30.6418 23.7188 30.6418 21.75ZM35.8918 18.25H32.3918V21.75C32.3918 24.6484 29.9855 27 27.1418 27H18.3918V30.5C18.3918 32.4688 19.923 34 21.8918 34H28.7277L33.2668 37.4453C33.5949 37.6641 34.1418 37.4453 34.1418 37.0078V34H35.8918C37.8059 34 39.3918 32.4688 39.3918 30.5V21.75C39.3918 19.8359 37.8059 18.25 35.8918 18.25Z"
        fill="white"
      />
      <Circle cx="14" cy="17.5" r="1.5" fill={theme.colors.bodyText} />
      <Circle cx="19" cy="17.5" r="1.5" fill={theme.colors.bodyText} />
      <Circle cx="24" cy="17.5" r="1.5" fill={theme.colors.bodyText} />
    </Svg>
  );
};

export default BenefitCircleCare;
