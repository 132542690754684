import { Text } from 'react-native-elements';
import styled, { css } from 'styled-components/native';

import { View } from 'app/components/Common/Styled';

const borderColor = 'rgba(218, 220, 224, 0.5)';

export const ExperienceWrapper = styled(View)`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
  `}
`;

// ROWS

const RowBase = styled(View)`
  border-bottom-width: 1px;
  border-bottom-color: ${borderColor};
  flex: 1 0 auto;
  flex-direction: row;
`;

export const QualityRow = styled(RowBase)``;
export const CareRow = styled(RowBase)``;
export const CostRow = styled(RowBase)``;

// CELLS

const LeftCellWrapper = styled(View)`
  ${({ theme }) => css`
    border-right-color: ${borderColor};
    border-right-width: 1px;
    justify-content: center;
    padding: ${theme.spacing / 2}px;
    width: 95px; /* MAGIC: Minimum size that fits all text with line-breaks  */
  `}
`;

const LeftCellText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.bodyText};
    font-family: ${theme.fontFamilyMedium};
    font-size: ${theme.fontSizes.small}px;
    text-align: right;
  `}
`;

export const LeftCell = ({ children }) => (
  <LeftCellWrapper>
    <LeftCellText>{children}</LeftCellText>
  </LeftCellWrapper>
);
