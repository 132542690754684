import { MINIMUM_PASSWORD_LENGTH } from 'app/util/constants';

import { AccountSettingsFormState } from './types';

export { breakpoints } from 'app/util/breakpoints';

import {
  isConfirmed,
  isFormat,
  isMinLength,
  isPresent,
} from 'app/util/validations';

export const defaultFormState: AccountSettingsFormState = {
  email: undefined,
  password: undefined,
  passwordConfirmation: undefined,
};

export const errorMessages = {
  email: {
    invalid: 'Please provide a well-formatted Email Address',
    missing: 'Please enter your Email Address',
  },
  password: {
    tooShort: `Please include at least ${MINIMUM_PASSWORD_LENGTH} characters`,
    missing: 'Please provide a Password',
    missingUppercase: 'Please include at least 1 uppercase',
    missingLowercase: 'Please include at least 1 lowercase',
    missingNumber: 'Please include at least 1 number',
  },
  passwordConfirmation: {
    mismatch: "Passwords don't match",
  },
};

export const validations = {
  email: [
    isPresent(errorMessages.email.missing),
    isFormat(/.+@.+/, errorMessages.email.invalid),
  ],
  password: [
    isPresent(errorMessages.password.missing),
    isMinLength(MINIMUM_PASSWORD_LENGTH, errorMessages.password.tooShort),
    isFormat(/[A-Z]/, errorMessages.password.missingUppercase),
    isFormat(/[a-z]/, errorMessages.password.missingLowercase),
    isFormat(/\d/, errorMessages.password.missingNumber),
  ],
  passwordConfirmation: [isConfirmed('password')],
};
