import { View } from 'react-native';
import { CheckBox as CheckBoxRaw } from 'react-native-elements';
import styled from 'styled-components/native';

export { Text } from 'react-native-elements';

export { default as FormError } from 'app/components/Common/FormError';

export const CheckBox = styled(CheckBoxRaw).attrs(({ disabled, theme }) => {
  return {
    size: 32,
    checkedColor: disabled ? theme.colors.default : theme.colors.primary,
    containerStyle: {
      borderWidth: 0,
    },
  };
})``;

export const FlexView = styled(View).attrs({
  flex: 1,
})`
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`;
