import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import defaultHistory from 'app/util/history';
import { State } from 'types/state';

import escalation from './escalationReducer';
import episode from './episodeReducer';
import message from './messageReducer';
import session from './sessionReducer';
import submission from './submissionReducer';
import ui from './uiReducer';

const rootReducer = combineReducers<State.RootState>({
  episode,
  form,
  message,
  session,
  submission,
  ui,
  escalation,
});

export default rootReducer;
