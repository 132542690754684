import React from 'react';

import { useHistory } from '@cross-platform/react-router-native';

import { TopBackLinkButton } from './styles';

interface TopBackLinkProps {
  onPress?: () => void;
  testID?: string;
  title?: string;
}

/**
 * Link to return to previous page in history.
 *
 * @param   onPress  Event handler to override the default behavior (i.e., go back one step in history)
 * @param   testID   Unique identifier within component to support automated testing
 * @param   title    Text to override the default button text ("Back")
 */
export const TopBackLink = ({
  onPress,
  testID,
  title = 'Back',
}: TopBackLinkProps) => {
  const history = useHistory();

  if (!onPress) onPress = history.goBack;

  return <TopBackLinkButton onPress={onPress} testID={testID} title={title} />;
};
