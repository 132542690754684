import { ProcedureDescriptionWrapperProps } from 'app/components/ProcedureDescription/types';

import { ProcedureDescriptionStyles as Styled } from 'app/components/ProcedureDescription/styles';

const ProcedureDescriptionWrapper = ({
  children,
  footer,
  testID,
}: ProcedureDescriptionWrapperProps) => {
  return (
    <>
      <Styled.Wrapper testID={testID}>{children}</Styled.Wrapper>

      {footer}
    </>
  );
};

export default ProcedureDescriptionWrapper; // NOTE: potential common component
