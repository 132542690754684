import { TestID } from 'app/components/Login/constants';
import { isGuestAccount, requestMagicLink } from 'app/components/Login/helpers';
import { useDispatch, useEffect, useState } from 'app/components/Login/hooks';
import { MagicLinkRequestStyles as Styled } from 'app/components/Login/styles';
import {
  MagicLinkRequestProps,
  MagicLinkRequestMessageProps,
  MagicLinkRequestWrapperProps,
  MagicLinkRequestSubtitleProps,
  MagicLinkRequestButtonProps,
} from 'app/components/Login/types';

const MagicLinkRequest = ({
  formValues = {},
  isResendRequest = false,
  loading = false,
  setLoginStep,
}: MagicLinkRequestProps) => {
  const dispatch = useDispatch();
  const [passwordHasBeenSet, setPasswordHasBeenSet] = useState(false);

  const email = formValues?.email;

  /**
   * Check secure storage to see if this user has a password when the component
   * mounts.
   */
  useEffect(() => {
    loadInitialState();
  }, []);

  useEffect(() => {
    if (!email && !isResendRequest) setLoginStep('email');
  }, []);

  const loadInitialState = async () => {
    const isGuest = await isGuestAccount(formValues.email);
    setPasswordHasBeenSet(!isGuest);
  };

  const sendMagicLink = async () => {
    if (!email) return;

    const success = dispatch(requestMagicLink({ userEmail: email }));

    if (success) setLoginStep('magic-link-sent'); // proceed to confirmation screen
  };

  return (
    <MagicLinkRequest.Wrapper>
      <MagicLinkRequest.Image />

      <MagicLinkRequest.Message
        email={email}
        passwordHasBeenSet={passwordHasBeenSet}
      />

      <MagicLinkRequest.Button
        formValues={formValues}
        loading={loading}
        sendMagicLink={sendMagicLink}
      />
    </MagicLinkRequest.Wrapper>
  );
};

const Button = ({
  formValues,
  loading,
  sendMagicLink,
}: MagicLinkRequestButtonProps) => {
  return (
    <Styled.ButtonWrapper>
      <Styled.Button
        disabled={!formValues?.email}
        loading={loading}
        onPress={sendMagicLink}
        testID={TestID.Login.SendMagicLink}
        title="Send Instant Login Link"
      />
    </Styled.ButtonWrapper>
  );
};

const Image = () => {
  return (
    <Styled.ImageWrapper>
      <Styled.Image />
    </Styled.ImageWrapper>
  );
};

const Message = ({ email }: MagicLinkRequestMessageProps) => {
  return (
    <Styled.Text>
      Use the button below to get a secure link sent to {email}.
    </Styled.Text>
  );
};

const Subtitle = ({ children }: MagicLinkRequestSubtitleProps) => (
  <Styled.Subtitle>{children}</Styled.Subtitle>
);

const Wrapper = ({ children }: MagicLinkRequestWrapperProps) => (
  <Styled.Wrapper>{children}</Styled.Wrapper>
);

MagicLinkRequest.Button = Button;
MagicLinkRequest.Image = Image;
MagicLinkRequest.Message = Message;
MagicLinkRequest.Subtitle = Subtitle;
MagicLinkRequest.Wrapper = Wrapper;

export default MagicLinkRequest;
