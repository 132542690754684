import { NavigationMode, PersonalInformationFormState } from './types';

import { isPhoneNumberFormat, isPresent } from 'app/util/validations';

import theme from 'app/util/theme';

export const defaultPersonalInformation: PersonalInformationFormState = {
  interpreterRequired: false,
  language: 'english',
  phoneNumber: '',
  preferredName: '',
  voicemailsAllowed: false,
};

export const defaultIcon = {
  color: theme.colors.default,
  name: 'user',
  type: 'font-awesome',
};

export const languageOptions = [
  { label: 'English', value: 'english' },
  { label: 'Spanish', value: 'spanish' },
  { label: 'Not Listed', value: 'not_listed' },
  { label: 'Decline to Answer', value: 'decline' },
];

export const LINKS = [
  {
    path: '/my-settings/personal-information',
    title: 'Personal Information',
    subtitle: 'Edit your personal information.',
  },
  {
    path: '/my-settings/account-settings',
    title: 'Account Settings',
    subtitle: 'Change your email and password.',
  },
];

export { NavigationMode };

export const personalInformationValidations = {
  language: [isPresent()],
  phoneNumber: [isPresent(), isPhoneNumberFormat()],
};

export { Routes } from 'app/util/routes';

export { TestID } from 'app/util/test-id';

export { theme };
