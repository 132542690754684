import theme from 'app/util/theme';

const navigationWidths = {
  button: 20,
  panel: 400,
};

export const animationConfig = {
  duration: 300,
  useNativeDriver: false, // native driver doesn't support animating width or left position
};

export const colors = {
  icon: {
    open: theme.colors.white,
    closed: theme.colors.darkBlue,
  },
  toggleButton: {
    open: theme.colors.darkBlue,
    closed: 'transparent',
  },
};

export const borderRadiuses = {
  rounded: 15,
  squared: 0,
};

export const navigationButton = {
  color: theme.colors.darkBlue,
  height: 120,
  positions: {
    open: 0,
    closed: navigationWidths.button,
  },
  width: navigationWidths.button,
};

export const navigationPanel = {
  positions: {
    open: 0,
    closed: -navigationWidths.panel,
  },
  width: navigationWidths.panel,
};

export { Routes } from 'app/util/routes';

export { routeToFAQ } from 'app/util/routeUtils';

export { theme };
