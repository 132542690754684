import { Platform, ViewProps } from 'react-native';
import styled, { css } from 'styled-components/native';

import FloatingLabelRaw from 'app/components/Common/FloatingLabel';
import { View as ViewRaw } from 'app/components/Common/Styled';
import defaultTheme from 'app/util/theme';

type FixedHeightViewProps = {
  height: string;
};

type InputWrapperProps = ViewProps & {
  disabled?: boolean;
};

export const FixedHeightView = styled(ViewRaw)<FixedHeightViewProps>`
  height: ${({ height }) => height};
`;

export const FloatingLabel = styled(FloatingLabelRaw)`
  margin-left: ${({ theme }) => theme.spacing / 2}px;
`;

// TODO: This style attempts to replicate `theme.Input.containerStyle`
//       using styled-components.  This is redundant, and should be
//       somehow resolved at a more global location.
export const InputWrapper = styled(ViewRaw)<InputWrapperProps>`
  ${({ disabled, theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: ${theme.spacing / 4}px;
    border-width: 0px;
    padding-bottom: ${theme.spacing / 2}px;
    padding-top: ${theme.spacing}px;
    margin-vertical: ${theme.spacing}px;
    ${disabled && 'pointer-events: none;'}
  `}
`;

/**
 * The `GooglePlacesAutocomplete` component expects styles to be defined in a nested object
 * as the `styles` property, which is overridden by styled-components.  In order to make the
 * styles reusable, this component is exported separately from the styles, which are defined
 * below.
 */
export { GooglePlacesAutocomplete } from '@cross-platform/react-native-google-places-autocomplete';

const getPadding = ({ spacing }) =>
  Platform.select({ default: spacing, web: spacing / 8 });
const getHeight = ({ spacing }) => spacing * 2;

const inputStyle = (theme) => ({
  ...theme.Input.inputStyle,
  borderWidth: 0,
  fontFamily: theme.fontFamily,
  height: getHeight(theme),
  paddingLeft: getPadding(theme),
  paddingRight: getPadding(theme),
});

export const googlePlacesStyles = (disabled) => ({
  textInput: {
    ...defaultTheme.Input.inputStyle,
    ...inputStyle(defaultTheme),
    opacity: disabled ? 0.3 : 1,
  },
  textInputContainer: {
    ...defaultTheme.Input.inputContainerStyle,
    borderTopWidth: 0,
    backgroundColor: 'transparent',
  },
  description: {
    ...defaultTheme.Input.inputStyle,
    fontSize: defaultTheme.fontSizes.small,
    fontWeight: '100',
    height: 32,
  },
});

export const googlePlacesSelectStyles = (disabled) => ({
  container: (provided) => ({
    ...provided,
  }),
  input: (provided) => ({
    ...provided,
    ...inputStyle(defaultTheme),
    borderWidth: 1,
    borderColor: 'red',
    opacity: disabled ? 0.3 : 1,
  }),
  control: (provided) => ({
    ...provided,
    borderWidth: 0,
    boxShadow: 'none',
    fontFamily: defaultTheme.fontFamily,
  }),
  menu: (provided) => ({
    ...provided,
    fontFamily: defaultTheme.fontFamily,
  }),
});
