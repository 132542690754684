import React, { useEffect, useState } from 'react';

import { GoogleMap, Marker as MarkerRaw } from '@react-google-maps/api';
import { View } from 'app/components/Common/Styled';

/**
 * This file attempts to establish parity between the web and mobile
 * libraries for Google Maps.
 *
 * The main goals of this file:
 *
 * 1) Export a default `<MapView />` component that works in web and mobile
 * 2) Expose a `<Marker />` component that works in web and mobile
 */

const DEFAULT_OPTIONS = {
  fullscreenControl: false,
  scrollwheel: false,
  streetViewControl: false,
  zoomControl: false,
};

/**
 * Because the <GoogleMap /> component is not compatible with styled-components, force the map
 * to fill the available space and wrap with a `<View />` component that can be styled properly.
 */
const mapContainerStyle = {
  height: '100%',
  width: '100%',
};

const MapView = React.forwardRef(({ style, ...props }, ref) => {
  const [mapView, setMapView] = useState(null);

  /**
   * Expose the current instance of the Google Map as the ref for this component.
   */
  useEffect(() => {
    if (!ref) return;

    ref.current = mapView;
  }, [mapView]);

  return (
    <View style={style}>
      <GoogleMap
        center={props.region || props.initialRegion}
        defaultOptions={props.options || DEFAULT_OPTIONS}
        defaultZoom={4}
        zoom={props.zoom || 4}
        mapContainerStyle={mapContainerStyle}
        onLoad={setMapView}
      >
        {props.children}
      </GoogleMap>
    </View>
  );
});

MapView.displayName = 'MapView';

/**
 * Map web based props to match the pattern for native.
 */
export const Marker = ({ children, coordinate, ...rest }) => (
  <MarkerRaw icon={children} position={coordinate} {...rest} />
);

export * from '@react-google-maps/api';
export default MapView;
