/**
 * Valid icon types supported by react-native-elements' <Icon />
 *
 * Source: https://reactnativeelements.com/docs/icon#icon
 */
export enum IconType {
  AntDesign = 'antdesign',
  Entypo = 'entypo',
  EvilIcon = 'evilicon',
  Feather = 'feather',
  FontAwesome = 'font-awesome',
  FontAwesome5 = 'font-awesome-5',
  Fontisto = 'fontisto',
  Foundation = 'foundation',
  Ionicon = 'ionicon',
  Material = 'material',
  MaterialCommunity = 'material-community',
  Octicon = 'octicon',
  SimpleLineIcon = 'simple-line-icon',
  Zocial = 'zocial',
}
