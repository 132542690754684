import { useSelector } from 'react-redux';

import { State } from 'types/state';

const useMessage = () => {
  const { message } = useSelector((store: State.RootState) => store);

  return message;
};

export default useMessage;
