import React, { useMemo } from 'react';
import { useTheme } from 'styled-components/native';

import CategoryButton from './SubCategoryButton';
import { FillerButton, FlatList, ListContainer } from './styles';

const SubCategoryButtons = ({ possibleProcedureCategories }) => {
  const {
    windowDimensions: { width },
    breakpoints,
  } = useTheme();

  const numColumns = useMemo(() => {
    if (width > breakpoints.medium) return 6;
    if (width > breakpoints.xsmall) return 5;
    return 4;
  }, [breakpoints.medium, breakpoints.xsmall, width]);

  const numItemsInFinalRow = possibleProcedureCategories.length % numColumns;
  const numFillerItems = numColumns - numItemsInFinalRow;

  const paddedCategoryOptions = possibleProcedureCategories.concat([
    ...Array(numFillerItems).keys(),
  ]);

  return (
    <ListContainer>
      <FlatList
        key={numColumns}
        data={paddedCategoryOptions}
        renderItem={({ item }: any) =>
          item.icon ? (
            <CategoryButton category={item.name} icon={item.icon} />
          ) : (
            <FillerButton />
          )
        }
        numColumns={numColumns}
        keyExtractor={(item: any) => item.name}
      />
    </ListContainer>
  );
};

export default SubCategoryButtons;
