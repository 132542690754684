import axios from 'axios';

import { showGlobalError } from 'app/actions/uiActions';
import { CORE_API_URL } from 'app/util/constants';
import { receiveProductOfferings } from './action-types';

/**
 * Fetches list of available product offerings.
 *
 * @return {promise} A promise that resolves with the offerings.
 */
export default () => {
  return async (dispatch, getState) => {
    try {
      const { coreToken } = getState().session;

      const response = await axios.get(`${CORE_API_URL}/product_offerings`, {
        params: {},
        headers: { Authorization: `Bearer ${coreToken}` },
      });

      dispatch(receiveProductOfferings(response.data.data));
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
};
