import { NotificationMessageStyles as Styled } from 'app/components/Messages/styles';
import { NotificationMessageProps } from 'app/components/Messages/types';

/**
 * Render a notification message.
 *
 * @param  body  content for the message
 */
export const Message = ({ body }: NotificationMessageProps) => {
  return (
    <Styled.Wrapper>
      <Styled.Text>{body}</Styled.Text>
    </Styled.Wrapper>
  );
};
