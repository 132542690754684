import styled, { css } from 'styled-components/native';

import { Animated } from 'react-native';
import FeatureSwitch from 'app/components/Common/FeatureSwitch';
import { Icon } from 'react-native-elements';
import IconLink from 'app/components/Common/IconLink';
import { Platform } from 'react-native';
import { ProgressNavComplete } from 'app/components/Common/svg-components';
import { ProgressNavCurrent } from 'app/components/Common/svg-components';
import { ProgressNavIncomplete } from 'app/components/Common/svg-components';
import { ResponsivePressable } from 'app/components/Common/ResponsivePressable';
import { Text } from 'app/components/Common/Styled';
import theme from 'app/util/theme';
import { View } from 'app/components/Common/Styled';

export { Animated };
export { FeatureSwitch };

const getProgressLinkTextColorForStatus = ({ status, theme }) => {
  if (status === 'complete') return theme.colors.white + theme.colors.opacity70;
  if (status === 'current') return theme.colors.white;
  if (status === 'incomplete') return theme.colors.darkBlue;
};

const AnimatedPressable = Animated.createAnimatedComponent(ResponsivePressable);

const AnimatedView = Animated.createAnimatedComponent(View);

const { darkBlue, lightBlue } = theme.colors;

const FlexRow = styled(View)`
  flex-direction: row;
  height: 120px;
  justify-content: space-evenly;
`;

export const ActionIconsStyles = {
  Wrapper: FlexRow,

  Link: IconLink,
};

export const ProgressLinkStyles = {
  Icons: {
    complete: ProgressNavComplete,
    current: ProgressNavCurrent,
    incomplete: ProgressNavIncomplete,
  },

  Text: styled(Text)`
    ${
      /* @ts-expect-error Property 'status" does not exist on type */
      ({ status, theme }) => css`
    color: ${getProgressLinkTextColorForStatus({ status, theme })}
      font-size: ${theme.fontSizes.body1};
      margin-left: ${theme.spacing * 1.25};
    `
    }
  `,

  Wrapper: styled(View)`
    flex-direction: row;
  `,
};

export const ProgressNavStyles = {
  MainContentWrapper: styled(AnimatedView)`
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  `,

  ToggleButton: {
    Wrapper: styled(AnimatedPressable)<{
      width: number;
    }>`
      align-items: center;
      border-color: ${darkBlue};
      border-width: 2px;
      display: flex;
      flex-direction: column;
      height: 120px;
      justify-content: center;
      overflow: visible;
      position: absolute;
      right: 0;
      top: 50%;
      width: ${({ width }) => width}px;
      z-index: ${theme.zIndexes.progressNavigation};
    `,

    Icon,
  },

  NavigationWrapper: styled(AnimatedView)<{
    width: number;
  }>`
    background: ${lightBlue};
    bottom: 0;
    flex-direction: column;
    flex: 1 0 auto;
    justify-content: space-between;
    left: 0;
    max-width: ${({ width }) => width}px;
    position: ${Platform.select({
      default: 'absolute',
      web: 'fixed',
    })};
    top: 0;
    width: ${({ width }) => width}px;
    z-index: ${theme.zIndexes
      .progressNavigation}; /** Allows nav menu button to be clicked when menu is closed */
  `,

  TopSpacer: FlexRow,

  Wrapper: styled(View)`
    flex: 1 0 auto;
    flex-direction: row;
    position: relative;
  `,
};

export const ProgressSectionStyles = {
  Wrapper: styled(View)`
    align-items: flex-start;
    flex: 1 0 auto;
    justify-content: space-around;
    padding: ${({ theme }) => theme.spacing * 3.5}px;
    max-height: 600px;
  `,
};
