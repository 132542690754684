import PropTypes from 'prop-types';
import React from 'react';
import { ActivityIndicator, View } from 'react-native';

import Text from 'app/components/Common/Styled/Text';
import { RedHorizontalLogo as SvgLogo } from 'app/components/Common/svg-components';
import { SessionPropTypes } from 'app/util/propTypes';

import {
  // ButtonContainer,
  // ButtonRow,
  Card,
  Column,
  Container,
  DetailsContainer,
  DetailsRow,
  NameContainer,
} from './styles';

/**
 * Renders the Carrum ID Card for the current patient.
 */
const CarrumID = ({ session }) => {
  // const [selectedPrinter, setSelectedPrinter] = useState();

  if (
    !session.user ||
    !session.user.eligiblePatient ||
    !session.user.employer ||
    !session.user.name
  ) {
    return <ActivityIndicator />;
  }

  const { eligiblePatient, employer, name } = session.user;

  // const print = async () => {
  //   // On iOS/android prints the given html. On web prints the HTML from the current page.
  //   // const printer = await Print.selectPrinterAsync(); // iOS only
  //   // setSelectedPrinter(printer);
  //   await Print.printAsync({
  //     html: printableHtml,
  //     printerUrl: selectedPrinter?.url, // iOS only
  //   });
  // };

  // const printToFile = async () => {
  //   // On iOS/android prints the given html. On web prints the HTML from the current page.

  //   if (Platform.OS === 'web') {
  //     await Print.printToFileAsync({
  //       html: printableHtml,
  //     });
  //   } else {
  //     const { uri } = await Print.printToFileAsync({
  //       html: printableHtml,
  //     });
  //     console.log('File has been saved to:', uri);
  //     await shareAsync(uri, { UTI: '.pdf', mimeType: 'application/pdf' });
  //   }
  // };

  return (
    <Container>
      <Column>
        <Card testID="CarrumID">
          <SvgLogo height={48} width={272} />
          <NameContainer>
            <Text size="small">Member Name:</Text>
            <Text bold color="black" size="body2">
              {name.toUpperCase()}
            </Text>
          </NameContainer>
          <View>
            <DetailsContainer>
              <DetailsRow>
                <Text color="black" size="small">
                  Carrum Member ID:
                </Text>
                <Text color="black" size="small">
                  {eligiblePatient.carrumId}
                </Text>
              </DetailsRow>

              <DetailsRow>
                <Text color="black" size="small">
                  Group #:
                </Text>
                <Text
                  color="black"
                  size="small"
                >{`CAR-${employer.code.toUpperCase()}`}</Text>
              </DetailsRow>
            </DetailsContainer>

            <Text color="black" size="small">
              This card is valid only for medical treatment provided at this
              facility associated with this specific surgical episode.
            </Text>
          </View>
        </Card>
        {/* TODO: this may comeback in a future story, TBD */}
        {/* <ButtonRow>
          <ButtonContainer>
            <Button title="Download" onPress={printToFile} />
          </ButtonContainer>
          <ButtonContainer>
            <Button title="Print" onPress={print} />
          </ButtonContainer>
        </ButtonRow> */}
      </Column>
    </Container>
  );
};

/**
 * @property {object} session The session object from the redux store.
 */
CarrumID.propTypes = {
  session: PropTypes.shape(SessionPropTypes).isRequired,
};

export default CarrumID;
