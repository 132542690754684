import { TestID } from './constants';

import { getUserEmployer, isValidPhoneNumber } from './helpers';

import {
  useCallback,
  useDispatch,
  useEffect,
  useParams,
  useSession,
  useRef,
  useState,
  useSingleClickRegistration,
} from './hooks';

import {
  ContinueButton,
  DateOfBirthPicker,
  InputContainer,
  LegalContent,
  LogoDivider,
  LogoForCarrum,
  LogoForEmployer,
  LogosContainer,
  OneClickWelcomeContainer,
  PhoneInput,
  RegistrationFormColumn,
  Routes,
  ValuePropositionsColumn,
  WelcomeTextBody,
  WelcomeTextContainer,
  WelcomeTextHeader,
  WelcomeTextWrongUserLink,
  WelcomeTextWrongUserLinkContainer,
} from './styles';

import { MatchParams } from './types';

const OneClickWelcome = () => {
  const dispatch = useDispatch();
  const { clientId, token } = useParams<MatchParams>();
  const {
    loading,
    user: { employer },
  } = useSession();
  const dateOfBirthInput = useRef<HTMLInputElement>();
  const { personalizationDetails, verify: verifySingleClickRegistration } =
    useSingleClickRegistration(token);

  const { firstName, lastName } = personalizationDetails;
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [dateOfBirth, setDateOfBirth] = useState<string>('');

  useEffect(() => {
    if (clientId) dispatch(getUserEmployer(clientId));
  }, [clientId]);

  const onContinue = useCallback(async () => {
    await verifySingleClickRegistration(phoneNumber, dateOfBirth);
  }, [dateOfBirth, token, phoneNumber]);

  if (!firstName || !lastName || !employer) return;

  const phoneNumberIsComplete = isValidPhoneNumber(phoneNumber as string);

  return (
    <OneClickWelcomeContainer testID={TestID.OneClickWelcome.Page}>
      <ValuePropositionsColumn />

      <RegistrationFormColumn>
        <LogosContainer>
          <LogoForEmployer source={employer.images.logo} />
          <LogoDivider />
          <LogoForCarrum />
        </LogosContainer>

        <WelcomeTextContainer>
          <WelcomeTextHeader>
            Welcome to Carrum, {firstName as string}!
          </WelcomeTextHeader>

          <WelcomeTextWrongUserLinkContainer>
            <WelcomeTextWrongUserLink
              title={`Click here if you are not ${firstName} ${lastName}`}
              to={`/${Routes.Register}`}
            />
          </WelcomeTextWrongUserLinkContainer>

          <WelcomeTextBody>
            Carrum is a health benefit offered by your employer,{' '}
            {employer.displayName}. We have successfully imported{' '}
            {employer.displayName} benefits data to get you started. Before
            continuing, we need to verify it's really you. Please confirm:
          </WelcomeTextBody>
        </WelcomeTextContainer>

        <InputContainer>
          <DateOfBirthPicker
            label="Date of Birth"
            forwardRef
            ref={dateOfBirthInput}
            input={{
              name: TestID.OneClickWelcome.DateOfBirthInput,
              onChange: (value?: string) => value && setDateOfBirth(value),
              value: dateOfBirth,
            }}
          />
          <PhoneInput
            label="Phone Number"
            input={{
              name: TestID.OneClickWelcome.PhoneNumberInput,
              value: phoneNumber,
              onChange: setPhoneNumber,
            }}
          />
        </InputContainer>

        <ContinueButton
          title="Continue"
          disabled={loading || !dateOfBirth || !phoneNumberIsComplete}
          loading={loading}
          onPress={onContinue}
          testID={TestID.OneClickWelcome.ContinueButton}
        />

        <LegalContent />
      </RegistrationFormColumn>
    </OneClickWelcomeContainer>
  );
};

export default OneClickWelcome;
