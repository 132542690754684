import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import TextRaw from 'app/components/Common/Styled/Text';

const Disclaimer = () => {
  return (
    <View>
      <DiscalaimerParagraph>
        Disclaimer: If the patient voluntarily elected to discontinue
        participation in the Carrum program prior to surgery but after funds
        have been issued, the patient will be responsible for returning the
        funds in total to Carrum Health.
      </DiscalaimerParagraph>
      <DiscalaimerParagraph>
        Certain travel expenses covered through Carrum Health may be considered
        taxable income. This applies regardless of if we pay travel expenses
        directly on your behalf, provide stipend funds for you to pay them, or
        reimburse you directly.
      </DiscalaimerParagraph>
      <DiscalaimerParagraph>
        Because the travel expenses for your care meet the IRS threshold, they
        are considered taxable income. We will mail you a 1099 form toward the
        end of this year or at the start of next year. This 1099 form will
        provide you with the information you need to properly report any taxable
        amount to the IRS when you file your taxes for next year.
      </DiscalaimerParagraph>
      <DiscalaimerParagraph>
        Please reach out to your assigned Patient Care Specialist or Patient
        Care Associate if you have any questions.
      </DiscalaimerParagraph>
    </View>
  );
};

const DiscalaimerParagraph = styled(TextRaw)`
  font-style: italic;
  font-size: ${({ theme }) => theme.fontSizes.small}px;
  margin-bottom: ${({ theme }) => theme.spacing}px;
`;

export default Disclaimer;
