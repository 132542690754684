import styled, { css } from 'styled-components/native';

import VisibilitySensorRaw from 'app/components/Common/VisibilitySensor';

export { DotIndicator } from 'app/components/Common/svg-components';

export const VisibilitySensor = styled(VisibilitySensorRaw)`
  flex: 1 0 auto;
  flex-direction: row;
  justify-content: center;

  ${({ theme }) => css`
    padding: ${theme.spacing}px;
  `}
`;
