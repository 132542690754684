import { Platform } from 'react-native';
import Constants from 'expo-constants';
import { isDevice } from 'expo-device';
import {
  getBadgeCountAsync,
  getExpoPushTokenAsync,
  getPermissionsAsync,
  requestPermissionsAsync,
  setBadgeCountAsync,
} from 'expo-notifications';

import { updateCurrentUser } from 'app/actions/userActions';
import { logError } from 'app/util/methods';

import { receiveNotificationToken } from './action-types';

/**
 * Checks to see if the user has already granted push notification
 * permissions for the application, and requests permissions if not.
 */
const fetchOrRequestNotificationPermissions = async () => {
  let status;

  try {
    ({ status } = await getPermissionsAsync());

    if (status !== 'granted') {
      ({ status } = await requestPermissionsAsync());
    }
  } catch (error) {
    logError('Encountered an error requesting notification permissions', {
      error,
    });
  }

  return status;
};

/**
 * Attempts to fetch a push notification token from Expo, which is
 * used for subsequent push notifications in the app.
 *
 * NOTE: Currently the platform only supports registering a single
 *       device for push notifications per user account.  In the
 *       event that a user logs in with two devices, the one that
 *       most recently granted permissions will be registered,
 *       overwriting the previous `notificationToken`.
 */
const requestExpoPushNotificationToken = async () => {
  let notificationToken;

  try {
    ({ data: notificationToken } = await getExpoPushTokenAsync({
      projectId: Constants.expoConfig?.extra?.eas?.projectId,
    }));
  } catch (error) {
    logError('Encountered an error requesting a push notification token', {
      error,
    });
  }

  return notificationToken;
};

/**
 * Makes a PATCH request to save the `notificationToken` on the
 * user, unless it matches the existing token on file.
 */
const saveNotificationToken = (notificationToken) => {
  return async (dispatch, getState) => {
    try {
      const {
        session: { user },
      } = getState();

      // Don't bother making an API call if the token we got is already saved.
      if (user.notificationToken === notificationToken) return;

      dispatch(updateCurrentUser({ notificationToken }));
    } catch (error) {
      logError('Unable to register for push notifications', { error });
    }
  };
};

/**
 * Requests push notification permissions in the device in use
 * on behalf of the current logged in user.  If the user accepts,
 * a `notificationToken` is requested from Expo and saved on the
 * user record.
 */
export const registerForPushNotifications = () => {
  return async (dispatch) => {
    // Only request permissions on mobile devices.
    if (Platform.OS === 'web' || !isDevice) return;

    const status = await fetchOrRequestNotificationPermissions();

    if (status !== 'granted') return;

    const notificationToken = await requestExpoPushNotificationToken();

    await dispatch(receiveNotificationToken(notificationToken));

    if (!notificationToken) return;

    await dispatch(saveNotificationToken(notificationToken));
  };
};

/**
 * Increments the current badge count by a given amount
 * on iOS devices.
 *
 * @param {integer} amount The amount to change the badge number by.
 * @return {promise} A promise that resolves after updating the badge number.
 *
 */
export const incrementBadgeNumber = (amount) => {
  return async () => {
    if (Platform.OS !== 'ios') return;

    try {
      const number = await getBadgeCountAsync();
      setBadgeCountAsync(number + amount);
    } catch (error) {
      logError('Unable to increment badge number', error);
    }
  };
};

/**
 * Sets the current badge count to a given number
 * on iOS devices.
 *
 * @param {integer} number The number to set the badge count to.
 * @return {promise} A promise that resolves after updating the badge number.
 *
 */
export const setBadgeNumber = (number) => {
  return async () => {
    if (Platform.OS !== 'ios') return;

    try {
      await setBadgeCountAsync(number);
    } catch (error) {
      logError('Unable to set badge number', error);
    }
  };
};
