export enum IStipendTypes {
  Baggage = 'baggage',
  Meals = 'meals',
  Mileage = 'mileage',
  Miscellaneous = 'miscellaneous',
  Parking = 'parking',
  Transportation = 'transportation',
}

export interface ITravel {
  disbursementDate?: string;
  disbursementMethod?: string;
  expense: string;
  expenses: IStipendExpense[];
  itinerary: string;
  paypalEmailAddress?: string;
}

export interface IStipendExpense {
  amount: number;
  date: string;
  description: IStipendTypes;
}
