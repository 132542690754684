import * as Font from 'expo-font';

export const FONTS = {
  inter: require('app/assets/fonts/Inter-Regular.ttf'),
  'inter-medium': require('app/assets/fonts/Inter-Medium.ttf'),
  'inter-bold': require('app/assets/fonts/Inter-SemiBold.ttf'),
  squarepeg: require('app/assets/fonts/SquarePeg-Regular.ttf'),
};

export const loadFontsAsync = async () => {
  try {
    await Font.loadAsync(FONTS);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('Failed to fonts', error);
  }
};
