import { change } from 'redux-form';

import { showGlobalError } from 'app/actions/uiActions';
import { fetchLocationFromAddress } from 'app/util/methods';
import { parseUserLocation } from 'app/util/userUtils';

import { receiveUserLocation } from './action-types';

/**
 * Calls the Google Maps API to get coordinates
 * from the current user's address and then updates
 * the user object in the session reducer.
 *
 * @return {promise} a promise that resolves when the data is fetched.
 */
export default () => {
  return async (dispatch) => {
    try {
      const location = await dispatch(fetchUserLocation());

      if (!location) return;

      dispatch(receiveUserLocation(location));
      dispatch(change('episode', 'location', location));
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
};

const fetchUserLocation = () => {
  return (dispatch, getState) => {
    const {
      session: { user },
      episode,
    } = getState();

    const episodeLocation = episode?.episode?.location;

    const { address, latitude, longitude } = parseUserLocation(
      user,
      episodeLocation
    );

    if (latitude && longitude) {
      return { address, lat: latitude, lng: longitude };
    }

    if (!address) return;

    return fetchLocationFromAddress(address);
  };
};
