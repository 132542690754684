import styled, { css } from 'styled-components/native';

import { View } from 'app/components/Common/Styled';

export { NumberBadge } from '../NumberBadge';
export { Text as NumberStepText } from 'app/components/Common/Styled';

export const NextStepInfoWrapper = styled(View)`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-shrink: 1;
    flex-direction: column;
    margin-top: ${theme.spacing * 1.75}px;
  `}
`;
