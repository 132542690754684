import React, { useEffect, useState, useRef } from 'react';
import { Platform, View, useWindowDimensions } from 'react-native';
import { isInViewPort } from './helpers';

interface DimensionDataProps {
  rectTop: number;
  rectBottom: number;
  rectWidth: number;
}

interface VisibilitySensorProps {
  /** Function that is triggered when component enters the viewport */
  onChange(visible: boolean): any;
  /** The component that needs to be in the viewport */
  children: React.ReactNode;
}

const VisibilitySensor: React.FC<VisibilitySensorProps> = (props) => {
  const myView: any = useRef(null);
  const [lastValue, setLastValue] = useState<boolean>(false);
  const [dimensions, setDimensions] = useState<DimensionDataProps>({
    rectTop: 0,
    rectBottom: 0,
    rectWidth: 0,
  });

  let interval: any = null;

  useEffect(() => {
    setLastValue(false);
    startWatching();
    onViewportChange();
    return stopWatching;
  }, [dimensions.rectTop, dimensions.rectBottom, dimensions.rectWidth]);

  const updateDimensions = async (
    width: number,
    height: number,
    pageX: number,
    pageY: number
  ) => ({
    rectTop: pageY,
    rectBottom: pageY + height,
    rectWidth: pageX + width,
  });

  const startWatching = () => {
    if (interval) {
      return;
    }

    interval = setInterval(() => {
      if (!myView || !myView.current) {
        return;
      }

      myView.current.measure(
        async (
          _x: number,
          _y: number,
          width: number,
          height: number,
          pageX: number,
          pageY: number
        ) => {
          setDimensions(await updateDimensions(width, height, pageX, pageY));
        }
      );
    }, 1000);
  };

  const stopWatching = () => {
    interval = clearInterval(interval);
  };

  const window = useWindowDimensions();
  const onViewportChange = () => {
    const isVisible = isInViewPort(dimensions, window);

    if (lastValue !== isVisible) {
      setLastValue(isVisible);
      props.onChange(isVisible);
    } else {
      props.onChange(isVisible);
    }
  };

  return (
    <View
      // NOTE: The `collapsable` is only supported in native
      collapsable={Platform.OS === 'web' ? undefined : false}
      ref={myView}
      {...props}
    >
      {props.children}
    </View>
  );
};

export default VisibilitySensor;
