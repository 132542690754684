import axios from 'axios';

import { showGlobalError } from 'app/actions/uiActions';
import { CORE_API_URL } from 'app/util/constants';

import { receiveReferralSources } from './action-types';

/**
 * Fetches the available referral sources from the API.
 *
 * @return {promise} a promise that resolves after fetching the data.
 */
export default () => {
  return (dispatch) => {
    return axios
      .get(`${CORE_API_URL}/referral_sources`, {
        params: { 'page[size]': 1000 },
      })
      .then((resp) => dispatch(receiveReferralSources(resp.data.data)))
      .catch((error) => dispatch(showGlobalError(error)));
  };
};
