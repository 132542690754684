import { Easing } from 'react-native';

import { breakpoints } from 'app/util/breakpoints';
import { useNativeDriver } from 'app/util/constants';
import { Routes } from 'app/util/routes';
import { EnvironmentTypes } from 'types/environments';
import { PlatformTypes } from 'types/platforms';

export const animationConfig = {
  duration: 200,
  useNativeDriver,
};

export const animationValues = {
  opening: {
    fadeTo: 1,
    slideTo: 0,
  },
  closing: {
    fadeTo: 0,
    slideTo: 280,
  },
};

export { breakpoints } from 'app/util/breakpoints';

export { ENVIRONMENT } from 'app/util/constants';

export const headerAnimationConfig = {
  delay: 120,
  duration: 300,
  easing: Easing.in(Easing.ease),
  useNativeDriver,
};

export const ModalPage = {
  CallUsPage: 'call-us-page',
  RequestReceivedPage: 'request-received-page',
};

export const nativePlatforms = [PlatformTypes.Android, PlatformTypes.iOS];

export const navigationLinkStyles = {
  underlineHeight: 3,
  badgeHeight: 18,
};

export const navigationPaths = {
  carrumId: `/${Routes.CarrumID}`,
  forms: `/${Routes.Forms}`,
  help: `/${Routes.LearnMore}`,
  home: `/`,
  logIn: `/${Routes.Login}`,
  messages: `/${Routes.Messages}`,
  register: `/${Routes.Register}`,
  settings: `/${Routes.MySettings}`,
  travel: `/${Routes.Travel}`,
};

export const nonProdEnvironments = [
  EnvironmentTypes.Demo,
  EnvironmentTypes.Development,
  EnvironmentTypes.Staging,
  EnvironmentTypes.UserAcceptanceTesting,
];

export { PATIENT_FAQ_URL } from 'app/util/constants';

export { Platform } from 'react-native';

export { REQUEST_RECEIVED_TEXT } from 'app/util/constants';

export { Routes } from 'app/util/routes';

export const screenSizes = {
  narrow: breakpoints.small - 1,
  wide: breakpoints.small + 1,
};

export { SelectYourDoctorRoutes } from 'app/components/SelectYourDoctor/routes';

export { TestID } from 'app/util/test-id';

export { default as theme } from 'app/util/theme';
