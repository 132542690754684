import React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

/**
 * Renders the Carrum Health logo as an SVG.
 */
const SvgLogo = ({ width, height }) => (
  <Svg viewBox="0 0 685 119.66" width={width} height={height}>
    <Path
      d="M202.75,147.85h11.17c4.44,0,8.47,1.55,8.47,6.48a6,6,0,0,1-4.59,5.92v.07a6.19,6.19,0,0,1,5.59,6.44c0,2.52-.89,7.51-9.47,7.51H202.75Zm2.52,11.58h8.66c4,0,6-2,6-4.48,0-3.29-2-5-6-5h-8.66Zm0,12.69h8.66c3.74,0,7-1.22,7-5.44,0-3.37-2.55-5.11-7-5.11h-8.66Z"
      fill="#676a63"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M230.21,165.35c0,3.4,1.81,7.51,6.29,7.51,3.4,0,5.25-2,6-4.88h2.33c-1,4.33-3.51,6.85-8.33,6.85-6.07,0-8.62-4.66-8.62-10.1,0-5,2.55-10.1,8.62-10.1s8.58,5.37,8.4,10.73Zm12.36-2c-.11-3.52-2.29-6.81-6.07-6.81s-5.92,3.33-6.29,6.81Z"
      fill="#676a63"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M253.56,155.17h3.88v2h-3.88V170c0,1.52.22,2.41,1.89,2.52a18.15,18.15,0,0,0,2-.11v2c-.7,0-1.37.07-2.07.07-3.11,0-4.18-1-4.14-4.29V157.13H247.9v-2h3.33v-5.74h2.33Z"
      fill="#676a63"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M266.36,155.17h3.89v2h-3.89V170c0,1.52.22,2.41,1.89,2.52a18.15,18.15,0,0,0,2-.11v2c-.7,0-1.37.07-2.07.07-3.11,0-4.18-1-4.14-4.29V157.13H260.7v-2H264v-5.74h2.33Z"
      fill="#676a63"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M276.84,165.35c0,3.4,1.81,7.51,6.29,7.51,3.4,0,5.25-2,6-4.88h2.33c-1,4.33-3.51,6.85-8.33,6.85-6.07,0-8.62-4.66-8.62-10.1,0-5,2.55-10.1,8.62-10.1s8.58,5.37,8.4,10.73Zm12.36-2c-.11-3.52-2.29-6.81-6.07-6.81s-5.92,3.33-6.29,6.81Z"
      fill="#676a63"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M296.49,155.17h2.15v4.48h.07a7.06,7.06,0,0,1,7.22-4.74v2.33a6.58,6.58,0,0,0-7.1,6.85v10.18h-2.33Z"
      fill="#676a63"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M321.57,165.57c-.11,5.44,3.81,7.1,8.62,7.1,2.78,0,7-1.52,7-5.37,0-3-3-4.18-5.44-4.77l-5.88-1.44c-3.11-.78-6-2.26-6-6.36,0-2.59,1.67-7.44,9.1-7.44,5.22,0,9.81,2.81,9.77,8.21h-2.52c-.15-4-3.48-6.07-7.21-6.07-3.44,0-6.62,1.33-6.62,5.25,0,2.48,1.85,3.52,4,4l6.44,1.59c3.74,1,6.84,2.63,6.84,7,0,1.81-.74,7.55-10.1,7.55-6.25,0-10.84-2.81-10.55-9.25Z"
      fill="#676a63"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M359.83,174.27h-2.15v-3.44h-.07a7.1,7.1,0,0,1-6.59,4c-4.81,0-6.7-2.81-6.7-7.25v-12.4h2.33V167.6c.11,3.44,1.41,5.25,5.11,5.25,4,0,5.74-3.77,5.74-7.66v-10h2.33Z"
      fill="#676a63"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M366.09,155.17h2.15v4.48h.07a7.06,7.06,0,0,1,7.22-4.74v2.33a6.58,6.58,0,0,0-7.1,6.85v10.18h-2.33Z"
      fill="#676a63"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M395.73,172.71c0,5.7-2.22,9.18-8.47,9.18-3.81,0-7.59-1.7-7.92-5.77h2.33c.52,2.81,3,3.81,5.59,3.81,4.29,0,6.14-2.55,6.14-7.22v-2.59h-.07a6.62,6.62,0,0,1-6.07,3.92c-6.11,0-8.62-4.37-8.62-9.81,0-5.25,3.11-9.62,8.62-9.62a6.72,6.72,0,0,1,6.07,3.85h.07v-3.29h2.33Zm-2.33-8.21c0-3.74-1.74-7.92-6.14-7.92s-6.29,4-6.29,7.92,2,7.59,6.29,7.59C391.29,172.08,393.4,168.38,393.4,164.5Z"
      fill="#676a63"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M403.39,165.35c0,3.4,1.81,7.51,6.29,7.51,3.4,0,5.25-2,6-4.88H418c-1,4.33-3.52,6.85-8.33,6.85-6.07,0-8.62-4.66-8.62-10.1,0-5,2.55-10.1,8.62-10.1s8.58,5.37,8.4,10.73Zm12.36-2c-.11-3.52-2.29-6.81-6.07-6.81s-5.92,3.33-6.29,6.81Z"
      fill="#676a63"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M440.13,164.72c0,5.48-3.18,10.1-9,10.1s-9-4.63-9-10.1,3.18-10.1,9-10.1S440.13,159.24,440.13,164.72Zm-15.73,0c0,4.07,2.22,8.14,6.7,8.14s6.7-4.07,6.7-8.14-2.22-8.14-6.7-8.14S424.41,160.65,424.41,164.72Z"
      fill="#676a63"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M445.43,155.17h2.33v3.29h.07a6.47,6.47,0,0,1,6.11-3.85c5.37,0,7,2.81,7,7.36v12.28H458.6V162.35c0-3.29-1.07-5.77-4.85-5.77s-5.92,2.81-6,6.55v11.14h-2.33Z"
      fill="#676a63"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M478.62,160.76c-.11-2.89-2.33-4.18-5-4.18-2.07,0-4.51.81-4.51,3.29,0,2.07,2.37,2.81,4,3.22l3.11.7c2.66.41,5.44,2,5.44,5.29,0,4.14-4.11,5.74-7.66,5.74-4.44,0-7.47-2.07-7.84-6.73h2.33c.18,3.15,2.52,4.77,5.62,4.77,2.18,0,5.22-1,5.22-3.63,0-2.22-2.07-3-4.18-3.48l-3-.67c-3-.81-5.33-1.85-5.33-5.11,0-3.89,3.81-5.37,7.18-5.37,3.81,0,6.84,2,7,6.14Z"
      fill="#676a63"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M491.31,170.34v4c0,2.15-1,4.4-3.26,5v-1.59c1.22-.44,1.78-2.26,1.7-3.48H488.2v-3.92Z"
      fill="#676a63"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M507.85,147.85H519c4.44,0,8.47,1.55,8.47,6.48a6,6,0,0,1-4.59,5.92v.07a6.19,6.19,0,0,1,5.59,6.44c0,2.52-.89,7.51-9.47,7.51H507.85Zm2.52,11.58H519c4,0,6-2,6-4.48,0-3.29-2-5-6-5h-8.66Zm0,12.69H519c3.74,0,7-1.22,7-5.44,0-3.37-2.55-5.11-7-5.11h-8.66Z"
      fill="#676a63"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M535.31,165.35c0,3.4,1.81,7.51,6.29,7.51,3.4,0,5.25-2,6-4.88h2.33c-1,4.33-3.52,6.85-8.33,6.85-6.07,0-8.62-4.66-8.62-10.1,0-5,2.55-10.1,8.62-10.1s8.58,5.37,8.4,10.73Zm12.36-2c-.11-3.52-2.29-6.81-6.07-6.81s-5.92,3.33-6.29,6.81Z"
      fill="#676a63"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M558.69,155.17h3.88v2h-3.88V170c0,1.52.22,2.41,1.89,2.52a18.15,18.15,0,0,0,2-.11v2c-.7,0-1.37.07-2.07.07-3.11,0-4.18-1-4.14-4.29V157.13H553v-2h3.33v-5.74h2.33Z"
      fill="#676a63"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M571.5,155.17h3.89v2H571.5V170c0,1.52.22,2.41,1.89,2.52a18.15,18.15,0,0,0,2-.11v2c-.7,0-1.37.07-2.07.07-3.11,0-4.18-1-4.14-4.29V157.13h-3.33v-2h3.33v-5.74h2.33Z"
      fill="#676a63"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M581.23,165.35c0,3.4,1.81,7.51,6.29,7.51,3.4,0,5.25-2,6-4.88h2.33c-1,4.33-3.51,6.85-8.33,6.85-6.07,0-8.62-4.66-8.62-10.1,0-5,2.55-10.1,8.62-10.1s8.58,5.37,8.4,10.73Zm12.36-2c-.11-3.52-2.29-6.81-6.07-6.81s-5.92,3.33-6.29,6.81Z"
      fill="#676a63"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M600.88,155.17H603v4.48h.07a7.06,7.06,0,0,1,7.22-4.74v2.33a6.58,6.58,0,0,0-7.1,6.85v10.18h-2.33Z"
      fill="#676a63"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M624,147.85h12.1c4.37,0,8,2.11,8,6.88,0,3.33-1.74,6.07-5.14,6.73v.07c3.44.44,4.44,2.81,4.63,6,.11,1.81.11,5.44,1.22,6.77h-2.78c-.63-1-.63-3-.7-4.11-.22-3.7-.52-7.81-5.29-7.62h-9.55v11.73H624Zm2.52,12.54h9.4c3.07,0,5.7-1.92,5.7-5.14s-1.92-5.25-5.7-5.25h-9.4Z"
      fill="#676a63"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M651.46,165.35c0,3.4,1.81,7.51,6.29,7.51,3.4,0,5.25-2,6-4.88h2.33c-1,4.33-3.51,6.85-8.33,6.85-6.07,0-8.62-4.66-8.62-10.1,0-5,2.55-10.1,8.62-10.1s8.58,5.37,8.4,10.73Zm12.36-2c-.11-3.52-2.29-6.81-6.07-6.81s-5.92,3.33-6.29,6.81Z"
      fill="#676a63"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M682.55,160.76c-.11-2.89-2.33-4.18-5-4.18-2.07,0-4.51.81-4.51,3.29,0,2.07,2.37,2.81,4,3.22l3.11.7c2.66.41,5.44,2,5.44,5.29,0,4.14-4.11,5.74-7.66,5.74-4.44,0-7.47-2.07-7.84-6.73h2.33c.18,3.15,2.52,4.77,5.62,4.77,2.18,0,5.22-1,5.22-3.63,0-2.22-2.07-3-4.18-3.48l-3-.67c-3-.81-5.33-1.85-5.33-5.11,0-3.89,3.81-5.37,7.18-5.37,3.81,0,6.85,2,7,6.14Z"
      fill="#676a63"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M706.23,174.27h-2.15v-3.44H704a7.1,7.1,0,0,1-6.59,4c-4.81,0-6.7-2.81-6.7-7.25v-12.4h2.33V167.6c.11,3.44,1.41,5.25,5.11,5.25,4,0,5.74-3.77,5.74-7.66v-10h2.33Z"
      fill="#676a63"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M712.52,147.85h2.33v26.42h-2.33Z"
      fill="#676a63"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M724.84,155.17h3.88v2h-3.88V170c0,1.52.22,2.41,1.89,2.52a18.15,18.15,0,0,0,2-.11v2c-.7,0-1.37.07-2.07.07-3.11,0-4.18-1-4.14-4.29V157.13h-3.33v-2h3.33v-5.74h2.33Z"
      fill="#676a63"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M745.38,160.76c-.11-2.89-2.33-4.18-5-4.18-2.07,0-4.51.81-4.51,3.29,0,2.07,2.37,2.81,4,3.22l3.11.7c2.66.41,5.44,2,5.44,5.29,0,4.14-4.11,5.74-7.66,5.74-4.44,0-7.47-2.07-7.84-6.73h2.33c.18,3.15,2.52,4.77,5.62,4.77,2.18,0,5.22-1,5.22-3.63,0-2.22-2.07-3-4.18-3.48l-3-.67c-3-.81-5.33-1.85-5.33-5.11,0-3.89,3.81-5.37,7.18-5.37,3.81,0,6.85,2,7,6.14Z"
      fill="#676a63"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M225.28,79.22A21.67,21.67,0,0,0,209.18,86a23.66,23.66,0,0,0,0,33,21.67,21.67,0,0,0,16.1,6.82A22.12,22.12,0,0,0,236,123.13a22.76,22.76,0,0,0,8.21-7.52,3.52,3.52,0,0,1,2.42-1.64,4.21,4.21,0,0,1,.82-.08,3.77,3.77,0,0,1,3.7,3.08,4.24,4.24,0,0,1,.08.82,3.7,3.7,0,0,1-.62,2.05,30.21,30.21,0,0,1-11,10.07,29.88,29.88,0,0,1-14.33,3.61,29.06,29.06,0,0,1-21.52-9.08,31.29,31.29,0,0,1,0-43.82,29,29,0,0,1,21.52-9.12,29.6,29.6,0,0,1,14.33,3.65,30.22,30.22,0,0,1,11,10.06,3.72,3.72,0,0,1,.66,2.09,4,4,0,0,1-.08.78,3.77,3.77,0,0,1-3.7,3.08,4.43,4.43,0,0,1-.86-.08,3.53,3.53,0,0,1-2.42-1.64A22.78,22.78,0,0,0,236,81.93a22.12,22.12,0,0,0-10.68-2.71"
      fill="#f6bb00"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M277.11,125.84a14.43,14.43,0,0,0,10.68-4.52,15.83,15.83,0,0,0,0-21.89,14.88,14.88,0,0,0-21.36,0,15.72,15.72,0,0,0,0,21.89,14.43,14.43,0,0,0,10.68,4.52M296,87.19a3.62,3.62,0,0,1,2.71,1.15A3.69,3.69,0,0,1,299.87,91V129.7a3.83,3.83,0,0,1-6.53,2.71,3.62,3.62,0,0,1-1.15-2.71v-2a22.45,22.45,0,0,1-31.17-.94A23.36,23.36,0,0,1,261,94a22.41,22.41,0,0,1,31.17-1V91A3.93,3.93,0,0,1,296,87.19"
      fill="#f6bb00"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M333.13,87.19a3.86,3.86,0,0,1,0,7.72,12.37,12.37,0,0,0-9.61,4.52,16.1,16.1,0,0,0-4,11v19.3a3.69,3.69,0,0,1-1.11,2.71,3.77,3.77,0,0,1-2.71,1.11,3.82,3.82,0,0,1-2.75-1.11,3.69,3.69,0,0,1-1.11-2.71V91A3.69,3.69,0,0,1,313,88.34a3.76,3.76,0,0,1,2.75-1.15A3.86,3.86,0,0,1,319.54,91v1.52a19.61,19.61,0,0,1,13.6-5.38"
      fill="#f6bb00"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M363.2,87.19a3.86,3.86,0,0,1,0,7.72,12.37,12.37,0,0,0-9.61,4.52,16.1,16.1,0,0,0-4,11v19.3a3.69,3.69,0,0,1-1.11,2.71,3.77,3.77,0,0,1-2.71,1.11,3.82,3.82,0,0,1-2.75-1.11,3.69,3.69,0,0,1-1.11-2.71V91A3.69,3.69,0,0,1,343,88.34a3.76,3.76,0,0,1,2.75-1.15A3.86,3.86,0,0,1,349.6,91v1.52a19.61,19.61,0,0,1,13.6-5.38"
      fill="#f6bb00"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M408,87.19a3.69,3.69,0,0,1,2.71,1.11A3.83,3.83,0,0,1,411.83,91V129.7a3.78,3.78,0,0,1-1.11,2.71,3.86,3.86,0,0,1-6.57-2.71v-1.23a18.26,18.26,0,0,1-12.65,5.05,18.48,18.48,0,0,1-14.38-6.78,24.05,24.05,0,0,1-6-16.39V90.93a3.69,3.69,0,0,1,1.11-2.71,3.82,3.82,0,0,1,5.46,0,3.69,3.69,0,0,1,1.11,2.71v19.43a16.7,16.7,0,0,0,3.7,10.92,11.07,11.07,0,0,0,17.91,0,16.67,16.67,0,0,0,3.7-10.92V91a3.76,3.76,0,0,1,1.15-2.75A3.69,3.69,0,0,1,408,87.19"
      fill="#f6bb00"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M475.85,87.19A18.44,18.44,0,0,1,490.23,94a24,24,0,0,1,6,16.39v19.3a3.79,3.79,0,0,1-3.82,3.82,3.82,3.82,0,0,1-2.75-1.11,3.78,3.78,0,0,1-1.11-2.71v-19.3a16.86,16.86,0,0,0-3.7-11,11.13,11.13,0,0,0-17.91,0,16.8,16.8,0,0,0-3.7,10.93V129.7a3.79,3.79,0,0,1-3.82,3.82,3.82,3.82,0,0,1-2.75-1.11,3.77,3.77,0,0,1-1.11-2.71V110.35a16.8,16.8,0,0,0-3.7-10.93,11.13,11.13,0,0,0-17.91,0,16.86,16.86,0,0,0-3.7,11v19.3a3.69,3.69,0,0,1-1.11,2.71,3.77,3.77,0,0,1-2.71,1.11,3.82,3.82,0,0,1-2.75-1.11,3.69,3.69,0,0,1-1.11-2.71V91a3.69,3.69,0,0,1,1.11-2.71,3.75,3.75,0,0,1,2.75-1.15A3.86,3.86,0,0,1,430.22,91v1.19a18.36,18.36,0,0,1,12.65-5.05q10.39,0,16.51,9.65,6.07-9.65,16.47-9.65"
      fill="#f6bb00"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M539.42,71.29a3.86,3.86,0,0,1,3.82,3.86v54.5a3.86,3.86,0,0,1-3.82,3.86,3.75,3.75,0,0,1-2.75-1.15,3.7,3.7,0,0,1-1.11-2.71V106.16H513.63v23.49a3.85,3.85,0,0,1-3.82,3.86,3.75,3.75,0,0,1-2.75-1.15,3.7,3.7,0,0,1-1.11-2.71V75.15a3.69,3.69,0,0,1,1.11-2.71,3.76,3.76,0,0,1,2.75-1.15,3.86,3.86,0,0,1,3.82,3.86V98.48h21.93V75.15a3.68,3.68,0,0,1,1.11-2.71,3.76,3.76,0,0,1,2.75-1.15"
      fill="#2991de"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M588.26,106.57a15.63,15.63,0,0,0-30.27,0Zm-31.63,20.17a23.24,23.24,0,0,1-5-7.31,23.07,23.07,0,0,1,5-25.42,23.49,23.49,0,0,1,7.43-5,22.84,22.84,0,0,1,9.08-1.85A22.53,22.53,0,0,1,589.61,94a23,23,0,0,1,5,7.39,22.7,22.7,0,0,1,1.85,9,3.69,3.69,0,0,1-1.11,2.71,3.75,3.75,0,0,1-2.75,1.15H558a15,15,0,0,0,5.5,8.25,15.31,15.31,0,0,0,9.65,3.29q8.54,0,13.18-5.22a3.58,3.58,0,0,1,2.63-1.27,3.84,3.84,0,0,1,2.79.95,3.82,3.82,0,0,1,.28,5.42q-6.9,7.8-18.89,7.8a22.64,22.64,0,0,1-16.51-6.78"
      fill="#2991de"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M623.29,125.84A14.43,14.43,0,0,0,634,121.32a15.83,15.83,0,0,0,0-21.89,14.88,14.88,0,0,0-21.36,0,15.72,15.72,0,0,0,0,21.89,14.43,14.43,0,0,0,10.68,4.52m18.93-38.65a3.62,3.62,0,0,1,2.71,1.15A3.69,3.69,0,0,1,646,91V129.7a3.83,3.83,0,0,1-6.53,2.71,3.62,3.62,0,0,1-1.15-2.71v-2a22.45,22.45,0,0,1-31.17-.94,23.35,23.35,0,0,1,0-32.74,22.41,22.41,0,0,1,31.17-1V91a3.93,3.93,0,0,1,3.86-3.86"
      fill="#2991de"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M661,71.5a3.86,3.86,0,0,1,3.82,3.86V129.7a3.68,3.68,0,0,1-1.11,2.71,3.77,3.77,0,0,1-2.71,1.11,3.82,3.82,0,0,1-2.75-1.11,3.69,3.69,0,0,1-1.11-2.71V75.36a3.69,3.69,0,0,1,1.11-2.71A3.76,3.76,0,0,1,661,71.5"
      fill="#2991de"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M694.1,87.19a3.62,3.62,0,0,1,2.71,1.15,3.86,3.86,0,0,1,0,5.42,3.61,3.61,0,0,1-2.71,1.15h-6V129.7a3.83,3.83,0,0,1-6.53,2.71,3.63,3.63,0,0,1-1.15-2.71V94.91h-6a3.75,3.75,0,0,1-2.75-1.15,3.87,3.87,0,0,1,0-5.42,3.76,3.76,0,0,1,2.75-1.15h6V75.36A3.83,3.83,0,0,1,687,72.65a3.69,3.69,0,0,1,1.11,2.71V87.19Z"
      fill="#2991de"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M725.8,87.06a19.71,19.71,0,0,1,15.07,6.82,23.33,23.33,0,0,1,6.24,16.39V129.7a3.86,3.86,0,0,1-6.57,2.71,3.77,3.77,0,0,1-1.11-2.71V110.27a16,16,0,0,0-4-11,12.48,12.48,0,0,0-19.22,0,16.09,16.09,0,0,0-4,11v19.3a3.69,3.69,0,0,1-1.11,2.71,3.77,3.77,0,0,1-2.71,1.11,3.82,3.82,0,0,1-2.75-1.11,3.69,3.69,0,0,1-1.11-2.71V75.24a3.69,3.69,0,0,1,1.11-2.71,3.76,3.76,0,0,1,2.75-1.15,3.86,3.86,0,0,1,3.82,3.86V92.45a19.61,19.61,0,0,1,13.6-5.38"
      fill="#2991de"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      fill="#f6bb00"
      d="M177,79.32a16.14,16.14,0,0,0-13.39-16.92c-8.79-.24-17.63,0-26.43-.09-8-.87-15.78,5.47-16.94,13.4,0,2.56-.06,5.11-.07,7.67h-.37A33.88,33.88,0,0,0,85.9,117.25q0,.88,0,1.74c-1.83,0-3.65,0-5.47,0-8-.87-15.78,5.47-16.94,13.4-.14,8.65,0,17.34-.06,26a16,16,0,0,0,13.39,17.3c8.7.3,17.42.05,26.13.11,8.32.94,16.4-5.72,17.21-14,.08-3.54.09-7.09.09-10.64a33.87,33.87,0,0,0,33.3-32c2,0,4.05,0,6.08,0,8.32.94,16.4-5.72,17.21-14C177.07,96.5,176.91,87.9,177,79.32Z"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M116.89,166.57a45.67,45.67,0,0,1-28.06-12,47.6,47.6,0,0,1-14.7-23.64A53.81,53.81,0,0,1,73.21,110a43.87,43.87,0,0,1,9-20.58,54.7,54.7,0,0,1,8.29-8.33,43.06,43.06,0,0,1,24.11-9.65c1.44-.12,6.14-.12,7.66,0a38.24,38.24,0,0,1,6.49.94,7.79,7.79,0,0,1,4.74,4.29,7,7,0,0,1,.73,3.62,8.53,8.53,0,0,1-.62,3.57,8.11,8.11,0,0,1-4.14,4.07c-1.64.76-3,.89-5.6.52a31.86,31.86,0,0,0-6.68-.34A27.36,27.36,0,0,0,106,91.2a33.1,33.1,0,0,0-12.24,11.27,33.82,33.82,0,0,0-2.6,5.24,31.41,31.41,0,0,0-.37,20.3,30.56,30.56,0,0,0,4.08,8.18,36.9,36.9,0,0,0,8.75,8.61,31,31,0,0,0,20.83,5.05,28.85,28.85,0,0,0,15.25-6.34,48.84,48.84,0,0,0,5.06-5.09,29.24,29.24,0,0,0,5.76-11.87,29.55,29.55,0,0,0,.27-11,11.64,11.64,0,0,1,.14-4.78,8.64,8.64,0,0,1,4-4.88,10.42,10.42,0,0,1,2.71-.81,11.37,11.37,0,0,1,3.73.18,9.28,9.28,0,0,1,4.24,2.78c1.06,1.35,1.42,2.39,1.79,5.21a81.26,81.26,0,0,1-.12,15.62,44.56,44.56,0,0,1-12.58,23.72,44.84,44.84,0,0,1-23.14,13.06,41.61,41.61,0,0,1-10,1c-2.05,0-4.17,0-4.7-.05Z"
      fill="#fff"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M177,136a16.27,16.27,0,0,0-9.27-15.68,62.87,62.87,0,0,1-.44,8.57,44.56,44.56,0,0,1-12.58,23.72,44.84,44.84,0,0,1-23.14,13.06,41.61,41.61,0,0,1-10,1h0a15.91,15.91,0,0,0,12,9c8.7.3,17.42.05,26.13.11,8.32.94,16.4-5.72,17.21-14C177.07,153.18,176.91,144.57,177,136Z"
      fill="#2991de"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M73.21,110a43.87,43.87,0,0,1,9-20.58,54.7,54.7,0,0,1,8.29-8.33,43.06,43.06,0,0,1,24.11-9.65c.77-.06,2.46-.09,4.11-.09a16,16,0,0,0-11.84-9c-8.79-.24-17.63,0-26.43-.09-8-.87-15.78,5.47-16.94,13.4-.14,8.65,0,17.34-.06,26a16.08,16.08,0,0,0,9.12,16A53.32,53.32,0,0,1,73.21,110Z"
      fill="#2991de"
      transform="translate(-63.37 -62.22)"
    />
    <Path
      d="M116.89,166.57a45.67,45.67,0,0,1-28.06-12,47.6,47.6,0,0,1-14.7-23.64A53.81,53.81,0,0,1,73.21,110a43.87,43.87,0,0,1,9-20.58,54.7,54.7,0,0,1,8.29-8.33,43.06,43.06,0,0,1,24.11-9.65c1.44-.12,6.14-.12,7.66,0a38.24,38.24,0,0,1,6.49.94,7.79,7.79,0,0,1,4.74,4.29,7,7,0,0,1,.73,3.62,8.53,8.53,0,0,1-.62,3.57,8.11,8.11,0,0,1-4.14,4.07c-1.64.76-3,.89-5.6.52a31.86,31.86,0,0,0-6.68-.34A27.36,27.36,0,0,0,106,91.2a33.1,33.1,0,0,0-12.24,11.27,33.82,33.82,0,0,0-2.6,5.24,31.41,31.41,0,0,0-.37,20.3,30.56,30.56,0,0,0,4.08,8.18,36.9,36.9,0,0,0,8.75,8.61,31,31,0,0,0,20.83,5.05,28.85,28.85,0,0,0,15.25-6.34,48.84,48.84,0,0,0,5.06-5.09,29.24,29.24,0,0,0,5.76-11.87,29.55,29.55,0,0,0,.27-11,11.64,11.64,0,0,1,.14-4.78,8.64,8.64,0,0,1,4-4.88,10.42,10.42,0,0,1,2.71-.81,11.37,11.37,0,0,1,3.73.18,9.28,9.28,0,0,1,4.24,2.78c1.06,1.35,1.42,2.39,1.79,5.21a81.26,81.26,0,0,1-.12,15.62,44.56,44.56,0,0,1-12.58,23.72,44.84,44.84,0,0,1-23.14,13.06,41.61,41.61,0,0,1-10,1c-2.05,0-4.17,0-4.7-.05Z"
      fill="#fff"
      transform="translate(-63.37 -62.22)"
    />
  </Svg>
);

/**
 * @property {number} width The rendered width.
 * @property {number} height The rendered height.
 */
SvgLogo.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default SvgLogo;
