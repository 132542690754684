import { getAccountSettingsFormValuesFromUser } from 'app/components/MySettings/helpers';

import {
  AccountSettingsForm,
  AccountSettingsWrapper,
  FormWrapper,
  InputWrapper,
} from 'app/components/MySettings/styles';

export const AccountSettings = ({
  headerComponent,
  nextRoute,
  submitButtonText = 'Save',
  testIDRoot,
  enableStickyNavFooter = true,
}) => {
  return (
    <AccountSettingsForm
      accountSettingsWrapper={AccountSettingsWrapper}
      formWrapper={FormWrapper}
      getFormValuesFromUser={getAccountSettingsFormValuesFromUser}
      headerComponent={headerComponent}
      inputWrapper={InputWrapper}
      nextRoute={nextRoute}
      submitButtonText={submitButtonText}
      testIDRoot={testIDRoot}
      enableStickyNavFooter={enableStickyNavFooter}
    />
  );
};

export default AccountSettings;
