import React from 'react';

import StatusMessage from 'app/components/Common/StatusMessage';

interface StatusScreenProps {
  error?: boolean;
  loading?: boolean;
}

const StatusScreen = ({
  error = false,
  loading = false,
}: StatusScreenProps) => {
  if (loading) {
    return (
      <StatusMessage
        status="info"
        message="Finding the nearest available providers..."
      />
    );
  }

  if (error) {
    return (
      <StatusMessage
        status="error"
        message="There was an error fetching this resource."
        ctaPath="/"
        ctaText="Back to Dashboard"
      />
    );
  }
};

export default StatusScreen;
