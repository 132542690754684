import React, { Component } from 'react';

import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import AbsoluteFixedContainer from 'app/components/Common/Styled/AbsoluteFixedContainer';

/**
 * Implemented as the `ModalComponent` prop for the `<Overlay />` component
 * rendered through `react-native-elements`.
 *
 * See `app/util/theme` for more details.
 */
class WebOverlayWrapper extends Component {
  render() {
    if (!this.props.visible) return null;

    const content = (
      <AbsoluteFixedContainer fill>
        {this.props.children}
      </AbsoluteFixedContainer>
    );

    /**
     * Append this component to the end of the document body so that the fixed
     * positioning overlays the screen content.
     */
    return ReactDOM.createPortal(content, document.body);
  }
}

WebOverlayWrapper.propTypes = {
  children: PropTypes.node,
  visible: PropTypes.bool,
};

WebOverlayWrapper.defaultProps = {
  children: null,
  visible: false,
};

export default WebOverlayWrapper;
