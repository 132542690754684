import styled from 'styled-components/native';

import { ListItem, ListItemProps, TextProps } from 'react-native-elements';

interface SuggestionWrapperProps extends ListItemProps {
  hovered: boolean;
}

export const SuggestionWrapper = styled(ListItem).attrs<SuggestionWrapperProps>(
  ({ hovered, theme }) => ({
    containerStyle: {
      [hovered && 'backgroundColor']: theme.colors.backgroundLightBlue,
      height: theme.spacing * 3,
      padding: theme.spacing / 2,
      paddingLeft: theme.spacing,
    },
  })
)<SuggestionWrapperProps>``;

interface SuggestionTextProps extends TextProps {
  hovered: boolean;
}

export const SuggestionText = styled(ListItem.Title)<SuggestionTextProps>``;
