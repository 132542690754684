import { ProcedureDescriptionStyles as Styled } from 'app/components/ProcedureDescription/styles';
import { ProcedureDescriptionLoadingIndicatorProps } from 'app/components/ProcedureDescription/types';

const ProcedureDescriptionLoadingIndicator = ({
  isVisible,
  testID,
}: ProcedureDescriptionLoadingIndicatorProps) => {
  if (!isVisible) return;

  return <Styled.LoadingIndicator testID={testID} />;
};

export default ProcedureDescriptionLoadingIndicator; // NOTE: potential common component
