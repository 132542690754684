import { ProgressLinkStyles as Styled } from 'app/components/Common/ProgressNav/styles';

import {
  ProgressLinkProps,
  ProgressLinkStatus,
} from 'app/components/Common/ProgressNav/types';

export const ProgressLink = ({
  status = ProgressLinkStatus.incomplete,
  title = '',
}: ProgressLinkProps) => {
  const ProgressIcon = Styled.Icons[status];

  return (
    <Styled.Wrapper>
      <ProgressIcon size={32} />
      {/* @ts-expect-error No overload matches this call. */}
      <Styled.Text status={status}>{title}</Styled.Text>
    </Styled.Wrapper>
  );
};
