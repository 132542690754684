import { Component, EVENTS, Platform, PropTypes, TestID } from './constants';

import { logEvent, trimWhitespace } from './helpers';

import {
  CenteredScrollScreen,
  Header,
  HeaderContainer,
  Stepper,
} from './styles';

import StepOne from './StepOne';
import StepTwo from './StepTwo';
import Success from './Success';

export default class Register extends Component {
  static propTypes = {
    createCoreUser: PropTypes.func.isRequired,
    fetchReferralSources: PropTypes.func.isRequired,
    getUserEmployer: PropTypes.func.isRequired,
    receiveSessionError: PropTypes.func.isRequired,
    referralSources: PropTypes.array.isRequired,
    verifyEligiblePatient: PropTypes.func.isRequired,

    bypassSsnRegistration: PropTypes.bool,
    clientId: PropTypes.string,
    error: PropTypes.string,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    bypassSsnRegistration: false,
    clientId: '',
    error: '',
    loading: false,
  };

  state = {
    errors: {},
    formValues: {},
    success: false,
    step: 1,
  };

  componentDidMount = () => {
    this.getUserEmployer();
    this.props.receiveSessionError(null);
    this.props.fetchReferralSources();
  };

  /**
   * Fetch information about the user's employer before registration, then
   * prompt them for a Member ID or SSN as required. This is necessary because
   * some employers do not provide the SSN.
   */
  getUserEmployer = () => {
    const clientId = this.props.match?.params?.clientId;
    if (clientId) this.props.getUserEmployer(clientId);
    // response has boolean `attributes.contract.bypass-ssn-registration`
    // reflected via "this.props.bypassSsnRegistration" (from redux state)
  };

  /** Scroll to the bottom of the window. */
  scrollToBottom = () => {
    // eslint-disable-next-line no-undef
    if (window?.scrollTo && document?.body) {
      // eslint-disable-next-line no-undef
      return this.scrollTo(document.body.scrollHeight);
    }

    if (this.scrollView) this.scrollView.scrollToEnd({ animated: true });
  };

  /** Scroll to the top of the window. */
  scrollToTop = () => {
    // eslint-disable-next-line no-undef
    if (window?.scrollTo && document?.body) {
      // eslint-disable-next-line no-undef
      return this.scrollTo(0);
    }

    if (this.scrollView) this.scrollView.scrollToPosition(0, 0, true);
  };

  /** Scroll smoothly to the specified window position. */
  scrollTo = (position) => {
    // eslint-disable-next-line no-undef
    return window.scrollTo({ position, behavior: 'smooth' });
  };

  /** Ensure that current form values match an eligible patient record. */
  validateAndConfirmFormValues = async (formValues) => {
    formValues = trimWhitespace(formValues);

    const success = await this.props.verifyEligiblePatient(formValues);

    if (success === false) return this.scrollToBottom();

    logEvent(EVENTS.account.stepOneComplete);

    this.setState({ formValues, step: 2 }, this.scrollToTop);
  };

  /** Create new user from current form values and display success message. */
  createNewUser = async (formValues) => {
    formValues = trimWhitespace({
      ...this.state.formValues,
      ...formValues,
      acceptedMemberAgreement: true,
      acceptedTerms: true,
      hasMobileApp: Platform.OS !== 'web',
    });

    const success = await this.props.createCoreUser(formValues);

    if (success === false) return this.scrollToBottom();

    this.setState({ success: true, formValues }, this.scrollToTop);
  };

  render() {
    if (this.state.success)
      return <Success email={this.state.formValues.email} />;

    return (
      <CenteredScrollScreen
        ref={(ref) => {
          this.scrollView = ref;
        }}
        testID={TestID.Register.Page}
      >
        <Stepper
          step={this.state.step}
          steps={['Verify Eligibility', 'Create Account']}
        />

        <HeaderContainer>
          {this.state.step === 1 ? (
            <Header>Verify Eligibility</Header>
          ) : (
            <Header>Create Account</Header>
          )}
        </HeaderContainer>

        {this.state.step === 1 ? (
          <StepOne
            bypassSsnRegistration={this.props.bypassSsnRegistration}
            error={this.props.error}
            loading={this.props.loading}
            onSubmit={this.validateAndConfirmFormValues}
          />
        ) : (
          <StepTwo
            error={this.props.error}
            loading={this.props.loading}
            formFields={this.props.formFields}
            formValues={this.state.formValues}
            onSubmit={this.createNewUser}
            referralSources={this.props.referralSources}
            scrollToBottom={this.scrollToBottom}
          />
        )}
      </CenteredScrollScreen>
    );
  }
}
