import { toDateTimeString } from 'app/util/methods';

import ProcedureSearchPage from 'app/components/ProcedureSearch/ProcedureSearchPage';
import { Routes } from 'app/util/routes';
import Welcome from 'app/components/Welcome';

export { isWithinDateRangeOf } from 'app/util/methods';
export { logCampaignParams } from 'app/util/analytics';
export {
  receiveSetCallUsModalVisibility,
  setShouldRememberDevice,
  syncShouldRememberFromLocalStorage,
} from 'app/actions/uiActions';

const getGoLiveDate = (user) => {
  return user?.employer?.contract?.goLiveDate;
};

/**
 * Determine whether user employer data has been loaded, and with a future
 * go-live date set in the Care App.
 *
 * @param    {object}   user  user data currently in state
 *
 * @returns  {boolean}        whether go-live date is in the future
 */
const goLiveDateIsInFuture = (user) => {
  const goLiveDate = getGoLiveDate(user);
  const dateIsSetOnContract = goLiveDate !== undefined && goLiveDate.length > 0;
  const dateString = toDateTimeString(goLiveDate);
  const timeStamp = Date.parse(dateString);
  const dateIsInFuture = timeStamp > Date.now();

  return dateIsSetOnContract && dateIsInFuture;
};

/**
 * Determine whether user employer data has been loaded, and with no go-live
 * date set in the Care App.
 *
 * @param    {object}   user  user data currently in state
 *
 * @returns  {boolean}        whether go-live date has been set in Care App
 */
const goLiveDateIsNotSetOnContract = (user) => {
  const employerIsDefined = user?.employer !== undefined;
  const goLiveDate = getGoLiveDate(user);
  const dateIsNotSet = goLiveDate === undefined || goLiveDate.length === 0;

  return employerIsDefined && dateIsNotSet;
};

const getGoLiveDateQueryString = (user) =>
  getGoLiveDate(user).replaceAll('/', '_');

/**
 * Return the proper component for the "Home" (`/`) route as follows:
 *
 * 1. If necessary, redirect users to block access when the go-live date of
 *    their employer's contract is (a) not set or (b) in the future.
 * 2. If possible, redirect users to the next route as determined by their
 *    current journey phase.
 * 3. Return the "Procedure Search" page for authenticated users.
 * 4. Otherwise, return the (public) "Welcome" page for unknown guests.
 *
 * > Note: Options 1 and 2 use a helper method to return the `<Redirect>`
 * >       component that is parameterized as required.
 *
 * @param   {boolean}   loggedIn   whether the user is logged in
 * @param   {string}    nextRoute  next route in the patient's journey
 * @param   {function}  redirect   function to return a React Router
 *                                 `<Redirect>` component
 * @param   {object}    user       details for current user
 *
 * @return  {function}
 */
export const getHomeComponent = ({ loggedIn, nextRoute, redirect, user }) => {
  if (goLiveDateIsNotSetOnContract(user)) {
    return redirect(`/${Routes.NotAvailable}`);
  } else if (goLiveDateIsInFuture(user)) {
    return redirect(
      `/${Routes.NotAvailable}?until=${getGoLiveDateQueryString(user)}`
    );
  } else if (nextRoute && nextRoute !== '/') {
    return redirect(nextRoute);
  } else if (loggedIn) {
    return ProcedureSearchPage;
  } else {
    return Welcome;
  }
};
