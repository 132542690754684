import { ReactNode } from 'react';
import { ScrollView } from 'react-native';

import { Text } from 'react-native-elements';
import styled from 'styled-components/native';

import { View } from 'app/components/Common/Styled';

export const LearnMoreText = styled(Text)`
  color: ${({ theme }) => theme.colors.bodyText};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.body2}px;
  margin-horizontal: 0;
  margin-top: ${({ theme }) => theme.spacing * 2}px;
  text-align: center;
`;

export const ScrollWrapper = styled(ScrollView).attrs(({ theme }) => {
  const { breakpoints, spacing, windowDimensions } = theme;
  const { width: screenWidth } = windowDimensions;

  const isMobile = screenWidth < breakpoints.small;

  return {
    contentContainerStyle: {
      flexShrink: 0,
      paddingBottom: isMobile ? spacing * 1.25 : 0,
    },
  };
})``;

// Column Title Styles

export const TabText = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamilyBold};
  font-size: ${({ theme }) => theme.fontSizes.small}px;
  text-align: center;
`;

const ColoredBackgroundText = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamilyMedium};
  font-size: ${({ theme }) => theme.fontSizes.body3}px;
  text-align: center;
`;

const ColoredBackgroundView = styled(View)<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor, theme }) =>
    theme.colors[backgroundColor]};
  border-radius: 4px;
  padding: 0 2px;
`;

interface ColoredBackgroundProps {
  backgroundColor: string;
  children: ReactNode;
}
const ColoredBackground = ({
  backgroundColor,
  children,
}: ColoredBackgroundProps) => (
  <ColoredBackgroundView backgroundColor={backgroundColor}>
    <ColoredBackgroundText>{children}</ColoredBackgroundText>
  </ColoredBackgroundView>
);

export const GoldBackground = ({ children }) => (
  <ColoredBackground backgroundColor="gold">{children}</ColoredBackground>
);
export const SilverBackground = ({ children }) => (
  <ColoredBackground backgroundColor="silver">{children}</ColoredBackground>
);

export const TabRow = styled(View)`
  flex-direction: row;
`;

export const TabWrapper = styled(View)`
  align-items: center;
  justify-content: flex-end;
`;
