/**
 * Returns the next incomplete step for the given journey phase,
 * or the last step in the phase if all are complete.
 */
export const getCurrentStepForJouneyPhase = (journeyPhase) => {
  if (!journeyPhase?.steps) return;

  const { steps } = journeyPhase;

  return steps.find(({ complete }) => !complete) || steps[steps.length - 1];
};

/**
 * Finds a Journey Phase with a code that matches the current pathname.
 */
export const getJourneyPhaseForPath = (journeyPhases, pathname) => {
  return journeyPhases?.find(({ code }) => pathname.startsWith(`/${code}`));
};

/**
 * Returns true if there is a current episode with steps, and all
 * steps have been marked complete.
 */
export const getIsJourneyComplete = (episode, steps) => {
  return (
    !!episode && steps.length > 0 && !steps.find(({ complete }) => !complete)
  );
};
