import React, { useEffect, useMemo, useState } from 'react';
import { useWindowDimensions } from 'react-native';

import { useRouteMatch } from '@cross-platform/react-router-native';
import { flow, map } from 'lodash/fp';

import { useEpisode, usePossibleHospitals, useSession } from 'app/hooks';
import { TestID } from 'app/util/test-id';

import { OptionsWrapper, PageWrapper, RiskWarningModal } from './styles';

import ExtendedOptionsModal from '../ExtendedOptionsModal';
import ExtendedOptionsNotification from '../ExtendedOptionsNotification';
import MapView from '../MapView';
import OptionsSection from '../OptionsSection';
import { filterOptionByTier } from '../helpers';

enum SelectionsSlugs {
  Doctors = 'doctors',
  Facilities = 'facilities',
}

const getRouteName = (match): SelectionsSlugs => {
  return match.path?.split('/').pop();
};

const FacilitiesSelection = () => {
  const [isReady, setIsReady] = useState(false);
  const [isExtendedOptionsModalVisible, setIsExtendedOptionsModalVisible] =
    useState(false);
  const { width } = useWindowDimensions();
  const match = useRouteMatch();
  const [slug, setSlug] = useState(getRouteName(match));

  const { loading, episode, tempCoeSelection } = useEpisode();
  const { user } = useSession();
  const {
    data: { records: hospitals },
  } = usePossibleHospitals();

  /**
   * Determine if we're on the "/doctors" vs "/facilities" route
   * when the navigation state changes.
   */
  useEffect(() => {
    setSlug(getRouteName(match));
  }, [match]);

  /**
   * Wait for the episode to load initially before attempting to
   * render sub-components.
   */
  useEffect(() => {
    if (isReady) return;
    if (!episode?.id || loading) return;

    setIsReady(true);
  }, [episode?.id, loading]);

  const facilitiesCoords = useMemo(() => {
    return flow([
      map(({ id, latitude, longitude, productOffering: { code } }) => ({
        id,
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
        isSilver: code === 'silver',
      })),
    ])(hospitals);
  }, [hospitals]);

  // Returns true if there are any silver providers available.
  const shouldShowExtendedNotification =
    facilitiesCoords.some((f) => f.isSilver) &&
    tempCoeSelection.productOffering.code === 'gold';
  const filteredMarkers = facilitiesCoords.filter((marker) =>
    filterOptionByTier(marker, tempCoeSelection.productOffering.code)
  );

  return (
    <>
      <ExtendedOptionsModal
        isVisible={isExtendedOptionsModalVisible}
        onClose={() => setIsExtendedOptionsModalVisible(false)}
      />
      <ExtendedOptionsNotification
        displayExtendedOptionsModal={() =>
          setIsExtendedOptionsModalVisible(true)
        }
        isVisible={shouldShowExtendedNotification}
      />

      {isReady && (
        <PageWrapper screenWidth={width} testID={TestID.SelectYourDoctor.Page}>
          <OptionsWrapper screenWidth={width}>
            <OptionsSection type={slug} />
          </OptionsWrapper>
          <MapView markers={filteredMarkers} />
        </PageWrapper>
      )}

      <RiskWarningModal userProfile={user.profile} />
    </>
  );
};

export default FacilitiesSelection;
