import { TestID } from 'app/components/Messages/constants';

import { InputFieldStyles as Styled } from 'app/components/Messages/styles';

import { InputFieldProps } from 'app/components/Messages/types';

export const InputField = ({
  formValues,
  sendMessage,
  setFormValues,
  submitting,
}: InputFieldProps) => {
  return (
    <Styled.Wrapper>
      <Styled.Field
        placeholder="Type a message..."
        blurOnSubmit={false}
        disabled={!sendMessage}
        input={{
          onChange: (body) => setFormValues({ ...formValues, body }),
          value: formValues.body,
          name: TestID.Messages.TextArea,
        }}
        name="body"
      />

      <Styled.SendButton
        onlyNarrow
        title="Send"
        disabled={submitting}
        onPress={sendMessage}
        testID={TestID.Messages.SendButton}
      />
    </Styled.Wrapper>
  );
};
