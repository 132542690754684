import {
  breakpoints,
  Routes,
  TestID,
} from 'app/components/App/Navigation/constants';

import { extractBranding } from 'app/components/App/Navigation/helpers';

import {
  useHistory,
  useSession,
  useWindowDimensions,
} from 'app/components/App/Navigation/hooks';

import { CarrumLogoStyles as Styled } from 'app/components/App/Navigation/styles';

/**
 * Render the Carrum Health logo responsively.
 *
 * - Use the narrow version when the screen is narrow and client branding is
 *   present.
 * - Use the wide version otherwise.
 */
export const CarrumLogo = () => {
  const history = useHistory();
  const session = useSession();
  const isNarrow = useWindowDimensions().width <= breakpoints.small;

  const hasClientLogo = !!extractBranding(session);
  const carrumLogoIsNarrow = isNarrow && hasClientLogo;
  const testKey = `CarrumLogo${carrumLogoIsNarrow ? 'Narrow' : 'Wide'}`;

  return (
    <Styled.Wrapper
      onPress={() => history.push(Routes.Home)}
      testID={TestID.Navigation[testKey]}
    >
      {carrumLogoIsNarrow ? <Styled.Logo.Narrow /> : <Styled.Logo.Wide />}
    </Styled.Wrapper>
  );
};
