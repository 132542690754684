import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { useHistory } from '@cross-platform/react-router-native';

import Anchor from '../Anchor';

interface GoBackLinkProps {
  onPress?: () => void;
  title?: string;
  titleStyle?: StyleProp<ViewStyle>;
}

export const GoBackLink = ({ title, titleStyle, onPress }: GoBackLinkProps) => {
  const history = useHistory();

  return (
    <Anchor
      title={title}
      titleStyle={titleStyle}
      onPress={onPress || history.goBack}
    />
  );
};

GoBackLink.defaultProps = {
  title: 'Go back',
};
