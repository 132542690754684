import { useHistory, useParams } from './hooks';

import { Routes, TestID } from './constants';

import {
  CreateAccountButton,
  CreateAccountButtonContainer,
  Container,
  Fade,
  FaqLink,
  LoginButton,
  LoginButtonContainer,
  LogoContainer,
  WelcomeLogo,
} from './styles';

const Welcome = () => {
  const { clientId } = useParams();
  const { push: goTo } = useHistory();

  const goToRegisterScreen = () => {
    goTo(`${clientId ? `${clientId}/` : ''}${Routes.Register}`);
  };

  return (
    <Container testID="Welcome">
      <Fade in delay={300}>
        <LogoContainer>
          <WelcomeLogo />
        </LogoContainer>
        <CreateAccountButtonContainer>
          <CreateAccountButton
            title="Create Account"
            onPress={goToRegisterScreen}
            testID={TestID.Welcome.CreateAccountButton}
          />
        </CreateAccountButtonContainer>
        <LoginButtonContainer>
          <LoginButton
            title="Log In"
            onPress={() => goTo(`/${Routes.Login}`)}
            testID={TestID.Welcome.LoginButton}
          />
        </LoginButtonContainer>
        <FaqLink onPress={() => goTo(`${clientId}/${Routes.LearnMore}`)}>
          Have Questions? Click Here
        </FaqLink>
      </Fade>
    </Container>
  );
};

export default Welcome;
