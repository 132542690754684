import React from 'react';
import { useWindowDimensions } from 'react-native';

import theme from 'app/util/theme';

import Table from './ExperienceTable';
import Tabs from './ExperienceTabs';

interface CarrumSilverExperienceTableProps {
  onClose?: () => void;
  onShowSilver?: () => void;
  showButtons?: boolean;
}

const CarrumSilverExperienceTable = ({
  onClose = () => {},
  onShowSilver = () => {},
  showButtons = false,
}: CarrumSilverExperienceTableProps) => {
  const { height: screenHeight, width: screenWidth } = useWindowDimensions();

  const isMobile = screenWidth < theme.breakpoints.small;
  const isShortScreen = screenHeight < theme.breakpoints.shortScreen;
  const isNarrowOrShort = isMobile || isShortScreen;

  const overlayStyleOverrides = {
    height: isNarrowOrShort ? screenHeight : 'auto',
    justifyContent: 'space-between',
    marginBottom: 0,
  };

  // Remove padding around Benefits Tabs
  if (isNarrowOrShort) {
    // @ts-ignore
    overlayStyleOverrides.paddingRight = 0;
    // @ts-ignore
    overlayStyleOverrides.paddingBottom = 0;
    // @ts-ignore
    overlayStyleOverrides.paddingLeft = 0;
  }

  return (
    <>
      {isNarrowOrShort ? (
        <Tabs />
      ) : (
        <Table
          onClose={onClose}
          onShowSilver={onShowSilver}
          showButtons={showButtons}
        />
      )}
    </>
  );
};

export default CarrumSilverExperienceTable;
