import { Text } from 'react-native-elements';
import styled from 'styled-components/native';
import { FontSizes, Palette } from 'types/styled';

interface TextProps {
  align?: 'left' | 'center' | 'right';
  bold?: boolean;
  color?: keyof Palette;
  medium?: boolean;
  size?: keyof FontSizes;
}

export default styled(Text)<TextProps>`
  color: ${({ theme, color = 'bodyText' }) => theme.colors[color]};
  font-family: ${({ theme, bold = false, medium = false }) => {
    if (bold) return theme.fontFamilyBold;
    if (medium) return theme.fontFamilyMedium;
    return theme.fontFamily;
  }};
  font-size: ${({ theme, size = 'body3' }) => theme.fontSizes[size]}px;
  text-align: ${({ align = 'left' }) => align};
`;
