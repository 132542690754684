import { defaultFormState } from 'app/components/Login/constants';

import {
  goToEmailForm,
  receiveSessionError,
} from 'app/components/Login/helpers';

import { useDispatch, useEffect, useHistory } from 'app/components/Login/hooks';

import { MagicLinkConfirmationStyles as Styled } from 'app/components/Login/styles';

import { MagicLinkConfirmationProps } from 'app/components/Login/types';

const MagicLinkConfirmation = ({
  formValues = {},
  isPasswordReset = false,
  setFormValues,
  setLoginStep,
}: MagicLinkConfirmationProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const email = formValues?.email;
  const message = isPasswordReset
    ? ` to reset your password.`
    : `\u2014without a password!`;
  // ^ NOTE: `\u2014` is unicode for an em dash

  useEffect(() => {
    if (!email) history.replace('/login');
  }, [email, history]);

  return (
    <Styled.Wrapper>
      <Styled.ImageWrapper>
        <Styled.Image />
      </Styled.ImageWrapper>

      <Styled.Text>
        Check your email at <Styled.Bold>{email}</Styled.Bold> for a link that
        will log you in instantly{message}
      </Styled.Text>

      <Styled.Text>
        <Styled.Anchor
          title="Wrong email?"
          onPress={() =>
            goToEmailForm({
              defaultFormState,
              dispatch,
              formValues,
              receiveSessionError,
              setFormValues,
              setLoginStep,
            })
          }
        />
      </Styled.Text>
    </Styled.Wrapper>
  );
};

export default MagicLinkConfirmation;
