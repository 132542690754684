import {
  RECEIVE_CORE_LOGIN,
  RECEIVE_LOADING,
  RECEIVE_LOGOUT,
  RECEIVE_PERSONALIZATION_DETAILS,
  RECEIVE_SESSION_ERROR,
  RECEIVE_USED_MAGIC_LINK,
} from 'app/actions/sessionActions';

import {
  RECEIVE_CURRENT_USER,
  RECEIVE_USER_LOCATION,
  RECEIVE_USER_EMPLOYER,
  RECEIVE_USER_INSURER,
} from 'app/actions/userActions';

import { formatData } from 'app/util/reducerUtils';

const nullState = Object.freeze({
  loading: false,
  coreToken: null,
  features: [],
  user: {},
  error: null,
  override: false,
  personalizationDetails: {},
  usedMagicLink: false,
});

export { nullState };

const sessionReducer = (state = nullState, action) => {
  switch (action.type) {
    case RECEIVE_CORE_LOGIN:
      return {
        ...state,
        coreToken: action.token,
        override: action.override,
        error: null,
      };
    case RECEIVE_CURRENT_USER: {
      const user = formatData(action.user);
      const features = action.features || state.features;

      return {
        ...state,
        features,
        user: {
          ...user,
          employer: user.employer || state.user.employer,
          insurer: user.insurer || state.user.insurer,
          location: user.location || state.user.location,
        },
      };
    }
    case RECEIVE_USER_LOCATION:
      return { ...state, user: { ...state.user, location: action.location } };
    case RECEIVE_USER_EMPLOYER:
      return {
        ...state,
        user: { ...state.user, employer: formatData(action.employer) },
      };
    case RECEIVE_USER_INSURER:
      return {
        ...state,
        user: { ...state.user, insurer: formatData(action.insurer) },
      };
    case RECEIVE_SESSION_ERROR:
      return { ...state, error: action.error, loading: false };
    case RECEIVE_LOADING:
      return { ...state, loading: action.loading };
    case RECEIVE_LOGOUT:
      return action.hard
        ? nullState
        : { ...state, coreToken: null, error: null };
    case RECEIVE_USED_MAGIC_LINK:
      return { ...state, usedMagicLink: action.usedMagicLink };
    case RECEIVE_PERSONALIZATION_DETAILS:
      return {
        ...state,
        personalizationDetails: formatData(action.personalizationDetails),
      };
    default:
      return state;
  }
};

export default sessionReducer;
