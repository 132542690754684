import { breakpoints, TestID } from 'app/components/App/Navigation/constants';

import {
  getIsLoggedIn,
  setProfileMenuOpen,
} from 'app/components/App/Navigation/helpers';

import {
  useDispatch,
  useSelector,
  useWindowDimensions,
  useUi,
} from 'app/components/App/Navigation/hooks';

import { ProfileMenuToggleStyles as Styled } from 'app/components/App/Navigation/styles';

import { State } from 'app/components/App/Navigation/types';

export const Toggle = () => {
  const dispatch = useDispatch();
  const hasTopNavLinks = useWindowDimensions().width > breakpoints.small;
  const isLoggedIn = useSelector((state: State.RootState) =>
    getIsLoggedIn(state.session)
  );
  const { profileMenuOpen: profileMenuIsOpen } = useUi();

  if (!isLoggedIn || !hasTopNavLinks) return null;

  return (
    <Styled.Wrapper
      onBlur={() => {
        dispatch(setProfileMenuOpen(false));
      }}
      onFocus={() => {
        dispatch(setProfileMenuOpen(!profileMenuIsOpen));
      }}
      testID={TestID.Navigation.ProfileMenuToggleContainer}
    >
      <Styled.Avatar />

      <Styled.Icon isOpen={profileMenuIsOpen} />
    </Styled.Wrapper>
  );
};
