import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Path } from 'react-native-svg';

/**
 * Renders the Carrum Health logo as an SVG.
 */
const RedCLogo = ({ height = 28, width = 28 }) => (
  <Svg height={height} version="1.1" viewBox="0 0 149.95 149.95" width={width}>
    <G>
      <G>
        <Path
          fill="#a53723"
          d="M26.64,74.98c0-26.66,21.69-48.34,48.34-48.34c9.71,0,19.08,2.87,27.08,8.29c6.09,4.13,14.37,2.54,18.5-3.55
			s2.53-14.37-3.55-18.5C104.57,4.45,90.04,0,74.98,0C33.63,0,0,33.63,0,74.98c0,9.5,1.77,18.73,5.22,27.49l24.59-10.25
			C27.71,86.72,26.64,80.93,26.64,74.98z"
        />
        <Path
          fill="#ef7136"
          d="M74.98,149.95c17.18,0,33.97-5.96,47.28-16.79c13.11-10.66,22.32-25.58,25.94-42
			c1.58-7.18-2.96-14.29-10.14-15.87c-7.19-1.59-14.29,2.96-15.87,10.14c-4.84,21.95-24.69,37.88-47.21,37.88
			c-19.62,0-37.16-11.71-44.68-29.84c-0.17-0.42-0.33-0.85-0.49-1.27L5.22,102.46c0.16,0.41,0.31,0.82,0.47,1.23
			C17.36,131.79,44.55,149.95,74.98,149.95z"
        />
      </G>
    </G>
  </Svg>
);

/**
 * @property {number} height The rendered height.
 * @property {number} width The rendered width.
 */
RedCLogo.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default RedCLogo;
