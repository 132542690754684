import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Image, View } from 'react-native';

import brokenLinkIcon from 'app/assets/images/broken-link-icon.png';

/**
 * Renders an image with a max height, allowing it to
 * be positioned vertically in the UI.
 *
 * @example
 * <SizedImage
 *   maxHeight={60}
 *   source="my-image.jpg"
 * />
 */
export default class SizedImage extends Component {
  static propTypes = {
    source: PropTypes.string.isRequired,
    maxHeight: PropTypes.number.isRequired,
    containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    imageStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  };

  state = {
    width: undefined,
    height: undefined,
    error: false,
  };

  componentDidMount() {
    Image.getSize(this.props.source, this.onSizeLoad, this.onSizeError);
  }

  onSizeLoad = (width, height) => {
    this.setState({ width, height });
  };

  onSizeError = () => {
    this.setState({
      height: this.props.maxHeight,
      width: this.props.maxHeight,
      error: true,
    });
  };

  render() {
    let { error, height, width } = this.state;
    const ratio = width / height;

    if (height && height > this.props.maxHeight) {
      height = this.props.maxHeight;
      width = ratio * height;
    }

    return (
      <View style={[{ overflow: 'hidden' }, this.props.containerStyle]}>
        <Image
          source={error ? brokenLinkIcon : { uri: this.props.source }}
          style={[
            { width, height, resizeMode: 'contain' },
            this.props.imageStyle,
          ]}
        />
      </View>
    );
  }
}
