import styled from 'styled-components/native';

import { ResponsivePressable } from 'app/components/Common/ResponsivePressable';
import { Text, View } from 'app/components/Common/Styled';
import {
  CheckCircle as CheckCircleRaw,
  InfoIcon as InfoIconRaw,
  InfoIconProps,
} from 'app/components/Common/svg-components';
import theme from 'app/util/theme';

export const iconConfig = {
  size: theme.spacing,
  style: {
    marginRight: theme.spacing / 2,
  },
};

export const DetailsRow = styled(View)`
  align-items: center;
  flex-direction: row;
  margin: 3.5px 0;
`;

interface CheckCircleProps {
  isSilver: boolean;
}

export const CheckCircle = styled(CheckCircleRaw).attrs<CheckCircleProps>(
  ({ theme, isSilver }) => ({
    color: isSilver ? theme.colors.silver : theme.colors.gold,
  })
)<CheckCircleProps>``;

export const DetailsText = styled(Text)`
  color: ${({ theme }) => theme.colors.bodyText};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.body3}px;
`;

// Extend/override DetailsText
export const ExperienceText = styled(DetailsText)<{ isSilver: boolean }>`
  color: ${({ isSilver, theme }) => (isSilver ? '#8b8d8b' : theme.colors.gold)};
`;

// Prevent Icons from shrinking next to varied text lengths
export const IconWrapper = styled(View)`
  flex: 0 0 auto;
`;

export const InfoIconWrapper = styled(ResponsivePressable)`
  margin-left: ${({ theme }) => theme.spacing / 2}px;
`;

export const InfoIcon = styled(InfoIconRaw).attrs<InfoIconProps>(
  ({ theme }) => ({
    color: theme.colors.default,
    size: 12,
  })
)``;
