import styled, { css } from 'styled-components/native';

import { checkMarkGreen } from 'app/components/Common/svg-components/CheckMark';
import { CheckMark } from 'app/components/Common/svg-components';
import { Fragment } from 'react';
import { GooglePlacesInput } from 'app/components/Common/GooglePlacesInput';
import Modal from 'app/components/Common/Modal';
import { Platform } from 'react-native';
import { default as RadioGroup } from 'app/components/Common/RadioGroup';
import { ScrollView } from 'app/components/Common/Styled';
import { Text } from 'app/components/Common/Styled';
import TextArea from 'app/components/Common/TextArea';
import { View } from 'app/components/Common/Styled';

export const LetUsKnowStyles = {
  ConfirmationMessage: {
    Image: CheckMark,
    Text: styled(Text).attrs({
      h3: true,
      h3Style: {
        color: checkMarkGreen,
      },
    })`
      margin-top: ${({ theme }) => theme.spacing}px;
      text-align: center;
    `,
    Wrapper: styled(View)`
      ${({ theme }) => css`
        align-items: center;
        display: flex;
        margin-bottom: ${theme.spacing * 3}px;
        margin-top: ${theme.spacing * 3}px;
      `}
    `,
  },

  Container: Platform.select({
    // @ts-expect-error No overload matches this call. 'React.JSX.Element' is not assignable
    default: ScrollView,
    web: View,
  }),

  Form: {
    AddressInput: {
      Field: GooglePlacesInput,
      Wrapper: styled(View)`
        flex: 1 0 auto;
        margin-bottom: ${({ theme }) => theme.spacing}px;
      `,
    },

    ContactMethodInput: RadioGroup,

    InquiryInput: {
      Field: TextArea,
      Wrapper: styled(View)`
        ${({ theme }) => css`
          background-color: ${theme.colors.sand};
          margin-bottom: ${theme.spacing}px;
        `}
      `,
    },

    Wrapper: Fragment,
  },

  GuidanceText: {
    Text: styled(Text)`
      ${({ theme }) => css`
        color: ${theme.colors.bodyText};
        font-family: ${theme.fontFamily};
        font-size: ${theme.fontSizes.body2}px;
      `}
    `,
    Wrapper: styled(View)`
      text-align: left;
      margin-bottom: ${({ theme }) => theme.spacing}px;
    `,
  },

  Modal: styled(Modal).attrs(({ theme }) => ({
    overlayStyle: {
      backgroundColor: theme.colors.sand,
      maxWidth: theme.spacing * 48,
      minWidth: theme.spacing * 23,
      minHeight: theme.spacing * 39,
      paddingLeft: theme.spacing,
      paddingRight: theme.spacing,
      width: '90%',
    },
  }))``,

  Wrapper: View,
};
