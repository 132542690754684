interface Location {
  lat: number;
  lng: number;
}

const travelDurations = {};
const travelDurationPromises = {};

/**
 * Get a string representing a time comparison between now and a prior time. The difference is rounded up by the unit: mins, hours, days.
 *
 * @param {number} durationInSeconds Duration provided by Google in seconds.
 * @returns A string escalating from mins to hours to days depending on the length of time since an event.
 */
export const formatDurationString = (durationInSeconds) => {
  const minuteDivisor = 60;
  const hourDivisor = 60 * 60;

  const ageInMinutes = Math.floor(durationInSeconds / minuteDivisor);
  if (ageInMinutes < 60) return `${ageInMinutes} min`;

  const ageInHours = Math.floor(durationInSeconds / hourDivisor);
  const extraMinutes = ageInMinutes % 60;

  return `${ageInHours} hr${` and ${extraMinutes && extraMinutes} min`}`;
};

/**
 * Generates a string representing a time comparison between now and a prior time.
 * The difference is rounded up by the unit: mins, hours, days.
 *
 * NOTE: The result is cached locally, allowing multiple consumers to get the
 *       travel duration for the same location without making extra API calls.
 *
 * @param {object} episodeLocation Location set on Episode
 * @param {object} destination Location of the destination facility
 * @returns A Promise with a string escalating from mins to hours to days depending on the length of time since an event.
 */
export const getTravelDuration = (
  episodeLocation: Location,
  destination: Location
): Promise<string> => {
  const travelDurationKey = formatTravelDurationKey(
    episodeLocation,
    destination
  );

  if (travelDurations[travelDurationKey])
    return Promise.resolve(travelDurations[travelDurationKey]);

  if (travelDurationPromises[travelDurationKey])
    return travelDurationPromises[travelDurationKey];

  return (travelDurationPromises[travelDurationKey] = fetchTravelDuration(
    episodeLocation,
    destination
  ));
};

const fetchTravelDuration = (
  episodeLocation: Location,
  destination: Location
): Promise<string> => {
  // TODO: investigate including the global `google` var on mobile
  const directionsService = new google.maps.DirectionsService();
  const travelDurationKey = formatTravelDurationKey(
    episodeLocation,
    destination
  );

  return directionsService
    .route({
      origin: { lat: episodeLocation.lat, lng: episodeLocation.lng },
      destination: {
        lat: destination.lat,
        lng: destination.lng,
      },
      travelMode: google.maps.TravelMode.DRIVING,
    })
    .then((response) => {
      const result = `${formatDurationString(
        response.routes[0].legs[0].duration.value
      )} drive`;

      return (travelDurations[travelDurationKey] = result);
    })
    .catch((err) => {
      console.error({ err });
      return 'Error';
    });
};

const formatTravelDurationKey = (
  episodeLocation: Location,
  destination: Location
): string => {
  return `${episodeLocation.lat},${episodeLocation.lng}:${destination.lat},${destination.lng}`;
};
