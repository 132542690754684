import styled from 'styled-components/native';

import { Text, View } from 'app/components/Common/Styled';
import theme from 'app/util/theme';

export const NumberBadgeText = styled(Text)`
  color: ${theme.colors.white};
`;

const numberBadgeSize = 30;

export const NumberBadgeWrapper = styled(View)`
  align-items: center;
  background: ${theme.colors.lightBlue};
  border-radius: ${numberBadgeSize / 2}px;
  display: flex;
  height: ${numberBadgeSize}px;
  justify-content: center;
  width: ${numberBadgeSize}px;
`;
