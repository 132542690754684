import { Routes, TestID } from 'app/components/ProcedureDescription/constants';

import {
  createEpisodeFromState,
  routeTo,
} from 'app/components/ProcedureDescription/helpers';

import {
  useDispatch,
  useHistory,
  useSelector,
  useState,
} from 'app/components/ProcedureDescription/hooks';

import {
  NavigationConfig,
  NavigationMode,
  ProcedureDescriptionNavFooterProps,
  State,
} from 'app/components/ProcedureDescription/types';

import { ProcedureDescriptionFooterStyles as Styled } from 'app/components/ProcedureDescription/styles';

const ProcedureDescriptionNavFooter = ({
  episodeIsAlreadyCreated,
  id,
  isVisible,
  procedure,
}: ProcedureDescriptionNavFooterProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const employer = useSelector(
    (state: State.RootState) => state.session?.user?.employer
  );
  const [isCreating, setIsCreating] = useState(false);

  if (!isVisible) return;

  const createEpisodeAndProceed = () => {
    setIsCreating(true);
    dispatch(
      createEpisodeFromState({
        procedure,
      })
    );
    history.push(`/${Routes.Procedures}/${id}/confirmation`);
  };

  // Find external partnerships for employer based on the procedure category.
  const nonSurgicalPartnership = employer?.partnerships.find(
    ({ procedureCategory }) => procedureCategory === procedure?.category
  );

  const goToNonSurgicalOption = () => {
    routeTo(nonSurgicalPartnership.url);
  };

  /**
   * Show an additional CTA if there is a matching partnership with a
   * CTA defined and an episode has not already been created.
   */
  const showNonSurgicalOption =
    !episodeIsAlreadyCreated && !!nonSurgicalPartnership?.url?.length;

  const navigationConfig: NavigationConfig[] = [];

  if (showNonSurgicalOption) {
    navigationConfig.push({
      disabled: isCreating,
      onPress: goToNonSurgicalOption,
      testID: TestID.ProcedureDescription.NonSurgicalButton,
      text: 'Explore Non-Surgical Options',
    });
  }

  navigationConfig.push({
    disabled: isCreating,
    onPress: episodeIsAlreadyCreated
      ? () => history.push('/')
      : createEpisodeAndProceed,
    testID: TestID.ProcedureDescription.NavFooterNextButton,
    text: 'Continue',
  });

  return (
    <Styled.NavFooter
      navigationConfig={navigationConfig}
      navigationMode={
        showNonSurgicalOption
          ? NavigationMode.DoubleButton
          : NavigationMode.Centered
      }
      testID={TestID.ProcedureDescription.NavFooter}
    />
  );
};

export default ProcedureDescriptionNavFooter; // NOTE: distinct sub-component
