import React from 'react';
import { useWindowDimensions } from 'react-native';

import Modal from 'app/components/Common/Modal';
import { Text } from 'app/components/Common/Styled';
import CarrumGoldExperienceBenefits from 'app/components/Common/CarrumGoldExperienceBenefits';
import Anchor from 'app/components/Common/Anchor';
import { Routes } from 'app/util/routes';
import breakpoints from 'app/util/breakpoints';

import { DescriptionWrapper, LearnMoreText, LearnMoreWrapper } from './styles';

interface GoldExperienceModalProps {
  isVisible: boolean;
  onClose: () => void;
}

export const GoldExperienceModal = ({
  isVisible,
  onClose,
}: GoldExperienceModalProps) => {
  const isNarrow = useWindowDimensions().width <= breakpoints.xsmall;

  return (
    <Modal
      fullScreen={isNarrow}
      isVisible={isVisible}
      onClose={onClose}
      options={[]}
      showCloseX
      size="large"
      title="What is Carrum Gold Experience?"
    >
      <DescriptionWrapper>
        <Text>
          We partner with only the top 10% of doctors and facilities across the
          nation to provide a premium patient experience with no surprise bills.
        </Text>
      </DescriptionWrapper>

      <CarrumGoldExperienceBenefits disabled />

      <LearnMoreWrapper>
        <LearnMoreText>
          Learn more in our <Anchor title="FAQs" to={`/${Routes.LearnMore}`} />
        </LearnMoreText>
      </LearnMoreWrapper>
    </Modal>
  );
};
