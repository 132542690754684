import { Image, View } from 'react-native';
import styled from 'styled-components/native';

import Text from 'app/components/Common/Styled/Text';

export const Container = styled.View`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-color: ${({ theme }) => theme.colors.default};
  border-radius: 2px;
  border-width: 1px;
  flex: 1 0 auto;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing / 2}px;
  margin-bottom: 0px;
  padding: ${({ theme }) => theme.spacing}px;
`;

export const Icon = styled(Image)`
  height: 100px;
  width: 100px;
`;

export const NumberLabel = styled(Text).attrs({
  bold: true,
  size: 'body2',
})`
  color: #fff;
  line-height: ${({ theme }) => theme.spacing * 2}px;
  text-align: center;
`;
export const NumberLabelContainer = styled.View`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bodyText};
  border-radius: 24px;
  margin-right: ${({ theme }) => theme.spacing / 2}px;
  height: ${({ theme }) => theme.spacing * 2}px;
  width: ${({ theme }) => theme.spacing * 2}px;
`;

export const TitleContainer = styled(View)`
  max-width: 90%;
  flex-direction: row;
  align-items: center;
  margin-vertical: ${({ theme }) => theme.spacing}px;
`;
