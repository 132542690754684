import { TestID } from 'app/components/Messages/constants';

import {
  receiveSetCallUsModalVisibility,
  receiveSetLiveChatModalVisibility,
  handleAlbumPress,
  handleCameraPress,
  handleDocumentPress,
} from 'app/components/Messages/helpers';

import { useDispatch } from 'app/components/Messages/hooks';

import {
  Platform,
  ActionButtonsStyles as Styled,
} from 'app/components/Messages/styles';

import { ActionsBarProps } from 'app/components/Messages/types';

export const ActionButtons = ({
  actionPressParams,
  sendMessage,
  submitting,
}: ActionsBarProps) => {
  const dispatch = useDispatch();

  const openCallUsModal = () => {
    dispatch(receiveSetLiveChatModalVisibility(false)); // ensure live chat modal is closed
    dispatch(receiveSetCallUsModalVisibility(true)); // open call schedule modal
  };

  return (
    <Styled.Wrapper>
      <Styled.LeftSide>
        <Styled.ScheduleCallButton
          icon={<Styled.PhoneIcon />}
          onPress={openCallUsModal}
          title="Request a call"
          testID={TestID.LiveChatModal.RequestACallButton}
        />
      </Styled.LeftSide>

      <Styled.RightSide>
        {Platform.OS !== 'web' && (
          <Styled.Button
            disabled={submitting}
            onPress={() => handleCameraPress(actionPressParams)}
            testID={TestID.Messages.TakePhotoButton}
          >
            <Styled.Icon name="photo-camera" />
          </Styled.Button>
        )}

        <Styled.Button
          disabled={submitting}
          onPress={() => handleAlbumPress(actionPressParams)}
          testID={TestID.Messages.PickPhotoButton}
        >
          <Styled.Icon name="photo-library" />
        </Styled.Button>

        <Styled.Button
          disabled={submitting}
          onPress={() => handleDocumentPress(actionPressParams)}
          testID={TestID.Messages.UploadFileButton}
        >
          <Styled.Icon name="insert-drive-file" />
        </Styled.Button>

        <Styled.SendButton
          onlyWide
          title="Send"
          disabled={submitting}
          onPress={sendMessage}
          testID={TestID.LiveChatModal.SendButton}
        />
      </Styled.RightSide>
    </Styled.Wrapper>
  );
};
