import styled, { css } from 'styled-components/native';

import { Video as VideoRaw } from 'expo-av';
import { Platform } from 'react-native';

export { default as BenefitsOverview } from 'app/components/Common/BenefitsOverview';
import {
  ScrollView as ScrollViewRaw,
  View,
} from 'app/components/Common/Styled';

/**
 * Computes the desired video size based on a given theme object such that the video
 * will fill the full width available in the given container.
 */
const getVideoWidth = ({ breakpoints, spacing, windowDimensions }: any) =>
  Math.min(windowDimensions.width, breakpoints.small);

const flexStyle = Platform.select({ default: '1', web: '1 1 auto' });

/**
 * Expo AV adds a `position: absolute;` style, which works properly in native but
 * not in web.  We can use the `videoStyle` prop to override this setting for web.
 */
const videoStyle = Platform.select({
  web: { position: 'static' },
});

export const FlexContainer = styled(View)`
  flex: ${flexStyle};
`;

export const ScrollView = styled(ScrollViewRaw)`
  overflow: visible;
`;

export const Video = styled(VideoRaw).attrs(({ source }) => ({
  source: { uri: source },
  resizeMode: 'contain',
  useNativeControls: true,
  videoStyle,
}))`
  ${({ theme }) => css`
    align-self: center;
    margin-bottom: ${theme.spacing}px;
    height: ${(getVideoWidth(theme) * 9) / 16}px;
    width: ${getVideoWidth(theme)}px;
  `}
`;
