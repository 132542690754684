import axios from 'axios';

import { showGlobalError } from 'app/actions/uiActions';
import { CARE_API_URL } from 'app/util/constants';
import { unformatObject } from 'app/util/reducerUtils';
import { Episode } from 'types/episode';
import { State } from 'types/state';

import { receiveEpisode, receiveEpisodeLoading } from './action-types';
import fetchEpisodeDetails from './fetch-episode-details';

/**
 * Makes a PATCH request to the Care Service API
 * to update an episode.
 *
 * @param {integer} episodeId - the ID of the episode to update.
 * @param {object} episode - episode details to save in the API.
 * @return {promise} a promise that resolves after the episode is updated.
 */
export default (episodeId, episode: Episode.Episode) => {
  return async (dispatch, getState: () => State.RootState) => {
    try {
      dispatch(receiveEpisodeLoading(true));

      const token = getState().session.coreToken;
      const response = await axios.patch(
        `${CARE_API_URL}/episodes/${episodeId}`,
        unformatObject({ episode }),
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      dispatch(receiveEpisode(response.data.data));
      dispatch(receiveEpisodeLoading(false));
      await dispatch(fetchEpisodeDetails());

      return true;
    } catch (error) {
      dispatch(showGlobalError(error));
      dispatch(receiveEpisodeLoading(false));

      return false;
    }
  };
};
