import { WelcomePages } from 'app/components/ProcedureSearch/constants';
import { getWelcomePagesForClientType } from 'app/components/ProcedureSearch/helpers';
import { useSession } from 'app/components/ProcedureSearch/hooks';
import { Onboarding } from 'app/components/ProcedureSearch/styles';

const ProcedureSearchPageOnboardingOverlay = () => {
  const { user } = useSession();

  if (!user.verified) return;

  const clientType = user?.employer?.type || 'employer';

  return (
    <Onboarding
      name="onboarding.app"
      pages={getWelcomePagesForClientType(WelcomePages, clientType)}
    />
  );
};

export default ProcedureSearchPageOnboardingOverlay;
