import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dimensions, Platform, StyleSheet, View } from 'react-native';

import theme, { zIndexes } from 'app/util/theme';

import Toast from './Toast';

/**
 * Renders an animated list of in-app notifications.
 */
export default class Toasts extends Component {
  /**
   * @property {Object[]} notifications A list of notification objects.
   * @property {function(number)} onDismiss A function to call when dismissing a notification.
   * @property {function(Object, number)} onPress A function to call when pressing a notification.
   */
  static propTypes = {
    notifications: PropTypes.array.isRequired,
    onDismiss: PropTypes.func.isRequired,
    onPress: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      dimensions: Dimensions.get('window'),
    };

    this.subscription = Dimensions.addEventListener(
      'change',
      this.onDimensionsChange
    );
  }

  componentWillUnmount() {
    this.subscription?.remove();
  }

  onDimensionsChange = (event) => {
    this.setState({ dimensions: event.window });
  };

  render() {
    const { width } = this.state.dimensions;

    return (
      <View
        testID="Toasts"
        style={StyleSheet.flatten([
          styles.container,
          width > theme.breakpoints.xsmall ? styles.containerWide : {},
        ])}
      >
        {this.props.notifications.map((item, index) => (
          <Toast
            onDismiss={() => this.props.onDismiss(index)}
            onPress={() => this.props.onPress(item)}
            count={this.props.notifications.length}
            index={index}
            key={item.id}
            {...item}
          />
        ))}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    left: 0,
    right: 0,
    bottom: 80,
    top: 0,
    paddingBottom: theme.spacing * 1.25,
    zIndex: zIndexes.overlay,
    overflow: 'visible',
    justifyContent: 'flex-end',
    pointerEvents: Platform.select({
      web: 'none',
      default: 'box-none',
    }),
    position: Platform.select({
      web: 'fixed',
      default: 'absolute',
    }),
  },

  containerWide: {
    bottom: 0,
    right: undefined,
  },
});
