import React from 'react';
import { Redirect, useParams } from '@cross-platform/react-router-native';

import { ProgressNav } from 'app/components/Common/ProgressNav';
import { useEpisode } from 'app/hooks';
import { Routes } from 'app/util/routes';

import LinkTask from './LinkTask';
import ManualTask from './ManualTask';

const WorkflowTask = () => {
  const { taskId } = useParams<{ taskId: any }>();

  const { episode, steps } = useEpisode();

  if (!episode) return <Redirect to="/" />;

  const {
    id: episodeId,
    currentStep: { id: currentStepId },
  } = episode;

  const currentStep = steps.find(
    (s) => s.id.toString() === currentStepId.toString()
  );

  const task = currentStep.tasks.find(
    (t) => t.id.toString() === taskId.toString()
  );
  if (currentStep && !task) return <Redirect to="/" />;

  if (task.meta?.form) {
    return <Redirect to={`/${Routes.Forms}/${task.meta.form}`} />;
  }

  if (task.meta?.path) {
    return (
      <ProgressNav open>
        <LinkTask task={task} />
      </ProgressNav>
    );
  }

  return (
    <ProgressNav open>
      <ManualTask task={task} episodeId={episodeId} />
    </ProgressNav>
  );
};

export default WorkflowTask;
