import { TestID } from 'app/components/Register/constants';
import { Input } from 'app/components/Register/styles';

const LastFourSSNInput = ({
  formValues,
  setFormValues,
  validateField,
  errors,
  handleFormSubmission,
  employerRegistrationIdInput,
}) => (
  <Input
    label="Last 4 of Social Security Number"
    error={errors.employerRegistrationId}
    input={{
      name: TestID.Register.EmployerRegistrationIdInput,
      value: formValues.employerRegistrationId,
      onBlur: () => validateField('employerRegistrationId'),
      onChange: (value) => {
        setFormValues({
          ...formValues,
          employerRegistrationId: value,
        });
      },
    }}
    inputMode="numeric"
    maxLength={4}
    onSubmitEditing={handleFormSubmission}
    enterKeyHint="done"
    textInputRef={employerRegistrationIdInput}
  />
);

export default LastFourSSNInput;
