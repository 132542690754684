import axios from 'axios';

import { CARE_API_URL } from 'app/util/constants';
import { unformatObject } from 'app/util/reducerUtils';
import { showGlobalError } from 'app/actions/uiActions';

import { receiveEpisode, receiveEpisodeLoading } from './action-types';
import fetchEpisodeDetails from './fetch-episode-details';

/**
 * Make a POST request to the Care Service API to create a new episode.
 *
 * @param   {object}   episode  episode details to save in the API
 * @return  {promise}           promise that resolves after episode is created
 */
export default (episode) => {
  return async (dispatch, getState) => {
    try {
      dispatch(receiveEpisodeLoading(true));
      const {
        session: { coreToken },
      } = getState();

      const response = await axios.post(
        `${CARE_API_URL}/episodes`,
        unformatObject({ episode }),
        {
          headers: { Authorization: `Bearer ${coreToken}` },
        }
      );

      await dispatch(receiveEpisode(response.data.data));
      await dispatch(fetchEpisodeDetails());
      dispatch(receiveEpisodeLoading(false));

      return true;
    } catch (error) {
      dispatch(showGlobalError(error));
      dispatch(receiveEpisodeLoading(false));

      return false;
    }
  };
};
