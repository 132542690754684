import axios from 'axios';

import { showGlobalError } from 'app/actions/uiActions';
import { CORE_API_URL } from 'app/util/constants';

import { parseUserLocation } from 'app/util/userUtils';
import {
  receiveEpisodeLoading,
  receivePossibleProcedures,
} from './action-types';

/**
 * Fetch the possible procedures from the Core Service API.
 *
 * @return {promise} a promise that resolves with the possible procedures.
 */
export default (search, fetchPossibleProcedureCategories = false) => {
  return async (dispatch, getState) => {
    dispatch(receiveEpisodeLoading(true));
    try {
      const {
        session: { coreToken, user },
      } = getState();

      const {
        eligiblePatient: { clientId },
      } = user;

      const { latitude, longitude } = parseUserLocation(user);

      const response = await axios.get(
        `${CORE_API_URL}/clients/${clientId}/offered_procedures`,
        {
          params: {
            include: 'rollups',
            'location[latitude]': latitude,
            'location[longitude]': longitude,
            search,
          },
          headers: { Authorization: `Bearer: ${coreToken}` },
        }
      );

      dispatch(
        receivePossibleProcedures(
          response.data.data,
          fetchPossibleProcedureCategories
        )
      );
    } catch (error) {
      dispatch(showGlobalError(error));
    } finally {
      dispatch(receiveEpisodeLoading(false));
    }
  };
};
