import React from 'react';
import { Marker as MarkerRaw } from '@cross-platform/react-native-maps';

interface Props {
  coordinates: {
    latitude: number;
    longitude: number;
  };
  icon: any;
}

const Marker = ({ coordinates, icon }: Props) => {
  return <MarkerRaw coordinate={coordinates}>{icon}</MarkerRaw>;
};

export default Marker;
