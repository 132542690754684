import { connect } from 'react-redux';

import Dashboard from 'app/components/Dashboard';

import { getEpisodeSteps, markTaskComplete } from 'app/actions/episodeActions';
import { fetchCurrentEpisode } from 'app/actions/episodeActions';
import { push } from 'app/util/history';
import { getIsLoggedIn, getIsPlatformOne } from 'app/selectors/session';

const formatEpisodeSteps = (steps, currentStep) => {
  return steps.map((step) => {
    const available = step.order <= currentStep.order;
    const current = step.order == currentStep.order;
    const stepId = step.id;

    return {
      ...step,
      current,
      tasks: step.tasks
        ?.filter(({ keyType }) => keyType === 'patient')
        .map((task) => ({
          ...task,
          stepId,
          available: task.complete || available,
          stepOrder: step.order,
        })),
    };
  });
};

const getEpisodeProgress = (steps) => {
  const allTasks = [].concat(...steps.map(({ tasks }) => tasks));
  const completeTasks = allTasks.filter(({ complete }) => complete);

  return (completeTasks.length / allTasks.length) * 100;
};

const mapStateToProps = ({ episode, session }) => ({
  loggedIn: getIsLoggedIn(session),
  isPlatformOne: getIsPlatformOne(session),
  ready: Boolean(
    episode.episode && episode.episode.currentStep && episode.steps.length > 0
  ),
  episodeId: episode.episode && episode.episode.id,
  steps:
    episode.episode && episode.episode.currentStep
      ? formatEpisodeSteps(episode.steps, episode.episode.currentStep)
      : [],
  progress:
    episode.episode && episode.episode.currentStep
      ? getEpisodeProgress(episode.steps)
      : 0,
});

const mapDispatchToProps = (dispatch) => ({
  push,
  fetchCurrentEpisode: () => dispatch(fetchCurrentEpisode()),
  getEpisodeSteps: () => dispatch(getEpisodeSteps()),
  markTaskComplete: (episodeId, stepId, taskId) =>
    dispatch(markTaskComplete(episodeId, stepId, taskId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
