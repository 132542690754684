import styled, { css } from 'styled-components/native';

import Input from 'app/components/Common/Input';
import { Text } from 'app/components/Common/Styled';
import TrackableButton from 'app/components/Common/TrackableButton';
import { View } from 'app/components/Common/Styled';

export const InputField = styled(Input).attrs(({ theme }) => {
  return {
    containerStyle: {
      marginBottom: theme.spacing,
    },
  };
})``;

export { LegalContent } from 'app/components/Common/LegalContent';

export { default as StickyNavFooter } from 'app/components/Common/StickyNavFooter';

export const TogglePasswordText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
  `}
`;

export const AccountSettingsFormStyles = {
  SubmitButton: {
    Button: styled(TrackableButton)`
      ${({ theme }) => css`
        background-color: ${theme.colors.primary};
        color: ${theme.colors.white}
        font-size: ${theme.rawFontSizes.medium}px;
        width: ${theme.spacing * 10}px;
      `}
    `,
    Wrapper: styled(View)`
      ${({ theme }) => css`
        align-items: center;
        justify-content: center;
        margin-top: ${theme.spacing * 2.5}px;
        padding-bottom: ${theme.spacing / 2}px;
      `}
    `,
  },
};
