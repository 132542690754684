import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useHistory, useParams } from '@cross-platform/react-router-native';
import { useDispatch, useSelector } from 'react-redux';

import { fetchOfferedProcedure } from 'app/actions/episodeActions';
import { NavigationMode } from 'app/components/Common/StickyNavFooter/types';
import { useEpisode, useNextPhaseRoute } from 'app/hooks';
import { getOfferedProcedure } from 'app/selectors/episode';
import { getIsLoggedIn } from 'app/selectors/session';
import { TestID } from 'app/util/test-id';
import { State } from 'types/state';

import { getProcedureTitle } from './helpers';
import {
  DotIndicator,
  ProcedureConfirmationContainer,
  NextStep,
  NextSteps,
  NextStepsTitle,
  NextStepsWrapper,
  ProcedureLabel,
  ProcedureName,
  StepToCompleteProfile,
  StepToEvaluateCase,
  StepToGetCare,
  StepToRecover,
  StepToSelectDoctor,
  StickyNavFooter,
} from './styles';
import { MatchParams, NextStepItem } from './types';

export const nextSteps: NextStepItem[] = [
  {
    icon: StepToCompleteProfile,
    text: 'Complete your profile',
  },
  {
    icon: StepToSelectDoctor,
    text: 'Select your doctor',
  },
  {
    icon: StepToEvaluateCase,
    text: 'Evaluate your case',
  },
  {
    icon: StepToGetCare,
    text: 'Get care',
  },
  {
    icon: StepToRecover,
    text: 'Recover',
  },
];

const ProcedureConfirmationPage = () => {
  const { id } = useParams<MatchParams>();
  const [isSaving, setIsSaving] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const { refresh } = useEpisode();
  const { nextRoute } = useNextPhaseRoute();
  const refreshInterval = useRef<NodeJS.Timer>();

  const procedure = useSelector((state: State.RootState) =>
    getOfferedProcedure(state, id)
  );

  const isLoggedIn = useSelector((state: State.RootState) =>
    getIsLoggedIn(state.session)
  );

  const [procedureIsLoading, setProcedureIsLoading] = useState(
    procedure?.key !== id
  );

  /** Fetch the specified procedure for logged in users (if necessary). */
  useEffect(
    function fetchSpecifiedProcedure() {
      if (isLoggedIn && id && procedure?.key !== id) {
        dispatch(fetchOfferedProcedure(id));
      }
    },
    [isLoggedIn]
  );

  /** Toggle the page loading state off after the procedure has been fetched. */
  useEffect(
    function toggleLoadingState() {
      if (procedure) setProcedureIsLoading(false);
    },
    [procedure]
  );

  /** Route the user to the next section when their episode has been created. */
  useEffect(
    function proceedWhenEpisodeCreated() {
      if (!isSaving) return; // Only navigate when saving.
      if (nextRoute === '/') return; // Only navigate when a non-empty route is returned.

      clearInterval(refreshInterval.current);
      history.push(nextRoute);
    },
    [isSaving, nextRoute]
  );

  /** Begin polling to determine if episode has been created on the server-side. */
  const startPolling = useCallback(async () => {
    setIsSaving(true);
    refreshInterval.current = setInterval(refresh, 1500);

    return () => {
      clearInterval(refreshInterval.current);
    };
  }, [refresh, setIsSaving]);

  if (!id) return null;

  return (
    <ProcedureConfirmationContainer testID={TestID.ProcedureConfirmation.Page}>
      {procedureIsLoading ? <DotIndicator /> : null}

      <ProcedureLabel>You selected:</ProcedureLabel>

      <ProcedureName testID={TestID.ProcedureConfirmation.ProcedureName}>
        {getProcedureTitle(procedure)}
      </ProcedureName>

      <NextStepsWrapper>
        <NextStepsTitle>Your next steps</NextStepsTitle>

        <NextSteps testID={TestID.ProcedureConfirmation.NextSteps}>
          {nextSteps.map((item, index) => (
            <NextStep {...item} key={index} number={index + 1} />
          ))}
        </NextSteps>
      </NextStepsWrapper>

      <StickyNavFooter
        navigationConfig={[
          {
            disabled: isSaving,
            onPress: startPolling,
            testID: TestID.ProcedureConfirmation.NavFooterContinueButton,
            text: isSaving ? 'Saving' : 'Continue',
          },
        ]}
        navigationMode={NavigationMode.Centered}
        testID={TestID.ProcedureConfirmation.NavFooter}
      />
    </ProcedureConfirmationContainer>
  );
};

export default ProcedureConfirmationPage;
