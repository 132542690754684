import styled from 'styled-components/native';

interface ProgressStepProps {
  isActive: boolean;
}

export const ProgressStep = styled.View<ProgressStepProps>`
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
  height: 10px;
  margin: ${({ theme }) => theme.spacing / 4}px;
  opacity: ${({ isActive }) => (isActive ? '1.0' : '0.2')};
  width: 10px;
`;

export const ProgressStepsContainer = styled.View`
  flex-direction: row;
  margin-top: ${({ theme }) => theme.spacing * 2}px;
  margin-bottom: ${({ theme }) => theme.spacing * 2.5}px;
`;
