import axios from 'axios';

import { showGlobalError } from 'app/actions/uiActions';
import { CORE_API_URL } from 'app/util/constants';

import { receivePossibleProcedures } from './action-types';

/**
 * Fetch an offered procedure by id or key from the Core Service API.
 *
 * @param {string} id The ID or key of the offered procedure to fetch.
 * @return {promise} a promise that resolves with the offered procedure.
 */
export default (id) => {
  return async (dispatch, getState) => {
    try {
      const { coreToken } = getState().session;

      const response = await axios.get(
        `${CORE_API_URL}/offered_procedures/${id}`,
        {
          headers: { Authorization: `Bearer: ${coreToken}` },
        }
      );

      dispatch(receivePossibleProcedures([response.data.data]));

      return response.data.data;
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
};
