import React, { useCallback, useEffect, useRef } from 'react';
import { DeviceEventEmitter, Pressable } from 'react-native';

import { BlurEvent, FocusEvent, FocusableViewProps } from './types';

export const FocusableView = ({
  onBlur = () => {},
  onFocus = () => {},
  PressableComponent = Pressable,
  ...props
}: FocusableViewProps) => {
  /**
   * Keep track of this component using `useRef()` so we can respond
   * to focus events exclusively for this instance.
   */
  const parent = useRef();

  /**
   * Add an event listener that calls the `onFocus()` prop when a `focus` event
   * is emitted and the target is this component or one of it's children.
   */
  useEffect(() => {
    const listener = DeviceEventEmitter.addListener(
      'focus',
      (event: FocusEvent) => {
        if (event.parent != parent.current) return;
        onFocus(event);
      }
    );

    return () => listener.remove();
  }, [onFocus]);

  /**
   * Add an event listener that calls the `onBlur()` prop when a `blur` event
   * is emitted, and immediately emit a `focus` event.
   */
  const listen = useCallback(
    (event: BlurEvent) => {
      const listener = DeviceEventEmitter.addListener(
        'blur',
        (event: BlurEvent) => {
          onBlur(event);
          listener.remove();
        }
      );

      /**
       * Pass this component as the `parent` attribute of the event so that
       * `focus` event handlers can be associated with this render tree.
       */
      DeviceEventEmitter.emit('focus', { ...event, parent: parent.current });

      return () => listener.remove();
    },
    [onBlur]
  );

  /**
   * Wrap children in a dynamic Pressable component, and use the `onPressIn`
   * prop to capture presses on this component or any of it's children and
   * begin the focus/blur life-cycle.
   */
  return <PressableComponent {...props} onPress={listen} ref={parent} />;
};

export default FocusableView;
