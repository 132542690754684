import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  getHospitalBundlePrices,
  receiveEstimatesError,
} from 'app/actions/episodeActions';
import useEpisode from 'app/hooks/useEpisode';

const useHospitalBundlePrices = () => {
  const {
    hospitalBundlePrices,
    hospitalBundlePricesLoading,
    procedure,
    tempCoeSelection: { facility },
    estimatesError,
  } = useEpisode();
  const hospitalId = facility?.id;
  const dispatch = useDispatch();

  /**
   * Attempt to fetch the records for this hook immediately opon loading the
   * selected procedure for the current episode (if any).
   */
  useEffect(() => {
    if (!procedure || !hospitalId) return;

    refresh();

    // reset error state on unmount
    return () => {
      dispatch(receiveEstimatesError(null));
    };
  }, [procedure]);

  const refresh = useCallback(() => {
    if (hospitalBundlePricesLoading) return; // Prevent parallel API calls.
    if (!procedure?.key) return; // Require a selected procedure.

    dispatch(getHospitalBundlePrices(hospitalId, procedure?.key));
  }, [dispatch, procedure?.key]);

  return {
    data: {
      records: hospitalBundlePrices,
    },
    loading: hospitalBundlePricesLoading,
    refresh,
    error:
      estimatesError === 'contractedRate' || estimatesError === 'both'
        ? estimatesError
        : null,
  };
};

export default useHospitalBundlePrices;
