import { callCarrum, sendMagicLink } from './helpers';

import { useDispatch } from './hooks';

import {
  Anchor,
  Button,
  ButtonContainer,
  CenteredScrollScreen,
  HelpText,
  ImageContainer,
  MagicLinkSvg,
  ParagraphText as GuidanceText,
  TitleText,
} from './styles';

import { ExpiredAuthLinkProps } from './types';

/**
 * Render a page of useful pivots when a guest has been redirected by the Core
 * Service due to an expired authentication link. The expired link may be for
 * two scenarios: a single-click registration link that has been used
 * already or a magic link that has been used or that the user waited too long
 * to click.
 *
 * @param  guestEmail    email address for guest pulled from local storage (for
 *                       SCR links) or the query parameters (for magic links)
 * @param  guidanceText  text to guide the user
 * @param  headerText    text for the page header
 * @param  history       object returned by useHistory hook
 * @param  linkText      text for the CTA button that sends a new magic link
 * @param  loading       whether the context page is still loading
 * @param  testID        unique identifier to use for testing
 */
export const ExpiredAuthLink = ({
  guestEmail,
  guidanceText,
  headerText,
  history = null,
  linkText,
  loading = false,
  testID,
}: ExpiredAuthLinkProps) => {
  const dispatch = useDispatch();

  return (
    <CenteredScrollScreen testID={testID}>
      <TitleText>{headerText}</TitleText>

      <ImageContainer>
        <MagicLinkSvg />
      </ImageContainer>

      <GuidanceText>{guidanceText}</GuidanceText>

      {guestEmail && (
        <ButtonContainer>
          <Button
            disabled={!guestEmail.includes('@')}
            loading={loading}
            onPress={() =>
              sendMagicLink({
                email: guestEmail,
                dispatch,
                history,
                resend: true,
              })
            }
            title={linkText}
          />
        </ButtonContainer>
      )}

      <HelpText>
        Need help? Call us at&nbsp;
        <Anchor onPress={(e) => callCarrum(e)} title="1-888-855-7806" />.
      </HelpText>
    </CenteredScrollScreen>
  );
};

export default ExpiredAuthLink;
