import {
  addCurrentStepStyle,
  getProgressLinksWithoutCurrent,
  getStepsAreCompleted,
} from 'app/components/Common/ProgressNav/helpers';

import { useEpisode } from 'app/components/Common/ProgressNav/hooks';

import { ProgressSectionStyles as Styled } from 'app/components/Common/ProgressNav/styles';

import { ProgressLink } from './subcomponents';

export const ProgressSection = () => {
  const { journeyPhase, journeyPhases, steps } = useEpisode();

  const allStepsAreCompleted = getStepsAreCompleted(steps);

  const progressLinksWithoutCurrent = getProgressLinksWithoutCurrent({
    allStepsAreCompleted,
    journeyPhase,
    journeyPhases,
  });

  const progressLinks = addCurrentStepStyle(progressLinksWithoutCurrent);

  return (
    <Styled.Wrapper>
      {progressLinks?.map(({ status, title }) => (
        <ProgressLink key={title} status={status} title={title} />
      ))}
    </Styled.Wrapper>
  );
};
