import {
  breakpoints,
  navigationPaths,
  TestID,
} from 'app/components/App/Navigation/constants';

import {
  useDispatch,
  useEpisode,
  useSelector,
  useWindowDimensions,
} from 'app/components/App/Navigation/hooks';

import {
  disconnectFromWebSocket,
  getIsLoggedIn,
  logout,
} from 'app/components/App/Navigation/helpers';

import { ProfileLinksStyles as Styled } from 'app/components/App/Navigation/styles';

import { LayoutProp, State } from 'app/components/App/Navigation/types';

import { Alert, HelpLink, NavigationLink } from './subcomponents';

/** Render navigation links only for authenticated users. */
export const ProfileLinks = ({ layout = 'row' }: { layout: LayoutProp }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state: State.RootState) =>
    getIsLoggedIn(state.session)
  );

  const episode = useEpisode();
  const isNarrow = useWindowDimensions().width <= breakpoints.small;

  if (!isLoggedIn) return null;

  const confirmLogout = () => {
    Alert.alert('Log Out', 'Do you want to log out of the app?', [
      { text: 'Cancel', style: 'cancel' },
      { text: 'OK', onPress: logoutUser },
    ]);
  };

  const logoutUser = () => {
    disconnectFromWebSocket();
    dispatch(logout(true));
  };

  return (
    <Styled.Wrapper testID={TestID.Navigation.ProfileLinks} layout={layout}>
      <NavigationLink
        layout={layout}
        name="My Carrum ID Card"
        path={navigationPaths.carrumId}
        testID={TestID.Navigation.MyCarrumIDCardLink}
      />
      <NavigationLink
        layout={layout}
        name="My Settings"
        path={navigationPaths.settings}
        testID={TestID.Navigation.MySettingsLink}
      />
      {isNarrow && <HelpLink />}
      <NavigationLink
        layout={layout}
        name="Log Out"
        onPress={confirmLogout}
        testID={TestID.Navigation.LogOutLink}
      />
    </Styled.Wrapper>
  );
};
