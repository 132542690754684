import { ReactElement } from 'react';

export { IconType } from 'types/icon';

export interface ProgressLinkObject {
  route: string;
  status: ProgressLinkStatus;
  title: string;
}

export interface ProgressLinkProps {
  status: ProgressLinkStatus;
  title: string;
}

export enum ProgressLinkStatus {
  incomplete = 'incomplete',
  current = 'current',
  complete = 'complete',
}

export type ProgressNavProps = {
  children: ReactElement;
  open?: boolean;
};
