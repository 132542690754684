import styled from 'styled-components/native';

import theme from 'app/util/theme';

// TODO: mayb be re-intro'd later, TBD
// export const ButtonRow = styled.View`
//   flex-direction: row;
//   justify-content: space-between;
//   @media print {
//     display: none;
//   }
// `;

// export const ButtonContainer = styled.View`
//   width: 48%;
// `;

export const Card = styled.View`
  border-radius: ${({ theme }) => theme.border.radiusTen}px;
  border: ${({ theme }) =>
    `${theme.border.width}px solid ${theme.colors.primary}`};
  margin-bottom: ${({ theme }) => theme.spacing}px;
  padding: ${({ theme }) => theme.spacing}px;
`;

export const Container = styled.View`
  flex-direction: row;
  justify-content: center;
`;

export const Column = styled.View`
  flex-direction: column;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing * 4}px;
  max-width: 480px;
  padding: ${({ theme }) => theme.spacing}px;
`;

export const DetailsContainer = styled.View`
  margin-bottom: ${({ theme }) => theme.spacing}px;
`;

export const DetailsRow = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const NameContainer = styled.View`
  border-bottom-color: ${theme.colors.primary};
  border-bottom-width: ${({ theme }) => theme.border.width}px;
  margin: ${({ theme }) => `${theme.spacing}px 0 ${theme.spacing}px`};
  padding-bottom: ${({ theme }) => theme.spacing}px;
`;
