import { Breakpoints } from 'types/styled';

export const breakpoints: Breakpoints = {
  xxxsmall: 376, // Mobile phones
  xxsmall: 420, // Mobile phones
  xsmall: 480, // Mobile phones
  small: 768, // iPads, Tablets
  medium: 1024, // Small screens, laptops
  large: 1200, // Desktops, large screens
  xlarge: 1440, // Desktops, xlarge screens
  shortScreen: 700, // Desktops, vertical breakpoint for short screens
};

export default breakpoints;
