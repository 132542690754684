import styled, { css } from 'styled-components/native';

import { CenteredScrollScreen } from 'app/components/Common/Layout';
import { DotIndicator } from 'app/components/Common/svg-components';
import HtmlView from 'app/components/Common/HtmlView';
import { StickyNavFooter } from 'app/components/Common/StickyNavFooter';
import { Text } from 'app/components/Common/Styled';
import { TopBackLink } from 'app/components/Common/TopBackLink';
import { View } from 'app/components/Common/Styled';

const ProcedureDescriptionTitleText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    text-align: center;
  `}
`;

export const ProcedureDescriptionContentStyles = {
  ColloquialProcedureName: styled(ProcedureDescriptionTitleText)`
    ${({ theme }) => css`
      font-family: ${theme.fontFamilyBold};
      font-size: ${theme.rawFontSizes.medium}px;
      margin-bottom: ${theme.spacing * 1.5}px;
    `}
  `,

  Header: styled(View)`
    ${({ theme }) => css`
      align-items: stretch;
      background-color: #7b98a3;
      flex: 1 0 auto;
      margin-top: ${theme.spacing / 4}px;
      padding-left: ${theme.spacing * 2.5}px;
      padding-right: ${theme.spacing * 2.5}px;

      ${!theme.isNarrow &&
      css`
        border-radius: ${theme.spacing / 2}px;
        margin-bottom: ${theme.spacing / 2.5}px;
      `}
    `}
  `,

  HtmlContent: styled(HtmlView).attrs(({ theme }) => ({
    tagsStyles: theme.isNarrow ? { p: { padding: theme.spacing } } : {},
  }))``,

  ProcedureLabel: styled(Text)`
    ${({ theme }) => css`
      color: ${theme.colors.white};
      margin-top: ${theme.spacing * 1.5}px;
      text-align: center;
    `}
  `,

  ProcedureName: styled(ProcedureDescriptionTitleText)`
    ${({ theme }) => css`
      font-size: ${theme.rawFontSizes.xxxlarge}px;
      font-family: ${theme.fontFamilyBold};
      margin-bottom: ${theme.spacing / 4}px;
      margin-top: ${theme.spacing / 2}px;
    `}
  `,

  Wrapper: styled(View)`
    ${({ theme }) => css`
      align-items: stretch;
      flex: 1 0 auto;
      flex-direction: column;
      padding-bottom: ${theme.spacing * 5}px;
    `}
  `,
};

export const ProcedureDescriptionStyles = {
  BackLink: {
    Wrapper: styled(View)`
      ${({ theme }) => css`
        padding: ${theme.isNarrow ? theme.spacing / 2 : 0}px;
        padding-top: ${theme.isNarrow ? theme.spacing : 0}px;
      `}
    `,

    Anchor: TopBackLink,
  },

  LoadingIndicator: DotIndicator,

  Wrapper: styled(CenteredScrollScreen).attrs({
    maxSize: 'small',
  })`
    margin-top: ${({ theme }) => theme.spacing * 4}px;
  `,
};

export const ProcedureDescriptionFooterStyles = {
  NavFooter: StickyNavFooter,
};
