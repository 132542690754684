import styled from 'styled-components/native';
import { View } from 'app/components/Common/Styled';

interface StyledViewProps {
  defaultOpacity?: Number;
  pressedOpacity?: Number;
  pressed: boolean;
  flexDirection?: 'row' | 'column';
}

export const StyledView = styled(View)<StyledViewProps>`
  flex: 1 0 auto;
  flex-direction: ${({ flexDirection = 'column' }) => flexDirection};
  opacity: ${({ defaultOpacity, pressedOpacity, pressed }) =>
    pressed ? pressedOpacity : defaultOpacity};
`;
