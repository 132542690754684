import { Button, Icon } from 'react-native-elements';

import {
  Component,
  LocalAuthentication,
  Platform,
  PropTypes,
  TEST_USER,
  theme,
} from 'app/components/Login/constants';

import {
  logError,
  SecureStore,
  StyleSheet,
} from 'app/components/Login/helpers';

import { EnvironmentTypes, PlatformTypes } from 'app/components/Login/types';

import { Alert, EnvironmentSwitch } from 'app/components/Login/styles';

const FingerprintLoginButton = ({ onPress }) => (
  <Button
    type="clear"
    title="Log in with Touch ID"
    testID="Login--touch-id-button"
    buttonStyle={styles.button}
    titleStyle={styles.buttonTitle}
    icon={<Icon name="fingerprint" iconStyle={styles.buttonIcon} />}
    iconRight
    onPress={onPress}
  />
);

export default class Biometrics extends Component {
  static propTypes = {
    appState: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
  };

  retryTimeout = 0;

  state = {
    credentials: false,
    functionality: false,
    biometricActive: false,
    biometricSuccess: false,
    biometricError: '',
  };

  componentDidMount() {
    this.setBiometricStatus();
  }

  componentWillUnmount() {
    clearTimeout(this.retryTimeout);
  }

  componentDidUpdate(prevProps) {
    if (this.state.biometricActive || this.state.biometricSuccess) return;
    if (prevProps.appState === 'background' && this.props.appState === 'active')
      this.checkForBiometrics();
  }

  /**
   * Updates the state with the status of the
   * biometric credentials and functionality.
   */
  setBiometricStatus = async () => {
    let credentials = await this.hasCredentials();
    let functionality = await this.hasBiometricFunctionality();

    this.setState({ credentials, functionality });
  };

  /**
   * Checks that the user has logged in manually before.
   * @return {Boolean} whether the stored email and password are present
   */
  hasCredentials = async () => {
    try {
      const { email, password } = await this.fetchCredentials();
      return Boolean(email && password);
    } catch (error) {
      logError('Unable to fetch credentials for biometric scan', error);
      return false;
    }
  };

  /**
   * Checks that the device has biometric ID functionality
   * and that the user has set it up.
   * @return {Boolean} Whether biometric functionality is available
   */
  hasBiometricFunctionality = async () => {
    let compatible = false;
    let enrolled = false;

    try {
      compatible = await LocalAuthentication.hasHardwareAsync();
      enrolled = await LocalAuthentication.isEnrolledAsync();
    } catch (error) {
      /* Do Nothing */
    }

    return Boolean(compatible && enrolled);
  };

  /**
   * Checks to confirm that the device is set up
   * for Touch or Face ID login. If true, begins
   * the biometric authentication process.
   */
  checkForBiometrics = async () => {
    let credentials = await this.hasCredentials();
    let functionality = await this.hasBiometricFunctionality();

    if (credentials && functionality) this.scanBiometrics();
  };

  /** Clear stored credentials from local storage. */
  clearCredentials = () => {
    const options = {
      keychainAccessible: SecureStore.WHEN_UNLOCKED_THIS_DEVICE_ONLY,
    };

    try {
      SecureStore.deleteItemAsync('email', options);
      SecureStore.deleteItemAsync('password', options);
    } catch (error) {
      /* Do Nothing */
    }
  };

  /**
   * Retrieve securely stored login credentials.
   *
   * @return  {object}  authentication object
   */
  fetchCredentials = async () => {
    let email, password;

    try {
      email = await SecureStore.getItemAsync('email');
      password = await SecureStore.getItemAsync('password');
    } catch (error) {
      if (error.message !== 'Could not decrypt the item in SecureStore')
        throw error;

      email = '';
      password = '';
    }

    return { email, password };
  };

  /**
   * Shows a message notifying the user to use the
   * form to activate the biometric functionality.
   */
  showActivateTouchID = () => {
    Alert.alert('Log in Now', 'Log in using the form to set up Touch ID', [
      { text: 'OK', style: 'cancel' },
    ]);
  };

  /**
   * Triggers the biometric authentication
   * functionality and logs in with the stored
   * credentials if successful.
   */
  scanBiometrics = async () => {
    this.setState({ biometricActive: true });

    const { success } = await LocalAuthentication.authenticateAsync({
      promptMessage: 'Log in',
    });

    if (!success) {
      if (Platform.OS === 'android')
        await LocalAuthentication.cancelAuthenticate();
      return this.setState({ biometricActive: false });
    }

    this.setState({ biometricActive: false, biometricSuccess: true });

    let { email, password } = await this.fetchCredentials();

    this.props
      .onSubmit({ email, password })
      .then(() => this.props.error && this.onFingerprintLoginError());
  };

  loginAsTestUser = () => {
    this.props.onSubmit(TEST_USER);
  };

  /**
   * Handle cases where the stored credentials are invalid
   */
  onFingerprintLoginError = () => {
    this.clearCredentials();

    this.setState({
      biometricError: '',
      biometricSuccess: false,
      credentials: false,
      loading: false,
    });
  };

  render() {
    if (!this.state.functionality)
      return (
        <EnvironmentSwitch
          environments={[EnvironmentTypes.Development]}
          platforms={[PlatformTypes.Android, PlatformTypes.iOS]}
        >
          <FingerprintLoginButton onPress={this.loginAsTestUser} />
        </EnvironmentSwitch>
      );

    return (
      <FingerprintLoginButton
        onPress={
          this.state.credentials
            ? this.scanBiometrics
            : this.showActivateTouchID
        }
      />
    );
  }
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
  },

  buttonIcon: {
    color: '#2089dc',
    marginLeft: theme.spacing / 4,
  },

  buttonTitle: {
    textTransform: 'none',
  },
});
