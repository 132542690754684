import { defaultIcon } from 'app/components/MySettings/constants';

import { useSession } from 'app/components/MySettings/hooks';

import {
  Avatar,
  AvatarButton,
  CameraIcon,
  CameraIconContainer,
  DetailsWrapper,
  Subtitle,
  Title,
  TitleWrapper,
} from 'app/components/MySettings/styles';

import { MySettingsHeaderProps } from 'app/components/MySettings/types';

const MySettingsHeader = ({ onAvatarEdit }: MySettingsHeaderProps) => {
  const { user } = useSession();
  const { email, name, profileImage, profile } = user;
  const { preferredName } = profile;

  return (
    <>
      <TitleWrapper>
        <Title>My Settings</Title>
      </TitleWrapper>

      <DetailsWrapper>
        <AvatarButton onPress={onAvatarEdit}>
          <Avatar
            /**
             * The underlying component is heavily cached and does not
             * refresh automatically if the `source` param changes.
             *
             * By setting `key` to the value of the image, we can force
             * this component to re-render if the profile image changes.
             */
            key={profileImage}
            rounded
            size={100}
            source={profileImage ? { uri: profileImage } : null}
            icon={profileImage ? null : defaultIcon}
          />

          <CameraIconContainer>
            <CameraIcon />
          </CameraIconContainer>
        </AvatarButton>

        <Subtitle>{preferredName || name}</Subtitle>

        <Subtitle>{email}</Subtitle>
      </DetailsWrapper>
    </>
  );
};

export default MySettingsHeader;
