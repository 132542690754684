import React from 'react';
import styled from 'styled-components/native';

import { Icon } from 'react-native-elements';

import TrackableButton from 'app/components/Common/TrackableButton';
import { View } from 'app/components/Common/Styled';
import theme from 'app/util/theme';
import { IconType } from 'types/icon';

const { white } = theme.colors;
interface IconLinkProps {
  color?: string;
  fontSize?: number;
  iconName: string;
  iconType?: IconType;
  onPress: () => void;
  size?: number;
  title: string;
}

const IconLink = ({
  color = white,
  fontSize = null,
  iconName = '',
  iconType = IconType.Material,
  onPress,
  size = theme.fontSizes.h2,
  title = '',
}: IconLinkProps) => {
  return (
    <TrackableButton
      buttonStyle={{
        backgroundColor: 'transparent',
      }}
      icon={
        <IconBorder color={color} size={size}>
          <Icon color={color} name={iconName} size={size} type={iconType} />
        </IconBorder>
      }
      iconName={iconName}
      iconPosition="top"
      onPress={onPress}
      title={title}
      titleStyle={{
        color: color,
        fontSize: fontSize || size * 0.66,
        marginTop: fontSize ? fontSize / 2 : size * 0.33,
      }}
    />
  );
};

export default IconLink;

const IconBorder = styled(View)<{ color: string; size: number }>`
  border-color: ${({ color }) => color};
  border-radius: ${({ size }) => size}px;
  border-width: 2px;
  height: ${({ size }) => size * 2}px;
  width: ${({ size }) => size * 2}px;
  align-items: center;
  justify-content: center;
`;
