import { TestID } from 'app/components/Messages/constants';

import { NotificationGroupStyles as Styled } from 'app/components/Messages/styles';

import { NotificationGroupProps } from 'app/components/Messages/types';

import { Message } from './subcomponents';

/**
 * Render a group of notification messages.
 *
 * @param  timestamp  when the first message in the group was created
 * @param  messages   group of message objects
 */
export const NotificationGroup = ({
  timestamp,
  messages,
}: NotificationGroupProps) => {
  const iconName =
    messages[0].notificationType === 'outside_business_hours'
      ? 'clock-o'
      : 'bell';

  return (
    <Styled.Wrapper testID={TestID.Messages.NotificationGroup}>
      <Styled.Avatar iconName={iconName} />

      <Styled.Content>
        <Styled.Timestamp>{timestamp}</Styled.Timestamp>

        <Styled.MessagesWrapper>
          {messages.map(({ id, body }) => (
            <Message key={id} body={body} />
          ))}
        </Styled.MessagesWrapper>
      </Styled.Content>
    </Styled.Wrapper>
  );
};
