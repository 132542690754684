import { Platform, ScrollView } from 'react-native';
import styled from 'styled-components/native';

import { View } from 'app/components/Common/Styled';
import theme from 'app/util/theme';

export { default as RiskWarningModal } from '../RiskWarningModal';

enum PageLayout {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

interface PageWrapperProps {
  children: any;
  screenWidth: number;
  testID?: string;
}

const getPageLayout = (screenWidth) =>
  screenWidth < theme.breakpoints.medium
    ? PageLayout.Vertical
    : PageLayout.Horizontal;

const HorizontalPageWrapper = styled(View)`
  background-color: #fff;
  flex: 1 0 auto;
  flex-direction: row;
`;

const MobileWrapper = styled(View)`
  flex: 1 1 0;
`;

export const OptionsWrapper = ({
  children,
  screenWidth,
  ...rest
}: PageWrapperProps) => {
  // Allow the page to scroll naturally on web.
  if (Platform.OS === 'web') return children;

  // Constrain the card list to half screen when using a horizontal layout.
  const width =
    getPageLayout(screenWidth) === PageLayout.Vertical
      ? screenWidth
      : screenWidth / 2;

  return (
    <ScrollView
      children={children}
      contentContainerStyle={{ width }}
      {...rest}
    />
  );
};

export const PageWrapper = ({ screenWidth, ...rest }: PageWrapperProps) => {
  const container =
    getPageLayout(screenWidth) === PageLayout.Vertical ? (
      <VerticalPageWrapper {...rest} />
    ) : (
      <HorizontalPageWrapper {...rest} />
    );

  // Allow the page to scroll naturally on web.
  if (Platform.OS === 'web') return container;
  if (screenWidth > theme.breakpoints.small) return container;

  return (
    <MobileWrapper>
      <ScrollView>{container}</ScrollView>
    </MobileWrapper>
  );
};

const VerticalPageWrapper = styled(View)`
  background-color: #fff;
  flex: 1 0 auto;
  flex-direction: column-reverse;
`;
