import React, { ReactNode } from 'react';

import { Fade } from 'app/components/Common/Animated';

import { TestID } from 'app/util/test-id';

import {
  ActionButton,
  ActionRow,
  Button,
  ButtonContainerWeb,
  Container,
  DotIndicator,
  DotIndicatorWrapper,
  FooterWrapper,
  Header,
  ProgressNav,
  ScrollView,
} from './styles';

interface JourneyPhaseIntroPageProps {
  children: ReactNode;
  continueDisabled: boolean;
  footerContent?: ReactNode;
  isRefreshing?: boolean;
  onContinue?: (e: Event) => void;
  title: string;
}

const JourneyPhaseIntroPage = ({
  children,
  continueDisabled,
  footerContent = null,
  isRefreshing = false,
  onContinue,
  title,
}: JourneyPhaseIntroPageProps) => {
  const buttonTitle = continueDisabled ? 'No pending tasks' : 'Continue';

  return (
    //@ts-ignore
    <ProgressNav open>
      <ScrollView>
        <Container testID={TestID.JourneyPhaseIntroPage.Page}>
          <Fade delay={300} in>
            <Header>{title}</Header>
          </Fade>

          <Fade delay={400} in>
            {children}
          </Fade>

          <Fade delay={500} in>
            <ButtonContainerWeb>
              <Button
                disabled={continueDisabled}
                testID={TestID.JourneyPhaseIntroPage.ContinueButton}
                title={buttonTitle}
                onPress={onContinue}
              />
            </ButtonContainerWeb>
          </Fade>

          {!!footerContent && (
            <Fade delay={600} in>
              <FooterWrapper>{footerContent}</FooterWrapper>
            </Fade>
          )}
        </Container>
      </ScrollView>
      {/* TODO: Action row to be replaced to sticky nav footer buton */}
      <ActionRow>
        <ActionButton
          disabled={continueDisabled}
          testID={TestID.JourneyPhaseIntroPage.ContinueButtonNav}
          iconRight
          onPress={onContinue}
          title={buttonTitle}
        />
      </ActionRow>
      {isRefreshing && (
        <DotIndicatorWrapper>
          <DotIndicator />
        </DotIndicatorWrapper>
      )}
    </ProgressNav>
  );
};

export default JourneyPhaseIntroPage;
