import { TestID } from 'app/util/test-id';

import {
  AlignmentWrapper,
  CarrumGoldExperienceBenefits,
  FooterText,
  FooterTextWrapper,
} from './styles';

const BenefitsOverview = ({ complete }) => {
  return (
    <AlignmentWrapper testID={TestID.BenefitsOverview.Component}>
      <CarrumGoldExperienceBenefits
        disabled={!complete}
        mode={complete ? 'dark' : 'light'}
      />

      {!!complete && (
        <FooterTextWrapper>
          <FooterText>Click each to see the details.</FooterText>
        </FooterTextWrapper>
      )}
    </AlignmentWrapper>
  );
};

export default BenefitsOverview;
