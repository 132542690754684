import styled, { css } from 'styled-components/native';
import { Image, Platform } from 'react-native';

import { CheckMark as CheckMarkRaw } from 'app/components/Common/svg-components';
export { default as JourneyPhaseHtml } from 'app/components/Common/HtmlView';
import { Text, View } from 'app/components/Common/Styled';
import theme from 'app/util/theme';
import { DefaultTheme } from 'types/theme';

interface GreyScaleWrapperProps {
  greyscale?: boolean;
}

/**
 * Grey out images on web and make the content semi-transparent.
 */
const GreyScaleWrapper = styled(View).attrs(
  ({ greyscale = false }: GreyScaleWrapperProps) => ({
    opacity: greyscale ? 0.2 : 1,
  })
)<GreyScaleWrapperProps>`
  ${({ greyscale = false }: GreyScaleWrapperProps) =>
    greyscale && Platform.OS === 'web'
      ? css`
          filter: grayscale(1);
          opacity: 50%;
        `
      : ''}
`;

export const CardImage = styled(Image).attrs({ resizeMode: 'contain' })`
  flex: 1 0 auto;
`;

interface CardImageWrapperProps {
  centered: boolean;
}

export const CardImageWrapper = styled(GreyScaleWrapper)<CardImageWrapperProps>`
  align-items: ${({ centered }: CardImageWrapperProps) =>
    centered ? 'center' : 'stretch'};
  align-self: ${({ centered }: CardImageWrapperProps) =>
    centered ? 'center' : 'stretch'};
  flex: 1 0 auto;
  flex-direction: column;
`;

export const CardText = styled(Text).attrs({
  h3: true,
  h3Style: {
    fontSize: theme.fontSizes.body2,
    color: theme.colors.bodyText,
  },
  numberOfLines: 2,
})`
  justify-content: space-around;
  text-align: center;
`;

export const CardTextWrapper = styled(GreyScaleWrapper)`
  height: ${({ theme }) => theme.spacing * 4}px;
  padding-left: ${({ theme }) => theme.spacing * 2}px;
  padding-right: ${({ theme }) => theme.spacing * 2}px;
`;

export const CardsWrapper = styled(View)`
  align-items: center;
  flex-direction: ${({ theme }) => (theme.isNarrow ? 'column' : 'row')};
  flex: 1 0 auto;
  justify-content: center;
  padding-bottom: ${({ theme }) => theme.spacing * 2}px;
  padding-top: ${({ theme }) => theme.spacing * 2}px;
`;

const cardDimensions = theme.spacing * 17.5;

interface CardProps {
  complete: boolean;
  theme: DefaultTheme;
}

export const Card = styled(View)<CardProps>`
  align-items: center;
  background-color: ${({ complete, theme }: CardProps) =>
    complete ? theme.colors.lightGreen : theme.colors.white};
  border-radius: ${({ theme }) => theme.spacing / 2}px;
  flex: 1 1 auto;
  height: ${cardDimensions - theme.spacing * 2}px;
  justify-content: space-around;
  max-width: ${cardDimensions}px;
  overflow: hidden;
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing * 2}px;
  padding-bottom: ${({ theme }) => theme.spacing * 2}px;
  ${({ theme }) =>
    theme.isNarrow
      ? `margin-bottom: ${theme.spacing}px`
      : `margin-right: ${theme.spacing}px`};
`;

export const CheckMark = styled(CheckMarkRaw).attrs(({ theme }) => ({
  color: theme.colors.green,
  size: 100,
}))``;

export const CompleteText = styled(Text).attrs({
  h3: true,
  h3Style: {
    fontSize: theme.fontSizes.body1,
    color: theme.colors.success,
  },
})``;

export const CompleteTextWrapper = styled(GreyScaleWrapper)`
  height: ${({ theme }) => theme.spacing * 4}px;
  align-items: center;
  justify-content: center;
`;

export const JourneyPhaseHtmlWrapper = styled(View)`
  align-items: stretch;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing}px;
`;
