import React from 'react';
import { useWindowDimensions } from 'react-native';

import { Text } from 'app/components/Common/Styled';
import { useEpisode } from 'app/hooks';

import { Bar, BlueMapDot, MapPin, LegendItem, LegendWrapper } from './styles';

const LegendBar = () => {
  const { tempCoeSelection } = useEpisode();
  const { width: screenWidth } = useWindowDimensions();
  const shouldIncludeSilver =
    tempCoeSelection.productOffering === 'both' ||
    tempCoeSelection.productOffering === 'silver';

  return (
    <LegendWrapper screenWidth={screenWidth}>
      <Bar screenWidth={screenWidth}>
        <LegendItem>
          <BlueMapDot />
          <Text>&nbsp;Your Location</Text>
        </LegendItem>
        <LegendItem>
          <MapPin width={18} height={26} tier="gold" />
          <Text>&nbsp;Carrum Gold Experience</Text>
        </LegendItem>
        {shouldIncludeSilver && (
          <LegendItem>
            <MapPin width={18} height={26} tier="silver" />
            <Text>&nbsp;Carrum Silver Experience</Text>
          </LegendItem>
        )}
      </Bar>
    </LegendWrapper>
  );
};

export default LegendBar;
