import React from 'react';
import PropTypes from 'prop-types';

import {
  ArrowIcon,
  BodyContainer,
  Container,
  Divider,
  Paragraph,
  Title,
  TitleContainer,
  UnorderedList,
} from './styles';

const FaqSection = ({ active, body, onToggle, title }) => (
  <Container>
    <TitleContainer onPress={onToggle}>
      <Title>{title}</Title>
      <ArrowIcon active={active} />
    </TitleContainer>

    {active && <Divider />}

    {active && (
      <BodyContainer>
        {body.map((section) =>
          Array.isArray(section) ? (
            <UnorderedList key={section.join(',')} items={section} />
          ) : (
            <Paragraph key={section}>{section}</Paragraph>
          )
        )}
      </BodyContainer>
    )}
  </Container>
);

FaqSection.propTypes = {
  active: PropTypes.bool,
  body: PropTypes.array.isRequired,
  onToggle: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

FaqSection.defaultProps = {
  active: false,
};

export default FaqSection;
