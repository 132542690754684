import React from 'react';

//@ts-ignore
import { useEpisode } from 'app/hooks';
import { getAvailableDoctorsAtFacility } from 'app/selectors/episode';

import {
  CardRow,
  DoctorCard,
  DoctorsSection,
  DoctorsSectionHeader,
} from './styles';

interface DoctorsAtThisFacility {
  facilityId?: number;
}

const DoctorsAtThisFacility = ({ facilityId }: DoctorsAtThisFacility) => {
  const episodeState = useEpisode();
  let doctorsAtThisFacility = getAvailableDoctorsAtFacility(
    episodeState,
    facilityId
  );

  return (
    <DoctorsSection>
      <DoctorsSectionHeader>Doctors at this facility</DoctorsSectionHeader>
      <CardRow>
        {doctorsAtThisFacility.map((doctor) => (
          <DoctorCard key={doctor.id} {...doctor} />
        ))}
      </CardRow>
    </DoctorsSection>
  );
};

export default DoctorsAtThisFacility;
