import React from 'react';
import { Linking, Platform } from 'react-native';

import { Redirect } from '@cross-platform/react-router-native';
import StickyNavFooter from 'app/components/Common/StickyNavFooter';
import { NavigationMode } from 'app/components/Common/StickyNavFooter/types';
import { useDispatch, useEpisode } from 'app/hooks';
import { EVENTS, logEvent } from 'app/util/analytics';
import { TestID } from 'app/util/test-id';

import {
  ButtonWrapper,
  LinkButton,
  LinkTaskContainer,
  TaskTitle,
} from './styles';
import { markTaskComplete } from 'app/actions/episodeActions';

interface LinkTaskProps {
  task: any;
}

const LinkTask = ({ task }: LinkTaskProps) => {
  const dispatch = useDispatch();
  const {
    episode: { id: episodeId },
  } = useEpisode();
  const taskPath = task.meta.path;

  const handleLinkPress = () => {
    logEvent(EVENTS.tasks.complete, { task: task.title });
    dispatch(markTaskComplete(episodeId, task.stepId, task.id));

    // handle absolute link (web)
    if (Platform.OS == 'web') {
      return window.open(taskPath, '_blank');
    }

    // handle absolute link (mobile)
    return Linking.openURL(taskPath);
  };

  // handle relative link
  if (taskPath.indexOf('http://') !== 0 && taskPath.indexOf('https://') !== 0) {
    return <Redirect to={`${task.meta.path}`} />;
  }

  return (
    <LinkTaskContainer>
      <TaskTitle>{task.title}</TaskTitle>
      <ButtonWrapper>
        <LinkButton
          title={task?.ctaLabel || 'Continue'}
          onPress={handleLinkPress}
          testID={TestID.WorkflowTask.LinkTaskButton}
        />
      </ButtonWrapper>

      <StickyNavFooter
        navigationConfig={[
          {
            onPress: handleLinkPress,
            testID: TestID.WorkflowTask.LinkTaskButton,
            text: task?.ctaLabel || 'Continue',
          },
        ]}
        navigationMode={NavigationMode.Continue}
        testID={TestID.WorkflowTask.NavFooter}
      />
    </LinkTaskContainer>
  );
};

export default LinkTask;
