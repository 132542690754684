import { breakpoints, TestID } from 'app/components/App/Navigation/constants';

import { getIsLoggedIn } from 'app/components/App/Navigation/helpers';

import {
  useSelector,
  useWindowDimensions,
} from 'app/components/App/Navigation/hooks';

import { ProfileMenuStyles as Styled } from 'app/components/App/Navigation/styles';

import { ProfileMenuProps, State } from 'app/components/App/Navigation/types';

import { ProfileInfo, ProfileLinks, Toggle } from './subcomponents';

/**
 * Provide access for authenticated users to account-related screens.
 *
 * Responsively render the navigation links based on several factors:
 *
 * 1. The screen size. This determines whether this component should be rendered.
 * 2. The patient's situation and position in their journey of care. This
 *    logic is encapsulated downstream where relevant.
 * 3. The user's authentication status.
 * 4. Whether this component has been toggled open to display.
 */

export const ProfileMenu = ({ isOpen = true }: ProfileMenuProps) => {
  const hasTopNavLinks = useWindowDimensions().width > breakpoints.small;
  const isLoggedIn = useSelector((state: State.RootState) =>
    getIsLoggedIn(state.session)
  );

  if (!isLoggedIn || !hasTopNavLinks) return null;

  return (
    <Styled.Wrapper testID={TestID.Navigation.ProfileMenu}>
      <Toggle />
      {isOpen && (
        <Styled.Panel testID={TestID.Navigation.ProfileMenuPanel}>
          <ProfileInfo />
          <ProfileLinks layout="column" />
        </Styled.Panel>
      )}
    </Styled.Wrapper>
  );
};
