const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/**
 * Builds the `messageGroups` and `participants`
 * properties for a conversation.
 *
 * @param {object} conversation The conversation object.
 * @param {array} userStatuses An array of user statuses from the API.
 * @param {string} currentUserId The ID of the current user.
 * @return {object} A composed conversation object.
 */
export function composeConversation(conversation, userStatuses, currentUserId) {
  return {
    ...conversation,
    messageGroups: groupMessages(
      conversation.messages,
      conversation.participants,
      currentUserId
    ),
    participants: conversation.participants
      .filter(
        (participant) => parseInt(participant.id) !== parseInt(currentUserId)
      )
      .map((participant) => {
        const userStatus =
          userStatuses.find(
            (u) => parseInt(u.userId) === parseInt(participant.id)
          ) || {};
        return { ...participant, status: userStatus.status };
      }),
  };
}

/**
 * Converts a Date object into a formatted
 * timestamp for displaying with messages.
 *
 * @param {Date} date A JavaScript Date object.
 * @return {string} A formatted timestamp string.
 */
export function toMessageTimestamp(date) {
  let hour = date.getHours();
  let minute = date.getMinutes();
  const day = date.getDate();
  const month = MONTHS[date.getMonth()];
  const ampm = hour >= 12 ? 'PM' : 'AM';

  if (hour > 12) {
    hour -= 12;
  } else if (hour === 0) {
    hour = 12;
  }

  if (minute < 10) {
    minute = `0${minute}`;
  }

  return `${month} ${day}, ${hour}:${minute} ${ampm}`;
}

/**
 * Takes an array of messages and groups them by
 * the author and create time (within 5 minutes).
 *
 * @param {array} messages The messages to group.
 * @param {array} participants A list of participants in the conversation.
 * @param {string} currentUserId The ID of the current user.
 * @return {object} An object keyed by author ID and timestamp.
 */
function groupMessages(messages, participants, currentUserId) {
  let increment;
  const messageGroups = {};
  const lastReadMessage = Array(...messages).filter(
    ({ readBy }) =>
      readBy.map((id) => parseInt(id)).indexOf(parseInt(currentUserId)) !== -1
  )[0];

  messages
    .filter((message) => !message.system)
    .forEach((message, index) => {
      if (index === 0) {
        increment = message.createdAt;
      } else if (
        messages[index - 1] &&
        parseInt(messages[index - 1].author.id) !== parseInt(message.author.id)
      ) {
        increment = message.createdAt;
      } else if (Math.ceil((increment - message.createdAt) / 1000 / 60) > 5) {
        increment = message.createdAt;
      }

      const { author, notification, createdAt } = message;
      const timestamp = toMessageTimestamp(createdAt);
      const key = `${author.id}-${increment.toISOString()}`;

      messageGroups[key] = messageGroups[key] || {
        timestamp,
        author,
        notification,
      };
      messageGroups[key].messages = messageGroups[key].messages || [];
      messageGroups[key].messages.unshift({
        ...message,
        mine: parseInt(author.id) === parseInt(currentUserId),
        read:
          (lastReadMessage && message.id <= lastReadMessage.id) ||
          message.readBy
            .map((id) => parseInt(id))
            .indexOf(parseInt(currentUserId)) !== -1,
      });
    });

  return messageGroups;
}
