import styled from 'styled-components/native';

import { View } from 'app/components/Common/Styled';

export const ButtonRow = styled(View)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
  flex-direction: row;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing}px;
  width: 100%;
`;
