import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { Video } from 'expo-av';

import brokenLinkIcon from 'app/assets/images/broken-link-icon.png';

/**
 * Renders a video with a max height, allowing it to
 * be positioned vertically in the UI.
 *
 * @example
 * <SizedVideo
 *   height={60}
 *   source="my-video.mp4"
 * />
 */
export default class SizedVideo extends Component {
  static propTypes = {
    source: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    controls: PropTypes.bool,
    containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    videoStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  };

  static defaultProps = {
    controls: true,
  };

  state = {
    error: false,
  };

  onError = () => {
    this.setState({ error: true });
  };

  render() {
    const { height } = this.props;
    const width = height * (4 / 3);

    return (
      <View style={[{ overflow: 'hidden' }, this.props.containerStyle]}>
        <Video
          onError={this.onError}
          useNativeControls={this.props.controls}
          source={
            this.state.error ? brokenLinkIcon : { uri: this.props.source }
          }
          style={[{ height, width }, this.props.videoStyle]}
        />
      </View>
    );
  }
}
