import {
  EVENTS,
  initialFormValues,
  messagesPerPage,
  TestID,
} from './constants';

import {
  chime,
  fetchMoreMessages,
  getConversation,
  getMessageGroups,
  getMessagesData,
  getParticipants,
  getShowLoadMoreButton,
  hasNewMessage,
  logEvent,
  markUnreadMessages,
  scrollToBottom,
  setMessagesOpen,
} from './helpers';

import {
  useDispatch,
  useEffect,
  useMessage,
  usePreviousValue,
  useRef,
  useSession,
  useState,
} from './hooks';

import { MessagesStyles as Styled, Platform } from './styles';

import {
  Attachments,
  EmptyState,
  Footer,
  Header,
  LoadMoreButton,
  MessageGroup,
  NotificationGroup,
} from './subcomponents';

const Messages = () => {
  let refreshTimeout;
  let unreadTimeout;

  const dispatch = useDispatch();
  const message = useMessage();
  const scrollView = useRef();
  const session = useSession();

  const conversation = getConversation(message, session);
  const currentUserId = session.user.id;
  const isLoading = session.loading;
  const previousConversation = usePreviousValue(conversation);
  const shouldScroll = !previousConversation && !!conversation;

  const [formValues, setFormValues] = useState(initialFormValues);
  const [refreshing, setRefreshing] = useState(false);

  const participants = getParticipants(conversation);
  const messageGroups = getMessageGroups(conversation);
  const messagesData = getMessagesData(messageGroups);
  const isInverted = Object.keys(messageGroups).length > 0;

  /** Log `view_messages` event */
  useEffect(() => {
    logEvent(EVENTS.messaging.view);
  }, []);

  /**
   * When a conversation is first available, scroll down to show messages.
   * Ensure that there was no previous conversation to prevent scrolling
   * when users click the "Load Previous Messages" button.
   */
  useEffect(() => {
    if (shouldScroll) scrollToBottom(false, scrollView.current);
  }, [shouldScroll]);

  /**
   * If a conversation exists, mark unread messages as bold for a short time
   * as an affordance for the user.
   */
  useEffect(() => {
    if (conversation)
      markUnreadMessages(dispatch, conversation.id, 2000, unreadTimeout);
  }, [conversation, unreadTimeout]);

  /** Sound a chime to indicate that a new message exists. */
  useEffect(() => {
    if (hasNewMessage(currentUserId, conversation, previousConversation))
      chime();
  }, [conversation, currentUserId, previousConversation]);

  /** On dismount, clear any timeouts and close the "Messages" tab. */
  useEffect(() => {
    setMessagesOpen(dispatch, true);

    return () => {
      refreshTimeout && clearTimeout(refreshTimeout);
      unreadTimeout && clearTimeout(unreadTimeout);

      setMessagesOpen(dispatch, false);
    };
  }, [refreshTimeout, unreadTimeout]);

  const handleEndReached = () => {
    if (Platform.OS !== 'web') loadMoreMessages();
  };

  const loadMoreMessages = () => {
    fetchMoreMessages(
      dispatch,
      conversation,
      refreshing,
      setRefreshing,
      refreshTimeout
    );
  };

  /** Render a message or notification.  */
  const renderMessageOrNotificationGroup = ({ item }) => {
    return item.notification ? (
      <NotificationGroup {...item} />
    ) : (
      <MessageGroup
        patientIsAuthor={parseInt(item.author.id) === parseInt(currentUserId)}
        {...item}
      />
    );
  };

  /** Show the "Load More" button if there's at least one page of messages. */
  const showLoadMoreButton = getShowLoadMoreButton({
    conversation,
    messagesPerPage,
  });

  return (
    <Styled.Container testID={TestID.Messages.Page}>
      <Header isRendered={conversation} participants={participants} />

      <Styled.ListContainer>
        <Styled.MessagesList
          data={messagesData}
          // @ts-expect-error No overload matches this call
          enableOnAndroid
          inverted={isInverted}
          ListEmptyComponent={<EmptyState isLoading={isLoading} />}
          ListFooterComponent={
            showLoadMoreButton && (
              <LoadMoreButton loading={refreshing} onPress={loadMoreMessages} />
            )
          }
          onEndReached={handleEndReached}
          onEndReachedThreshold={0.2}
          ref={scrollView}
          renderItem={renderMessageOrNotificationGroup}
        />

        <Attachments
          attachments={formValues.attachments}
          formValues={formValues}
          isRendered={conversation}
          setFormValues={setFormValues}
        />

        <Footer
          conversation={conversation}
          currentUserId={currentUserId}
          formValues={formValues}
          isRendered={conversation}
          scrollView={scrollView}
          setFormValues={setFormValues}
        />
      </Styled.ListContainer>
    </Styled.Container>
  );
};

export default Messages;
