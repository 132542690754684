import {
  PATIENT_FAQ_URL,
  TestID,
} from 'app/components/App/Navigation/constants';

import { getFaqUrl } from 'app/components/App/Navigation/helpers';

import { useSession } from 'app/components/App/Navigation/hooks';

import { NavigationLink } from './subcomponents';

export const HelpLink = () => {
  const {
    user: { employer },
  } = useSession();

  const faqUrl = getFaqUrl(employer);

  return (
    <NavigationLink
      key={PATIENT_FAQ_URL}
      name="Help"
      path={faqUrl}
      testID={TestID.Navigation.HelpLink}
    />
  );
};
