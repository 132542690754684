import React, { Component, Fragment } from 'react';

import { StyleSheet } from 'react-native';
import { Button, Icon, Text } from 'react-native-elements';
import { Redirect } from '@cross-platform/react-router-native';

import { logEvent, EVENTS } from 'app/util/analytics';
import { Routes } from 'app/util/routes';
import { routeTo } from 'app/util/routeUtils';
import { playSound } from 'app/util/soundUtils';
import theme, { shadows, zIndexes } from 'app/util/theme';

import Anchor from 'app/components/Common/Anchor';
import { DotIndicator } from 'app/components/Common/svg-components';
import FloatingActionButton from 'app/components/Common/FloatingActionButton';
import HtmlView from 'app/components/Common/HtmlView';
import Onboarding from 'app/components/Common/Onboarding';
import { ProgressNav } from 'app/components/Common/ProgressNav';
import SlidingSheet from 'app/components/Common/SlidingSheet';
import SlidingView from 'app/components/Common/SlidingView';
import { ScrollView, View } from 'app/components/Common/Styled';

import ManualTaskButton from './ManualTaskButton';
import Task from './Task';
import Modal from 'app/components/Common/Modal';

interface DashboardProps {
  fetchCurrentEpisode: () => Promise<void>;
  markTaskComplete: (episodeId: string, stepId: string, taskId: string) => void;

  episodeId?: string;
  isPlatformOne?: boolean;
  loggedIn?: boolean;
  progress?: number;
  ready?: boolean;
  steps?: any[];
}
interface State {
  error: boolean;
  help: string;
  showHelp: boolean;
  selectedStep: number;
  showComplete: boolean;
  task: any;
}

export default class Dashboard extends Component<DashboardProps, State> {
  static defaultProps = {
    episodeId: null,
    isPlatformOne: true,
    loggedIn: false,
    progress: 0,
    ready: false,
    steps: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      error: false,
      help: '',
      showHelp: false,
      selectedStep: this.getCurrentStep(),
      showComplete: true,
      task: null,
    };
  }

  slidingView;
  fetchTimeout;
  slideTimeout;

  componentDidMount = () => {
    // Don't attempt to fetch data if the user navigated without logging in.
    if (!this.props.loggedIn) return;

    this.fetchEpisode();
  };

  componentDidUpdate = (prevProps) => {
    // Don't attempt to fetch data if the user just logged out.
    if (!this.props.loggedIn) return;

    // Attempt to refresh the current episode if one is not loaded.
    if (prevProps.ready && !this.props.ready) this.fetchEpisode();

    if (this.state.selectedStep === this.getCurrentStep()) return;

    if (
      (!prevProps.ready && this.props.ready) ||
      prevProps.progress !== this.props.progress
    ) {
      this.setState({ selectedStep: this.getCurrentStep() });
    }
  };

  componentWillUnmount = () => {
    clearTimeout(this.fetchTimeout);
    clearTimeout(this.slideTimeout);
  };

  getCurrentStep = () => {
    const step = this.props.steps.find(({ current }) => current);
    return step ? step.order : 0;
  };

  fetchEpisode = async (attempts = 0) => {
    await this.props.fetchCurrentEpisode();

    if (this.props.ready) return;
    if (attempts > 30) return this.setState({ error: true });

    this.fetchTimeout = setTimeout(() => this.fetchEpisode(attempts + 1), 1000);
  };

  markTaskComplete = (task) => {
    logEvent(EVENTS.tasks.complete, { task: task.title });
    this.props.markTaskComplete(this.props.episodeId, task.stepId, task.id);
  };

  showStepHelp = () => {
    this.setState({ showHelp: true });
  };

  onTaskPress = (task) => {
    if (task.meta.form) {
      routeTo(`/forms/${task.meta.form}`);
    } else if (task.meta.path) {
      routeTo(task.meta.path);
    } else if (task.meta.details) {
      logEvent(EVENTS.tasks.viewDetails, { task: task.title });
      this.setState({ task });
    }

    if (task.taskType === 'link') this.markTaskComplete(task);
  };

  onTaskHelpPress = (task) => {
    playSound('info');
    logEvent(EVENTS.tasks.viewHelp, { task: task.title });
    this.setState({ help: task.meta.help });
  };

  onPreviousStep = () => {
    if (this.slidingView) this.slidingView.slideRight();

    this.slideTimeout = setTimeout(() => {
      this.setState({ selectedStep: this.state.selectedStep - 1 });
    }, 140);
  };

  onNextStep = () => {
    if (this.slidingView) this.slidingView.slideLeft();

    this.slideTimeout = setTimeout(() => {
      this.setState({ selectedStep: this.state.selectedStep + 1 });
    }, 140);
  };

  render() {
    const step = this.props.steps.find(
      ({ order }) => order === this.state.selectedStep
    );
    const completeTasks = step?.tasks.filter(({ complete }) => complete);
    const currentTasks = step?.tasks.filter(
      ({ available, complete }) => available && !complete
    );
    const upcomingTasks = step?.tasks.filter(
      ({ available, complete }) => !available && !complete
    );

    const complete = this.props.ready && this.props.progress === 100;

    if (complete)
      return <Redirect to={`/${Routes.Dashboard}/congratulations`} />;

    return (
      <View style={styles.container}>
        {this.props.isPlatformOne && step?.helpContent?.length > 0 ? (
          <Button
            type="clear"
            icon={<Icon size={54} name="help" style={{ elevation: 3 }} />}
            onPress={this.showStepHelp}
            containerStyle={styles.helpIcon}
          />
        ) : null}
        <ProgressNav open>
          <ScrollView contentContainerStyle={styles.scrollView}>
            <Text h1>Progress</Text>

            {this.state.error ? (
              <View style={styles.loadingContainer}>
                <Text h3>There was a problem loading your information.</Text>
              </View>
            ) : !this.props.ready || !step ? (
              <View style={styles.loadingContainer}>
                <Text h3>Loading your dashboard...</Text>
                <DotIndicator style={{ marginTop: theme.spacing * 1.25 }} />
              </View>
            ) : (
              <SlidingView
                ref={(slidingView) => (this.slidingView = slidingView)}
              >
                <View style={styles.tasksContainer}>
                  {/* UPCOMING TASKS */}
                  {upcomingTasks.length > 0 && (
                    <View>
                      {upcomingTasks.map((task) => (
                        <Task key={task.id} status="upcoming" {...task} />
                      ))}
                    </View>
                  )}

                  {/* CURRENT TASKS */}
                  {currentTasks.length > 0 ? (
                    <View>
                      {currentTasks.map((task) => (
                        <Task
                          key={task.id}
                          onPress={() => this.onTaskPress(task)}
                          onPressHelp={() => this.onTaskHelpPress(task)}
                          status="current"
                          {...task}
                        />
                      ))}
                    </View>
                  ) : (
                    !this.state.showComplete &&
                    upcomingTasks.length === 0 && (
                      <Text h3 style={styles.anchor}>
                        {step.current
                          ? 'Your patient care specialist is reviewing your information.'
                          : "You're all caught up!"}
                      </Text>
                    )
                  )}

                  {/* COMPLETE TASKS */}
                  {completeTasks.length > 0 && this.state.showComplete ? (
                    <Anchor
                      onPress={() => this.setState({ showComplete: false })}
                      title={`HIDE ${completeTasks.length} COMPLETED`}
                      titleStyle={styles.anchor}
                    />
                  ) : (
                    completeTasks.length > 0 && (
                      <Anchor
                        onPress={() => this.setState({ showComplete: true })}
                        style={{ flex: 1 }}
                        title={`SHOW ${completeTasks.length} COMPLETED`}
                        titleStyle={styles.anchor}
                      />
                    )
                  )}
                  {completeTasks.length > 0 && this.state.showComplete && (
                    <View>
                      {completeTasks.map((task) => (
                        <Task status="complete" key={task.id} {...task} />
                      ))}
                    </View>
                  )}
                </View>
              </SlidingView>
            )}
          </ScrollView>
        </ProgressNav>

        <SlidingSheet
          isVisible={Boolean(this.state.showHelp)}
          onClose={() => this.setState({ showHelp: false })}
        >
          <HtmlView
            tagsStyles={{
              body: {
                paddingBottom: theme.spacing * 6,
                paddingHorizontal: theme.spacing * 1.25,
              },
              p: { textAlign: 'center' },
            }}
            html={(step && step.helpContent) || ''}
          />

          <FloatingActionButton
            show={this.state.showHelp}
            icon="close"
            containerStyle={{
              position: 'absolute',
              bottom: 20,
              left: '50%',
              marginLeft: -1.5 * theme.spacing,
            }}
            onPress={() => this.setState({ showHelp: false })}
          />
        </SlidingSheet>

        <Modal
          options={[]}
          isVisible={Boolean(this.state.help)}
          onClose={() => this.setState({ help: null })}
        >
          <Fragment>
            <Icon
              color="#FFCA58"
              name="lightbulb-outline"
              size={100}
              containerStyle={styles.lightbulbIcon}
            />

            <HtmlView
              tagsStyles={{ p: { textAlign: 'center' } }}
              html={this.state.help || ''}
            />

            <Button
              type="outline"
              title="Close"
              containerStyle={{ margin: theme.spacing * 1.25 }}
              onPress={() => this.setState({ help: null })}
            />
          </Fragment>
        </Modal>

        <SlidingSheet
          isVisible={Boolean(this.state.task)}
          onClose={() => this.setState({ task: null })}
        >
          <View
            style={{
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'space-between',
              paddingTop: theme.spacing * 2.5,
            }}
          >
            <ScrollView
              style={{
                alignSelf: 'center',
                paddingHorizontal: theme.spacing * 1.25,
                paddingBottom: theme.spacing,
              }}
            >
              <HtmlView html={this.state?.task?.meta?.details || ''} />
            </ScrollView>
            {this.state.task && this.state.task.taskType === 'manual' && (
              <View style={styles.buttonContainer}>
                <ManualTaskButton
                  markTaskComplete={this.markTaskComplete}
                  resetTask={() => this.setState({ task: null })}
                  task={this.state.task}
                />
              </View>
            )}
          </View>
        </SlidingSheet>

        {this.props.episodeId && this.props.isPlatformOne && (
          <Onboarding
            name={`onboarding.dashboard.${this.props.episodeId}`}
            pages={[
              {
                key: 'success',
                title: 'Success',
                subtitle:
                  "You've taken the first step towards better health!  We'll " +
                  'guide you through the rest of the process.',
                icon: 'check-circle-outline',
                iconType: 'material-community',
                iconColor: theme.colors.success,
              },
              {
                key: 'stay-in-control',
                title: 'Stay in Control',
                subtitle:
                  'Tasks will become available throughout your journey, with ' +
                  'notifications to keep you on track.',
                icon: 'speedometer',
                iconType: 'material-community',
                iconColor: theme.colors.primary,
              },
              {
                key: 'here-to-help',
                title: "We're Here to Help",
                subtitle:
                  'Use secure messaging to get assistance from your ' +
                  'personally assigned Patient Care Specialist.',
                icon: 'forum',
                iconType: 'material-community',
                iconColor: theme.colors.primary,
              },
            ]}
          />
        )}
      </View>
    );
  }
}

// TODO: apply SC - https://carrumhealth.atlassian.net/browse/TEC-2358
const styles = StyleSheet.create({
  buttonContainer: {
    backgroundColor: theme.colors.white,
    ...shadows.medium,
  },

  container: {
    flex: 1,
  },

  loadingContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  tasksContainer: {
    flex: 1,
    padding: theme.spacing * 1.25,
    paddingTop: 0,
  },

  scrollView: {
    paddingBottom: theme.spacing * 6,
  },

  anchor: {
    alignItems: 'center',
    marginTop: theme.spacing / 2,
    marginBottom: theme.spacing * 1.25,
    textAlign: 'center',
  },

  closeIcon: {
    alignSelf: 'flex-end',
    marginTop: theme.spacing * 1.25,
  },

  helpIcon: {
    alignSelf: 'flex-end',
    position: 'absolute',
    zIndex: zIndexes.dashboardProgressHelpIcon,
  },

  lightbulbIcon: {
    marginVertical: theme.spacing * 1.25,
  },
});
