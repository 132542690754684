import React from 'react';

import PropTypes from 'prop-types';
import { openInBrowser } from 'app/util/routeUtils';

import {
  Card,
  CardContent,
  PlayIcon,
  PlayIconContainer,
  VideoTitle,
} from './styles';

PlayIcon.displayName = 'PlayIcon';

const propTypes = {
  thumbnail: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

const SurgeonVideo = ({ thumbnail, title, url }) => (
  <Card>
    <Card.Image source={{ uri: thumbnail }} />
    <CardContent>
      <VideoTitle>{title}</VideoTitle>
      <PlayIconContainer onPress={() => openInBrowser(url)}>
        <PlayIcon />
      </PlayIconContainer>
    </CardContent>
  </Card>
);

SurgeonVideo.propTypes = propTypes;
export default SurgeonVideo;
