import React, { CSSProperties } from 'react';
import { StyledProps } from 'styled-components/native';
import { ProgressStepsContainer, ProgressStep } from './styles';

interface ProgressIndicatorProps {
  pageCount: number;
  pageNumber: number;
  style?: CSSProperties | StyledProps<any>;
}

/**
 * Simple, non-interactive indicator of position in a sequence.
 *
 * @param  props             Required properties to render progress indicator.
 * @param  props.pageCount   Total number of pages in the sequence.
 * @param  props.pageNumber  Currently active page.
 */
const ProgressIndicator = ({
  pageCount,
  pageNumber,
  style = {},
}: ProgressIndicatorProps) => {
  return (
    <ProgressStepsContainer style={style}>
      {Array.from(Array(pageCount).keys()).map((index) => {
        return <ProgressStep key={index} isActive={pageNumber === index} />;
      })}
    </ProgressStepsContainer>
  );
};

export default ProgressIndicator;
