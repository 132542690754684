import { Platform, Text, ViewProps } from 'react-native';

import { Icon } from 'react-native-elements';
import styled, { css } from 'styled-components/native';

import { ResponsivePressable } from 'app/components/Common/ResponsivePressable';
import TrackableButton from 'app/components/Common/TrackableButton';
import { View } from 'app/components/Common/Styled';
import { DefaultTheme } from 'types/theme';

import { NavigationAlignment, NavigationMode } from './types';

interface FlexButtonProps {
  alignment?: NavigationAlignment;
  navigationMode?: NavigationMode;
  disabled?: boolean;
}

interface GetBackgroundColorProps {
  alignment?: NavigationAlignment;
  navigationMode?: NavigationMode;
  disabled?: boolean;
  theme: DefaultTheme;
}

interface GetButtonTextColorProps {
  alignment?: NavigationAlignment;
  navigationMode?: NavigationMode;
  disabled?: boolean;
  theme: DefaultTheme;
}

interface GetFooterJustificationProps {
  navigationMode?: NavigationMode;
  theme: DefaultTheme;
}

interface GetNavigationJustificationProps {
  justificationKey: string;
  navigationMode?: NavigationMode;
}

interface NavigationFooterContainerProps extends ViewProps {
  navigationMode: NavigationMode;
}

interface NavigationButtonProps {
  navigationMode: NavigationMode;
}

interface NavigationViewProps extends ViewProps {
  alignment: NavigationAlignment;
  navigationMode?: NavigationMode;
  disabled?: boolean;
}

const getBackgroundColor = ({
  alignment,
  navigationMode,
  disabled,
  theme,
}: GetBackgroundColorProps) => {
  switch (true) {
    case navigationMode === NavigationMode.PreviousAndNext &&
      alignment === 'left':
      return theme.colors.white;
    case navigationMode === NavigationMode.Centered &&
      theme.windowDimensions.width > theme.breakpoints.xsmall:
    case navigationMode === NavigationMode.DoubleButton:
    case navigationMode === NavigationMode.SkipAndNext &&
      theme.windowDimensions.width > theme.breakpoints.xsmall:
      return theme.colors.sand;
    case disabled:
      return theme.colors.disabledNavBackground;
    default:
      return theme.colors.primary;
  }
};

const getButtonTextColor = ({
  alignment,
  navigationMode,
  disabled,
  theme,
}: GetButtonTextColorProps) => {
  switch (true) {
    case disabled:
      return theme.colors.white;
    case alignment === 'left' &&
      theme.windowDimensions.width > theme.breakpoints.xsmall:
      return theme.colors.primary;
    default:
      return theme.colors.white;
  }
};

const getFooterJustification = ({
  navigationMode,
  theme,
}: GetFooterJustificationProps) => {
  if (
    navigationMode === NavigationMode.Centered &&
    theme.windowDimensions.width > theme.breakpoints.xsmall
  ) {
    return 'center';
  } else {
    return 'space-between';
  }
};
const getFooterPosition = ({ navigationMode }) => {
  if (navigationMode === NavigationMode.SkipAndNext) return 'absolute';
  return Platform.OS === 'web' ? 'fixed' : 'absolute';
};

const getNavigationButtonMaxWidth = (theme) => {
  switch (true) {
    case theme.windowDimensions.width <= theme.breakpoints.xsmall:
      return theme.spacing * 10;
    case theme.windowDimensions.width <= theme.breakpoints.small:
      return theme.spacing * 21;
    default:
      return theme.spacing * 100;
  }
};

const getNavigationButtonMinWidth = (theme, navigationMode) => {
  switch (true) {
    case navigationMode !== NavigationMode.DoubleButton:
    case navigationMode === NavigationMode.DoubleButton &&
      theme.windowDimensions.width > theme.breakpoints.small:
      return theme.spacing * 21;
    default:
      return theme.spacing * 11;
  }
};

const getNavigationButtonPadding = (theme, navigationMode) => {
  switch (true) {
    case navigationMode === NavigationMode.DoubleButton &&
      theme.windowDimensions.width <= theme.breakpoints.xsmall:
      return {
        top: theme.spacing * 0.75,
        bottom: theme.spacing * 0.75,
        right: theme.spacing * 1.25,
        left: theme.spacing * 0.25,
      };
    default:
      return {
        top: theme.spacing * 0.75,
        right: theme.spacing * 0.75,
        bottom: theme.spacing * 0.75,
        left: theme.spacing * 0.75,
      };
  }
};

const getNavigationJustification = ({
  justificationKey,
  navigationMode,
}: GetNavigationJustificationProps) => {
  switch (justificationKey) {
    case 'left':
      return navigationMode === NavigationMode.DoubleButton
        ? 'flex-end'
        : 'flex-start';
    case 'center':
      return 'center';
    default:
      return navigationMode === NavigationMode.DoubleButton
        ? 'flex-start'
        : 'flex-end';
  }
};

const getNavigationViewPadding = (theme, navigationMode) => {
  switch (true) {
    case navigationMode === NavigationMode.DoubleButton &&
      theme.windowDimensions.width <= theme.breakpoints.xsmall:
      return `${theme.spacing * 1.25}px ${theme.spacing / 4}px`;
    default:
      return `${theme.spacing * 1.25}px`;
  }
};

export const ButtonIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.colors.white,
  name: 'arrow-right',
  size: 16,
  type: 'font-awesome',
}))`
  margin-left: ${({ theme }) => theme.spacing / 2}px;
`;

export const FlexButton = styled(Text)<FlexButtonProps>`
  ${({
    alignment = NavigationAlignment.Right,
    navigationMode,
    disabled,
    theme,
  }) => css`
    color: ${getButtonTextColor({
      alignment,
      navigationMode,
      disabled,
      theme,
    })};
    font-family: ${theme.fontFamilyBold};
    font-size: ${theme.fontSizes.body2}px;
  `}
`;

export const NavigationButton = styled(TrackableButton).attrs(
  ({ theme, navigationMode, type }) => {
    const padding = getNavigationButtonPadding(theme, navigationMode);
    const minWidth = getNavigationButtonMinWidth(theme, navigationMode);
    const maxWidth = getNavigationButtonMaxWidth(theme);
    return {
      buttonStyle: {
        paddingBottom: padding.bottom,
        paddingRight: padding.right,
        paddingLeft: padding.left,
        paddingTop: padding.top,
        maxWidth: maxWidth,
        minWidth: minWidth,
        borderColor: type !== 'outline' ? null : theme.colors.primary,
        borderWidth: type !== 'outline' ? 0 : 1,
        minHeight: theme.spacing * 4,
      },
      disabledStyle: {
        backgroundColor:
          type !== 'outline'
            ? theme.colors.disabledNavBackground
            : theme.colors.defaultLight,
      },
      disabledTitleStyle: {
        color: theme.colors.disabledNavText,
      },
      titleStyle: {
        color: type === 'outline' ? theme.colors.primary : theme.colors.white,
        fontFamily: theme.fontFamilyMedium,
        fontSize: theme.rawFontSizes[theme.isNarrow ? 'xsmall' : 'medium'],
      },
    };
  }
)<NavigationButtonProps>`
  ${({ theme, navigationMode }) => css`
    margin: 0;
    min-width: ${getNavigationButtonMinWidth(theme, navigationMode)}px;
    max-width: ${getNavigationButtonMaxWidth(theme)}px;
  `}
`;

export const NavigationFooterContainer = styled(
  View
)<NavigationFooterContainerProps>`
  align-items: center;
  background: ${(props) => getBackgroundColor(props)};
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => getFooterJustification(props)};
  left: 0;
  min-height: ${Platform.OS === 'ios' ? '70' : '60'}px;
  position: ${(props) => getFooterPosition(props)};
  right: 0;
  z-index: ${({ theme }) => theme.zIndexes.overlay};
`;

export const NavigationView = styled(View)<NavigationViewProps>`
  ${({ theme, navigationMode, alignment: justificationKey }) => css`
    align-content: center;
    align-items: center;
    background-color: ${(props) => getBackgroundColor(props)};
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: ${getNavigationJustification({
      justificationKey,
      navigationMode,
    })};
    padding: ${getNavigationViewPadding(theme, navigationMode)};
    pointer-events: auto;
  `}
`;

export const PressableArea = styled(ResponsivePressable)`
  align-content: stretch;
  align-items: stretch;
  flex: 1 0 auto;
  justify-content: center;
`;
