import * as WebBrowser from 'expo-web-browser';
import { Platform } from 'react-native';

import { PATIENT_FAQ_URL } from 'app/util/constants';
import history from 'app/util/history';

/**
 * Handle relative and absolute links on both web and mobile.
 */
export const routeTo = (url) => {
  // Redirect local links using react-router.
  if (/^\//.test(url)) return history.push(url);
  // Open web links in a new tab in web browsers.
  if (Platform.OS === 'web') return window.open(url);
  // Open web links in a local window on native devices.
  openInBrowser(url);
};

export const routeToFAQ = () => routeTo(PATIENT_FAQ_URL);

/**
 * Opens the given URL in a WebBrowser that overlays the current screen in
 * native devices, or opens in a new tab in web browsers.
 */
export const openInBrowser = (url: string) => {
  WebBrowser.openBrowserAsync(url, { windowName: '_blank' });
};
