import { ReactNode } from 'react';
import { TextProps, PressableProps } from 'react-native';
import styled, { css } from 'styled-components/native';
import { DefaultTheme } from 'types/theme';

import { Mode } from 'app/components/Common/CarrumGoldExperienceBenefits/types';
import { ResponsivePressable } from 'app/components/Common/ResponsivePressable';
import { Text, View } from 'app/components/Common/Styled';
import {
  CareIcon,
  CheckMark,
  SavingsIcon,
  QualityIcon,
} from 'app/components/Common/svg-components';
import breakpoints from 'app/util/breakpoints';
import theme from 'app/util/theme';

export {
  CareModal,
  CostModal,
  QualityModal,
} from 'app/components/BenefitModals/GoldBenefits';
export {
  GoldLaurelsLeft,
  GoldLaurelsRight,
} from 'app/components/Common/svg-components';

interface BenefitsIconProps {
  mode: string;
}

interface CardTextProps extends TextProps {
  mode: Mode;
}

interface CardWrapperProps extends PressableProps {
  mode: Mode;
  children: ReactNode;
}

interface PlacementWrapper {
  placement: 'left' | 'right';
}

/** Return the design configuration (size and color) for an icon based on its type. */
const getBenefitsIconAttributes = (mode: string, theme: DefaultTheme) => {
  return {
    fill: mode === 'light' ? theme.colors.gold : theme.colors.white,
    height: theme.isNarrow ? theme.spacing * 4 : theme.spacing * 6,
    width: theme.isNarrow ? theme.spacing * 4 : theme.spacing * 6,
  };
};

const getBreakpointBeforeLaurels = (theme: DefaultTheme) => theme.spacing * 41; // final width before laurels display: ;

/** Return the number of pixels required to display laurels. */
const getLaurelOffset = (theme: DefaultTheme): number => theme.spacing * -4;

/**
 * Return the width of the wrapper around the benefits cards based on (1) the
 * width of the window and (2) whether or not the progress nav is expected to
 * display.
 */
const getBenefitsCardsWrapperWidth = (theme: DefaultTheme) => {
  const laurelsWidth = theme.spacing * 10; // includes padding and svg, left and right
  const progressNavWidth = theme.spacing * 25; // the nav component decides when it appears
  const maximumWidth = theme.spacing * 50; // wrapper becomes fixed-width and centered at 800px
  const padding = theme.spacing * 4; // the padding from the containing element
  const windowWidth = theme.windowDimensions.width;

  const toPixels = (value: number): string => `${value}px`;

  const navNoLaurelsNo = windowWidth <= getBreakpointBeforeLaurels(theme); // 0-656
  const navNoLaurelsYesWithFlexibleWidth =
    windowWidth > getBreakpointBeforeLaurels(theme) &&
    windowWidth < maximumWidth; // 657-799
  const navNoLaurelsYesWithFixedWidth =
    windowWidth >= maximumWidth && windowWidth < theme.breakpoints.medium; // 800-1023
  const navYesLaurelsNo =
    windowWidth >= theme.breakpoints.medium &&
    windowWidth < theme.breakpoints.large; // 1024-1199
  const navYesLaurelsYes = windowWidth >= theme.breakpoints.large; // 1200+

  switch (true) {
    case navNoLaurelsNo: // 0-656
      return '100%';
    case navNoLaurelsYesWithFlexibleWidth: // 657-799
      return toPixels(windowWidth - laurelsWidth - padding);
    case navNoLaurelsYesWithFixedWidth: // 800-1023
      return toPixels(maximumWidth - laurelsWidth - padding);
    case navYesLaurelsNo: // 1024-1199
      return toPixels(windowWidth - progressNavWidth - padding);
    case navYesLaurelsYes: // 1200+
      return toPixels(maximumWidth - laurelsWidth - padding);
    default:
      // NOTE: this should never be hit
      return '100%';
  }
};

/** Return the width of the value propisition card. */
const getCardWidth = (theme: DefaultTheme) => {
  // Local breakpoints are for optimizing display on smaller mobile screens. These
  // need not be promoted to the SCTheme unless/until they are useful for another
  // screen.
  const windowWidth = theme.windowDimensions.width;
  const breakpoints = theme.breakpoints;
  switch (true) {
    case windowWidth >= breakpoints.large:
      return theme.spacing * 10;
    case windowWidth >= breakpoints.medium:
      return theme.spacing * 10;
    case windowWidth >= breakpoints.small:
      return theme.spacing * 10;
    case windowWidth >= breakpoints.xsmall:
      return theme.spacing * 8;
    case windowWidth >= theme.spacing * 26: // local breakpoint
      return theme.spacing * 8;
    case windowWidth >= theme.spacing * 23: // local breakpoint
      return theme.spacing * 6;
    default:
      return theme.spacing * 6;
  }
};

const getCardTextFontSize = (theme: DefaultTheme) => {
  switch (true) {
    case theme.windowDimensions.width < theme.spacing * 26.25:
      return theme.rawFontSizes.xxsmall;
    case theme.windowDimensions.width < theme.breakpoints.xsmall:
      return theme.rawFontSizes.xsmall;
    default:
      return theme.rawFontSizes.small;
  }
};

const getGoldExperienceTextFontSize = (theme: DefaultTheme) => {
  return theme.windowDimensions.width < theme.breakpoints.small
    ? theme.rawFontSizes.large
    : theme.rawFontSizes.xxlarge;
};

const getResponsiveSize = (
  theme: DefaultTheme,
  narrowMultiplier: number,
  standardMultiplier: number
): string => {
  const multiplier = theme.isNarrow ? narrowMultiplier : standardMultiplier;
  const size = theme.spacing * multiplier;
  return `${size}px`;
};

const getCardWrapperMarginSize = (theme: DefaultTheme) => {
  const windowWidth = theme.windowDimensions.width;
  switch (true) {
    case windowWidth <= theme.spacing * 26.25: // 0-420
      return theme.spacing * 0.25;
    case windowWidth <= theme.breakpoints.xsmall: // 421-480
      return theme.spacing * 0.5;
    default:
      return theme.spacing * 0.625; // 481+
  }
};

/**
 * Determine whether the laurel elements are displayed, based on window
 * dimensions. Laurels appear conditionally when sufficient space is available.
 * The space requirement is measured within two distinct ranges: (1) the range
 * when the progress nav displays, and (2) the range when it does not.
 */
export const hasLaurels = (windowWidth: number): boolean => {
  const hasLaurelsWithNav = windowWidth >= breakpoints.large;
  const hasLaurelsWithoutNav =
    windowWidth > getBreakpointBeforeLaurels(theme) &&
    windowWidth < breakpoints.medium;
  return hasLaurelsWithNav || hasLaurelsWithoutNav;
};

export const AlignmentWrapper = styled(View)`
  ${({ theme }) => css`
    flex: 1 0 auto;
    margin: 0 auto;
    max-width: ${theme.breakpoints.small + theme.spacing * 3}px;
    overflow: visible;
  `}
`;

export const BenefitsCostIcon = styled(SavingsIcon).attrs<BenefitsIconProps>(
  ({ mode, theme }) => getBenefitsIconAttributes(mode, theme)
)``;

export const BenefitsQualityIcon = styled(QualityIcon).attrs<BenefitsIconProps>(
  ({ mode, theme }) => getBenefitsIconAttributes(mode, theme)
)``;

export const BenefitsSpecialistIcon = styled(CareIcon).attrs<BenefitsIconProps>(
  ({ mode, theme }) => getBenefitsIconAttributes(mode, theme)
)``;

export const CardTextWrapper = styled(View)`
  align-items: center;
  padding: 0;
`;

export const CardText = styled(Text).attrs<CardTextProps>(({ mode }) => ({
  align: 'center',
  color: mode === 'light' ? 'bodyText' : 'white',
}))<CardTextProps>`
  ${({ theme }) => css`
    font-size: ${getCardTextFontSize(theme)}px;
  `}
`;

const getCardWrapperHeight = (theme: DefaultTheme) => {
  const windowWidth = theme.windowDimensions.width;
  switch (true) {
    case windowWidth <= theme.spacing * 26.25: // 0-420
      return 8.25;
    case windowWidth <= theme.breakpoints.xsmall: // 421-480
      return 8;
    default:
      return 19; // 481+
  }
};

export const CardWrapper = styled(ResponsivePressable)<CardWrapperProps>`
  ${({ mode, theme }) => {
    return css`
      align-items: center;
      background-color: ${mode === 'light'
        ? theme.colors.white
        : theme.colors.primary};
      border-radius: ${theme.spacing / 4}px;
      flex: 1 0 auto;
      flex-direction: column;
      flex-grow: 1;
      height: ${theme.spacing * getCardWrapperHeight(theme)}px;
      justify-content: space-between;
      margin: ${getCardWrapperMarginSize(theme)}px;
      max-height: ${getResponsiveSize(theme, 9, 12)};
      padding: ${getResponsiveSize(theme, 0.75, 1.5)}
        ${getResponsiveSize(theme, 0.5, 1.5)};
      width: ${getCardWidth(theme)}px;
    `;
  }}
`;

export const CarrumBenefits = styled(View)`
  ${({ theme }) => {
    return css`
      align-items: center;
      background-color: ${theme.colors.gold}20;
      border-radius: ${theme.isNarrow ? 0 : theme.spacing / 4}px;
      flex: 0 1 auto;
      justify-content: center;
      width: ${getBenefitsCardsWrapperWidth(theme)};
      margin: 0
        ${hasLaurels(theme.windowDimensions.width)
          ? `${theme.spacing * 5}px`
          : 0};
    `;
  }}
`;

export const CarrumBenefitsCards = styled(View)`
  ${({ theme }) => {
    const getResponsivePaddingSize = () => {
      const windowWidth = theme.windowDimensions.width;
      switch (true) {
        case windowWidth <= theme.spacing * 26.25: // 0-420
          return 1;
        case windowWidth <= theme.breakpoints.xsmall: // 421-480
          return 0.75;
        default:
          return 0.625; // 481+
      }
    };

    return css`
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: ${theme.spacing * getResponsivePaddingSize()}px;
      width: 100%;
    `;
  }}
`;

export const GoldCheckMark = styled(CheckMark).attrs({
  size: 30,
  color: theme.colors.gold,
})``;

export const GoldCheckMarkWrapper = styled(View)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing / 2}px;
  `}
`;

export const GoldExperienceText = styled(Text).attrs({
  color: 'gold',
  medium: true,
})`
  ${({ theme }) => css`
    font-size: ${getGoldExperienceTextFontSize(theme)}px;
  `}
`;

export const GoldExperienceTextWrapper = styled(View)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing / 2}px;
  `}
`;

export const GoldExperienceWrapper = styled(View)`
  ${({ theme }) => css`
    align-items: center;
    flex-direction: row;
    justify-content: center;
    padding-top: 0
    padding-bottom: ${theme.spacing * 1.25}px;
  `}
`;

export const GoldExperienceGoldText = styled(Text).attrs({
  color: 'white',
  medium: true,
})`
  ${({ theme }) => css`
    font-size: ${getGoldExperienceTextFontSize(theme)}px;
  `}
`;

export const GoldExperienceGoldTextWrapper = styled(GoldExperienceTextWrapper)`
  ${({ theme }) => css`
    background-color: ${theme.colors.gold};
    border-radius: ${theme.spacing / 4}px;
    padding: ${theme.spacing / 4}px ${theme.spacing / 2}px;
  `}
`;

export const LaurelWrapper = styled(View)<PlacementWrapper>`
  ${({ theme, placement }) => css`
    position: absolute;
    bottom: ${theme.spacing}px;
    ${placement}: ${getLaurelOffset(theme)}px;
  `}
`;
