import React from 'react';

import careIcon from 'app/assets/images/care.png';
import doctorsIcon from 'app/assets/images/doctor.png';
import doctorIcon from 'app/assets/images/doctor-2.png';
import calendarIcon from 'app/assets/images/calendar.png';
import surgeryIcon from 'app/assets/images/surgery.png';
import recoveryIcon from 'app/assets/images/recovery.png';
import Text from 'app/components/Common/Styled/Text';

import HowItWorksSection from './HowItWorksSection';
import { Container, Disclaimer, Paragraph, UnorderedList } from './styles';

const HowItWorks = () => (
  <Container>
    <HowItWorksSection
      title="Meet Your Personal Care Concierge"
      icon={careIcon}
      number={1}
    >
      <Paragraph>
        When you contact Carrum, you will be introduced to your personal Care
        Concierge, who will support you throughout the entire surgical
        experience.
      </Paragraph>
    </HowItWorksSection>

    <HowItWorksSection
      title="Compare and Select Your Hospital and Surgeon"
      icon={doctorsIcon}
      number={2}
    >
      <Paragraph>
        Compare the quality of Carrum surgeons versus other surgeons in your
        region, as well as your potential cost savings. Then select a surgeon to
        perform your procedure. Your care concierge can provide you with any
        guidance and advice that you need to make this decision.
      </Paragraph>
    </HowItWorksSection>

    <HowItWorksSection
      title="Receive Clearance for Surgery"
      icon={doctorIcon}
      number={3}
    >
      <Paragraph>
        Your Care Concierge will contact you to sign your medical records
        release form and begin gathering your medical records to send to your
        chosen surgeon.
      </Paragraph>
      <Paragraph>
        Once the medical records have been reviewed, your Care Concierge will
        connect you with your surgeon to review your treatment recommendation.
        If surgery is deemed necessary, you can consider your options and then
        elect to proceed with surgery through Carrum Health.
      </Paragraph>
    </HowItWorksSection>

    <HowItWorksSection
      title="Receive Full Support Preparing for Surgery"
      icon={calendarIcon}
      number={4}
    >
      <Paragraph>
        Your Care Specialist will guide you step by step so you know what to
        expect leading up to the day of surgery including:
      </Paragraph>
      <UnorderedList
        items={[
          'Work with your Center of Excellence to schedule your procedure and travel logistics (if applicable).',
          'Assist with scheduling of follow-up visits including physical therapy or nutrition.',
          'Prepare for your procedure with educational materials from your surgeon.',
        ]}
      />
      <Paragraph>
        Prior to surgery, you will receive a pre-paid debit card in the mail
        with funds for your per diem (e.g. meals and other incidentals).
      </Paragraph>
    </HowItWorksSection>

    <HowItWorksSection title="Have Your Surgery*" icon={surgeryIcon} number={5}>
      <Paragraph>
        <Text bold>Day 1</Text>: You will check into the hospital, meet your
        surgeon and undergo a final medical evaluation.
      </Paragraph>
      <Paragraph>
        <Text bold>Day 2-3</Text>: The operation is completed successfully and
        you will stay at the hospital to relax and recover.
      </Paragraph>
      <Paragraph>
        <Text bold>Day 4</Text>: You will be discharged from the hospital.
        Depending on whether travel was necessary, you will either spend a few
        days at your hotel before traveling home, or travel home from the
        hospital.
      </Paragraph>
      <Disclaimer>
        * The itinerary may vary depending on your condition and surgical
        procedure. Please talk to your Care Concierge about your surgery
        specific schedule by calling 1-888-855-7806.
      </Disclaimer>
    </HowItWorksSection>

    <HowItWorksSection
      title="Recover Smoothly with Total Care Coordination"
      icon={recoveryIcon}
      number={6}
    >
      <Paragraph>
        After arriving back home, your Care Concierge will call you to walk you
        through the post-discharge care plan and schedule all remaining medical
        visits. Your Care Concierge will monitor your progress until you return
        to work.
      </Paragraph>
    </HowItWorksSection>
  </Container>
);

export default HowItWorks;
