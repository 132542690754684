import * as Sentry from '@sentry/react-native';

import { logError } from 'app/util/methods';
import { ENVIRONMENT } from 'app/util/constants';

export default (store) => (next) => (action) => {
  if (ENVIRONMENT !== 'development') {
    Sentry.setContext('lastAction', action);
    Sentry.setContext('state', store.getState());

    Sentry.addBreadcrumb({
      category: 'redux-action',
      message: action.type,
      level: 'info',
    });
  }

  try {
    return next(action);
  } catch (error) {
    logError(error);
    throw error;
  }
};
