import styled, { css } from 'styled-components/native';

import { Text, View } from 'app/components/Common/Styled';

export const AlignmentWrapper = styled(View)`
  ${({ theme }) => css`
    flex: 1 0 auto;
    margin: 0 auto;
    max-width: ${theme.spacing * 50}px;
  `}
`;

export { default as CarrumGoldExperienceBenefits } from 'app/components/Common/CarrumGoldExperienceBenefits';

export const FooterText = styled(Text).attrs({
  size: 'body1',
})``;

export const FooterTextWrapper = styled(View)`
  ${({ theme }) => css`
    align-items: center;
    justify-content: center;
    margin: ${theme.spacing}px;
  `}
`;
