import {
  Anchor,
  BodyText,
  ErrorContainer,
  Routes,
  Text,
} from 'app/components/Register/styles';

const RegisterError = () => (
  <ErrorContainer>
    <Text h1>Account Activation Failed</Text>

    <BodyText>
      There was a problem activating your account. Please&nbsp;
      <Anchor to={`/${Routes.Register}`} title="try again" />.
    </BodyText>
  </ErrorContainer>
);

export default RegisterError;
