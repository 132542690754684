import React from 'react';
import { useHistory } from '@cross-platform/react-router-native';

import { showGlobalError } from 'app/actions/uiActions';

import Anchor from 'app/components/Common/Anchor';
import { JourneyPhaseIntroPage } from 'app/components/Common/Layout';
import { Testimonial } from 'app/components/Common/Testimonial';

import { useDispatch, useEpisode, useSession } from 'app/hooks';
import { getFaqUrl } from 'app/util/methods';
import { Routes } from 'app/util/routes';
import { TestID } from 'app/util/test-id';

import {
  DisclaimerText,
  Greeting,
  Paragraph,
  TestimonialWrapper,
} from './styles';

const Introduction = () => {
  const { user } = useSession();
  const { episode } = useEpisode();
  const dispatch = useDispatch();
  const history = useHistory();

  const onContinue = () => {
    if (!episode.location) {
      dispatch(
        showGlobalError(
          `Episode in Step 3 is missing location/address data (episode ID: ${episode.id})`
        )
      );
      return;
    }
    history.push(`/${Routes.SelectYourDoctor}/facilities`);
  };

  return (
    <JourneyPhaseIntroPage
      footerContent={
        <DisclaimerText>
          Please note that some of the provider details like cost, travel time
          are estimates based on historical data. Read more about how we
          calculate them in our{' '}
          <Anchor to={getFaqUrl(user.employer)} title="FAQ" />. Before
          finializing your selection, we recommend that you independently
          confirm details with the provider.
        </DisclaimerText>
      }
      onContinue={onContinue}
      testID={TestID.SelectYourDoctor.Introduction}
      title="Select your doctor"
    >
      <Greeting>Hi {user.firstName},</Greeting>

      <Paragraph>
        In this step you are going to select your facility and doctor.
      </Paragraph>
      <Paragraph>
        We partner with the top 10% of doctors and facilities across the nation,
        to enable you to receive the best care and the care that is right for
        you.
      </Paragraph>
      <Paragraph>
        See what other Carrum patients have to say about their experience.
      </Paragraph>
      <TestimonialWrapper>
        <Testimonial testimonialKey="leslie-knee-replacement" />
        <Testimonial testimonialKey="melody-knee-replacement" />
      </TestimonialWrapper>
    </JourneyPhaseIntroPage>
  );
};

export default Introduction;
