/**
 * Helper functions for the `<VisibilitySensor />` component.
 *
 *
 * NOTE: These functions are isolated into a separate file to support
 *       mocking out the visibility in tests.
 */

export const isInViewPort = (dimensions, window) => {
  return (
    dimensions.rectBottom != 0 &&
    dimensions.rectTop >= 0 &&
    dimensions.rectBottom <= window.height &&
    dimensions.rectWidth > 0 &&
    dimensions.rectWidth <= window.width
  );
};
