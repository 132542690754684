import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  getCostEstimate,
  receiveEstimatesError,
} from 'app/actions/episodeActions';
import useEpisode from 'app/hooks/useEpisode';

const useOopEstimate = () => {
  const { costEstimate, costEstimateLoading, procedure, estimatesError } =
    useEpisode();

  const dispatch = useDispatch();

  /**
   * Attempt to fetch the records for this hook immediately opon loading the
   * selected procedure for the current episode (if any).
   */
  useEffect(() => {
    if (!procedure || costEstimateLoading || costEstimate) return;

    refresh();

    // reset error state on unmount
    return () => {
      dispatch(receiveEstimatesError(null));
    };
  }, [procedure]);

  const refresh = useCallback(() => {
    if (costEstimateLoading) return; // Prevent parallel API calls.
    if (!procedure?.key) return; // Require a selected procedure.

    dispatch(getCostEstimate(procedure?.key));
  }, [dispatch, costEstimateLoading, procedure?.key]);

  return {
    data: {
      record: costEstimate,
    },
    loading: costEstimateLoading,
    refresh,
    error:
      estimatesError === 'oop' || estimatesError === 'both'
        ? estimatesError
        : null,
  };
};

export default useOopEstimate;
