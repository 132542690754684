import theme from 'app/util/theme';
import React from 'react';
import Svg, { Circle } from 'react-native-svg';

interface Props {
  borderColor?: string;
  color?: string;
  height?: number;
  width?: number;
}

const MapDot = ({
  borderColor = theme.colors.white,
  color = '#3872E0',
  height = 20,
  width = 20,
}: Props) => (
  <Svg width={width} height={height} viewBox="0 0 20 20" fill="none">
    <Circle
      cx="10"
      cy="10"
      r="9"
      fill={color}
      stroke={borderColor}
      strokeWidth="2"
    />
  </Svg>
);

export default MapDot;
