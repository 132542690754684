export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER';
export const RECEIVE_USER_LOCATION = 'RECEIVE_USER_LOCATION';
export const RECEIVE_USER_EMPLOYER = 'RECEIVE_USER_EMPLOYER';
export const RECEIVE_USER_INSURER = 'RECEIVE_USER_INSURER';

export const receiveCurrentUser = (user, features) => {
  return {
    type: RECEIVE_CURRENT_USER,
    user,
    features,
  };
};

export const receiveUserLocation = (location) => {
  return {
    type: RECEIVE_USER_LOCATION,
    location,
  };
};

export const receiveUserEmployer = (employer) => {
  return {
    type: RECEIVE_USER_EMPLOYER,
    employer,
  };
};

export const receiveUserInsurer = (insurer) => {
  return {
    type: RECEIVE_USER_INSURER,
    insurer,
  };
};
