import React from 'react';

import PropTypes from 'prop-types';

import { Container, Icon, ScrollView, Title } from './styles';

const propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,

  icon: PropTypes.string,
};

const defaultProps = {
  icon: 'build-circle',
};

const IconScreen = ({ children, icon, title }) => (
  <ScrollView>
    <Container>
      <Icon name={icon} />
      <Title>{title}</Title>

      {children}
    </Container>
  </ScrollView>
);

export default IconScreen;

IconScreen.propTypes = propTypes;
IconScreen.defaultProps = defaultProps;
