import { TestID } from './constants';

import {
  fetchOfferedProcedure,
  getIsLoggedIn,
  getOfferedProcedure,
} from './helpers';

import {
  useCallback,
  useDispatch,
  useEffect,
  useEpisode,
  useParams,
  useSelector,
  useState,
} from './hooks';

import { MatchParams, State } from './types';

import {
  ProcedureDescriptionBackLink,
  ProcedureDescriptionContent,
  ProcedureDescriptionLoadingIndicator,
  ProcedureDescriptionNavFooter,
  ProcedureDescriptionWrapper,
} from './subcomponents';

const ProcedureDescription = () => {
  const dispatch = useDispatch();
  const episodeState = useEpisode();
  const { id } = useParams<MatchParams>();

  const [componentHasMounted, setComponentHasMounted] = useState(false);

  const isLoggedIn = useSelector((state: State.RootState) =>
    getIsLoggedIn(state.session)
  );

  const procedure = useSelector((state: State.RootState) =>
    getOfferedProcedure(state, id)
  );

  const [procedureIsLoading, setProcedureIsLoading] = useState(
    procedure?.key !== id
  );

  const fetchSpecifiedProcedure = useCallback(
    (id) => {
      dispatch(fetchOfferedProcedure(id));
    },
    [dispatch]
  );

  /** Refresh current episode on mount.  */
  useEffect(() => {
    if (!componentHasMounted) {
      setComponentHasMounted(true);
      episodeState.refresh();
    }
  }, [componentHasMounted, episodeState]);

  /** Fetch the specified procedure for logged in users (if necessary). */
  useEffect(() => {
    if (isLoggedIn && id && procedure?.key !== id) fetchSpecifiedProcedure(id);
  }, [fetchSpecifiedProcedure, id, isLoggedIn, procedure?.key]);

  /** Toggle the page loading state off after the procedure has been fetched. */
  useEffect(
    function toggleLoadingState() {
      if (procedure) setProcedureIsLoading(false);
    },
    [procedure]
  );

  return (
    <ProcedureDescription.Wrapper
      testID={TestID.ProcedureDescription.Page}
      footer={
        <ProcedureDescription.NavFooter
          episodeIsAlreadyCreated={Boolean(episodeState.episode)}
          id={id}
          isVisible={!episodeState.loading}
          procedure={procedure}
        />
      }
    >
      <ProcedureDescription.BackLink
        testID={TestID.ProcedureDescription.BackLink}
      />
      <ProcedureDescription.LoadingIndicator
        testID={TestID.ProcedureDescription.LoadingIndicator}
        isVisible={procedureIsLoading}
      />
      <ProcedureDescription.Content procedure={procedure} />
    </ProcedureDescription.Wrapper>
  );
};

ProcedureDescription.BackLink = ProcedureDescriptionBackLink;
ProcedureDescription.Content = ProcedureDescriptionContent;
ProcedureDescription.LoadingIndicator = ProcedureDescriptionLoadingIndicator;
ProcedureDescription.NavFooter = ProcedureDescriptionNavFooter;
ProcedureDescription.Wrapper = ProcedureDescriptionWrapper;

export default ProcedureDescription; //NOTE: compound component
