import axios from 'axios';
import { showGlobalError } from 'app/actions/uiActions';
import { CORE_API_URL } from 'app/util/constants';

type RequestParams = {
  magic_link: boolean;
  email: string;
  type?: string;
};

/**
 * Post a request to the core service to send a magic link to the associated
 * user email. Modify request parameters based on whether the user needs
 * standard authentication link or a password reset link.
 *
 * @param   userEmail        email address for the user
 * @param   isPasswordReset  whether to request password reset
 *
 * @return                   promise that resolves when the request is posted
 */
export default ({ userEmail, isPasswordReset = false }) => {
  const requestParams: RequestParams = {
    magic_link: true,
    email: userEmail,
  };

  if (isPasswordReset) requestParams.type = 'request_magic_password_reset';

  return async (dispatch) => {
    try {
      return axios.post(
        `${CORE_API_URL}/users/request_password_reset`,
        requestParams
      );
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
};
