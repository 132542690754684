import React from 'react';

import { Button, ButtonText, TextRow } from './styles';
import { GoldBackground, SilverBackground } from '../styles';
import { TestID } from 'app/util/test-id';

import ContactMeButton from '../ContactMeButton';
import { useSession } from 'app/hooks';

export const GoldButton = ({ onPress }) => (
  <Button
    isFirstButton
    onPress={onPress}
    title={
      <TextRow>
        <ButtonText>Show Only&nbsp;</ButtonText>
        <GoldBackground>Gold</GoldBackground>
      </TextRow>
    }
  />
);

export const SilverButton = ({ onPress }) => {
  const { user } = useSession();
  return (
    <>
      {user?.employer?.contract?.carrumLocalSelfSelection ? (
        <ContactMeButton />
      ) : (
        <Button
          testID={TestID.SelectYourDoctor.ShowSilverButton}
          onPress={onPress}
          title={
            <TextRow>
              <ButtonText>Include&nbsp;</ButtonText>
              <SilverBackground>Silver</SilverBackground>
            </TextRow>
          }
        />
      )}
    </>
  );
};
