import React from 'react';
import Svg, { G, Circle, ClipPath, Defs, Path, Rect } from 'react-native-svg';

interface ProgressNavCompleteProps {
  size: number;
}

/**
 * Renders the Progress Nav Complete Icon as an SVG.
 */
const ProgressNavComplete = ({ size }: ProgressNavCompleteProps) => (
  <Svg
    version="1.1"
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Circle cx="16" cy="16" r="11" fill="#3A4750" />
    <Circle cx="16" cy="16" r="8" fill="white" />
    <G clipPath="url(#clip0_1550_34218)">
      <Path
        d="M14.3966 19.5802L11.1466 16.3302C10.9513 16.1349 10.9513 15.8184 11.1466 15.6231L11.8537 14.916C12.0489 14.7207 12.3655 14.7207 12.5608 14.916L14.7501 17.1053L19.4395 12.416C19.6347 12.2207 19.9513 12.2207 20.1466 12.416L20.8537 13.1231C21.0489 13.3183 21.0489 13.6349 20.8537 13.8302L15.1037 19.5802C14.9084 19.7755 14.5918 19.7755 14.3966 19.5802V19.5802Z"
        fill="#3A4750"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_1550_34218">
        <Rect
          width="10"
          height="10"
          fill="white"
          transform="translate(11 11)"
        />
      </ClipPath>
    </Defs>
  </Svg>
);

export default ProgressNavComplete;
