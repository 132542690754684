import { Image } from 'react-native';
import styled, { css } from 'styled-components/native';

import { Text as TextRaw, View } from 'app/components/Common/Styled';
import { PlaceCircle } from 'app/components/Common/svg-components';

export { default as FacilityDetailsWrapper } from '../FacilityDetailsWrapper';
export { default as ExperienceLabel } from 'app/components/Common/ExperienceLabel';
export { default as HtmlView } from 'app/components/Common/HtmlView';
export {
  BenefitCircleCare,
  BenefitCircleNoCare,
  BenefitCircleNoSavings,
  BenefitCircleQuality,
  BenefitCircleSavings,
} from 'app/components/Common/svg-components';
export { default as TravelDuration } from 'app/components/Common/TravelDuration';

export const AddressIcon = styled(PlaceCircle)`
  ${({ theme }) => css`
    align-self: baseline;
    margin-right: ${theme.spacing / 2}px;
  `}
`;

export const BenefitItem = styled(View)`
  ${({ theme }) => css`
    align-items: center;
    flex: 1 1 auto;
    flex-direction: ${theme.windowDimensions.width < theme.breakpoints.medium
      ? 'column'
      : 'row'};
  `}
`;

export const BenefitRow = styled(View)`
  ${({ theme }) => css`
    align-items: flex-start;
    flex: 1 0 auto;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: ${theme.spacing}px;
  `}
`;

export const BenefitText = styled(TextRaw)<{ isDisabled?: boolean }>`
  ${({ isDisabled, theme }) => {
    const isSmallScreen =
      theme.windowDimensions.width < theme.breakpoints.medium;
    return css`
      color: ${theme.colors[isDisabled ? 'default' : 'bodyText']}
      flex: 1 0 auto;
      flex-wrap: wrap;
      font-size: ${theme.fontSizes.body3}px;
      margin-left: ${isSmallScreen ? 0 : theme.spacing / 2}px;
      max-width: 115px;
      text-align: ${isSmallScreen ? 'center' : 'left'};
    `;
  }}
`;

export const Logo = styled(Image)`
  ${({ theme }) => css`
    flex-grow: 0;
    height: ${theme.spacing * 5}px;
    resize-mode: contain;
  `}
`;

export const MainImage = styled(Image).attrs({
  containerStyle: { flex: 1 },
  resizeMode: 'cover',
})`
  ${({ theme }) => css`
    align-self: stretch;
    flex: 1 0 auto;
    height: 300px;
    margin: ${theme.windowDimensions.width < theme.breakpoints.medium
      ? 0
      : theme.spacing}px;
    width: 100%;
  `}
`;

export const NameHeader = styled(TextRaw)`
  ${({ theme }) => css`
    font-family: ${theme.fontFamilyBold};
    font-size: ${theme.fontSizes.h1}px;
    margin-bottom: ${theme.spacing}px;
    margin-top: ${theme.spacing}px;
  `}
`;

export const Row = styled(View)`
  ${({ theme }) => css`
    align-items: center;
    flex-direction: row;
    margin-bottom: ${theme.spacing}px;
  `}
`;

export const Text = styled(TextRaw)``;

export const TopImageSection = styled(View)`
  ${({ theme }) => css`
    justify-content: center;
    min-height: ${theme.spacing * 6}px;
  `}
`;
