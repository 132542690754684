import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { debounce } from 'lodash';
import theme from 'app/util/theme';

import { Icon } from 'react-native-elements';
import { Picker } from '@react-native-picker/picker';
import { Platform, StyleSheet, View } from 'react-native';
import FormError from 'app/components/Common/FormError';
import FloatingLabel from 'app/components/Common/FloatingLabel';

const asString = (value) => (value ? JSON.stringify(value) : '');
const asValue = (value) => (value ? JSON.parse(value) : '');

/**
 * A select component wrapper for interacting
 * with redux-form on Android devices.
 *
 * @example
 * import { Field } from 'redux-form';
 * import Select from 'app/components/Common/Select';
 *
 * const MyForm = () => (
 *   <View>
 *    <Field
 *      name="mySelectField"
 *      label="My Select"
 *      options={[{ label: 'Test 1', value: 1 }, { label: 'Test 2', value: 2 }]}
 *      component={Select}
 *    />
 *   </View>
 * );
 */
export default class Select extends Component {
  /**
   * @property  {object}    input             object with props passed down from redux-form (required)
   * @property  {function}  input.onChange    function to call when the value changes (required)
   * @property  {function}  input.onBlur      function to call after the changes
   * @property  {*}         input.value       currently selected value
   * @property  {array}     options           array of options to render in the select (required)
   * @property  {boolean}   disabled          whether the input is disabled
   * @property  {string}    error             error message to display above the select
   * @property  {string}    label             label to display above the select
   * @property  {object}    containerStyle    optional style to pass to the container
   * @property  {boolean}   unSelectedOption  whether to display a default prompt when nothing is selected
   */
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func,
      value: PropTypes.any,
    }).isRequired,
    options: PropTypes.array.isRequired,

    disabled: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.string,
    containerStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    unSelectedOption: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    label: '',
    containerStyle: {},
    unSelectedOption: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      selected: props.input.value || '',
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.input &&
      asString(this.props.input) !== asString(prevProps.input)
    ) {
      this.setState({ selected: this.props.input.value });
    }
  }

  focus = () => {
    if (Platform.OS === 'web') {
      this.select?.querySelector?.('select')?.focus?.();
    } else {
      this.picker?.focus?.();
    }
  };

  onChange = (selected) => {
    this.props.input.onChange(selected);

    this.setState({ selected }, this.onSelect);
  };

  /**
   * Call input life-cycle events to match ReactNative <Input />.
   * Note: Uses debounce to avoid race conditions in redux-form.
   */
  onSelect = debounce(async () => {
    await this.props.input.onBlur?.();
    this.props.onSubmitEditing?.();
  });

  render() {
    const { name } = this.props.input;

    const options = [].concat(this.props.options);

    if (this.props.unSelectedOption) {
      options.unshift({ label: 'Select an option', value: '' });
    }

    return (
      <View
        style={[
          theme.Input.containerStyle,
          this.props.containerStyle,
          { paddingHorizontal: theme.spacing },
        ]}
        testID={name}
        ref={(select) => (this.select = select)}
      >
        <FloatingLabel>{this.props.label}</FloatingLabel>

        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Picker
            enabled={!this.props.disabled}
            itemStyle={theme.Input.inputStyle}
            onValueChange={(value) => this.onChange(asValue(value))}
            style={StyleSheet.flatten([
              theme.Input.inputStyle,
              styles.picker,
              { ...this.props.pickerStyle },
              { appearance: 'none' },
              this.props.disabled ? { opacity: 0.3 } : null, // TODO: make this a re-usable import
            ])}
            selectedValue={asString(this.state.selected)}
            testID={`${name}--native-component`}
          >
            {options.map(({ label, value }) => (
              <Picker.Item label={label} value={asString(value)} key={value} />
            ))}
          </Picker>

          {Platform.OS === 'web' && (
            <Icon
              containerStyle={{
                marginLeft: -16,
                overflow: 'visible',
                pointerEvents: 'none',
              }}
              color={theme.colors.mediumGrey}
              name="expand-more"
            />
          )}
        </View>

        {this.props.error && (
          <FormError textStyle={styles.error}>{this.props.error}</FormError>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  picker: {
    height: 50,
    /**
     * Offset the natural <select /> padding so that the options text is
     * horizontally aligned with the label text.
     */
    marginLeft: Platform.select({
      android: theme.spacing / -2,
      web: theme.spacing / -4,
    }),

    ...Platform.select({
      android: {
        width: '100%',
      },
      web: {
        backgroundColor: 'transparent',
        borderWidth: 0,
        width: 'calc(100% - 10px)',
      },
    }),
  },

  error: {
    marginLeft: 0,
  },

  item: {
    marginLeft: 0,
  },
});
