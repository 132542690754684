import { ProcedureDescriptionStyles as Styled } from 'app/components/ProcedureDescription/styles';
import { ProcedureDescriptionBackLinkProps } from 'app/components/ProcedureDescription/types';

const ProcedureDescriptionBackLink = ({
  testID,
}: ProcedureDescriptionBackLinkProps) => {
  return (
    <Styled.BackLink.Wrapper>
      <Styled.BackLink.Anchor testID={testID} />
    </Styled.BackLink.Wrapper>
  );
};

export default ProcedureDescriptionBackLink; // NOTE: potential common component
