import { Image } from 'react-native';
import styled from 'styled-components/native';

import Text from 'app/components/Common/Styled/Text';

export const Container = styled.View`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-color: ${({ theme }) => theme.colors.default};
  border-radius: 2px;
  border-width: 1px;
  flex: 1 0 auto;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing / 2}px;
  margin-bottom: 0px;
  padding: ${({ theme }) => theme.spacing}px;
`;

export const Icon = styled(Image)`
  height: 100px;
  width: 100px;
`;

export const Title = styled(Text).attrs({ h3: true })`
  flex: 1 1 auto;
  margin-vertical: ${({ theme }) => theme.spacing}px;
`;
