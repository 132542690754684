import React from 'react';

import { useOopEstimate } from 'app/hooks';
import { getOopEstimate } from 'app/util/oop';

import Cell from './Cell';
import { cellFlexSizes, RowWrapper } from './styles';
import { GoldButton, SilverButton } from '../CTAs';
import { experienceModalContent } from '../data';
import { formatDetails } from '../helpers';

interface CostRowProps {
  onClose?: () => void;
  onShowSilver?: () => void;
  showButtons?: boolean;
}

const CostRow = ({
  onClose = () => {},
  onShowSilver = () => {},
  showButtons = false,
}: CostRowProps) => {
  const { columns, rowTitles } = experienceModalContent;
  const [_qualityTitle, _careTitle, costTitle] = rowTitles;
  const [
    {
      cost: { header: goldHeader },
    },
    {
      cost: { header: silverHeader },
    },
  ] = columns;
  const [cell1Flex, cell2Flex, cell3Flex] = cellFlexSizes;

  const { data } = useOopEstimate();
  const { carrumEstimate, nonCarrumEstimate } = getOopEstimate(data);

  const formattedCarrumEstimate = formatDetails(carrumEstimate);
  const formattedNonCarrumEstimate = formatDetails(nonCarrumEstimate);

  return (
    <RowWrapper>
      <Cell
        flex={cell1Flex}
        header={costTitle}
        centered
        borderTop
        borderRight
      />
      <Cell
        borderRight
        borderTop
        button={showButtons && <GoldButton onPress={onClose} />}
        centered
        details={formattedCarrumEstimate}
        flex={cell2Flex}
        header={goldHeader}
      />
      <Cell
        borderTop
        button={showButtons && <SilverButton onPress={onShowSilver} />}
        centered
        details={formattedNonCarrumEstimate}
        flex={cell3Flex}
        header={silverHeader}
      />
    </RowWrapper>
  );
};

export default CostRow;
