import React from 'react';
import { Platform } from 'react-native';
import styled from 'styled-components/native';

import { ScrollView, View } from 'app/components/Common/Styled';

/**
 * Renders the given children within a `<ScrollView />` on native and a
 * `<View />` on web to allow targeted scrolling to be implemented without
 * impacting the web experience.
 */
const MobileOnlyScrollView = Platform.select({
  default: View,
  native: ScrollView,
});

export default MobileOnlyScrollView;
