import { Platform } from './constants';
import { requestMagicLink } from 'app/actions/userActions';
import { isWithinDateRangeOf } from 'app/util/methods';
import { SendMagicLinkProps } from './types';
import * as SecureStore from '@cross-platform/expo-secure-store';

export { callCarrum } from 'app/util/call';
export { isGuestAccount } from 'app/util/userUtils';
export { logError } from 'app/util/methods';
export { logEvent } from 'app/util/analytics';
export { loginCoreUser } from 'app/actions/sessionActions';
export { receiveSessionError } from 'app/actions/sessionActions';
export { requestMagicLink };
export { SecureStore };

export const getRememberMeSettings = async () => {
  const result =
    (await SecureStore.getItemAsync('localRememberDeviceSetting')) || '{}';
  const { userEmail, shouldRememberDateSet, shouldRememberDevice } =
    JSON.parse(result);
  const isValid = isWithinDateRangeOf(new Date(shouldRememberDateSet), 30);

  return { isValid, userEmail, shouldRememberDateSet, shouldRememberDevice };
};

/** Return to the email form and reset the password and error state. */
export const goToEmailForm = ({
  defaultFormState,
  dispatch,
  formValues,
  receiveSessionError,
  setFormValues,
  setLoginStep,
}) => {
  setFormValues({ ...formValues, password: defaultFormState.password });
  setLoginStep('email');
  dispatch(receiveSessionError(null));
};

/**
 * Deliver magic link email then navigate to the confirmation screen. Support
 * two types of email: standard (authentication) email and password reset.
 *
 * @param  email            email address for delivery of magic link
 * @param  dispatch         method to dispatch actions for redux store
 * @param  isPasswordReset  whether to send password reset version of email
 * @param  setLoginStep     method to set the login step in state
 */
export const sendMagicLink = async ({
  email,
  dispatch,
  resend = false,
  history = null,
  setLoginStep = null,
  isPasswordReset = false,
}: SendMagicLinkProps) => {
  if (!email) return;

  const nextLoginStep = isPasswordReset
    ? 'magic-password-reset-sent'
    : 'magic-link-sent';

  const success = dispatch(requestMagicLink({ userEmail: email }));

  if (success && setLoginStep) setLoginStep(nextLoginStep);

  if (resend && history && !isPasswordReset)
    history.push(`/login?email=${email}&resent=true`);
};

/**
 * Persist authentication credentials to secure storage for native devices.
 *
 * @param  email     the user's email address
 * @param  password  the user's password
 */
export const storeCredentials = ({ email, password }) => {
  if (Platform.OS === 'web') return;

  const options = {
    keychainAccessible: SecureStore.WHEN_UNLOCKED_THIS_DEVICE_ONLY,
  };

  SecureStore.setItemAsync('email', email, options);
  SecureStore.setItemAsync('password', password, options);
};

/**
 * Parse out the email address and reset password token from the current path.
 *
 * @param   {object}  location  `location` property from react-router
 *
 * @return  {object}            object with `email`, `token`, and `redirectTo`
 *                              properties
 */
export const parseQueryParams = (location) => {
  const email = decodeURIComponent(
    location.search.match(/email=([^&$]+)/)[1]
  ).replace(' ', '+'); // Preserve plus signs in email addresses
  const token = decodeURIComponent(location.search.match(/token=([^&$]+)/)[1]);

  // if `redirect_to` param has value, return it as string; otherwise, null
  let redirectTo = location.search.match(/redirect_to=([^&$]+)/)?.[1];
  if (redirectTo) redirectTo = decodeURIComponent(redirectTo);

  return { email, token, redirectTo };
};

export { StyleSheet } from 'react-native';

export { validateActivation } from 'app/actions/sessionActions';
