export const isPresent =
  (message = 'is required') =>
  (value) =>
    value && value.length ? false : message;

export const isFormat =
  (format, message = 'is not valid') =>
  (value) => {
    return format.test(value || '') ? false : message;
  };

export const isMinLength =
  (length, message = `Please use ${length} or more characters`) =>
  (value) =>
    value && value.length >= length ? false : message;

export const isConfirmed =
  (confirmWith, message = "Passwords don't match") =>
  (value, values) =>
    value === values[confirmWith] ? false : message;

export const isPhoneNumberFormat =
  (message = 'is not valid') =>
  (value) => {
    const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
    return re.test(String(value)) && value.replace(/\D/g, '').length === 10
      ? false
      : message;
  };

export const isSsnFormat =
  (message = 'is not valid') =>
  (value) => {
    const re =
      /^(?!000|666)[0-8][0-9]{2}[-]?(?!00)[0-9]{2}[-]?(?!0000)[0-9]{4}$/;
    return re.test(String(value).toLowerCase()) ? false : message;
  };
