import React from 'react';

import { getOopEstimate } from 'app/util/oop';
import { useOopEstimate } from 'app/hooks';

import {
  CareRow,
  CostRow,
  ExperienceWrapper,
  LeftCell,
  QualityRow,
} from './styles';
import Cell from '../../ExperienceTable/Cell';
import { formatDetails } from '../../helpers';

interface Cell {
  header: string;
  details?: string;
}

interface GridColumn {
  quality: Cell;
  care: Cell;
  cost: Cell;
}

interface ExperienceColumnProps {
  column: GridColumn;
  rowTitles: string[];
}

const ExperienceColumn = ({ column, rowTitles }: ExperienceColumnProps) => {
  const [qualityTitle, careTitle, costTitle] = rowTitles;

  const { data } = useOopEstimate();
  const { carrumEstimate, nonCarrumEstimate } = getOopEstimate(data);

  const isGoldColumn = column.care.header === 'Premium'; // "Premium" is used to describe the Carrum Gold benefit.

  const formattedCarrumEstimate = formatDetails(carrumEstimate);
  const formattedNonCarrumEstimate = formatDetails(nonCarrumEstimate);

  return (
    <ExperienceWrapper>
      <QualityRow>
        <LeftCell>{qualityTitle}</LeftCell>
        <Cell details={column.quality.details} header={column.quality.header} />
      </QualityRow>

      <CareRow>
        <LeftCell>{careTitle}</LeftCell>
        <Cell details={column.care.details} header={column.care.header} />
      </CareRow>
      <CostRow>
        <LeftCell>{costTitle}</LeftCell>
        <Cell
          centered
          details={
            isGoldColumn ? formattedCarrumEstimate : formattedNonCarrumEstimate
          }
          header={column.cost.header}
        />
      </CostRow>
    </ExperienceWrapper>
  );
};

export default ExperienceColumn;
