import { Platform } from 'react-native';
import { Button, ButtonProps } from 'react-native-elements';
import styled, { css } from 'styled-components/native';

import {
  ScrollView as ScrollViewRaw,
  Text,
  View,
} from 'app/components/Common/Styled';
import theme from 'app/util/theme';

export { TopBackLink } from 'app/components/Common/TopBackLink';
export const ActionRow = styled(View)<{ smallScreen: boolean }>`
  background-color: ${theme.colors.primary};
  bottom: 0;
  flex-direction: row;
  justify-content: ${({ smallScreen }) =>
    smallScreen ? 'flex-end' : 'center'};
  left: 0;
  padding: ${({ theme }) => theme.spacing / 2}px;
  position: ${Platform.OS === 'web' ? 'fixed' : 'absolute'};
  right: 0;
`;

export const CenteredRow = styled(View)`
  flex-direction: row;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing}px;
`;

export const Column = styled(View)`
  width: 100%;
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    flex: 1 0 auto;
    max-width: ${theme.breakpoints.large}px;
    padding: ${`${theme.spacing * 3}px ${theme.spacing}px ${
      theme.spacing * 5
    }px`};
  `}
`;

export const ContentWidthWrapper = styled(View)`
  max-width: ${({ theme }) => theme.breakpoints.small}px;
`;

export const NameHeader = styled(Text)`
  text-align: center;
  ${({ theme }) => css`
    font-family: ${theme.fontFamilyBold};
    font-size: ${theme.fontSizes.h1}px;
    margin-top: ${theme.spacing / 2}px;
  `}
`;

export const SandPaddingWrapper = styled(View)`
  align-items: center;
  background: ${({ theme }) => theme.colors.sand};
  padding: 0;
  flex: 1 0 auto;
`;

export const ScrollView = styled(ScrollViewRaw)`
  background: ${({ theme }) => theme.colors.white};
  flex: 1 0 auto;
`;

interface SelectButtonProps extends ButtonProps {
  isSmallScreen: boolean;
}

export const SelectButton = styled(Button)<SelectButtonProps>`
  background-color: transparent;
  border: ${({ isSmallScreen }) =>
    isSmallScreen ? 'none' : `2px solid ${theme.colors.white}`};
`;

export const Spacer = styled(View)`
  height: ${({ theme }) => theme.spacing * 3}px;
`;

export const Specialties = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamilyBold};
`;

export const SubSection = styled(View)`
  margin-bottom: ${({ theme }) => theme.spacing * 2}px;
`;

export const SubHeader = styled(Text)`
  ${({ theme }) => css`
    font-family: ${theme.fontFamilyBold};
    font-size: ${theme.fontSizes.body2}px;
    margin-bottom: ${theme.spacing}px;
  `}
`;

export const TitleHeader = styled(Text)`
  ${({ theme }) => css`
    font-family: ${theme.fontFamilyBold};
    font-size: ${theme.fontSizes.body2}px;
    margin-horizontal: ${-theme.spacing}px;
  `}
`;

export const WhitePaddingWrapper = styled(View)`
  ${({ theme }) => css`
    align-items: center;
    background: ${theme.colors.white};
    flex: 1 0 auto;
    max-width: ${theme.breakpoints.medium}px;
    padding: ${1.25 * theme.spacing}px;
    width: 100%;
  `}
`;
