import { TestID, theme } from 'app/components/App/Navigation/constants';

import {
  extractUnreadMessageCount,
  getHolidayMessage,
  receiveSetLiveChatModalVisibility,
} from 'app/components/App/Navigation/helpers';

import {
  useDispatch,
  useMessage,
  useSession,
  useUi,
  useWindowDimensions,
} from 'app/components/App/Navigation/hooks';

import {
  IdleMoon,
  LiveChatIcon,
  LiveChatIconResponsive,
  ChatButtonStyles as Styled,
} from 'app/components/App/Navigation/styles';

import { LiveChatModal } from './subcomponents';

export const ChatButton = () => {
  const dispatch = useDispatch();
  const { isLiveChatModalVisible } = useUi();
  const message = useMessage();
  const { user } = useSession();
  const windowDimensions = useWindowDimensions();

  const conversation = message.conversations[0];

  const unreadMessagesCount = conversation
    ? extractUnreadMessageCount(user.id, conversation.messages)
    : 0;

  const isLargeScreen = windowDimensions.width > theme.breakpoints.small;
  const hasMessages = unreadMessagesCount > 0;
  const buttonText = 'Talk to Us';

  const hourInEasternTime = new Date().getUTCHours() - 5;
  const isWithinBusinessHours =
    hourInEasternTime >= 9 && hourInEasternTime < 20;
  const isWithinHoliday = Boolean(getHolidayMessage()); // true if message returned

  const getButtonSettings = () => {
    const settings = {
      text: 'After hours',
      Icon: <IdleMoon />,
    };

    if (isWithinBusinessHours && !isWithinHoliday) {
      settings.text = 'Care Team is active';
      settings.Icon = <Styled.GreenDot />;
    }

    if (isWithinHoliday) settings.text = 'Holiday hours';

    return settings;
  };

  const button = getButtonSettings();

  return (
    <>
      {isLargeScreen ? (
        <Styled.Button
          onPress={() => dispatch(receiveSetLiveChatModalVisibility(true))}
          icon={<LiveChatIcon />}
          testID={TestID.Navigation.ChatButton}
          type="outline"
          title={
            <>
              <Styled.ButtonTextContainer>
                <Styled.ButtonText>
                  {buttonText}
                  {hasMessages && <Styled.Badge value={unreadMessagesCount} />}
                </Styled.ButtonText>

                <Styled.ButtonSubheaderContainer>
                  {button.Icon}

                  <Styled.ButtonSubheaderText>
                    {button.text}
                  </Styled.ButtonSubheaderText>
                </Styled.ButtonSubheaderContainer>
              </Styled.ButtonTextContainer>
            </>
          }
        />
      ) : (
        <Styled.ResponsiveContainer
          testID={TestID.Navigation.ChatButtonNarrow}
          onPress={() => dispatch(receiveSetLiveChatModalVisibility(true))}
        >
          <LiveChatIconResponsive color="white" height={20} width={20} />

          <Styled.FloatingStatusContainer>
            {button.Icon}
          </Styled.FloatingStatusContainer>
        </Styled.ResponsiveContainer>
      )}

      <LiveChatModal
        onClose={() => dispatch(receiveSetLiveChatModalVisibility(false))}
        isVisible={isLiveChatModalVisible}
      />
    </>
  );
};
