import React from 'react';
import { Pressable, PressableProps, StyleProp, ViewStyle } from 'react-native';
import { StyledView } from './styles';

interface ResponsivePressableProps extends PressableProps {
  children: React.ReactNode;
  defaultOpacity?: Number;
  flexDirection?: 'row' | 'column';
  pressedOpacity?: Number;
  pressableStyle?: StyleProp<ViewStyle>;
}

export const ResponsivePressable = ({
  defaultOpacity = 1,
  pressedOpacity = 0.5,
  children,
  flexDirection,
  ...props
}: ResponsivePressableProps) => {
  return (
    <Pressable {...props}>
      {({ pressed }) => (
        <StyledView
          defaultOpacity={defaultOpacity}
          pressedOpacity={pressedOpacity}
          flexDirection={flexDirection}
          pressed={pressed}
          style={props.pressableStyle}
        >
          {children}
        </StyledView>
      )}
    </Pressable>
  );
};
