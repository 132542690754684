import React, { ElementType } from 'react';
import { Platform } from 'react-native';

import { shadows } from 'app/util/theme';
import {
  Button,
  HelpIcon,
  IconsWrapper,
  NonButton,
  StatusIcon,
  StatusText,
  TextWrapper,
  TitleText,
} from './styles';
import { TaskProps, TaskStatus } from './types';

const taskToTextMap = (status: TaskStatus) => {
  const mapping = {
    [TaskStatus.Complete]: 'COMPLETED',
    [TaskStatus.Current]: 'TO DO',
    [TaskStatus.Upcoming]: 'UPCOMING',
  };

  return mapping[status];
};

const Task = ({
  meta,
  onPress,
  onPressHelp,
  status = TaskStatus.Current,
  title,
}: TaskProps) => {
  const Container = (
    status === 'current' && onPress ? Button : NonButton
  ) as ElementType;

  const shadowStyles =
    status === 'current'
      ? Platform.select({
          ios: shadows.small,
          web: shadows.small,
        })
      : {};

  return (
    <Container onPress={onPress} status={status} style={shadowStyles}>
      <TextWrapper>
        <StatusText status={status}>{taskToTextMap(status)}</StatusText>
        <TitleText>{title}</TitleText>
      </TextWrapper>

      <IconsWrapper>
        {meta?.help && onPressHelp ? <HelpIcon onPress={onPressHelp} /> : null}
        {/* @ts-ignore TODO: Resolve ts-ignore */}
        {status !== TaskStatus.Upcoming && <StatusIcon status={status} />}
      </IconsWrapper>
    </Container>
  );
};

export default Task;
