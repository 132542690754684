import { TestID } from 'app/components/App/Navigation/constants';
import { SidePanelAnimatedOverlayStyles as Styled } from 'app/components/App/Navigation/styles';

/**
 * Render the animated overlay which enables the user to close the side panel.
 */
export const AnimatedOverlay = ({ onClose, style }) => {
  return (
    <Styled.Wrapper testID={TestID.Navigation.AnimatedOverlay} style={style}>
      <Styled.Switch
        testID={TestID.Navigation.OverlayToggleSwitch}
        onPress={onClose}
      />
    </Styled.Wrapper>
  );
};
