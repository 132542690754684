import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Animated, Easing, Platform } from 'react-native';
import { G, Circle, Svg } from 'react-native-svg';

import { useNativeDriver } from 'app/util/constants';

const AnimatedCircle = Animated.createAnimatedComponent(Circle);

/**
 * Renders a dot based indicator for loading messages.
 */
export default class DotIndicator extends Component {
  static propTypes = {
    color: PropTypes.string,
    style: PropTypes.any,
    testID: PropTypes.string,
  };

  static defaultProps = {
    color: '#e6e1e1',
    style: {},
    testID: null,
  };

  state = {
    scale1: new Animated.Value(2),
    scale2: new Animated.Value(4),
    scale3: new Animated.Value(6),
  };

  circles = {
    scale1: null,
    scale2: null,
    scale3: null,
  };

  constructor(props) {
    super(props);

    Object.keys(this.circles).forEach(this.addAnimationListener);
    setTimeout(this.animate, 10);
  }

  componentWillUnmount() {
    Object.keys(this.circles).forEach(this.removeAnimationListener);
  }

  /**
   * Adds a listener for an animated value
   * that will call `onRadiusChange()` with
   * an updated value.
   *
   * @param {string} key The key of the value being tracked in the state.
   */
  addAnimationListener = (key) => {
    this.state[key].addListener((radius) => this.onRadiusChange(key, radius));
  };

  /**
   * Removes the event listener for an
   * animated value.
   *
   * @param {string} key The key of the value being tracked in the state.
   */
  removeAnimationListener = (key) => {
    this.state[key].removeAllListeners();
  };

  /**
   * Uses `setNativeProps()` when an animated
   * value changes to force a re-render.
   *
   * @param {string} key The key of the value being tracked in the state.
   * @param {object} radius The new value for setting a circle's radius.
   */
  onRadiusChange = (key, radius) => {
    if (!this.circles[key]) return;

    if (Platform.OS === 'web') {
      if (this.circles[key].setAttribute)
        this.circles[key].setAttribute('r', radius.value.toString());
    } else {
      if (this.circles[key].setNativeProps)
        this.circles[key].setNativeProps({ r: radius.value.toString() });
    }
  };

  /**
   * Runs a looping animation that modifies the
   * radius of the circles in the dot indicator.
   */
  animate = () => {
    const config = {
      duration: 160,
      easing: Easing.inOut(Easing.ease),
      useNativeDriver,
    };

    Animated.parallel([
      Animated.sequence([
        Animated.timing(this.state.scale3, { ...config, toValue: 6 }),
        Animated.timing(this.state.scale3, { ...config, toValue: 4 }),
        Animated.timing(this.state.scale3, { ...config, toValue: 2 }),
        Animated.timing(this.state.scale3, { ...config, toValue: 4 }),
      ]),
      Animated.sequence([
        Animated.timing(this.state.scale2, { ...config, toValue: 4 }),
        Animated.timing(this.state.scale2, { ...config, toValue: 2 }),
        Animated.timing(this.state.scale2, { ...config, toValue: 4 }),
        Animated.timing(this.state.scale2, { ...config, toValue: 6 }),
      ]),
      Animated.sequence([
        Animated.timing(this.state.scale1, { ...config, toValue: 2 }),
        Animated.timing(this.state.scale1, { ...config, toValue: 4 }),
        Animated.timing(this.state.scale1, { ...config, toValue: 6 }),
        Animated.timing(this.state.scale1, { ...config, toValue: 4 }),
      ]),
    ]).start(this.animate);
  };

  render() {
    return (
      <Svg
        viewBox="0 0 48 32"
        width="48"
        height="28"
        style={this.props.style}
        testID={this.props.testID}
      >
        {Object.keys(this.state).map((key, index) => (
          <G key={key} transform={`translate(${index * 16 + 8} 14)`}>
            <AnimatedCircle
              cx="0"
              cy="0"
              r="2"
              fill={this.props.color}
              ref={(ref) => (this.circles[key] = ref)}
            />
          </G>
        ))}
      </Svg>
    );
  }
}
