import { EmptyStateStyles as Styled } from 'app/components/Messages/styles';

/**
 * Render the default content when there are no messages.
 *
 * @param  isLoading  whether conversation is loading
 */
export const EmptyState = ({ isLoading = true }: { isLoading?: boolean }) => {
  if (isLoading) {
    return (
      <Styled.Wrapper>
        <Styled.Text>Loading messages...</Styled.Text>
        <Styled.ProgressIndicator />
      </Styled.Wrapper>
    );
  }

  return <Styled.Text>You have no messages</Styled.Text>;
};
