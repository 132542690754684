import {
  nativePlatforms,
  nonProdEnvironments,
  TestID,
} from 'app/components/App/Navigation/constants';

import { showDevTools } from 'app/components/App/Navigation/helpers';

import { DevToolsLinkStyles as Styled } from 'app/components/App/Navigation/styles';

import { EnvironmentSwitch } from './subcomponents';

/**
 * Only render a "Developer Tools" link on native devices in non-prod
 * environments.
 */
export const DevToolsLink = () => {
  return (
    <EnvironmentSwitch
      environments={nonProdEnvironments}
      platforms={nativePlatforms}
    >
      <Styled.Pressable
        testID={TestID.Navigation.DevToolsLink}
        onPress={showDevTools}
      >
        <Styled.Text>Developer Tools</Styled.Text>
      </Styled.Pressable>
    </EnvironmentSwitch>
  );
};
