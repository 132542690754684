import styled, { css } from 'styled-components/native';

import { Fade as FadeRaw } from 'app/components/Common/Animated';
import { Text, View } from 'app/components/Common/Styled';
import TrackableButton from 'app/components/Common/TrackableButton';
import { WelcomeLogo as WelcomeLogoRaw } from 'app/components/Common/svg-components';

const logoSize = 220;

export const Container = styled(View)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  justify-content: center;
  flex: 1 0 auto;
  min-height: 280px;
`;

export const CreateAccountButton = styled(TrackableButton).attrs(
  ({ theme }) => ({
    buttonStyle: { backgroundColor: theme.colors.white },
    type: 'outline',
  })
)``;

export const CreateAccountButtonContainer = styled(View)`
  ${({ theme }) => css`
    margin-top: ${theme.windowDimensions.height * 0.07}px;
  `}
`;

export const Fade = styled(FadeRaw)``;

export const FaqLink = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    margin-bottom: ${theme.spacing}px;
    margin-top: ${theme.windowDimensions.height * 0.1}px;
    text-align: center;
  `}
`;

export const LoginButton = styled(TrackableButton).attrs(({ theme }) => ({
  buttonStyle: {
    borderColor: theme.colors.white,
    borderWidth: 2,
  },
}))``;

export const LoginButtonContainer = styled(View)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing}px;
  `}
`;

export const LogoContainer = styled(View)`
  align-items: center;
`;

export const WelcomeLogo = styled(WelcomeLogoRaw).attrs({
  height: logoSize,
  width: logoSize,
})``;
