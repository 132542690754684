// The number of milliseconds to wait before fading out the loading animation.
export { EVENTS } from 'app/util/analytics';

export const loadingAnimationTimeout = 2500;

export { Routes } from 'app/util/routes';

export { TestID } from 'app/util/test-id';

export { default as theme } from 'app/util/theme';

export const WelcomePages = [
  {
    title: 'Quality Advantages',
    clientTypes: ['employer', 'partner'],
    image: { key: 'quality', height: 455, width: 682.5 },
    subtitle:
      'We partner with the top 10% of doctors and facilities across the nation, to enable you to receive the best care and the care that is right for you.',
  },
  {
    title: 'Dedicated Care Specialist',
    clientTypes: ['employer', 'partner'],
    image: { key: 'care', height: 455, width: 455 },
    subtitle:
      'A dedicated care specialist to support you through your journey, keeping you informed and in charge of the entire process.',
  },
  {
    title: 'Lower Out-of-Pocket Cost',
    clientTypes: ['employer'],
    image: { key: 'cost', height: 455, width: 455 },
    subtitle:
      "When you get care through Carrum Health, you'll get access to low negotiated prices and you may never receive a medical bill.",
  },
  {
    title: 'No Surprise Bills',
    clientTypes: ['partner'],
    image: { key: 'cost', height: 455, width: 455 },
    subtitle: "You don't need to worry about any unexpected bills.",
  },
  {
    title: 'Peace of Mind',
    clientTypes: ['employer'],
    image: { key: 'mindForEmployer', height: 455, width: 682.5 },
    subtitle:
      "You can be confident that by choosing Carrum Health, you've made the right decision for you, your loved ones, and your health.",
  },
  {
    title: 'Peace of Mind',
    clientTypes: ['partner'],
    image: { key: 'mindForPartner', height: 455, width: 682.5 },
    subtitle:
      "You can be confident that by choosing Carrum Health, you've made the right decision for you, your loved ones, and your health.",
  },
];
