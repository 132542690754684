import { TestID, theme } from 'app/components/ProcedureSearch/constants';

import {
  fetchPossibleProcedures,
  getSuggestionsFromMapping,
} from 'app/components/ProcedureSearch/helpers';

import {
  useDispatch,
  useEffect,
  useState,
  useWindowDimensions,
} from 'app/components/ProcedureSearch/hooks';

import {
  ProcedureSearchPageFooter,
  ProcedureSearchPageLetUsKnow,
  ProcedureSearchPageLoadingScreen,
  ProcedureSearchPageSearchInterface,
  ProcedureSearchPageStyles as Styled,
  ProcedureSearchPageOnboardingOverlay,
} from 'app/components/ProcedureSearch/styles';

const ProcedureSearchPage = () => {
  const [isLetUsKnowVisible, setIsLetUsKnowVisible] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [mapping, setMapping] = useState({});
  const [search, setSearch] = useState('');

  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const isMediumOrLargerDevice = width > theme.breakpoints.xxsmall; // base responsive design on size
  const query = search.toLowerCase().trim(); // case insensitive w/ no whitespace
  const searchSuggestions = getSuggestionsFromMapping(mapping, query);

  const queryHasNoResults =
    Boolean(query) && !isSearching && searchSuggestions.length === 0;

  /** When the component mounts, fetch all possible procedures. */
  useEffect(() => {
    dispatch(fetchPossibleProcedures('', true));
  }, [dispatch]);

  return (
    <ProcedureSearchPage.Wrapper testID={TestID.ProcedureSearch.Page}>
      {/**
       * Present input for text-based searching of procedures and a collection
       * of icons to click for direct access to various procedure categories.
       */}
      <ProcedureSearchPage.SearchInterface
        dispatch={dispatch}
        isMediumOrLargerDevice={isMediumOrLargerDevice}
        isSearching={isSearching}
        queryHasNoResults={queryHasNoResults}
        search={search}
        searchSuggestions={searchSuggestions}
        setIsSearching={setIsSearching}
        setMapping={setMapping}
        setSearch={setSearch}
        query={query}
      />

      {/** Present icons to enable user interactions beyond search. */}
      <ProcedureSearchPage.Footer
        queryHasNoResults={queryHasNoResults}
        setIsLetUsKnowVisible={setIsLetUsKnowVisible}
      />

      {/*
       * Present overlay to welcome new registrants from the standard
       * registration workflow, but not from single-click.
       */}
      <ProcedureSearchPage.OnboardingOverlay />

      {/** Upon request, present a modal to request a new procedure. */}
      <ProcedureSearchPage.LetUsKnow
        isVisible={isLetUsKnowVisible}
        setIsVisible={setIsLetUsKnowVisible}
      />

      {/** Present animated illustration as overlay when loading. */}
      <ProcedureSearchPage.LoadingScreen />
    </ProcedureSearchPage.Wrapper>
  );
};

ProcedureSearchPage.Footer = ProcedureSearchPageFooter;
ProcedureSearchPage.LetUsKnow = ProcedureSearchPageLetUsKnow;
ProcedureSearchPage.LoadingScreen = ProcedureSearchPageLoadingScreen;
ProcedureSearchPage.OnboardingOverlay = ProcedureSearchPageOnboardingOverlay;
ProcedureSearchPage.SearchInterface = ProcedureSearchPageSearchInterface;
ProcedureSearchPage.Wrapper = Styled.Wrapper;

export default ProcedureSearchPage;
