import {
  ProgressLinkObject,
  ProgressLinkStatus,
} from 'app/components/Common/ProgressNav/types';

import { cloneDeep } from 'lodash';
import { parameterize } from 'app/util/methods';

const getStatus = (isComplete: boolean): ProgressLinkStatus =>
  isComplete ? ProgressLinkStatus.complete : ProgressLinkStatus.incomplete;

export { receiveSetCallUsModalVisibility } from 'app/actions/uiActions';

export const addCurrentStepStyle = (
  links: ProgressLinkObject[]
): ProgressLinkObject[] => {
  const updatedLinks = cloneDeep(links);

  const currentStep = updatedLinks?.find(
    (link) => link.status === ProgressLinkStatus.incomplete
  );

  if (currentStep) {
    const indexOfCurrentStep = updatedLinks?.indexOf(currentStep);
    const updatedCurrentStep = {
      ...currentStep,
      status: ProgressLinkStatus.current,
    };

    updatedLinks.splice(indexOfCurrentStep, 1, updatedCurrentStep);
  }

  return updatedLinks;
};

export const getStepsAreCompleted = (steps) => {
  return steps.every(({ complete }) => complete);
};

export const getProgressLinksWithoutCurrent = ({
  allStepsAreCompleted,
  journeyPhase,
  journeyPhases,
}): ProgressLinkObject[] => {
  return journeyPhases?.map((phase) => ({
    route: parameterize(phase.code),
    title: phase.name,
    status: getStatus(
      phase?.ordinal < journeyPhase?.ordinal || allStepsAreCompleted
    ),
  }));
};
