import { TestID } from 'app/components/ProcedureDescription/constants';

import {
  getProcedureColloquialName,
  getProcedureName,
} from 'app/components/ProcedureDescription/helpers';

import { ProcedureDescriptionContentStyles as Styled } from 'app/components/ProcedureDescription/styles';

import { Procedure } from 'app/components/ProcedureDescription/types';

const ProcedureDescriptionContent = ({ procedure }: Procedure) => {
  const htmlContent = procedure?.content?.details ?? '';

  if (!htmlContent) return;

  const procedureName = getProcedureName(procedure);
  const procedureColloquialName = getProcedureColloquialName(procedure);

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.ProcedureLabel
          testID={TestID.ProcedureDescription.DescriptionHeaderText}
        >
          You selected:
        </Styled.ProcedureLabel>

        <Styled.ProcedureName>{procedureName}</Styled.ProcedureName>

        <Styled.ColloquialProcedureName>
          {procedureColloquialName}
        </Styled.ColloquialProcedureName>
      </Styled.Header>

      <Styled.HtmlContent html={htmlContent} />
    </Styled.Wrapper>
  );
};

export default ProcedureDescriptionContent; // NOTE: distinct sub-component
