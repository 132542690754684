import FeatureSwitch from 'app/components/Common/FeatureSwitch';
import HtmlView from 'app/components/Common/HtmlView';
import IconScreen from 'app/components/Common/Layout/IconScreen';
import React from 'react';
import Disclaimer from './Disclaimer';
import { HtmlContentContainer } from './styles';
import TravelStipend from './TravelStipend';
import { ITravel } from './types';

interface TravelProps {
  travel: ITravel;
}

/**
 * Renders the HTML travel itinerary for the current episode in a container.
 */
const Travel = ({ travel }: TravelProps) => {
  return (
    <IconScreen icon="event-note" title="My Travel Itinerary">
      <HtmlContentContainer>
        <HtmlView html={travel?.itinerary} />
      </HtmlContentContainer>
      <FeatureSwitch name="episode-travel-stipend" state="on">
        <TravelStipend travel={travel} />
      </FeatureSwitch>
      <Disclaimer />
    </IconScreen>
  );
};

export default Travel;
