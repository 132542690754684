import { useCallback, useEffect, useState } from 'react';
import { useHistory } from '@cross-platform/react-router-native';

import useEpisode from 'app/hooks/useEpisode';
import { Routes } from 'app/util/routes';
import { Episode } from 'types/episode';

interface UseNextTask {
  goToNextTask: () => void;
  nextTask: Episode.Task;
  presentationMode: Episode.Step['presentationMode'];
  stepProgress: number;
}

/**
 * A hook that helps finding and navigating to the next available
 * task for a patient during their journey.
 *
 * @example
 *
 * const { goToNextTask, nextTask, presentationMode } = useNextTask();
 *
 * return (
 *   <>
 *     <Text>
 *       Dashboard vs Workflow: ${presentationMode}
 *     </Text>
 *     <Text>
 *       Next task: ${nextTask?.title}
 *     </Text>
 *     <Button
 *       title="Go to your next task"
 *       onPress={goToNextTask}
 *     />
 *   </>
 * )
 */
export const useNextTask = (): UseNextTask => {
  const { episode, steps } = useEpisode();
  const currentStepId = episode?.currentStep?.id?.toString();
  const history = useHistory();

  /**
   * Finds a step in the redux store with an ID that matches the
   * episode's current step ID.
   */
  const currentStep = steps.find(({ id }) => id?.toString() === currentStepId);

  /**
   * Finds the next incomplete task that a patient must complete.
   */
  const nextTask = currentStep?.tasks?.find(
    ({ complete, keyType }) => !complete && keyType === 'patient'
  );

  /**
   * Calculate % of tasks completed in current step
   */

  const allPatientTasks = currentStep?.tasks.filter(
    ({ keyType }) => keyType === 'patient'
  );

  const completedPatientTasks = allPatientTasks?.filter(
    ({ complete }) => complete
  );
  const stepProgress = completedPatientTasks?.length / allPatientTasks?.length;

  /**
   * Navigate to the next incomplete task or back to the home page (if there are none).
   */
  const goToNextTask = useCallback(() => {
    if (!nextTask?.id) return history.push('/');

    history.push(`/${Routes.Tasks}/${nextTask?.id}`);
  }, [history.push, nextTask?.id]);

  /**
   * Sets up a `isGoingToNextTask` variable to handle fetching and
   * updating the `nextTask` variable in a way that will prevent
   * consuming React components from having a "stale" reference
   * to this object when the Redux store is updated.
   */
  const [isGoingToNextTask, setIsIsGoingToNextTask] = useState(false);

  const goToNextTaskOnNextRender = () => setIsIsGoingToNextTask(true);

  useEffect(() => {
    if (!isGoingToNextTask) return;

    goToNextTask();

    setIsIsGoingToNextTask(false);
  }, [isGoingToNextTask]);

  return {
    goToNextTask: goToNextTaskOnNextRender,
    nextTask,
    presentationMode: currentStep?.presentationMode,
    stepProgress,
  };
};

export default useNextTask;
