import React from 'react';

import PropTypes from 'prop-types';

import AbsoluteFixedContainer from 'app/components/Common/Styled/AbsoluteFixedContainer';

/**
 * Implemented as the `ModalComponent` prop for the `<Tooltip />` component
 * rendered through `react-native-elements`.
 *
 * See `app/util/theme` for more details.
 */
const WebTooltipWrapper = ({ children, visible }) =>
  visible ? <AbsoluteFixedContainer>{children}</AbsoluteFixedContainer> : null;

WebTooltipWrapper.propTypes = {
  children: PropTypes.node,
  visible: PropTypes.bool,
};

WebTooltipWrapper.defaultProps = {
  children: null,
  visible: false,
};

export default WebTooltipWrapper;
