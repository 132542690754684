import React from 'react';

import badgeIcon from 'app/assets/images/badge-icon.png';
import nurseIcon from 'app/assets/images/nurse-icon.png';
import savingsIcon from 'app/assets/images/savings-icon.png';

import { Container, Disclaimer, Paragraph } from './styles';
import WhyCarrumSection from './WhyCarrumSection';

const WhyCarrum = () => (
  <Container>
    <WhyCarrumSection
      title="PERSONALIZED support throughout your journey"
      icon={nurseIcon}
    >
      <Paragraph>
        Planning for surgery and navigating the healthcare system can be a
        daunting task. Figuring out who to visit, how to prepare and what to
        expect when going from one care provider to the next is not as straight
        forward as it should be.
      </Paragraph>

      <Paragraph>
        That’s why Carrum Health lends a helping hand. We assign a personal Care
        Concierge to guide you through the entire episode of care. From
        selecting the right hospital and doctor, to gathering medical records,
        to assisting with travel (if needed) for you and a companion – your Care
        Concierge will be there to help every step of the way.
      </Paragraph>
    </WhyCarrumSection>

    <WhyCarrumSection
      title="TOP QUALITY hospitals and doctors in your region"
      icon={badgeIcon}
    >
      <Paragraph>
        Finding the right hospital and doctor for your individual needs is
        challenging. Not all medical providers deliver the same quality of care.
        In fact, going to a low-quality hospital for surgery – even if it is the
        most convenient location – can be life threatening.
      </Paragraph>

      <Paragraph>
        At Carrum Health, we have identified regional “Centers of Excellence”,
        which are hospitals and doctors that have demonstrated the best results,
        fewest complications and highest level of personalized care. This means
        you can experience a smoother recovery and get back to health sooner.
      </Paragraph>
    </WhyCarrumSection>

    <WhyCarrumSection title="ZERO out-of-pocket costs*" icon={savingsIcon}>
      <Paragraph>
        Medical bills are confusing and can seem never-ending – especially for
        surgery. Determining if they are accurate, when they will stop and how
        much you will end up paying is incredibly frustrating.
      </Paragraph>

      <Paragraph>
        If you choose Carrum Health for your surgery, you will know exactly what
        it will cost beforehand, if anything at all. In most cases*, your health
        insurance plan will cover 100% of all costs, potentially saving you
        thousands of dollars. No medical bills, no confusion and no surprises.
      </Paragraph>

      <Disclaimer>
        *Due to IRS regulations, High Deductible Health Plan (HDHP) enrollees
        are subject to a federal minimum deductible but co-insurance is waived.
      </Disclaimer>
    </WhyCarrumSection>
  </Container>
);

export default WhyCarrum;
