import React from 'react';
import Svg, { Path } from 'react-native-svg';

interface Props {
  color?: string;
  size?: number;
  style?: any;
}

const GradCapCircle = ({ color = '#5F7881', size = 16, style }: Props) => (
  <Svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <Path
      d="M15.5775 5.12874L8.51 2.5923C8.16675 2.46923 7.7925 2.46923 7.44925 2.5923L0.40125 5.12874C0.16095 5.21646 0 5.44704 0 5.70771C0 5.96837 0.16095 6.20021 0.40125 6.28643L1.90175 6.82555C1.6045 7.22155 1.39525 7.68172 1.288 8.17773C1.00125 8.29929 0.8 8.58501 0.8 8.91585C0.8 9.16531 0.92035 9.37928 1.0985 9.5264L0.47775 13.2609C0.437 13.505 0.625 13.7281 0.87225 13.7281H2.32775C2.57493 13.7281 2.763 13.5055 2.72225 13.2611L2.1015 9.52665C2.27975 9.37953 2.4 9.14393 2.4 8.91585C2.4 8.65795 2.27065 8.43889 2.082 8.29202C2.1883 7.84689 2.404 7.44437 2.7055 7.11328L7.4705 8.82337C7.81375 8.94643 8.188 8.94643 8.53125 8.82337L15.5988 6.28693C15.84 6.19895 16 5.96837 16 5.70771C16 5.44704 15.84 5.21646 15.5775 5.12874ZM8.7775 9.57753C8.5425 9.67027 8.2725 9.71789 8 9.71789C7.727 9.71789 7.45775 9.6711 7.2 9.57866L3.57 8.27673L3.2 11.8558C3.2 12.8909 5.3275 13.7281 8 13.7281C10.6275 13.7281 12.8 12.8909 12.8 11.8566L12.4305 8.27497L8.7775 9.57753Z"
      fill={color}
    />
  </Svg>
);

export default GradCapCircle;
