import React, { Component } from 'react';
import { Animated, Easing, StyleSheet, View } from 'react-native';
import { Text } from 'react-native-elements';

import { useNativeDriver } from 'app/util/constants';
import theme, { fontFamily } from 'app/util/theme';

import { Facebook, Twitter, LinkedIn } from 'app/components/Common/SocialLinks';

class CongratsFooter extends Component {
  state = {
    slide: new Animated.Value(200),
  };

  componentDidMount() {
    const config = {
      delay: 800,
      duration: 200,
      easing: Easing.out(Easing.ease),
      useNativeDriver,
    };

    Animated.timing(this.state.slide, { ...config, toValue: 0 }).start(() => {
      this.facebookBtn?.animate(100);
      this.twitterBtn?.animate(500);
      this.linkedinBtn?.animate(900);
    });
  }

  render() {
    return (
      <Animated.View
        style={[
          styles.container,
          { transform: [{ translateY: this.state.slide }] },
        ]}
      >
        <Text style={styles.title}>Follow us on social media</Text>

        <View style={styles.ctaContainer}>
          <Facebook
            ref={(facebookBtn) => (this.facebookBtn = facebookBtn)}
            style={styles.socialButton}
          />
          <Twitter
            ref={(twitterBtn) => (this.twitterBtn = twitterBtn)}
            style={styles.socialButton}
          />
          <LinkedIn
            ref={(linkedinBtn) => (this.linkedinBtn = linkedinBtn)}
            style={styles.socialButton}
          />
        </View>
      </Animated.View>
    );
  }
}

export default CongratsFooter;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: theme.colors.primary,
    flex: 1,
    maxHeight: 200,
    paddingBottom: theme.spacing * 2.5,
    paddingTop: theme.spacing / 2,
  },

  title: {
    color: 'white',
    fontFamily: fontFamily,
    fontStyle: 'italic',
    marginBottom: theme.spacing * 2.5,
    textAlign: 'center',
  },

  ctaContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },

  socialButton: {
    marginHorizontal: theme.spacing / 3,
  },
});
