import { Platform } from 'react-native';
import styled from 'styled-components/native';

import Text from 'app/components/Common/Styled/Text';
import UnorderedListRaw from 'app/components/Common/UnorderedList';

export const Container = styled.View`
  flex: 1 0 auto;
`;

export const Disclaimer = styled(Text).attrs({ size: 'small' })`
  font-style: italic;
  margin-top: ${({ theme }) => theme.spacing / 2}px;
`;

export const Paragraph = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacing / 2}px;
`;

export const UnorderedList = styled(UnorderedListRaw).attrs(({ theme }) => ({
  containerStyle: {
    marginBottom: theme.spacing / 2,
    maxWidth: '100%',
  },
  bulletStyle: {
    [Platform.OS !== 'web' ? 'marginLeft' : undefined]: 0, // only applies a "margin-left" override if on native
  },
}))``;
