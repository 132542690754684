import { TestID } from 'app/components/Forms/constants';

import {
  buildLinkedQuestionsList,
  capitalizeFirstLetters,
  getCurrentQuestionsAnsweredNumber,
  getTotalQuestionCount,
  isFormValid,
  parameterize,
  validateQuestion,
} from 'app/components/Forms/helpers';

import {
  useCallback,
  useEffect,
  useHistory,
  useMemo,
  useNextTask,
  useState,
} from 'app/components/Forms/hooks';

import {
  CenteredScrollView,
  ConstrainedWidthView,
  FormFadeaway,
  FormLayout,
  FormQuestion,
  LeftAlignedView,
  SaveLink,
  StickyNavFooter,
} from 'app/components/Forms/styles';

import {
  FormDetailsLayoutProps,
  NavigationMode,
  Question,
} from 'app/components/Forms/types';

/**
 * Render a form with inputs arranged in a single-page layout so that patients
 * can modify and submit answers that meet validation requirements.
 *
 * TODO: This component is heavily coupled with app/components/Forms/FormDetails and
 * the two should be refactored to remove duplicative code.
 */
const FormDetailsLayout = ({
  initialize,
  iterations = [],
  onSave,
  onSubmit,
  patientName,
  values,
}: FormDetailsLayoutProps) => {
  const [formErrors, setFormErrors] = useState<any>({});
  const [isSaving, setIsSaving] = useState(false);
  const { goToNextTask, presentationMode } = useNextTask();
  const history = useHistory();

  const submissions = [...iterations];
  const earliestIncompleteForm =
    submissions.find(({ completedOn }) => !completedOn) ||
    submissions.reverse()[submissions.length - 1];

  const currentOrder = earliestIncompleteForm.order;
  const submission = iterations[currentOrder];
  const { formLayout } = useMemo(() => submission, [submission]);
  const questionsByLabel = {};

  buildLinkedQuestionsList(submission, values).forEach((question) => {
    return (questionsByLabel[question.label] = question);
  });

  /**
   * When this component mounts, initialize the `redux-form` state with the
   * selected form to populate existing values (if any).
   *
   * TODO: Remove when `redux-form` is removed from the app.
   */
  useEffect(() => {
    initialize(submission);
  }, [submission?.formKey]);

  /**
   * Handle the final navigation event after submitting a completed form based
   * on the presentation mode for the current task.
   *
   * If the presentation mode is 'workflow,' then we'll automatically go to the next task.
   *
   * Otherwise, we'll go back to the previous page that the user navigated from.
   */
  const onComplete = useCallback(() => {
    if (presentationMode === 'workflow') return goToNextTask();
    history.goBack();
  }, [goToNextTask, history.goBack, presentationMode]);

  const handleSaveAndClose = async (event) => {
    event?.preventDefault();

    // Prevent saving completed or invalid forms.
    if (!submission.completedOn) {
      if (!isFormValid(validateAll())) return;

      await onSave(submission.formKey, submission.order);
    }

    if (presentationMode === 'workflow') return history.push('/');

    history.goBack();
  };

  const submitForm = async () => {
    if (isSaving) return;

    setIsSaving(true);

    // Don't attempt to resubmit if the form was already complete.
    if (submission.completedOn) return onComplete();

    const newErrors = validateAll();

    if (!isFormValid(newErrors)) return setIsSaving(false);

    const isSuccess = await onSubmit(submission.formKey, submission.order);

    if (!isSuccess) return setIsSaving(false);

    onComplete();
  };

  const validateAll = () => {
    if (submission.completedOn) return setFormErrors({});

    const newErrors = {};

    const formQuestions = buildLinkedQuestionsList(submission, values);

    formQuestions.forEach((question: Question) => {
      const error = validateQuestion(question, values, patientName);
      const questionKey = parameterize(question.label);
      newErrors[questionKey] = error;
    });

    setFormErrors(newErrors);

    return newErrors;
  };

  const renderColumnQuestions = useCallback(
    (questions) => {
      return (
        <FormLayout.Column key={JSON.stringify(questions)}>
          {questions.map((label, index) => {
            const question = questionsByLabel[label];

            if (question) {
              const questionKey = parameterize(question?.label);
              return (
                <FormLayout.Question key={label}>
                  <FormQuestion
                    content={question.content}
                    disabled={
                      Boolean(submission.completedOn) || question.disabled
                    }
                    error={formErrors[questionKey]}
                    inputType={question.inputType}
                    labelText={question.label}
                    onCancel={history.goBack}
                    options={question.options}
                    name={questionKey}
                    required={question.required}
                    enterKeyHint="next"
                    signatureConfirmationText={capitalizeFirstLetters(
                      patientName
                    )}
                  />
                </FormLayout.Question>
              );
            }
            return null;
          })}
        </FormLayout.Column>
      );
    },
    [
      formErrors,
      history?.goBack,
      patientName,
      questionsByLabel,
      submission?.completedOn,
    ]
  );

  const renderLayoutRow = useCallback(
    (row) => {
      return (
        <FormLayout.Row key={JSON.stringify(row)}>
          {row.columns.map((column) => {
            if (column.rows) {
              return column.rows.map(renderLayoutRow);
            } else if (column.questions) {
              return renderColumnQuestions(column.questions);
            }
          })}
        </FormLayout.Row>
      );
    },
    [renderColumnQuestions]
  );

  /**
   * TODO: https://carrumhealth.atlassian.net/browse/TEC-4479
   *
   * Ideally this form would attempt to save each field individually
   * on change.  However, this form doesn't currently support that
   * type of saving and needs to be refactored to do so properly.
   */
  // const debouncedSave = debounce(async () => {
  //   // TODO: Check form validity before submitting to avoid errors
  //   saveForm();
  // }, 500);
  //
  //  const saveForm = async () => {
  //    const newErrors = validateAll();
  //
  //    if (!isFormValid(newErrors)) return;
  //
  //    const isSuccess = await onSave(submission.formKey, submission.order);
  //  };

  const questionLayout = useMemo(
    () => formLayout?.rows.map(renderLayoutRow),
    [formLayout, renderLayoutRow]
  );

  // wait for `initialize()` callback to populate questions before rendering
  // rendering the inputs.
  if (Object.keys(values).length === 0) return;

  return (
    <FormDetailsLayout.Wrapper
      stickyNavFooter={
        <FormDetailsLayout.StickyNavFooter
          isSaving={isSaving}
          goBack={history.goBack}
          submitForm={submitForm}
        />
      }
    >
      <FormDetailsLayout.Questions>
        <FormDetailsLayout.Header
          handleSaveAndClose={handleSaveAndClose}
          submission={submission}
          values={values}
        />
        {questionLayout}
      </FormDetailsLayout.Questions>
    </FormDetailsLayout.Wrapper>
  );
};

const FormDetailsLayoutHeader = ({
  handleSaveAndClose,
  submission,
  values,
}) => {
  const currentStep = getCurrentQuestionsAnsweredNumber(values);
  const totalSteps = getTotalQuestionCount(submission.formQuestions);
  const { stepProgress, presentationMode } = useNextTask();

  const progressValue =
    presentationMode === 'dashboard' ? currentStep / totalSteps : stepProgress;

  return (
    <>
      <SaveLink.Wrapper>
        <SaveLink.Link
          title={'Save and close'}
          onPress={handleSaveAndClose}
          testID={TestID.FormDetails.SaveLink}
        />
      </SaveLink.Wrapper>
      <SaveLink.ProgressIndicator value={progressValue} />
    </>
  );
};

FormDetailsLayout.Header = FormDetailsLayoutHeader;

FormDetailsLayout.Questions = ConstrainedWidthView;

const FormDetailsLayoutStickyNavFooter = ({ goBack, isSaving, submitForm }) => {
  return (
    <StickyNavFooter
      navigationConfig={[
        {
          onPress: goBack,
          text: 'Previous',
        },
        {
          /**
           * TODO: https://carrumhealth.atlassian.net/browse/TEC-4478
           *
           * Ideally this form would be disabled and validate each question
           * on change.  However, this form doesn't currently support that
           * type of validation and needs to be refactored to do so properly.
           */
          // disabled: !isFormValid(formErrors),
          disabled: isSaving,
          loading: isSaving,
          onPress: submitForm,
          text: 'Next',
        },
      ]}
      navigationMode={NavigationMode.SkipAndNext}
      testID={TestID.FormDetails.NavFooter}
    />
  );
};

FormDetailsLayout.StickyNavFooter = FormDetailsLayoutStickyNavFooter;

const FormDetailsLayoutWrapper = ({ children, stickyNavFooter }) => {
  return (
    <>
      <CenteredScrollView testID={TestID.FormDetails.Component}>
        <LeftAlignedView>{children}</LeftAlignedView>
      </CenteredScrollView>
      <FormFadeaway />

      {stickyNavFooter}
    </>
  );
};

FormDetailsLayout.Wrapper = FormDetailsLayoutWrapper;

export default FormDetailsLayout;
