import { ParticipantStyles as Styled } from 'app/components/Messages/styles';

import { ParticipantProps } from 'app/components/Messages/types';

export const Participant = ({ id, name, status }: ParticipantProps) => {
  return (
    <Styled.Wrapper key={id}>
      <Styled.Status status={status} />

      <Styled.Name>{name}</Styled.Name>
    </Styled.Wrapper>
  );
};
