import React from 'react';

import {
  DetailsRow,
  DetailsText,
  ExperienceCircle,
  iconConfig,
  IconWrapper,
  GradCapCircle,
  LocationWrapper,
  LuggageCircle,
  PlaceCircle,
  TravelDuration,
} from './styles';

interface Address {
  city: string;
  state: string;
}

interface LocationSectionProps {
  address: Address;
  education?: string;
  yearsOfExperience?: string;
  latitude: string;
  longitude: string;
}

const LocationSection = ({
  address: { city, state } = { city: '', state: '' },
  education,
  yearsOfExperience,
  latitude,
  longitude,
}: LocationSectionProps) => {
  return (
    <LocationWrapper>
      {/* Doctors have an education property and should show it. Facilities do not and should show their location here instead. */}
      {education ? (
        <DetailsRow>
          <IconWrapper>
            <GradCapCircle {...iconConfig} />
          </IconWrapper>
          <DetailsText>{education}</DetailsText>
        </DetailsRow>
      ) : (
        <DetailsRow>
          <IconWrapper>
            <PlaceCircle {...iconConfig} />
          </IconWrapper>
          <DetailsText>
            {city}, {state}
          </DetailsText>
        </DetailsRow>
      )}

      {yearsOfExperience && (
        <DetailsRow>
          <IconWrapper>
            <ExperienceCircle {...iconConfig} />
          </IconWrapper>
          <DetailsText>{yearsOfExperience} years of Experience</DetailsText>
        </DetailsRow>
      )}

      <DetailsRow>
        <IconWrapper>
          <LuggageCircle {...iconConfig} />
        </IconWrapper>
        <TravelDuration
          latitude={parseFloat(latitude)}
          longitude={parseFloat(longitude)}
        />
      </DetailsRow>
    </LocationWrapper>
  );
};

export default LocationSection;
