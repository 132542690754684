export type Mode = 'light' | 'dark';

export interface CarrumGoldExperienceBenefitsProps {
  mode?: Mode;
  disabled?: boolean;
}

export enum SelectedModal {
  Cost = 'cost',
  None = 'none',
  Quality = 'quality',
  Care = 'care',
}

export { State } from 'types/state';
