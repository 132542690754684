import React, { Component } from 'react';
import { Platform } from 'react-native';

import {
  ButtonGroup,
  Faq,
  Header,
  HowItWorks,
  MoreInfo,
  ScrollView,
  View,
  WhyCarrum,
} from './styles';

export default class LearnMore extends Component {
  state = {
    selectedIndex: 0,
  };

  updateIndex = (selectedIndex) => {
    this.setState({ selectedIndex });
  };

  render() {
    return (
      <ScrollView testID="LearnMore">
        <View>
          <Header>Learn More About Carrum</Header>

          {Platform.OS === 'web' && (
            <MoreInfo>For more information, call us at 1-888-855-7806</MoreInfo>
          )}

          <ButtonGroup
            buttons={['FAQ', 'WHY CARRUM?', 'HOW IT WORKS']}
            onPress={this.updateIndex}
            selectedIndex={this.state.selectedIndex}
            testID="LearnMore-tabs"
          />

          {this.state.selectedIndex === 0 ? (
            <Faq />
          ) : this.state.selectedIndex === 1 ? (
            <WhyCarrum />
          ) : (
            <HowItWorks />
          )}
        </View>
      </ScrollView>
    );
  }
}
