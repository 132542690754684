import React, { ReactElement } from 'react';
import { StyleSheet, View } from 'react-native';
import { CheckBox as CheckBoxNative } from 'react-native-elements';

import FormError from '../FormError';

interface CheckBoxProps {
  containerStyle?: any;
  disabled?: boolean;
  error?: string;
  label: string | ReactElement;
  input: {
    onBlur?: () => void;
    onChange: (value: boolean) => void;
    value: any;
    name?: string;
    ref?: typeof React.createRef;
  };
}

/**
 * A checkbox component wrapper for interacting
 * with redux-form.
 *
 * @example
 * import { Field } from 'redux-form';
 * import CheckBox from 'app/components/Common/CheckBox';
 *
 * const MyForm = () => (
 *   <View>
 *    <Field
 *      name="myBooleanField"
 *      component={CheckBox}
 *    />
 *   </View>
 * );
 */
const CheckBox = ({
  containerStyle,
  disabled,
  error,
  label,
  input: { name, onBlur, onChange, value },
}: CheckBoxProps) => (
  <View
    style={[
      disabled ? { opacity: 0.3 } : null,
      StyleSheet.flatten(containerStyle),
    ]}
    testID={name}
  >
    <CheckBoxNative
      // React claims that it only supports a string value for `title`,
      // but we pass in a component title sometimes and it works fine.
      title={label}
      checked={Boolean(value)}
      checkedColor={disabled ? '#545454' : '#A53723'}
      containerStyle={{
        borderWidth: 0,
      }}
      onPress={() => {
        if (disabled) return;

        onChange(!value);

        if (onBlur) setTimeout(onBlur);
      }}
      onIconPress={() => {
        if (disabled) return;
        onChange(!value);

        if (onBlur) {
          setTimeout(onBlur);
        }
      }}
    />
    {error ? <FormError>{error}</FormError> : null}
  </View>
);

export default CheckBox;
