import React from 'react';
import PropTypes from 'prop-types';

import { Container, Icon, Title } from './styles';

const propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  title: PropTypes.string.isRequired,
};

const WhyCarrumSection = ({ icon, title, children }) => (
  <Container>
    <Icon source={icon} />
    <Title>{title}</Title>
    {children}
  </Container>
);

WhyCarrumSection.propTypes = propTypes;
export default WhyCarrumSection;
