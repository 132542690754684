import React from 'react';
import { useHistory } from '@cross-platform/react-router-native';

import { Button, CategoryIcon, ButtonContainer, Label } from './styles';

interface CategoryButtonProps {
  category: string;
  icon: string;
}

const CategoryButton = ({ category, icon }: CategoryButtonProps) => {
  const history = useHistory();

  return (
    <ButtonContainer
      onPress={() => {
        history.replace(`/search/${category}`);
      }}
    >
      <Button>
        <CategoryIcon source={{ uri: icon }} />
      </Button>
      <Label>{category}</Label>
    </ButtonContainer>
  );
};

export default CategoryButton;
