import React from 'react';
import { StyleSheet } from 'react-native';
import { ButtonGroup } from 'react-native-elements';

import PropTypes from 'prop-types';

import theme from 'app/util/theme';

/**
 * A tab menu for navigating a <Tabs /> component.
 *
 * @example
 * <TabList
 *   tabs={['Tab 1', 'Tab 2', 'Tab 3']}
 *   onTabPress={(selectedIndex) => console.log('selectedIndex', selectedIndex)}
 *   selectedIndex={0}
 * />
 */
const TabList = ({ containerStyle, onTabPress, selectedIndex, tabs }) => (
  <ButtonGroup
    testID="Profile-TabList"
    onPress={onTabPress}
    selectedIndex={selectedIndex}
    buttons={tabs}
    buttonStyle={{
      borderBottomColor: '#eee',
      borderBottomWidth: 2,
      borderLeftColor: 'transparent',
      borderRightColor: 'transparent',
      flex: 1,
    }}
    containerStyle={StyleSheet.flatten([styles.container, containerStyle])}
    innerBorderStyle={{
      color: 'transparent',
      width: 4,
    }}
    selectedButtonStyle={{
      backgroundColor: 'transparent',
      borderBottomColor: theme.colors.primaryDark,
      borderLeftColor: 'transparent',
      borderRightColor: 'transparent',
    }}
    selectedTextStyle={styles.buttonSelectedText}
    textStyle={styles.buttonText}
  />
);

/**
 * @property {function} onTabPress A function to call when pressing a tab (required).
 * @property {integer|object} containerStyle Optional styles for the container (defaults to {}).
 * @property {integer} selectedIndex The index of the selected tab (defaults to 0).
 * @property {array} tabs A list of tabs to display (defaults to []).
 */
TabList.propTypes = {
  onTabPress: PropTypes.func.isRequired,

  containerStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  selectedIndex: PropTypes.number,
  tabs: PropTypes.array,
};

TabList.defaultProps = {
  containerStyle: {},
  selectedIndex: 0,
  tabs: [],
};

export default TabList;

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    borderRadius: 0,
    borderWidth: 0,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    marginBottom: 0,
    marginHorizontal: 0,
  },

  buttonText: {
    fontSize: theme.fontSizes.body3,
    color: theme.colors.primary,
  },

  buttonSelectedText: {
    color: theme.colors.primaryDark,
  },
});
