import React from 'react';

import CostRow from './CostRow';
import HeaderRow from './HeaderRow';
import CareRow from './CareRow';
import QualityRow from './QualityRow';

import { TableWrapper } from './styles';
import { CTACallbacks } from '../types';

interface ExperienceTableProps extends CTACallbacks {
  showButtons?: boolean;
}

const ExperienceTable = ({
  onClose,
  onShowSilver,
  showButtons,
}: ExperienceTableProps) => {
  return (
    <TableWrapper testID="extended-options--modal--table">
      <HeaderRow />
      <QualityRow />
      <CareRow />
      <CostRow
        onClose={onClose}
        onShowSilver={onShowSilver}
        showButtons={showButtons}
      />
    </TableWrapper>
  );
};

export default ExperienceTable;
