export const deleteItemAsync = (item) => {
  if (isSupported(() => window.localStorage)) {
    try {
      window.localStorage.removeItem(item);

      return true;
    } catch (e) {
      return false;
    }
  } else {
    return false;
  }
};

export const getItemAsync = (item) => {
  if (isSupported(() => window.localStorage)) {
    return window.localStorage.getItem(item);
  } else {
    return false;
  }
};

export const setItemAsync = (item, value) => {
  if (isSupported(() => window.localStorage)) {
    try {
      window.localStorage.setItem(item, value);

      return true;
    } catch (e) {
      return false;
    }
  } else {
    return false;
  }
};

export const WHEN_UNLOCKED_THIS_DEVICE_ONLY = false;

// Private

/**
 * Checks if localStorage or sessionStorage is accessible. This is necessary because checking the truthiness of either can be misleading in some browsers.
 *
 * Source: https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/#solution
 *
 * @param {function} getStorage A callback that returns localStorage or sessionStorage
 * @returns A boolean indicating if localStorage is accessible.
 * @example isSupported(() => localStorage); // => true | false
 */
const isSupported = (getStorage: () => any): boolean => {
  try {
    const key = '__some_random_key_you_are_not_going_to_use__';
    getStorage().setItem(key, key);
    getStorage().removeItem(key);

    return true;
  } catch (e) {
    if (process.env.NODE_ENV === 'development') {
      console.log('isSupported error:', e);
    }

    return false;
  }
};
