import axios from 'axios';

import updateInsurance from 'app/actions/userActions/update-insurance';
import { PRICE_API_URL } from 'app/util/constants';
import { extractFormValues } from 'app/util/methods';

import {
  receiveCostEstimate,
  receiveCostEstimateLoading,
  receiveEstimatesError,
} from './action-types';

/**
 * Fetch the estimated Carrum cost for the active episode.
 *
 * @return  {promise}  promise that resolves with the estimated savings
 */
export default () => {
  return async (dispatch, getState) => {
    try {
      const {
        episode: {
          costEstimateLoading,
          episode: { id: episodeId },
        },
        form,
        session: { coreToken, features, user },
      } = getState();

      // prevent parallel api calls
      if (costEstimateLoading) return;

      // use hardcoded values for demos
      if (features.includes('demo')) {
        return dispatch(
          receiveCostEstimate({
            'carrum-deductible': 0,
            'carrum-coinsurance-amount': 0,
            'carrum-copay': 0,
            'non-carrum-deductible': 1000,
            'non-carrum-coinsurance-amount': 3250,
            'non-carrum-copay': 25,
          })
        );
      }

      dispatch(receiveCostEstimateLoading(true));

      const formValues = extractFormValues(form, 'episode');

      if (
        !user.profile ||
        !user.profile.insurance ||
        (formValues &&
          formValues.memberId &&
          formValues.memberId !== user.profile.insurance.memberId)
      ) {
        await dispatch(updateInsurance({ memberId: formValues.memberId }));
      }

      const response = await axios.get(
        `${PRICE_API_URL}/cost_estimates/${episodeId}`,
        {
          headers: { Authorization: `Bearer ${coreToken}` },
        }
      );

      dispatch(receiveCostEstimate(response.data.data));
    } catch {
      dispatch(
        receiveEstimatesError(
          getState().episode.estimatesError === 'contractedRate'
            ? 'both'
            : 'oop'
        )
      );
      dispatch(receiveCostEstimateLoading(false));
    }
  };
};
