import { Episode } from 'types/episode';
import { Location } from 'types/location';
import { State } from 'types/state';
import createInquiry from './create-inquiry';

/**
 * Creates a new inquiry from the current form and session state.
 *
 * @param {string} [description] Describes what the user is requesting
 * @param {Object} [location] Google Maps location of where the user is located
 * @param {string} [contactMethodPreference] Indicates how the user wishes for a rep to reach them
 *
 * @return {promise} A promise that resolves after creating a new inquiry.
 */
export default (
  description?: string,
  location?: Location,
  contactMethodPreference?: Episode.ContactMethod
) => {
  return (dispatch, getState: () => State.RootState): Promise<boolean> => {
    const { session } = getState();
    const { id, email, referralSource, eligiblePatient, employer } =
      session.user;

    return dispatch(
      createInquiry({
        description,
        location,
        eligiblePatient: { ...eligiblePatient, employer },
        user: { id, email, referralSource },
        procedureName: 'Other',
        initialContactDate: new Date(),
        inquiryMethod: 'online',
        contactMethodPreference,
      })
    );
  };
};
