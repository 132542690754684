import theme from 'app/util/theme';
import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

interface Props {
  height?: number;
  width?: number;
}

const BenefitCircleQuality = ({ height = 47, width = 47 }: Props) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 47 47" fill="none">
      <Circle cx="23.5" cy="23.5" r="23.5" fill={theme.colors.bodyText} />
      <Path
        d="M17.5 41.259C17.5 42.0308 18.3372 42.5116 19.0039 42.1227L22.9961 39.7939C23.3075 39.6123 23.6925 39.6123 24.0039 39.7939L27.9961 42.1227C28.6628 42.5116 29.5 42.0308 29.5 41.259V28.5C29.5 27.6875 28.8125 27 28 27H19C18.1562 27 17.5 27.6875 17.5 28.5V41.259Z"
        fill="white"
      />
      <Path
        d="M38 23.125C38 21.0293 36.6406 19.2168 34.7148 18.4805C35.5645 16.668 35.2246 14.4023 33.752 12.873C32.2227 11.4004 29.957 11.0605 28.1445 11.9102C27.4082 9.98438 25.5957 8.625 23.5 8.625C21.3477 8.625 19.5352 9.98438 18.7988 11.9102C16.9863 11.0605 14.7207 11.4004 13.248 12.873C11.7188 14.4023 11.3789 16.668 12.2285 18.4805C10.3027 19.2168 9 21.0293 9 23.125C9 25.2773 10.3027 27.0898 12.2285 27.8262C11.3789 29.6387 11.7188 31.9043 13.248 33.377C14.7207 34.9062 16.9863 35.2461 18.7988 34.3965C19.5352 36.3223 21.3477 37.625 23.5 37.625C25.5957 37.625 27.4082 36.3223 28.1445 34.3965C30.0137 35.2461 32.2227 34.9062 33.752 33.377C35.2246 31.9043 35.5645 29.6387 34.7148 27.8262C36.6406 27.0898 38 25.2773 38 23.125Z"
        fill={theme.colors.bodyText}
      />
      <Path
        d="M38 20.125C38 18.0293 36.6406 16.2168 34.7148 15.4805C35.5645 13.668 35.2246 11.4023 33.752 9.87305C32.2227 8.40039 29.957 8.06055 28.1445 8.91016C27.4082 6.98438 25.5957 5.625 23.5 5.625C21.3477 5.625 19.5352 6.98438 18.7988 8.91016C16.9863 8.06055 14.7207 8.40039 13.248 9.87305C11.7188 11.4023 11.3789 13.668 12.2285 15.4805C10.3027 16.2168 9 18.0293 9 20.125C9 22.2773 10.3027 24.0898 12.2285 24.8262C11.3789 26.6387 11.7188 28.9043 13.248 30.377C14.7207 31.9062 16.9863 32.2461 18.7988 31.3965C19.5352 33.3223 21.3477 34.625 23.5 34.625C25.5957 34.625 27.4082 33.3223 28.1445 31.3965C30.0137 32.2461 32.2227 31.9062 33.752 30.377C35.2246 28.9043 35.5645 26.6387 34.7148 24.8262C36.6406 24.0898 38 22.2773 38 20.125Z"
        fill="white"
      />
      <Path
        d="M32.7031 20.375C32.7031 15.3281 28.5469 11.1719 23.5 11.1719C18.416 11.1719 14.2969 15.3281 14.2969 20.375C14.2969 25.459 18.416 29.5781 23.5 29.5781C28.5469 29.5781 32.7031 25.459 32.7031 20.375ZM22.4238 25.2734C22.2012 25.4961 21.793 25.4961 21.5703 25.2734L17.7109 21.4141C17.4883 21.1914 17.4883 20.7832 17.7109 20.5605L18.5645 19.7441C18.7871 19.4844 19.1582 19.4844 19.3809 19.7441L22.0156 22.3418L27.582 16.7754C27.8047 16.5156 28.1758 16.5156 28.3984 16.7754L29.252 17.5918C29.4746 17.8145 29.4746 18.2227 29.252 18.4453L22.4238 25.2734Z"
        fill={theme.colors.bodyText}
      />
    </Svg>
  );
};

export default BenefitCircleQuality;
