import { Platform, TestID } from 'app/components/Login/constants';

import { useForm, useState } from 'app/components/Login/hooks';

import {
  Button,
  ButtonContainer,
  CheckBox,
  Controller,
  FormErrorContainer,
  FormView,
  Input,
  Message,
  Text,
} from 'app/components/Login/styles';

import { EmailProps } from 'app/components/Login/types';

const Email = ({
  error = '',
  formValues = {},
  loading = false,
  onSubmit,
  softLogout = false,
}: EmailProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({ defaultValues: formValues });

  // Store the initial soft logout state to persist message while loading.
  const [showSoftLogout] = useState(softLogout);

  return (
    <FormView testID={TestID.Login.EmailForm}>
      {showSoftLogout ? (
        <Message>
          You have been automatically logged out for your protection. Log in
          again to resume your progress.
        </Message>
      ) : null}

      {error ? (
        <FormErrorContainer>
          <Message>{error}</Message>
        </FormErrorContainer>
      ) : softLogout ? (
        <Text></Text>
      ) : null}

      <Controller
        control={control}
        rules={{ minLength: 1, required: true }}
        name="email"
        render={({ field }) => (
          <Input
            autoComplete="username"
            blurOnSubmit={false}
            input={field}
            inputMode="email"
            label="Email"
            onSubmitEditing={handleSubmit(onSubmit)}
            enterKeyHint="next"
          />
        )}
      />

      {Platform.OS == 'web' && (
        <Controller
          control={control}
          name="shouldRememberDevice"
          render={({ field }) => (
            <CheckBox label="Remember me for 30 days" input={field} />
          )}
        />
      )}

      <ButtonContainer>
        <Button
          disabled={!isDirty || errors.email ? true : false}
          loading={loading}
          onPress={handleSubmit(onSubmit)}
          testID={TestID.Login.SubmitEmailButton}
          title="Next"
        />
      </ButtonContainer>
    </FormView>
  );
};

export default Email;
