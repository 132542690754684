import {
  navigationPaths,
  TestID,
} from 'app/components/App/Navigation/constants';

import { getIsLoggedIn } from 'app/components/App/Navigation/helpers';

import { useSelector } from 'app/components/App/Navigation/hooks';

import { State } from 'app/components/App/Navigation/types';

import { GuestLinksStyles as Styled } from 'app/components/App/Navigation/styles';

import { HelpLink, NavigationLink } from './subcomponents';

/**
 * Render navigation links pertaining only to non-authenticated users.
 */
export const GuestLinks = () => {
  const isLoggedIn = useSelector((state: State.RootState) =>
    getIsLoggedIn(state.session)
  );

  if (isLoggedIn) return null;

  return (
    <Styled.Wrapper testID={TestID.Navigation.GuestLinks}>
      <NavigationLink
        key={navigationPaths.register}
        name="Create Account"
        path={navigationPaths.register}
        testID={TestID.Navigation.CreateAccountLink}
      />
      <NavigationLink
        key={navigationPaths.logIn}
        name="Log In"
        path={navigationPaths.logIn}
        testID={TestID.Navigation.LogInLink}
      />
      <HelpLink />
    </Styled.Wrapper>
  );
};
