import React from 'react';

import { useHistory } from '@cross-platform/react-router-native';

import { GetHelpModal } from 'app/components/App/Navigation/GetHelpModal';

const CallUs = () => {
  const history = useHistory();
  const onClose = () => history.push('/');

  return <GetHelpModal visible onClose={onClose} />;
};

export default CallUs;
