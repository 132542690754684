import React, { Component } from 'react';
import { FlatList, StyleSheet, View } from 'react-native';

import PropTypes from 'prop-types';
import { Icon, ListItem, Text } from 'react-native-elements';

import { Link } from '@cross-platform/react-router-native';

import theme from 'app/util/theme';
import { TestID } from 'app/util/test-id';

const iconFor = ({ completedOn, startedOn }) => ({
  color: completedOn ? '#1ac600' : theme.colors.primary,
  name: completedOn ? 'check' : startedOn ? 'restore' : 'new-releases',
});

const getFormName = (item, forms) => {
  if (item.formSchedule?.duration && item.formSchedule.interval) {
    return withCounts(item, forms);
  } else {
    return item.formName;
  }
};

const uniqueForms = (forms) => {
  const results = {};

  forms.forEach(
    (form) => (results[form.formKey] = results[form.formKey] || form)
  );

  return Object.keys(results).map((key) => results[key]);
};

const withCounts = (form, forms) => {
  const complete = forms.filter(
    ({ completedOn, formKey }) => completedOn && formKey === form.formKey
  ).length;

  const { duration, interval } = forms.find(
    ({ formKey }) => formKey === form.formKey
  ).formSchedule;

  const total = duration / interval;

  return `${form.formName} (${complete}/${total})`;
};

/**
 * A list of a patient's forms.
 */
export default class FormList extends Component {
  /**
   * @property {array} forms An array of forms to display.
   * @property {boolean} loading True if the app is loading forms.
   * @property {function} refresh A function to call when loading or refreshing the component.
   */
  static propTypes = {
    forms: PropTypes.array,
    loading: PropTypes.bool,
    refresh: PropTypes.func.isRequired,
  };

  static defaultProps = {
    forms: [],
    loading: false,
  };

  componentDidMount() {
    if (this.props.forms.length === 0) this.props.refresh();
  }

  keyExtractor = ({ formKey }) => formKey;

  render() {
    const incompleteCount = this.props.forms.filter(
      ({ completedOn, startedOn }) => !completedOn && startedOn
    ).length;
    const unstartedCount = this.props.forms.filter(
      ({ startedOn }) => !startedOn
    ).length;
    const allComplete = Boolean(
      this.props.forms.length && !(incompleteCount || unstartedCount)
    );

    return (
      <View style={styles.container} testID={TestID.FormsList.Component}>
        <View style={styles.hero}>
          <Text h1 style={styles.heroTitle}>
            Forms
          </Text>

          {this.props.forms.length === 0 && (
            <Text style={StyleSheet.flatten([styles.heroMessage])}>
              You don&apos;t have any forms.
            </Text>
          )}

          {unstartedCount > 0 && (
            <Text style={StyleSheet.flatten([styles.heroMessage])}>
              {`${unstartedCount} new ${
                unstartedCount === 1 ? 'form' : 'forms'
              }`}
            </Text>
          )}

          {incompleteCount > 0 && (
            <Text style={StyleSheet.flatten([styles.heroMessage])}>
              {`${incompleteCount} incomplete ${
                incompleteCount === 1 ? 'form' : 'forms'
              }`}
            </Text>
          )}

          {allComplete && (
            <View style={styles.row}>
              <Icon
                containerStyle={styles.heroIcon}
                name="tag-faces"
                size={32}
              />

              <Text style={StyleSheet.flatten([styles.heroMessage])}>
                You&apos;re all caught up!
              </Text>
            </View>
          )}
        </View>

        <FlatList
          data={uniqueForms(this.props.forms)}
          enableOnAndroid
          keyExtractor={this.keyExtractor}
          onRefresh={this.props.refresh}
          refreshing={this.props.loading}
          renderItem={({ item }) => (
            <Link
              style={{ textDecorationLine: 'none' }}
              to={`forms/${item.formKey}`}
            >
              <ListItem
                bottomDivider
                topDivider
                containerStyle={styles.listItem}
              >
                <Icon {...iconFor(item)} />
                <ListItem.Content>
                  <ListItem.Title>
                    {getFormName(item, this.props.forms)}
                  </ListItem.Title>
                </ListItem.Content>
                <ListItem.Chevron />
              </ListItem>
            </Link>
          )}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  hero: {
    paddingHorizontal: 40,
    marginBottom: 40,
  },
  heroIcon: {
    marginRight: 4,
  },
  heroMessage: {
    textAlign: 'center',
  },
  heroTitle: {
    marginBottom: 4,
  },
  listItem: {
    backgroundColor: '#F8F8F8',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
