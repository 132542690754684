export { disconnectFromWebSocket } from 'app/actions/messageActions';
export { logout } from 'app/actions/sessionActions';

export const getDisplayDate = (queryParam) => {
  const [month, day, year] = queryParam.split('_');

  const getMonthName = (month) => {
    if (month.length === 1) month = '0' + month;

    const months = {
      '01': 'January',
      '02': 'February',
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'June',
      '07': 'July',
      '08': 'August',
      '09': 'September',
      '10': 'October',
      '11': 'November',
      '12': 'December',
    };

    return months[month];
  };

  const getDayWithoutLeadingZero = (day) => {
    if (day[0] === '0') return day[1];

    return day;
  };

  const getYearWithFourDigits = (year) => {
    if (year.length === 2) return '20' + year;

    return year;
  };

  return `${getMonthName(month)} ${getDayWithoutLeadingZero(
    day
  )}, ${getYearWithFourDigits(year)} at midnight`;
};
