import React from 'react';
import { View } from 'react-native';

import Svg, { Path, G } from 'react-native-svg';
import { ScalableNextStepsSvgDimensions as dimensions } from '../constants';

// To support fluid scaling in flexbox scenarios that shrink for React Native,
// the SVG is wrapped in a View having a fixed aspect ratio.
export const StepToCompleteProfile = () => {
  return (
    <View style={{ aspectRatio: dimensions.aspectRatio, width: '100%' }}>
      <Svg
        fill="none"
        height={'100%'}
        viewBox={dimensions.viewBox}
        width={'100%'}
      >
        <Path
          d="M182.916 83.9423L152.029 72.2617C151.521 72.0698 151.057 71.7798 150.661 71.4082C150.265 71.0367 149.947 70.5908 149.724 70.0961C149.501 69.6014 149.377 69.0676 149.36 68.5251C149.343 67.9827 149.433 67.4422 149.625 66.9346L165.795 24.1689C165.987 23.6614 166.277 23.1965 166.648 22.801C167.019 22.4055 167.465 22.087 167.959 21.8638C168.454 21.6406 168.987 21.517 169.529 21.5001C170.072 21.4831 170.612 21.5733 171.119 21.7652L190.703 29.1881L199.973 50.5494L188.253 81.5386C188.061 82.0474 187.771 82.5134 187.398 82.9098C187.026 83.3061 186.579 83.6251 186.083 83.8484C185.587 84.0717 185.052 84.195 184.509 84.2111C183.965 84.2272 183.424 84.1358 182.916 83.9423Z"
          fill="#F5F5F5"
        />
        <Path
          d="M190.703 29.1885L186.16 41.22C185.98 41.6927 185.894 42.1964 185.909 42.7023C185.923 43.2082 186.038 43.7062 186.245 44.1677C186.453 44.6292 186.75 45.0451 187.119 45.3915C187.488 45.7379 187.921 46.008 188.395 46.1862L199.973 50.5498L190.703 29.1885Z"
          fill="#ECECEC"
        />
        <Path
          d="M185.918 42.335C185.918 42.335 184.66 45.1955 187.62 47.4104C187.733 47.4932 187.849 47.5694 187.951 47.6422C189.362 48.4831 198.635 54.0122 198.721 53.7837C198.807 53.5553 199.946 50.5491 199.946 50.5491L188.359 46.1755C188.359 46.1755 185.541 44.9836 185.918 42.335Z"
          fill="#E0E0E0"
        />
        <Path
          d="M183.75 39.7496C183.694 39.9052 180.439 38.8192 176.499 37.326C172.559 35.8329 169.404 34.4986 169.46 34.3463C169.517 34.194 172.771 35.2767 176.711 36.7698C180.651 38.263 183.813 39.594 183.75 39.7496Z"
          fill="#E0E0E0"
        />
        <Path
          d="M190.411 50.0429C190.352 50.1985 185.038 48.3312 178.545 45.8746C172.053 43.418 166.832 41.3024 166.891 41.1468C166.951 40.9911 172.262 42.8584 178.757 45.3151C185.253 47.7717 190.468 49.8873 190.411 50.0429Z"
          fill="#E0E0E0"
        />
        <Path
          d="M190.372 56.5257C190.312 56.678 184.525 54.6352 177.46 51.9568C170.394 49.2783 164.686 46.9906 164.746 46.8283C164.806 46.6661 170.59 48.7188 177.658 51.3973C184.727 54.0757 190.438 56.3701 190.372 56.5257Z"
          fill="#E0E0E0"
        />
        <Path
          d="M188.352 61.8827C188.296 62.0383 182.508 59.9922 175.44 57.3171C168.371 54.642 162.667 52.3509 162.726 52.1887C162.786 52.0264 168.57 54.0791 175.638 56.7576C182.707 59.436 188.412 61.7304 188.352 61.8827Z"
          fill="#E0E0E0"
        />
        <Path
          d="M186.326 67.2421C186.266 67.3977 180.482 65.3516 173.414 62.6765C166.345 60.0014 160.641 57.7103 160.7 57.5481C160.76 57.3858 166.544 59.4385 173.612 62.117C180.681 64.7954 186.399 67.0898 186.326 67.2421Z"
          fill="#E0E0E0"
        />
        <Path
          d="M184.3 72.6024C184.24 72.7581 178.453 70.712 171.387 68.0368C164.322 65.3617 158.618 63.0706 158.674 62.9084C158.73 62.7462 164.521 64.7989 171.586 67.474C178.651 70.1491 184.359 72.4502 184.3 72.6024Z"
          fill="#E0E0E0"
        />
        <Path
          d="M80.4836 39.3576L38.637 54.0121C37.5394 54.3964 36.9612 55.5978 37.3456 56.6953L58.1718 116.166C58.5561 117.263 59.7575 117.841 60.8551 117.457L102.702 102.802C103.799 102.418 104.377 101.217 103.993 100.119L83.1669 40.649C82.7825 39.5514 81.5812 38.9732 80.4836 39.3576Z"
          fill="#ECECEC"
        />
        <Path
          d="M80.4445 43.1851L41.054 56.9795L61.3056 114.809L100.696 101.015L80.4445 43.1851Z"
          fill="#E0E0E0"
        />
        <Path
          d="M79.7397 42.9087L40.3491 56.7031L60.2134 113.427L99.604 99.6323L79.7397 42.9087Z"
          fill="#FAFAFA"
        />
        <Path
          d="M99.6058 99.6354C99.437 99.162 91.4381 76.3902 79.688 42.9312L79.7608 42.9643L40.392 56.7604L40.4284 56.681C47.8545 77.9894 54.7642 97.6655 60.2933 113.408L60.2303 113.379L99.629 99.6321L60.2303 113.478L60.184 113.494L60.1674 113.445L40.3026 56.724L40.2827 56.6677L40.339 56.6479L79.7376 42.8617L79.7906 42.8418L79.8105 42.8948L99.6058 99.6354Z"
          fill="#E0E0E0"
        />
        <Path
          d="M85.6309 104.529C85.6309 104.529 94.4045 97.1291 95.9242 89.4248C95.0435 90.6445 93.9251 91.6733 92.6363 92.4494C91.3476 93.2255 89.9151 93.7328 88.4252 93.9407C88.4252 93.9407 88.2497 102.029 85.6309 104.529Z"
          fill="#E0E0E0"
        />
        <Path
          d="M54.1157 69.2348L51.4378 70.1726C50.8476 70.3793 50.5367 71.0253 50.7434 71.6155L51.6812 74.2934C51.8879 74.8837 52.5339 75.1946 53.1241 74.9879L55.802 74.0501C56.3922 73.8434 56.7031 73.1974 56.4964 72.6072L55.5586 69.9293C55.3519 69.3391 54.7059 69.0282 54.1157 69.2348Z"
          fill="#ECECEC"
        />
        <Path
          d="M53.5624 72.9142C53.5472 72.9207 53.5309 72.924 53.5144 72.924C53.4979 72.924 53.4815 72.9207 53.4664 72.9142L52.5162 72.3613C52.492 72.3441 52.475 72.3184 52.4688 72.2894C52.4625 72.2604 52.4673 72.23 52.4822 72.2043C52.4971 72.1786 52.5211 72.1594 52.5494 72.1505C52.5777 72.1416 52.6084 72.1435 52.6354 72.156L53.473 72.6427L54.4662 70.4973C54.4799 70.4688 54.5041 70.4466 54.5337 70.4355C54.5633 70.4244 54.5961 70.4252 54.6252 70.4377C54.6393 70.4445 54.652 70.4539 54.6624 70.4655C54.6729 70.4772 54.681 70.4908 54.6862 70.5055C54.6914 70.5203 54.6937 70.5359 54.6929 70.5516C54.6921 70.5672 54.6882 70.5825 54.6814 70.5966L53.6352 72.8546C53.6188 72.8825 53.593 72.9037 53.5624 72.9142Z"
          fill="white"
        />
        <Path
          d="M80.764 60.5649C80.764 60.598 75.6587 62.419 69.3384 64.6306C63.0181 66.8422 57.883 68.6036 57.8731 68.5804C57.8631 68.5572 62.975 66.7297 69.2987 64.5147C75.6223 62.2998 80.7541 60.5318 80.764 60.5649Z"
          fill="#E0E0E0"
        />
        <Path
          d="M68.7489 68.7256C68.6294 68.7867 68.5054 68.8387 68.3781 68.8812L67.3517 69.2619C66.4843 69.593 65.2792 70.0069 63.9482 70.4737C62.6173 70.9405 61.4088 71.3544 60.5315 71.649L59.4919 71.9801C59.366 72.0272 59.2364 72.0638 59.1045 72.0894C59.224 72.0283 59.348 71.9763 59.4753 71.9338L60.505 71.553C61.3724 71.2385 62.5742 70.8081 63.9085 70.3413C65.2427 69.8744 66.4479 69.4606 67.3219 69.1659L68.3648 68.8348C68.4898 68.7886 68.6182 68.752 68.7489 68.7256Z"
          fill="#E0E0E0"
        />
        <Path
          d="M81.3101 62.1274C81.3101 62.1572 76.2049 63.9782 69.8845 66.1931C63.5642 68.408 58.4291 70.1661 58.4192 70.1429C58.4093 70.1197 63.5212 68.2888 69.8448 66.0739C76.1685 63.859 81.3002 62.0943 81.3101 62.1274Z"
          fill="#E0E0E0"
        />
        <Path
          d="M57.0776 77.6909L54.3997 78.6287C53.8095 78.8354 53.4986 79.4814 53.7053 80.0716L54.6431 82.7495C54.8498 83.3397 55.4958 83.6506 56.086 83.4439L58.7639 82.5061C59.3541 82.2994 59.665 81.6534 59.4583 81.0632L58.5205 78.3853C58.3138 77.7951 57.6678 77.4842 57.0776 77.6909Z"
          fill="#ECECEC"
        />
        <Path
          d="M56.5221 81.3666C56.5069 81.3731 56.4906 81.3764 56.4741 81.3764C56.4576 81.3764 56.4413 81.3731 56.4261 81.3666L55.4759 80.817C55.4517 80.7998 55.4348 80.7741 55.4285 80.7451C55.4222 80.7161 55.427 80.6857 55.4419 80.66C55.4568 80.6343 55.4808 80.6151 55.5091 80.6062C55.5375 80.5973 55.5681 80.5992 55.5951 80.6117L56.4327 81.0984L57.4259 78.9497C57.4408 78.9223 57.4652 78.9014 57.4946 78.891C57.5239 78.8806 57.5561 78.8815 57.5849 78.8934C57.599 78.9002 57.6117 78.9096 57.6221 78.9212C57.6326 78.9329 57.6407 78.9465 57.6459 78.9612C57.6512 78.976 57.6534 78.9916 57.6526 79.0073C57.6518 79.0229 57.6479 79.0382 57.6412 79.0523L56.585 81.3136C56.5704 81.3377 56.5483 81.3563 56.5221 81.3666Z"
          fill="white"
        />
        <Path
          d="M83.7237 69.0171C83.7237 69.0502 78.6185 70.8711 72.2981 73.0827C65.9778 75.2944 60.8427 77.0557 60.8328 77.0325C60.8228 77.0094 65.9347 75.1818 72.2584 72.9669C78.582 70.7519 83.7105 68.984 83.7237 69.0171Z"
          fill="#E0E0E0"
        />
        <Path
          d="M71.7088 77.1787C71.5893 77.2398 71.4654 77.2918 71.338 77.3343L70.3117 77.7151C69.4442 78.0461 68.2391 78.46 66.9082 78.9235C65.5772 79.387 64.3688 79.8075 63.4914 80.1022L62.4518 80.4332C62.3253 80.4783 62.1959 80.5148 62.0645 80.5425C62.1832 80.4797 62.3073 80.4276 62.4353 80.3869L63.4616 80.0061C64.3324 79.6751 65.5342 79.2612 66.8684 78.7944C68.2027 78.3276 69.4078 77.9137 70.2819 77.619L71.3215 77.288C71.4468 77.2394 71.5765 77.2028 71.7088 77.1787Z"
          fill="#E0E0E0"
        />
        <Path
          d="M84.2701 70.5765C84.2701 70.6097 79.1648 72.4306 72.8445 74.6455C66.5242 76.8605 61.3891 78.6185 61.3792 78.5953C61.3692 78.5721 66.4811 76.7413 72.8048 74.5263C79.1284 72.3114 84.2569 70.5467 84.2701 70.5765Z"
          fill="#E0E0E0"
        />
        <Path
          d="M60.0359 86.1401L57.358 87.0779C56.7678 87.2846 56.4569 87.9306 56.6635 88.5208L57.6013 91.1987C57.808 91.7889 58.454 92.0998 59.0442 91.8931L61.7221 90.9553C62.3124 90.7487 62.6233 90.1026 62.4166 89.5124L61.4788 86.8345C61.2721 86.2443 60.6261 85.9334 60.0359 86.1401Z"
          fill="#ECECEC"
        />
        <Path
          d="M59.482 89.819C59.4668 89.8255 59.4505 89.8288 59.434 89.8288C59.4175 89.8288 59.4012 89.8255 59.386 89.819L58.4358 89.2694C58.4207 89.2624 58.4073 89.2524 58.3964 89.2399C58.3854 89.2275 58.3772 89.2129 58.3722 89.1971C58.3672 89.1813 58.3656 89.1646 58.3674 89.1481C58.3692 89.1316 58.3745 89.1157 58.3828 89.1014C58.3911 89.0871 58.4024 89.0746 58.4158 89.0649C58.4292 89.0551 58.4445 89.0483 58.4607 89.0448C58.4769 89.0412 58.4936 89.0412 58.5099 89.0445C58.5261 89.0478 58.5415 89.0545 58.555 89.0641L59.3926 89.5475L60.3859 87.4021C60.3922 87.3876 60.4015 87.3746 60.4131 87.3639C60.4246 87.3532 60.4383 87.3449 60.4532 87.3397C60.4681 87.3344 60.4839 87.3322 60.4996 87.3333C60.5154 87.3343 60.5307 87.3386 60.5448 87.3458C60.5589 87.3525 60.5716 87.362 60.5821 87.3736C60.5925 87.3853 60.6006 87.3988 60.6058 87.4136C60.6111 87.4284 60.6133 87.444 60.6125 87.4596C60.6117 87.4753 60.6078 87.4906 60.6011 87.5047L59.5416 89.766C59.5289 89.7904 59.5077 89.8092 59.482 89.819Z"
          fill="white"
        />
        <Path
          d="M86.6837 77.4732C86.6837 77.5063 81.5784 79.3239 75.2581 81.5389C68.9377 83.7538 63.8027 85.5118 63.7927 85.4887C63.7828 85.4655 68.8947 83.6346 75.2183 81.423C81.542 79.2114 86.6704 77.4368 86.6837 77.4732Z"
          fill="#E0E0E0"
        />
        <Path
          d="M74.6688 85.6309C74.5495 85.6915 74.4255 85.7424 74.298 85.7832L73.2716 86.1639C72.4042 86.495 71.1991 86.9088 69.8681 87.3757C68.5372 87.8425 67.3287 88.2596 66.4514 88.5543L65.4118 88.8854C65.2856 88.9323 65.1561 88.9699 65.0244 88.9979C65.1431 88.9351 65.2672 88.883 65.3952 88.8423L66.4216 88.4616C67.2923 88.1305 68.4941 87.7167 69.8284 87.2498C71.1627 86.783 72.3678 86.3692 73.2418 86.0712L74.2814 85.7401C74.4068 85.6915 74.5365 85.6549 74.6688 85.6309Z"
          fill="#E0E0E0"
        />
        <Path
          d="M87.2298 79.0288C87.2298 79.0619 82.1246 80.8828 75.8042 83.0945C69.4839 85.3061 64.3488 87.0674 64.3389 87.0443C64.3289 87.0211 69.4408 85.1935 75.7645 82.9786C82.0881 80.7636 87.2166 78.9957 87.2298 79.0288Z"
          fill="#E0E0E0"
        />
        <Path
          d="M62.9978 94.5962L60.3199 95.534C59.7297 95.7407 59.4188 96.3867 59.6255 96.9769L60.5632 99.6548C60.7699 100.245 61.4159 100.556 62.0061 100.349L64.684 99.4114C65.2743 99.2047 65.5852 98.5587 65.3785 97.9685L64.4407 95.2906C64.234 94.7004 63.588 94.3895 62.9978 94.5962Z"
          fill="#ECECEC"
        />
        <Path
          d="M62.4419 98.2718C62.4268 98.2782 62.4104 98.2816 62.3939 98.2816C62.3774 98.2816 62.3611 98.2782 62.3459 98.2718L61.3957 97.7222C61.3681 97.7064 61.3478 97.6802 61.3394 97.6495C61.3311 97.6187 61.3352 97.5859 61.351 97.5583C61.3668 97.5306 61.393 97.5104 61.4237 97.502C61.4544 97.4936 61.4873 97.4978 61.5149 97.5136L62.3526 98.0003L63.3458 95.8549C63.3609 95.8296 63.3848 95.8109 63.4129 95.8022C63.441 95.7935 63.4713 95.7955 63.498 95.8078C63.5247 95.8202 63.5459 95.8419 63.5576 95.869C63.5692 95.896 63.5704 95.9263 63.561 95.9542L62.5015 98.2188C62.4888 98.2431 62.4676 98.262 62.4419 98.2718Z"
          fill="white"
        />
        <Path
          d="M89.6434 85.9223C89.6434 85.9521 84.5381 87.7731 78.2178 89.988C71.8975 92.2029 66.7624 93.961 66.7525 93.9378C66.7425 93.9146 71.8544 92.0838 78.1781 89.8688C84.5017 87.6539 89.6302 85.8892 89.6434 85.9223Z"
          fill="#E0E0E0"
        />
        <Path
          d="M77.6285 94.0801C77.5098 94.1429 77.3857 94.195 77.2577 94.2357L76.2314 94.6164C75.3606 94.9475 74.1588 95.3614 72.8278 95.8282C71.4969 96.295 70.2885 96.7089 69.4111 97.0068L68.3715 97.3379C68.2463 97.387 68.1165 97.4236 67.9841 97.4472C68.1037 97.3861 68.2276 97.3341 68.3549 97.2916L69.3813 96.9141C70.252 96.5831 71.4539 96.1692 72.7881 95.7024C74.1224 95.2355 75.3242 94.8184 76.2016 94.5237L77.2412 94.1926C77.3675 94.1462 77.497 94.1086 77.6285 94.0801Z"
          fill="#E0E0E0"
        />
        <Path
          d="M90.1898 87.4819C90.1898 87.515 85.0845 89.336 78.7642 91.5476C72.4438 93.7592 67.3088 95.5206 67.2988 95.4974C67.2889 95.4742 72.4008 93.6466 78.7244 91.4317C85.0481 89.2168 90.1765 87.4488 90.1898 87.4819Z"
          fill="#E0E0E0"
        />
        <Path
          d="M44.8118 51.1781L45.8514 54.1579C46.1425 54.984 46.7492 55.6611 47.5385 56.0409C48.3278 56.4207 49.2355 56.4721 50.0627 56.1841L71.8512 48.5692C72.6773 48.2781 73.3544 47.6714 73.7342 46.8821C74.1139 46.0928 74.1654 45.1851 73.8774 44.3579L72.8378 41.3848L44.8118 51.1781Z"
          fill="#E0E0E0"
        />
        <Path
          d="M59.1145 47.1527C58.5359 47.3546 57.9105 47.3805 57.3172 47.227C56.724 47.0735 56.1895 46.7476 55.7815 46.2905C55.3734 45.8333 55.11 45.2655 55.0246 44.6587C54.9392 44.0519 55.0357 43.4334 55.3017 42.8814C55.5678 42.3294 55.9915 41.8686 56.5194 41.5574C57.0472 41.2462 57.6555 41.0984 58.2673 41.1328C58.8792 41.1672 59.467 41.3823 59.9567 41.7507C60.4463 42.1192 60.8157 42.6245 61.0182 43.2029C61.1527 43.5872 61.2102 43.9943 61.1873 44.4009C61.1645 44.8074 61.0617 45.2055 60.8849 45.5723C60.7081 45.9392 60.4607 46.2675 60.157 46.5387C59.8532 46.8099 59.4989 47.0185 59.1145 47.1527ZM57.4591 42.4249C57.1027 42.5501 56.7915 42.7781 56.5647 43.0802C56.338 43.3822 56.2058 43.7447 56.185 44.1218C56.1642 44.4989 56.2556 44.8737 56.4477 45.1989C56.6398 45.5241 56.924 45.785 57.2644 45.9487C57.6048 46.1124 57.986 46.1715 58.36 46.1186C58.7339 46.0658 59.0838 45.9032 59.3655 45.6515C59.6471 45.3998 59.8477 45.0703 59.9421 44.7046C60.0365 44.3389 60.0204 43.9534 59.8958 43.5969C59.7277 43.1186 59.3767 42.7266 58.9199 42.5068C58.463 42.2871 57.9376 42.2576 57.4591 42.4249Z"
          fill="#E0E0E0"
        />
        <Path
          d="M76.5293 54.8566C76.5491 54.9129 70.8049 56.9756 63.6999 59.462C56.5949 61.9484 50.8175 63.925 50.7877 63.8687C50.7579 63.8124 56.5121 61.7464 63.6204 59.26C70.7287 56.7736 76.5094 54.8004 76.5293 54.8566Z"
          fill="#E0E0E0"
        />
        <Path
          d="M69.1728 55.9128C69.1926 55.9691 66.6632 56.9061 63.5245 58.0052C60.3859 59.1044 57.8266 59.9487 57.8068 59.8924C57.7869 59.8361 60.313 58.8992 63.455 57.8C66.597 56.7008 69.1529 55.8565 69.1728 55.9128Z"
          fill="#E0E0E0"
        />
        <Path
          d="M182.366 141.977C183.892 141.189 185.677 141.087 187.382 141.368C188.292 141.517 189.239 141.788 189.858 142.474C190.477 143.159 190.597 144.358 189.885 144.947C189.425 145.256 188.873 145.395 188.322 145.341C186.998 145.341 185.524 145.093 184.465 145.89C183.862 146.347 183.472 147.102 182.737 147.327C182.375 147.411 181.997 147.374 181.658 147.223C181.319 147.072 181.039 146.815 180.86 146.49C180.514 145.825 180.437 145.054 180.644 144.334C180.73 143.835 180.931 143.363 181.229 142.954C181.528 142.545 181.917 142.211 182.366 141.977Z"
          fill="#EF7136"
        />
        <Path
          d="M178.873 135.23C179.804 136.555 181.618 136.121 182.459 135.31C183.3 134.499 183.654 133.323 183.979 132.191C184.717 129.625 185.462 126.93 184.912 124.315C184.783 123.524 184.439 122.784 183.919 122.176C183.653 121.876 183.313 121.651 182.933 121.524C182.553 121.397 182.146 121.373 181.754 121.454C180.84 121.696 180.27 122.59 179.86 123.44C178.649 125.955 178.016 128.708 178.006 131.499C178.006 132.797 178.152 134.148 178.887 135.23"
          fill="#EF7136"
        />
        <Path
          d="M176.311 137.14C177.324 135.939 177.516 134.187 177.065 132.681C176.615 131.174 175.595 129.893 174.417 128.847C173.035 127.619 171.428 126.671 169.686 126.056C169.01 125.784 168.284 125.661 167.557 125.695C167.194 125.716 166.842 125.825 166.531 126.013C166.219 126.201 165.959 126.462 165.772 126.774C165.272 127.724 165.749 128.877 166.266 129.817C167.176 131.473 168.216 133.055 169.374 134.548C170.305 135.75 171.361 136.935 172.791 137.478C174.222 138.021 175.685 137.955 176.433 136.972"
          fill="#EF7136"
        />
        <Path
          d="M178.807 155.327C178.858 155.128 178.887 154.925 178.893 154.721C178.936 154.28 178.989 153.727 179.052 153.065C179.135 152.252 179.26 151.443 179.426 150.642C179.643 149.666 179.937 148.71 180.307 147.781C180.668 146.854 181.16 145.984 181.767 145.195C182.259 144.551 182.891 144.026 183.614 143.659C184.103 143.416 184.627 143.253 185.167 143.176C185.371 143.17 185.573 143.14 185.77 143.086C185.566 143.065 185.361 143.065 185.157 143.086C184.595 143.129 184.046 143.273 183.535 143.51C182.775 143.87 182.109 144.4 181.588 145.06C180.955 145.862 180.445 146.755 180.075 147.708C179.699 148.65 179.407 149.623 179.201 150.615C179.041 151.426 178.93 152.245 178.87 153.069C178.817 153.77 178.797 154.34 178.79 154.724C178.775 154.925 178.781 155.127 178.807 155.327Z"
          fill="#3A4750"
        />
        <Path
          d="M178.923 154.056C178.937 153.963 178.943 153.868 178.939 153.774C178.939 153.562 178.939 153.297 178.939 152.97C178.939 152.274 178.939 151.265 178.976 150.02C179.039 147.527 179.251 144.848 179.664 141.081C180.058 137.525 180.585 133.39 181.141 130.698C181.26 130.089 181.379 129.536 181.492 129.042C181.605 128.549 181.694 128.142 181.78 127.804L181.969 127.023C181.994 126.933 182.011 126.841 182.018 126.748C181.973 126.83 181.94 126.918 181.919 127.01C181.859 127.212 181.783 127.467 181.687 127.781C181.591 128.096 181.479 128.529 181.356 129.009C181.234 129.489 181.098 130.039 180.969 130.648C180.281 133.83 179.762 137.046 179.416 140.283C179.002 144.07 178.817 147.52 178.8 150.016C178.8 151.265 178.8 152.274 178.843 152.973C178.863 153.304 178.88 153.566 178.893 153.778C178.891 153.871 178.901 153.965 178.923 154.056Z"
          fill="#3A4750"
        />
        <Path
          d="M178.939 147.404C178.96 147.124 178.96 146.843 178.939 146.563C178.891 145.802 178.805 145.044 178.681 144.291C178.499 143.174 178.253 142.069 177.943 140.981C177.58 139.643 177.115 138.334 176.552 137.067C175.595 134.724 174.219 132.575 172.493 130.724C172.194 130.408 171.874 130.112 171.536 129.836C171.391 129.711 171.262 129.588 171.129 129.489L170.758 129.234C170.534 129.065 170.297 128.914 170.05 128.78C170.01 128.837 171.043 129.442 172.368 130.846C174.039 132.71 175.383 134.844 176.34 137.157C176.888 138.419 177.353 139.717 177.731 141.04C178.049 142.12 178.312 143.215 178.519 144.321C178.691 145.265 178.794 146.036 178.85 146.573C178.859 146.851 178.889 147.129 178.939 147.404Z"
          fill="#3A4750"
        />
        <Path
          d="M172.51 159.138L173.175 168.269H184.909L185.574 159.138H172.51Z"
          fill="#3A4750"
        />
        <Path
          d="M186.971 155.539H171.374V159.138H186.971V155.539Z"
          fill="#3A4750"
        />
        <Path
          d="M171.377 167.921V168.917H172.109H186.716V167.921H171.377Z"
          fill="#5F7881"
        />
        <Path
          d="M172.828 170.673L172.109 168.918H186.157L185.319 170.673H172.828Z"
          fill="#5F7881"
        />
        <Path
          d="M185.356 162.121C185.343 162.152 185.325 162.181 185.303 162.207L185.127 162.439C184.958 162.65 184.73 162.945 184.442 163.313L184.412 163.352L184.382 163.313L182.704 160.995H182.8L181.144 163.336L181.091 163.409L181.035 163.342L179.075 161.025H179.188C179.151 161.065 179.115 161.111 179.078 161.157C178.473 161.922 177.883 162.66 177.327 163.356L177.267 163.428L177.215 163.352L175.559 161.015H175.655L173.619 163.332L173.583 163.372L173.556 163.326L172.92 162.23L172.758 161.945C172.737 161.913 172.721 161.879 172.708 161.843C172.708 161.843 172.735 161.872 172.775 161.932L172.957 162.207L173.619 163.26H173.556L175.543 160.912L175.596 160.853L175.639 160.916C176.148 161.624 176.708 162.409 177.294 163.233H177.181L178.933 161.031L179.039 160.896L179.095 160.826L179.151 160.896C179.84 161.713 180.499 162.505 181.108 163.233H180.999L182.654 160.916L182.704 160.849L182.75 160.916L184.406 163.263H184.346L185.054 162.409C185.134 162.323 185.197 162.25 185.247 162.19C185.28 162.162 185.316 162.139 185.356 162.121Z"
          fill="#E0E0E0"
        />
        <Path
          d="M185.736 159.078C185.736 159.117 182.796 159.151 179.171 159.151C175.546 159.151 172.606 159.117 172.606 159.078C172.606 159.038 175.546 159.005 179.171 159.005C182.796 159.005 185.736 159.038 185.736 159.078Z"
          fill="#5F7881"
        />
        <Path
          d="M186.329 169.017C186.329 169.057 183.134 169.09 179.194 169.09C175.255 169.09 172.06 169.057 172.06 169.017C172.06 168.977 175.255 168.944 179.194 168.944C183.134 168.944 186.329 168.977 186.329 169.017Z"
          fill="#3A4750"
        />
        <Path
          d="M70.5963 139.882C70.5665 139.922 70.2983 139.759 70.0004 139.521C69.7024 139.283 69.4839 139.058 69.517 139.018C69.5501 138.978 69.8183 139.137 70.1162 139.375C70.4142 139.614 70.6294 139.842 70.5963 139.882Z"
          fill="#3A4750"
        />
        <Path
          d="M78.7344 127.721C78.7841 126.582 78.8139 125.403 78.4563 124.337C78.0988 123.271 77.2644 122.268 76.1388 122.036C74.8674 121.775 73.5663 122.576 72.8544 123.662C72.1426 124.748 71.8943 126.062 71.6857 127.343C70.2348 136.177 69.7001 145.138 70.0899 154.081L75.2912 154.81C77.5889 145.477 78.3173 137.319 78.7344 127.721Z"
          fill="#5F7881"
        />
        <Path
          d="M75.4302 121.997C75.4256 122.025 75.4178 122.053 75.407 122.08L75.321 122.315C75.2349 122.537 75.119 122.835 74.9733 123.215C74.9005 123.411 74.8045 123.623 74.7283 123.878C74.6522 124.133 74.5661 124.384 74.4734 124.666C74.3807 124.947 74.2847 125.252 74.182 125.569C74.0794 125.887 74.0099 126.232 73.9139 126.602C73.4834 128.297 73.1518 130.015 72.9206 131.747C72.6392 133.704 72.4538 135.869 72.3081 138.147C72.0433 142.703 72.0002 146.841 71.8843 149.831C71.8281 151.328 71.7784 152.539 71.7188 153.374C71.6923 153.778 71.6725 154.099 71.6559 154.334C71.6559 154.436 71.6393 154.516 71.6327 154.585C71.6362 154.613 71.6362 154.641 71.6327 154.668C71.6299 154.64 71.6299 154.611 71.6327 154.582C71.6327 154.516 71.6327 154.433 71.6327 154.334C71.6327 154.095 71.6526 153.774 71.6658 153.37C71.6989 152.533 71.732 151.324 71.7685 149.828C71.8545 146.848 71.8744 142.7 72.1393 138.137C72.285 135.856 72.4704 133.684 72.7683 131.724C73.0103 129.985 73.3597 128.262 73.8145 126.566L74.096 125.536L74.4039 124.632L74.672 123.848C74.7548 123.606 74.8541 123.394 74.9336 123.202L75.311 122.315C75.3541 122.222 75.3872 122.146 75.417 122.086C75.4164 122.056 75.4208 122.026 75.4302 121.997Z"
          fill="#3A4750"
        />
        <Path
          d="M75.9103 122C76.1821 122.32 76.3971 122.684 76.546 123.076C76.9445 124.15 77.1071 125.297 77.0227 126.44C76.8837 129.314 76.6254 133.273 76.2844 137.647C75.9434 142.02 75.5593 145.974 75.2514 148.831C75.0991 150.261 74.9667 151.413 74.8674 152.211C74.8177 152.599 74.778 152.903 74.7482 153.132L74.7151 153.37C74.7253 153.372 74.7344 153.378 74.7404 153.387C74.7465 153.396 74.7491 153.406 74.7479 153.417C74.7467 153.427 74.7416 153.437 74.7338 153.444C74.7259 153.451 74.7157 153.455 74.7051 153.455C74.6946 153.455 74.6844 153.451 74.6765 153.444C74.6687 153.437 74.6636 153.427 74.6624 153.417C74.6612 153.406 74.6638 153.396 74.6699 153.387C74.6759 153.378 74.685 153.372 74.6952 153.37C74.6952 153.304 74.6952 153.224 74.6952 153.128C74.7151 152.903 74.7449 152.595 74.778 152.208C74.8574 151.39 74.9667 150.221 75.1091 148.821C75.3806 145.96 75.7414 142.007 76.0858 137.637C76.4301 133.267 76.7049 129.304 76.877 126.44C76.977 125.312 76.8417 124.175 76.4797 123.103C76.3245 122.718 76.1339 122.349 75.9103 122Z"
          fill="#3A4750"
        />
        <Path
          d="M79.519 135.462C78.8456 135.352 78.1981 135.12 77.6086 134.776C78.282 134.886 78.9295 135.118 79.519 135.462Z"
          fill="#3A4750"
        />
        <Path
          d="M80.1448 130.161C79.8634 130.385 79.5467 130.561 79.2078 130.681C78.8926 130.857 78.5484 130.976 78.1914 131.032C78.4727 130.808 78.7894 130.632 79.1284 130.512C79.4434 130.335 79.7877 130.216 80.1448 130.161Z"
          fill="#3A4750"
        />
        <Path
          d="M79.8203 125.834C79.5686 125.907 79.3034 125.921 79.0456 125.874C78.7828 125.885 78.5212 125.835 78.2808 125.728C78.5322 125.654 78.7977 125.64 79.0555 125.688C79.3184 125.676 79.5806 125.726 79.8203 125.834Z"
          fill="#3A4750"
        />
        <Path
          d="M74.4536 123.062C74.4072 123.089 74.1821 122.804 73.9437 122.427C73.7734 122.212 73.6534 121.962 73.5928 121.695C73.6358 121.669 73.8643 121.95 74.0993 122.327C74.3344 122.705 74.4933 123.033 74.4536 123.062Z"
          fill="#3A4750"
        />
        <Path
          d="M72.6789 125.678C72.4426 125.763 72.1892 125.789 71.9406 125.754C71.5333 125.754 71.2056 125.698 71.2056 125.645C71.4419 125.56 71.6952 125.534 71.9439 125.569C72.3511 125.579 72.6789 125.625 72.6789 125.678Z"
          fill="#3A4750"
        />
        <Path
          d="M71.4043 130.913C71.1322 130.899 70.8665 130.826 70.6263 130.698C70.219 130.532 69.9012 130.366 69.921 130.313C70.1922 130.323 70.4575 130.396 70.6958 130.525C71.1063 130.691 71.4241 130.863 71.4043 130.913Z"
          fill="#3A4750"
        />
        <Path
          d="M71.2685 135.313C71.2685 135.363 70.9837 135.469 70.6063 135.548C70.2289 135.628 69.9176 135.651 69.9077 135.601C69.8978 135.552 70.1924 135.446 70.5699 135.366C70.9473 135.287 71.2585 135.264 71.2685 135.313Z"
          fill="#3A4750"
        />
        <Path
          d="M70.6693 142.513C70.6693 142.563 70.3647 142.662 69.9674 142.735C69.7289 142.807 69.4768 142.819 69.2324 142.772C69.2324 142.722 69.537 142.623 69.9343 142.55C70.1728 142.479 70.425 142.466 70.6693 142.513Z"
          fill="#3A4750"
        />
        <Path
          d="M70.2719 146.49C70.295 146.533 70.0335 146.719 69.6858 146.904C69.3382 147.089 69.0237 147.199 69.0237 147.156C69.0237 147.113 69.2621 146.924 69.6097 146.738C69.9573 146.553 70.2487 146.444 70.2719 146.49Z"
          fill="#3A4750"
        />
        <Path
          d="M73.5165 150.999C73.4734 151.026 73.245 150.711 73.0066 150.294C72.8408 150.056 72.7279 149.785 72.6755 149.5C72.7186 149.473 72.947 149.788 73.1854 150.205C73.3512 150.443 73.4641 150.714 73.5165 150.999Z"
          fill="#3A4750"
        />
        <Path
          d="M74.4536 143.504C74.49 143.54 74.2351 143.835 73.8842 144.192C73.5332 144.55 73.222 144.782 73.1856 144.745C73.1492 144.709 73.4041 144.414 73.755 144.057C74.106 143.699 74.4172 143.467 74.4536 143.504Z"
          fill="#3A4750"
        />
        <Path
          d="M74.2483 139.571C74.2085 139.604 73.9735 139.412 73.7185 139.137C73.4636 138.862 73.2914 138.614 73.3279 138.578C73.3643 138.541 73.6027 138.737 73.8543 139.011C74.1059 139.286 74.2847 139.534 74.2483 139.571Z"
          fill="#3A4750"
        />
        <Path
          d="M74.8906 134.545C74.9303 134.578 74.7151 134.876 74.4172 135.207C74.1192 135.538 73.8411 135.786 73.8047 135.753C73.7682 135.72 73.9768 135.422 74.2781 135.091C74.5794 134.76 74.8509 134.508 74.8906 134.545Z"
          fill="#3A4750"
        />
        <Path
          d="M75.2548 130.897C75.2184 130.933 74.9237 130.701 74.5926 130.377C74.2615 130.052 74.0232 129.761 74.0596 129.715C74.096 129.668 74.3907 129.91 74.7217 130.234C75.0528 130.559 75.2879 130.86 75.2548 130.897Z"
          fill="#3A4750"
        />
        <Path
          d="M75.9666 125.967C75.9111 126.234 75.7981 126.486 75.6355 126.705C75.4103 127.086 75.1885 127.367 75.1455 127.347C75.1024 127.327 75.2481 126.99 75.4766 126.609C75.705 126.228 75.9202 125.94 75.9666 125.967Z"
          fill="#3A4750"
        />
        <Path
          d="M77.304 121.394C77.3504 121.42 77.218 121.751 77.0094 122.135C76.8008 122.52 76.5988 122.798 76.5525 122.798C76.5941 122.533 76.6948 122.28 76.8472 122.059C77.0557 121.662 77.261 121.371 77.304 121.394Z"
          fill="#3A4750"
        />
        <Path
          d="M78.8238 123.47C78.847 123.517 78.6384 123.662 78.3603 123.801C78.0822 123.94 77.8372 124.003 77.814 123.957C77.7908 123.91 77.9994 123.765 78.2808 123.626C78.5623 123.487 78.7973 123.424 78.8238 123.47Z"
          fill="#3A4750"
        />
        <Path
          d="M78.8602 139.881C78.506 139.885 78.1566 139.799 77.8438 139.633C77.5069 139.524 77.2037 139.33 76.9631 139.07C77.2931 139.17 77.6124 139.302 77.9166 139.464C78.245 139.57 78.5613 139.709 78.8602 139.881Z"
          fill="#3A4750"
        />
        <Path
          d="M78.2411 143.358C78.2411 143.408 77.9365 143.51 77.5392 143.586C77.1419 143.662 76.8141 143.686 76.8042 143.633C76.7943 143.58 77.1088 143.48 77.5061 143.404C77.7436 143.329 77.996 143.313 78.2411 143.358Z"
          fill="#3A4750"
        />
        <Path
          d="M77.473 149.318C77.1533 149.159 76.8806 148.919 76.6817 148.623C76.4353 148.363 76.2638 148.042 76.1851 147.692C76.421 147.946 76.6358 148.218 76.8274 148.507C77.067 148.757 77.2832 149.028 77.473 149.318Z"
          fill="#3A4750"
        />
        <Path
          d="M69.1164 152.042C69.0833 152.002 69.2952 151.767 69.5866 151.516C69.8779 151.264 70.1461 151.092 70.1792 151.132C70.2123 151.171 70.0037 151.406 69.7091 151.658C69.4144 151.91 69.1496 152.082 69.1164 152.042Z"
          fill="#3A4750"
        />
        <Path
          d="M66.7127 139.034C66.3817 137.978 65.7394 136.747 64.6302 136.717C64.3004 136.735 63.9815 136.842 63.7067 137.025C63.4319 137.209 63.2112 137.462 63.0675 137.76C62.7657 138.351 62.6209 139.01 62.647 139.673C62.7837 144.381 63.5863 149.046 65.0308 153.529H69.0932C69.0428 148.604 68.24 143.716 66.7127 139.034Z"
          fill="#5F7881"
        />
        <Path
          d="M59.3694 132.379C58.7999 130.111 57.1081 128.883 55.1415 128.771C53.347 129.085 52.6484 130.853 52.4365 132.661C52.2246 134.468 52.7842 136.273 53.3404 138.008L58.684 154.727L63.7363 154.178C62.1372 146.708 61.2201 139.789 59.3694 132.379Z"
          fill="#5F7881"
        />
        <Path
          d="M64.6304 136.736C64.3749 137.069 64.1946 137.453 64.102 137.863C64.0094 138.272 64.0067 138.696 64.0941 139.107C64.1735 139.878 64.2629 140.809 64.3921 141.825C64.5212 142.841 64.7066 143.964 64.935 145.136C65.3952 147.47 65.9283 149.562 66.2395 151.095C66.4083 151.86 66.5342 152.479 66.6103 152.906C66.6467 153.105 66.6732 153.267 66.6964 153.403C66.7101 153.46 66.7156 153.519 66.7129 153.578C66.6881 153.525 66.6703 153.468 66.66 153.41C66.6269 153.277 66.5871 153.118 66.5408 152.923C66.4315 152.469 66.2892 151.863 66.1137 151.125C65.7528 149.609 65.2131 147.52 64.7529 145.166C64.5245 143.997 64.3457 142.875 64.2199 141.855C64.157 141.342 64.1139 140.862 64.0709 140.395C64.0279 139.928 63.9848 139.514 63.965 139.12C63.8914 138.497 63.9792 137.865 64.2199 137.286C64.3161 137.076 64.4561 136.888 64.6304 136.736Z"
          fill="#3A4750"
        />
        <Path
          d="M55.2143 128.625C54.9909 128.884 54.7862 129.158 54.6018 129.446C54.1366 130.254 53.8732 131.163 53.8337 132.095C53.8116 133.476 54.0231 134.85 54.4594 136.16L55.8996 141.041C56.8928 144.504 57.8199 147.636 58.4853 149.927C58.8164 151.052 59.0647 151.97 59.2501 152.628L59.4488 153.363C59.4763 153.448 59.4952 153.536 59.5051 153.625C59.4677 153.544 59.4367 153.459 59.4124 153.373C59.3528 153.191 59.2766 152.95 59.1806 152.648C58.982 151.986 58.7039 151.082 58.3595 149.966L55.7109 141.094L54.2972 136.203C53.8635 134.7 53.6118 133.28 53.7078 132.081C53.7584 131.135 54.0464 130.216 54.5455 129.41C54.7239 129.113 54.9499 128.848 55.2143 128.625Z"
          fill="#3A4750"
        />
        <Path
          d="M62.6371 153.638C62.5916 153.561 62.5528 153.481 62.5213 153.397C62.455 153.235 62.3491 152.996 62.2332 152.685C61.9915 152.063 61.6836 151.149 61.3658 150.003C61.048 148.858 60.7301 147.47 60.4222 145.928C60.1143 144.385 59.8097 142.683 59.472 140.902C59.1343 139.121 58.7304 137.442 58.2768 135.936C57.8833 134.603 57.3908 133.301 56.8035 132.042C56.4058 131.194 55.95 130.374 55.4394 129.589C55.2673 129.304 55.1084 129.102 55.0157 128.953C54.9608 128.882 54.9132 128.805 54.8733 128.725C54.9359 128.788 54.9935 128.857 55.0454 128.93C55.1481 129.072 55.3169 129.261 55.5023 129.549C56.0365 130.324 56.5113 131.139 56.9227 131.986C57.5304 133.244 58.0374 134.548 58.439 135.886C58.9299 137.519 59.3355 139.177 59.6541 140.852C59.9852 142.637 60.3163 144.335 60.5911 145.881C60.8659 147.427 61.187 148.808 61.4916 149.954C61.7962 151.099 62.0809 152.019 62.3028 152.645C62.4054 152.976 62.5014 153.201 62.5577 153.367C62.5905 153.456 62.617 153.546 62.6371 153.638Z"
          fill="#3A4750"
        />
        <Path
          d="M53.4299 137.173C53.4299 137.226 53.1683 137.336 52.8174 137.418C52.4665 137.501 52.1718 137.534 52.1552 137.485C52.1387 137.435 52.4168 137.326 52.7711 137.24C53.1253 137.154 53.42 137.124 53.4299 137.173Z"
          fill="#3A4750"
        />
        <Path
          d="M52.8934 133.727C52.3298 133.829 51.7548 133.851 51.1851 133.793C51.7358 133.605 52.3297 133.582 52.8934 133.727Z"
          fill="#3A4750"
        />
        <Path
          d="M53.4629 130.578C53.4298 130.618 53.1583 130.439 52.8504 130.181C52.5425 129.923 52.324 129.678 52.3571 129.641C52.3902 129.605 52.665 129.781 52.9729 130.039C53.2808 130.297 53.496 130.539 53.4629 130.578Z"
          fill="#3A4750"
        />
        <Path
          d="M55.0059 127.661C55.0556 127.661 55.0423 127.92 55.0357 128.218C55.0291 128.516 55.0357 128.761 54.9728 128.767C54.9099 128.774 54.8404 128.529 54.8503 128.211C54.8603 127.893 54.9596 127.651 55.0059 127.661Z"
          fill="#3A4750"
        />
        <Path
          d="M58.1543 129.035C58.1974 129.065 58.0749 129.313 57.8795 129.591C57.6842 129.869 57.4922 130.071 57.4524 130.042C57.4127 130.012 57.5319 129.764 57.7272 129.485C57.9226 129.207 58.1113 129.005 58.1543 129.035Z"
          fill="#3A4750"
        />
        <Path
          d="M60.1971 132.588C60.217 132.634 59.9554 132.786 59.6144 132.919C59.2734 133.051 58.9787 133.131 58.9522 133.084C58.9258 133.038 59.1939 132.886 59.5383 132.753C59.8826 132.621 60.1806 132.538 60.1971 132.588Z"
          fill="#3A4750"
        />
        <Path
          d="M61.4387 136.24C61.2116 136.432 60.9329 136.553 60.6375 136.588C60.3554 136.683 60.0519 136.694 59.7634 136.621C60.0276 136.513 60.3048 136.441 60.5878 136.406C60.8628 136.313 61.1489 136.257 61.4387 136.24Z"
          fill="#3A4750"
        />
        <Path
          d="M55.7475 135.203C55.6978 135.187 55.7475 134.935 55.8236 134.637C55.8998 134.339 56.0322 134.114 56.0819 134.131C56.1315 134.147 56.0819 134.399 56.0024 134.694C55.923 134.988 55.7938 135.217 55.7475 135.203Z"
          fill="#3A4750"
        />
        <Path
          d="M56.5388 139.79C56.557 139.522 56.635 139.262 56.7673 139.028C56.9427 138.631 57.1215 138.323 57.1679 138.343C57.1501 138.611 57.072 138.871 56.9394 139.104C56.7673 139.501 56.5885 139.809 56.5388 139.79Z"
          fill="#3A4750"
        />
        <Path
          d="M54.5786 141.041C54.5588 141.09 54.2476 141.001 53.8503 140.882C53.453 140.763 53.1517 140.65 53.1616 140.597C53.4149 140.563 53.6727 140.6 53.9065 140.703C54.2972 140.829 54.5985 140.998 54.5786 141.041Z"
          fill="#3A4750"
        />
        <Path
          d="M55.7078 144.781C55.7376 144.824 55.5224 145.03 55.2244 145.242C54.9264 145.453 54.6649 145.592 54.6351 145.549C54.6053 145.506 54.8205 145.301 55.1185 145.089C55.4164 144.877 55.678 144.742 55.7078 144.781Z"
          fill="#3A4750"
        />
        <Path
          d="M56.6149 148.51C56.6149 148.562 56.36 148.668 56.019 148.751C55.678 148.834 55.3899 148.864 55.3767 148.814C55.3634 148.764 55.6316 148.655 55.976 148.572C56.3203 148.49 56.6149 148.46 56.6149 148.51Z"
          fill="#3A4750"
        />
        <Path
          d="M61.538 139.355C61.5645 139.398 61.3361 139.58 61.0017 139.706C60.6673 139.832 60.3759 139.845 60.366 139.795C60.3561 139.746 60.6209 139.65 60.9355 139.531C61.25 139.411 61.5115 139.309 61.538 139.355Z"
          fill="#3A4750"
        />
        <Path
          d="M62.2432 145.153C62.2201 145.202 61.9486 145.12 61.6341 144.971C61.3195 144.822 61.0811 144.666 61.101 144.62C61.1209 144.573 61.3957 144.653 61.7102 144.802C62.0247 144.951 62.2631 145.106 62.2432 145.153Z"
          fill="#3A4750"
        />
        <Path
          d="M63.4814 149.317C63.2021 149.304 62.9289 149.23 62.6802 149.102C62.4087 149.029 62.1567 148.896 61.9419 148.715C62.2229 148.725 62.498 148.798 62.7464 148.93C63.1735 149.082 63.5013 149.258 63.4814 149.317Z"
          fill="#3A4750"
        />
        <Path
          d="M56.8134 152.556C56.7902 152.51 57.1213 152.288 57.502 151.987C57.72 151.781 57.9611 151.601 58.2205 151.45C58.2602 151.483 58.0185 151.818 57.6179 152.132C57.3946 152.346 57.1159 152.493 56.8134 152.556Z"
          fill="#3A4750"
        />
        <Path
          d="M60.0979 150.446C60.0449 150.446 59.9721 150.172 59.8463 149.847C59.7205 149.523 59.5881 149.268 59.6311 149.238C59.6741 149.208 59.886 149.43 60.0185 149.781C60.1509 150.132 60.1476 150.443 60.0979 150.446Z"
          fill="#3A4750"
        />
        <Path
          d="M59.2272 145.454C59.1974 145.497 58.9855 145.395 58.7537 145.229C58.5219 145.064 58.3597 144.898 58.3895 144.852C58.4193 144.805 58.6312 144.911 58.863 145.077C59.0947 145.242 59.2636 145.411 59.2272 145.454Z"
          fill="#3A4750"
        />
        <Path
          d="M66.8785 137.631C66.9116 137.674 66.703 137.876 66.4183 138.088C66.1335 138.3 65.8786 138.435 65.8488 138.392C65.819 138.349 66.0243 138.147 66.309 137.939C66.5937 137.73 66.8454 137.591 66.8785 137.631Z"
          fill="#3A4750"
        />
        <Path
          d="M68.0306 140.783C68.0306 140.832 67.6995 140.902 67.3121 140.859C66.9247 140.816 66.6102 140.68 66.6235 140.634C66.6367 140.587 66.9545 140.634 67.332 140.673C67.7094 140.713 68.0272 140.73 68.0306 140.783Z"
          fill="#3A4750"
        />
        <Path
          d="M63.0742 141.491C63.0742 141.54 62.7861 141.646 62.412 141.673C62.0379 141.699 61.7134 141.63 61.7201 141.58C61.7267 141.531 62.028 141.511 62.3988 141.487C62.7696 141.464 63.0643 141.438 63.0742 141.491Z"
          fill="#3A4750"
        />
        <Path
          d="M63.4981 138.511C63.4981 138.561 63.1935 138.568 62.8624 138.415C62.5313 138.263 62.3393 138.028 62.3757 137.995C62.4122 137.962 62.6373 138.108 62.9386 138.247C63.2399 138.386 63.508 138.459 63.4981 138.511Z"
          fill="#3A4750"
        />
        <Path
          d="M68.4545 144.166C68.428 144.209 68.1234 144.063 67.7625 143.835C67.4016 143.606 67.1433 143.394 67.1698 143.351C67.1963 143.308 67.5009 143.454 67.8618 143.682C68.2227 143.911 68.4809 144.123 68.4545 144.166Z"
          fill="#3A4750"
        />
        <Path
          d="M80.5488 153.013H54.1086V157.697H80.5488V153.013Z"
          fill="#EF7136"
        />
        <Path
          d="M56.0818 156.767L59.2966 170.771H75.0561L78.5721 156.767H56.0818Z"
          fill="#EF7136"
        />
        <Path
          d="M80.5321 157.704C80.5321 157.754 74.5726 157.797 67.2127 157.797C59.8528 157.797 53.8933 157.754 53.8933 157.704C53.8933 157.654 59.8528 157.611 67.2127 157.611C74.5726 157.611 80.5321 157.651 80.5321 157.704Z"
          fill="#3A4750"
        />
        <Path
          d="M65.829 141.594C65.7859 141.62 65.6038 141.408 65.425 141.123C65.2462 140.839 65.137 140.581 65.18 140.554C65.2231 140.528 65.4019 140.736 65.584 141.024C65.766 141.312 65.8852 141.564 65.829 141.594Z"
          fill="#3A4750"
        />
        <Path
          d="M65.8388 145.361C65.7892 145.361 65.8388 145.03 65.9779 144.652C66.117 144.275 66.2428 143.99 66.2924 143.99C66.305 144.235 66.2573 144.48 66.1534 144.702C66.0276 145.086 65.8852 145.377 65.8388 145.361Z"
          fill="#3A4750"
        />
        <Path
          d="M67.1299 149.318C67.0836 149.341 66.8849 149.023 66.6863 148.612C66.5398 148.373 66.4471 148.104 66.4148 147.824C66.4611 147.801 66.6565 148.119 66.8551 148.53C67.0035 148.769 67.0973 149.038 67.1299 149.318Z"
          fill="#3A4750"
        />
        <Path
          d="M64.3356 152.506C64.2992 152.472 64.4548 152.227 64.6899 151.956C64.9249 151.684 65.1434 151.499 65.1798 151.532C65.2163 151.565 65.064 151.81 64.8289 152.078C64.5938 152.347 64.3753 152.539 64.3356 152.506Z"
          fill="#3A4750"
        />
        <Path
          d="M63.7498 146.722C63.7498 146.775 63.4882 146.772 63.1836 146.722C62.879 146.672 62.6373 146.583 62.6473 146.533C62.6572 146.484 62.9088 146.484 63.2134 146.533C63.518 146.583 63.7597 146.669 63.7498 146.722Z"
          fill="#3A4750"
        />
        <Path
          d="M155.737 120.805H93.0371V170.805H155.737V120.805Z"
          fill="#E0E0E0"
        />
        <Path
          d="M97.6028 120.911C104.986 123.559 113.127 124.106 121.05 124.255C122.689 124.285 124.361 124.281 125.94 124.695C127.519 125.109 129.062 126 129.88 127.423C130.767 128.973 130.671 130.916 130.168 132.628C129.665 134.339 128.791 135.939 128.158 137.594C127.048 140.542 126.706 143.724 127.165 146.841C127.31 148.171 127.757 149.449 128.473 150.579C129.234 151.672 130.459 152.503 131.784 152.522C134.167 152.556 135.8 150.205 136.978 148.146C141.825 139.657 147.066 130.006 150.169 120.735L97.6028 120.911Z"
          fill="#EF7136"
        />
        <Path
          d="M114.399 170.536C114.339 170.536 114.289 159.455 114.289 145.788C114.289 132.121 114.339 121.036 114.399 121.036C114.458 121.036 114.511 132.117 114.511 145.788C114.511 159.458 114.461 170.536 114.399 170.536Z"
          fill="#5F7881"
        />
        <Path
          d="M140.09 83.8562C139.905 82.608 136.177 78.4861 136.177 78.4861C136.177 78.4861 134.316 71.0069 133.757 68.8019C133.57 68.1665 133.297 67.5594 132.946 66.9975C131.627 64.7995 129.75 62.9892 127.506 61.7499L122.348 58.8496L121.116 56.1017L108.724 49.7383L105.363 55.6646C105.363 55.6646 97.2055 58.257 96.1262 60.1077C95.0469 61.9585 96.6758 86.7796 96.6758 86.7796C96.6758 86.7796 93.259 101.106 92.5406 104.069C91.8221 107.032 92.7723 110.568 92.7723 110.568L98.8477 102.953C100.735 100.582 104.883 99.4932 107.86 99.5362L133.307 99.6488L132.293 92.5206L152.867 96.5201C152.867 96.5201 156.409 89.617 156.688 89.478C156.966 89.3389 140.09 83.8562 140.09 83.8562Z"
          fill="#EF7136"
        />
        <Path
          d="M108.194 57.9925C108.015 57.8138 109.634 63.2898 111.66 68.8321C112.757 71.8353 113.317 75.0079 113.316 78.205V98.3315L125.516 97.5038L123.692 84.2605C123.692 84.2605 122.205 75.7054 121.788 72.4509C121.407 69.4711 120.096 61.3927 120.096 61.3927C120.096 61.3927 113.303 63.1971 108.194 57.9925Z"
          fill="white"
        />
        <Path
          d="M135.266 88.8593C135.247 88.7576 135.247 88.6532 135.266 88.5514C135.294 88.2731 135.348 87.9982 135.429 87.7303C135.687 86.8368 136.167 86.0227 136.823 85.3631C137.482 84.7074 138.295 84.2261 139.186 83.9626C139.455 83.8843 139.73 83.8311 140.008 83.8037C140.203 83.7839 140.312 83.7872 140.312 83.8037C140.312 83.8203 139.885 83.8865 139.233 84.1116C138.387 84.4082 137.616 84.8888 136.978 85.5187C136.347 86.1573 135.868 86.9304 135.578 87.78C135.349 88.4289 135.306 88.8626 135.266 88.8593Z"
          fill="#3A4750"
        />
        <Path
          d="M135.896 84.0286C135.869 83.9789 136.67 83.575 137.76 83.4889C138.849 83.4028 139.703 83.6644 139.687 83.7174C139.67 83.7703 138.822 83.618 137.776 83.7174C136.73 83.8167 135.919 84.0948 135.896 84.0286Z"
          fill="#3A4750"
        />
        <Path
          d="M95.4309 95.0205L94.3582 96.2124C95.4938 97.5367 96.9671 98.6392 98.6622 99.0564C100.366 99.4185 102.106 99.5763 103.847 99.5265C104.553 99.558 105.259 99.4901 105.946 99.3246C106.638 99.1259 107.27 98.7352 107.999 98.6922C108.48 98.6952 108.959 98.7632 109.422 98.8942C109.887 99.0275 110.378 99.0354 110.846 98.9173C111.079 98.8553 111.286 98.7197 111.436 98.5305C111.585 98.3414 111.67 98.1089 111.677 97.8678C106.383 96.3246 100.934 95.3698 95.4309 95.0205Z"
          fill="#EF7136"
        />
        <Path
          d="M130.26 96.5532L127.281 93.3715L121.123 91.8154C121.123 91.8154 115.478 93.5039 115.342 93.5006C115.206 93.4973 110.432 92.1962 110.432 92.1962L108.777 98.3179L117.385 98.9072L127.205 98.5132C127.205 98.5132 127.718 98.0364 127.416 97.712C127.115 97.3875 129.055 98.2616 129.287 98.2715C129.519 98.2814 129.949 98.4602 130.28 98.0927C130.446 97.8411 130.717 97.0399 130.26 96.5532Z"
          fill="#FFBE9D"
        />
        <Path
          d="M127.383 97.7122C127.044 97.6515 126.712 97.5584 126.39 97.4341C125.794 97.242 124.967 96.9904 124.043 96.752C123.119 96.5136 122.272 96.3415 121.659 96.219C121.321 96.176 120.988 96.0984 120.666 95.9872C121.006 95.9708 121.348 95.9964 121.682 96.0634C122.495 96.178 123.301 96.3372 124.096 96.5401C124.891 96.7431 125.673 96.9918 126.44 97.2851C126.771 97.3877 127.088 97.5311 127.383 97.7122Z"
          fill="#EB996E"
        />
        <Path
          d="M130.261 96.5528C129.797 96.4299 129.341 96.2829 128.893 96.1124C128.056 95.8277 126.883 95.4834 125.582 95.1192C124.281 94.755 123.119 94.3445 122.242 94.2948C121.769 94.2709 121.294 94.2897 120.825 94.3511C120.939 94.2878 121.063 94.2452 121.192 94.2253C121.54 94.1497 121.896 94.1207 122.252 94.1392C123.159 94.1392 124.354 94.5232 125.642 94.9073C126.93 95.2913 128.118 95.6423 128.953 95.9634C129.367 96.1224 129.698 96.2647 129.926 96.3674C130.045 96.4138 130.158 96.4761 130.261 96.5528Z"
          fill="#EB996E"
        />
        <Path
          d="M96.126 60.1074C96.126 60.1074 91.6862 63.1964 90.6069 67.6428C89.5275 72.0892 84.8461 90.2391 85.8062 92.954C86.7663 95.6688 105.433 98.3307 105.433 98.3307L107.644 89.0009L98.1655 86.4118C98.1655 86.4118 98.2846 77.7673 98.4038 74.185C98.523 70.6027 96.126 60.1074 96.126 60.1074Z"
          fill="#EF7136"
        />
        <Path
          d="M107.519 90.3955L110.575 91.1471L108.737 98.1925L105.734 97.7124L107.519 90.3955Z"
          fill="white"
        />
        <Path
          d="M85.75 91.9942C85.7665 91.9942 85.75 92.1498 85.7963 92.4345C85.9036 92.847 86.1347 93.2168 86.4585 93.494C87.2696 94.2489 88.8191 94.8779 90.7857 95.2322C91.6829 95.5268 92.706 95.7619 93.7985 96.0102C94.8911 96.2585 96.0698 96.5035 97.3113 96.7584C99.7944 97.265 102.542 97.798 105.456 98.3443L105.327 98.4271C105.539 97.5597 105.761 96.6459 105.989 95.7188L107.499 89.5342L107.575 89.6634L99.1488 87.2597C97.9894 86.7701 96.7339 86.5505 95.4771 86.6174C94.5207 86.674 93.5816 86.8981 92.7027 87.2796C92.145 87.5218 91.6118 87.8167 91.1102 88.1603L90.7228 88.4417C90.6806 88.4768 90.6351 88.5078 90.5871 88.5344C90.6228 88.4928 90.6627 88.4551 90.7062 88.4218C90.7923 88.3556 90.9115 88.2463 91.0804 88.1205C91.5727 87.7542 92.1034 87.4422 92.6629 87.1902C93.5484 86.7909 94.4981 86.5532 95.4672 86.4883C96.7476 86.4078 98.0294 86.6206 99.215 87.1107L107.648 89.478L107.747 89.5045L107.724 89.6038C107.244 91.5903 106.731 93.6463 106.221 95.7884C105.992 96.7187 105.77 97.6325 105.559 98.4966L105.532 98.5993L105.429 98.5794C102.519 98.0298 99.7713 97.4901 97.2881 96.9703C96.0466 96.7121 94.8712 96.4538 93.7754 96.1989C92.6795 95.944 91.6631 95.7023 90.7493 95.3944C88.7628 95.0136 87.21 94.3614 86.3923 93.5668C86.0689 93.2719 85.8465 92.8827 85.7566 92.4544C85.7258 92.3027 85.7235 92.1467 85.75 91.9942Z"
          fill="#3A4750"
        />
        <Path
          d="M98.6622 87.0284C98.639 87.0582 98.3311 86.7669 97.7517 86.3861C96.2607 85.3869 94.4971 84.8734 92.7028 84.9161C92.0174 84.9161 91.597 85.0055 91.5903 84.9691C91.9467 84.8367 92.3227 84.765 92.7028 84.7572C94.542 84.6247 96.3678 85.1569 97.8478 86.257C98.1594 86.4681 98.4345 86.7287 98.6622 87.0284Z"
          fill="#3A4750"
        />
        <Path
          d="M99.9203 71.8252C99.9381 72.0146 99.9381 72.2053 99.9203 72.3947C99.9203 72.7622 99.8806 73.2886 99.8309 73.9408C99.725 75.2453 99.5296 77.0463 99.1886 79.013C98.9166 80.6944 98.5238 82.3541 98.0133 83.9792C97.9073 84.2904 97.8014 84.5685 97.7087 84.8102C97.616 85.0519 97.52 85.2439 97.4372 85.4227C97.3581 85.5964 97.2616 85.7617 97.1492 85.916C97.2013 85.7336 97.2724 85.5572 97.3611 85.3896L97.5928 84.7671C97.6756 84.5221 97.7683 84.244 97.8643 83.9328C98.3279 82.2998 98.6982 80.6417 98.9734 78.9666C99.3045 77.0066 99.5296 75.2155 99.6753 73.9176C99.7382 73.3018 99.7912 72.7853 99.8342 72.3781C99.8433 72.1913 99.8722 72.0059 99.9203 71.8252Z"
          fill="#3A4750"
        />
        <Path
          d="M110.743 67.0108C110.743 67.0108 110.7 66.8949 110.598 66.6797C110.447 66.3968 110.227 66.1565 109.959 65.9811C109.759 65.8453 109.531 65.7566 109.291 65.7216C109.052 65.6866 108.808 65.7063 108.578 65.7791C108.286 65.88 108.024 66.0551 107.82 66.2872C107.616 66.5194 107.475 66.8007 107.413 67.1035L107.353 67.4047L107.224 67.1266C106.727 66.0594 106.294 64.9638 105.926 63.8456C105.276 61.9838 104.951 60.0238 104.966 58.0517C104.966 57.6743 105.019 57.3333 105.042 57.0353C105.065 56.7373 105.131 56.4824 105.174 56.2771C105.221 56.0639 105.284 55.8546 105.363 55.6514C105.218 56.4448 105.137 57.2486 105.121 58.055C105.154 60.0047 105.496 61.9369 106.135 63.7794C106.499 64.8885 106.924 65.9771 107.406 67.0406L107.217 67.0637C107.291 66.7281 107.453 66.4181 107.685 66.1652C107.918 65.9122 108.213 65.7253 108.541 65.6235C108.795 65.55 109.062 65.5353 109.322 65.5806C109.582 65.626 109.828 65.7301 110.041 65.8851C110.317 66.0798 110.533 66.3467 110.667 66.6565C110.703 66.7375 110.731 66.8217 110.75 66.9081C110.747 66.9776 110.75 67.0108 110.743 67.0108Z"
          fill="#3A4750"
        />
        <Path
          d="M121.099 67.9675C121.053 67.8015 121.02 67.6321 121 67.4609C120.907 66.9925 120.877 66.5137 120.911 66.0373C121.008 65.3049 121.374 64.6347 121.937 64.1568C122.283 63.8699 122.719 63.713 123.169 63.7131C123.418 63.7061 123.665 63.7548 123.893 63.8556C124.121 63.9564 124.324 64.1068 124.486 64.2958L124.301 64.4018C124.169 64.0707 124.033 63.7396 123.897 63.3853C123.102 61.3989 122.397 59.5713 121.911 58.2536C121.676 57.6212 121.48 57.0948 121.331 56.6876C121.248 56.4998 121.186 56.3032 121.146 56.1016C121.249 56.2797 121.337 56.4657 121.411 56.6578L122.073 58.1973C122.609 59.5018 123.338 61.3095 124.129 63.3092C124.268 63.6568 124.404 64.0012 124.529 64.3256L124.791 64.9878L124.344 64.4382C124.203 64.2769 124.029 64.1479 123.833 64.06C123.638 63.972 123.426 63.9271 123.212 63.9283C122.807 63.9257 122.415 64.0625 122.099 64.3157C121.569 64.7604 121.217 65.3808 121.106 66.0638C121.061 66.5278 121.069 66.9954 121.129 67.4576C121.139 67.6282 121.129 67.7992 121.099 67.9675Z"
          fill="#3A4750"
        />
        <Path
          d="M128.976 69.0205C129.039 69.0205 129.996 75.47 131.121 83.4424C132.247 91.4148 133.108 97.8842 133.045 97.8941C132.982 97.904 132.025 91.4479 130.903 83.4722C129.781 75.4965 128.916 69.0304 128.976 69.0205Z"
          fill="#3A4750"
        />
        <Path
          d="M106.055 63.9287C106.108 65.5797 106.467 67.2064 107.115 68.7261C107.575 68.1533 108.088 67.5507 108.793 67.3819C109.671 67.1733 110.558 67.713 111.19 68.3751C111.078 67.7146 110.816 67.0885 110.425 66.5443C110.231 66.2782 109.978 66.0604 109.685 65.9077C109.393 65.755 109.07 65.6715 108.74 65.6636C108.409 65.6719 108.091 65.7899 107.834 65.9991C107.578 66.2082 107.398 66.4966 107.323 66.8191L106.105 63.9618"
          fill="#EF7136"
        />
        <Path
          d="M98.8839 68.5963C98.8408 68.5963 98.8541 68.1526 98.7283 67.4573C98.5535 66.5555 98.2025 65.6971 97.6953 64.9312C97.4108 64.5141 97.0846 64.127 96.7219 63.7757C96.4212 63.4939 96.0954 63.2401 95.7485 63.0175C95.1526 62.6434 94.7321 62.4977 94.7553 62.458C94.8605 62.4655 94.9636 62.4913 95.0599 62.5342C95.1739 62.5685 95.2847 62.6128 95.391 62.6666C95.5412 62.73 95.6871 62.803 95.828 62.8851C96.1975 63.0936 96.5428 63.3423 96.8577 63.6267C97.2405 63.9806 97.5815 64.3772 97.8741 64.8087C98.3989 65.5965 98.7385 66.493 98.8673 67.4309C98.9096 67.7135 98.924 67.9996 98.9104 68.285C98.918 68.3895 98.9091 68.4946 98.8839 68.5963Z"
          fill="#3A4750"
        />
        <Path
          d="M123.533 38.2864C123.291 36.0147 122.203 33.9172 120.485 32.4107C118.768 30.9042 116.547 30.099 114.263 30.1551H113.793C108.72 30.5822 106.247 35.1213 106.817 40.1868L108.207 57.9791L108.796 58.7042C110.124 60.3298 111.952 61.4709 113.996 61.95C116.04 62.429 118.184 62.2191 120.096 61.3529L119.709 55.6285C119.709 55.6285 124.573 54.7445 124.506 49.861C124.463 47.5269 124.003 42.6733 123.533 38.2864Z"
          fill="#FFBE9D"
        />
        <Path
          d="M119.732 55.6478C117.464 55.7076 115.227 55.1146 113.286 53.9395C113.286 53.9395 114.882 57.4158 119.742 56.8497L119.732 55.6478Z"
          fill="#EB996E"
        />
        <Path
          d="M121.99 42.3253C121.997 42.5084 121.931 42.6867 121.807 42.8217C121.683 42.9567 121.511 43.0376 121.328 43.0471C121.239 43.0571 121.149 43.0491 121.064 43.0234C120.978 42.9978 120.898 42.955 120.83 42.8978C120.761 42.8406 120.705 42.77 120.664 42.6904C120.623 42.6108 120.599 42.5238 120.593 42.4346C120.588 42.252 120.654 42.0746 120.778 41.9403C120.901 41.8061 121.073 41.7256 121.255 41.7161C121.344 41.7061 121.434 41.714 121.519 41.7395C121.604 41.7649 121.684 41.8074 121.752 41.8643C121.821 41.9211 121.878 41.9913 121.918 42.0705C121.959 42.1498 121.984 42.2364 121.99 42.3253Z"
          fill="#3A4750"
        />
        <Path
          d="M122.301 39.6699C122.245 39.756 121.692 39.8156 121.033 40.1732C120.374 40.5307 119.974 40.9744 119.881 40.9678C119.838 40.9678 119.881 40.8453 120.047 40.6367C120.303 40.3558 120.607 40.1217 120.944 39.9447C121.266 39.7717 121.617 39.6595 121.98 39.6136C122.222 39.5871 122.328 39.6302 122.301 39.6699Z"
          fill="#3A4750"
        />
        <Path
          d="M114.941 42.7095C114.947 42.8923 114.881 43.0701 114.757 43.2049C114.633 43.3398 114.462 43.4209 114.279 43.4312C114.19 43.4413 114.1 43.4332 114.015 43.4076C113.929 43.3819 113.85 43.3392 113.781 43.2819C113.712 43.2247 113.656 43.1542 113.615 43.0745C113.575 42.9949 113.55 42.9079 113.544 42.8187C113.539 42.6358 113.605 42.4581 113.729 42.3233C113.852 42.1884 114.024 42.1072 114.206 42.097C114.295 42.0869 114.385 42.095 114.471 42.1206C114.556 42.1463 114.636 42.189 114.705 42.2462C114.773 42.3035 114.83 42.374 114.87 42.4536C114.911 42.5333 114.935 42.6203 114.941 42.7095Z"
          fill="#3A4750"
        />
        <Path
          d="M114.835 40.6968C114.736 40.72 114.282 40.4121 113.554 40.2333C112.826 40.0545 112.23 40.0943 112.17 40.0314C112.111 39.9684 112.246 39.9486 112.501 39.9254C112.879 39.9028 113.258 39.9397 113.624 40.0347C113.976 40.1184 114.312 40.2617 114.617 40.4584C114.829 40.5942 114.885 40.6902 114.835 40.6968Z"
          fill="#3A4750"
        />
        <Path
          d="M117.779 46.5298C118.17 46.395 118.578 46.3104 118.991 46.2782C119.183 46.2517 119.361 46.2087 119.391 46.0796C119.404 45.8807 119.354 45.6828 119.249 45.5134C119.057 45.0543 118.856 44.5732 118.646 44.0699C117.805 42.0106 117.193 40.3188 117.276 40.2856C117.358 40.2525 118.107 41.8914 118.931 43.9507L119.51 45.4042C119.637 45.6318 119.679 45.8967 119.63 46.1524C119.604 46.2207 119.562 46.2821 119.509 46.332C119.456 46.382 119.391 46.419 119.322 46.4405C119.214 46.4736 119.103 46.4925 118.991 46.4967C118.589 46.556 118.183 46.5671 117.779 46.5298Z"
          fill="#3A4750"
        />
        <Path
          d="M114.845 37.7965C114.763 37.9985 114.018 37.8661 113.134 37.929C112.25 37.9919 111.525 38.1971 111.415 38.0084C111.369 37.919 111.501 37.7336 111.799 37.5482C112.194 37.3247 112.634 37.1942 113.087 37.1666C113.54 37.1391 113.993 37.2153 114.412 37.3893C114.726 37.535 114.885 37.7038 114.845 37.7965Z"
          fill="#3A4750"
        />
        <Path
          d="M122.281 37.9647C122.119 38.1369 121.474 37.9647 120.699 37.9647C119.924 37.9647 119.265 38.0938 119.113 37.9183C119.047 37.8323 119.149 37.6601 119.444 37.4946C119.841 37.2908 120.283 37.1906 120.729 37.2032C121.173 37.2006 121.61 37.3147 121.997 37.5343C122.262 37.7065 122.354 37.8786 122.281 37.9647Z"
          fill="#3A4750"
        />
        <Path
          d="M124.96 33.9657C124.915 33.7405 124.789 33.5396 124.606 33.401C124.423 33.2625 124.196 33.196 123.967 33.2142C124.207 33.1474 124.431 33.0349 124.629 32.8831C124.729 32.8035 124.803 32.6968 124.844 32.5758C124.884 32.4548 124.889 32.3247 124.857 32.2011C124.735 31.8237 124.251 31.7442 123.864 31.6382C123.373 31.4891 122.938 31.1934 122.619 30.7907C122.156 30.2047 121.977 29.4399 121.576 28.8042C121.316 28.3933 120.967 28.0462 120.554 27.7884C120.142 27.5306 119.677 27.3687 119.194 27.3146C118.71 27.2606 118.221 27.3158 117.762 27.4762C117.302 27.6366 116.885 27.8981 116.541 28.2414C116.166 27.856 115.705 27.5657 115.196 27.3945C114.687 27.2234 114.144 27.1763 113.613 27.2573C113.082 27.3382 112.577 27.5449 112.142 27.86C111.707 28.1752 111.353 28.5897 111.111 29.0691C110.46 28.7982 109.748 28.707 109.05 28.805C108.351 28.903 107.692 29.1867 107.141 29.6264C106.589 30.0661 106.166 30.6457 105.915 31.3047C105.664 31.9638 105.595 32.6781 105.714 33.3731L108.757 35.2371C108.783 35.0068 108.858 34.7846 108.976 34.5849C109.094 34.3852 109.252 34.2125 109.441 34.078C109.63 33.9435 109.845 33.8501 110.072 33.804C110.299 33.7578 110.534 33.7599 110.76 33.8101C111.677 34.075 112.263 35.1345 113.217 35.2106C113.958 35.2669 114.591 34.6908 115.309 34.4988C116.183 34.267 117.103 34.6147 117.888 35.0583C118.673 35.502 119.424 36.0516 120.298 36.2734C121.344 36.4833 122.43 36.3545 123.397 35.9059C123.826 35.7524 124.218 35.5098 124.546 35.1941C124.707 35.0362 124.828 34.8427 124.9 34.6293C124.972 34.4159 124.992 34.1886 124.96 33.9657Z"
          fill="#3A4750"
        />
        <Path
          d="M109.283 33.8131L123.215 33.7303C122.715 32.7942 121.989 31.9976 121.103 31.4127C119.254 30.0631 117.026 29.3309 114.736 29.3203C112.465 29.3435 107.919 32.2371 106.32 33.8462C105.039 35.1374 105.075 36.22 105.075 36.22C104.916 38.0311 105.986 40.054 106.436 41.8153C106.668 42.7225 107.145 45.4771 107.145 45.4771C107.913 45.4539 108.181 44.8348 108.042 44.0799L107.817 41.1002C108.572 40.4724 109.1 39.6145 109.321 38.6579C109.543 37.7012 109.444 36.6984 109.042 35.8029C108.836 35.5711 108.379 34.3428 109.283 33.8131Z"
          fill="#3A4750"
        />
        <Path
          d="M107.601 40.9216C106.893 38.455 106.241 35.813 106.995 33.3597L106.582 33.3398L106.409 33.4657C106.016 33.3342 105.592 33.3314 105.197 33.4575C104.802 33.5837 104.458 33.8323 104.214 34.1673C103.97 34.5023 103.839 34.9063 103.841 35.3207C103.842 35.7351 103.975 36.1383 104.221 36.4719C103.807 36.4655 103.405 36.6124 103.093 36.8844C102.78 37.1564 102.58 37.5342 102.529 37.9452C102.527 38.5177 102.681 39.08 102.976 39.5708C103.273 40.0611 103.454 40.6125 103.506 41.1832C103.506 41.7526 103.145 42.3717 102.579 42.438C102.713 42.7278 102.943 42.9626 103.23 43.1025C103.517 43.2425 103.844 43.279 104.155 43.2061C103.958 43.6911 103.626 44.1093 103.198 44.4112C104.105 45.0303 105.353 44.8184 106.34 44.3384C106.949 44.0935 107.446 43.631 107.734 43.0405C107.999 42.3784 107.797 41.6169 107.601 40.9216Z"
          fill="#3A4750"
        />
        <Path
          d="M107.373 43.7952C107.287 43.7621 103.989 42.888 104.185 46.2253C104.38 49.5626 107.684 48.6786 107.684 48.5826C107.684 48.4866 107.373 43.7952 107.373 43.7952Z"
          fill="#FFBE9D"
        />
        <Path
          d="M106.886 47.3715C106.886 47.3715 106.83 47.4146 106.734 47.4675C106.598 47.5319 106.444 47.5437 106.3 47.5007C105.943 47.3914 105.611 46.8385 105.562 46.2492C105.536 45.9637 105.575 45.676 105.678 45.4082C105.702 45.3015 105.752 45.2025 105.823 45.1199C105.895 45.0372 105.986 44.9737 106.088 44.9348C106.154 44.9139 106.225 44.9163 106.289 44.9416C106.352 44.967 106.406 45.0137 106.439 45.0738C106.489 45.1665 106.472 45.2327 106.492 45.2394C106.512 45.246 106.558 45.1798 106.525 45.0407C106.499 44.9605 106.448 44.8908 106.38 44.8421C106.332 44.8084 106.278 44.7851 106.221 44.7737C106.164 44.7623 106.105 44.763 106.048 44.7759C105.913 44.8102 105.789 44.8788 105.688 44.9751C105.587 45.0714 105.513 45.192 105.472 45.3254C105.345 45.6228 105.294 45.947 105.323 46.269C105.383 46.9312 105.764 47.547 106.254 47.6596C106.345 47.6805 106.439 47.6806 106.531 47.66C106.622 47.6394 106.707 47.5985 106.78 47.5404C106.886 47.451 106.899 47.3782 106.886 47.3715Z"
          fill="#EB996E"
        />
        <Path
          d="M103.907 41.0305C103.907 41.0305 103.814 40.9047 103.847 40.6398C103.88 40.4582 103.953 40.2863 104.061 40.1364C104.169 39.9864 104.308 39.8621 104.469 39.7724C104.948 39.5452 105.469 39.419 105.999 39.4015C106.559 39.342 107.201 39.183 107.522 38.6301C107.797 38.0903 107.889 37.4754 107.784 36.8787C107.704 36.3192 107.555 35.8093 107.482 35.3723C107.303 34.4883 107.482 33.9255 107.505 33.9387C107.506 34.4087 107.558 34.8772 107.661 35.3359C107.757 35.7597 107.923 36.2563 108.019 36.8456C108.149 37.4983 108.052 38.1759 107.744 38.7659C107.556 39.0658 107.278 39.2978 106.949 39.428C106.653 39.54 106.342 39.608 106.025 39.63C105.523 39.6352 105.025 39.734 104.559 39.9213C104.413 39.9959 104.284 40.0987 104.179 40.2238C104.074 40.3489 103.995 40.4937 103.946 40.6497C103.925 40.7756 103.912 40.9028 103.907 41.0305Z"
          fill="#5F7881"
        />
        <Path
          d="M103.102 39.574C103.102 39.574 103.029 39.4779 102.999 39.2727C102.965 38.9766 103.023 38.6772 103.165 38.4152C103.264 38.2311 103.399 38.0689 103.562 37.9384C103.753 37.7966 103.97 37.6952 104.201 37.6404C104.428 37.5937 104.643 37.505 104.837 37.3789C104.921 37.3022 104.988 37.2079 105.031 37.1025C105.075 36.9972 105.094 36.8835 105.088 36.7697C105.088 36.5148 105.059 36.2466 105.065 35.9784C105.069 35.7236 105.096 35.4697 105.148 35.2202C105.238 34.7981 105.4 34.3948 105.628 34.0283C105.703 33.8953 105.809 33.7829 105.938 33.7014C106.067 33.6199 106.214 33.5718 106.366 33.5615C106.588 33.5615 106.698 33.6608 106.671 33.6708C106.572 33.661 106.472 33.661 106.373 33.6708C106.246 33.6982 106.126 33.7546 106.024 33.8356C105.922 33.9165 105.84 34.0198 105.784 34.1376C105.592 34.4952 105.456 34.8805 105.383 35.2798C105.341 35.5148 105.32 35.753 105.32 35.9917C105.32 36.2367 105.357 36.4982 105.347 36.7896C105.353 36.9405 105.324 37.0908 105.263 37.2291C105.203 37.3673 105.111 37.4899 104.996 37.5875C104.775 37.7327 104.527 37.8328 104.267 37.8821C104.074 37.9144 103.89 37.9875 103.727 38.0965C103.565 38.2056 103.427 38.3481 103.324 38.5145C103.148 38.8385 103.071 39.2067 103.102 39.574Z"
          fill="#5F7881"
        />
        <Path
          d="M122.622 30.7905C122.642 30.7905 122.371 30.9726 121.934 30.764C121.688 30.6333 121.473 30.4502 121.305 30.2277C121.093 29.9517 120.83 29.719 120.53 29.5423C120.139 29.3241 119.694 29.2236 119.247 29.2529C118.801 29.2823 118.372 29.4403 118.014 29.7079C117.6 30.0092 117.236 30.4263 116.756 30.774C116.509 30.9617 116.224 31.0938 115.921 31.1613C115.602 31.2167 115.274 31.1858 114.971 31.0719C114.369 30.8567 113.895 30.4859 113.375 30.2972C112.876 30.1051 112.344 30.0096 111.809 30.0158C110.894 30.0195 110.006 30.3256 109.283 30.8865C108.795 31.2664 108.389 31.7418 108.091 32.2837C107.883 32.6711 107.81 32.9061 107.787 32.8995C107.764 32.8929 107.787 32.8366 107.82 32.7207C107.869 32.5556 107.93 32.3941 108.002 32.2373C108.276 31.6581 108.675 31.1469 109.171 30.7409C109.919 30.1283 110.853 29.7886 111.819 29.7774C112.384 29.7637 112.947 29.8604 113.475 30.0621C114.034 30.2641 114.518 30.6349 115.064 30.8269C115.323 30.9264 115.605 30.9539 115.878 30.9064C116.145 30.8504 116.397 30.7375 116.617 30.5753C117.064 30.2442 117.438 29.837 117.878 29.5225C118.299 29.206 118.812 29.0362 119.338 29.0391C119.791 29.0399 120.235 29.161 120.626 29.39C120.939 29.5843 121.209 29.84 121.421 30.1416C121.572 30.3572 121.764 30.5407 121.987 30.6813C122.18 30.7889 122.404 30.8274 122.622 30.7905Z"
          fill="#5F7881"
        />
        <Path
          d="M115.925 47.404C116.031 47.3775 116.15 48.0927 116.845 48.4966C117.541 48.9005 118.289 48.6919 118.312 48.7913C118.312 48.8343 118.166 48.9568 117.845 49.0197C117.429 49.0983 116.998 49.0255 116.63 48.8145C116.277 48.6124 116.016 48.2813 115.902 47.8907C115.826 47.5961 115.878 47.4073 115.925 47.404Z"
          fill="#3A4750"
        />
        <Path
          d="M159.879 113.766C158.577 112.655 156.892 112.099 155.184 112.217L152.317 121.338C154.42 121.431 156.519 122.517 158.548 121.96C159.357 121.711 160.077 121.235 160.625 120.59C161.173 119.945 161.525 119.157 161.64 118.318C161.733 117.474 161.622 116.62 161.315 115.827C161.009 115.035 160.516 114.328 159.879 113.766Z"
          fill="#EF7136"
        />
        <Path
          d="M155.237 112.001C155.237 111.928 155.549 111.872 156.105 111.878C156.908 111.889 157.703 112.038 158.456 112.319C159.603 112.769 160.596 113.54 161.316 114.54C161.755 115.137 162.078 115.811 162.27 116.527C162.478 117.307 162.503 118.126 162.342 118.917C162.183 119.72 161.823 120.47 161.296 121.096C160.797 121.672 160.16 122.114 159.445 122.38C158.288 122.792 157.038 122.873 155.837 122.615C155.064 122.46 154.31 122.226 153.585 121.917C153.079 121.698 152.814 121.536 152.837 121.463C152.86 121.39 153.168 121.414 153.708 121.503C154.247 121.592 155.012 121.755 155.946 121.834C157.003 121.958 158.074 121.819 159.065 121.43C159.609 121.202 160.09 120.846 160.468 120.394C160.865 119.898 161.135 119.312 161.256 118.689C161.371 118.073 161.361 117.44 161.228 116.828C161.095 116.216 160.841 115.636 160.482 115.123C159.896 114.237 159.089 113.518 158.141 113.037C157.471 112.719 156.77 112.473 156.049 112.302C155.525 112.17 155.231 112.08 155.237 112.001Z"
          fill="#F5F5F5"
        />
        <Path
          d="M128.638 146.215L122.394 163.709L144.12 171.589C144.745 169.467 135.018 161.226 135.018 161.226L139.478 150.181L128.638 146.215Z"
          fill="#EF7136"
        />
        <G opacity="0.6">
          <Path
            opacity="0.6"
            d="M128.642 157.846C128.407 157.83 128.176 157.903 127.992 158.049C127.808 158.196 127.686 158.405 127.648 158.637C127.624 158.871 127.691 159.105 127.835 159.29C127.98 159.475 128.19 159.597 128.423 159.63C128.669 159.643 128.911 159.563 129.102 159.407C129.293 159.251 129.419 159.03 129.456 158.786C129.456 158.323 128.996 157.793 128.539 157.859"
            fill="white"
          />
        </G>
        <Path
          d="M122.394 163.71L123.026 161.945L143.663 170.176C143.663 170.176 144.477 170.947 144.12 171.59L122.394 163.71Z"
          fill="white"
        />
        <Path
          d="M135.412 161.236C135.372 161.339 134.823 161.196 134.177 161.349C133.532 161.501 133.091 161.845 133.008 161.766C132.926 161.686 133.306 161.134 134.094 160.965C134.882 160.796 135.468 161.153 135.412 161.236Z"
          fill="#3A4750"
        />
        <Path
          d="M137.346 163.011C137.346 163.123 136.809 163.136 136.286 163.454C135.763 163.772 135.485 164.209 135.382 164.166C135.28 164.123 135.429 163.504 136.087 163.117C136.746 162.729 137.399 162.908 137.346 163.011Z"
          fill="#3A4750"
        />
        <Path
          d="M137.65 166.202C137.548 166.202 137.591 165.606 138.074 165.123C138.557 164.64 139.137 164.59 139.15 164.693C139.163 164.795 138.743 164.997 138.352 165.401C137.961 165.805 137.759 166.236 137.65 166.202Z"
          fill="#3A4750"
        />
        <Path
          d="M136.147 158.615C136.068 158.694 135.634 158.37 135.018 158.194C134.402 158.019 133.869 158.035 133.846 157.926C133.823 157.817 134.412 157.595 135.131 157.817C135.849 158.038 136.23 158.548 136.147 158.615Z"
          fill="#3A4750"
        />
        <Path
          d="M136.564 155.846C136.564 155.892 136.108 155.806 135.478 155.446C135.112 155.233 134.773 154.978 134.469 154.684C134.287 154.511 134.117 154.325 133.962 154.128C133.864 154.015 133.785 153.888 133.727 153.75C133.685 153.65 133.678 153.538 133.706 153.433C133.734 153.327 133.796 153.234 133.883 153.168C133.963 153.113 134.055 153.079 134.15 153.066C134.246 153.053 134.344 153.063 134.436 153.095C134.574 153.143 134.705 153.213 134.823 153.3C135.035 153.439 135.234 153.599 135.416 153.777C135.722 154.076 135.98 154.421 136.18 154.8C136.511 155.462 136.545 155.909 136.511 155.916C136.478 155.922 136.326 155.512 135.955 154.939C135.736 154.609 135.475 154.307 135.18 154.042C135.008 153.887 134.823 153.746 134.628 153.621C134.419 153.479 134.224 153.406 134.128 153.486C134.091 153.519 134.085 153.532 134.108 153.618C134.153 153.717 134.211 153.809 134.28 153.893C134.422 154.08 134.575 154.259 134.737 154.429C135.014 154.719 135.317 154.982 135.644 155.214C136.187 155.608 136.588 155.79 136.564 155.846Z"
          fill="#3A4750"
        />
        <Path
          d="M136.372 155.939C136.329 155.939 136.256 155.485 136.465 154.783C136.592 154.375 136.788 153.991 137.044 153.648C137.198 153.444 137.372 153.256 137.564 153.088C137.674 152.966 137.814 152.874 137.97 152.822C138.126 152.769 138.293 152.759 138.455 152.79C138.549 152.829 138.63 152.893 138.688 152.975C138.747 153.058 138.781 153.156 138.786 153.257C138.796 153.405 138.785 153.553 138.753 153.697C138.704 153.954 138.614 154.201 138.488 154.429C138.281 154.815 137.982 155.144 137.617 155.386C136.988 155.793 136.498 155.76 136.505 155.717C136.511 155.674 136.932 155.581 137.452 155.158C137.753 154.914 137.997 154.608 138.167 154.26C138.267 154.06 138.337 153.845 138.375 153.625C138.425 153.383 138.405 153.164 138.313 153.154C138.217 153.152 138.122 153.17 138.034 153.208C137.946 153.245 137.867 153.302 137.803 153.373C137.627 153.522 137.465 153.687 137.319 153.866C137.071 154.173 136.867 154.513 136.713 154.876C136.465 155.515 136.435 155.955 136.372 155.939Z"
          fill="#3A4750"
        />
        <Path
          d="M107.601 99.4935C104.29 99.5697 101.178 100.036 98.9071 102.427C95.8975 105.595 92.1762 110.111 91.9544 113.243C91.6398 117.925 94.4606 119.315 96.5233 120.458C97.4352 120.955 98.4654 121.194 99.503 121.15L137.594 120.861L138.789 114.409L114.153 108.337L115.176 99.0664L107.601 99.4935Z"
          fill="#3A4750"
        />
        <Path
          d="M115.19 99.0656L127.172 98.5127C127.172 98.5127 147.871 99.6549 152.297 103.711C156.724 107.766 156.161 112.359 154.688 116.772C153.214 121.185 140.246 149.426 140.246 149.426C140.246 149.426 141.299 151.221 140.706 153.015C140.469 153.799 140.034 154.507 139.441 155.072C138.849 155.637 138.12 156.038 137.326 156.237L125.811 152.002C125.811 152.002 124.245 151.449 125.351 149.979C126.456 148.509 127.188 148.231 127.188 148.231V145.288C127.188 145.288 131.237 137.928 131.605 136.918C131.972 135.908 135.852 120.927 135.852 120.927L138.428 116.5C138.428 116.5 132.197 113.57 126.794 111.445L114.18 108.336L115.19 99.0656Z"
          fill="#3A4750"
        />
        <Path
          d="M134.624 151.47C134.601 151.496 134.24 151.211 133.614 150.807C131.989 149.749 130.155 149.051 128.238 148.761C127.503 148.649 127.039 148.622 127.039 148.586C127.444 148.537 127.854 148.544 128.257 148.606C129.235 148.713 130.195 148.943 131.115 149.291C132.034 149.64 132.904 150.107 133.704 150.678C134.046 150.898 134.356 151.164 134.624 151.47Z"
          fill="#5F7881"
        />
        <Path
          d="M140.378 149.446C140.129 149.24 139.907 149.003 139.716 148.741C139.329 148.288 138.809 147.639 138.269 146.904C137.73 146.169 137.276 145.48 136.945 144.974C136.752 144.712 136.593 144.427 136.472 144.126C136.697 144.359 136.899 144.614 137.074 144.887L138.448 146.771L139.839 148.645C140.047 148.892 140.228 149.161 140.378 149.446Z"
          fill="#5F7881"
        />
        <Path
          d="M144.603 112.458C144.603 112.491 144.179 112.584 143.514 112.806C142.645 113.096 141.813 113.486 141.034 113.968C140.258 114.455 139.541 115.032 138.899 115.686C138.405 116.189 138.134 116.537 138.104 116.514C138.284 116.172 138.513 115.857 138.783 115.58C140.032 114.177 141.658 113.163 143.467 112.657C143.834 112.534 144.217 112.467 144.603 112.458Z"
          fill="#5F7881"
        />
        <Path
          d="M132.82 132.452C132.827 132.368 132.844 132.284 132.869 132.204C132.916 132.022 132.972 131.79 133.045 131.502C133.217 130.866 133.449 129.995 133.734 128.933C134.346 126.741 135.177 123.764 136.094 120.473C136.571 118.818 136.982 117.252 137.442 115.838C137.93 114.598 138.668 113.471 139.61 112.528C140.234 111.89 140.925 111.32 141.67 110.829C141.938 110.66 142.143 110.534 142.292 110.455C142.362 110.41 142.438 110.373 142.517 110.346C142.517 110.362 142.229 110.541 141.719 110.902C141.003 111.415 140.337 111.996 139.733 112.637C138.823 113.575 138.113 114.688 137.644 115.908C137.2 117.312 136.789 118.904 136.319 120.543C135.376 123.827 134.525 126.797 133.899 128.982C133.585 130.038 133.33 130.903 133.141 131.532C133.052 131.813 132.982 132.041 132.926 132.224C132.901 132.304 132.866 132.381 132.82 132.452Z"
          fill="#5F7881"
        />
        <Path
          d="M122.222 110.882C122.162 110.882 121.911 108.647 121.659 105.879C121.408 103.112 121.255 100.86 121.328 100.854C121.401 100.847 121.639 103.088 121.891 105.859C122.143 108.631 122.285 110.875 122.222 110.882Z"
          fill="#5F7881"
        />
        <Path
          d="M102.281 116.872C102.067 116.59 101.868 116.297 101.685 115.994C101.196 115.305 100.61 114.69 99.9469 114.167C99.2793 113.648 98.5399 113.228 97.7518 112.922C97.1426 112.687 96.7585 112.591 96.7585 112.558C97.1169 112.57 97.4705 112.643 97.8048 112.773C99.4667 113.32 100.887 114.429 101.821 115.908C102.024 116.203 102.179 116.528 102.281 116.872Z"
          fill="#5F7881"
        />
        <Path
          d="M109.621 108.73C109.598 108.763 109.091 108.439 108.211 108.038C105.908 106.99 103.352 106.624 100.847 106.982C99.8906 107.118 99.3112 107.287 99.3013 107.25C99.4249 107.179 99.559 107.128 99.6986 107.098C100.067 106.983 100.443 106.892 100.824 106.827C102.083 106.606 103.369 106.585 104.635 106.764C105.901 106.945 107.131 107.328 108.277 107.896C108.62 108.066 108.952 108.259 109.27 108.472C109.398 108.542 109.516 108.629 109.621 108.73Z"
          fill="#5F7881"
        />
        <Path
          d="M138.312 114.402C138.21 114.397 138.109 114.38 138.011 114.352L137.167 114.17L134.061 113.472C131.439 112.886 127.814 112.098 123.798 111.3C119.782 110.502 116.12 109.853 113.471 109.396L110.336 108.856L109.485 108.704C109.385 108.691 109.286 108.669 109.19 108.638C109.291 108.634 109.393 108.641 109.492 108.661L110.349 108.774C111.094 108.873 112.17 109.032 113.498 109.24C116.146 109.658 119.815 110.287 123.841 111.084C127.867 111.882 131.485 112.694 134.104 113.319C135.429 113.65 136.465 113.895 137.193 114.087L138.031 114.309C138.127 114.332 138.221 114.363 138.312 114.402Z"
          fill="#5F7881"
        />
        <Path
          d="M114.167 109.432C114.013 109.017 113.837 108.61 113.64 108.214C113.401 107.802 113.108 107.423 112.77 107.088C112.353 106.676 111.91 106.29 111.445 105.932C110.582 105.296 109.67 104.73 108.717 104.237C108.312 104.048 107.922 103.826 107.552 103.575C107.978 103.712 108.392 103.887 108.787 104.098C109.772 104.554 110.708 105.109 111.581 105.754C112.057 106.117 112.506 106.514 112.925 106.942C113.27 107.297 113.561 107.699 113.789 108.137C113.936 108.433 114.047 108.746 114.12 109.068C114.158 109.185 114.174 109.309 114.167 109.432Z"
          fill="#5F7881"
        />
        <Path
          d="M156.601 115.19C156.651 115.236 156.012 115.882 155.519 116.862C155.025 117.842 154.856 118.729 154.79 118.716C154.724 118.702 154.79 117.775 155.32 116.759C155.85 115.743 156.558 115.147 156.601 115.19Z"
          fill="#3A4750"
        />
        <Path
          d="M129.33 71.666L132.055 90.8687L132.191 91.1733C132.83 84.5352 131.849 77.8411 129.33 71.666Z"
          fill="#EF7136"
        />
        <Path
          d="M144.02 82.7005L135.147 98.2084L113.296 98.6818L113.243 99.6751L134.392 99.5161L135.055 99.4963L157.389 99.5393L161.902 91.7556L167.669 83.4785C167.754 83.3568 167.805 83.2142 167.815 83.066C167.824 82.9178 167.794 82.7697 167.725 82.6378C167.657 82.5058 167.554 82.395 167.428 82.3174C167.301 82.2398 167.156 82.1982 167.007 82.1973L144.712 82.28C144.57 82.2844 144.432 82.3254 144.311 82.399C144.189 82.4727 144.089 82.5766 144.02 82.7005Z"
          fill="#E0E0E0"
        />
        <Path
          d="M161.919 91.7529C161.919 91.7529 163.067 92.8025 162.885 93.6964C162.703 94.5903 162.766 95.2558 161.919 95.3154C161.93 95.6404 161.817 95.9575 161.604 96.2032C161.391 96.4488 161.093 96.6048 160.77 96.6397C160.77 96.6397 161.031 98.0402 159.399 98.2951C159.399 98.2951 158.899 99.7916 157.873 99.4307C157.284 99.2275 156.717 98.9646 156.181 98.646L155.88 98.1031C155.88 98.1031 154.367 97.5336 154.737 96.6794C154.963 96.1596 155.747 96.0173 155.747 96.0173C155.659 95.8044 155.621 95.5737 155.638 95.3437C155.654 95.1136 155.725 94.8908 155.843 94.6929C156.207 94.2062 157.386 94.6102 157.386 94.6102C157.359 94.1904 157.493 93.7761 157.76 93.4514C158.164 92.9746 160.045 94.2725 160.045 94.2725L161.071 93.0475L161.919 91.7529Z"
          fill="#FFBE9D"
        />
        <Path
          d="M159.399 98.288C159.366 98.341 158.522 97.8742 157.515 97.2451C156.509 96.616 155.714 96.0698 155.747 96.0168C155.78 95.9638 156.621 96.4306 157.631 97.0597C158.641 97.6888 159.432 98.235 159.399 98.288Z"
          fill="#EB996E"
        />
        <Path
          d="M160.77 96.6461C160.74 96.6957 159.955 96.2819 159.021 95.719C158.088 95.1562 157.366 94.6563 157.386 94.6066C157.406 94.5569 158.2 94.9675 159.137 95.5303C160.074 96.0932 160.803 96.5931 160.77 96.6461Z"
          fill="#EB996E"
        />
        <Path
          d="M158.919 93.5704C158.952 93.5075 159.654 93.8783 160.468 94.3948C160.964 94.6839 161.442 95.0035 161.899 95.3517C161.866 95.4245 161.137 95.0934 160.323 94.5703C159.821 94.2871 159.351 93.9519 158.919 93.5704Z"
          fill="#EB996E"
        />
        <Path
          d="M167.689 82.7937C167.653 82.7973 167.616 82.7973 167.58 82.7937H167.269L166.06 82.8136L161.627 82.85L146.993 82.8831H145.927C145.77 82.8689 145.612 82.897 145.469 82.9646C145.327 83.0321 145.205 83.1366 145.116 83.2672L144.123 85.0583C143.461 86.237 142.838 87.3759 142.239 88.4585L139.054 94.226L136.879 98.0897L136.28 99.1359L136.117 99.4074C136.081 99.467 136.058 99.4968 136.054 99.4935C136.066 99.459 136.08 99.4258 136.097 99.3941C136.137 99.3213 136.184 99.2286 136.24 99.116C136.376 98.8545 136.571 98.5002 136.806 98.0499C137.313 97.1097 138.031 95.782 138.918 94.1498L142.073 88.3758C142.672 87.2898 143.296 86.1553 143.947 84.9722L144.941 83.1679C145.049 83.007 145.198 82.8777 145.372 82.7928C145.547 82.7079 145.74 82.6706 145.934 82.6845H147L161.634 82.7143L166.067 82.7507L167.275 82.7706H167.587L167.689 82.7937Z"
          fill="#3A4750"
        />
        <Path
          d="M151.966 90.5867C151.863 90.7361 151.805 90.912 151.8 91.0934C151.794 91.2748 151.841 91.454 151.934 91.6095C152.028 91.765 152.164 91.8903 152.327 91.9702C152.49 92.0501 152.673 92.0813 152.853 92.0601C153.033 92.0389 153.203 91.9662 153.343 91.8506C153.483 91.735 153.587 91.5816 153.642 91.4086C153.697 91.2356 153.701 91.0505 153.653 90.8753C153.606 90.7002 153.508 90.5425 153.373 90.4212C153.271 90.3386 153.152 90.2772 153.026 90.2408C152.899 90.2044 152.766 90.1935 152.635 90.209C152.504 90.2244 152.378 90.2657 152.263 90.3306C152.148 90.3954 152.047 90.4825 151.966 90.5867Z"
          fill="white"
        />
        <Path
          d="M89.9646 84.5914C89.7759 84.6675 89.5574 84.7933 89.5574 84.9986C89.5659 85.0808 89.6014 85.1578 89.6583 85.2177C89.7152 85.2776 89.7903 85.317 89.8719 85.3297C90.1811 85.2807 90.4841 85.1985 90.7757 85.0847C93.0734 84.5781 94.9672 85.0648 97.1557 85.9256L97.2252 85.8528C96.3445 84.012 92.4345 83.5981 89.9646 84.5914Z"
          fill="#EF7136"
        />
        <Path
          d="M208.303 170.805C208.303 170.867 169.898 170.917 122.503 170.917C75.1091 170.917 36.697 170.867 36.697 170.805C36.697 170.742 75.1024 170.695 122.503 170.695C169.904 170.695 208.303 170.745 208.303 170.805Z"
          fill="#3A4750"
        />
        <Path
          d="M129.903 43.2487L135.902 43.9109C137.919 41.0424 140.936 39.032 144.36 38.2748C147.784 37.5176 151.368 38.0684 154.406 39.8189C157.445 41.5695 159.719 44.3932 160.781 47.7351C161.843 51.0769 161.618 54.6954 160.148 57.8792C158.678 61.063 156.071 63.5819 152.838 64.941C149.605 66.3001 145.981 66.401 142.678 65.2238C139.375 64.0467 136.631 61.6767 134.987 58.5796C133.342 55.4825 132.915 51.8822 133.79 48.4864L129.903 43.2487Z"
          fill="#FAFAFA"
        />
        <Path
          d="M129.903 43.2492L133.833 48.4604H133.757C133.76 48.454 133.764 48.4482 133.77 48.4436C133.775 48.439 133.782 48.4356 133.789 48.4337C133.796 48.4319 133.803 48.4315 133.81 48.4327C133.817 48.4338 133.824 48.4365 133.83 48.4406C133.841 48.4463 133.85 48.4557 133.854 48.4672C133.859 48.4787 133.86 48.4916 133.856 48.5035C133.251 50.8869 133.284 53.3876 133.952 55.7542C134.149 56.4633 134.408 57.1535 134.727 57.8168C134.899 58.1479 135.058 58.5055 135.243 58.8465C135.429 59.1875 135.661 59.5086 135.876 59.8397C137.893 62.7717 140.951 64.8256 144.428 65.584C146.352 66.0102 148.346 66.0193 150.275 65.6105C152.306 65.1693 154.216 64.2853 155.866 63.0214C157.594 61.7112 158.985 60.009 159.926 58.0552C160.887 56.0594 161.359 53.8633 161.303 51.6488C161.241 49.5282 160.703 47.4487 159.73 45.5635C158.817 43.7783 157.523 42.2152 155.939 40.9846C153.083 38.7766 149.489 37.7491 145.898 38.1142C144.379 38.2945 142.897 38.7042 141.501 39.3292C139.279 40.3537 137.365 41.9441 135.952 43.9412C135.946 43.9489 135.939 43.9549 135.93 43.9584C135.921 43.9619 135.912 43.9628 135.902 43.9611L129.91 43.2492L135.912 43.8551L135.866 43.8783C137.276 41.8507 139.199 40.2331 141.438 39.1902C142.845 38.5497 144.343 38.1288 145.878 37.942C149.517 37.5607 153.164 38.5918 156.065 40.8224C157.675 42.0672 158.992 43.6493 159.926 45.4575C160.917 47.3748 161.462 49.4912 161.521 51.6488C161.577 53.8999 161.093 56.1316 160.111 58.1578C159.158 60.1475 157.744 61.8807 155.986 63.2134C154.31 64.493 152.372 65.3862 150.311 65.829C148.353 66.2412 146.33 66.2288 144.378 65.7925C140.855 65.0165 137.76 62.9268 135.723 59.949L135.081 58.9325C134.896 58.5816 134.734 58.2273 134.565 57.8863C134.245 57.2129 133.984 56.5128 133.787 55.7939C133.123 53.4012 133.106 50.8752 133.737 48.4737L133.84 48.5134C133.832 48.5254 133.819 48.5341 133.805 48.5377C133.791 48.5414 133.776 48.5398 133.763 48.5333L133.747 48.5167L129.903 43.2492Z"
          fill="#3A4750"
        />
        <Path
          d="M142.209 46.9907C142.213 47.0557 142.213 47.121 142.209 47.186C142.209 47.3251 142.209 47.5171 142.209 47.729C142.209 48.2223 142.226 48.9076 142.236 49.7718C142.236 51.5331 142.262 54.0162 142.282 56.986L142.189 56.89H152.181C152.208 56.8668 151.973 57.0986 152.072 56.9993V54.2976C152.072 53.417 152.072 52.5528 152.072 51.7119C152.072 50.0366 152.072 48.4541 152.072 46.9973L152.168 47.0933L144.95 47.047L142.911 47.0205H142.365C142.301 47.0257 142.237 47.0257 142.173 47.0205C142.23 47.0154 142.288 47.0154 142.345 47.0205H142.871L144.891 46.994L152.175 46.9443H152.297V47.0404C152.297 48.4971 152.297 50.0797 152.297 51.7549C152.297 52.5959 152.297 53.46 152.297 54.3407V57.0324L152.185 57.1449L142.193 57.1284H142.093V57.0324C142.113 54.0361 142.13 51.5331 142.14 49.7486C142.14 48.8911 142.159 48.2124 142.166 47.7257C142.166 47.5105 142.166 47.335 142.166 47.2026C142.168 47.1299 142.182 47.0582 142.209 46.9907Z"
          fill="#3A4750"
        />
        <Path
          d="M151.125 48.6428C151.268 48.7587 149.923 50.6955 148.119 52.9701C147.762 53.4236 147.411 53.8574 147.083 54.2514L146.871 54.5096L146.633 54.2944C145.07 52.8873 144.01 51.808 144.106 51.6954C144.202 51.5828 145.431 52.4768 147.043 53.8176L146.589 53.8574C146.901 53.4501 147.252 53.0098 147.599 52.5562C149.404 50.2817 150.973 48.5303 151.125 48.6428Z"
          fill="#EF7136"
        />
      </Svg>
    </View>
  );
};
