import { LoadMoreButtonStyles as Styled } from 'app/components/Messages/styles';

import { LoadMoreButtonProps } from 'app/components/Messages/types';

/**
 * Render a "Load Previous Messages" button on web browsers.
 *
 * @param  loading  whether to disable the button with a spinner
 * @param  onPress  function to call when pressing the button
 */
export const LoadMoreButton = ({
  loading = false,
  onPress,
}: LoadMoreButtonProps) => {
  return (
    <Styled.Wrapper>
      <Styled.Button
        title="Load Previous Messages"
        disabled={loading}
        loading={loading}
        onPress={onPress}
      />
    </Styled.Wrapper>
  );
};
