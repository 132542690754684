export enum Routes {
  CallUs = 'call-us',
  CarrumID = 'carrum-id',
  Complete = 'complete',
  CompleteYourProfile = 'complete-your-profile',
  Dashboard = 'dashboard',
  EvaluateYourCase = 'evaluate-your-case',
  Forms = 'forms',
  GetSurgery = 'get-surgery',
  Home = '/',
  InvalidActivation = 'invalid-activation',
  LearnMore = 'learn-more',
  Login = 'login',
  Messages = 'messages',
  MySettings = 'my-settings',
  NotAvailable = 'not-available',
  Overview = 'overview',
  PasswordReset = 'password-reset',
  ProcedureConfirmation = 'procedures/:id/confirmation',
  Procedures = 'procedures',
  Profile = 'profile',
  Recover = 'recover',
  Register = 'register',
  SaveProgress = 'save-progress',
  Search = 'search',
  SelectSurgeon = 'select-surgeon',
  SelectYourDoctor = 'select-your-doctor',
  Tasks = 'tasks',
  Travel = 'travel',
}

type ProtectedRoutes = {
  [key in Routes]: boolean;
};

export const protectedRoutes: ProtectedRoutes = {
  [Routes.CarrumID]: true,
  [Routes.CompleteYourProfile]: true,
  [Routes.Dashboard]: true,
  [Routes.EvaluateYourCase]: true,
  [Routes.Forms]: true,
  [Routes.GetSurgery]: true,
  [Routes.CallUs]: false,
  [Routes.Home]: false,
  [Routes.InvalidActivation]: false,
  [Routes.LearnMore]: false,
  [Routes.Login]: false,
  [Routes.Messages]: true,
  [Routes.MySettings]: true,
  [Routes.NotAvailable]: false,
  [Routes.Overview]: true,
  [Routes.PasswordReset]: false,
  [Routes.ProcedureConfirmation]: true,
  [Routes.Procedures]: true,
  [Routes.Recover]: true,
  [Routes.Register]: false,
  [Routes.Search]: true,
  [Routes.SelectSurgeon]: true,
  [Routes.SelectYourDoctor]: true,
  [Routes.Tasks]: true,
  [Routes.Travel]: true,
  [Routes.SaveProgress]: true,
};
