import { useEffect } from 'react';
import { useRef } from 'react';

export { useDispatch } from 'react-redux';
export { useEffect };

export const usePreviousValue = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

export { useMessage } from 'app/hooks';
export { useRef };
export { useSession } from 'app/hooks';
export { useState } from 'react';
