import { uniqBy } from 'lodash';
import { isEmpty } from 'lodash/fp';

import { Episode } from 'types/episode';
import { State } from 'types/state';

export function getOfferedProcedures(
  state: State.RootState
): Episode.OfferedProcedure[] {
  return state?.episode?.possibleProcedures || [];
}

export function getOfferedProcedure(state: State.RootState, key: string) {
  return state?.episode?.possibleProcedures?.find(
    (procedure) => procedure.key === key
  );
}

export function getEpisodeProcedure(state: State.RootState) {
  return getOfferedProcedure(state.episode?.episode?.procedureKey);
}

export const getIsEpisodeCreated = (episodeState: Episode.EpisodeState) => {
  return !!episodeState?.episode?.createdAt;
};

export const getIsCoeSelected = (episodeState: Episode.EpisodeState) => {
  return (
    !!episodeState?.episode?.provider &&
    !isEmpty(episodeState.episode.provider) &&
    !!episodeState?.episode?.physician &&
    !isEmpty(episodeState.episode.physician)
  );
};

export const getIsEpisodeTraveling = (episodeState: Episode.EpisodeState) => {
  return !!episodeState?.episode?.travel?.itinerary;
};

export const getAvailableDoctors = (episodeState: Episode.EpisodeState) => {
  return episodeState?.possiblePhysicians || [];
};

export const getAvailableDoctorsAtFacility = (
  episodeState: Episode.EpisodeState,
  facilityId: number
) => {
  return getAvailableDoctors(episodeState).filter(
    (doctor) => doctor.hospital.id == facilityId
  );
};

export const getAvailableFacilities = (episodeState: Episode.EpisodeState) => {
  return uniqBy(episodeState.possibleHospitals, 'id');
};

export const getDoctorById = (
  episodeState: Episode.EpisodeState,
  doctorId: number
) => {
  return getAvailableDoctors(episodeState).find(({ id }) => id == doctorId);
};

export const getFacilityById = (
  episodeState: Episode.EpisodeState,
  facilityId: number
) => {
  return getAvailableFacilities(episodeState).find(
    ({ id }) => id.toString() == facilityId.toString()
  );
};

export const getEpisodeJourneyPhase = (episodeState: Episode.EpisodeState) => {
  const { episode, journeyPhases } = episodeState;

  if (!episode?.currentStep?.journeyPhaseId) return;
  if (!journeyPhases) return;

  const { currentStep } = { ...episode };

  const currentJourneyPhase = journeyPhases?.find(
    ({ id }) => parseInt(id) === currentStep.journeyPhaseId
  );

  return currentJourneyPhase;
};

/**
 * Returns true if episode was created for Platform 1
 */
export function getIsPlatformOne(episodeState: Episode.EpisodeState): boolean {
  return episodeState?.episode?.productOffering?.platform?.code !== '2.0';
}

/**
 * Returns true if episode was created for Platform 2
 */
export function getIsPlatformTwo(episodeState: Episode.EpisodeState): boolean {
  return episodeState?.episode?.productOffering?.platform?.code === '2.0';
}
