import { Icon, IconProps } from 'react-native-elements';
import styled, { css } from 'styled-components/native';

import { Text as TextRaw, View } from 'app/components/Common/Styled';
import AnchorRaw from 'app/components/Common/Anchor/index';
import { DefaultTheme } from 'types/theme';

export const Anchor = styled(AnchorRaw)``;

export const Container = styled(View)`
  ${({ theme }) => css`
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    margin: ${theme.spacing * 2}px;
  `}
`;

interface StatusIconProps extends IconProps {
  status: string;
  theme: DefaultTheme;
}

export const StatusIcon = styled(Icon).attrs(
  ({ status, theme }: StatusIconProps) => ({
    color: theme.colors[status],
  })
)<StatusIconProps>``;

export const Text = styled(TextRaw)`
  ${({ theme }) => css`
    margin: ${theme.spacing / 2}px 0;
    text-align: center;
    width: 100%;
  `}
`;
