/**
 * Return every detail required to update the user account.
 *
 * > NOTE: It is mandatory for users to accept terms if they have not already
 *         done so. This function assumes that the corresponding form
 *         includes legal text near its submit button.
 */
export const getUserDetails = (email, password, hasAcceptedTerms) => {
  let userDetails = { email, password };

  if (!hasAcceptedTerms) {
    userDetails = {
      ...userDetails,
      ...{
        acceptedMemberAgreement: true,
        acceptedTerms: true,
      },
    };
  }
  return userDetails;
};

export { updateUserDetails } from 'app/actions/userActions';
