interface OopEstimateParams {
  record: {
    carrumCoinsuranceAmount: number;
    carrumCopay: number;
    carrumDeductible: number;
    nonCarrumCoinsuranceAmount: number;
    nonCarrumCopay: number;
    nonCarrumDeductible: number;
  };
}

interface oopEstimate {
  carrumEstimate: number;
  nonCarrumEstimate: number;
}

/**
 * Accepts the data returned from the `useOopEstimate` hook and returns
 * a sum total "out-of-pocket" estimate.
 *
 * @param oopEstimate
 * @returns The estimated "out-of-pocket" expense if Carrum is used
 */
export const getOopEstimate = (oopEstimate: OopEstimateParams): oopEstimate => {
  const carrumCoinsuranceAmount = oopEstimate.record?.carrumCoinsuranceAmount;
  const carrumCopay = oopEstimate.record?.carrumCopay;
  const carrumDeductible = oopEstimate.record?.carrumDeductible;

  const nonCarrumCoinsuranceAmount =
    oopEstimate.record?.nonCarrumCoinsuranceAmount;
  const nonCarrumCopay = oopEstimate.record?.nonCarrumCopay;
  const nonCarrumDeductible = oopEstimate.record?.nonCarrumDeductible;

  return {
    carrumEstimate: carrumCoinsuranceAmount + carrumCopay + carrumDeductible,
    nonCarrumEstimate:
      nonCarrumCoinsuranceAmount + nonCarrumCopay + nonCarrumDeductible,
  };
};
