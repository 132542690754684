import { LINKS } from 'app/components/MySettings/constants';

import { useHistory } from 'app/components/MySettings/hooks';

import {
  AnchorContent,
  AnchorIcon,
  AnchorSubText,
  AnchorText,
  MySettingsMenuContainer,
  MySettingsMenuRow,
} from 'app/components/MySettings/styles';

/** A link menu for navigating the Profile page. */
const MySettingsMenu = () => {
  const history = useHistory();

  return (
    <MySettingsMenuContainer testID="MySettingsMenu">
      {LINKS.map((link, index) => {
        const isFinalRow = index === LINKS.length - 1;
        const isFirstRow = index === 0;
        return (
          <MySettingsMenuRow isFinalRow={isFinalRow} key={link.path}>
            <AnchorContent
              isFirstRow={isFirstRow}
              isFinalRow={isFinalRow}
              onPress={() => history.push(link.path)}
            >
              <AnchorText>
                <AnchorSubText bold>{link.title}</AnchorSubText>
                <AnchorSubText size="small">{link.subtitle}</AnchorSubText>
              </AnchorText>
              <AnchorIcon name="chevron-right" />
            </AnchorContent>
          </MySettingsMenuRow>
        );
      })}
    </MySettingsMenuContainer>
  );
};

export default MySettingsMenu;
