import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Animated,
  Dimensions,
  Easing,
  Platform,
  StyleSheet,
} from 'react-native';
import { Icon, Overlay } from 'react-native-elements';

import { View } from 'app/components/Common/Styled';
import { useNativeDriver } from 'app/util/constants';
import theme from 'app/util/theme';

/**
 * Renders a sliding sheet that overlays the current content.
 */
export default class SlidingSheet extends Component {
  /**
   * @property {node} children The content to render within the sliding sheet.
   */
  static propTypes = {
    children: PropTypes.node.isRequired,

    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    isVisible: false,
    onClose: null,
  };

  constructor(props) {
    super(props);

    const offset = Dimensions.get('window').height;

    this.state = {
      offset,
      slide: new Animated.Value(props.isVisible ? 0 : offset),
      visible: props.isVisible,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isVisible === this.props.isVisible) return;

    if (this.props.isVisible) this.reveal();
    if (!this.props.isVisible) this.hide();
  }

  hide = () => {
    const config = {
      duration: this.state.offset * 0.25,
      easing: Easing.in(Easing.ease),
      toValue: this.state.offset,
      useNativeDriver,
    };

    Animated.timing(this.state.slide, { ...config }).start(() => {
      this.setState({ visible: false }, this.props.onClose);
    });
  };

  reveal = () => {
    const config = {
      duration: this.state.offset * 0.25,
      easing: Easing.out(Easing.ease),
      toValue: 0,
      useNativeDriver,
    };

    this.setState({ visible: true }, () => {
      Animated.timing(this.state.slide, { ...config }).start();
    });
  };

  render() {
    return (
      <Overlay
        isVisible={this.state.visible}
        backdropStyle={styles.backdrop}
        overlayStyle={styles.overlay}
      >
        <Animated.View
          style={StyleSheet.flatten([
            styles.container,
            { transform: [{ translateY: this.state.slide }] },
          ])}
        >
          {/* TODO: apply SC - https://carrumhealth.atlassian.net/browse/TEC-2358 */}
          <View style={{ width: '100%', height: '100%' }}>
            {this.props.children}
          </View>

          <Icon
            name="close"
            containerStyle={styles.closeIcon}
            onPress={this.props.onClose}
          />
        </Animated.View>
      </Overlay>
    );
  }
}

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: 'transparent',
    bottom: 0,
    left: 0,
    height: 'auto',
    padding: 0,
    right: 0,
    top: 0,
  },

  overlay: {
    backgroundColor: 'transparent',
    padding: 0,
    position: 'absolute',
    top: Platform.select({
      default: 0,
      web: theme.navigationHeight,
    }),
    bottom: 0,
    width: '100%',
  },

  container: {
    flex: 1,
    backgroundColor: '#fafafa',
    alignItems: 'center',
    paddingTop: Platform.select({
      default: theme.spacing * 1.25,
      ios: theme.spacing * 3.5,
    }),
  },

  icon: {
    marginTop: Platform.select({
      default: '20%',
      web: '10%',
    }),
  },

  content: {
    flex: 1,
    paddingHorizontal: theme.spacing * 1.25,
    paddingTop: Platform.select({
      default: '14%',
      web: '5%',
    }),
    maxWidth: Platform.select({
      web: 980,
    }),
  },

  actions: {
    width: '100%',
    backgroundColor: '#fff',
    borderTopWidth: 1,
    borderTopColor: '#ccc',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing * 1.25,
  },

  pagination: {
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 60,
  },

  badgeDisabled: {
    backgroundColor: '#ccc',
  },

  badgeSelected: {
    backgroundColor: theme.colors.primary,
    transform: [{ scale: 1.6 }],
  },

  closeIcon: {
    position: 'absolute',
    alignSelf: 'flex-end',
    right: 20,
    top: Platform.select({
      default: 20,
      ios: 60,
    }),
  },
});
