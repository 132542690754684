import { isEmpty } from 'lodash';

import { formatDateDisplayToRails, toDateTimeString } from 'app/util/methods';
import { Session } from 'types/session';

/**
 * Returns true if the "bypass SSN" setting is turned on for registration. Otherwise, returns false.
 */
export function getBypassSsnRegistration(
  sessionState: Session.SessionState
): boolean {
  return !!sessionState?.user?.employer?.contract?.bypassSsnRegistration;
}

/**
 * Returns true if user is on Platform 1
 */
export function getIsPlatformOne(sessionState: Session.SessionState): boolean {
  return !getIsPlatformTwo(sessionState);
}

/**
 * Returns true if user is on Platform 2
 */
export function getIsPlatformTwo(sessionState: Session.SessionState): boolean {
  // Return early if no contract object is defined.
  if (!sessionState?.user?.employer?.contract) return false;

  const { platformTwo, platformTwoGoLiveDate } =
    sessionState.user.employer.contract;

  /**
   * In some JavaScript environments, the date needs to be parsed from either
   * "MM/DD/YYYY" or "YYYY-MM-DD" format.
   *
   * Attempt to parse the date with the "MM/DD/YYYY" format (default), and if
   * that returns a falsy value (eg., NaN), convert to "YYYY-MM-DD" format and
   * try again.
   */
  const formattedGoLiveDate =
    Date.parse(toDateTimeString(platformTwoGoLiveDate)) ||
    Date.parse(
      toDateTimeString(formatDateDisplayToRails(platformTwoGoLiveDate))
    );

  // Check to see that the `platformTwo` boolean is true and that `platformTwoGoLiveDate`
  // is defined and not in the past.
  return (
    !!platformTwo &&
    !!platformTwoGoLiveDate &&
    formattedGoLiveDate <= Date.now()
  );
}

/**
 * Returns true if logged in
 */
export function getIsLoggedIn(sessionState: Session.SessionState): boolean {
  return Boolean(
    sessionState?.coreToken &&
      !sessionState?.loading &&
      !isEmpty(sessionState.user)
  );
}

export function getUserPhoneNumber(sessionState: Session.SessionState): string {
  return (
    sessionState?.user?.profile?.phoneNumber ||
    sessionState?.user?.eligiblePatient?.phoneNumber ||
    ''
  );
}
