import { Platform } from 'react-native';
import { Button, Icon as IconRaw } from 'react-native-elements';
import styled, { css } from 'styled-components/native';

import { Animated } from 'app/components/Common/Onboarding/constants';
import {
  IconProps,
  IconRequiredProps,
} from 'app/components/Common/Onboarding/types';
import ValuePropImageRaw from 'app/components/Common/Onboarding/ValuePropImage';
import ProgressIndicatorRaw from 'app/components/Common/ProgressIndicator';
import SlidingViewRaw from 'app/components/Common/SlidingView';
export {
  CareImage,
  CostImage,
  MindImageForEmployer,
  MindImageForPartner,
  QualityImage,
} from 'app/components/Common/svg-components';
import { View } from 'app/components/Common/Styled';

const AnimatedView = Animated.createAnimatedComponent(View);

export const AnimationContainer = styled(AnimatedView)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  flex: 1 0 auto;
  justify-content: flex-start;
`;

export const ContentContainer = styled.View`
  ${({ theme }) => css`
    align-items: center;
    flex: 1 1 auto;
    justify-content: ${theme.windowDimensions.width >
      theme.windowDimensions.height && Platform.OS === 'web'
      ? 'flex-start'
      : 'center'};
    max-width: ${theme.breakpoints.medium}px;
    padding: ${`0 ${theme.spacing * 1.25}px`};
  `}
`;

export const Icon = styled(IconRaw).attrs<IconProps>(({ page, theme }) => ({
  color: page.iconColor,
  name: page.icon,
  size: theme.windowDimensions.width > theme.breakpoints.medium ? 284 : 224,
  type: page.iconType,
}))<IconRequiredProps>``;

export const ImageContainer = styled.View`
  align-items: center;
  flex: 0 0 auto;
  justify-content: center;
`;

export { default as Modal } from 'app/components/Common/Modal';

export const ProgressIndicator = ProgressIndicatorRaw;

export const SlidingView = styled(SlidingViewRaw).attrs(({ theme }) => ({
  containerStyle: {
    flexBasis: 'auto',
    flexGrow: 0,
    flexShrink: 1,
  },
}))``;

export { default as StickyNavFooter } from 'app/components/Common/StickyNavFooter';

export const Subtitle = styled.Text`
  ${({ theme }) => css`
    color: ${theme.colors.bodyText};
    font-family: ${theme.fontFamily};
    font-size: ${theme.fontSizes.body2}px;
    text-align: center;
  `};
`;

export const TextContainer = styled.View`
  ${({ theme }) => css`
    align-items: center;
    flex: 0 0 auto;
    justify-content: flex-start;
    padding: ${`${theme.spacing / 2}px ${theme.spacing / 4}px`};
  `}
`;

export const Title = styled.Text`
  ${({ theme }) => css`
    color: ${theme.colors.bodyText};
    font-family: ${theme.fontFamilyMedium};
    font-size: ${theme.fontSizes.h1}px;
    margin-bottom: ${theme.spacing / 2}px;
    text-align: center;
  `};
`;

export const ValuePropImage = styled(ValuePropImageRaw).attrs(({ theme }) => ({
  dimensions: {
    height: theme.windowDimensions.height,
    width: theme.windowDimensions.width,
  },
}))``;
