import {
  defaultFormState,
  EVENTS,
  TestID,
} from 'app/components/Login/constants';

import {
  callCarrum,
  getRememberMeSettings,
  goToEmailForm,
  isGuestAccount,
  logEvent,
  loginCoreUser,
  receiveSessionError,
  storeCredentials,
} from 'app/components/Login/helpers';

import {
  useAppState,
  useDispatch,
  useEffect,
  useHistory,
  useRouteMatch,
  useSession,
  useState,
} from 'app/components/Login/hooks';

import {
  Anchor,
  Biometrics,
  CenteredScrollScreen,
  Email,
  HelpText,
  KeyboardAvoidingView,
  Linking,
  LogoContainer,
  MagicLinkConfirmation,
  MagicLinkRequest,
  Password,
  RedVerticalLogo,
  Route,
  Routes,
  Switch,
  Text,
  TitleText,
  TopBackLink,
  TopBackLinkWrapper,
} from 'app/components/Login/styles';

import { LoginStep } from 'app/components/Login/types';

const Login = () => {
  let email;
  let resent;

  const { appState } = useAppState();
  const dispatch = useDispatch();
  const { location } = useHistory();
  const match = useRouteMatch();
  const session = useSession();
  const { queryParams } = Linking.parse(
    `carrumhealth:${location?.search || '?'}`
  );

  if (queryParams) ({ email, resent } = queryParams);
  // ^ NOTE: app/components/App/spec.tsx test fails without this conditional check

  const emailQueryParamExists = !!email;
  const magicLinkWasResent = resent === 'true';

  const error = session.error;
  const initialLoginStep = magicLinkWasResent ? 'magic-link-sent' : 'email';
  const loading = session.loading;
  const softLogout = Boolean(
    !session.error && !session.loading && session.user?.id
  );

  const [loginStep, setLoginStep] = useState<LoginStep>(initialLoginStep);
  const [formValues, setFormValues] = useState(defaultFormState);

  /** Load default values from query params and local storage on mount. */
  useEffect(() => {
    setDefaultValues();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /** Clear any session errors and log the path on component mount. */
  useEffect(() => {
    dispatch(receiveSessionError(null));

    if (location.pathname === '/login/activated') {
      logEvent(EVENTS.account.activate);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Proceed to `Password` screen if user was auto-logged out and email is
   * still in Redux.
   */
  useEffect(() => {
    if (session?.user?.email) {
      setFormValues({ ...formValues, email: session.user.email });
      setLoginStep('password');
    }
  }, [session.user.email]); // eslint-disable-line react-hooks/exhaustive-deps

  const setDefaultValues = async () => {
    const { isValid, userEmail, shouldRememberDevice } =
      await getRememberMeSettings();

    if (isValid) {
      setFormValues({
        ...formValues,
        email: userEmail || session?.user?.email,
        shouldRememberDevice,
      });
    }

    // override email in state when magic link was resent
    if (emailQueryParamExists && magicLinkWasResent) {
      setFormValues({ ...formValues, email: email.toString() });
    }
  };

  /**
   * Move to the correct next page when the form is submitted.
   *
   * If this user started their registration process using the single-click
   * registration workflow but has not yet set a password, do not display a
   * password form. Instead, allow them to request a "Magic Link" to log in.
   */
  const setUserEmail = async ({ email, shouldRememberDevice }) => {
    const isGuest = await isGuestAccount(email);
    const nextStep = isGuest ? 'magic-link' : 'password';

    setFormValues({ ...formValues, email, shouldRememberDevice });
    setLoginStep(nextStep);
  };

  /** Log the user in and securely store the credentials if successful. */
  const logUserIn = async (formValues) => {
    if (loading) return;

    await dispatch(loginCoreUser(formValues));

    if (!error) storeCredentials(formValues);
  };

  const hideBackButton = [
    'email',
    'password',
    'magic-link-sent',
    'magic-password-reset-sent',
  ].includes(loginStep);

  return (
    <CenteredScrollScreen testID={TestID.Login.Page}>
      <KeyboardAvoidingView behavior="position">
        {!hideBackButton && (
          <TopBackLinkWrapper>
            <TopBackLink
              onPress={() =>
                goToEmailForm({
                  defaultFormState,
                  dispatch,
                  formValues,
                  receiveSessionError,
                  setFormValues,
                  setLoginStep,
                })
              }
              testID={TestID.Login.TopBackLink}
            />
          </TopBackLinkWrapper>
        )}

        {loginStep === 'email' && (
          <LogoContainer>
            <RedVerticalLogo height={120} width={200} />
          </LogoContainer>
        )}

        <Switch>
          <Route
            exact
            path={`${match.url}/activated`}
            render={() => <Text h1>Account Activated</Text>}
          />
          <Route
            exact
            path={`${match.url}/previous`}
            render={() => <Text h1>Already Activated</Text>}
          />

          {(loginStep === 'email' || loginStep === 'password') && (
            <TitleText>Login</TitleText>
          )}

          {loginStep === 'magic-link' && (
            <TitleText>Login without a password</TitleText>
          )}

          {['magic-link-sent', 'magic-password-reset-sent'].includes(
            loginStep
          ) && <TitleText>Email sent!</TitleText>}
        </Switch>

        {loginStep === 'email' && (
          <Email
            error={error}
            formValues={formValues}
            loading={loading}
            onSubmit={setUserEmail}
            setLoginStep={setLoginStep}
            softLogout={softLogout}
          />
        )}

        {loginStep === 'password' && (
          <Password
            appState={appState}
            error={error}
            formValues={formValues}
            loading={loading}
            onSubmit={logUserIn}
            setFormValues={setFormValues}
            setLoginStep={setLoginStep}
            softLogout={softLogout}
          />
        )}

        {loginStep === 'magic-link' && (
          <MagicLinkRequest
            formValues={formValues}
            isResendRequest={magicLinkWasResent}
            loading={loading}
            setLoginStep={setLoginStep}
          />
        )}

        {loginStep === 'magic-link-sent' && (
          <MagicLinkConfirmation
            formValues={formValues}
            setFormValues={setFormValues}
            setLoginStep={setLoginStep}
          />
        )}

        {loginStep === 'magic-password-reset-sent' && (
          <MagicLinkConfirmation
            formValues={formValues}
            isPasswordReset={true}
            setFormValues={setFormValues}
            setLoginStep={setLoginStep}
          />
        )}
      </KeyboardAvoidingView>

      {loginStep !== 'password' && (
        <Biometrics
          appState={appState}
          error={error}
          onSubmit={(params) => dispatch(loginCoreUser(params))}
        />
      )}

      {['magic-link-sent', 'magic-password-reset-sent'].includes(loginStep) && (
        <>
          <HelpText>
            Need help? Call us at&nbsp;
            <Anchor onPress={(e) => callCarrum(e)} title="1-888-855-7806" />.
          </HelpText>
        </>
      )}

      {['email'].includes(loginStep) && (
        <>
          <HelpText>
            Don&apos;t have an account?&nbsp;
            <Anchor to={`/${Routes.Register}`} title="Register" />
          </HelpText>

          <HelpText>
            Have questions?&nbsp;
            <Anchor to={`/${Routes.LearnMore}`} title="See FAQ" />
          </HelpText>

          <HelpText>
            or call us at&nbsp;
            <Anchor onPress={(e) => callCarrum(e)} title="1-888-855-7806" />
          </HelpText>
        </>
      )}

      {['magic-link'].includes(loginStep) && (
        <>
          <HelpText>
            Need help? Call us at&nbsp;
            <Anchor onPress={(e) => callCarrum(e)} title="1-888-855-7806" />.
          </HelpText>
        </>
      )}
    </CenteredScrollScreen>
  );
};

export default Login;
