import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { TestID } from 'app/util/test-id';

import ButtonList from 'app/components/Common/ButtonList';
import FormError from 'app/components/Common/FormError';
import { StyleSheet, View } from 'react-native';
import { Text } from 'app/components/Common/Styled';
import theme from 'app/util/theme';

/**
 * A radio group component wrapper for interacting with redux-form.
 *
 * @example
 * import { Field } from 'redux-form';
 * import RadioGroupButtonList from 'app/components/Common/RadioGroupButtonList';
 *
 * const MyForm = () => (
 *   <View>
 *    <Field
 *      name="myRadioGroupButtonList"
 *      label="My Radio Group Button List"
 *      options={[{ label: 'Test 1', value: 1 }, { label: 'Test 2', value: 2 }]}
 *      component={RadioGroupButtonList}
 *    />
 *   </View>
 * );
 */
export default class RadioGroupButtonList extends Component {
  /**
   * @property  {object}    containerStyle  optional style to pass to the container
   * @property  {boolean}   disabled        whether the input is disabled
   * @property  {string}    error           error message to display below the radio group
   * @property  {object}    input           object with props passed down from redux-form
   * @property  {function}  input.onChange  function to call when the value changes
   * @property  {function}  input.onBlur    function to call after the changes
   * @property  {*}         input.value     currently selected value
   * @property  {string}    label           label to display above the radio group
   * @property  {string}    mode            select "single" or "multi" options
   * @property  {array}     options         array of options to render in the radio group
   * @property  {object}    optionsStyle    optional style to pass to the options container
   */
  static propTypes = {
    containerStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    disabled: PropTypes.bool,
    error: PropTypes.string,
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func,
      value: PropTypes.any,
    }).isRequired,
    label: PropTypes.string,
    mode: PropTypes.string,
    options: PropTypes.array.isRequired,
    optionsStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  };

  static defaultProps = {
    containerStyle: {},
    disabled: false,
    label: '',
    mode: 'single',
  };

  constructor(props) {
    super(props);

    this.state = {
      selected: props.input ? props.input.value : null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.input && this.props.input.value !== prevProps.input.value) {
      this.setState({ selected: this.props.input.value });
    }
  }

  onChange = (selected) => {
    this.setState({ selected });
    this.props.input.onChange(selected);
  };

  render() {
    return (
      <View
        style={[this.props.containerStyle]}
        testID={TestID.FormDetails.RadioGroupButtonList}
      >
        {!!this.props.label && <Text size="body2">{this.props.label}</Text>}

        <View
          style={[
            styles.options,
            this.props.disabled ? styles.optionsDisabled : null,
            StyleSheet.flatten(this.props.optionsStyle),
          ]}
        >
          <ButtonList
            disabled={this.props.disabled}
            value={this.props.input.value}
            key={TestID.FormDetails.RadioGroupButtonList}
            mode={this.props.mode}
            onChange={(selected) =>
              this.props.disabled || this.onChange(selected)
            }
            options={this.props.options}
          />
        </View>

        {this.props.error && (
          <View style={styles.formErrorWrapper}>
            <FormError>{this.props.error}</FormError>
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  formErrorWrapper: {
    marginTop: theme.spacing / 2,
  },
  options: {
    flex: 1,
  },

  optionsDisabled: {
    opacity: 0.3,
  },
});
