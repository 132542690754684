import React, { memo } from 'react';
import { Route, Switch } from '@cross-platform/react-router-native';

import Congratulations from './Congratulations';
import Progress from './Progress';

const Dashboard = memo((props) => (
  <Switch>
    <Route
      exact
      path="/dashboard/congratulations"
      component={Congratulations}
    />
    <Route render={() => <Progress {...props} />} />
  </Switch>
));

Dashboard.displayName = 'Dashboard';

export default Dashboard;
