import updateEpisode from './update-episode';

/**
 * Updates the current episode with new params.
 *
 * @param {object} params New params that should be used to update the current episopde.
 * @return {promise} A promise that resolves after updating the new episode.
 */
export default (params) => {
  return (dispatch, getState) => {
    const { episode } = getState();

    return dispatch(updateEpisode(episode.episode.id, params));
  };
};
