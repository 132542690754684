import { TestID } from 'app/components/App/Navigation/constants';
import { SidePanelAnimatedPanelStyles as Styled } from 'app/components/App/Navigation/styles';

import {
  CurrentEnvironment,
  DevToolsLink,
  NavigationLinks,
  ProfileInfo,
} from './subcomponents';

/**
 * Render the animated panel which may contain profile information for the user
 * and navigation links. The content displayed varies based on conditions such
 * as screen size, the user's authentication status, and details about the
 * user's journey of care.
 */
export const AnimatedPanel = ({ style }) => {
  return (
    <Styled.Wrapper testID={TestID.Navigation.AnimatedPanel} style={style}>
      <Styled.Panel>
        <ProfileInfo />
        <NavigationLinks />
        <DevToolsLink />
        <CurrentEnvironment />
      </Styled.Panel>
    </Styled.Wrapper>
  );
};
