import { TestID } from 'app/components/Register/constants';
import {
  Input,
  InstructiveText,
  StyledCardImage,
  View,
  MemberCardImage,
} from 'app/components/Register/styles';

const BypassSsnRegistrationContent = () => (
  <View>
    <InstructiveText>
      Each person covered by a health insurance plan has a member ID number.
      Please find your health insurance card, and enter the last 4 digits of
      your member ID.
    </InstructiveText>
    <StyledCardImage resizeMode="contain" source={MemberCardImage} />
  </View>
);

const LastFourMemberIDInput = ({
  formValues,
  setFormValues,
  validateField,
  errors,
  handleFormSubmission,
  employerRegistrationIdInput,
}) => (
  <>
    <Input
      label="Last 4 of Member ID"
      error={errors.employerRegistrationId}
      info={{
        content: <BypassSsnRegistrationContent />,
        title: 'What is Insurance Member ID?',
      }}
      input={{
        name: TestID.Register.EmployerRegistrationIdInput,
        value: formValues.employerRegistrationId,
        onBlur: () => validateField('employerRegistrationId'),
        onChange: (value) => {
          setFormValues({
            ...formValues,
            employerRegistrationId: value,
          });
        },
      }}
      inputMode="text"
      maxLength={4}
      onSubmitEditing={handleFormSubmission}
      enterKeyHint="done"
      textInputRef={employerRegistrationIdInput}
    />
  </>
);

export default LastFourMemberIDInput;
