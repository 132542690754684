import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import styled, { css } from 'styled-components/native';

import { View } from 'app/components/Common/Styled';

interface AlignmentWrapperProps {
  maxSize?: 'xsmall' | 'small' | 'medium' | undefined;
  theme?: any;
}

export const AlignmentWrapper = styled(View)<AlignmentWrapperProps>`
  ${({ maxSize, theme }) => css`
    flex: 1 1 auto;
    max-width: ${theme.breakpoints[maxSize]}px;
    width: 100%;
  `}
`;

interface ContainerProps {
  ref?: (instance: unknown) => void;
}

export const Container = styled(KeyboardAwareScrollView).attrs({
  enableOnAndroid: true,
  extraScrollHeight: 20,
  contentContainerStyle: {
    alignItems: 'center',
  },
})<ContainerProps>``;
