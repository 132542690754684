import {
  InnerContainer,
  Messages,
  Modal,
} from 'app/components/LiveChatModal/styles';

import { ModalProps } from 'app/components/Common/Modal';

export const LiveChatModal = (props: ModalProps) => {
  return (
    <Modal {...props}>
      <InnerContainer>
        <Messages />
      </InnerContainer>
    </Modal>
  );
};
