import { Platform } from 'react-native';
import * as Sentry from '@sentry/react-native';

import Constants from 'expo-constants';
import * as Device from 'expo-device';
import * as Updates from 'expo-updates';

import { ENVIRONMENT, SENTRY_DSN, SENTRY_ENABLED } from 'app/util/constants';

export const routingInstrumentation = new Sentry.RoutingInstrumentation();

const initializeSentry = () => {
  try {
    const integrations = [];
    const options = {
      tracingOrigins: ['localhost', 'carrumhealth.com', /^\//],
      tracePropagationTargets: ['localhost', 'carrumhealth.com', /^\//],
    };

    const tracing = new Sentry.ReactNativeTracing({
      ...options,
      routingInstrumentation,
    });
    integrations.push(tracing);

    Sentry.init({
      dsn: SENTRY_DSN,
      environment: ENVIRONMENT,
      enableAutoSessionTracking: Platform.OS !== 'web',
      debug: ENVIRONMENT === 'development',
      integrations,
      // Only trace 20% of transactions in production
      tracesSampleRate: ENVIRONMENT === 'development' ? 1.0 : 0.2,
    });

    /**
     * Set context tags based on the current device and release info.
     *
     * https://docs.sentry.io/platforms/react-native/migration/sentry-expo/#changes-to-default-tags
     */
    Sentry.setExtras({
      manifest: Updates.manifest,
      deviceYearClass: Device.deviceYearClass,
      linkingUri: Constants.linkingUri,
    });

    Sentry.setTag('expoReleaseChannel', Updates.manifest.releaseChannel);
    Sentry.setTag('appVersion', Updates.manifest.version);
    Sentry.setTag('appPublishedTime', Updates.manifest.publishedTime);
    Sentry.setTag('expoSdkVersion', Updates.manifest.sdkVersion);
    Sentry.setTag('deviceId', Constants.sessionId);
    Sentry.setTag('appOwnership', Constants.appOwnership || 'N/A');
    Sentry.setTag('expoAppVersion', Constants.expoVersion);
    Sentry.setTag('expoChannel', Updates.channel);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Unable to initialize Sentry', error);
  }
};

/**
 * Log errors using Sentry when running in non-development mode,
 * including staging and production builds of the web and native
 * app.
 *
 * Explicitly prevent launching in a simulator when running e2
 * tests against the staging environment.
 */
if (SENTRY_ENABLED) initializeSentry();
