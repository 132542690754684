import axios from 'axios';

import { unformatObject } from 'app/util/reducerUtils';
import { CARE_API_URL } from 'app/util/constants';
import { getEpisodeSteps } from 'app/actions/episodeActions';
import { showGlobalError } from 'app/actions/uiActions';

export const RECEIVE_SUBMISSION = 'RECEIVE_SUBMISSION';
export const RECEIVE_SUBMISSIONS = 'RECEIVE_SUBMISSIONS';
export const RECEIVE_SUBMISSION_LOADING = 'RECEIVE_SUBMISSION_LOADING';

/**
 * Makes a GET request to the Care Service API
 * to fetch submissions for the current episode.
 *
 * @return {promise} A promise that resolves after the submissions are loaded.
 */
export const fetchSubmissions = () => {
  return async (dispatch, getState) => {
    const {
      episode: { episode },
      session: { coreToken },
    } = getState();

    if (!episode) return;

    try {
      const path = `/episodes/${episode.id}/submissions`;

      dispatch(receiveSubmissionLoading(true));

      const response = await axios.get(`${CARE_API_URL}${path}`, {
        params: { sort: '-completed_on' },
        headers: { Authorization: `Bearer ${coreToken}` },
      });

      await dispatch(receiveSubmissions(response.data.data));
      await dispatch(receiveSubmissionLoading(false));

      return true;
    } catch (error) {
      await dispatch(showGlobalError(error));
      await dispatch(receiveSubmissionLoading(false));

      return false;
    }
  };
};

/**
 * Makes a PATCH request to the Care Service API
 * to update a submission for the current episode.
 *
 * @param {integer} submissionId The ID of the submission to update.
 * @param {object} submission Submission details to save in the API.
 * @return {promise} A promise that resolves after the submission is updated.
 */
export const updateSubmission = (submissionId, submission) => {
  return async (dispatch, getState) => {
    try {
      dispatch(receiveSubmissionLoading(true));

      const {
        episode: { episode },
        session: { coreToken },
      } = getState();

      const path = `/episodes/${episode.id}/submissions/${submissionId}`;

      const response = await axios.patch(
        `${CARE_API_URL}${path}`,
        unformatObject({ submission }),
        {
          headers: { Authorization: `Bearer ${coreToken}` },
        }
      );

      /**
       * Form submissions will mark tasks complete in the backend, so
       * refresh the list of steps/tasks for this episode before
       * returning so the UI has the most up-to-date information.
       */
      if (submission.completedOn) await dispatch(getEpisodeSteps());

      await dispatch(receiveSubmission(response.data.data));
      await dispatch(receiveSubmissionLoading(false));

      return true;
    } catch (error) {
      await dispatch(receiveSubmissionLoading(false));
      await dispatch(showGlobalError(error));

      return false;
    }
  };
};

export const receiveSubmissions = (submissions) => {
  return {
    type: RECEIVE_SUBMISSIONS,
    submissions,
  };
};

export const receiveSubmission = (submission) => {
  return {
    type: RECEIVE_SUBMISSION,
    submission,
  };
};

export const receiveSubmissionLoading = (loading) => {
  return {
    type: RECEIVE_SUBMISSION_LOADING,
    loading,
  };
};
