import {
  emailValidationError,
  MINIMUM_PASSWORD_LENGTH,
  Platform,
  stepTwoValidations as validations,
  stepTwoDefaultFormValues as defaultFormValues,
  TestID,
} from 'app/components/Register/constants';

import {
  useCallback,
  useRef,
  useState,
  useValidations,
} from 'app/components/Register/hooks';

import {
  Button,
  CheckBox,
  FormError,
  FormView,
  Input,
  PhoneNumberInput,
  Select,
  InstructiveText,
  TogglePasswordText,
  View,
} from 'app/components/Register/styles';

import { StepTwoProps } from 'app/components/Register/types';

const StepTwo = ({
  error,
  formValues: formValuesFromProps,
  loading,
  onSubmit,
  referralSources,
  scrollToBottom,
}: StepTwoProps) => {
  const [formValues, setFormValues] = useState(
    formValuesFromProps || defaultFormValues
  );
  const [showPassword, setShowPassword] = useState(false);
  const [showSkipEmailValidation, setShowSkipEmailValidation] = useState(true);
  const { errors, isValid, validateField } = useValidations({
    formValues,
    validations,
  });

  const emailInput = useRef<HTMLInputElement>();
  const passwordConfirmationInput = useRef<HTMLInputElement>();
  const passwordInput = useRef<HTMLInputElement>();
  const phoneNumberInput = useRef<HTMLInputElement>();
  const referralSourceInput = useRef<HTMLInputElement>();

  // True if there are any incomplete form values
  const isIncomplete = Object.values(formValues).some(
    (value) => typeof value === 'undefined'
  );

  /**
   * Enable user to skip email validation on the server-side during
   * registration.
   */
  const toggleSkipEmailValidation = () => {
    setFormValues({
      ...formValues,
      skipEmailValidation: !formValues.skipEmailValidation,
    });
  };

  /**
   * Call the `onSubmit` prop with the current form values if the component is
   * not loading and there are no errors.
   */
  const handleFormSubmission = useCallback(() => {
    if (!isValid) return;

    setShowSkipEmailValidation(true);
    onSubmit(formValues);
  }, [formValues, isValid, onSubmit, setShowSkipEmailValidation]);

  /** Toggle whether to show passwords in plain text in password inputs. */
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  /**
   * Assign focus to a specific input (if possible).
   *
   * @param  inputRef  the input ref to receive focus
   */
  const assignFocusTo = (
    inputRef: React.MutableRefObject<HTMLInputElement>
  ) => {
    if (inputRef?.current) inputRef.current.focus();
  };

  return (
    <View testID={TestID.Register.StepTwo}>
      <InstructiveText paragraph spacing>
        Your eligibility has been verified! Create an account and take the first
        step towards better care.
      </InstructiveText>

      <InstructiveText paragraph>
        Your password must be at least {MINIMUM_PASSWORD_LENGTH} characters long
        and include at least 1 upper case letter and 1 number.
      </InstructiveText>

      <FormView>
        <Input
          label="Email Address"
          autoCorrect={false}
          blurOnSubmit={false}
          error={errors.email}
          input={{
            name: TestID.Register.EmailInput,
            value: formValues.email,
            onBlur: () => {
              validateField('email');
            },
            onChange: (email) => {
              setFormValues({ ...formValues, email });
              setShowSkipEmailValidation(false);
            },
          }}
          inputMode="email"
          onSubmitEditing={() => assignFocusTo(phoneNumberInput)}
          enterKeyHint="next"
          textInputRef={emailInput}
        />

        <PhoneNumberInput
          label="Phone Number"
          blurOnSubmit={false}
          error={errors.phoneNumber}
          input={{
            name: TestID.Register.PhoneNumberInput,
            value: formValues.phoneNumber,
            onBlur: () => validateField('phoneNumber'),
            onChange: (phoneNumber) => {
              setFormValues({ ...formValues, phoneNumber });
            },
          }}
          inputMode="tel"
          onSubmitEditing={() => assignFocusTo(passwordInput)}
          enterKeyHint="done"
          textInputRef={(input) => {
            if (Platform.OS === 'web') {
              phoneNumberInput.current = input?.input?.getInputDOMNode?.();
            } else {
              phoneNumberInput.current = input?.input?.getElement?.();
            }
          }}
        />

        <View>
          <Input
            label="Password"
            blurOnSubmit={false}
            error={errors.password}
            input={{
              // @ts-expect-error No overload matches
              autoComplete: 'new-password',
              // ^ prevent chrome prefilling
              name: TestID.Register.PasswordInput,
              value: formValues.password,
              onBlur: () => validateField('password'),
              onChange: (password) => {
                setFormValues({ ...formValues, password });
              },
            }}
            onSubmitEditing={() => assignFocusTo(passwordConfirmationInput)}
            enterKeyHint="next"
            rightIcon={
              <TogglePasswordText onPress={togglePassword}>
                {showPassword ? 'HIDE' : 'SHOW'}
              </TogglePasswordText>
            }
            secureTextEntry={!showPassword}
            textInputRef={passwordInput}
          />
        </View>

        <View>
          <Input
            label="Confirm Password"
            error={errors.passwordConfirmation}
            input={{
              name: TestID.Register.PasswordConfirmationInput,
              value: formValues.passwordConfirmation,
              onBlur: () => validateField('passwordConfirmation'),
              onChange: (passwordConfirmation) => {
                setFormValues({ ...formValues, passwordConfirmation });
              },
            }}
            onSubmitEditing={() => assignFocusTo(referralSourceInput)}
            enterKeyHint="next"
            rightIcon={
              <TogglePasswordText onPress={togglePassword}>
                {showPassword ? 'HIDE' : 'SHOW'}
              </TogglePasswordText>
            }
            secureTextEntry={!showPassword}
            textInputRef={passwordConfirmationInput}
          />
        </View>

        {referralSources.length ? (
          <Select
            label="How did you hear about Carrum?"
            error={errors.referralSource}
            forwardRef
            input={{
              name: TestID.Register.ReferralSourceInput,
              value: formValues.referralSource,
              onChange: (referralSource) => {
                setFormValues({
                  ...formValues,
                  referralSource,
                });
              },
            }}
            onSubmitEditing={() => scrollToBottom()}
            options={referralSources}
            ref={referralSourceInput}
            unSelectedOption
          />
        ) : null}

        {error ? (
          <>
            <FormError testID={TestID.Register.ErrorMessage}>{error}</FormError>
            {error === emailValidationError && showSkipEmailValidation && (
              <CheckBox
                label="I'm sure this is the correct email"
                input={{
                  name: TestID.Register.SkipEmailValidationCheckBox,
                  value: formValues.skipEmailValidation,
                  onBlur: () => {},
                  onChange: () => toggleSkipEmailValidation(),
                }}
              />
            )}
          </>
        ) : null}

        <Button
          title="Create Account"
          disabled={loading || isIncomplete || !isValid}
          loading={loading}
          onPress={handleFormSubmission}
          raised
          testID={TestID.Register.SubmitButton}
        />
      </FormView>
    </View>
  );
};

export default StepTwo;
