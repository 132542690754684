import React from 'react';

import PropTypes from 'prop-types';
import { StyleSheet } from 'react-native';
import { withTheme } from 'react-native-elements';
import Text from 'app/components/Common/Styled/Text';

const FormError = ({ theme, ...props }) => (
  <Text
    style={StyleSheet.flatten([
      { ...theme.Input.errorStyle },
      { color: theme.colors.error },
      props.textStyle,
    ])}
    {...props}
  />
);

FormError.propTypes = {
  theme: PropTypes.object.isRequired,
  textStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
};

export default withTheme(FormError);
