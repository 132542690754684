import { Routes } from 'app/util/routes';

export const SelectYourDoctorRoutes = {
  Root: `/${Routes.SelectYourDoctor}`,

  Confirmation: `/${Routes.SelectYourDoctor}/confirmation`,
  Doctor: `/${Routes.SelectYourDoctor}/doctors/:id`,
  Doctors: `/${Routes.SelectYourDoctor}/doctors`,
  Facilities: `/${Routes.SelectYourDoctor}/facilities`,
  Facility: `/${Routes.SelectYourDoctor}/facilities/:id`,
};
