import { initializeApp } from 'firebase/app';
import {
  getAnalytics,
  initializeAnalytics,
  logEvent as _logEvent,
  setUserId as _setUserId,
  setUserProperties as _setUserProperties,
} from 'firebase/analytics';
// @ts-ignore
import { ENVIRONMENT } from 'app/util/constants';
import firebaseConfig from '../../.google/firebase-config.json';

/**
 * The SDK is automatically initialized on mobile devices, but
 * still needs to be initialized manually for the web.
 *
 * https://firebase.google.com/docs/web/setup#add-sdks-initialize
 */
let app;
if (firebaseConfig[ENVIRONMENT] && !app) {
  app = initializeApp(firebaseConfig[ENVIRONMENT].web.firebase);

  /**
   * Google Analytics will register the current URL as the `page_location` property
   * for the initial page view and all subsequent events on web.
   *
   * Because our application may contain sensitive information in the query params,
   * we call the `initializeAnalytics()` method with a configuration object that
   * overrides the initial `page_location` value with the query params removed.
   *
   * https://firebase.google.com/docs/reference/js/analytics.gtagconfigparams.md#gtagconfigparamspage_location
   */
  initializeAnalytics(app, {
    config: { page_location: window.location.href.split('?')[0] },
  });
}

const logEvent = (event, params = {}) =>
  _logEvent(getAnalytics(app), event, params);
const setUserId = (value) => _setUserId(getAnalytics(app), value);
const setUserProperty = (name, value) =>
  _setUserProperties(getAnalytics(app), { name: value });

export { logEvent, setUserId, setUserProperty };
