import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Platform } from 'react-native';

import { JourneyPhaseIntroPage } from 'app/components/Common/Layout';
import StatusMessage from 'app/components/Common/StatusMessage';
import { DotIndicator } from 'app/components/Common/svg-components';
import {
  useEpisode,
  useNextPhaseRoute,
  useNextTask,
  useSession,
} from 'app/hooks';

import { Routes } from 'app/util/routes';
import { useHistory, Redirect } from '@cross-platform/react-router-native';

import {
  getCurrentStepForJouneyPhase,
  getIsJourneyComplete,
  getJourneyPhaseForPath,
} from './helpers';
import {
  Card,
  CardImage,
  CardImageWrapper,
  CardText,
  CardTextWrapper,
  CardsWrapper,
  CheckMark,
  CompleteText,
  CompleteTextWrapper,
  JourneyPhaseHtml,
  JourneyPhaseHtmlWrapper,
} from './styles';

const interpolateHtml = (html, user, episode) => {
  if (!html) return '';

  return html
    .replace('{user.firstName}', user?.firstName)
    .replace('{user.lastName}', user?.lastName)
    .replace('{episode.physician.name}', episode?.physician?.name);
};

const JourneyPhase = () => {
  const {
    location: { pathname },
    push,
  } = useHistory();
  const { episode, journeyPhases, refresh, steps } = useEpisode();
  const { user } = useSession();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { nextRoute } = useNextPhaseRoute();
  const { goToNextTask, nextTask, presentationMode } = useNextTask();

  const journeyPhase = getJourneyPhaseForPath(journeyPhases, pathname);
  const currentStep = getCurrentStepForJouneyPhase(journeyPhase);
  const isJourneyComplete = getIsJourneyComplete(episode, steps);

  const htmlContent = useMemo(() => {
    return interpolateHtml(currentStep?.helpContent || '', user, episode);
  }, [currentStep?.helpContent, user, episode]);

  /**
   * Refreshes the episode data with a loading state that is
   * reflected as a loading indicator in the UI.
   */
  const refreshData = useCallback(async () => {
    if (!episode) return;

    setIsRefreshing(true);
    await refresh();
    setIsRefreshing(false);
  }, [episode, refresh, setIsRefreshing]);

  /**
   * Refresh the current episode when this page loads to make sure
   * we have the most up to date information to present to users.
   */
  useEffect(() => {
    refreshData();
  }, []);

  /**
   * Redirect the user to the dashboard page when they've completed
   * the entire journey to show the "congratulations" screen.
   */
  if (isJourneyComplete) {
    return <Redirect to={`/${Routes.Dashboard}`} />;
  }

  /**
   * Redirect the user to the next Journey Phase route (if any) when the
   * current journey phase is complete.
   */
  if (!isJourneyComplete && nextRoute !== pathname && currentStep?.complete) {
    return <Redirect to={nextRoute} />;
  }

  if (!journeyPhase && isRefreshing) return <DotIndicator />;
  if (!journeyPhase)
    return (
      <StatusMessage
        status="error"
        message="We could not find the page you are looking for."
        ctaText="Click here to return home"
        ctaPath="/"
      />
    );

  const goToDashboard = () => {
    push(`/${Routes.Dashboard}`);
  };

  const onContinue = () => {
    if (presentationMode === 'workflow') {
      goToNextTask();
    } else {
      goToDashboard();
    }
  };

  const stepIcon = ({ iconUrl }) =>
    Platform.select({
      default: { uri: iconUrl },
      web: iconUrl,
    });

  return (
    <JourneyPhaseIntroPage
      continueDisabled={presentationMode === 'workflow' && !nextTask}
      isRefreshing={isRefreshing}
      onContinue={onContinue}
      title={journeyPhase.name}
    >
      <JourneyPhaseHtmlWrapper>
        <JourneyPhaseHtml html={htmlContent} />
      </JourneyPhaseHtmlWrapper>
      <CardsWrapper>
        {journeyPhase.steps.length > 1 &&
          journeyPhase.steps.map((step) => (
            <Card key={step.order} complete={step.complete}>
              <CardImageWrapper
                centered={step.complete}
                greyscale={step.order > episode.currentStep?.order}
              >
                {step.complete ? (
                  <CheckMark />
                ) : (
                  <CardImage source={stepIcon(step)} />
                )}

                {step.complete && (
                  <CompleteTextWrapper>
                    <CompleteText>Completed</CompleteText>
                  </CompleteTextWrapper>
                )}
              </CardImageWrapper>

              <CardTextWrapper
                greyscale={step.order > episode.currentStep?.order}
              >
                <CardText>{step.title}</CardText>
              </CardTextWrapper>
            </Card>
          ))}
      </CardsWrapper>
    </JourneyPhaseIntroPage>
  );
};

export default JourneyPhase;
