import React from 'react';
import Svg, { Circle } from 'react-native-svg';

interface ProgressNavCurrentProps {
  size: number;
}

/**
 * Renders the Progress Nav Complete Icon as an SVG.
 */
const ProgressNavCurrent = ({ size }: ProgressNavCurrentProps) => (
  <Svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Circle cx="16" cy="16" r="11" fill="#3A4750" />
    <Circle cx="16" cy="16" r="8" fill="white" />
    <Circle cx="16" cy="16" r="15.5" stroke="white" />
  </Svg>
);

export default ProgressNavCurrent;
