import styled, { css } from 'styled-components/native';

import { Text, View } from 'app/components/Common/Styled';

export {
  Anchor,
  Button,
  CenteredScrollScreen,
  ParagraphText,
  TitleText,
} from 'app/components/Login/styles';

export const ButtonContainer = styled(View)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing * 2}px;
  `}
`;

export const HelpText = styled(Text)`
  ${({ theme }) => css`
    font-family: ${theme.fontFamilyMedium};
    text-align: center;
    margin-bottom: ${theme.spacing}px;
  `}
`;

export const ImageContainer = styled(View)`
  ${({ theme }) => css`
    margin: ${theme.spacing * 0.5}px auto;
  `}
`;

export { MagicLink as MagicLinkSvg } from 'app/components/Common/svg-components';
