import styled, { css } from 'styled-components/native';

import { Button } from 'react-native-elements';
import { NotAvailableTimer } from 'app/components/Common/svg-components';
import { Text } from 'app/components/Common/Styled';
import { View } from 'app/components/Common/Styled';

const getFixedWidth = (theme) => {
  const windowWidth = theme.windowDimensions.width;

  if (windowWidth <= theme.breakpoints.xxxsmall) {
    return theme.spacing * 20;
  } else if (windowWidth <= theme.breakpoints.xxsmall) {
    return theme.breakpoints.xxxsmall;
  } else if (windowWidth <= theme.breakpoints.xsmall) {
    return theme.breakpoints.xxsmall;
  } else {
    return theme.breakpoints.xsmall;
  }
};

export const BodyText = styled(Text)`
  text-align: center;
`;

export { CenteredScrollScreen } from 'app/components/Common/Layout';

export const DateText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.bodyText};
    font-family: ${theme.fontFamilyMedium};
    font-size: ${theme.rawFontSizes.large}px;
    text-align: center;
    margin-bottom: ${theme.spacing * 1.5}px;
    margin-top: ${theme.spacing * 1.5}px;
  `}
`;

export const FinalText = styled(BodyText)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing * 2.5}px;
    margin-top: ${theme.spacing * 2.5}px;
  `}
`;

export const LogOutButton = styled(Button).attrs(({ theme }) => {
  return {
    buttonStyle: {
      height: 66,
    },
    containerStyle: {
      alignSelf: 'center',
      width: theme.spacing * 10,
    },
    disabledStyle: {
      backgroundColor: theme.colors.disabledNavBackground,
    },
    disabledTitleStyle: {
      color: theme.colors.white,
    },
    raised: true,
  };
})``;

export const LogOutButtonContainer = styled(View)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing * 2}px;
  `}
`;

export const NotAvailableTimerSvg = styled(NotAvailableTimer).attrs(
  ({ theme }) => ({
    fixedWidth: getFixedWidth(theme),
  })
)``;

export const SvgWrapper = styled(View)`
  flex: 1 0 auto;
  flex-direction: row;
  justify-content: center;
`;

export const Title = styled(Text).attrs(({ theme }) => {
  return {
    h1: true,
    h1Style: {
      color: theme.colors.bodyText,
      marginTop: theme.spacing * 2.5,
      marginBottom: theme.spacing * 0.5,
    },
  };
})``;
