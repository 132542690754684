import React from 'react';
import { useWindowDimensions } from 'react-native';

import theme from 'app/util/theme';
import { TestID } from 'app/util/test-id';

import {
  ButtonIcon,
  FlexButton,
  NavigationButton,
  NavigationFooterContainer,
  NavigationView,
  PressableArea,
} from './styles';

import {
  DisplayScreens,
  NavigationAlignment,
  NavigationMode,
  NavigationPosition,
  NavigationType,
  StickyNavFooterProps,
} from './types';

/**
 * Opinionated navigation footer that sticks to the bottom of the screen and
 * only displays when the screen width is equal to or less than a specified
 * breakpoint.
 */
export const StickyNavFooter = (props: StickyNavFooterProps) => {
  const { navigationConfig, navigationMode, testID } = props;

  const { height, width } = useWindowDimensions();
  const isNarrowScreen = width <= theme.breakpoints.xsmall;
  const isShortScreen = height <= theme.breakpoints.shortScreen;

  const getDisplaySettings = (navigationMode: NavigationMode) => {
    switch (navigationMode) {
      case NavigationMode.SkipAndNext:
        return {
          display: DisplayScreens.NarrowAndShort,
          isNavDisplayed: true,
          navigationPosition: NavigationPosition.LeftAndRight,
          navigationType: isNarrowScreen
            ? NavigationType.Flex
            : NavigationType.Standard,
          navigationAlignment: NavigationAlignment.Right,
        };
      case NavigationMode.PreviousAndNext:
        return {
          display: DisplayScreens.NarrowOnly,
          isNavDisplayed: isNarrowScreen,
          navigationPosition: NavigationPosition.LeftAndRight,
          navigationType: NavigationType.Flex,
          navigationAlignment: NavigationAlignment.Right,
        };
      case NavigationMode.Continue:
        return {
          display: DisplayScreens.NarrowOnly,
          isNavDisplayed: isNarrowScreen,
          navigationPosition: NavigationPosition.RightOnly,
          navigationType: NavigationType.Flex,
          navigationAlignment: NavigationAlignment.Right,
        };
      case NavigationMode.Centered:
        return {
          display: DisplayScreens.NarrowAndWide,
          isNavDisplayed: true,
          navigationPosition: NavigationPosition.RightOrCenter,
          navigationType: NavigationType.NarrowFlexWideStandard, // TODO: WHY OK TO
          navigationAlignment: isNarrowScreen
            ? NavigationAlignment.Right
            : NavigationAlignment.Center,
        };
      case NavigationMode.DoubleButton:
        return {
          display: DisplayScreens.NarrowAndWide,
          isNavDisplayed: true,
          navigationPosition: NavigationPosition.LeftAndRight,
          navigationType: NavigationType.Standard,
          navigationAlignment: NavigationAlignment.Right,
        };
    }
  };

  const displaySettings = getDisplaySettings(navigationMode);
  const hasCenteredButton =
    displaySettings.navigationAlignment === NavigationAlignment.Center;
  const hasOneButton =
    displaySettings.navigationPosition !== NavigationPosition.LeftAndRight;
  const hasOptionalNavigation = !hasCenteredButton && !hasOneButton;
  const hasButtonOnLeft =
    displaySettings.navigationType === NavigationType.Standard;
  const hasButtonOnRight =
    displaySettings.navigationType === NavigationType.Standard ||
    (displaySettings.navigationType === NavigationType.NarrowFlexWideStandard &&
      !isNarrowScreen);

  const optionalNavigation = navigationConfig[0];
  const requiredNavigation = hasOptionalNavigation
    ? navigationConfig[1]
    : navigationConfig[0];

  if (displaySettings.isNavDisplayed) {
    return (
      <NavigationFooterContainer
        navigationMode={navigationMode}
        testID={testID}
      >
        {hasOptionalNavigation && (
          <PressableArea
            disabled={optionalNavigation.disabled}
            onPress={optionalNavigation.onPress}
            testID={hasButtonOnLeft ? '' : optionalNavigation.testID}
          >
            <NavigationView
              alignment={NavigationAlignment.Left}
              disabled={optionalNavigation.disabled}
              navigationMode={navigationMode}
              testID={TestID.StickyNavFooter.OptionalNavigation}
            >
              {hasButtonOnLeft ? (
                <NavigationButton
                  disabled={optionalNavigation.disabled}
                  loading={optionalNavigation.loading}
                  navigationMode={navigationMode}
                  onPress={optionalNavigation.onPress}
                  testID={optionalNavigation.testID}
                  title={optionalNavigation.text}
                  type="outline"
                />
              ) : (
                <FlexButton
                  alignment={NavigationAlignment.Left}
                  navigationMode={navigationMode}
                  disabled={optionalNavigation.disabled}
                  loading={optionalNavigation.loading}
                >
                  {optionalNavigation.text}
                </FlexButton>
              )}
            </NavigationView>
          </PressableArea>
        )}

        <PressableArea
          disabled={requiredNavigation.disabled || hasCenteredButton}
          onPress={requiredNavigation.onPress}
          testID={hasButtonOnRight ? '' : requiredNavigation.testID}
        >
          <NavigationView
            alignment={displaySettings.navigationAlignment}
            disabled={requiredNavigation.disabled}
            navigationMode={navigationMode}
            testID={TestID.StickyNavFooter.RequiredNavigation}
          >
            {hasButtonOnRight ? (
              <NavigationButton
                disabled={requiredNavigation.disabled}
                loading={requiredNavigation.loading}
                onPress={requiredNavigation.onPress}
                navigationMode={navigationMode}
                testID={requiredNavigation.testID}
                title={requiredNavigation.text}
              />
            ) : (
              <>
                <FlexButton>{requiredNavigation.text}</FlexButton>
                <ButtonIcon />
              </>
            )}
          </NavigationView>
        </PressableArea>
      </NavigationFooterContainer>
    );
  }

  return null;
};

export default StickyNavFooter;
