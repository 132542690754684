import React from 'react';
import { useWindowDimensions } from 'react-native';

import CarrumSilverExperienceTable from 'app/components/CarrumSilverExperienceTable';
import { Text } from 'app/components/Common/Styled';
import Anchor from 'app/components/Common/Anchor';
import Modal from 'app/components/Common/Modal';
import breakpoints from 'app/util/breakpoints';
import { routeToFAQ } from 'app/util/routeUtils';

import {
  DescriptionWrapper,
  LearnMoreText,
  LearnMoreWrapper,
} from '../GoldExperience/styles';

interface SilverExperienceModalProps {
  isVisible: boolean;
  onClose: () => void;
}

export const SilverExperienceModal = ({
  isVisible,
  onClose,
}: SilverExperienceModalProps) => {
  const isNarrow = useWindowDimensions().width <= breakpoints.xsmall;

  return (
    <Modal
      fullScreen={isNarrow}
      isVisible={isVisible}
      onClose={onClose}
      showCloseX
      size="large"
      title="What is Carrum Silver Experience?"
    >
      <DescriptionWrapper>
        <Text>
          Each facility and doctor is hand-picked based on a comprehensive
          vetting process, so you always know that you are receiving the best
          care. The differences are in the experience and cost.
        </Text>
      </DescriptionWrapper>
      <CarrumSilverExperienceTable showButtons={false} />

      <LearnMoreWrapper>
        <LearnMoreText>
          Learn more in <Anchor title="our FAQs" to={routeToFAQ} />
        </LearnMoreText>
      </LearnMoreWrapper>
    </Modal>
  );
};

export default SilverExperienceModal;
