import {
  ENVIRONMENT,
  nonProdEnvironments,
} from 'app/components/App/Navigation/constants';

import { CurrentEnvironmentStyles as Styled } from 'app/components/App/Navigation/styles';

import { EnvironmentSwitch } from './subcomponents';

export const CurrentEnvironment = () => (
  <EnvironmentSwitch environments={nonProdEnvironments}>
    <Styled.Wrapper>
      <Styled.Text>
        Running app in&nbsp;
        <Styled.BoldText>{ENVIRONMENT}</Styled.BoldText>
      </Styled.Text>
    </Styled.Wrapper>
  </EnvironmentSwitch>
);
